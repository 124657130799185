import L10n from "localization";
import React from "react";
import Style from "style";
import { IProduct } from "./types";

interface IProps {
  showAppleTerms?: boolean;
  product: IProduct | undefined;
}
export default function FinePrint(props: IProps) {
  const { showAppleTerms, product } = props;

  const appleTermsStyle = {
    fontSize: "11px",
    fontWeight: 300,
    marginTop: 16,
  } as React.CSSProperties;

  const appleTerms =
    product?.eligible_for_free_trial === true && product?.freeTrialDuration
      ? L10n.localize((s) => s.account.appleFinePrintFreeTrial)
      : product?.frequency
        ? L10n.localize((s) => s.account.appleFinePrintSubscription)
        : L10n.localize((s) => s.account.appleFinePrintOneTimePurchase);

  const linkStyle = { color: Style.colors.secondaryFg };

  const style = {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
    justifyContent: "center",
  } as React.CSSProperties;

  return (
    <div style={{ color: Style.colors.mutedFg, marginTop: 48 }}>
      {showAppleTerms && <div style={appleTermsStyle}>{appleTerms}</div>}
      <div style={style}>
        <a href="https://www.ankiapp.com/terms.html" style={linkStyle}>
          {L10n.localize((s) => s.account.termsOfService)}
        </a>
        <span style={{ margin: "0 6px" }}>&</span>
        <a href="https://www.ankiapp.com/privacy.html" style={linkStyle}>
          {L10n.localize((s) => s.account.privacyPolicy)}
        </a>
      </div>
    </div>
  );
}
