import { IonListHeader } from "@ionic/react";
import { IMarketItem } from "fields/layoutMarket/types";
import { isValidField } from "fields/lib";
import React from "react";
import { ID } from "types/ID";
import ConfigEditorItem from "./configEditorItem";
import ConfigSelectorItem from "./configSelectorItem";
import { IConfigSelection } from "./main";

interface IProps {
  selectedID?: ID;
  name: string;
  list: IMarketItem[];
  onSelect: (cfg: IConfigSelection) => void;
}

export default function MarketSection({ selectedID, name, list, onSelect }: IProps) {
  return (
    <>
      <IonListHeader>
        <h4>{name}</h4>
      </IonListHeader>
      <div
        style={{
          paddingLeft: 12,
          paddingRight: 12,
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          overflowX: "scroll",
          justifyContent: "flex-start",
        }}
      >
        {list.map((item) => {
          const hasInvalidFields = item.fields.some((f) => !isValidField(f));
          const isHandledByConfigSpecificOptionsAfterSelection = [
            "language",
            "japanese",
            "chinese",
          ].includes(item.base);
          if (hasInvalidFields && !isHandledByConfigSpecificOptionsAfterSelection) {
            return (
              <ConfigEditorItem
                key={item.name}
                name={item.name}
                base={item.base}
                selected={item.base === selectedID}
                fields={item.fields}
                values={item.values}
                description={item.description}
                onSave={onSelect}
                backOnly
              />
            );
          }

          return (
            <ConfigSelectorItem
              key={item.name}
              name={item.name}
              base={item.base}
              selected={item.base === selectedID}
              fields={item.fields}
              values={item.values}
              previewValuesHTML={item.previewValuesHTML}
              description={item.description}
              onSelect={onSelect}
              backOnly
            />
          );
        })}
      </div>
    </>
  );
}
