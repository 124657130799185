import idb, { IDBAppSchema, IFolderRow } from "@data/idb";
import migrateResponsesToV2IfNecessary from "@data/migrateResponsesToV2IfNecessary";
import { Base64UUID, HexUUID, convertToBase64IfNecessary, convertToHexIfNecessary } from "@lib/ids";
import { filterIterable } from "@lib/iterable";
import type { UTCMillis } from "@lib/timestamps";
import { Deck, IDeck } from "@models/deck";
import { IDeckSettings } from "@models/deckSettings";
import { IKnol, Knol } from "@models/knol";
import { IIDBResponse } from "@models/response";
import { User } from "@models/user";
import { descendsFrom } from "@screens/folder/overview";
import EventBus from "eventBus";
import { MAGIC_LAYOUT_ID_BASE64 } from "fields/magicLayout";
import { IDBPTransaction } from "idb";
import { ID } from "types/ID";
import Events from "../../../../node_modules/eventemitter3/index";

// INestedResponse omits the various IDs indexing it, on the assumption
// that it's organized in a nested hierarchy in which those are implicit
// (e.g. map knol -> responses).
export interface INestedResponse {
  durationMillis: number;
  createdAt: UTCMillis;
  score: number;
}

type CacheEventName =
  | "knolUpdated"
  | "deckUpdated"
  | "deckMoved"
  | "folderUpdated"
  | "responsesUpdated"
  | "responsesLoaded"
  | "initialized";
class SuperCache {
  events: Events<CacheEventName>;
  decks: Map<ID, IDeck> | undefined;
  folders: Map<string, IFolderRow> | undefined;
  knols: Map<ID, IKnol> | undefined;
  deckIDToKnolIDs: Map<ID, Set<ID>> | undefined;
  knolIDToDeckID: Map<Base64UUID, Base64UUID> | undefined;
  responses: Map<Base64UUID, Map<Base64UUID, INestedResponse[]>> | undefined; // knol ID -> layout ID -> responses
  responsesLoaded = false;
  initialized = false;

  constructor() {
    this.events = new Events();
  }

  // private emit<E extends CacheEventName>(name: E, event: IEvents[E]) {
  //   this.events.emit(name, event);
  // }

  async init() {
    this.decks = new Map();
    this.deckIDToKnolIDs = new Map();
    const decks = await idb.db.getAll("decks");
    for (const deck of decks) {
      this.decks.set(deck.id, deck);
      this.deckIDToKnolIDs.set(deck.id, new Set());
    }

    this.folders = new Map();
    await this.loadFolders();

    this.knols = new Map();
    this.knolIDToDeckID = new Map();
    await this.loadKnols();
    await this.loadKnolsDecks();

    if (User.isLoggedIn()) {
      try {
        await migrateResponsesToV2IfNecessary();
      } catch (err) {
        await User.logout();
        return;
      }

      this.responses = new Map();
      // console.time("loading responses into superCache");
      await this.loadResponses();
      // console.timeEnd("loading responses into superCache");

      // console.time("computing knol grades");
      this.updateKnolGrades();
      // console.timeEnd("computing knol grades");

      EventBus.on("responseInserted", this.responseInsertedHandler);
      this.events.emit("responsesLoaded");
    }

    EventBus.on("deckAdded", this.deckAddedHandler);
    EventBus.on("deckDeleted", this.decksRemovedHandler);
    EventBus.on("deckGradesReset", this.deckGradesResetHandler);
    EventBus.on("deckMoved", this.deckMovedHandler);
    EventBus.on("deckRemoved", this.decksRemovedHandler);
    EventBus.on("deckUpdated", this.deckUpdatedHandler);
    EventBus.on("knolUpdated", this.knolUpdatedHandler);
    EventBus.on("folderCreated", this.loadFolders);
    EventBus.on("folderDeleted", this.folderUpdateHandler);
    EventBus.on("folderUpdated", this.folderUpdateHandler);
    EventBus.on("folderRenamed", this.folderRenamedHandler);

    this.initialized = true;
    this.events.emit("initialized");

    for (const deck of decks) {
      this.events.emit("deckUpdated", { ID: deck.id });
    }
  }

  clear() {
    this.decks?.clear();
    this.deckIDToKnolIDs?.clear();
    this.decks?.clear();
    this.folders?.clear();
    this.knols?.clear();
    this.events.emit("folderUpdated", { name: "" });
    this.responses?.clear();
    this.knolIDToDeckID?.clear();
  }

  private loadFolders = async () => {
    const folders = await idb.db.getAll("folders");
    for (const folder of folders) {
      this.folders?.set(folder.name, folder);
    }
    this.events.emit("folderUpdated");
  };

  private decksRemovedHandler = async (opts: { deckIDs: string[] }) => {
    for (const id of opts.deckIDs) {
      await this.deckUpdatedHandler({ ID: id });
    }
  };

  private deckGradesResetHandler = async (opts: { ID: string }) => {
    await this.loadKnols(opts.ID);
    this.events.emit("knolUpdated", { deckID: opts.ID });
  };

  private loadKnols = async (deckID?: ID) => {
    const tx = idb.db.transaction(["knols"], "readonly");
    const store = tx.objectStore("knols");
    const idx = store.index("deck_id");
    const query = deckID ? IDBKeyRange.only(deckID) : undefined;
    for await (const cursor of idx.iterate(query)) {
      const knol = cursor.value;
      if (deckID && knol.deck_id !== deckID) {
        // Since the index is in place, this shouldn't be reached. Belt and suspenders.
        continue;
      }
      this.knols?.set(knol.id, knol);
      this.deckIDToKnolIDs?.get(knol.deck_id)?.add(knol.id);

      const knolID64 = convertToBase64IfNecessary(knol.id as HexUUID);
      const deckID64 = convertToBase64IfNecessary(knol.deck_id as HexUUID);
      this.knolIDToDeckID?.set(knolID64, deckID64);
    }
    await tx.done;
  };

  private loadKnolsDecks = async () => {
    const tx = idb.db.transaction(["knols_decks"], "readonly");
    const store = tx.objectStore("knols_decks");
    if (!this.knolIDToDeckID) {
      this.knolIDToDeckID = new Map();
    }
    for (const { knolID, deckID } of await store.getAll()) {
      this.knolIDToDeckID.set(knolID, deckID);
    }
    await tx.done;
  };

  private loadResponse = (resp: IIDBResponse) => {
    let layoutMap = this.responses?.get(resp.knol_id);
    if (!layoutMap) {
      layoutMap = new Map();
      this.responses?.set(resp.knol_id, layoutMap);
    }

    const layoutID = resp.layout_id ?? MAGIC_LAYOUT_ID_BASE64;

    const responses = layoutMap.get(layoutID) ?? [];
    const nestedResponse: INestedResponse = {
      createdAt: resp.created_at,
      durationMillis: resp.duration_ms,
      score: resp.score,
    };
    responses.push(nestedResponse);
    layoutMap.set(layoutID, responses);
  };

  private loadResponseBatch = async (
    tx: IDBPTransaction<IDBAppSchema, ["responsesV2"], "readonly">,
    createdAtLowerBound: number,
  ): Promise<{ numRowsProcessed: number; lastCreatedAt: number }> => {
    const store = tx.objectStore("responsesV2");
    const index = store.index("created_at");

    const batchSize = 1000;
    const batch = await index.getAll(IDBKeyRange.lowerBound(createdAtLowerBound, true), batchSize);
    for (const resp of batch) {
      this.loadResponse(resp);
    }
    const lastCreatedAt = batch.length ? batch[batch.length - 1].created_at : -1;

    return { lastCreatedAt, numRowsProcessed: batch.length };
  };

  private loadResponses = async () => {
    const totalResponses = await idb.db.count("responsesV2");

    EventBus.emit("responsesBatchLoaded", {
      numerator: 0,
      denominator: totalResponses,
    });

    const tx = idb.db.transaction("responsesV2", "readonly");
    let numLoadedResponses = 0;
    let res = await this.loadResponseBatch(tx, 0);
    numLoadedResponses += res.numRowsProcessed;

    while (res.numRowsProcessed > 0) {
      EventBus.emit("responsesBatchLoaded", {
        numerator: numLoadedResponses,
        denominator: totalResponses,
      });
      res = await this.loadResponseBatch(tx, res.lastCreatedAt);
      numLoadedResponses += res.numRowsProcessed;
    }

    EventBus.emit("responsesBatchLoaded", {
      numerator: numLoadedResponses,
      denominator: totalResponses,
    });

    await tx.done;
    this.responsesLoaded = true;
    this.events.emit("responsesUpdated");
  };

  private updateKnolGrade = (knolID: ID) => {
    const knolIDHex = convertToHexIfNecessary(knolID as Base64UUID | HexUUID);
    const knol = this.knols?.get(knolIDHex);
    if (!knol) {
      return;
    }
    const knolID64 = convertToBase64IfNecessary(knolID as Base64UUID | HexUUID);
    const deckID = this.knolIDToDeckID?.get(knolID64);
    if (!deckID) {
      return;
    }
    const deckIDHex = convertToHexIfNecessary(deckID as Base64UUID | HexUUID);
    const deck = this.decks?.get(deckIDHex);
    if (!deck) {
      return;
    }
    const deckLastResetAt = deck.user_config?.lastResetAt
      ? new Date(deck.user_config?.lastResetAt) // HACK: if user syncs a grade reset operation, it ends up going into IDB as a string, not a Date.
      : undefined;
    const grades = Knol.ComputeGradesForAllLayouts(knolID64, deckLastResetAt);
    knol.grades = grades;
    this.knols?.set(knolIDHex, knol);
  };

  private updateKnolGrades = async () => {
    for (const knolID of this.knols?.keys() ?? []) {
      this.updateKnolGrade(knolID);
    }
  };

  private deckMovedHandler = async (opts: { ID: string; folder?: string }) => {
    await this.deckUpdatedHandler({ ID: opts.ID });
    this.events.emit("deckMoved");
  };

  private folderUpdateHandler = async (opts: { name: string }) => {
    const folder = await idb.db.get("folders", opts.name);
    if (folder) {
      this.folders?.set(folder.name, folder);
    } else {
      this.folders?.delete(opts.name);
    }
    this.events.emit("folderUpdated", { name: opts.name });
  };

  private responseInsertedHandler = async (opts: { response: IIDBResponse }) => {
    this.loadResponse(opts.response);

    const knolID64 = opts.response.knol_id;
    this.updateKnolGrade(knolID64);

    this.events.emit("responsesUpdated");

    const knolIDHex = convertToHexIfNecessary(knolID64);
    const deckID64 = this.knolIDToDeckID?.get(knolID64);
    if (deckID64) {
      const deckIDHex = convertToHexIfNecessary(deckID64);
      this.events.emit("knolUpdated", { knolID: knolIDHex, deckID: deckIDHex });
    }
  };

  private folderRenamedHandler = async (opts: { tag_name_prev: string; tag_name: string }) => {
    const folder = await idb.db.get("folders", opts.tag_name);
    this.folders?.delete(opts.tag_name_prev);
    if (folder) {
      this.folders?.set(folder.name, folder);
    }
    this.events.emit("folderUpdated", { name: opts.tag_name });
  };

  private deckAddedHandler = async (opts: { ID: string }) => {
    const deck = await idb.db.get("decks", opts.ID);
    if (deck) {
      this.decks?.set(deck.id, deck);
      this.deckIDToKnolIDs?.set(deck.id, new Set());
      await this.loadKnols(deck.id);
      this.events.emit("deckUpdated", { ID: deck.id });
    }
  };

  private deckUpdatedHandler = async (opts: { ID: string }) => {
    const deck = await idb.db.get("decks", opts.ID);
    if (deck) {
      this.decks?.set(deck.id, deck);
      if (!this.deckIDToKnolIDs?.has(deck.id)) {
        this.deckIDToKnolIDs?.set(deck.id, new Set());
      }
      if (Deck.isArchived(deck)) {
        // Clear its knols.
        for (const knolID of this?.deckIDToKnolIDs?.get(opts.ID) ?? []) {
          this?.knols?.delete(knolID);
        }
      }
      this.events.emit("deckUpdated", { ID: deck.id });
    } else {
      // Delete it.
      this.decks?.delete(opts.ID);
      for (const knolID of this?.deckIDToKnolIDs?.get(opts.ID) ?? []) {
        this?.knols?.delete(knolID);
      }
      this.deckIDToKnolIDs?.delete(opts.ID);
      this.events.emit("deckUpdated", { ID: opts.ID });
    }
  };

  private knolUpdatedHandler = async (event: { knolID: ID; deckID: ID }) => {
    const deckID64 = convertToBase64IfNecessary(event.deckID as Base64UUID | HexUUID);
    const knolID64 = convertToBase64IfNecessary(event.knolID as Base64UUID | HexUUID);
    this.knolIDToDeckID?.set(knolID64, deckID64);

    const deck = this.decks?.get(event.deckID);
    if (deck) {
      const newKnol = await Knol.WithGrade(event.knolID, deck.layout_id);
      if (newKnol) {
        this.deckIDToKnolIDs?.get(event.deckID)?.add(event.knolID);
        this.knols?.set(event.knolID, newKnol as IKnol);
      } else {
        this.deckIDToKnolIDs?.get(event.deckID)?.delete(event.knolID);
        this.knols?.delete(event.knolID);
      }
      this.events.emit("knolUpdated", event);
    }
  };

  filteredKnolIDsFor(
    knolIDs: Iterable<ID>,
    settings: IDeckSettings,
    excludeIgnored = true,
    sort = false,
  ): Set<ID> {
    let filtered: Iterable<ID> = knolIDs;

    const reviewGrades = settings?.reviewGrades;
    if (reviewGrades && reviewGrades.length > 0) {
      filtered = filterIterable(filtered, (knolID) => {
        const knol = this.knols?.get(knolID);
        if (!knol) {
          return false;
        }
        const grade = Knol.GetGrade(knol);
        return Knol.isGradeInSet(grade, reviewGrades);
      });
    }

    if (settings?.reviewTags?.length) {
      const tagSet = new Set(settings.reviewTags);
      filtered = filterIterable(filtered, (knolID) => {
        const knol = this.knols?.get(knolID);
        return knol?.tags?.some((tag) => tagSet.has(tag));
      });
    }

    // Remove ignored cards.
    if (excludeIgnored) {
      filtered = filterIterable(filtered, (knolID) => {
        const knol = this.knols?.get(knolID);
        const ignored = knol?.tags?.some((tag) => tag === Knol.IGNORE_TAG);
        return !ignored;
      });
    }

    if (sort) {
      const filteredKnolIDs: ID[] = [];
      for (const id of filtered) {
        filteredKnolIDs.push(id);
      }
      if (settings?.sortOrder) {
        const sorter = Deck.sortKnols(settings.sortOrder);
        filteredKnolIDs.sort((i1, i2) => {
          const [k1, k2] = [this.knols?.get(i1), this.knols?.get(i2)];
          if (!k1 || !k2) {
            // Shouldn't happen.
            return 0;
          }
          return sorter(k1, k2);
        });
      }
      return new Set(filteredKnolIDs);
    }

    const filteredKnolIDs = new Set<ID>();
    for (const id of filtered) {
      filteredKnolIDs.add(id);
    }
    return filteredKnolIDs;
  }

  decksInFolder(name: string): Set<ID> | undefined {
    if (!this.decks) {
      return undefined;
    }
    const deckIDs = new Set<ID>();
    for (const [id, deck] of this.decks) {
      if (descendsFrom(deck, name) && !Deck.isArchived(deck)) {
        deckIDs.add(id);
      }
    }
    return deckIDs;
  }
}
export const superCache = new SuperCache();
