import { useFolderSelectorModal } from "@components/folderSelector/folderSelectorModal";
import ScreenMenu, { MenuButton } from "@components/screenMenu";
import MapView from "@lib/mapView";
import { Deck, IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { useCardModal } from "@screens/card";
import DeckEditScreen from "@screens/deckEdit";
import DeckLayoutsScreen from "@screens/deckLayouts";
import { useFlagAndContext } from "featureFlags";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { checkmarkCircleOutline } from "ionicons/icons";
import L10n from "localization";
import React, { BaseSyntheticEvent } from "react";
import {
  LuArchive,
  LuFilePlus,
  LuFilterX,
  LuFolderInput,
  LuInfo,
  LuLayout,
  LuPencilLine,
  LuShare,
  LuTimerReset,
  LuUserPlus,
  LuWand2,
} from "react-icons/lu";
import { ID } from "types/ID";
import ShareModal from "./sharing/deckShareModal";
import { History } from "history";

function AddToFolderItem({ deck }: { deck: IDeck }) {
  const [presentFolderAddSuccessToast] = useDismissibleToast();

  async function handleSave(folder?: string, initialFolder?: string) {
    if (folder) {
      await Deck.moveToFolder(deck, folder);
    } else if (!folder && initialFolder) {
      await Deck.removeFromFolder(deck.id, initialFolder);
    }

    presentFolderAddSuccessToast({
      message: L10n.localize((s) => s.folder.added),
      icon: checkmarkCircleOutline,
      position: "middle",
      color: "success",
      duration: 1000,
    });
  }
  const [present] = useFolderSelectorModal({ deckID: deck.id, onSave: handleSave });
  return (
    <MenuButton
      label={L10n.localize((s) => s.actions.move)}
      detail={false}
      icon={<LuFolderInput size={24} />}
      onClick={present}
    />
  );
}

interface IProps {
  deck: IDeck;
  knols: MapView<ID, IKnol>;
  isEditable: boolean;
  isLegacyDeck: boolean;
  handleArchiveDeck: () => void;
  handleResetGrades: () => void;
  onResetFilters?: () => void;
  handleExport: (e: BaseSyntheticEvent<MouseEvent>) => void;
  onDismissModal?: () => void;
  presentGenCardsModal: () => void;
  history: History;
}
export default function Menu(props: IProps): JSX.Element {
  const {
    deck,
    isEditable,
    handleArchiveDeck,
    handleExport,
    handleResetGrades,
    onResetFilters,
    onDismissModal,
    isLegacyDeck,
    presentGenCardsModal,
  } = props;

  const canResetFilters = !!onResetFilters;
  const [presentShareSuccessToast] = useDismissibleToast();
  const [presentCardCreateModal] = useCardModal({
    deck,
    onDismiss: onDismissModal,
  });
  const [presentDeckEditModal] = useIonCardModalWithDismiss(DeckEditScreen, {
    deck,
    onDismiss: onDismissModal,
  });
  const [presentDeckShareModal] = useIonCardModalWithDismiss(ShareModal, {
    deckID: deck.id,
    history: props.history,
    onSuccess: () => {
      presentShareSuccessToast({
        message: L10n.localize((s) => s.sharing.sharedDecks),
        icon: checkmarkCircleOutline,
        position: "middle",
        color: "success",
        duration: 1000,
      });
    },
  });
  const [presentLayoutsModal] = useIonCardModalWithDismiss(DeckLayoutsScreen, {
    deck: deck,
    onDismiss: onDismissModal,
  });

  const genFlag = useFlagAndContext("features");
  const hasGenFlag = genFlag?.flag?.ai_card_create !== undefined;

  return (
    <ScreenMenu>
      {isEditable && (
        <MenuButton
          label={L10n.localize((s) => s.card.new)}
          icon={<LuFilePlus size={24} />}
          onClick={presentCardCreateModal}
        />
      )}
      {isEditable && hasGenFlag ? (
        <MenuButton
          label={L10n.localize((s) => s.card.generate)}
          icon={<LuWand2 size={24} />}
          onClick={presentGenCardsModal}
        />
      ) : undefined}
      <MenuButton
        label={isEditable ? L10n.localize((s) => s.deck.edit) : L10n.localize((s) => s.deck.info)}
        icon={isEditable ? <LuPencilLine size={24} /> : <LuInfo size={24} />}
        onClick={presentDeckEditModal}
      />
      {isLegacyDeck ? (
        <MenuButton
          label={L10n.localize((s) => s.layout.plural)}
          icon={<LuLayout size={24} />}
          onClick={presentLayoutsModal}
        />
      ) : undefined}
      <AddToFolderItem deck={deck} />
      <MenuButton
        label={L10n.localize((s) => s.actions.share)}
        detail={false}
        icon={<LuUserPlus size={24} />}
        onClick={presentDeckShareModal}
      />
      {canResetFilters && (
        <MenuButton
          detail={false}
          label={L10n.localize((s) => s.deck.resetFilters)}
          icon={<LuFilterX size={24} />}
          color={canResetFilters ? "primary" : undefined}
          onClick={onResetFilters}
        />
      )}
      {handleExport && isEditable && (
        <MenuButton
          detail={false}
          label={L10n.localize((s) => s.actions.export)}
          icon={<LuShare size={24} />}
          onClick={handleExport}
        />
      )}
      <MenuButton
        detail={false}
        label={L10n.localize((s) => s.deck.resetGrades)}
        icon={<LuTimerReset size={24} />}
        color="danger"
        onClick={handleResetGrades}
      />
      <MenuButton
        lines="none"
        detail={false}
        label={L10n.localize((s) => s.archive.verb)}
        icon={<LuArchive size={24} />}
        color="danger"
        onClick={handleArchiveDeck}
      />
    </ScreenMenu>
  );
}
