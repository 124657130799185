import { LanguageSelectorItem } from "fields/editors/languageSelectorItem";
import { IField } from "fields/fields";
import { humanLanguages, isBFF69, translationLanguages } from "fields/lang";
import { BFF69 } from "fields/langCodes";
import { IBaseTextField } from "fields/lib";
import { includesPipelineNodeType } from "fields/sources/lib";
import { IFieldRichText } from "fields/types/richText";
import React from "react";

export default function TextFieldLangSelector({
  field,
  onChange,
  langCode,
}: {
  field: IFieldRichText | IBaseTextField;
  onChange: (newField: IField) => void;
  langCode?: BFF69;
}) {
  function handleLanguageChange(code: string | undefined) {
    if (code && isBFF69(code)) {
      onChange({
        ...field,
        attributes: {
          ...field.attributes,
          lang: code,
        },
      });
    }
  }

  const langs = includesPipelineNodeType(field.source, "translation")
    ? translationLanguages()
    : humanLanguages();

  return (
    <LanguageSelectorItem
      field={field}
      langCode={langCode}
      langs={langs}
      onChange={handleLanguageChange}
    />
  );
}
