import React from "react";
import { IPDPContentProps } from "./pdpContent";
import { Products } from "./products";

export default function AndroidPDPContent({ userID, email, reason }: IPDPContentProps) {
  return (
    <Products
      reason={reason}
      onPurchase={() => null}
      products={[]}
      gateway={undefined}
      userID={userID}
      email={email}
    />
  );
}
