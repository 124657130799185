import React, { useState, useCallback } from "react";

interface IProps {
  onFileSelected: (file: File) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
  children: React.ReactNode;
}

export default function FileDropZone({ onFileSelected, disabled, style, children }: IProps) {
  const [isDragging, setIsDragging] = useState(false);

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // Set dragging state to true only if a file is being dragged over
    if (e.dataTransfer.items?.[0]?.kind === "file") {
      setIsDragging(true);
    }
  }, []);

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (!isDragging && e.dataTransfer.items?.[0]?.kind === "file") {
        setIsDragging(true);
      }
    },
    [isDragging],
  );

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        if (!disabled) {
          onFileSelected(e.dataTransfer.files[0]);
        }
      }
    },
    [onFileSelected, disabled],
  );

  return (
    <div
      style={{
        ...style,
        backgroundColor: disabled ? "transparent" : isDragging ? "#f0f0f0" : "transparent",
      }}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {children}
    </div>
  );
}
