import { dateToYYYYMMDD } from "@lib/dates";
import { Base64UUID, HexUUID, convertToBase64IfNecessary } from "@lib/ids";
import { IIDBResponse, Response } from "@models/response";
import EventBus from "eventBus";
import { superCache } from "./superCache";

export type IResponseHistory = Map<string, Map<Base64UUID, number>>;
export type IResponsesByDate = Map<string, number>;

let responseHistory: IResponseHistory | null = null;
let totalReviewsByDate: IResponsesByDate | null = null;
let totalReviews = 0;

let isInitialized = false;

function initializeResponseHistory() {
  if (!superCache.responsesLoaded) {
    // No data yet. Try again next time.
    return;
  }
  if (!isInitialized) {
    // console.time("fetchInitialData");
    fetchInitialData();
    // console.timeEnd("fetchInitialData");
    EventBus.on("responseInserted", updateResponseHistory);
    isInitialized = true;
  }
}

function fetchInitialData() {
  // console.time("Response.AllHistory()");
  const initialData = Response.AllHistory();
  // console.timeEnd("Response.AllHistory()");
  responseHistory = initialData;
  calculateTotalReviews(initialData);
}

function calculateTotalReviews(data: IResponseHistory) {
  const totals = new Map<string, number>();
  let total = 0;
  for (const [date, deckToCountMap] of data) {
    const dayTotal = Array.from(deckToCountMap.values()).reduce((acc, curr) => acc + curr, 0);
    totals.set(date, dayTotal);
    total += dayTotal;
  }
  totalReviewsByDate = totals;
  totalReviews = total;
}

function getGlobalResponseHistory() {
  if (!isInitialized) {
    initializeResponseHistory();
  }

  return responseHistory;
}

function getResponseHistoryForDeck(deckID: string) {
  if (!isInitialized) {
    initializeResponseHistory();
  }
  if (!responseHistory) {
    return null;
  }

  const specificDeckHistory: IResponsesByDate = new Map();

  const b64ID = convertToBase64IfNecessary(deckID as HexUUID | Base64UUID);

  for (const [date, deckMap] of responseHistory) {
    const count = deckMap.get(b64ID);
    if (count !== undefined) {
      specificDeckHistory.set(date, count);
    }
  }

  return specificDeckHistory;
}

function getGlobalTotalReviewsByDate() {
  if (!isInitialized) {
    initializeResponseHistory();
  }
  return totalReviewsByDate;
}

function getTotalReviewsByDateForDeck(deckID: string) {
  if (!isInitialized) {
    initializeResponseHistory();
  }

  if (!responseHistory) {
    return new Map<string, number>();
  }

  const b64ID = convertToBase64IfNecessary(deckID as HexUUID | Base64UUID);

  const specificTotalReviewsByDate: IResponsesByDate = new Map();
  for (const [date, deckMap] of responseHistory) {
    const count = deckMap.get(b64ID);
    if (count !== undefined) {
      specificTotalReviewsByDate.set(date, count);
    }
  }

  return specificTotalReviewsByDate;
}

function getGlobalTotalReviews() {
  if (!isInitialized) {
    initializeResponseHistory();
  }
  return totalReviews;
}

function getTotalReviewsForDeck(deckID: string) {
  if (!isInitialized) {
    initializeResponseHistory();
  }

  if (!responseHistory) {
    return 0;
  }

  const b64ID = convertToBase64IfNecessary(deckID as HexUUID | Base64UUID);

  let specificTotalReviews = 0;
  for (const deckMap of responseHistory.values()) {
    const count = deckMap.get(b64ID);
    if (count !== undefined) {
      specificTotalReviews += count;
    }
  }

  return specificTotalReviews;
}

function getToday(): string {
  const date = new Date();
  const formattedDate = dateToYYYYMMDD(date);
  return formattedDate;
}

function getGlobalTotalNumberReviewsToday() {
  if (!isInitialized) {
    initializeResponseHistory();
  }
  const today = getToday();
  const totalReviewsByDate = getGlobalTotalReviewsByDate();
  if (!totalReviewsByDate) {
    return 0;
  }
  return totalReviewsByDate.get(today) || 0;
}

function getTotalNumberReviewsTodayForDeck(deckID: string) {
  if (!isInitialized) {
    initializeResponseHistory();
  }
  const today = getToday();
  const totalReviewsByDate = getTotalReviewsByDateForDeck(deckID);
  if (!totalReviewsByDate) {
    return 0;
  }
  return totalReviewsByDate.get(today) || 0;
}

function updateResponseHistory(eventData: { response: IIDBResponse }) {
  if (!responseHistory) return;

  const date = eventData.response.created_at;
  const formattedDate = dateToYYYYMMDD(new Date(date));

  const deckID = superCache.knolIDToDeckID?.get(eventData.response.knol_id);
  if (!deckID) {
    return;
  }
  const deckMap = responseHistory.get(formattedDate) || new Map<Base64UUID, number>();
  deckMap.set(deckID, (deckMap.get(deckID) || 0) + 1);
  responseHistory.set(formattedDate, deckMap);
  calculateTotalReviews(responseHistory);
  EventBus.emit("responseHistoryUpdated");
}

export {
  initializeResponseHistory,
  getGlobalResponseHistory,
  getResponseHistoryForDeck,
  getGlobalTotalReviewsByDate,
  getTotalReviewsByDateForDeck,
  getGlobalTotalReviews,
  getTotalReviewsForDeck,
  getTotalNumberReviewsTodayForDeck,
  getGlobalTotalNumberReviewsToday,
};
