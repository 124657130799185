import FooterButton from "@components/footerButton";
import LoadingIndicator from "@components/loadingIndicator";
import MultiSelectSettingModal, { IMultiSelectOption } from "@components/multiSelectSettingModal";
import ScreenComponent from "@components/screen";
import { IonButton, IonItem, IonLabel, IonList, IonListHeader, IonNote } from "@ionic/react";
import { filterIterable, mapIterable } from "@lib/iterable";
import { Deck, IDeck } from "@models/deck";
import { DeckSetting, IDeckSettings, defaultDeckSettings } from "@models/deckSettings";
import DeckDownloadManager from "deckDownloadManager";
import EventBus from "eventBus";
import { isModernDeck } from "fields/magicLayout";
import { History } from "history";
import { useLens } from "hooks/data/useLens";
import useErrorAlert from "hooks/util/useErrorAlert";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback, useState } from "react";
import { ID } from "types/ID";
import usePDP from "unlimited/pdp";
import { checkReviewGate } from "unlimited/reviewGate";
import CardsPerSessionSettingItem from "./deck/cardsPerSessionSettingItem";
import FlipStabilizationSelectorItem from "./deck/flipStabilizationSelectorItem";
import FontSizeSettingItem from "./deck/fontSizeSettingItem";
import OrientationSelectorItem from "./deck/orientationSelectorItem";
import ReviewModeSettingItem from "./deck/reviewModeSettingItem";
import TimerSettingItem from "./deck/timerSettingItem";
import OmniReviewDeckSelectorSettingItem, {
  OmniReviewDeckSelectionValue,
  omniReviewDeckSelectionValues,
} from "./review/omniReviewDeckSelectorSettingItem";

const omniReviewSettingsLocalStorageKey = "omniReviewSettings";
export const omniReviewRecencyFilterLocalStorageKey = "omniReviewRecencyFilter";

export function loadRecentDecks(deckFilter: OmniReviewDeckSelectionValue): IDeck[] {
  const allDecks = Deck.getAllDecksInFolders();

  const now = new Date();
  const oneWeekAgo = new Date(now.setDate(now.getDate() - 7));
  const oneMonthAgo = new Date(now.setMonth(now.getMonth() - 1));
  const threeMonthsAgo = new Date(now.setMonth(now.getMonth() - 3));
  let lastReviewedCutoff: Date | undefined = undefined;
  switch (deckFilter) {
    case "all":
      lastReviewedCutoff = undefined;
      break;
    case "1wk":
      lastReviewedCutoff = oneWeekAgo;
      break;
    case "1mo":
      lastReviewedCutoff = oneMonthAgo;
      break;
    case "3mo":
      lastReviewedCutoff = threeMonthsAgo;
      break;
  }

  const filteredDecks = allDecks.filter((deck) => {
    if (lastReviewedCutoff === undefined) {
      return true;
    }
    const lastReviewedDate = deck.last_reviewed_at;
    if (lastReviewedDate === undefined) {
      return false;
    }
    return lastReviewedDate >= lastReviewedCutoff;
  });
  return filteredDecks;
}

export async function downloadDecks(decks: Iterable<IDeck>) {
  // Download if necessary.
  const nonLocalDecks = Array.from(filterIterable(decks, (deck) => !deck.local));
  if (nonLocalDecks.length > 0) {
    EventBus.emit("omniReviewDeckDownloaded", {
      numerator: 0,
      denominator: nonLocalDecks.length,
    });
    for (let i = 0; i < nonLocalDecks.length; i++) {
      const deck = nonLocalDecks[i];
      const uri = `/decks/${deck.id}`;
      await DeckDownloadManager.addDownload(uri);
      EventBus.emit("omniReviewDeckDownloaded", {
        numerator: i,
        denominator: nonLocalDecks.length,
      });
    }
  }
}

const omniReviewDeckIDBlacklistKey = "omniReviewDeckIDBlacklist";
export function loadOmniReviewDeckIDBlacklist(): Set<ID> {
  try {
    const json = localStorage.getItem(omniReviewDeckIDBlacklistKey);
    const ids = json ? JSON.parse(json) : [];
    if (!(ids instanceof Array) || !ids.every((id) => typeof id === "string")) {
      return new Set();
    }
    return new Set(ids);
  } catch {
    return new Set();
  }
}

export function loadPersistedOmniReviewSettings(): IDeckSettings {
  try {
    const json = localStorage.getItem(omniReviewSettingsLocalStorageKey);
    const settings = json ? JSON.parse(json) : defaultDeckSettings();
    return settings;
  } catch {
    return defaultDeckSettings();
  }
}

const defaultOmniReviewFilter: OmniReviewDeckSelectionValue = "all";
export function loadPersistedOmniReviewFilter(): OmniReviewDeckSelectionValue {
  const persisted = localStorage.getItem(omniReviewRecencyFilterLocalStorageKey) ?? "";
  if (!omniReviewDeckSelectionValues.includes(persisted as OmniReviewDeckSelectionValue)) {
    return defaultOmniReviewFilter;
  }
  return persisted as OmniReviewDeckSelectionValue;
}

interface IProps {
  history: History | undefined;
  dismiss: () => void;
}
function OmniReviewModal(props: IProps): JSX.Element {
  const {
    decks,
    unfilteredDecks,
    knols,
    settings: settingsRef,
  } = useLens("omni", { listenWhenOffscreen: true });
  const settings = settingsRef.current;

  const [loading, setLoading] = useState(false);

  const defaultRecency = loadPersistedOmniReviewFilter();
  const [deckFilter, setDeckFilter] = useState<OmniReviewDeckSelectionValue>(defaultRecency);
  const handleDeckFilterSet = useCallback((recency: OmniReviewDeckSelectionValue) => {
    localStorage.setItem(omniReviewRecencyFilterLocalStorageKey, recency);
    setDeckFilter(recency);
    EventBus.emit("omniReviewRecencyChanged");
  }, []);

  function useSettingChangeHandler<T extends DeckSetting>(setting: T) {
    return useCallback(
      async (value: IDeckSettings[T]) => {
        const newSettings = { ...settings, [setting]: value };
        localStorage.setItem(omniReviewSettingsLocalStorageKey, JSON.stringify(newSettings));
        EventBus.emit("omniReviewSettingsChanged");
      },
      [settings, setting],
    );
  }
  const handleReviewModeChange = useSettingChangeHandler("reviewMode");
  const handleTimerSettingsChanged = useSettingChangeHandler("reviewTimer");
  const handleCardFontSizeChanged = useSettingChangeHandler("cardFontSize");
  const handleCardsPerReviewChange = useSettingChangeHandler("cardsPerReview");
  const handleCardOrientationSelected = useSettingChangeHandler("cardOrientation");
  const handleFlipStabilizationSelected = useSettingChangeHandler("flipStabilization");

  const deckIDBlacklist = loadOmniReviewDeckIDBlacklist();
  const deckSelectOptions: IMultiSelectOption<ID>[] = Array.from(
    mapIterable(unfilteredDecks.values(), (deck) => {
      return {
        name: Deck.displayName(deck),
        val: deck.id,
        selected: !deckIDBlacklist.has(deck.id),
      };
    }),
  );
  const handleDeckSelectChange = (deckID: ID, selected: boolean) => {
    if (selected) {
      deckIDBlacklist.delete(deckID);
    } else {
      deckIDBlacklist.add(deckID);
    }
    const json = JSON.stringify(Array.from(deckIDBlacklist));
    localStorage.setItem(omniReviewDeckIDBlacklistKey, json);
    EventBus.emit("omniReviewRecencyChanged");
  };
  const [showDecksModal] = useIonCardModalWithDismiss(MultiSelectSettingModal<ID>, {
    options: deckSelectOptions,
    title: L10n.localize((s) => s.deck.plural),
    onChange: handleDeckSelectChange,
    // description: L10n.localize((s) => s.deck.plural),
  });

  const [showFatalPDP] = usePDP({
    source: "reviewgate_gate",
    reason: () => L10n.localize((s) => s.account.reviewGateGateReason),
  });

  const [showDownloadError] = useErrorAlert({ code: "DOWNLOADING_DECK" });
  const handleReview = useCallback(async () => {
    if (!decks) {
      return;
    }

    const gate = await checkReviewGate();
    if (gate?.level === "gate") {
      showFatalPDP({ context: gate });
      return;
    }

    try {
      setLoading(true);
      await downloadDecks(decks.values());
    } catch (err) {
      showDownloadError(err);
    } finally {
      setLoading(false);
    }

    props.dismiss();
    props.history?.push("/review");
  }, [props.dismiss, props.history, decks, showDownloadError, showFatalPDP]);

  const allDecksModern = Array.from(decks?.values() ?? []).every(isModernDeck);
  const noDecks = unfilteredDecks.size < 1;

  return (
    <ScreenComponent
      slug="OmniReview"
      noBigTitle
      title={L10n.localize((s) => s.review.omni)}
      onBack={props.dismiss}
      leftButton={
        <IonButton onClick={props.dismiss}>{L10n.localize((s) => s.actions.close)}</IonButton>
      }
      bottomButton={
        <FooterButton
          onClick={handleReview}
          disabled={!decks || noDecks}
          isTheaterMode
          style={{ margin: 0, padding: "0 2px" }}
        >
          {L10n.localize((s) => s.review.singular)}
        </FooterButton>
      }
      content={
        <>
          {loading ? <LoadingIndicator eventName="omniReviewDeckDownloaded" /> : undefined}
          <IonList>
            <IonListHeader>{L10n.localize((s) => s.deck.plural)}</IonListHeader>
            <OmniReviewDeckSelectorSettingItem value={deckFilter} onChange={handleDeckFilterSet} />
            <IonItem
              button={!noDecks}
              detail={!noDecks}
              onClick={noDecks ? undefined : showDecksModal}
            >
              <IonLabel>{L10n.localize((s) => s.deck.plural)}</IonLabel>
              <IonNote>
                {noDecks
                  ? L10n.localize((s) => s.general.none)
                  : `${decks.size}/${unfilteredDecks.size}`}
              </IonNote>
            </IonItem>
          </IonList>
          <IonList>
            <IonListHeader>{L10n.localize((s) => s.general.settings)}</IonListHeader>
            <CardsPerSessionSettingItem
              numTotalCards={knols?.size ?? 0}
              cardsPerReview={settings.cardsPerReview}
              onChange={handleCardsPerReviewChange}
            />
            <FontSizeSettingItem
              fontSizePx={settings.cardFontSize}
              onChange={handleCardFontSizeChanged}
            />
            <TimerSettingItem
              timerSettings={settings.reviewTimer}
              onChange={handleTimerSettingsChanged}
            />
            <ReviewModeSettingItem
              reviewMode={settings.reviewMode}
              cardsPerReview={settings.cardsPerReview}
              setReviewMode={handleReviewModeChange}
            />
            {allDecksModern ? (
              <>
                <OrientationSelectorItem
                  orientation={settings.cardOrientation}
                  onChange={handleCardOrientationSelected}
                />
                <FlipStabilizationSelectorItem
                  setting={settings.flipStabilization}
                  onChange={handleFlipStabilizationSelected}
                />
              </>
            ) : undefined}
          </IonList>
        </>
      }
    />
  );
}
export default React.memo(OmniReviewModal);
