import { showPrompt } from "@components/prompt";
import SortMenuButton from "@components/selectOptionButton";
import {
  stringWithoutSubfolderMagicSeparatorRegex,
  subFolderMagicSeparator,
} from "@data/lib/folders";
import { syncOperationsAndRefetchRemoteDecks } from "@data/remoteDecks";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  RefresherEventDetail,
} from "@ionic/react";
import { Deck, IDeck } from "@models/deck";
import {
  archiveFolder,
  basename,
  dirname,
  formFullFolderPath,
  renameFolder,
  trimFolderPrefix,
} from "@models/folder";
import { filterIterable } from "@screens/deck/cards/browseCardsContent";
import { setDefaultFolder } from "@screens/deckCreate/defaultFolder";
import DeckListItem from "@screens/decks/deckListItem";
import sortRows from "@screens/decks/sortRows";
import { SortOptions, sortMenuOptions, sortOptionStringFromKey } from "@screens/decks/types";
import ScreenComponent from "components/screen";
import DeckDownloadManager from "deckDownloadManager";
import EventBus from "eventBus";
import { isModernDeck } from "fields/magicLayout";
import { History } from "history";
import { IDeckOrFolderRow, genDeckListWithFolders } from "hooks/data/deckList";
import { useFolders } from "hooks/data/folders";
import { useLens } from "hooks/data/useLens";
import useErrorAlert from "hooks/util/useErrorAlert";
import { cloudDownloadOutline } from "ionicons/icons";
import L10n from "localization";
import React, { useCallback, useMemo } from "react";
import { getDeckSortOrder, setDeckSortOrder } from "settings";
import { ID } from "types/ID";
import { Menu } from "./menu";
import EmptyFolder from "./emptyFolder";
import SettingsSection from "./settingsSection";

function isInExactFolder(row: IDeckOrFolderRow, folder: string): boolean {
  if (row.type === "deck") {
    return (row.deck.tags ?? []).some((tag) => tag === folder);
  }
  // Otherwise it's a folder.
  return dirname(row.name) === folder;
}

export function descendsFrom(deck: IDeck, folder: string): boolean {
  return (deck.tags || []).some(
    (tag) => tag === folder || tag.startsWith(folder + subFolderMagicSeparator),
  );
}

interface IProps {
  folder: string;
  onCreateDeck: () => void;
  history: History;
}
export default function FolderOverviewScreen(props: IProps): JSX.Element {
  const { folder, onCreateDeck, history } = props;
  const cacheSpec = useMemo(() => ({ folder }), [folder]);
  const { decks: deckMap, knols, settings, loading: decksLoading } = useLens(cacheSpec);

  const handleCreateDeck = useCallback(() => {
    setDefaultFolder(folder);
    onCreateDeck();
  }, [folder, onCreateDeck]);

  const numTotalCards = knols?.size;

  const [sortOrder, setSortOrder] = React.useState<SortOptions>(getDeckSortOrder());
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [showMoveError] = useErrorAlert({ code: "MOVING_DECK" });
  async function removeFromFolder(deckIDs: ID[]) {
    try {
      await Promise.all(
        deckIDs.map((deckID) => {
          return Deck.deleteTag(deckID, folder);
        }),
      );
    } catch (err) {
      showMoveError(err);
    }
  }

  const handleSort = useCallback((value: SortOptions) => {
    if (value) {
      setDeckSortOrder(value).finally(() => {
        setSortOrder(value);
      });
    }
  }, []);

  React.useEffect(() => {
    EventBus.on("deckSortUpdated", handleSort);
    return () => {
      EventBus.off("deckSortUpdated", handleSort);
    };
  }, [handleSort]);

  async function moveDeckToThisFolder(deck: IDeck) {
    await Deck.moveToFolder(deck, folder);
  }

  async function addToFolder(decks: IDeck[]) {
    try {
      await Promise.all(decks.map(moveDeckToThisFolder));
    } catch (err) {
      showMoveError(err);
    }
  }

  const currentFolder = basename(folder);
  const parentFolder = basename(dirname(folder));

  async function handleRenameFolder() {
    showPrompt({
      promptType: "input",
      title: L10n.localize((s) => s.folder.renamePrompt),
      autoCapitalize: "off",
      default: currentFolder,
      validationRegex: stringWithoutSubfolderMagicSeparatorRegex,
      callback: async (newName) => {
        if (!newName || newName === folder) {
          return;
        }
        const newPath = formFullFolderPath(newName, parentFolder);
        await renameFolder(folder, newPath);
        history.replace(`/folders/${encodeURIComponent(newPath)}`);
      },
    });
  }

  const [showError] = useErrorAlert({ code: "ARCHIVING_FOLDER" });
  async function handleArchiveFolder() {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.folder.confirmArchive),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.archive.verb),
      callback: async () => {
        try {
          await archiveFolder(folder);
          const pathname = history.location.pathname;
          const upOneLevelPath = pathname.substring(
            0,
            pathname.lastIndexOf(subFolderMagicSeparator),
          );
          if (!upOneLevelPath || upOneLevelPath === "/folders") {
            history.replace("/home");
          } else {
            history.replace(upOneLevelPath);
          }
        } catch (err) {
          showError(err);
        }
      },
    });
  }

  // Compute deck list.
  const folders = useFolders(folder);
  const rowData = genDeckListWithFolders(
    Array.from(filterIterable(deckMap?.values() ?? [], (deck) => !Deck.isArchived(deck))),
    folders,
  );

  const matchingRows = rowData.filter((row) => isInExactFolder(row, folder));

  const nonLocalFolderDecks = rowData.filter((row) => row.type === "deck" && !row.deck.local);

  // Downloads all decks in this folder and sub-folders.
  async function downloadFolder() {
    setIsDownloading(true);
    for (const row of nonLocalFolderDecks) {
      if (row.type === "deck") {
        const uri = `/decks/${row.deck.id}`;
        await DeckDownloadManager.addDownload(uri);
      }
    }
    setIsDownloading(false);
  }

  const [showRefreshErrorAlert] = useErrorAlert({ code: "REFETCHING_DECKS" });
  const handleRefresh = useCallback(
    (event: CustomEvent<RefresherEventDetail>) => {
      syncOperationsAndRefetchRemoteDecks()
        .catch((err) => showRefreshErrorAlert(err))
        .finally(() => {
          event.detail.complete();
        });
    },
    [showRefreshErrorAlert],
  );

  // Strip current folder prefix from each deck's name.
  const sortedRowsSansFolderPrefix: IDeckOrFolderRow[] = matchingRows.map((row) => {
    const trimmed = trimFolderPrefix(folder, row.name);
    switch (row.type) {
      case "deck":
        return {
          ...row,
          deck: {
            ...row.deck,
            name: trimmed,
          },
          name: trimmed,
        };
      case "folder":
        return {
          ...row,
          name: trimmed,
        };
    }
  });

  const sortedRows = sortRows(sortOrder, sortedRowsSansFolderPrefix);

  const decks: IDeck[] = [];
  for (const row of sortedRowsSansFolderPrefix) {
    if (row.type === "deck") {
      decks.push(row.deck);
    }
  }

  const allDecksModern = Array.from(decks?.values() ?? []).every(isModernDeck);

  const loading = decksLoading;
  const empty = !loading && sortedRows.length < 1;

  return (
    <ScreenComponent
      title={currentFolder}
      slug="Folder"
      defaultBackLink="/home"
      backText={parentFolder || L10n.localize((s) => s.deck.plural)}
      contentColor={empty ? "light" : undefined}
      content={
        <>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          {empty ? (
            <EmptyFolder addToFolder={addToFolder} createDeck={handleCreateDeck} />
          ) : loading ? undefined : (
            <>
              <IonListHeader>
                <IonLabel style={{ margin: 0 }}>{L10n.localize((s) => s.deck.plural)}</IonLabel>
                {isDownloading ? (
                  <IonSpinner name="crescent" />
                ) : nonLocalFolderDecks.length > 0 ? (
                  <IonButton onClick={downloadFolder}>
                    <IonIcon icon={cloudDownloadOutline} />
                  </IonButton>
                ) : undefined}
                <SortMenuButton
                  options={sortMenuOptions()}
                  value={sortOrder}
                  onSelect={handleSort}
                  style={{ margin: "4px" }}
                >
                  {sortOptionStringFromKey(sortOrder)}
                </SortMenuButton>
              </IonListHeader>
              <IonList>
                {sortedRows.map((r) => (
                  <DeckListItem
                    key={r.type === "deck" ? r.deck.id : r.name}
                    row={r}
                    showFolderPrefix={false}
                  />
                ))}
              </IonList>
            </>
          )}
          {!loading && !empty ? (
            <SettingsSection
              folder={folder}
              settings={settings.current}
              numTotalCards={numTotalCards ?? 0}
              allDecksInFolderAreModern={allDecksModern}
            />
          ) : undefined}
        </>
      }
      rightButton={
        <Menu
          folder={folder}
          decks={decks}
          createDeck={handleCreateDeck}
          removeFromFolder={removeFromFolder}
          addToFolder={addToFolder}
          archiveFolder={handleArchiveFolder}
          renameFolder={handleRenameFolder}
        />
      }
    />
  );
}
