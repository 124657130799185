import { StepLogger } from "analytics/funnel";
import { History } from "history";
import L10n from "localization";
import React from "react";
import SingleSelectNav, { IPromptOption } from "./singleSelectNav";
import GoalTestVectorNav from "./goalTestVectorNav";

interface IProps {
  dismiss: () => Promise<void>;
  onAddToInbox: () => void;
  history: History;
  logStep: StepLogger;
}
export default function GoalTestNav(props: IProps) {
  const opts: IPromptOption[] = [
    { name: "GMAT", val: "GMAT" },
    { name: "LSAT", val: "LSAT" },
    { name: "MCAT", val: "MCAT" },
    { name: "USMLE", val: "USMLE" },
    { name: "NCLEX", val: "NCLEX" },
    { name: "IELTS", val: "IELTS" },
    { name: "TOEFL", val: "TOEFL" },
    { name: "CFA", val: "CFA" },
  ].sort((o1, o2) => o1.name.localeCompare(o2.name));
  return (
    <SingleSelectNav
      title={L10n.localize((s) => s.onboarding.testNavTitle)}
      description={L10n.localize((s) => s.onboarding.testNavDescription)}
      options={opts}
      other
      dismiss={props.dismiss}
      history={props.history}
      onNav={(opt) => {
        return (
          <GoalTestVectorNav
            dismiss={props.dismiss}
            history={props.history}
            onAddToInbox={props.onAddToInbox}
            logStep={props.logStep}
            test={opt.val}
          />
        );
      }}
      searchable
    />
  );
}
