import Modal from "@components/modal";
import { AlertButton } from "@ionic/core";
import EventBus from "eventBus";
import useAlert from "hooks/util/useAlert";
import L10n from "localization";
import React from "react";

export interface IPromptOptions {
  title?: string;
  prompt?: string;
  inputType?: "password" | "textarea" | "text" | "email";
  type?: string;
  accept?: string;
  cancel?: string;
  promptType: "confirm" | "alert" | "custom" | "input" | "dangerousConfirm";
  dangerousConfirmOkButtonText?: string;
  callback?: (result: string | null) => void;
  cancelCallback?: () => void;
  autoCapitalize?: "off" | "none" | "on" | "sentences" | "words" | "characters";
  content?: React.ReactElement;
  validationRegex?: RegExp;
  onHide?: () => void;
  suggestions?: string[];
  default?: string;
  useChips?: boolean;
  hasPadding?: boolean;
}

export const promptShowEventName = "prompt:show";
export const promptHideEventName = "prompt:hide";

export function showPrompt(opts: IPromptOptions): void {
  EventBus.emit(promptShowEventName, opts);
}

export function hidePrompt(): void {
  EventBus.emit(promptHideEventName);
}

// ScreenModal controls the singleton Modal's visibility in response to global show/hide events.
// There's probably a better name for it. Just named this way because it was ripped out of Screen, and wraps a Modal.
export function ScreenModal(): JSX.Element {
  const [state, setState] = React.useState<{ visible: boolean; opts: IPromptOptions | null }>({
    visible: false,
    opts: null,
  });

  const [showAlert, hideAlert] = useAlert();

  const hidePromptModal = React.useCallback(() => {
    setState({ visible: false, opts: null });
  }, []);

  React.useEffect(() => {
    async function showPromptModal(opts: IPromptOptions) {
      switch (opts.promptType) {
        case "alert": {
          const buttons: AlertButton[] = [{ text: L10n.localize((s) => s.actions.ok) }];
          await hideAlert(); // Must hide previous alert in case this is called in a nested manner.
          await showAlert({
            header: opts.title,
            message: opts.prompt,
            buttons: buttons,
            onDidDismiss: () => opts.callback?.(null),
          });
          return;
        }
        case "confirm": {
          const buttons: AlertButton[] = [
            {
              text:
                opts.dangerousConfirmOkButtonText ||
                opts.accept ||
                L10n.localize((s) => s.actions.ok),
              handler: () => opts.callback?.(null),
            },
            {
              text: opts.cancel || L10n.localize((s) => s.actions.cancel),
              handler: () => opts.cancelCallback?.(),
            },
          ];
          showAlert({
            header: opts.title || L10n.localize((s) => s.actions.confirm),
            message: opts.prompt,
            buttons: buttons,
          });
          return;
        }
        case "dangerousConfirm": {
          opts.promptType = "confirm";
          const okButton: AlertButton = {
            text: opts.dangerousConfirmOkButtonText || L10n.localize((s) => s.actions.ok),
            role: "destructive",
            handler: () => opts.callback?.(null),
            cssClass: "dangerous-confirm",
          };

          const cancelButton: AlertButton = {
            text: opts.cancel || L10n.localize((s) => s.actions.cancel),
          };

          showAlert({
            header: opts.title || L10n.localize((s) => s.actions.confirm),
            message: opts.prompt,
            buttons: [cancelButton, okButton],
          });
          return;
        }
        default: {
          setState({ opts, visible: true });
        }
      }
    }

    EventBus.on(promptShowEventName, showPromptModal);
    EventBus.on(promptHideEventName, hidePromptModal);

    return () => {
      EventBus.off(promptShowEventName, showPromptModal);
      EventBus.off(promptHideEventName, hidePromptModal);
    };
  }, [hidePromptModal, showAlert, hideAlert]);

  const { visible, opts } = state;

  return <Modal isOpen={visible} onHide={hidePromptModal} promptProps={opts} />;
}
