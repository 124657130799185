import { IDBAppSchema, IQueuedBlobSansDeckID } from "@data/idb";
import BlobStore from "@data/idb/blobStore";
import { IKnolValue, Knol } from "@models/knol";
import { IBaseOperation } from "@models/operation";
import { loadBlobIDsForCard } from "@screens/review/reviewSessionBlobs";
import EventBus from "eventBus";
import { IDBPTransaction, StoreNames } from "idb";
import * as Sentry from "@sentry/browser";
import { handleIndexedDBRefreshRequiredErrorIfNecessary } from "errors/indexedDBRefreshRequired";

export interface IKnolInsertOperationObjectParameters {
  knol_id: string;
  deck_id: string;
  knol_values?: IKnolValue[];
  values: Record<string, string>;
  knol_tags?: string[];
  created_at?: string;
  modified_at?: string;
  response_type_id?: string;
}

export interface IKnolInsertOperation extends IBaseOperation {
  object_type: "knol";
  type: "INSERT";
  object_parameters: IKnolInsertOperationObjectParameters;
}

export interface IKnolUpdateOperation extends IBaseOperation {
  object_type: "knol";
  type: "UPDATE";
  object_parameters: {
    knol_id: string;
    deck_id: string;
    values: Record<string, string>;
    knol_values?: IKnolValue[];
    knol_tags?: string[];
  };
}

export interface IKnolDeleteOperation extends IBaseOperation {
  object_type: "knol";
  type: "DELETE";
  object_parameters: {
    knol_id: string;
    deck_id: string;
  };
}

export interface IKnolValueBlob {
  id: string;
  type: string;
  value: string;
}

export type KnolOperation = IKnolInsertOperation | IKnolUpdateOperation | IKnolDeleteOperation;

const INSERT = async (
  operation: IKnolInsertOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const params = operation.object_parameters;
  const store = tx.objectStore("knols");
  const knol = {
    id: params.knol_id,
    deck_id: params.deck_id,
    values: params.values ?? {},
    tags: params.knol_tags,
    created_at: params.created_at ? new Date(params.created_at) : undefined,
    modified_at: params.modified_at ? new Date(params.modified_at) : undefined,
  };
  // Support legacy operations.
  for (const kv of operation.object_parameters.knol_values ?? []) {
    knol.values[kv.key] = kv.value;
  }

  await store.put(knol);
  await Knol.UpdateGradesForAllLayouts(tx, knol.id, knol.deck_id);
  const decksStore = tx.objectStore("decks");
  const deck = await decksStore.get(params.deck_id);

  let queuedBlobs: IQueuedBlobSansDeckID[] = [];
  if (deck) {
    deck.modified_at = new Date(operation.timestamp);
    await decksStore.put(deck);

    // Enqueue blobs for download.
    const knolBlobContent = await loadBlobIDsForCard(deck.id, knol.values, deck.config?.fields);
    queuedBlobs = knolBlobContent.queuedBlobs;
  } else {
    console.error("Deck not found with id", params.deck_id);
  }
  return async () => {
    try {
      await BlobStore.enqueueBlobsForDownload(params.deck_id, queuedBlobs);
    } catch (err) {
      if (!(await handleIndexedDBRefreshRequiredErrorIfNecessary(err))) {
        Sentry.captureException(err, { tags: { operationName: "knol", operationType: "INSERT" } });
      }
    }
    BlobStore.downloadQueuedBlobs(params.deck_id).catch(async (err) => {
      if (!(await handleIndexedDBRefreshRequiredErrorIfNecessary(err))) {
        Sentry.captureException(err, { tags: { operationName: "knol", operationType: "INSERT" } });
      }
    });
    EventBus.emit("knolUpdated", { knolID: knol.id, deckID: operation.object_parameters.deck_id });
  };
};

const UPDATE = async (
  operation: IKnolUpdateOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const knolID = operation.object_parameters.knol_id;
  const store = tx.objectStore("knols");
  const knol = await store.get(knolID);
  if (!knol) {
    console.error("Knol not found with the id ", knolID);
    return;
  }
  if (!knol.values) {
    knol.values = {};
  }
  // Support legacy operations.
  for (const kv of operation.object_parameters.knol_values ?? []) {
    knol.values[kv.key] = kv.value;
  }
  // Modern operation form.
  for (const [k, v] of Object.entries(operation.object_parameters.values)) {
    knol.values[k] = v;
  }

  knol.tags = operation.object_parameters.knol_tags ?? knol.tags;

  knol.modified_at = new Date(operation.timestamp); // Set the modified_at date to the operation's timestamp
  await store.put(knol);

  // TODO: handle grades and deck modification updates

  // Enqueue blobs for download.
  let queuedBlobs: IQueuedBlobSansDeckID[] = [];
  const decksStore = tx.objectStore("decks");
  const deck = await decksStore.get(knol.deck_id);
  if (deck) {
    const knolBlobContent = await loadBlobIDsForCard(deck.id, knol.values, deck.config?.fields);
    queuedBlobs = knolBlobContent.queuedBlobs;
  }

  return async () => {
    try {
      await BlobStore.enqueueBlobsForDownload(knol.deck_id, queuedBlobs);
    } catch (err) {
      if (!(await handleIndexedDBRefreshRequiredErrorIfNecessary(err))) {
        Sentry.captureException(err, { tags: { operationName: "knol", operationType: "UPDATE" } });
      }
    }
    BlobStore.downloadQueuedBlobs(knol.deck_id).catch(async (err) => {
      if (!(await handleIndexedDBRefreshRequiredErrorIfNecessary(err))) {
        Sentry.captureException(err, { tags: { operationName: "knol", operationType: "UPDATE" } });
      }
    });
    EventBus.emit("knolUpdated", { knolID: knolID, deckID: operation.object_parameters.deck_id });
  };
};

const DELETE = async (
  operation: IKnolDeleteOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const knolID = operation.object_parameters.knol_id;
  const deckID = operation.object_parameters.deck_id;

  const knolsStore = tx.objectStore("knols");
  await knolsStore.delete(knolID);

  const decksStore = tx.objectStore("decks");
  const deck = await decksStore.get(deckID);

  if (deck) {
    deck.modified_at = new Date(operation.timestamp);
    await decksStore.put(deck);
  } else {
    console.error("Deck not found with id", deckID);
  }
  // TODO: Delete related objects such as grades, blobs, etc.
  return () => {
    EventBus.emit("knolUpdated", { knolID: knolID, deckID: operation.object_parameters.deck_id });
  };
};

export { DELETE, INSERT, UPDATE };
