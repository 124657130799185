import { responseDataFromTimeAdjustedScore } from "@core/timeAdjustedScore";
import { IDBAppSchema } from "@data/idb";
import { Base64UUID, HexUUID, convertToBase64IfNecessary, convertToHexIfNecessary } from "@lib/ids";
import { dateToUTCMillis } from "@lib/timestamps";
import { IBaseOperation } from "@models/operation";
import { IIDBResponse } from "@models/response";
import EventBus from "eventBus";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import { TResponseValue } from "globals";
import { IDBPTransaction, StoreNames } from "idb";

export interface IResponseData {
  type?: "FHGE"; // Undefined means the standard FAIL/HARD/GOOD/EASY/AUTO response, indicated by a val of 0/1/2/3/-1.
}

export interface IResponseDataFHGE extends IResponseData {
  type?: "FHGE";
  input: TResponseValue;
}

export interface IResponseObjectParameters {
  device_id?: string;
  created_at: string;
  deck_id: string;
  duration_ms: number;
  knol_id: string;
  layout_id: string;
  data?: IResponseData;
  score: number;
  score_mean: number | null;
  score_standard_deviation: number | null;
  last_response_at: string | null;
}

export interface IResponseInsertOperation extends IBaseOperation {
  object_type: "response";
  type: "INSERT";
  object_parameters: IResponseObjectParameters;
}

export type ResponseOperation = IResponseInsertOperation;

export const INSERT = async (
  op: IResponseInsertOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const { knol_id, layout_id, deck_id } = op.object_parameters;

  let r: IIDBResponse;
  if (knol_id && deck_id) {
    // Check for knol_id and deck_id presence because my user has at least one response with a null deck_id.
    const store = tx.objectStore("responsesV2");
    const kdStore = tx.objectStore("knols_decks");
    const knolID = convertToBase64IfNecessary(knol_id as HexUUID);
    const deckID = convertToBase64IfNecessary(deck_id as HexUUID);

    const score = op.object_parameters.score;
    const data = op.object_parameters.data ?? responseDataFromTimeAdjustedScore(score);

    r = {
      knol_id: knolID,
      duration_ms: op.object_parameters.duration_ms,
      created_at: dateToUTCMillis(new Date(op.object_parameters.created_at)),
      score,
      data,
    };
    if (layout_id !== MAGIC_LAYOUT_ID) {
      r.layout_id = convertToBase64IfNecessary(layout_id as HexUUID);
    }
    await store.put(r);
    await kdStore.put({ knolID, deckID });
  }

  // Server could return these IDs as either base64 or hex.
  // It's also possible for e.g. the deck ID to be missing (in ancient responses). That's what the ternary operator checks are about.
  const deckIDHex = deck_id ? convertToHexIfNecessary(deck_id as Base64UUID | HexUUID) : deck_id;

  const deckStore = tx.objectStore("decks");
  const deck = await deckStore.get(deckIDHex);
  if (deck) {
    const last_reviewed_at = new Date(op.object_parameters.created_at);
    await deckStore.put({ ...deck, last_reviewed_at });
  }
  return () => {
    if (r) {
      EventBus.emit("responseInserted", { response: r });
    }
    EventBus.emit("deckUpdated", { ID: deckIDHex });
  };
};
