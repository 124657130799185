import {
  IonItem,
  IonLabel,
  IonList,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from "@ionic/react";
import Disconnected from "components/disconnected";
import ScreenComponent from "components/screen";
import EventBus from "eventBus";
import L10n from "localization";
import { usePushNotifPermissionRequest } from "pushnotifs";
import React from "react";
import { useHistory } from "react-router-dom";
import StudyGroupListItem from "./studyGroups/groupListItem";
import { useNewGroupPrompt } from "./studyGroups/newModal";
import NewStudyGroupButton from "./studyGroups/newStudyGroupButton";
import PendingRequestList from "./studyGroups/pendingRequestList";
import { IGroupHeader } from "./studyGroups/types";
import useStudyGroups, { usePendingGroupJoinRequests } from "./studyGroups/useStudyGroups";

interface IStudyGroupsViewProps {
  groups: IGroupHeader[];
  loadingGroupsState: { loading: boolean; error: boolean };
}
function StudyGroupsView(props: IStudyGroupsViewProps) {
  const { groups, loadingGroupsState } = props;

  const isEmpty = !loadingGroupsState.loading && !loadingGroupsState.error && groups.length < 1;

  const sortedGroups = groups.sort((g1, g2) => (g1.created_at < g2.created_at ? 1 : -1));

  const presentNewStudyGroupPrompt = useNewGroupPrompt();
  const history = useHistory();
  async function handleNewStudyGroup() {
    const resp = await presentNewStudyGroupPrompt();
    if (resp) {
      history.push(`/groups/${resp.id}`);
    }
  }

  return (
    <>
      {loadingGroupsState.loading && <IonProgressBar type="indeterminate" />}
      {isEmpty && (
        <IonList inset>
          <IonItem color="light" button detail onClick={handleNewStudyGroup}>
            <IonLabel>
              <h1>{L10n.localize((s) => s.groups.new)}</h1>
              <p>{L10n.localize((s) => s.sharing.studyGroupsDescription)}</p>
            </IonLabel>
          </IonItem>
        </IonList>
      )}
      {loadingGroupsState.error && <Disconnected />}
      {sortedGroups.map((g) => (
        <StudyGroupListItem key={g.id} header={g} />
      ))}
    </>
  );
}

interface IStudyGroupsBodyProps {
  groups: IGroupHeader[];
  loadingState: {
    loading: boolean;
    error: boolean;
  };
}
function StudyGroupsBody(props: IStudyGroupsBodyProps): JSX.Element {
  const { groups, loadingState } = props;
  const [pendingRequestsVal, fetchPendingRequests] = usePendingGroupJoinRequests();

  return (
    <>
      <StudyGroupsView groups={groups} loadingGroupsState={loadingState} />
      <PendingRequestList
        pendingRequestsVal={pendingRequestsVal}
        fetchPendingRequests={fetchPendingRequests}
      />
    </>
  );
}

export function StudyGroupsSection(): JSX.Element {
  const [groups, loadingState, refetch] = useStudyGroups();
  usePushNotifPermissionRequest();

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    refetch().finally(() => {
      event.detail.complete();
    });
  }

  React.useEffect(() => {
    EventBus.on("leftStudyGroup", refetch);
    EventBus.on("deletedStudyGroup", refetch);
    EventBus.on("studyGroupAdded", refetch);
    return () => {
      EventBus.off("leftStudyGroup", refetch);
      EventBus.off("deletedStudyGroup", refetch);
      EventBus.off("studyGroupAdded", refetch);
    };
  }, [refetch]);

  return (
    <>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent />
      </IonRefresher>
      <StudyGroupsBody groups={groups} loadingState={loadingState} />
    </>
  );
}

export function StudyGroupsScreen(): JSX.Element {
  return (
    <ScreenComponent
      slug="StudyGroups"
      defaultBackLink="/home"
      title={L10n.localize((s) => s.groups.studyGroups)}
      content={<StudyGroupsSection />}
      rightButton={<NewStudyGroupButton />}
    />
  );
}
