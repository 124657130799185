import React from "react";
import Style from "style";
import L10n from "localization";
import Tag from "@components/tag";
import { showPrompt } from "@components/prompt";

interface ITagHUDProps {
  folder?: string;
  tags: string[];
  onDelete?: (t: string) => void;
  onAdd?: (t: string) => void;
  allTags?: string[];
  style?: React.CSSProperties;
  noChip?: boolean;
  isTheaterMode?: boolean;
}
export default function TagHUD({
  folder,
  tags,
  onDelete,
  onAdd,
  allTags = [],
  style = {},
  noChip,
  isTheaterMode,
}: ITagHUDProps): React.ReactElement {
  const containerStyle: React.CSSProperties = {
    position: "fixed",
    marginTop: isTheaterMode ? 4 : 36,
    color: Style.colors.secondaryFg,
    zIndex: 10,
    textTransform: "uppercase",
    fontSize: "0.8rem",
    width: "100%",
    ...style,
  };

  const tagWrapperStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
  };

  return (
    <div style={containerStyle}>
      <div style={tagWrapperStyle}>
        {folder ? <Tag noChip name={folder} folder /> : undefined}
        {tags.map((t) => (
          <Tag
            key={t}
            name={t}
            noChip={noChip}
            style={{ marginRight: noChip ? 8 : undefined }}
            disabled={!onDelete}
            onDelete={
              onDelete
                ? () => {
                    onDelete(t);
                  }
                : undefined
            }
          />
        ))}
        {onAdd && (
          <Tag
            style={{ borderStyle: "dashed" }}
            name="+"
            onClick={() => {
              showPrompt({
                promptType: "input",
                title: L10n.localize((s) => s.tag.singular),
                prompt: L10n.localize((s) => s.general.name),
                validationRegex: /.+/,
                suggestions: allTags,
                callback: (response) => {
                  const tagName = response?.trim().toLowerCase() ?? "";
                  if (tagName === "") {
                    return;
                  }

                  onAdd(tagName);
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
}
