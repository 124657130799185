import { IonButton, IonCard, IonCardContent } from "@ionic/react";
import { file2Base64 } from "lib";
import React, { useEffect, useState } from "react";
import { LuXCircle } from "react-icons/lu";

function ImageFile({ file }: { file: Blob | undefined }) {
  const [fileBase64, setFileBase64] = useState<string>();
  useEffect(() => {
    if (!file) {
      return;
    }
    file2Base64(file)
      .then((b64) => setFileBase64(b64))
      .catch(() => {
        // Meh.
      });
  }, [file]);
  return fileBase64 ? <img src={fileBase64} /> : undefined;
}

interface IProps {
  file: Blob | undefined;
  prompt: string | null | undefined;
  clearAttachment?: () => void;
}
export default function PromptCard({ file, prompt, clearAttachment }: IProps) {
  return (
    <IonCard style={{ width: "calc(100% - 24px)", padding: 8, marginBottom: 6 }}>
      {prompt ? (
        <IonCardContent style={{ whiteSpace: "pre-wrap", userSelect: "text" }}>
          {prompt}
        </IonCardContent>
      ) : clearAttachment !== undefined ? (
        <IonButton fill="clear" onClick={clearAttachment}>
          <LuXCircle size={24} />
        </IonButton>
      ) : undefined}
      <ImageFile file={file} />
    </IonCard>
  );
}
