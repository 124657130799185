import { preventOrchestrationDatasetKey } from "@cardRendering/audioBlob";
import { ITypedBlobURL } from "@data/idb";
import { reviewScreenID } from "@screens/review/reviewScreen";
import * as Sentry from "@sentry/browser";
import EventBus from "eventBus";
import { useLayoutEffect, useMemo } from "react";
import { ID } from "types/ID";

async function orchestrate(els: HTMLAudioElement[], overridePreventOrchestration = false) {
  const endedListeners = new Map<HTMLAudioElement, (value: Event | PromiseLike<Event>) => void>();

  function playEl(el: HTMLAudioElement): Promise<Event> {
    return new Promise((resolve, reject) => {
      el.addEventListener("ended", resolve);

      // HACK: to clean up this event listener, have to do this weird global-ish storage, so caller can cleanup.
      // Can't neatly unhook from this function, because it needs a handle to it's own resolve method--circular.
      endedListeners.set(el, resolve);

      el.play().catch(reject);
    });
  }

  let paused = false;

  for (let i = 0; i < els.length; i++) {
    const el = els[i];

    const preventOrchestration =
      el.dataset[preventOrchestrationDatasetKey] === "true" && !overridePreventOrchestration;
    const skipEl = !el.src || preventOrchestration;
    if (skipEl) {
      continue;
    }

    function handlePause() {
      el.pause();
      el.currentTime = 0;
      paused = true;
    }
    EventBus.on("pauseTimer", handlePause);

    try {
      await playEl(el);
    } catch (err) {
      if (
        err instanceof Error &&
        (err.name === "AbortError" || err.message.includes("The operation was aborted."))
      ) {
        // Ignore it.
      } else {
        Sentry.captureException(err, { tags: { location: "audioOrchestrator" } });
      }
    } finally {
      EventBus.off("pauseTimer", handlePause);
      for (const [el, listener] of endedListeners) {
        el.removeEventListener("ended", listener);
      }
    }

    if (paused) {
      // Even if there was a leftover event listener, this would stop the
      // progression from continuing, so we don't record duplicate responses.
      return;
    }
  }
}

// useAudioOrchestrator serializes the playback of multiple audio blobs.
export default function useAudioOrchestrator(
  paused: boolean,
  pause: () => void,
  autoplayAudio: boolean,
  side: number,
  sideBlobIDs: Array<ID[]> | undefined,
  blobMap: Record<ID, ITypedBlobURL> | undefined,
  onOrchestrationComplete?: (side: number) => void,
): void {
  const blobIDs = sideBlobIDs?.[side] ?? [];
  const currSideAudioBlobIDs = useMemo(
    () => blobIDs.filter((id) => blobMap?.[id]?.type.startsWith("audio")),
    [blobIDs, blobMap],
  );

  const allSidesAudioBlobIDs = useMemo(() => {
    const bothSideBlobIDs: ID[] = [];
    // sideBlobIDs is an array with an entry for each "side" of the knol.
    // (We designed this to support more than 2 sides in the future.)
    // NOTE: a side might have an undefined array of blob IDs.
    for (const potentiallyUndefinedIDs of sideBlobIDs ?? []) {
      const ids = potentiallyUndefinedIDs ?? [];
      bothSideBlobIDs.push(...ids);
    }
    return bothSideBlobIDs.filter((id) => blobMap?.[id]?.type.startsWith("audio"));
  }, [sideBlobIDs, blobMap]);

  // NOTE: this logic depends on blobs being pre-loaded before rendering cards.
  // Otherwise, they may not all be in the DOM with a src set by the time this hook runs.
  useLayoutEffect(() => {
    if (!autoplayAudio || paused) {
      return;
    }

    const audioEls = Array.from(
      document.querySelectorAll<HTMLAudioElement>(`#${reviewScreenID} audio`),
    );

    let alreadyOrchestrated = false;
    function orchestrateIfAllLoaded(allSides = false) {
      const audioBlobIDs = allSides ? allSidesAudioBlobIDs : currSideAudioBlobIDs;

      if (alreadyOrchestrated) {
        // Prevent duplicates caused by mutation listeners triggering in quick succession.
        return;
      }

      function allLoaded(): boolean {
        const loadedAudioEls = audioEls.filter((el) => el.src);

        const loadedBlobs: Record<ID, boolean> = {};
        for (const id of audioBlobIDs) {
          loadedBlobs[id] = false;
        }
        for (const audioEl of loadedAudioEls) {
          const blobID = audioEl.getAttribute("data-blob-id");
          if (blobID && blobID in loadedBlobs) {
            loadedBlobs[blobID] = true;
          }
        }
        return audioBlobIDs.every((id) => loadedBlobs[id] === true);
      }

      function blockReruns() {
        alreadyOrchestrated = true;
        detachLoadedListeners();
      }

      if (audioBlobIDs.length === 0) {
        blockReruns();
        onOrchestrationComplete?.(side);
      } else if (allLoaded()) {
        blockReruns();
        orchestrate(audioEls, allSides)
          .then(() => {
            onOrchestrationComplete?.(side);
          })
          .catch((err) => {
            // NOTE: this catches errors such as failure to play due to user not clicking a button first.
            pause();
          });
      }
    }

    const observer = new MutationObserver((mutations) => {
      orchestrateIfAllLoaded();
    });

    function attachLoadedListeners() {
      for (const el of audioEls) {
        observer.observe(el, { attributes: true, attributeFilter: ["src", "data-blob-id"] });
      }
    }

    function detachLoadedListeners() {
      observer.disconnect();
    }

    function handlePause() {
      alreadyOrchestrated = false;
      detachLoadedListeners();
    }

    function replay() {
      handlePause();
      orchestrateIfAllLoaded(true);
    }

    attachLoadedListeners();
    EventBus.on("pauseTimer", handlePause);
    EventBus.on("resumeTimer", orchestrateIfAllLoaded);
    EventBus.on("replayAudio", replay);

    orchestrateIfAllLoaded();

    return () => {
      EventBus.off("resumeTimer", orchestrateIfAllLoaded);
      EventBus.off("pauseTimer", handlePause);
      EventBus.off("replayAudio", replay);
      detachLoadedListeners();
    };
  }, [
    paused,
    pause,
    autoplayAudio,
    side,
    currSideAudioBlobIDs,
    allSidesAudioBlobIDs,
    onOrchestrationComplete,
  ]);
}
