import { StepLogger } from "analytics/funnel";
import { appLang } from "fields/lang";
import { BFF69, bff69 } from "fields/langCodes";
import { History } from "history";
import L10n from "localization";
import React from "react";
import GoalLanguageMotivationNav from "./goalLanguageMotivationNav";
import SingleSelectNav, { IPromptOption } from "./singleSelectNav";

interface IProps {
  dismiss: () => Promise<void>;
  onAddToInbox: () => void;
  history: History;
  logStep: StepLogger;
}
interface ILangNameCodePair {
  name: string;
  code: BFF69;
}
export default function GoalLanguageNav(props: IProps) {
  const allLangs: Array<ILangNameCodePair | undefined> = bff69.map((code) => {
    try {
      const name = new Intl.DisplayNames([appLang()], { type: "language" }).of(code);
      if (!name) {
        return undefined;
      }
      return { code, name };
    } catch {
      return undefined;
    }
  });
  const langs: ILangNameCodePair[] = [];
  for (const lang of allLangs) {
    if (lang) {
      langs.push(lang);
    }
  }
  langs.sort();
  const opts: IPromptOption[] = langs.map((lang) => {
    return {
      name: lang.name,
      val: lang.code,
    };
  });
  return (
    <SingleSelectNav
      title={L10n.localize((s) => s.onboarding.langNavTitle)}
      description={L10n.localize((s) => s.onboarding.langNavDescription)}
      options={opts}
      other
      dismiss={props.dismiss}
      history={props.history}
      onNav={(opt) => {
        return (
          <GoalLanguageMotivationNav
            langName={opt.name}
            langCode={opt.val as BFF69}
            dismiss={props.dismiss}
            history={props.history}
            onAddToInbox={props.onAddToInbox}
            logStep={props.logStep}
          />
        );
      }}
      searchable
    />
  );
}
