import { StepLogger } from "analytics/funnel";
import { History } from "history";
import L10n from "localization";
import React from "react";
import GoalLanguageProficiencyNav from "./goalLanguageProficiencyNav";
import SingleSelectNav, { IPromptOption } from "./singleSelectNav";
import { BFF69 } from "fields/langCodes";
import { formatLangName } from "./genFlashcardsNav";

interface IProps {
  dismiss: () => Promise<void>;
  onAddToInbox: () => void;
  history: History;
  langName: string;
  langCode: BFF69;
  logStep: StepLogger;
}
export default function GoalLanguageMotivationNav(props: IProps) {
  const opts: IPromptOption[] = [
    { name: L10n.localize((s) => s.onboarding.langMotivationTravel), val: "travel" },
    { name: L10n.localize((s) => s.onboarding.langMotivationRelocate), val: "relocate" },
    { name: L10n.localize((s) => s.onboarding.langMotivationWork), val: "work" },
    { name: L10n.localize((s) => s.onboarding.langMotivationSchool), val: "school" },
    { name: L10n.localize((s) => s.onboarding.langMotivationFun), val: "fun" },
  ];
  return (
    <SingleSelectNav
      title={formatLangName(props.langName)}
      description={L10n.localize((s) => s.onboarding.langMotivationNavDescription)}
      options={opts}
      other
      dismiss={props.dismiss}
      history={props.history}
      onNav={(opt) => {
        return (
          <GoalLanguageProficiencyNav
            langName={props.langName}
            langCode={props.langCode}
            motivation={opt.name}
            dismiss={props.dismiss}
            history={props.history}
            onAddToInbox={props.onAddToInbox}
            logStep={props.logStep}
          />
        );
      }}
    />
  );
}
