import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  SearchbarCustomEvent,
  useIonPopover,
} from "@ionic/react";
import { ionicMode } from "ionicApp";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import React, { FormEvent, useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import Style from "style";
import Banner from "./banner/banner";

export default function Header({
  banner,
  collapse,
  title,
  defaultBackLink,
  backIsCancel,
  backText,
  confirmCancel,
  rightButton,
  leftButton,
  searchQuery,
  searchPlaceholder,
  onSearch,
  onLiveSearch,
  searchDebounceMs,
  searchRightButton,
  isTheaterMode,
  color,
}: {
  banner?: React.ReactNode;
  collapse?: boolean;
  title?: string | React.ReactElement;
  defaultBackLink?: string;
  backIsCancel?: boolean;
  backText?: string;
  confirmCancel?: boolean;
  rightButton?: React.ReactElement;
  leftButton?: React.ReactElement;
  searchQuery?: string;
  searchPlaceholder?: string;
  onSearch?: (query: string) => void;
  onLiveSearch?: (query: string) => void;
  searchDebounceMs?: number;
  searchRightButton?: React.ReactElement;
  isTheaterMode?: boolean;
  color?: string;
}) {
  const history = useHistory();
  function handleCancel() {
    if (confirmCancel) {
      const ok = confirm(L10n.localize((s) => s.general.confirmLeaveWithoutSaving));
      if (!ok) {
        return;
      }
    }
    history.goBack();
  }

  const showSearch = (onSearch || onLiveSearch) && (collapse === true || collapse === undefined);

  const [query, setQuery] = React.useState(searchQuery);

  // Respond to changes in the searchQuery provided to the Screen, e.g. when a category is selected on the Search screen.
  useEffect(() => {
    setQuery(searchQuery);
  }, [searchQuery, setQuery]);

  function handleSearchSubmit(evt: FormEvent) {
    if (query) {
      onSearch?.(query);
    }
    evt.preventDefault();
    evt.stopPropagation();
  }

  function handleLiveSearch(query: string) {
    setQuery(query);
    onLiveSearch?.(query);
  }

  const style:
    | undefined
    | (React.CSSProperties & { "--background": string; "--border-style": string }) = isTheaterMode
    ? { "--background": Style.colors.primaryBg, "--border-style": "none" }
    : undefined;

  const isTheaterModeOpacityStyle: React.CSSProperties = {
    opacity: isTheaterMode ? 0.6 : undefined,
  };

  const titlePopover = isTheaterMode;
  const [showTitlePopover] = useIonPopover(() => <>{title}</>);
  const handleTitleClick = useCallback(
    (evt: React.MouseEvent<HTMLIonTitleElement, MouseEvent>) => {
      if (titlePopover) {
        showTitlePopover({ event: evt.nativeEvent });
      }
    },
    [showTitlePopover, titlePopover],
  );

  return (
    <IonHeader translucent collapse={collapse ? "condense" : undefined}>
      <IonToolbar style={style} color={color}>
        {banner && !collapse && <Banner>{banner}</Banner>}
        <IonTitle
          size={collapse ? "large" : undefined}
          style={isTheaterModeOpacityStyle}
          onClick={handleTitleClick}
        >
          {title}
        </IonTitle>
        <IonButtons
          slot="start"
          style={{
            display: collapse === true ? "none" : undefined,
            ...isTheaterModeOpacityStyle,
          }}
        >
          {leftButton ? (
            leftButton
          ) : backIsCancel ? (
            <IonButton onClick={handleCancel} color="danger">
              {L10n.localize((s) => s.actions.cancel)}
            </IonButton>
          ) : (
            <IonBackButton
              defaultHref={defaultBackLink}
              text={backText ?? L10n.localize((s) => s.general.goBack)}
            />
          )}
          {/* TODO: add cancel support */}
        </IonButtons>
        <IonButtons
          slot="end"
          style={{
            display: collapse === true ? "none" : undefined,
            ...isTheaterModeOpacityStyle,
          }}
        >
          {rightButton}
        </IonButtons>
      </IonToolbar>
      {showSearch && (
        <IonToolbar>
          <form action="" onSubmit={handleSearchSubmit}>
            <IonSearchbar
              {...cyAttr("screen-searchbar")}
              value={query}
              debounce={searchDebounceMs ?? (onLiveSearch ? 250 : 0)}
              onIonInput={(e: SearchbarCustomEvent) => {
                const newVal = e.detail.value;
                if (newVal !== undefined && newVal !== null) {
                  handleLiveSearch(newVal);
                }
              }}
              placeholder={searchPlaceholder ?? L10n.localize((s) => s.actions.search)}
              style={
                {
                  "--box-shadow": ionicMode === "md" ? "none" : undefined,
                } as React.CSSProperties & { "--box-shadow": string }
              }
            />
          </form>
          {searchRightButton && <IonButtons slot="end">{searchRightButton}</IonButtons>}
        </IonToolbar>
      )}
    </IonHeader>
  );
}
