import { IonLabel, IonTabBar, IonTabButton } from "@ionic/react";
import { Deck } from "@models/deck";
import useRefreshCounter from "@screens/deck/hooks/useRefreshCounter";
import OmniReviewModal from "@screens/omniReview";
import EventBus from "eventBus";
import { LensSpec, isDeckSpec, isFolderSpec, isOmniSpec, useLens } from "hooks/data/useLens";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { forwardRef, useCallback, useEffect, useMemo } from "react";
import {
  LuActivity,
  LuHome,
  LuPlay,
  LuPlayCircle,
  LuPlaySquare,
  LuSearch,
  LuUserCog,
} from "react-icons/lu";
import { useHistory, useLocation } from "react-router";
import usePDP from "unlimited/pdp";
import { checkReviewGate, getReviewGateThreshold } from "unlimited/reviewGate";

function BottomTabBarImpl(props: any, ref: any) {
  const location = useLocation();
  const history = useHistory();

  const [_refreshCounter, incrementRefreshCounter] = useRefreshCounter();
  useEffect(() => {
    EventBus.on("localeUpdated", incrementRefreshCounter);
    return () => {
      EventBus.off("localeUpdated", incrementRefreshCounter);
    };
  }, [incrementRefreshCounter]);

  const lensSpec: LensSpec = useMemo(() => {
    const deckPrefix = "/decks/local/";
    const folderPrefix = "/folders/";
    if (location.pathname.startsWith(deckPrefix)) {
      const deckID = location.pathname.slice(deckPrefix.length, deckPrefix.length + 32);
      return { deckID };
    } else if (location.pathname.startsWith(folderPrefix)) {
      const folder = decodeURIComponent(location.pathname.slice(folderPrefix.length));
      return { folder };
    } else {
      return "omni";
    }
  }, [location.pathname]);
  const isInReviewSession = location.pathname.endsWith("/review");
  const { decks, filteredKnols, knols } = useLens(lensSpec, {
    listenWhenOffscreen: true,
    skipRefilterKnolsOnUpdate: isInReviewSession,
  });

  const [presentOmniReviewModal] = useIonCardModalWithDismiss(OmniReviewModal, {
    history: history,
  });

  const doHistoryPush = useCallback(() => {
    if (isDeckSpec(lensSpec)) {
      history.push(`/decks/local/${lensSpec.deckID}/review`);
    } else if (isFolderSpec(lensSpec)) {
      history.push(`/folders/${encodeURIComponent(lensSpec.folder)}/review`);
    } else if (isOmniSpec(lensSpec)) {
      history.push(`/review`);
    }
  }, [lensSpec]);

  const [showWarnPDP] = usePDP({
    source: "reviewgate_warn",
    onDismiss: doHistoryPush,
    reason: () =>
      L10n.localize((s) => s.funcs.account.reviewGateWarnReason)(getReviewGateThreshold()),
  });

  const [showFatalPDP] = usePDP({
    source: "reviewgate_gate",
    reason: () => L10n.localize((s) => s.account.reviewGateGateReason),
  });

  const handleReview = useCallback(async () => {
    const gate = await checkReviewGate();
    if (gate) {
      if (gate.level === "warn") {
        showWarnPDP({ context: gate });
      } else {
        showFatalPDP({ context: gate });
      }
      return;
    }
    doHistoryPush();
  }, [lensSpec]);

  if (location.pathname.endsWith("/review") || location.pathname.endsWith("/cards")) {
    return <></>;
  }

  const hasCardsButNoReviews = knols.size > 0 && Deck.hasNoReviews(knols.values());

  const reviewDisabled = filteredKnols.size < 1;
  const folderReviewButtonLabel =
    decks.size >= 1 && filteredKnols.size === 0
      ? ` (${L10n.localize((s) => s.card.none)})`
      : decks.size === 1
        ? ` (1 ${L10n.localize((s) => s.deck.singular)})`
        : decks.size > 1
          ? ` (${decks.size} ${L10n.localize((s) => s.deck.plural)})`
          : "";

  const deckReviewButtonLabel =
    filteredKnols.size === 1
      ? ` (1 ${L10n.localize((s) => s.card.singular)})`
      : filteredKnols.size > 1
        ? ` (${filteredKnols.size} ${L10n.localize((s) => s.card.plural)})`
        : "";

  return (
    <IonTabBar {...props} ref={props.ref}>
      <IonTabButton tab="home" href="/home">
        <LuHome size={24} />
        <IonLabel>{L10n.localize((s) => s.general.home)}</IonLabel>
      </IonTabButton>
      <IonTabButton tab="stats" href="/stats">
        <LuActivity size={24} />
        <IonLabel>{L10n.localize((s) => s.general.stats)}</IonLabel>
      </IonTabButton>
      {location.pathname.startsWith("/decks") ? (
        <IonTabButton
          tab="review"
          className={hasCardsButNoReviews && !reviewDisabled ? "glowing-icon" : undefined}
          onClick={handleReview}
          disabled={reviewDisabled}
        >
          <LuPlay size={22} style={{ fill: "currentcolor" }} />
          <IonLabel>
            {L10n.localize((s) => s.review.singular)}
            <div style={{ fontSize: "8px" }}>{deckReviewButtonLabel}</div>
          </IonLabel>
        </IonTabButton>
      ) : location.pathname.startsWith("/folder") ? (
        <IonTabButton tab="review" onClick={handleReview} disabled={reviewDisabled}>
          <LuPlaySquare size={24} />
          <IonLabel>
            {L10n.localize((s) => s.review.singular)}

            <div style={{ fontSize: "8px" }}>{folderReviewButtonLabel}</div>
          </IonLabel>
        </IonTabButton>
      ) : (
        <IonTabButton tab="review" onClick={presentOmniReviewModal}>
          <LuPlayCircle size={24} />
          <IonLabel>{L10n.localize((s) => s.review.omni)}</IonLabel>
        </IonTabButton>
      )}
      <IonTabButton tab="search" href="/search">
        <LuSearch size={24} />
        <IonLabel>{L10n.localize((s) => s.actions.search)}</IonLabel>
      </IonTabButton>
      <IonTabButton tab="profile" href="/profile">
        <LuUserCog size={24} />
        <IonLabel>{L10n.localize((s) => s.general.account)}</IonLabel>
      </IonTabButton>
    </IonTabBar>
  );
}

// Here be dragons
export const BottomTabBar = forwardRef(BottomTabBarImpl) as any;

BottomTabBar.isTabBar = true;
BottomTabBar.type = BottomTabBarImpl;
