import { IonButton, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { useFolders } from "hooks/data/folders";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import React, { useCallback, useMemo, useState } from "react";
import NestedFolderView from "./nestedFolderView";
import { superCache } from "hooks/data/superCache";
import { subFolderMagicSeparator } from "@data/lib/folders";

export function useFolderSelectorModal({
  deckID,
  onSave,
}: {
  deckID: string;
  onSave: (folder?: string, initialFolder?: string) => void;
}): [() => void, () => void] {
  const initialFolder = superCache.decks?.get(deckID)?.tags?.[0];
  const [present, dismiss] = useIonCardModalWithDismiss(FolderSelectorModal, {
    initialFolder,
    onSave,
  });
  return [present, dismiss];
}

interface IProps {
  initialFolder?: string;
  excludeFolder?: string;
  dismiss: () => void;
  onSave: (folder?: string, initialFolder?: string) => void;
}
export default function FolderSelectorModal({
  initialFolder,
  excludeFolder,
  dismiss,
  onSave,
}: IProps): JSX.Element {
  const [selectedFolder, setSelectedFolder] = useState<string | undefined>(initialFolder);

  const selectedFolders = useMemo(() => {
    const sfs: string[] = [];
    if (selectedFolder) {
      sfs.push(selectedFolder);
    }
    return sfs;
  }, [selectedFolder]);

  const allFolderNames = useFolders();
  let folders = selectedFolder ? [selectedFolder].concat(allFolderNames) : allFolderNames;
  if (excludeFolder) {
    folders = folders.filter(
      (f) => f !== excludeFolder && !f.startsWith(excludeFolder + subFolderMagicSeparator),
    );
  }

  const handleClick = useCallback(
    (folder: string) => {
      if (folder === selectedFolder) {
        setSelectedFolder(undefined);
      } else {
        setSelectedFolder(folder);
      }
    },
    [selectedFolder],
  );
  const handleAddFolder = useCallback((folder?: string) => {
    setSelectedFolder(folder);
  }, []);

  const handleSave = useCallback(() => {
    onSave(selectedFolder, initialFolder);
    dismiss();
  }, [dismiss, initialFolder, onSave, selectedFolder]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={dismiss}>{L10n.localize((s) => s.actions.close)}</IonButton>
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.actions.move)}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={handleSave}
              disabled={selectedFolder === initialFolder}
              {...cyAttr("save-button")}
            >
              {L10n.localize((s) => s.actions.save)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent {...cyAttr("folder-selector-screen")}>
        <NestedFolderView
          folders={folders}
          selectedFolders={selectedFolders}
          onClick={handleClick}
          onAddFolder={handleAddFolder}
        />
        <div style={{ height: "env(safe-area-inset-bottom)" }} />
      </IonContent>
    </>
  );
}
