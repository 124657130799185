import { BFF69 } from "fields/langCodes";
import cyAttr from "lib/cyAttr";
import React from "react";
import { IoVolumeMedium } from "react-icons/io5";
import { speechSynthesisSpeak } from "speech";
import Style from "style";
import { editableValueClassName } from "./editableValueWrapper";

interface IProps {
  autoplayAudio: boolean;
  disableClick: boolean;
  lang?: BFF69;
  rate: number;
  otherProps?: any;
  children: React.ReactElement;
}
export default function TTSWrapper(props: IProps): JSX.Element {
  const {
    autoplayAudio = false,
    disableClick = false,
    lang = "en-US",
    rate = 1.0,
    otherProps = {},
    children,
  } = props;

  const [active, setActive] = React.useState(false);
  const [empty, setEmpty] = React.useState(false);
  const [speaking, setSpeaking] = React.useState(false);

  const speakingRef = React.useRef(false);
  const ref = React.useRef<HTMLDivElement>(null);

  function speak() {
    if (empty) {
      return;
    }

    // Prevent double-speak.
    if (speakingRef.current === true) {
      return;
    }

    setActive(true);

    // In case there is an EditableValueWrapper around the contents to be spoken,
    // just get the contents, not the wrapper too.
    const text =
      (
        ref.current?.getElementsByClassName(editableValueClassName) as HTMLCollectionOf<HTMLElement>
      )[0]?.innerText ?? ref.current?.innerText;

    const opts = {
      lang: lang,
      rate: rate,
    };

    const onStart = () => {
      speakingRef.current = true;
      setSpeaking(true);
      setActive(false);
    };

    const onEnd = () => {
      speakingRef.current = false;
      setSpeaking(false);
      setActive(false);
    };

    return speechSynthesisSpeak(text, opts, onStart, onEnd);
  }

  React.useEffect(() => {
    setEmpty(!ref.current?.innerText);
    if (autoplayAudio) {
      speak();
    }
  }, [autoplayAudio]);

  const backgroundColor = speaking || active ? Style.colors.ttsBg : null;

  const style = {
    border: disableClick
      ? // Don't visualize clickability.
        undefined
      : `1px dotted ${Style.colors.actionableFg}`,
    backgroundColor,
  };

  const iconStyle = {
    marginLeft: "0.3rem",
    color: speaking || active ? Style.colors.actionableFg : Style.colors.actionableMutedFg,
  };

  if (empty) {
    return children;
  }

  return (
    <div
      {...otherProps}
      onClick={
        disableClick
          ? null
          : speaking || active
            ? // HACK: multiple clicks during speech caused XCode to bomb-out, so don't even listen on clicks in this case.
              null
            : speak
      }
      style={style}
      ref={ref}
    >
      <span>{children}</span>
      <span {...cyAttr("sound-icon")}>
        <IoVolumeMedium style={iconStyle} />
      </span>
    </div>
  );
}
