import { useFolders } from "hooks/data/folders";
import L10n from "localization";
import React, { useCallback } from "react";
import NestedFolderView from "./nestedFolderView";

// just emit onClick events from each folder, then a higher level component handles the selection logic

interface IProps {
  selectedFolders: string[];
  onAddFolder?: (folder?: string) => void;
  onDeleteFolder?: (folder: string) => void;
  confirmRemoval?: boolean;
}
export default function FolderSelector({
  selectedFolders,
  onAddFolder,
  onDeleteFolder,
  confirmRemoval,
}: IProps) {
  const isSelected = useCallback(
    (fullFolder: string) => {
      return selectedFolders.includes(fullFolder);
    },
    [selectedFolders],
  );
  const handleClick = useCallback(
    (fullFolder: string) => {
      const selected = isSelected(fullFolder);
      if (selected) {
        if (confirmRemoval) {
          const confirmed = confirm(L10n.localize((s) => s.folder.confirmRemoval));
          if (!confirmed) {
            return;
          }
        }
        onDeleteFolder?.(fullFolder);
      } else {
        onAddFolder?.(fullFolder);
      }
    },
    [confirmRemoval, isSelected, onAddFolder, onDeleteFolder],
  );

  const allFolderNames = useFolders();
  const folders = allFolderNames.concat(selectedFolders);

  return (
    <NestedFolderView
      folders={folders}
      selectedFolders={selectedFolders}
      onClick={handleClick}
      onAddFolder={onAddFolder}
    />
  );
}
