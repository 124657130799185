import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { DotID, clearDot, useClearedAtAndRecordImpression } from "dots";
import { closeOutline, informationCircleOutline } from "ionicons/icons";
import L10n from "localization";
import React, { useState } from "react";

interface IProps {
  dotID: DotID;
  message: string;
  hiddenStyle?: React.CSSProperties;
}
export default function TipDot({ dotID, message, hiddenStyle }: IProps) {
  const { loading: dotLoading, val: dotClearedAt } = useClearedAtAndRecordImpression(dotID);
  const [manuallyEnableTip, setManuallyEnableTip] = useState(false);
  const showDot = manuallyEnableTip || (!dotLoading && !dotClearedAt);

  if (!showDot) {
    return (
      <IonButton fill="clear" onClick={() => setManuallyEnableTip(true)} style={hiddenStyle}>
        <IonIcon icon={informationCircleOutline} slot="start" />
        {L10n.localize((s) => s.general.tip)}
      </IonButton>
    );
  }

  function handleClick() {
    setManuallyEnableTip(false);
    clearDot(dotID).catch(() => {});
  }
  return (
    <IonCard color="primary">
      <IonCardHeader>
        <IonCardTitle style={{ display: "flex", alignItems: "center" }}>
          <IonIcon icon={informationCircleOutline} style={{ marginRight: 8 }} />
          <IonLabel>{L10n.localize((s) => s.general.tip)}</IonLabel>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent style={{ whiteSpace: "pre-wrap", wordBreak: "break-all" }}>
        {message}
      </IonCardContent>
      <IonButton fill="clear" color="light" onClick={handleClick}>
        <IonIcon icon={closeOutline} />
        {L10n.localize((s) => s.actions.close)}
      </IonButton>
    </IonCard>
  );
}
