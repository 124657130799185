// MIN_FLOAT_VALUE is a floating-point value that the

import { IGrade } from "@models/knol";
import type { UTCMillis } from "@lib/timestamps";

// Cordova native bridge can handle without error.
const MIN_FLOAT_VALUE = 1e-20;

// MAX_MEAN_DELTA is the maximum amount that the mean is
// allowed to change by. Without this "clamping", a high variance
// (which occurs when you ace a card repeatedly, then fail it once),
// will cause a huge swing in score.
const MAX_MEAN_DELTA = 0.2;

// MIN_PREV_WEIGHT sets a floor on how little the previous
// response mean can be worth in computing the next mean.
const MIN_PREV_WEIGHT = 0.5;

export default function computeStats(
  score: number,
  prevNumResponses: number,
  currTime: UTCMillis,
  prevTime: UTCMillis | null,
  prevMean: number | null,
  prevStandardDeviation: number | null,
): IGrade {
  const WEIGHT_HALF_LIFE = 5; // Number of responses at which previous response is weighted as half of current response.

  const prevVariance = Math.pow(prevStandardDeviation ?? Number.NaN, 2) || 0;

  // "Smoothing".
  if (score < 0.5) {
    // Make 2 initial fails have lower mean than single initial fail.
    prevMean = prevMean || 0.25;
  } else if (score < 0.75) {
    // Make initial "Hard" an "E".
    prevMean = prevMean || 0.55;
  } else if (score < 0.9) {
    // Make initial "Good" a "D".
    prevMean = prevMean || 0.65;
  } else {
    // Make initial "Easy" a "C".
    prevMean = prevMean || 0.75;
  }

  // As you accumulate a lot of responses on a card (like 20),
  // the previous mean becomes increasingly meaningless,
  // and the current score comes to dominate.
  let prevWeight;
  if (prevNumResponses === 0) {
    prevWeight = 0.5;
  } else {
    const halfLives = prevNumResponses / WEIGHT_HALF_LIFE;
    prevWeight = Math.exp(Math.log(0.5) * halfLives) * (prevNumResponses / (prevNumResponses + 1));
  }

  // Clamp prevWeight so that it can't be worth less than MIN_PREV_WEIGHT.
  // This restrains the score from swinging too wildly.
  prevWeight = Math.max(prevWeight, MIN_PREV_WEIGHT);

  const currWeight = 1.0 - prevWeight;

  let mean = prevWeight * prevMean + currWeight * score;

  // Clamp mean so that it can't change by more than MAX_MEAN_DELTA at a time.
  const meanDelta = mean - prevMean;
  if (Math.abs(meanDelta) > MAX_MEAN_DELTA) {
    const clampedMeanDelta = Math.sign(meanDelta) * MAX_MEAN_DELTA;
    mean = prevMean + clampedMeanDelta;
  }

  const variance =
    currWeight * Math.pow(score, 2) +
    prevWeight * (prevVariance + Math.pow(prevMean, 2)) -
    Math.pow(currWeight * score + prevWeight * prevMean, 2);

  const standardDeviation = Math.sqrt(variance);

  return {
    score_mean: Math.max(mean, MIN_FLOAT_VALUE),
    score_standard_deviation: Math.max(standardDeviation, MIN_FLOAT_VALUE),
    last_response_at: currTime,
    num_responses: prevNumResponses + 1,
  };
}
