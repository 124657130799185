import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";

interface IProps {
  daysUntilExpiration: number;
  onClick: () => void;
  willAutoRenew?: boolean;
}
export default function SubscriptionRenewalWarning({
  daysUntilExpiration,
  onClick,
  willAutoRenew,
}: IProps) {
  const isWarning = willAutoRenew !== false; // Determines if it's a warning or danger
  return (
    <IonCard color={isWarning ? "warning" : "danger"} button onClick={onClick}>
      <IonCardHeader>
        <IonCardTitle style={{ display: "flex", alignItems: "center" }}>
          <IonIcon icon={warningOutline} style={{ marginRight: 8 }} />
          <IonLabel>
            {L10n.localize((s) =>
              daysUntilExpiration <= 0
                ? s.account.subscriptionExpired
                : willAutoRenew
                  ? s.account.subscriptionRenewal
                  : s.account.subscriptionExpiring,
            )}
          </IonLabel>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {L10n.localize((s) =>
          willAutoRenew
            ? s.funcs.account.subscriptionWarning
            : s.funcs.account.subscriptionExpiryWarning,
        )(daysUntilExpiration)}
      </IonCardContent>
    </IonCard>
  );
}
