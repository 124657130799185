const zeroPaddedMMs = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, "0"));
const zeroPaddedDDs = Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, "0"));

// Idea: could keeping a small cache of recently-computed yyyy-mm-dd strings help?
// could use numbers of form yyyymmdd as the cache keys (e.g. y*10^5 + m*10^3 + d).

export function dateToYYYYMMDD(date: Date): string {
  const yyyy = date.getFullYear();

  // const monthNum = date.getMonth() + 1;
  // const mm = monthNum < 10 ? `0${monthNum}` : `${monthNum}`;
  // const mm = (date.getMonth() + 1).toString().padStart(2, "0");
  const mm = zeroPaddedMMs[date.getMonth()];

  // const dayNum = date.getDate();
  // const dd = dayNum < 10 ? `0${dayNum}` : `${dayNum}`;
  // const dd = date.getDate().toString().padStart(2, "0");
  const dd = zeroPaddedDDs[date.getDate() - 1];

  return `${yyyy}-${mm}-${dd}`;
}
