import MapView from "@lib/mapView";
import { IKnol } from "@models/knol";
import { ID } from "types/ID";

export interface IGeneratedRow {
  values: Record<string, string>;
  enabled: boolean;
  duplicate: boolean;
}

export function objDiff(o1: Record<string, any>, o2: Record<string, any>) {
  const diffKeys = new Set([...Object.keys(o1), ...Object.keys(o2)]);
  for (const key of diffKeys) {
    if (o1[key] !== o2[key]) {
      return true;
    }
  }
  return false;
}

export function isLikelyDuplicate(
  knols: MapView<ID, IKnol>,
  values: Record<string, string | undefined>,
): boolean {
  const kvs = Object.entries(values);
  for (const knol of knols.values()) {
    for (const [k, v] of kvs) {
      if (knol.values?.[k]?.toLowerCase() === v?.toLowerCase() && v !== undefined && v !== "") {
        return true;
      }
    }
  }
  return false;
}

type GenerateResponseRow = string[];
export interface IGenerateResponse {
  data: GenerateResponseRow[];
}

export function parseGeneratedResponse(
  response: IGenerateResponse,
  columns: string[],
  knols: MapView<ID, IKnol>,
): IGeneratedRow[] {
  if (!Object.prototype.hasOwnProperty.call(response, "data")) {
    return [];
  }
  if (!Array.isArray(response.data)) {
    return [];
  }

  const respRows: IGeneratedRow[] = [];
  for (const row of response.data) {
    const values: Record<string, string> = {};
    for (let j = 0; j < columns.length; j++) {
      const col = columns[j];
      const val = row[j];
      values[col] = val;
    }
    const duplicate = isLikelyDuplicate(knols, values);
    respRows.push({
      values,
      duplicate,
      enabled: !duplicate,
    });
  }
  return respRows;
}
