import useStudyGroups from "@screens/studyGroups/useStudyGroups";
import InlineLoadingSpinner from "components/inlineLoadingSpinner";
import React, { useCallback } from "react";
import { IonItem, IonLabel, IonList, IonNavLink } from "@ionic/react";
import { ID } from "types/ID";
import StudyGroupsEmpty from "./studyGroupsEmpty";
import { History } from "history";
import cyAttr from "@lib/cyAttr";
import L10n from "localization";
import NewStudyGroupNav from "./newStudyGroupNav";
import StudyGroupShareNav from "./studyGroupShareNav";

interface IProps {
  onDismiss: () => Promise<void>;
  onShare: (emails: string[], groupIDs: string[]) => Promise<void>;
  history: History;
}
export default function StudyGroupsList(props: IProps) {
  const [groups, loadingState] = useStudyGroups();

  const handleShare = useCallback(
    async (groupIDs: ID[]) => {
      await props.onShare([], groupIDs);
      await props.onDismiss();
    },
    [props.onShare, props.onDismiss],
  );

  return (
    <>
      {!loadingState.loading && groups.length < 1 ? (
        <StudyGroupsEmpty onShare={handleShare} history={props.history} />
      ) : undefined}
      <IonList>
        {loadingState.loading && (
          <div style={{ textAlign: "center", margin: "8px 0" }}>
            <InlineLoadingSpinner />
          </div>
        )}

        {!loadingState.loading && groups.length > 0 ? (
          <IonNavLink
            routerDirection="forward"
            component={() => <NewStudyGroupNav onShare={handleShare} history={props.history} />}
          >
            <IonItem button detail {...cyAttr("new-study-group-button")}>
              <IonLabel color="primary">{L10n.localize((s) => s.sharing.addStudyGroup)}</IonLabel>
            </IonItem>
          </IonNavLink>
        ) : undefined}

        {groups.map((g) => {
          return (
            <IonNavLink
              key={g.id}
              routerDirection="forward"
              component={() => (
                <StudyGroupShareNav
                  id={g.id}
                  name={g.name}
                  onShare={handleShare}
                  history={props.history}
                />
              )}
            >
              <IonItem button>
                <IonLabel>{g.name}</IonLabel>
              </IonItem>
            </IonNavLink>
          );
        })}
      </IonList>
    </>
  );
}
