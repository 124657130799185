import React, { useCallback, useEffect } from "react";
import Network from "network";
import ShareModal from "./shareModal";
import logEvent from "analytics";
import { IonNav } from "@ionic/react";
import useErrorAlert from "hooks/util/useErrorAlert";
import { History } from "history";

interface IProps {
  deckID: string;
  dismiss: () => Promise<void>;
  onSuccess: () => void;
  history: History;
}
function DeckShareNav(props: IProps): JSX.Element {
  const { deckID, dismiss, onSuccess } = props;

  useEffect(() => {
    logEvent("deck_share_showing_modal", { deckID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deckID]);

  const shareHistory =
    localStorage["AnkiApp.shareHistory"] != null
      ? JSON.parse(localStorage["AnkiApp.shareHistory"])
      : [];

  const [showError] = useErrorAlert({ code: "SHARING_DECK" });
  const sendShare = useCallback(
    async (emails: string[], groupIDs: string[]) => {
      try {
        await Network.fetch("POST", "/decks/share", {
          deck_id: deckID,
          emails: emails,
          groups: groupIDs.map((id) => ({ id })),
        });
        onSuccess();
      } catch (err) {
        showError(err);
      }
    },
    [deckID, onSuccess, showError],
  );

  const lastHistoryIndex = shareHistory.length - 1;
  const prevShareEmails = shareHistory[lastHistoryIndex]?.response?.split(",") ?? [];

  const handleShare = useCallback(
    async (emails: string[], groupIDs: string[]) => {
      logEvent("deck_share_handling_share", {
        deckID,
        numEmails: emails.length,
        numGroups: groupIDs.length,
      });

      if (emails.length > 0) {
        const now = new Date().toISOString();
        shareHistory.push({
          response: emails.join(","),
          deck_id: deckID,
          timestamp: now,
        });
        localStorage.setItem("AnkiApp.shareHistory", JSON.stringify(shareHistory));
      }

      await sendShare(emails, groupIDs);
      await dismiss();
    },
    [deckID, sendShare, dismiss, shareHistory],
  );

  return (
    <ShareModal
      defaultEmails={prevShareEmails}
      onShare={handleShare}
      onDismiss={dismiss}
      history={props.history}
    />
  );
}

export default function DeckShareModal(props: IProps): JSX.Element {
  const navRoot = useCallback(() => {
    return (
      <DeckShareNav
        deckID={props.deckID}
        dismiss={props.dismiss}
        onSuccess={props.onSuccess}
        history={props.history}
      />
    );
  }, [props.dismiss, props.onSuccess, props.deckID, props.history]);
  return <IonNav root={navRoot} />;
}
