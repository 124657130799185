export default {
  groups: {
    confirmCancelPendingRequest: {
      en: (name: string) => `Cancel request to join "${name}"?`,
      de: (name: string) => `Antrag auf Beitritt zu "${name}" stornieren?`,
      es: (name: string) => `¿Cancelar la solicitud de adhesión a "${name}"?`,
      fr: (name: string) => `Annuler la demande d'adhésion à "${name}" ?`,
      "pt-br": (name: string) => `Cancelar solicitação para participar do grupo "${name}"?`,
      ja: (name: string) => `"${name}"への参加要請をキャンセルしますか？`,
      "zh-cn": (name: string) => `取消加入 ${name} 的请求？`,
      "zh-tw": (name: string) => `取消加入 ${name} 的請求？`,
    },
    joinRequestPreAuthMessage: {
      en: (name: string) => `Sign up or log in to request to join ${name}.`,
      de: (name: string) =>
        `Registrieren Sie sich oder melden Sie sich an, um ${name} beizutreten.`,
      es: (name: string) => `Regístrese o inicie sesión para solicitar la adhesión a ${name}.`,
      fr: (name: string) => `Inscrivez-vous ou connectez-vous pour demander à rejoindre ${name}.`,
      "pt-br": (name: string) =>
        `Cadastre-se ou faça o login para pedir para participar do grupo ${name}.`,
      ja: (name: string) =>
        `サインアップまたはログインして、${name}への参加をリクエストしてください。`,
      "zh-cn": (name: string) => `添加学习小组'${name}'之前先注册或登录`,
      "zh-tw": (name: string) => `添加學習小組'${name}'之前先註冊或登錄`,
    },
    joinRequestSent: {
      en: (name: string) => `Group join request sent. (${name})`,
      de: (name: string) => `Anfrage zum Beitritt zur Gruppe gesendet. (${name})`,
      es: (name: string) => `Solicitud de adhesión al grupo enviada. (${name})`,
      fr: (name: string) => `Demande d'adhésion au groupe envoyée. (${name})`,
      "pt-br": (name: string) => `Pedido para participar do grupo enviado. (${name})`,
      ja: (name: string) => `グループ参加リクエストを送信しました。(${name})`,
      "zh-cn": (name: string) => `加入学习小组请求已发送。 (${name})`,
      "zh-tw": (name: string) => `加入學習小組請求已發送。 (${name})`,
    },
    joinRequestFailed: {
      en: (name: string) => `Group join request failed. (${name})`,
      de: (name: string) => `Anfrage zum Beitritt zur Gruppe fehlgeschlagen. (${name})`,
      es: (name: string) => `La solicitud de unión al grupo ha fallado. (${name})`,
      fr: (name: string) => `Échec de la demande d'adhésion à un groupe. (${name})`,
      "pt-br": (name: string) => `O pedido para participar do grupo falhou. (${name})`,
      ja: (name: string) => `グループ参加リクエストに失敗しました。(${name})`,
      "zh-cn": () => "加入学习小组请求失败",
      "zh-tw": () => "加入學習小組請求失敗",
    },
    leaveGroupQuestion: {
      en: (name: string) => `Leave ${name}?`,
      es: (name: string) => `Salir de ${name}?`,
      de: (name: string) => `${name} verlassen?`,
      fr: (name: string) => `Laisser ${name}?`,
      "pt-br": (name: string) => `Sair do grupo ${name}?`,
      "zh-cn": (name: string): string => `离开${name}？`,
      "zh-tw": (name: string): string => `離開${name}？`,
      ja: (name: string): string => `${name}を残す？`,
    },
    kickMemberQuestion: {
      en: (userEmail: string, groupName: string) =>
        `Are you sure you wish to remove '${userEmail}' from group '${groupName}'?`,
      es: (userEmail: string, groupName: string) =>
        `¿Está seguro de que desea eliminar '${userEmail}' del grupo '${groupName}'?`,
      de: (userEmail: string, groupName: string) =>
        `Möchten Sie „${userEmail}“ wirklich aus der Gruppe „${groupName}“ entfernen?`,
      fr: (userEmail: string, groupName: string) =>
        `Voulez-vous vraiment supprimer '${userEmail}' du groupe '${groupName}'?`,
      "pt-br": (userEmail: string, groupName: string) =>
        `Tem certeza de que deseja remover '${userEmail}' do grupo '${groupName}'?`,
      "zh-cn": (userEmail: string, groupName: string) =>
        `您确定要从组“${groupName}”中删除“${userEmail}”吗？`,
      "zh-tw": (userEmail: string, groupName: string) =>
        `您確定要從組“${groupName}”中刪除“${userEmail}”嗎？`,
      ja: (userEmail: string, groupName: string) =>
        `グループ'${groupName}'から'${userEmail}'を削除してよろしいですか？`,
    },
  },
  unlimited: {
    oneTimePurchase: {
      en: (amount: string) => `${amount} one-time purchase`,
      es: (amount: string) => `${amount} compra única`,
      de: (amount: string) => `Einmalkauf ${amount}`,
      "pt-br": (amount: string) => `Compra única de ${amount}`,
      "zh-tw": (amount: string) => `${amount} 一次性購買`,
      "zh-cn": (amount: string) => `${amount} 一次性购买`,
      ja: (amount: string) => `${amount} 一回の購入`,
      fr: (amount: string) => `Achat unique de ${amount}`,
    },
    freeTrial1Week: {
      en: (amount: string) => `1 week free, then ${amount}/year`,
      fr: (amount: string) => `1 semaine gratuite, puis ${amount}/an`,
      "pt-br": (amount: string) => `1 semana grátis, depois ${amount}/ano`,
      es: (amount: string) => `1 semana gratis, luego ${amount}/año`,
      ja: (amount: string) => `1週間無料、その後 ${amount}/年`,
      "zh-cn": (amount: string) => `1周免费，然后${amount}/年`,
      "zh-tw": (amount: string) => `1週免費，之後每年${amount}`,
      de: (amount: string) => `1 Woche kostenlos, dann ${amount}/Jahr`,
    },
    freeTrial3Days: {
      en: (amount: string) => `3 days free, then ${amount}/year`,
      fr: (amount: string) => `3 jours gratuits, puis ${amount}/an`,
      "pt-br": (amount: string) => `3 dias grátis, depois ${amount}/ano`,
      es: (amount: string) => `3 días gratis, luego ${amount}/año`,
      ja: (amount: string) => `3日間無料、その後 ${amount}/年`,
      "zh-cn": (amount: string) => `3天免费，然后${amount}/年`,
      "zh-tw": (amount: string) => `3天免費，之後每年${amount}`,
      de: (amount: string) => `3 Tage kostenlos, dann ${amount}/Jahr`,
    },
    cardsPerSessionUpgradePrompt: {
      en: (n: number) =>
        `To review more than ${n} cards per session, you must upgrade to AnkiApp Unlimited.`,
      de: (n: number) =>
        `Um mehr als ${n} Karten pro Sitzung zu überprüfen, müssen Sie auf AnkiApp Unlimited upgraden.`,
      es: (n: number) =>
        `Para revisar más de ${n} tarjetas por sesión, debes pasar a AnkiApp Unlimited.`,
      fr: (n: number) =>
        `Pour examiner plus de ${n} cartes par session, vous devez passer à AnkiApp Unlimited.`,
      "pt-br": (n: number) =>
        `Para revisar mais de ${n} cartões por sessão, você deve atualizar para AnkiApp Ilimitada.`,
      ja: (n: number) =>
        `1セッションで${n}枚以上のカードをレビューするには、AnkiApp Unlimitedにアップグレードする必要があります。`,
      "zh-cn": (n: number) => `学习${n}张以上的卡片数量要升级AnkiApp Unlimited。`,
      "zh-tw": (n: number) => `學習${n}張以上的卡片數量要升級AnkiApp Unlimited。`,
    },
  },
  review: {
    numToSkip: {
      en: (n: number) => `Skipping ${n} cards`,
      de: (n: number) => `Überspringen ${n} Karten`,
      es: (n: number) => `Saltar ${n} cartas`,
      fr: (n: number) => `Sauter ${n} cartes`,
      "pt-br": (n: number) => `Pular ${n} cartões`,
      "zh-cn": (n: number) => `跳${n}卡片`,
      "zh-tw": (n: number) => `跳${n}卡片`,
      ja: (n: number) => `${n}カードをスキップする`,
    },
  },
  account: {
    subscriptionExpiresOn: {
      en: (date: string) => {
        const past = new Date(date) < new Date();
        return `Your subscription ${past ? "expired on" : "is valid until"} ${date}.`;
      },
      de: (date: string) => {
        const past = new Date(date) < new Date();
        return `Ihr Abonnement ist ${past ? "am" : "gültig bis zum"} ${date}${past ? " abgelaufen" : ""}.`;
      },
      es: (date: string) => {
        const past = new Date(date) < new Date();
        return `Su suscripción ${past ? "expiró el" : "es válida hasta el"} ${date}.`;
      },
      fr: (date: string) => {
        const past = new Date(date) < new Date();
        return `Votre abonnement ${past ? "a expiré le" : "est valable jusqu'au"} ${date}.`;
      },
      "pt-br": (date: string) => {
        const past = new Date(date) < new Date();
        return `Sua assinatura ${past ? "expirou em" : "é válida até"} ${date}.`;
      },
      "zh-cn": (date: string) => {
        const past = new Date(date) < new Date();
        return `您的订阅${past ? "已于" : "有效期至"}${date}。`;
      },
      "zh-tw": (date: string) => {
        const past = new Date(date) < new Date();
        return `您的訂閱${past ? "已於" : "有效期至"}${date}。`;
      },
      ja: (date: string) => {
        const past = new Date(date) < new Date();
        return `あなたのサブスクリプションは${past ? `${date}に期限が切れました` : `${date}まで有効です`}。`;
      },
    },
    subscriptionWarning: {
      en: (days: number) =>
        `Your subscription will be auto-renewed ${
          days === 0 ? "today" : days === 1 ? "in 1 day" : `in ${days} days`
        } unless canceled or changed.`,
      de: (days: number) =>
        `Ihr Abonnement wird ${
          days === 0 ? "heute" : days === 1 ? "in 1 Tag" : `in ${days} Tagen`
        } automatisch verlängert, sofern es nicht gekündigt oder geändert wird.`,
      es: (days: number) =>
        `Su suscripción se renovará automáticamente ${
          days === 0 ? "hoy" : days === 1 ? "en 1 día" : `en ${days} días`
        } a menos que se cancele o cambie.`,
      fr: (days: number) =>
        `Votre abonnement sera renouvelé automatiquement ${
          days === 0 ? "aujourd'hui" : days === 1 ? "dans 1 jour" : `dans ${days} jours`
        } sauf annulation ou modification.`,
      "pt-br": (days: number) =>
        `Sua assinatura será renovada automaticamente ${
          days === 0 ? "hoje" : days === 1 ? "em 1 dia" : `em ${days} dias`
        } a menos que seja cancelada ou alterada.`,
      "zh-cn": (days: number) =>
        `您的订阅将自动续订 ${
          days === 0 ? "今天" : days === 1 ? "在 1 天内" : `在 ${days} 天内`
        }，除非已取消或更改。`,
      "zh-tw": (days: number) =>
        `您的訂閱將自動續訂 ${
          days === 0 ? "今天" : days === 1 ? "在 1 天內" : `在 ${days} 天內`
        }，除非已取消或更改。`,
      ja: (days: number) =>
        `ご利用のサブスクリプションは ${
          days === 0 ? "今日" : days === 1 ? "1日以内" : `${days}日以内`
        } に自動更新されます。キャンセルまたは変更されない限り。`,
    },
    subscriptionExpiryWarning: {
      en: (days: number) =>
        `Your subscription ${
          days === 0
            ? "has expired, and you no longer have unlimited access."
            : `will expire in ${days} days, and usage limits under the non-unlimited plan will be reinstated unless the subscription is renewed.`
        }`,
      de: (days: number) =>
        `Ihr Abonnement ${
          days === 0
            ? "ist abgelaufen, und Sie haben keinen unbegrenzten Zugriff mehr."
            : `läuft in ${days} Tagen ab, und die Nutzungseinschränkungen des Nicht-Unlimited-Plans werden wieder eingesetzt, es sei denn, das Abonnement wird erneuert.`
        }`,
      es: (days: number) =>
        `Su suscripción ${
          days === 0
            ? "ha expirado, y ya no tiene acceso ilimitado."
            : `expirará en ${days} días, y los límites de uso bajo el plan no ilimitado se restablecerán a menos que se renueve la suscripción.`
        }`,
      fr: (days: number) =>
        `Votre abonnement ${
          days === 0
            ? "a expiré, et vous n'avez plus accès illimité."
            : `expirera dans ${days} jours, et les limites d'utilisation sous le plan non illimité seront rétablies à moins que l'abonnement ne soit renouvelé.`
        }`,
      "pt-br": (days: number) =>
        `Sua assinatura ${
          days === 0
            ? "expirou, e você não tem mais acesso ilimitado."
            : `expirará em ${days} dias, e os limites de uso do plano não ilimitado serão restabelecidos a menos que a assinatura seja renovada.`
        }`,
      "zh-cn": (days: number) =>
        `您的订阅${days === 0 ? "已过期，您不再拥有无限制访问权限。" : `将于${days} 天后到期，并且非无限计划的使用限制将恢复，除非订阅续订。`}`,
      "zh-tw": (days: number) =>
        `您的訂閱${days === 0 ? "已過期，您不再擁有無限制訪問權。" : `將於${days} 天內到期，並且非無限計劃的使用限制將恢復，除非訂閱續訂。`}`,
      ja: (days: number) =>
        `ご利用のサブスクリプションは${days === 0 ? "期限が切れ、無制限アクセスを利用できなくなっています。" : `${days}日以内に終了し、無制限プランではない使用制限が復元されますので、サブスクリプションを更新してください。`}`,
    },
    forceLogoutPrompt: {
      de: (keyword: string) =>
        `Nicht alle Daten werden synchronisiert; das Abmelden führt zu Datenverlust. Wenn Sie sich trotzdem abmelden wollen, geben Sie ein: ${keyword}`,
      en: (keyword: string) =>
        `Not all data is synced; logout will result in data loss. If you still want to log out, type: ${keyword}`,
      es: (keyword: string) =>
        `No todos los datos están sincronizados; el cierre de sesión provocará la pérdida de datos. Si todavía quiere cerrar la sesión, escriba: ${keyword}`,
      fr: (keyword: string) =>
        `Toutes les données ne sont pas synchronisées ; la déconnexion entraînera une perte de données. Si vous voulez toujours vous déconnecter, tapez : ${keyword}`,
      "pt-br": (keyword: string) =>
        `Nem todos os dados estão sincronizados; desconectar-se resultará em perda de dados. Se você ainda quiser sair da sessão, digite: ${keyword}`,
      "zh-cn": (keyword: string) => `数据未同步：确定登出请输入: ${keyword}`,
      "zh-tw": (keyword: string) => `數據未同步：確定登出請輸入: ${keyword}`,
      ja: (keyword: string) =>
        `すべてのデータが同期されているわけではありませんので、ログアウトするとデータが失われます。それでもログアウトする場合は、次のように入力します。${keyword}`,
    },
    reviewGateWarnReason: {
      en: (n: number) =>
        `You're approaching the daily review limit. Once you reach ${n} reviews, you'll need to upgrade to study more today. Upgrade now to remove this message.`,
      de: (n: number) =>
        `Sie nähern sich dem täglichen Bewertungslimit. Sobald Sie ${n} Bewertungen erreicht haben, müssen Sie ein Upgrade durchführen, um heute noch mehr zu lernen. Aktualisieren Sie jetzt, um diese Meldung zu entfernen.`,
      fr: (n: number) =>
        `Vous approchez de la limite de révision quotidienne. Une fois que vous aurez atteint ${n} critiques, vous devrez passer à la version supérieure pour étudier davantage aujourd'hui. Passez à la version supérieure maintenant pour supprimer ce message.`,
      es: (n: number) =>
        `Te estás acercando al límite de revisiones diarias. Cuando llegues a ${n} opiniones, tendrás que actualizarte para estudiar más hoy. Actualiza ahora para eliminar este mensaje.`,
      "pt-br": (n: number) =>
        `Você está se aproximando do limite de revisão diária. Quando você chegar a ${n} revisões, você precisará se atualizar para estudar mais hoje. Atualize agora para remover esta mensagem.`,
      ja: (n: number) =>
        `1日のレビュー数の上限に近づいています。レビューが${n}件に達したら、今日からもっと勉強するためにアップグレードが必要です。このメッセージを削除するに>は、今すぐアップグレードしてください。`,
      "zh-cn": (n: number) =>
        `您接近每日复习限制。一旦达到${n}次复习，您将需要升级以便今天学习更多。立即升级以移除此消息。`,
      "zh-tw": (n: number) =>
        `您接近每日複習限制。一旦達到${n}次複習，您將需要升級以便今天學習更多。立即升級以移除此訊息。`,
    },
  },
  general: {
    studyNotification: {
      de: (deckName: string) =>
        `Vergiss nicht zu lernen! Es ist schon einen Tag her, dass du ${deckName} geprüft hast.`,
      en: (deckName: string) =>
        `Don't forget to study! It's been a day since you've reviewed ${deckName}.`,
      es: (deckName: string) =>
        `¡No te olvides de estudiar! Hace un día que no revisas ${deckName}.`,
      fr: (deckName: string) =>
        `N'oubliez pas d'étudier ! Cela fait un jour que vous n'avez pas révisé ${deckName}.`,
      "pt-br": (deckName: string) =>
        `Não se esqueça de estudar! Já se passou um dia desde que você revisou ${deckName}.`,
      "zh-cn": (deckName: string) => `再忙也要记得复习哦！您上一次复习'${deckName}'是一天前.`,
      "zh-tw": (deckName: string) => `再忙也要記得復習喔！您上一次復習'${deckName}'是一天前.`,
      ja: (deckName: string) => `勉強を忘れずに!${deckName}を復習してから1日経ったね。`,
    },
    studyNotification3Days: {
      de: (deckName: string) =>
        `Vergiss nicht zu lernen! Es ist schon ein paar Tage her, dass du ${deckName} geprüft hast.`,
      en: (deckName: string) =>
        `Don't forget to study! It's been a few days since you've reviewed ${deckName}.`,
      es: (deckName: string) =>
        `¡No te olvides de estudiar! Hace unos días que no revisas ${deckName}.`,
      fr: (deckName: string) =>
        `N'oubliez pas d'étudier ! Cela fait quelques jours que vous n'avez pas revu ${deckName}.`,
      "pt-br": (deckName: string) =>
        `Não se esqueça de estudar! Já se passaram alguns dias desde que você revisou ${deckName}.`,
      "zh-cn": (deckName: string) => `再忙也要记得复习哦！您上一次复习'${deckName}'是几天前.`,
      "zh-tw": (deckName: string) => `再忙也要記得復習喔！您上一次復習'${deckName}'是几天前.`,
      ja: (deckName: string) => `勉強を忘れずに!${deckName}を復習してから数日経ちましたね。`,
    },
    studyNotification1Week: {
      de: (deckName: string) =>
        `Vergiss nicht zu lernen! Es ist schon eine Woche her, dass du ${deckName} geprüft hast.`,
      en: (deckName: string) =>
        `Don't forget to study! It's been a week since you've reviewed ${deckName}.`,
      es: (deckName: string) =>
        `¡No te olvides de estudiar! Hace una semana que no revisas ${deckName}.`,
      fr: (deckName: string) =>
        `N'oubliez pas d'étudier ! Cela fait une semaine que vous n'avez pas révisé ${deckName}.`,
      "pt-br": (deckName: string) =>
        `Não se esqueça de estudar! Faz uma semana desde que você revisou ${deckName}.`,
      "zh-cn": (deckName: string) => `再忙也要记得复习哦！您上一次复习'${deckName}'是一周前.`,
      "zh-tw": (deckName: string) => `再忙也要記得復習喔！您上一次復習'${deckName}'是一周前.`,
      ja: (deckName: string) => `勉強を忘れずに!${deckName}を復習してから1週間が経ちましたね。`,
    },
  },
};
