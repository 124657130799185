import CardPreviewHeader, { MarkedIcon, IgnoredIcon } from "@components/cardPreviewHeader";
import cardPriority from "@core/cardPriority";
import { Grade } from "@core/grade";
import { Deck, DeckFields, IDeck } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import { Layout } from "@models/layout";
import { useCardModal } from "@screens/card";
import Card, { reviewCardClass } from "cardRendering/card";
import color from "color";
import { ALL_LAYOUT_ID, useIsLegacyDeck } from "fields/magicLayout";
import Globals from "globals";
import Lib from "lib";
import L10n from "localization";
import React from "react";
import { defaultCardFontSize } from "settings";
import Style from "style";

export interface ICardPreviewProps {
  deck: IDeck;
  knol: IKnol | undefined;
  autoplayAudio?: boolean;
  editable?: boolean;
  border?: boolean;
  disableTouchEvents?: boolean;
  visibleSide?: 0 | 1;
  onFieldClick?: (fieldKey: string) => void;
  onFieldBlur?: (fieldKey: string) => void;
  isClickable?: boolean;
  rubberbandScroll?: boolean;
  unfold?: boolean;
  stack?: boolean;
  persistDownloadedBlobs?: boolean;
  disableAudioControlsRendering?: boolean;
  mini?: boolean;
  showHeader?: boolean;
  editingValues?: Record<string, string>;
  query?: string;
  cardHeight?: number | "100%" | "50%";
  cardWidth?: number | "100%" | "48%";
  onClick?: () => void;
  fill?: boolean;
  overflow?: string;
  style?: React.CSSProperties;
}

export default function CardPreview(props: ICardPreviewProps) {
  const {
    autoplayAudio = false,
    deck,
    knol,
    query,
    editable = false,
    disableTouchEvents = true,
    onFieldClick = () => {
      return;
    },
    onFieldBlur = () => {
      return;
    },
    isClickable,
    rubberbandScroll,
    unfold = false,
    stack = false,
    persistDownloadedBlobs,
    disableAudioControlsRendering,
    mini, // used for layout market
    visibleSide,
    showHeader,
    editingValues,
    cardHeight = 280,
    cardWidth = 240,
    onClick,
    fill,
  } = props;

  const activeSide = visibleSide ?? 0;

  const values = editingValues ?? knol?.values ?? {};

  // Used for the header to indicate marked or ignored cards
  const tags = knol?.tags ?? [];

  const legacy = useIsLegacyDeck(deck);

  const [presentCardModal] = useCardModal({
    knol,
    deck,
  });

  let templates: string[];
  let css: string;

  if (legacy) {
    const layouts = deck.layouts ?? [];
    let activeLayout = layouts.find((layout) => layout.id === deck?.layout_id);
    if (!activeLayout) {
      // This handles "ALL_LAYOUT_ID" by showing all values
      const keys = Object.keys(values);
      activeLayout = {
        id: ALL_LAYOUT_ID,
        name: Layout.ALL_LAYOUT_VALUE,
        status: 0,
        response_type_id: Globals.basicResponseType.id,
        templates: Layout.defaultTemplates(keys),
        style: "",
      };
    }
    templates = activeLayout?.templates ?? [];
    css = activeLayout?.style ?? "";
  } else {
    if (deck?.config?.fields && deck.config.fields.length > 0) {
      templates = Layout.templatesFor(deck.config?.fields);
      const order = Deck.fieldOrderFromFields(deck.config.fields);
      css = Layout.defaultCss(order);
    } else {
      // synthesize fields based on the values
      const fields: DeckFields = [];
      const keys = Deck.fieldOrderFromDeckOrKnolValues(deck, deck.config?.fields, values);
      for (const key of keys) {
        fields.push({
          name: key,
          type: "richtext",
          sides: [1, 1], // All keys on both sides
        });
      }
      templates = Layout.templatesFor(fields);
      const order = Deck.fieldOrderFromFields(fields);
      css = Layout.defaultCss(order);
    }
  }

  let header: JSX.Element | null;
  const g = knol ? Grade(cardPriority(Knol.GetGrade(knol))) : undefined;
  const gradeColor = g ? Style.colors.grades[g] : undefined;
  if (showHeader && g) {
    const label = g ? L10n.localize((s) => s.grade[g]) : "";
    header = g && (
      <CardPreviewHeader bgColor={gradeColor}>
        <span>{label.toString()}</span>
        {tags.indexOf("marked") > -1 && <MarkedIcon />}
        {tags.indexOf("ignored") > -1 && <IgnoredIcon />}
      </CardPreviewHeader>
    );
  }

  const cardsWrapperStyle: React.CSSProperties = {
    textAlign: "center",
    display: "flex",
    width: "100%",
    flex: 1,
    flexDirection: stack ? "column" : "row",
  };

  const sideContainerStyle: React.CSSProperties = {
    flex: 1,
    flexDirection: "column",
    display: "flex",
  };

  const sideLabelStyle: React.CSSProperties = {
    textTransform: "uppercase",
    fontWeight: 300,
    fontSize: 10,
    textAlign: "center",
    // marginBottom: 2,
  };

  const fontSizePx = deck.user_config?.settings?.cardFontSize ?? defaultCardFontSize;

  return (unfold ? ([0, 1] as const) : [activeSide]).map((side) => (
    <button
      type="button"
      onClick={isClickable ? (onClick ? onClick : presentCardModal) : undefined}
      key={(knol?.id ?? Lib.uuid16()) + side}
      className={reviewCardClass}
      disabled={!isClickable}
      style={{
        ...props.style,
        display: "flex",
        flex: fill ? 1 : "initial",
        flexDirection: "column",
        borderRadius: "6px",
        border:
          showHeader && gradeColor
            ? `${Globals.platform === "ios" ? "1px" : "2px"} solid ${color(gradeColor).alpha(0.8).string()}`
            : undefined,
        position: "relative",
        width: cardWidth,
        height: cardHeight,
        minWidth: cardWidth,
        minHeight: cardHeight,
        zIndex: 9,
      }}
    >
      <div style={cardsWrapperStyle}>
        <div style={sideContainerStyle}>
          {unfold && (
            <div style={sideLabelStyle}>
              {L10n.localize((s) => (side === 0 ? s.general.frontSide : s.general.backSide))}
            </div>
          )}
          {header}
          <Card
            deckID={deck.id}
            query={query}
            flipStabilization="none"
            persistDownloadedBlobs={persistDownloadedBlobs}
            disableAudioControlsRendering={disableAudioControlsRendering}
            autoplayAudio={autoplayAudio}
            disableTouchEvents={disableTouchEvents}
            rubberbandScroll={rubberbandScroll}
            templates={templates}
            values={values}
            editable={editable}
            layoutID={deck.layout_id}
            onFieldClick={onFieldClick}
            onFieldBlur={onFieldBlur}
            style={css}
            fontSize={mini ? fontSizePx * 0.6 : fontSizePx}
            fields={deck.config?.fields}
            visibleSide={side}
            overflow={props.overflow}
          />
        </div>
      </div>
    </button>
  ));
}
