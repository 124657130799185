import Empty from "@components/empty";
import { folderDisplayString } from "@components/folderWithSubfoldersString";
import LoadingIndicator from "@components/loadingIndicator";
import MiddleEllipse from "@components/middleEllipse";
import { IonButton, IonRefresher, IonRefresherContent, RefresherEventDetail } from "@ionic/react";
import { TimerMode } from "@models/deckSettings";
import { basename } from "@models/folder";
import ScreenComponent from "components/screen";
import { clearDot } from "dots";
import EventBus from "eventBus";
import Globals, { TResponseValue } from "globals";
import trimFinalPathComponent from "lib/trimFinalPathComponent";
import L10n from "localization";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReviewControls from "screens/review/reviewControls";
import ReviewMenu from "screens/review/reviewMenu";
import { getBooleanSetting, updateSetting, useShowTags } from "settings";
import HUD from "./HUD";
import PauseResumeButton from "./pauseResumeButton";
import ReviewCard from "./reviewCard";
import { IReviewCardContent } from "./types";
import useKeyboardShortcutHandler from "./useKeyboardShortcutHandler";

export const reviewScreenID = "review-screen";

export interface ISessionInfo {
  cardIndex: number;
  numCards: number;
  deckName: string;
  deckTagName: string | undefined;
  reviewTimerMode: TimerMode;
  timerMillis?: number;
}

interface IProps extends ISessionInfo {
  loading: boolean;
  paused: boolean;
  content: IReviewCardContent;
  handleFlip: () => void;
  reloadPresentationData: () => void;
  skipToRecap: () => void;
  handleResponse: (response: TResponseValue) => void;
  error?: unknown;
  reloadSession: () => void;
  setPaused: (paused: boolean) => void;
}
export default function ReviewScreen(props: IProps): JSX.Element {
  const {
    cardIndex,
    numCards,
    timerMillis,
    handleFlip,
    reloadPresentationData,
    deckTagName,
    reviewTimerMode,
    handleResponse,
    content,
    loading,
    error,
    reloadSession,
    paused,
    setPaused,
  } = props;

  const displayName = basename(content.deck?.name ?? deckTagName ?? "");

  const empty = numCards < 1 && !loading;
  const showBottomButton = !error && !loading && !empty;

  // NOTE: this ref + the useEffect after the useCallback is necessary because the useCallback would have no mechanism to clean up the EventBus listener if it registered one itself.
  const orchestrationTimeoutHandleRef = useRef<NodeJS.Timeout>();

  const flip = useCallback(() => {
    handleFlip();
    if (["semi-auto", "full-auto"].includes(reviewTimerMode)) {
      clearTimeout(orchestrationTimeoutHandleRef.current);
      EventBus.emit("resetTimer");
    }
  }, [handleFlip, reviewTimerMode]);

  const respond = useCallback(
    (response: TResponseValue) => {
      handleResponse(response);
      if (["semi-auto", "full-auto"].includes(reviewTimerMode)) {
        clearTimeout(orchestrationTimeoutHandleRef.current);
        EventBus.emit("resetTimer");
      }
    },
    [handleResponse, reviewTimerMode],
  );

  const advance = useCallback(() => {
    respond(Globals.RESPONSES.AUTO);
  }, [respond]);

  const handleOrchestrationComplete = useCallback(
    (side: number) => {
      const autoFlip = side === 0 && ["semi-auto", "full-auto"].includes(reviewTimerMode);
      const autoAdvance = side === 1 && reviewTimerMode === "full-auto";

      if (autoFlip && timerMillis !== undefined) {
        EventBus.emit("startTimer", timerMillis);
        orchestrationTimeoutHandleRef.current = setTimeout(flip, timerMillis);
      } else if (autoAdvance && timerMillis !== undefined) {
        EventBus.emit("startTimer", timerMillis);
        orchestrationTimeoutHandleRef.current = setTimeout(advance, timerMillis);
      }
    },
    [flip, advance, reviewTimerMode, timerMillis],
  );
  useEffect(() => {
    function handlePause() {
      clearTimeout(orchestrationTimeoutHandleRef.current);
    }
    EventBus.on("pauseTimer", handlePause);
    return () => {
      EventBus.off("pauseTimer", handlePause);
    };
  }, []);

  const initalIsTheaterMode = getBooleanSetting("isTheaterMode");
  const [isTheaterMode, setIsTheaterMode] = useState(initalIsTheaterMode);
  const toggleTheaterMode = useCallback(() => {
    clearDot("theater_mode_menu_item").catch(() => {});
    updateSetting("isTheaterMode", !isTheaterMode).then((newSettings) =>
      setIsTheaterMode(newSettings.isTheaterMode),
    );
  }, [isTheaterMode]);

  const [promptIsShowing, setPromptIsShowing] = React.useState(false);
  const modalIsShowingRef = React.useRef(false);
  function setModalIsShowing(showing: boolean) {
    modalIsShowingRef.current = showing;
  }

  useKeyboardShortcutHandler(
    promptIsShowing,
    modalIsShowingRef,
    content.activeSideNum,
    flip,
    respond,
    "review",
  );

  const [showTags, toggleShowTags] = useShowTags();

  const handlePause = useCallback(() => {
    setPaused(true);
  }, [setPaused]);
  const handleResume = useCallback(() => {
    setPaused(false);
  }, [setPaused]);

  const pausedByMenu = useRef(false);
  const handleMenuOpened = useCallback(() => {
    if (!paused) {
      pausedByMenu.current = true;
      setPaused(true);
    }
  }, [paused, setPaused]);
  const handleMenuClosed = useCallback(() => {
    if (pausedByMenu.current) {
      setPaused(false);
    }
    pausedByMenu.current = false;
  }, [setPaused]);

  const handleRefresh = useCallback((event: CustomEvent<RefresherEventDetail>) => {
    EventBus.emit("replayAudio");
    event.detail.complete();
  }, []);

  return (
    <ScreenComponent
      id={reviewScreenID}
      isTheaterMode={isTheaterMode}
      title={
        isTheaterMode ? (
          <MiddleEllipse text={displayName} style={{ justifyContent: "center" }} />
        ) : (
          L10n.localize((s) => s.review.singular)
        )
      }
      noBigTitle
      defaultBackLink={trimFinalPathComponent(location.pathname)}
      rightButton={
        empty || !content.knol ? undefined : (
          <ReviewMenu
            cardContent={content}
            isFirstCard={cardIndex === 0}
            skipToRecap={props.skipToRecap}
            setPromptIsShowing={setPromptIsShowing}
            setModalIsShowing={setModalIsShowing}
            showTags={showTags}
            toggleShowTags={toggleShowTags}
            toggleTheaterMode={toggleTheaterMode}
            isTheaterMode={isTheaterMode}
            reloadPresentationData={reloadPresentationData}
            onOpen={handleMenuOpened}
            onClose={handleMenuClosed}
          />
        )
      }
      bottomButton={
        showBottomButton ? (
          reviewTimerMode === "full-auto" ? (
            <PauseResumeButton
              paused={paused}
              onPause={handlePause}
              onResume={handleResume}
              isTheaterMode={isTheaterMode}
            />
          ) : (
            <ReviewControls
              enabled={!props.loading}
              activeSideNum={content.activeSideNum}
              handleFlip={flip}
              handleResponse={respond}
              isTheaterMode={isTheaterMode}
            />
          )
        ) : undefined
      }
      content={
        error ? (
          <Empty
            text={L10n.localize((s) => s.review.failedToLoadSession)}
            actionEl={
              <IonButton onClick={reloadSession}>{L10n.localize((s) => s.actions.retry)}</IonButton>
            }
          />
        ) : empty ? (
          <Empty text={L10n.localize((s) => s.card.none)} />
        ) : (
          <>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            {loading ? <LoadingIndicator eventName="cardBlobsPreloaded" /> : undefined}
            <HUD
              deckName={displayName}
              deckTagName={deckTagName ? folderDisplayString(deckTagName) : undefined}
              activeCardIndex={cardIndex}
              numCards={numCards}
              showTags={showTags}
              cardTags={content.knol?.tags ?? []}
              timerMillis={timerMillis}
              timerMode={reviewTimerMode}
              isTheaterMode={isTheaterMode}
            />
            {loading ? undefined : (
              <ReviewCard
                content={content}
                hasFooter={showBottomButton}
                onPause={handlePause}
                onOrchestrationComplete={handleOrchestrationComplete}
                isTheaterMode={isTheaterMode}
              />
            )}
          </>
        )
      }
    />
  );
}
