import MultiSelectModal from "@components/multiSelectModal";
import { filterIterable } from "@lib/iterable";
import { Deck, IDeck, archiveTag } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import logEvent from "analytics";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import { superCache } from "hooks/data/superCache";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import useErrorAlert from "hooks/util/useErrorAlert";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { checkmarkCircleOutline } from "ionicons/icons";
import L10n from "localization";

export default function useCopyKnols(deck: IDeck | undefined, knols: IKnol[]) {
  const decks = filterIterable(
    superCache.decks?.values() ?? [],
    (deck) => !deck.tags?.includes(archiveTag),
  );
  const privateDecks = filterIterable(decks, (d) => d.status === Deck.STATUS_PRIVATE);
  const otherDecks = filterIterable(
    privateDecks,
    (d) =>
      d.id !== deck?.id &&
      (deck?.layout_id === MAGIC_LAYOUT_ID ? d.layout_id === MAGIC_LAYOUT_ID : true),
  );

  const sortedDecks = Array.from(otherDecks).sort((d1, d2) =>
    (d1.name || "").toLowerCase() < (d2.name || "").toLowerCase() ? -1 : 1,
  );

  const [showCopyCardError] = useErrorAlert({ code: "COPYING_CARDS" });
  const [showModal, dismiss] = useIonCardModalWithDismiss(MultiSelectModal<IDeck>, {
    title: L10n.localize((s) => s.card.copy),
    selectLabel: L10n.localize((s) => s.actions.copy),
    description: L10n.localize((s) => s.card.copyCompatibilityWarning),
    options: sortedDecks.map((deck) => {
      const name = Deck.displayName(deck);
      return {
        name: name,
        val: deck,
      };
    }),
    onSelect: (decks: IDeck[]) => {
      if (!decks || knols.length < 1) {
        return;
      }
      if (!knols.every((knol) => knol.values)) {
        return;
      }

      const promises = knols.flatMap((knol) => decks.map((deck) => Knol.Copy(knol, deck.id)));
      Promise.all(promises)
        .then(() => {
          logEvent("card_copied");
          presentCopyCardToast({
            message: L10n.localize((s) => s.general.done),
            icon: checkmarkCircleOutline,
            color: "success",
            duration: 1000,
          });
        })
        .catch((err) => showCopyCardError(err))
        .finally(() => dismiss());
    },
  });

  const [presentCopyCardToast] = useDismissibleToast();
  return showModal;
}
