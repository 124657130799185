import {
  IonButton,
  IonIcon,
  IonItem,
  IonList,
  IonTextarea,
  TextareaCustomEvent,
} from "@ionic/react";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { timeOutline } from "ionicons/icons";
import L10n from "localization";
import React, { useCallback } from "react";
import { LuPaperclip, LuWand2 } from "react-icons/lu";
import PromptHistoryModal, { updatePromptHistory } from "./promptHistoryModal";

interface IProps {
  disabled: boolean;
  maxChars: number;
  prompt: string | null | undefined;
  setPrompt: (prompt: string | null | undefined) => void;
  generate: () => void;
  pickPhoto: () => void;
  enableText: boolean | undefined;
  enablePhoto: boolean | undefined;
}

export default function PromptInput({
  disabled,
  prompt,
  setPrompt,
  generate,
  maxChars,
  pickPhoto,
  enableText,
  enablePhoto,
}: IProps) {
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // Check if CMD (or CTRL for non-Mac users) is pressed along with ENTER
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        handleGenerate();
        return;
      }
    },
    [generate],
  );

  const handleGenerate = () => {
    if (prompt) {
      updatePromptHistory(prompt);
    }
    generate();
  };

  const [showHistoryModal] = useIonCardModalWithDismiss(PromptHistoryModal, {
    onSelect: setPrompt,
  });

  return (
    <IonList>
      <IonItem lines="none" className="prompt-input-item">
        <IonButton
          slot="start"
          aria-label="attach"
          size="default"
          fill="clear"
          disabled={disabled || !enablePhoto}
          onClick={pickPhoto}
          style={{ "--padding-start": 0, "--padding-end": 0 }}
        >
          <LuPaperclip size={24} />
        </IonButton>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <IonTextarea
            rows={1}
            autoGrow
            placeholder={L10n.localize((s) => s.general.promptPlaceholderText)}
            value={prompt}
            onIonInput={(e: TextareaCustomEvent) => setPrompt(e.detail.value)}
            disabled={disabled || !enableText}
            onKeyDown={handleKeyDown}
            counter
            maxlength={maxChars}
            style={{ marginBottom: 8, maxHeight: 240, overflowY: "auto" }}
          />
          <IonButton onClick={showHistoryModal} disabled={disabled} fill="clear" size="large">
            <IonIcon icon={timeOutline} />
          </IonButton>
        </div>
        <IonButton
          slot="end"
          aria-label={L10n.localize((s) => s.actions.generate)}
          size="default"
          onClick={handleGenerate}
          disabled={disabled}
          style={{ marginLeft: 8 }}
        >
          <LuWand2 size={24} />
        </IonButton>
      </IonItem>
    </IonList>
  );
}
