import { hidePrompt, showPrompt } from "@components/prompt";
import { IDB_FALSE } from "@data/idb";
import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonNote,
} from "@ionic/react";
import { Deck, IDeck, IDeckConfig } from "@models/deck";
import { ILayout } from "@models/layout";
import DeckDownloadScreenFC from "@screens/deckDownload";
import logEvent from "analytics";
import EventBus from "eventBus";
import Globals from "globals";
import { History } from "history";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { closeCircle, folderOutline, helpCircleOutline } from "ionicons/icons";
import Lib from "lib";
import L10n from "localization";
import Network, { NetworkError } from "network";
import React, { useCallback } from "react";
import Style from "style";
import { ID } from "types/ID";

interface IImportResponse {
  decks: {
    id: string;
    name: string;
    layout_id: ID;
    layouts: ILayout[];
    created_at: string;
    tags?: string[];
    config?: IDeckConfig;
  }[];
}

function ImportedDeckItem(props: {
  history: History;
  deckID: string;
  name: string;
  createdAt: string;
  onDismiss: () => void;
}): JSX.Element {
  const [presentDownloadDeckModal] = useIonCardModalWithDismiss(DeckDownloadScreenFC, {
    history: props.history,
    deckID: props.deckID,
    onDismiss: props.onDismiss,
  });
  return (
    <IonItem
      key={props.deckID}
      button
      onClick={() => {
        presentDownloadDeckModal();
      }}
    >
      <IonLabel>
        <h2>{Deck.folderSeparatorFormattedName(props.name)}</h2>
        <p>
          {new Date(props.createdAt).toLocaleString(L10n.currentLocale, {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </p>
      </IonLabel>
    </IonItem>
  );
}

export default function ImportAPKG(props: {
  history: History;
  dismiss?: () => Promise<void>;
}): JSX.Element {
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<File>();
  const [blob, setBlob] = React.useState<Blob | null>(null);
  const fileChooserRef = React.useRef<HTMLInputElement>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    logEvent("import_apkg_selected_file", { numFiles: e.target.files?.length });
    const file = e.target.files?.[0];
    if (file) {
      if (file.name.match(/(.*?)\.(apkg|anki2)$/) === null) {
        alert(L10n.localize((s) => s.import.incorrectFileTypeAPKG));
        setIsDisabled(true);
        return;
      }

      // On Android, users can select files stored in Google Drive.
      // To successfully upload those, must immediately copy to a Blob.
      // See https://bugs.chromium.org/p/chromium/issues/detail?id=1063576&q=ERR_UPLOAD_FILE_CHANGED&can=2. NOTE: if you run a debugger on or prior to reaching this line, it won't work.
      let blob: Blob | null = null;
      if (Globals.platform === "android") {
        blob = await Lib.file2blob(file);
      }
      setFile(file);
      setBlob(blob);
      setIsDisabled(false);
    }
  };

  const resetForm = () => {
    if (fileChooserRef.current) {
      fileChooserRef.current.value = "";
    }
    setFile(undefined);
    setIsDisabled(true);
    setIsLoading(false);
  };

  const handleHelp = () => {
    logEvent("import_apkg_clicked_help");
    showPrompt({
      title: L10n.localize((s) => s.general.help),
      promptType: "custom",
      content: (
        <div style={{ textAlign: "center", color: Style.colors.primaryFg }}>
          <p>{L10n.localize((s) => s.import.apkgHelp1)}</p>
          <img
            style={{ maxWidth: "100%" }}
            src="https://static.ankiapp.com/20284f8d5c875cda73f443c98d0c60e524198fd4"
          />
          <br />
          <p>{L10n.localize((s) => s.import.apkgHelp2)}</p>
          <img
            style={{ maxWidth: "100%" }}
            src="https://static.ankiapp.com/181af18c3d8bf720d5f86b82d7fb5435ab3ea1c8"
          />
          <p>{L10n.localize((s) => s.import.apkgHelp3)}</p>
          <br />
        </div>
      ),
    });
  };

  const handleImport = () => {
    if (!file) {
      return;
    }
    setIsLoading(true);

    logEvent("import_apkg_submitted_file");

    Network.fetchWithMetadata<IImportResponse>({
      action: "POST",
      path: "/imports/anki",
      data: blob ?? file,
    })
      .then(async ([response, { status }]) => {
        if (status === 202) {
          showPrompt({
            title: L10n.localize((s) => s.general.attention),
            prompt: L10n.localize((s) => s.import.uploadedProcessingAPKG),
            promptType: "alert",
          });
        } else {
          const importedDecks = response?.decks?.map((deck) => {
            return { name: deck.name, id: deck.id, createdAt: deck.created_at };
          });

          const idbRows: IDeck[] = (response?.decks ?? []).map(
            ({ name, id, config, layout_id, layouts, created_at, tags }) => {
              return {
                id,
                name,
                created_at: new Date(created_at),
                modified_at: new Date(created_at),
                tags: tags,
                layout_id: layout_id,
                layouts: layouts,
                config: config,
                local: IDB_FALSE,
              };
            },
          );
          for (const row of idbRows) {
            await Deck.migrateDeckFromNet(row);
          }
          EventBus.emit("remoteDecksMigrated");

          EventBus.emit("deckImported");
          await props.dismiss?.();
          showPrompt({
            title: L10n.localize((s) => s.import.success),
            promptType: "custom",
            hasPadding: false,
            content: (
              <IonList>
                <IonListHeader>
                  <IonLabel>{L10n.localize((s) => s.deck.plural)}</IonLabel>
                </IonListHeader>
                {importedDecks?.map((d) => {
                  return (
                    <ImportedDeckItem
                      onDismiss={() => {
                        hidePrompt();
                        props.dismiss?.();
                      }}
                      history={props.history}
                      key={d.id}
                      deckID={d.id}
                      name={d.name}
                      createdAt={d.createdAt}
                    />
                  );
                })}
              </IonList>
            ),
          });
        }
      })
      .catch((e: NetworkError) => {
        if (e.statusCode >= 400 && e.statusCode < 500) {
          showPrompt({
            title: L10n.localize((s) => s.general.attention),
            prompt: `${L10n.localize((s) => s.import.errorAPKG)}\n\n${L10n.localize(
              (s) => s.import.errorAdditionalDetails,
            )}: <pre style="white-space: pre-wrap; font-size: small">${e.message}</pre>`,
            promptType: "alert",
          });
        } else {
          showPrompt({
            title: L10n.localize((s) => s.general.attention),
            prompt: L10n.localize((s) => s.error.communication),
            promptType: "alert",
          });
        }
      })
      .finally(() => {
        resetForm();
      });
  };

  let accept: string | undefined = ".apkg,.anki2"; // adding zip prevented iOS photo options, but prevented selecting .apkg.
  if (Globals.platform === "android") {
    // On Android, anything in the accept attribute was blocking the file dialog from opening.
    accept = undefined;
  }

  const handleFileChooserClicked = useCallback(() => {
    if (file) {
      if (fileChooserRef.current) {
        fileChooserRef.current.value = "";
      }
      setIsDisabled(true);
      setFile(undefined);
    } else {
      logEvent("import_apkg_clicked_file_chooser");
      fileChooserRef.current?.click();
    }
  }, [file]);

  return (
    <>
      <IonList inset>
        <IonItem lines="none">
          <IonLabel>{L10n.localize((s) => s.import.apkgInstructions1)}</IonLabel>
        </IonItem>
        <IonItem button detail={false} onClick={handleHelp}>
          <IonIcon slot="start" icon={helpCircleOutline} />
          <IonLabel color="medium">{L10n.localize((s) => s.import.needHelp)}</IonLabel>
        </IonItem>
      </IonList>

      <IonList inset>
        <IonItem button onClick={handleFileChooserClicked} detail={!file}>
          <IonIcon slot="start" color={file ? undefined : "primary"} icon={folderOutline} />
          <IonLabel color={file ? undefined : "primary"}>
            {file ? file.name : L10n.localize((s) => s.import.chooseFile)}
          </IonLabel>
          <input
            ref={fileChooserRef}
            style={{ display: "none" }}
            type="file"
            accept={accept}
            onChange={handleFileChange}
          />
          {file ? (
            <IonNote slot="end">
              <IonIcon icon={closeCircle} />
            </IonNote>
          ) : undefined}
        </IonItem>

        {!isDisabled && (
          <>
            <IonItem
              detail={false}
              button
              disabled={isDisabled || isLoading}
              onClick={handleImport}
            >
              <IonLabel color="primary" style={{ textAlign: "center" }}>
                {L10n.localize((s) => s.import.beginImport)}
              </IonLabel>
            </IonItem>
            <IonLoading
              cssClass="my-custom-class"
              isOpen={isLoading}
              message={L10n.localize((s) => s.import.pleaseWait)}
            />
          </>
        )}
      </IonList>
    </>
  );
}
