import { invisibleClass } from "@models/layout";
import { hintClass } from "hooks/util/useHint";
import React from "react";
import { ID } from "types/ID";

export const backOnlyClass = "back-only";

const DEFAULT_FRONT_TO_BACK_LAYOUT_ID = "acf3bd5e3fc94d64a9b21d7a531a6563";
const DEFAULT_BACK_TO_FRONT_LAYOUT_ID = "a4abf3245c0340c39e43216fcd714dfc";
const defaultLayouts = [DEFAULT_BACK_TO_FRONT_LAYOUT_ID, DEFAULT_FRONT_TO_BACK_LAYOUT_ID];

function isDefaultLayout(layoutID: string): boolean {
  return defaultLayouts.includes(layoutID);
}

interface ILayoutStyleProps {
  css: string;
  fontSize?: number;
  layoutID: ID | undefined;
  layoutClass?: string;
}

export default function LayoutStyle({
  css = "",
  fontSize,
  layoutID,
  layoutClass,
}: ILayoutStyleProps): React.ReactElement {
  if (!layoutID) {
    return <></>;
  }
  // HACK: make list items look nicer.
  const listCss = "ul,ol { padding-left:0; } ul,ol li { list-style-position:inside; }";

  const className = layoutClass ?? `layout_${layoutID}`;
  let finalCss = `${listCss} ${css || ""}`;

  if (fontSize !== undefined) {
    // NOTE: this will get namespaced (layout class selector prepended).

    const fontSizeWithPx = `${fontSize}px`;

    if (isDefaultLayout(layoutID)) {
      // HACK: special-case handling for default layouts, which hard-code a font size for their text. This overrides those.
      finalCss = `div:not(.card-edit-label,.card-edit-instructions) {font-size: ${fontSizeWithPx} !important;}${finalCss}`;
    } else {
      finalCss = `{font-size: ${fontSizeWithPx};}${finalCss}`;
    }
  }

  // Invisible field support.
  finalCss = `.${invisibleClass} { opacity: 0 }${finalCss}`;

  // Hint support.
  finalCss = `.side-0 .${hintClass} { opacity: 0 }${finalCss}`;
  finalCss = `.${hintClass}.side-0 .${hintClass} { opacity: 1 }${finalCss}`;

  // Annotation mode support.
  finalCss = `.side-0 .${backOnlyClass} { opacity: 0 }${finalCss}`;

  const namespacedCss = Array.from(finalCss.split(/}\s*/))
    .map((rule) => (rule === "" ? "" : `.${className} ${rule}`))
    .join("} ");

  return (
    <style
      id={className}
      className="layoutCss"
      type="text/css"
      dangerouslySetInnerHTML={{ __html: namespacedCss }}
    />
  );
}
