import { DeckFields } from "@models/deck";
import React, { useCallback } from "react";
import ConfigItem from "./configItem";
import { IConfigSelection } from "./main";

interface IProps {
  selected?: boolean;
  base?: string;
  name?: string;
  fields: DeckFields;
  values?: Record<string, string>;
  description?: string;
  onSelect: (cfg: IConfigSelection) => void;
  backOnly?: boolean;
  previewValuesHTML?: Record<string, string>;
}

function ConfigSelectorItem(props: IProps): JSX.Element {
  const { fields, base, onSelect, name, description, values } = props;
  const handleClick = useCallback(
    () => onSelect({ fields, base, name, description, values }),
    [base, description, fields, name, onSelect, values],
  );

  // If HTML is specified for preview values, short-circuit rendering (optimization to defer loading Japanese and Chinese dictionaries).
  const previewFields: DeckFields = props.previewValuesHTML
    ? fields.map((field) => ({ ...field, type: "richtext", attributes: undefined }))
    : fields;

  return (
    <ConfigItem
      {...props}
      fields={previewFields}
      values={props.previewValuesHTML ?? values}
      onClick={handleClick}
    />
  );
}

export default React.memo(ConfigSelectorItem);
