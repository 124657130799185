import BarChart from "@components/barChart";
import EventBus from "eventBus";
import { IResponsesByDate, getResponseHistoryForDeck } from "hooks/data/responseHistory";
import Lib from "lib";
import React from "react";
import Style from "style";

const NUM_HISTORY_DAYS = 7;

function defaultResponseHistory(numHistoryDays: number) {
  const responseHistory: Array<{ count: number; label: string }> = [];
  for (let daysAgo = numHistoryDays - 1; daysAgo >= 0; daysAgo--) {
    responseHistory.push({
      count: 0,
      label: Lib.dayName(daysAgo),
    });
  }
  return responseHistory;
}

function getLastNDates(n: number) {
  const dates = [];
  for (let i = n - 1; i >= 0; i--) {
    const date = new Date();
    date.setDate(date.getDate() - i);

    const formattedDate = [
      date.getFullYear(),
      (date.getMonth() + 1).toString().padStart(2, "0"),
      date.getDate().toString().padStart(2, "0"),
    ].join("-");

    dates.push(formattedDate);
  }
  return dates;
}

const HistoryResponses = (props: {
  deckID?: string;
  widescreenChartHeight?: string;
  knolsLastUpdated?: Date;
}) => {
  const { deckID, widescreenChartHeight } = props;
  const [deckHistory, setDeckHistory] = React.useState<IResponsesByDate | null>(null);
  const lastNDates = getLastNDates(NUM_HISTORY_DAYS);

  const fetchHistory = React.useCallback(() => {
    if (!deckID) {
      return;
    }

    getResponseHistoryForDeck(deckID).then((history) => {
      setDeckHistory(history);
    });
  }, [deckID]);

  React.useEffect(() => {
    fetchHistory();
    EventBus.on("responseInserted", fetchHistory);
    return () => {
      EventBus.off("responseInserted", fetchHistory);
    };
  }, [fetchHistory]);

  if (!deckID) {
    return (
      <BarChart
        height={widescreenChartHeight || undefined}
        actionable={true}
        count={true}
        bars={defaultResponseHistory(NUM_HISTORY_DAYS)}
      />
    );
  }

  const barsData = deckHistory
    ? lastNDates
        .reverse()
        .map((date, index) => {
          const label = Lib.dayName(index);
          return { label, count: deckHistory.get(date) || 0 };
        })
        .reverse()
    : defaultResponseHistory(NUM_HISTORY_DAYS);

  return (
    <div
      style={{
        paddingLeft: Style.edgePadding,
        paddingRight: Style.edgePadding,
      }}
    >
      <BarChart
        height={widescreenChartHeight || undefined}
        actionable={true}
        count={true}
        bars={barsData}
      />
    </div>
  );
};

export default HistoryResponses;
