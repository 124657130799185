import { IFieldValueProps } from "fields/fieldType";
import { fieldTypeMap } from "fields/fields";
import { IFieldImage, sampleImageID, sampleImageURL } from "fields/types/image";
import useAsyncBlobVal from "hooks/util/useAsyncBlobVal";
import React from "react";

interface IProps extends IFieldValueProps<IFieldImage> {
  style?: React.CSSProperties;
}
export default function ImageValue({
  value,
  blobIdToObjectUrl,
  options,
  style,
}: IProps): JSX.Element {
  const parsedVal = fieldTypeMap.image.loadFML(value);
  const id = parsedVal?.id;
  const blobVal = useAsyncBlobVal(
    id,
    id === sampleImageID,
    sampleImageURL,
    blobIdToObjectUrl,
    options.persistDownloadedBlobs,
    undefined,
    options.deckID,
  );

  // biome-ignore lint/a11y/useAltText: causes alt text to show on cards https://suppzero.pages.dev/tickets?id=4c56a462-027f-4336-a355-339203bad6e6
  return <img src={blobVal?.url} style={style} />;
}
