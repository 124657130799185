import { subFolderMagicSeparator } from "@data/lib/folders";
import { syncOperationsAndRefetchRemoteDecks } from "@data/remoteDecks";
import { Deck, IDeck, archiveTag } from "@models/deck";
import EventBus from "eventBus";
import { debounce } from "lib/debounce";
import L10n from "localization";
import React, { useEffect, useState } from "react";
import { ID } from "types/ID";
import { useFolders } from "./folders";
import { superCache } from "./superCache";

export type RowType = "deck" | "folder";
export interface IDeckListDeckRow {
  deck: IDeck;
  name: string;
  type: "deck";
  modified_at: Date | undefined;
  timestamp?: Date;
  count?: number;
  folderPath?: string;
}
export interface IDeckListFolderRow {
  decks: IDeck[] | undefined;
  name: string;
  type: "folder";
  modified_at: Date | undefined;
  timestamp?: Date;
  count?: number;
  folderPath?: string;
}
export type IDeckOrFolderRow = IDeckListDeckRow | IDeckListFolderRow;

export function useDecks(opts?: {
  includeArchived?: boolean;
  archiveOnly?: boolean;
  inFolder?: string | null;
  includeSubfolders?: boolean;
}): IDeck[] {
  const inFolder = opts?.inFolder;
  const includeSubfolders = opts?.includeSubfolders;
  const [decks, setDecks] = useState<IDeck[]>(
    Deck.getAllDecksInFolders(inFolder, {
      archiveOnly: opts?.archiveOnly,
      includeArchived: opts?.includeArchived,
      includeSubFolders: includeSubfolders,
    }),
  );
  useEffect(() => {
    async function update() {
      setDecks(
        Deck.getAllDecksInFolders(inFolder, {
          archiveOnly: opts?.archiveOnly,
          includeArchived: opts?.includeArchived,
          includeSubFolders: includeSubfolders,
        }),
      );
    }
    const [debouncedUpdate, cancel] = debounce(update, 100);
    update();
    EventBus.on("remoteDecksMigrated", debouncedUpdate);
    EventBus.on("deckDownloadComplete", debouncedUpdate);
    superCache.events.on("deckUpdated", debouncedUpdate);
    superCache.events.on("deckMoved", debouncedUpdate);
    superCache.events.on("folderUpdated", debouncedUpdate);
    return () => {
      cancel();
      EventBus.off("remoteDecksMigrated", debouncedUpdate);
      EventBus.off("deckDownloadComplete", debouncedUpdate);
      superCache.events.off("deckUpdated", debouncedUpdate);
      superCache.events.off("deckMoved", debouncedUpdate);
      superCache.events.off("folderUpdated", debouncedUpdate);
    };
  }, [inFolder, includeSubfolders, opts?.archiveOnly, opts?.includeArchived]);
  return decks;
}

export function genDeckListWithFolders(
  decks: IDeck[],
  folders: string[],
  deckIDToNumKnols?: Record<ID, number>,
): IDeckOrFolderRow[] {
  const genRow = (deck: IDeck): IDeckOrFolderRow => {
    const numKnols = deckIDToNumKnols ? deckIDToNumKnols[deck.id] : undefined;
    return {
      deck,
      type: "deck",
      timestamp: deck.last_reviewed_at,
      count: numKnols,
      name: deck.name ?? L10n.localize((s) => s.general.none),
      modified_at: deck.modified_at,
    };
  };

  const decksInFolder: Record<string, Set<IDeck>> = {};
  for (const folder of folders) {
    decksInFolder[folder] = new Set<IDeck>();
    for (const deck of decks) {
      if (
        deck.tags?.some(
          (tag) => tag === folder || tag.startsWith(`${folder}${subFolderMagicSeparator}`),
        )
      ) {
        decksInFolder[folder].add(deck);
      }
    }
  }

  const folderRows = folders.map((folder) => {
    const decks = Array.from(decksInFolder[folder]);
    return deckRowForFolder(folder, decks);
  });

  const deckRows = decks.map((deck) => genRow(deck));
  const rows = deckRows.concat(folderRows ?? []);

  return rows.filter((row) => row.name !== archiveTag);
}

function deckRowForFolder(folder: string, decks: IDeck[]): IDeckOrFolderRow {
  const numDecks = decks.length;
  let updatedAt: Date | undefined = undefined;
  let lastReviewedAt: Date | null = null;
  for (const deck of decks) {
    if (!updatedAt || deck.modified_at > updatedAt) {
      updatedAt = deck.modified_at;
    }

    const lra = deck.last_reviewed_at;
    if (lra) {
      if (!lastReviewedAt || lra > lastReviewedAt) {
        lastReviewedAt = lra;
      }
    }
  }

  return {
    name: folder,
    type: "folder",
    modified_at: updatedAt,
    timestamp: lastReviewedAt || undefined,
    count: numDecks,
    folderPath: folder,
    decks,
  };
}

// inFolder === null means hide decks that are in a folder
// inFolder === undefined means include decks in a folder
export function useDeckListWithFolders(
  inFolder?: string | null,
  includeSubfolders = false,
): {
  rows: IDeckOrFolderRow[];
  refetch: () => Promise<void>;
} {
  const decks = useDecks({ inFolder, includeSubfolders });
  const folders = useFolders(inFolder);
  const [deckIDToNumKnols, setDeckIDToNumKnols] = React.useState<Record<ID, number>>();
  React.useEffect(() => {
    function updateNumKnols() {
      const numKnolsMap: Record<ID, number> = {};
      for (const deck of decks) {
        if (deck.local) {
          numKnolsMap[deck.id] = superCache.deckIDToKnolIDs?.get(deck.id)?.size ?? 0;
        }
      }
      setDeckIDToNumKnols(numKnolsMap);
    }

    updateNumKnols();
    superCache.events.on("knolUpdated", updateNumKnols);
    return () => {
      superCache.events.off("knolUpdated", updateNumKnols);
    };
  }, [decks]);

  let rows = genDeckListWithFolders(decks, folders, deckIDToNumKnols);
  if (inFolder === null) {
    rows = rows.filter((row) => {
      const isDeckInFolder = row.type === "deck" && row.deck?.tags && row.deck?.tags.length > 0;
      if (isDeckInFolder) {
        return false;
      }
      return true;
    });
  }

  return {
    rows,
    refetch: syncOperationsAndRefetchRemoteDecks,
  };
}

export function useDeckList(opts?: { archiveOnly?: boolean; includeArchived?: boolean }): {
  rows: IDeckOrFolderRow[];
  refetch: () => Promise<void>;
} {
  const decks = useDecks(opts);

  const genRow = (deck: IDeck): IDeckOrFolderRow => {
    return {
      deck,
      type: "deck",
      timestamp: deck.last_reviewed_at,
      name: deck.name ?? L10n.localize((s) => s.general.none),
      modified_at: deck.modified_at,
    };
  };

  const rows = decks.map((deck) => genRow(deck));

  return {
    rows,
    refetch: syncOperationsAndRefetchRemoteDecks,
  };
}
