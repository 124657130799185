import { StepLogger } from "analytics/funnel";
import { History } from "history";
import L10n from "localization";
import React from "react";
import GoalLanguageContextNav from "./goalLanguageContextNav";
import SingleSelectNav, { IPromptOption } from "./singleSelectNav";
import { BFF69 } from "fields/langCodes";
import { formatLangName } from "./genFlashcardsNav";

function proficiencyLevels() {
  return [
    {
      level: "0",
      name: L10n.localize((s) => s.onboarding.langProficiencyNone),
      desc: L10n.localize((s) => s.onboarding.langProficiencyNoneDesc),
    },
    {
      level: "A1",
      name: L10n.localize((s) => s.onboarding.langProficiencyA1),
      desc: L10n.localize((s) => s.onboarding.langProficiencyA1Desc),
    },
    {
      level: "A2",
      name: L10n.localize((s) => s.onboarding.langProficiencyA2),
      desc: L10n.localize((s) => s.onboarding.langProficiencyA2Desc),
    },
    {
      level: "B1",
      name: L10n.localize((s) => s.onboarding.langProficiencyB1),
      desc: L10n.localize((s) => s.onboarding.langProficiencyB1Desc),
    },
    {
      level: "B2",
      name: L10n.localize((s) => s.onboarding.langProficiencyB2),
      desc: L10n.localize((s) => s.onboarding.langProficiencyB2Desc),
    },
    {
      level: "C1",
      name: L10n.localize((s) => s.onboarding.langProficiencyC1),
      desc: L10n.localize((s) => s.onboarding.langProficiencyC1Desc),
    },
    {
      level: "C2",
      name: L10n.localize((s) => s.onboarding.langProficiencyC2),
      desc: L10n.localize((s) => s.onboarding.langProficiencyC2Desc),
    },
  ];
}

interface IProps {
  dismiss: () => Promise<void>;
  history: History;
  onAddToInbox: () => void;
  langName: string;
  langCode: BFF69;
  motivation: string;
  logStep: StepLogger;
}
export default function GoalLanguageProficiencyNav(props: IProps) {
  const opts: IPromptOption[] = proficiencyLevels().map((prof) => {
    return { name: prof.name, val: prof.level, desc: prof.desc };
  });

  return (
    <SingleSelectNav
      title={formatLangName(props.langName)}
      description={L10n.localize((s) => s.onboarding.langProficiencyNavDescription)}
      options={opts}
      dismiss={props.dismiss}
      history={props.history}
      onNav={(opt) => {
        return (
          <GoalLanguageContextNav
            langName={props.langName}
            langCode={props.langCode}
            motivation={opt.name}
            proficiency={opt.val}
            proficiencyDesc={opt.desc ?? ""}
            dismiss={props.dismiss}
            history={props.history}
            onAddToInbox={props.onAddToInbox}
            logStep={props.logStep}
          />
        );
      }}
    />
  );
}
