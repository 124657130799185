import { useIonAlert } from "@ionic/react";
import L10n from "localization";
import { useCallback } from "react";

export function useYesNoPrompt(header: string, message: string) {
  const [presentConfirmCancel] = useIonAlert();
  const presentDialog = useCallback(
    (handler: () => void | Promise<void>) => {
      presentConfirmCancel({
        header,
        message,
        buttons: [
          {
            role: "cancel",
            text: L10n.localize((s) => s.general.no),
          },
          {
            text: L10n.localize((s) => s.general.yes),
            handler,
          },
        ],
      });
    },
    [header, message, presentConfirmCancel],
  );
  return presentDialog;
}

export default function useConfirmCancellationDialog(handler: () => void) {
  const header = L10n.localize((s) => s.general.attention);
  const message = L10n.localize((s) => s.general.confirmDiscardChange);
  const showPrompt = useYesNoPrompt(header, message);
  return useCallback(() => {
    showPrompt(handler);
  }, [showPrompt, handler]);
}
