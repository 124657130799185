import React from "react";
import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
} from "@ionic/react";
import { personOutline, personRemove } from "ionicons/icons";

interface IProps {
  email: string;
  onRemove: (email: string) => void;
}
export default function ShareListItem(props: IProps) {
  const { email, onRemove } = props;

  function handleRemove() {
    onRemove(email);
  }

  return (
    <IonItemSliding>
      <IonItem>
        <IonIcon slot="start" icon={personOutline} />
        <IonLabel>
          <h2>{email}</h2>
        </IonLabel>
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption color="danger" onClick={handleRemove}>
          <IonIcon slot="icon-only" icon={personRemove} />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
}
