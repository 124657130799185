import React from "react";
import GenTable from "./genTable";
import GenCardPreviews from "./genCardPreviews";
import { IGeneratedRow } from "./lib";
import { IDeck } from "@models/deck";
import { IonSegment, IonSegmentButton } from "@ionic/react";
import { PiCards, PiGridNine } from "react-icons/pi";

interface IProps {
  deck: IDeck;
  genIndex: number;
  rows: IGeneratedRow[];
  columns: string[];
  updateRow: (genIndex: number, rowIndex: number, row: IGeneratedRow) => void;
}
export default function GenResults(props: IProps) {
  const [mode, setMode] = React.useState<"cards" | "table">("table");
  function handleModeChanged(e: any) {
    const browseMode = e.detail.value as any;
    setMode(browseMode);
  }
  return (
    <>
      <IonSegment
        style={{ width: 160, marginTop: 12 }}
        value={mode}
        onIonChange={handleModeChanged}
      >
        <IonSegmentButton value="table">
          <PiGridNine size={20} />
        </IonSegmentButton>
        <IonSegmentButton value="cards">
          <PiCards size={20} />
        </IonSegmentButton>
      </IonSegment>
      {mode === "table" ? <GenTable {...props} /> : <GenCardPreviews {...props} />}
    </>
  );
}
