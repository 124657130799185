import { useState } from "react";
import { IMultiSelectOption } from "./multiSelectSettingModal";
import React from "react";
import {
  IonPage,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonNote,
  IonSearchbar,
  SearchbarCustomEvent,
} from "@ionic/react";
import L10n from "localization";
import { fuzzyMatch } from "@lib/search";

interface IProps<T> {
  title: string;
  options: IMultiSelectOption<T>[];
  onSelect: (vals: T[]) => void;
  description?: string;
  dismiss: () => Promise<void>;
  selectLabel?: string;
  cancelLabel?: string;
}

export default function MultiSelectModal<T>(props: IProps<T>) {
  const [query, setQuery] = useState<string>("");

  const [selectedVals, setSelectedVals] = useState<Set<T>>(new Set());
  const optionsWithSelectedState = props.options.map((opt) => {
    return {
      ...opt,
      selected: selectedVals.has(opt.val),
    };
  });

  function handleChange(val: T, selected: boolean) {
    if (selected) {
      selectedVals.add(val);
    } else {
      selectedVals.delete(val);
    }
    const newSelectedVals = new Set(selectedVals);
    setSelectedVals(newSelectedVals);
  }

  async function handleDismiss() {
    props.onSelect(Array.from(selectedVals));
    await props.dismiss();
  }

  const optionsMatchingQuery = optionsWithSelectedState.filter((opt) => {
    return fuzzyMatch(query, opt.name);
  });

  return (
    <IonPage>
      <IonToolbar>
        <IonTitle>{props.title}</IonTitle>
        <IonButtons slot="secondary">
          <IonButton onClick={props.dismiss}>
            {props.cancelLabel ?? L10n.localize((s) => s.actions.cancel)}
          </IonButton>
        </IonButtons>
        <IonButtons slot="primary">
          <IonButton onClick={handleDismiss} disabled={selectedVals.size < 1}>
            {props.selectLabel ?? L10n.localize((s) => s.general.done)}
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonToolbar>
        <IonSearchbar
          value={query}
          onIonInput={(e: SearchbarCustomEvent) => {
            const newVal = e.detail.value;
            if (newVal !== undefined && newVal !== null) {
              setQuery(newVal);
            }
          }}
          placeholder={L10n.localize((s) => s.actions.search)}
        />
      </IonToolbar>
      <IonContent color="light" style={{ "--padding-bottom": "env(safe-area-inset-bottom)" }}>
        <IonList inset>
          {optionsMatchingQuery.map(({ name, val, desc, selected }) => {
            return (
              <IonItem key={JSON.stringify(val)} onClick={() => handleChange(val, !selected)}>
                <IonCheckbox checked={selected}>
                  <IonLabel className="ion-text-wrap">
                    {name}
                    {desc ? <p>{desc}</p> : undefined}
                  </IonLabel>
                </IonCheckbox>
              </IonItem>
            );
          })}
        </IonList>
        {props.description ? (
          <IonNote>
            <p className="ion-margin-horizontal ion-text-wrap">{props.description}</p>
          </IonNote>
        ) : undefined}
      </IonContent>
    </IonPage>
  );
}
