import { suppressPDPCancelSurvey } from "@components/pdpCancelSurvey";
import { setUnlimitedBasedOnApplePurchase } from "hooks/util/useHasUnlimited";
import React, { useCallback, useState } from "react";
import { IPDPContentProps } from "./pdpContent";
import { Products } from "./products";
import { useIAPProducts } from "./useIAPProducts";
import useIAPPurchaseHandler from "./useIAPPurchaseHandler";

interface IProps extends IPDPContentProps {
  onPurchaseComplete: () => void;
}
export default function ApplePDPContent({
  userID,
  email,
  logger,
  reason,
  onPurchaseComplete,
  dismiss,
}: IProps) {
  function handlePurchaseSuccess() {
    suppressPDPCancelSurvey();
    setPurchasePending(false);
    dismiss();
    setUnlimitedBasedOnApplePurchase();
    onPurchaseComplete();
  }

  const [purchasePending, setPurchasePending] = useState(false);
  const handlePurchasePending = useCallback(() => {
    setPurchasePending(true);
  }, []);

  const handlePurchaseFailure = useCallback(
    (err?: string) => {
      setPurchasePending(false);
      dismiss();
    },
    [dismiss],
  );

  const productsState = useIAPProducts(logger, handlePurchaseFailure);

  const onPurchase = useIAPPurchaseHandler({
    logger,
    onPurchaseFailure: handlePurchaseFailure,
    onPurchaseSuccess: handlePurchaseSuccess,
    onPurchasePending: handlePurchasePending,
    iapProducts: productsState,
    userID,
  });

  const loading = !productsState || purchasePending;

  return (
    <Products
      reason={reason}
      showAppleTerms
      onPurchase={onPurchase}
      products={productsState ?? []}
      productsLoading={loading}
      userID={userID}
      email={email}
      gateway="apple"
    />
  );
}
