import IDB from "@data/idb";
import { SSMLGender } from "@lib/tts";
import { ITTSBlobParams, ttsParamsHash } from "@lib/ttsParamsHash";
import { BFF69, validTTSLangCodes } from "fields/langCodes";
import Globals from "globals";
import { ID } from "types/ID";
import { IEvent } from "types/analytics";
import { blobHash } from "./lib";

export const blobWorkerSuccess = 0;
export const blobWorkerFailure = 1;

export interface IBlobUploadQueue {
  blobID: ID;
  knolID: ID;
}

export interface IBlobWorkerResult {
  status: typeof blobWorkerSuccess | typeof blobWorkerFailure;
  error?: unknown;
}

export interface IDeviceNetworkCredentials {
  deviceID: string;
  deviceToken: string | null;
}

export async function sendAnalyticsEvents(
  events: IEvent[],
  { deviceID, deviceToken }: IDeviceNetworkCredentials,
) {
  const headers: Record<string, string> = {
    "AnkiApp-Client-Version": Globals.version,
    "AnkiApp-Client-Id": deviceID,
    "Content-Type": "application/json",
  };
  if (deviceToken) {
    headers["AnkiApp-Client-Token"] = deviceToken;
  }

  return fetch(new URL(`${Globals.apiEndpoint}/dat`), {
    method: "POST",
    headers,
    body: JSON.stringify({ events }),
  });
}

function genFetchURL(blobID: ID, { deviceID, deviceToken }: IDeviceNetworkCredentials) {
  const fetchURL = new URL(`${Globals.blobsEndpoint}/${blobID}`);
  if (deviceToken) {
    fetchURL.searchParams.set("t", deviceToken);
  }
  fetchURL.searchParams.set("client_version", Globals.version);
  fetchURL.searchParams.set("client_id", deviceID);
  return fetchURL;
}

export async function downloadBlob(id: ID, creds: IDeviceNetworkCredentials): Promise<Blob | null> {
  const fetchURL = genFetchURL(id, creds);
  const resp = await fetch(fetchURL);
  return resp.blob();
}

interface ITTSResponse {
  blob: Blob;
  id: ID;
}
export async function renderTTS({
  text,
  lang,
  preferredGender,
  token,
}: {
  text: string;
  lang?: BFF69;
  preferredGender?: SSMLGender;
  token: string;
}): Promise<ITTSResponse | undefined> {
  const paramHash = ttsParamsHash({ text, lang, preferredGender });

  const fetchURL = new URL(`${Globals.ttsEndpoint}/${paramHash}`);
  fetchURL.searchParams.set("t", token);
  fetchURL.searchParams.set("text", text);
  if (lang) {
    if (!validTTSLangCodes.includes(lang)) {
      return;
    }
    fetchURL.searchParams.set("lang", lang);
  }
  if (preferredGender) {
    fetchURL.searchParams.set("preferredGender", preferredGender);
  }

  const resp = await fetch(fetchURL, { headers: {} });
  if (!resp.ok) {
    throw new Error(`TTS failed: error code ${resp.status}`);
  }
  const blob = await resp.blob();

  return { blob, id: paramHash };
}

export async function downloadTTSBlob(
  id: ID,
  ttsParams: ITTSBlobParams,
  creds: IDeviceNetworkCredentials,
): Promise<Blob | null> {
  if (!creds.deviceToken) {
    return null;
  }
  const resp = await renderTTS({ ...ttsParams, token: creds.deviceToken });
  if (resp?.id !== id) {
    return null;
  }
  return resp.blob;
}

export async function uploadBlob(
  id: ID,
  knolID: ID,
  blob: Blob,
  etag: string,
  creds: IDeviceNetworkCredentials,
): Promise<Response> {
  const fetchURL = genFetchURL(id, creds);
  return fetch(fetchURL, {
    method: "PUT",
    headers: {
      etag,
      "X-AnkiApp-Knol-ID": knolID,
    },
    body: blob,
  });
}

export async function downloadAndSaveBlobImmediately(
  db: typeof IDB.blobs,
  id: ID,
  deckID: ID,
  creds: IDeviceNetworkCredentials,
  ttsParams?: ITTSBlobParams,
): Promise<Blob | null> {
  // Pull ticket off the queue.
  await db.delete("queuedBlobs", id);

  const existingBlob = await db.get("blobs", id);
  if (existingBlob) {
    return existingBlob.blob;
  }

  const blob = ttsParams
    ? await downloadTTSBlob(id, ttsParams, creds)
    : await downloadBlob(id, creds);
  if (blob) {
    // console.time(`inserting blob ${id}`);

    try {
      await db.put("blobs", { id, deckID, blob });
    } catch (err) {
      // Put blob back on the queue, so it will be retried.
      await db.put("queuedBlobs", { id, deckID });
    }

    // console.timeEnd(`inserting blob ${id}`);
    return blob;
  }
  // TODO: Put blob back on queue? Or will that get into an infinite fetch issue? Probably need to check error code.
  // await db.queuedBlobs.put({ id, deckID });
  return null;
}

class BlobNetworkManagerDB {
  async downloadAndSaveBlob(
    id: ID,
    deckID: ID,
    ttsParams: ITTSBlobParams | undefined,
    creds: IDeviceNetworkCredentials,
  ): Promise<Blob | null> {
    return downloadAndSaveBlobImmediately(IDB.blobs, id, deckID, creds, ttsParams);
  }

  async hasBlobDownloadQueued(id: ID) {
    const dl = await IDB.blobs.get("queuedBlobs", id);
    return dl !== undefined;
  }

  async hasBlobUploadQueued(item: IBlobUploadQueue): Promise<boolean> {
    const ul = await IDB.blobs.get("blobUploadQueue", [item.blobID, item.knolID]);
    return ul !== undefined;
  }

  async uploadAndDequeueBlob(
    item: IBlobUploadQueue,
    creds: IDeviceNetworkCredentials,
  ): Promise<unknown> {
    const storedBlob = await IDB.blobs.get("blobs", item.blobID);
    if (!storedBlob) {
      return;
    }

    const etag = await blobHash(storedBlob.blob);

    await uploadBlob(item.blobID, item.knolID, storedBlob.blob, etag, creds);

    return IDB.blobs.delete("blobUploadQueue", [item.blobID, item.knolID]);
  }
}

const BlobNetworkManager = new BlobNetworkManagerDB();
export default BlobNetworkManager;
