import L10n from "localization";
import React from "react";
import { IProduct } from "./types";

interface IPriceProps {
  product?: IProduct;
  style?: React.CSSProperties;
}
export default function Price({ product, style }: IPriceProps) {
  if (!product) {
    return null;
  }

  if (product.eligible_for_free_trial && product.display_price) {
    if (product.freeTrialDuration === "1 week") {
      return (
        <div style={style}>
          {L10n.localize((s) => s.funcs.unlimited.freeTrial1Week)(product.display_price)}
        </div>
      );
    }
    if (product.freeTrialDuration === "3 days") {
      return (
        <div style={style}>
          {L10n.localize((s) => s.funcs.unlimited.freeTrial3Days)(product.display_price)}
        </div>
      );
    }
  }

  let price = product.display_price;
  if (product.display_price.endsWith(" USD") && !product.display_price.startsWith("$")) {
    price = `$${product.display_price}`;
  }

  return (
    <div style={style}>
      {product.frequency ? (
        <>
          <span>{price}</span>
          <span style={{ fontSize: "80%", marginLeft: 1, marginRight: 1 }}>/</span>
          <span style={{ fontSize: "80%" }}>{product.frequency}</span>
        </>
      ) : (
        <span>{L10n.localize((s) => s.funcs.unlimited.oneTimePurchase)(price)}</span>
      )}
    </div>
  );
}
