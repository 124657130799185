import { useIonViewWillLeave } from "@ionic/react";
import { defaultOrientation } from "@models/deckSettings";
import { Knol } from "@models/knol";
import { LensSpec, isFolderSpec, useLens } from "hooks/data/useLens";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAutoplayAudio } from "settings";
import RecapScreen from "./review/recapScreen";
import ReviewScreen from "./review/reviewScreen";
import { IReviewCardContent } from "./review/types";
import {
  IReviewSessionController,
  useReviewSessionController,
} from "./review/useReviewSessionController";
import { ID } from "types/ID";
import EventBus from "eventBus";

export interface IDeckAndKnolsUpdated {
  deckLastUpdated: Date;
  knolsLastUpdated: Date;
}

interface IProps {
  spec: LensSpec;
}

export const [FRONT, BACK] = [0, 1] as const;

export interface IActiveCard {
  index: number;
  side: 0 | 1;
  startTimeMs: number;
}

// Package up the data that the screen needs to render the current card.
function useCurrentCardContent(
  session: IReviewSessionController,
  knolsLastUpdated: Date | undefined,
): IReviewCardContent {
  return useMemo(() => {
    const state = session.state;
    state.presentationDataTimestamp; // Reminder that this is necessary, since session.presentationData is a ref.
    const presentationData = session.presentationData.current;

    const knolID = state.knols[state.activeCard.index]?.id;
    const knol = state.knolMap.get(knolID);
    const deck = session.state.decks.get(knol?.deck_id ?? "");
    const layout = session.state.knolIDToLayout.get(knolID);
    const orientation = state.cardOrientations[knolID] ?? defaultOrientation;
    const fontSizePx = state.settings.cardFontSize;
    const flipStabilization = state.settings.flipStabilization;
    const showGenSrcImage = state.settings.background?.genSrcImage;

    return {
      autoplayAudio: getAutoplayAudio() || state.settings.reviewTimer.mode === "full-auto",
      activeSideNum: state.activeCard.side,
      knol: knol,
      deck,
      fields: deck?.config?.fields ?? [],
      blobIDToURL: presentationData.blobIDToURL,
      sideBlobIDs: presentationData.knolIDToSideBlobIDs[knol?.id ?? ""],
      orientation,
      layout,
      fontSizePx,
      flipStabilization,
      showGenSrcImage,
      paused: state.paused,
    };
  }, [
    session.presentationData,
    session.state,
    session.state.presentationDataTimestamp,
    knolsLastUpdated,
  ]);
}

function genSessionID(): string {
  let result = "";
  const characters = "0123456789abcdef";
  const charactersLength = characters.length;
  for (let i = 0; i < 16; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export default function SessionScreen(props: IProps): JSX.Element {
  const [sessionID, setSessionID] = useState(genSessionID());
  const { decks, knols, filteredKnols, settings, knolsUpdatedAt, loading } = useLens(
    props.spec ?? "omni",
    { skipRefilterKnolsOnUpdate: true, sessionID },
  );
  const folder = isFolderSpec(props.spec) ? props.spec.folder : undefined;
  const deckName = folder ? folder : Array.from(decks?.values() ?? [])[0]?.name ?? "";

  // Initialize and return data necessary to perform a review session.
  // This includes a list of drawn cards as well as relevant deck settings and card content + metadata.
  const session = useReviewSessionController(
    loading ? undefined : decks, // HACK: lens currently returns valid MapViews even before content is fully-loaded. This simnulates using undefined to indicate not yet loaded.
    loading ? undefined : filteredKnols,
    settings,
  );

  // Begin session when this component first mounts.
  // Recap screen will trigger subsequent beginSession calls.
  useEffect(() => {
    session.beginReviewSession();
  }, [session.beginReviewSession]);

  const continueSession = useCallback(
    (src?: string) => {
      setSessionID(genSessionID()); // Force a re-filter of knols.
      session.beginReviewSession(src);
    },
    [session.beginReviewSession],
  );

  useIonViewWillLeave(() => {
    session.cleanup();
  }, [session.cleanup]);

  // Collect the data needed to render the current card.
  const content = useCurrentCardContent(session, knolsUpdatedAt);

  // Handle ignoring a card.
  // The card content will disappear because it'll get excluded from filteredKnols.
  // So just skip it when that happens.
  const knolID = session.state.knols[session.state.activeCard.index]?.id;
  const knol = knols.get(knolID);
  useEffect(() => {
    if (knol?.tags?.some((tag) => tag === Knol.IGNORE_TAG)) {
      session.skip();
    }
  }, [knol, session.skip]);

  // Handle deleting a card.
  useEffect(() => {
    async function handleUpdate({ knolID, deckID }: { knolID: ID; deckID: ID }) {
      if (knolID === knol?.id) {
        const knol = await Knol.Get(knolID);
        const knolDeleted = knol === undefined;
        if (knolDeleted) {
          session.skip();
        }
      }
    }
    EventBus.on("knolUpdated", handleUpdate);
    return () => {
      EventBus.off("knolUpdated", handleUpdate);
    };
  }, [knol?.id, session.skip]);

  useEffect(() => {
    if (session.presentationData.current.knolIDToMissingBlobs[knolID]) {
      session.skip();
    }
  }, [session.skip, session.presentationData.current.knolIDToMissingBlobs, knolID]);

  if (session.state.screenMode === "recap") {
    return (
      <RecapScreen
        onContinue={continueSession}
        decks={decks}
        knols={knols}
        filteredKnols={filteredKnols}
        deckName={deckName}
        folder={folder}
        knolIDToLayout={session.state.knolIDToLayout}
        responses={session.responses.current}
      />
    );
  }

  const timer = session.state.settings.reviewTimer;

  return (
    <ReviewScreen
      reviewTimerMode={timer.mode}
      content={content}
      timerMillis={timer.millis}
      cardIndex={session.state.activeCard.index}
      handleResponse={session.inputResponse}
      handleFlip={session.flip}
      skipToRecap={session.skipToRecap}
      numCards={session.state.knols.length}
      deckName={deckName}
      deckTagName={folder}
      loading={session.state.loading}
      error={session.state.error}
      reloadSession={session.beginReviewSession}
      reloadPresentationData={session.reloadPresentationData}
      paused={session.state.paused}
      setPaused={session.setPaused}
    />
  );
}
