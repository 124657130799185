import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback } from "react";
import { maxCardFontSize, minCardFontSize } from "settings";
import FontSizeSettingModal from "./fontSizeSettingModal";
import InfoDot from "@components/infoDot";
import { PiTextAa } from "react-icons/pi";
import SettingsListItemIcon from "@components/settingsListItemIcon";

interface IProps {
  fontSizePx: number;
  onChange: (px: number) => void;
}
export default function FontSizeSettingItem({ fontSizePx, onChange }: IProps): JSX.Element {
  const handleFontSizeChange = useCallback(
    (px: number) => {
      const fontSize = Math.min(Math.max(px, minCardFontSize), maxCardFontSize);
      onChange(fontSize);
    },
    [onChange],
  );

  const [showFontSizeModal] = useIonCardModalWithDismiss(FontSizeSettingModal, {
    fontSizePx,
    handleFontSizeChange,
  });

  return (
    <IonItem button onClick={showFontSizeModal}>
      <InfoDot id="review_font_size_setting" />
      <SettingsListItemIcon icon={PiTextAa} />
      <IonLabel>{L10n.localize((s) => s.general.fontSize)}</IonLabel>
      <IonNote slot="end">{fontSizePx}px</IonNote>
    </IonItem>
  );
}
