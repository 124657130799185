import InlineLoadingSpinner from "components/inlineLoadingSpinner";
import React from "react";
import Style from "style";
import MiddleEllipse from "./middleEllipse";
import ProgressPie from "./progressPie";

interface IProps {
  style?: React.CSSProperties;
  text: any;
  loading?: boolean;
  progress?: number;
  isTheaterMode?: boolean;
}
export default function Subtitle(props: IProps): JSX.Element {
  const { style = {}, text = "", loading, progress, isTheaterMode } = props;

  const containerStyle: React.CSSProperties = {
    backgroundColor: isTheaterMode ? undefined : "var(--ion-color-light-tint)",
    borderBottom: `${Style.hairline} solid ${Style.colors.divider}`,
    borderTop: `${Style.hairline} solid ${Style.colors.divider}`,
    color: "var(--ion-color-medium)",
    fontSize: "1rem",
    fontWeight: 700,
    minHeight: isTheaterMode ? 24 : 30,
    overflow: "hidden",
    position: "relative",
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const content = [
    <span key="subtitle" style={{ flex: 1, width: "100%" }}>
      {text}
    </span>,
  ];

  if (progress !== undefined && !isNaN(progress)) {
    content.unshift(
      <ProgressPie
        key="pie"
        donut={isTheaterMode}
        progress={progress}
        style={{
          opacity: 0.5,
          position: "absolute",
          right: "5px",
          zIndex: 9,
        }}
      />,
    );
  }

  if (loading) {
    const loadingStyle: React.CSSProperties = {
      left: "5px",
      position: "absolute",
      zIndex: 9,
    };
    const loadingEl = (
      <div style={loadingStyle}>
        <InlineLoadingSpinner size={20} />
      </div>
    );
    content.unshift(loadingEl);
  }

  return (
    <div
      style={{
        ...containerStyle,
        ...(isTheaterMode
          ? {
              borderTop: "none",
              borderBottom: "none",
              // backgroundColor: Style.colors.primaryBg,
            }
          : undefined),
        ...style,
      }}
    >
      {typeof text === "string" ? (
        <MiddleEllipse text={text} />
      ) : (
        <div style={{ display: "flex", alignItems: "center", maxWidth: "100%" }}>{content}</div>
      )}
    </div>
  );
}
