import { DeckFields, fieldMap } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import { Operation } from "@models/operation";
import BlobStore from "data/idb/blobStore";
import { fieldTypeMap } from "fields/fields";
import { ID } from "types/ID";
import { genCardInsertOrEditOperation } from "./io";
import { CardBlobs } from "./lib";
import captureSilently from "errors/captureSilently";

export default async function saveCard(
  deckId: ID,
  knol: IKnol | undefined,
  fields: DeckFields,
  values: Record<string, string>,
  blobs: CardBlobs,
  tags: string[],
) {
  // Render TTS fields.
  const fmap = fieldMap(fields);
  const fmlBlobIDs: ID[] = [];
  for (const key in values) {
    const value = values[key];
    const field = fmap[key];
    const fieldType = field?.type;
    if (!fieldType) {
      continue;
    }
    const fml = value;
    switch (fieldType) {
      case "audio": {
        const attrs = fieldTypeMap.audio.loadFML(fml);
        const id = attrs?.id;
        if (id) {
          fmlBlobIDs.push(id);
        }
        break;
      }
      case "image": {
        const attrs = fieldTypeMap.image.loadFML(fml);
        const id = attrs?.id;
        if (id) {
          fmlBlobIDs.push(id);
        }
        break;
      }
      case "tts": {
        // NOTE: tts blobs will be downloaded when the knol INSERT operation handler runs.
        break;
      }
      default:
    }
  }

  const op = await genCardInsertOrEditOperation({
    deckId,
    values,
    tags,
    knolID: knol?.id,
    fmap,
  });

  // Mark blobs that are used as saved and pending upload.
  const blobIDs: ID[] = fmlBlobIDs;

  // Find used IDs in string values (rich text).
  for (const val of Object.values(op.object_parameters.values)) {
    const ids = Knol.blobIdsPresentInString(val);
    blobIDs.push(...ids);
  }

  await BlobStore.markBlobsAsSavedAndPendingUpload(blobIDs, op.object_parameters.knol_id);

  // Wipe unused blobs.
  await BlobStore.deleteBlobsPendingSave();

  // Upload blobs.
  BlobStore.uploadBlobsWithIDs(blobIDs).catch(captureSilently);

  return Operation.operateAndSave(op);
}
