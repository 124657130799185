import { StepLogger } from "analytics/funnel";
import { History } from "history";
import L10n from "localization";
import React from "react";
import GenFlashcardsNav, { formatLangName } from "./genFlashcardsNav";
import { OnboardingGoalInfo } from "./onboardingModal";
import FreeformTextNav from "./freeformTextNav";
import { BFF69 } from "fields/langCodes";

interface IProps {
  dismiss: () => Promise<void>;
  history: History;
  onAddToInbox: () => void;
  langName: string;
  langCode: BFF69;
  motivation: string;
  proficiency: string;
  proficiencyDesc: string;
  logStep: StepLogger;
}
export default function GoalLanguageContextNav(props: IProps) {
  return (
    <FreeformTextNav
      title={formatLangName(props.langName)}
      description={L10n.localize((s) => s.onboarding.langContextNavDescription)}
      dismiss={props.dismiss}
      history={props.history}
      onNav={(context) => {
        const goal: OnboardingGoalInfo = {
          goal: "lang",
          langName: props.langName,
          langCode: props.langCode,
          motivation: props.motivation,
          proficiency: props.proficiency,
          context,
        };
        let prompt = `Make flashcards for someone learning ${goal.langName} ${goal.motivation}`;
        if (goal.proficiency === "None") {
          prompt += " with no prior knowledge of it";
        } else {
          prompt += ` whose proficiency is ${props.proficiencyDesc}`;
        }
        prompt += ` and who expects to use the language in the following context(s) ${context}`;
        return (
          <GenFlashcardsNav
            prompt={prompt}
            dismiss={props.dismiss}
            history={props.history}
            onAddToInbox={props.onAddToInbox}
            logStep={props.logStep}
            goal={goal}
          />
        );
      }}
    />
  );
}
