import { Grade } from "@core/grade";
import idb from "@data/idb";
import { CardSortValue } from "@screens/deck/cards";
import { FlipStabilizationSetting } from "@screens/deck/flipStabilizationSelectorItem";
import EventBus from "eventBus";
import { GuaranteedAsyncVal } from "hooks/util/useAsyncValState";
import { useCallback, useEffect, useState } from "react";
import { defaultAutoflipTimerMillis, defaultCardFontSize, getCurrentUserSettings } from "settings";
import { Deck, IDeck } from "./deck";

export type Orientation = "normal" | "reversed" | "mixed";

export const defaultOrientation: Orientation = "normal";

export const defaultCardSortOrder = "cards.created_at DESC";

// NOTE: a value of 0 is used rather than something like undefined or NaN, because 0 works better with Ionic's radio selection logic in the UI to configure cards per session.

export const cardsPerReviewAllValue = 0;

export interface IReviewModeSRS {
  name: "srs";
}
export interface IReviewModeShuffled {
  name: "shuffled";
}
export interface IReviewModeInOrder {
  name: "in_created_order";
  skip: number;
}
export interface IBackgroundSettings {
  genSrcImage?: boolean;
}

export type ReviewModeSetting = IReviewModeSRS | IReviewModeShuffled | IReviewModeInOrder;
export type ReviewMode = ReviewModeSetting["name"];

export interface IDeckSettings {
  cardsPerReview: number;
  cardFontSize: number;
  cardOrientation: Orientation;
  reviewTags: string[];
  sortOrder: CardSortValue;
  reviewGrades: Grade[];
  reviewMode: ReviewModeSetting;
  reviewTimer: ITimerSettings;
  flipStabilization: FlipStabilizationSetting;
  background: IBackgroundSettings;
}

export const defaultReviewTimer: ITimerSettings = {
  mode: "off",
  millis: defaultAutoflipTimerMillis,
};

export const defaultDeckSettings = (): IDeckSettings => {
  return {
    cardFontSize: defaultCardFontSize,
    cardOrientation: defaultOrientation,
    cardsPerReview: getCurrentUserSettings().defaultCardsPerReview,
    reviewGrades: [],
    reviewMode: { name: "srs" },
    reviewTags: [],
    reviewTimer: defaultReviewTimer,
    sortOrder: defaultCardSortOrder,
    flipStabilization: "stabilize",
    background: { genSrcImage: true },
  };
};

export interface ITimerSettings {
  mode: TimerMode;
  millis: number;
}
export const timerModes = ["off", "semi-auto", "full-auto"] as const;
export type TimerMode = (typeof timerModes)[number];

export type DeckSetting = keyof IDeckSettings;

export function useDeckSettings(deck?: IDeck): GuaranteedAsyncVal<IDeckSettings> {
  const [state, setState] = useState<GuaranteedAsyncVal<IDeckSettings>>({
    val: defaultDeckSettings(),
    loading: true,
    error: undefined,
  });
  useEffect(() => {
    if (!deck) {
      return;
    }
    const settings: IDeckSettings = {
      ...defaultDeckSettings(),
      ...deck?.user_config?.settings,
    };
    setState({ val: settings, loading: false });
  }, [deck]);
  return state;
}

export function useDeckSettingChangeHandler<T extends DeckSetting>(
  deck: IDeck,
  setting: T,
  handler: (err: Error) => void,
) {
  return useCallback(
    async (value: IDeckSettings[T]) => {
      Deck.setSetting(deck, setting, value).catch(handler);
    },
    [deck, setting, handler],
  );
}

export async function getDeckSettings(deck: IDeck): Promise<IDeckSettings> {
  return {
    ...defaultDeckSettings(),
    ...deck?.user_config?.settings,
  };
}

export function useFolderSettings(folder: string): GuaranteedAsyncVal<IDeckSettings> {
  const [state, setState] = useState<GuaranteedAsyncVal<IDeckSettings>>({
    val: defaultDeckSettings(),
    loading: true,
    error: undefined,
  });
  useEffect(() => {
    function update({ name }: { name: string }) {
      if (name !== folder) {
        return;
      }
      idb.db
        .get("folders", folder)
        .then((f) => {
          const settings: IDeckSettings = {
            ...defaultDeckSettings(),
            ...f?.settings,
          };
          setState({ val: settings, loading: false });
        })
        .catch((err) => {
          setState({ val: defaultDeckSettings(), loading: false, error: err });
        });
    }
    update({ name: folder });
    EventBus.on("folderUpdated", update);
    return () => {
      EventBus.off("folderUpdated", update);
    };
  }, [folder]);
  return state;
}

export async function getFolderSettings(folder: string): Promise<IDeckSettings> {
  const f = await idb.db.get("folders", folder);
  return {
    ...defaultDeckSettings(),
    ...f?.settings,
  };
}
