import { IonBreadcrumb, IonBreadcrumbs, IonToolbar } from "@ionic/react";
import L10n from "localization";
import React from "react";

interface IProps {
  crumb: "goal" | "details" | "flashcards";
}
export default function Breadcrumbs({ crumb }: IProps) {
  return (
    <IonToolbar>
      <IonBreadcrumbs>
        <IonBreadcrumb active={crumb === "goal"}>
          {L10n.localize((s) => s.onboarding.goalBreadcrumb)}
        </IonBreadcrumb>
        <IonBreadcrumb active={crumb === "details"}>
          {L10n.localize((s) => s.onboarding.detailsBreadcrumb)}
        </IonBreadcrumb>
        <IonBreadcrumb active={crumb === "flashcards"}>
          {L10n.localize((s) => s.onboarding.flashcardsBreadcrumb)}
        </IonBreadcrumb>
      </IonBreadcrumbs>
    </IonToolbar>
  );
}
