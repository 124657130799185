import ErrorBoundary from "@components/errorBoundary";
import LayoutStyle from "@components/layoutStyle";
import { ITypedBlobURL } from "@data/idb";
import { DeckFields } from "@models/deck";
import { Orientation } from "@models/deckSettings";
import { FlipStabilizationSetting } from "@screens/deck/flipStabilizationSelectorItem";
import Lib from "lib";
import React from "react";
import { ID } from "types/ID";
import CardSides from "./cardSides";

export const reviewCardClass = "reviewCard";

interface IProps {
  deckID?: ID;
  persistDownloadedBlobs?: boolean;
  disableAudioControlsRendering?: boolean;
  refreshCounter?: number;
  autoplayAudio: boolean;
  disableTouchEvents: boolean;
  templates: string[];
  layoutName?: string;
  visibleSide: 0 | 1;
  overflow?: string | null;
  fontSize?: number;
  editable: boolean;
  rubberbandScroll?: boolean;
  layoutID?: string;
  onFieldClick?: (fieldKey: string) => void;
  onFieldBlur?: (fieldKey: string) => void;
  values: Record<string, string>;
  backgroundColor?: string;
  style: string;
  fields: DeckFields | undefined;
  orientation?: Orientation;
  blobIDToURL?: Record<ID, ITypedBlobURL>;
  flipStabilization?: FlipStabilizationSetting;
  query?: string;
}

export default function Card(props: IProps): JSX.Element | null {
  const {
    query,
    persistDownloadedBlobs,
    disableAudioControlsRendering,
    refreshCounter = 0,
    autoplayAudio = false,
    disableTouchEvents = false,
    templates = [],
    layoutName = "",
    visibleSide = 0,
    overflow = "auto",
    fontSize = undefined,
    editable = false,
    rubberbandScroll = true,
    onFieldClick,
    onFieldBlur,
    layoutID,
    style,
    values = {},
    fields,
    orientation,
    blobIDToURL,
    flipStabilization,
    deckID,
  } = props;

  if (!layoutID) {
    return null;
  }

  const divStyle = {
    display: "flex",
    flex: "1",
    textAlign: "center",
    justifyContent: "center",
    position: "relative",
    whiteSpace: "initial",
    paddingLeft: "4px",
    paddingRight: "4px",
  } as React.CSSProperties;

  // HACK: this suffix prevents collisions when multiple layouts with same ID
  // but different content are in the DOM, e.g. when changing layouts for a
  // new deck.
  const layoutClassSuffix = Lib.uuid16();
  const layoutClass = `layout_${layoutID}${layoutClassSuffix}`;

  return (
    <div className={`${layoutClass} ${reviewCardClass}`} style={divStyle}>
      <LayoutStyle
        key="style"
        fontSize={fontSize}
        layoutID={layoutID}
        layoutClass={layoutClass}
        css={style}
      />
      <ErrorBoundary>
        <CardSides
          query={query}
          persistDownloadedBlobs={persistDownloadedBlobs}
          disableAudioControlsRendering={disableAudioControlsRendering}
          values={values}
          disableTouchEvents={disableTouchEvents}
          editable={editable}
          layoutName={layoutName}
          templates={templates}
          visibleSide={visibleSide}
          rubberbandScroll={rubberbandScroll}
          autoplayAudio={autoplayAudio}
          overflow={overflow}
          onFieldClick={onFieldClick}
          onFieldBlur={onFieldBlur}
          refreshCounter={refreshCounter}
          fields={fields}
          orientation={orientation}
          blobIDToURL={blobIDToURL}
          flipStabilization={flipStabilization}
          deckID={deckID}
        />
      </ErrorBoundary>
    </div>
  );
}
