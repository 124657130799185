import EventBus from "eventBus";
import { IField, fieldTypeMap } from "fields/fields";
import { FML } from "fields/fml";
import { fieldLang } from "fields/lang";
import { fieldValueString } from "fields/lib";
import { IFieldChinese } from "fields/types/chinese";
import { IFieldJapanese } from "fields/types/japanese";
import { IFieldText } from "fields/types/text";
import { IFieldTTS } from "fields/types/tts";
import useDebounce from "hooks/util/useDebounce";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import L10n from "localization";
import { useEffect } from "react";
import { executePipeline, matchesPipeline } from "./lib";
import { TranslationError } from "./translation";

type TextPipelineField = IFieldChinese | IFieldJapanese | IFieldTTS | IFieldText;

export function isTextPipelineField(field: IField): field is TextPipelineField {
  return (
    field.type === "chinese" ||
    field.type === "japanese" ||
    field.type === "tts" ||
    field.type === "text"
  );
}

export async function computePipelineOutputFor(
  outField: TextPipelineField,
  inField: IField,
  inVal: string,
): Promise<FML | undefined> {
  const srcNode = outField.source;
  if (!matchesPipeline(srcNode, inField.name)) {
    return;
  }

  const text = fieldValueString(inField.type, inVal);
  if (text === undefined) {
    return;
  }

  const lang = fieldLang(outField);
  const pipelineOutput = await executePipeline(srcNode, inField, text, lang);
  if (pipelineOutput) {
    switch (outField.type) {
      case "text": {
        return fieldTypeMap.text.updateValue(outField, pipelineOutput.text);
      }
      case "tts": {
        return fieldTypeMap.tts.updateValue(outField, {
          text: pipelineOutput.text,
        });
      }
      case "chinese": {
        return fieldTypeMap.chinese.updateValue(outField, {
          chinese: pipelineOutput.text,
        });
      }
      case "japanese": {
        return fieldTypeMap.japanese.updateValue(outField, {
          japanese: pipelineOutput.text,
        });
      }
    }
  }
}

export default function useValueEditorSourcePipeline(
  field: TextPipelineField,
  onChange: (name: string, newVal: FML) => void,
): boolean {
  const [presentToast] = useDismissibleToast();

  // TODO: compute debounce based on max of debounce of all nodes in pipeline so it's instant for ref-only, but debounced for translation, etc...
  const [startDebounce, resetDebounce, debouncePending] = useDebounce(1_000);
  useEffect(() => {
    function handleFieldInput({ src, value }: { src: IField; value: string }) {
      const srcNode = field.source;
      if (!matchesPipeline(srcNode, src.name)) {
        return;
      }

      startDebounce(async () => {
        try {
          const fml = await computePipelineOutputFor(field, src, value);
          if (fml) {
            onChange(field.name, fml);
          }
          return;
        } catch (err) {
          // TODO: handle error. Make default value for each field type?
          // const fml = fieldType.dumpFML({ text: "" });
          // onChange(field.name, fml);

          if (err instanceof TranslationError) {
            presentToast({
              message: L10n.localize((s) => s.error.translation),
              color: "warning",
              duration: 2500,
            });
          }
        }
      });
    }

    EventBus.on("fieldValueInput", handleFieldInput);
    return () => {
      EventBus.off("fieldValueInput", handleFieldInput);
      resetDebounce();
    };
  }, [field, onChange, presentToast, resetDebounce, startDebounce]);

  return debouncePending;
}
