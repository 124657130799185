import FieldEditorTTS from "fields/editors/fieldEditorTTS";
import { IFieldType } from "fields/fieldType";
import { parseFML, serializeFML } from "fields/fml";
import { isBFF69 } from "fields/lang";
import { BFF69 } from "fields/langCodes";
import { IBaseTextField, validateTranslationPipelineLang } from "fields/lib";
import { parseSides } from "fields/sides";
import { hasFieldRef } from "fields/sources/lib";
import FieldValueEditorTTS from "fields/valueEditors/fieldValueEditorTTS";
import TTSValue from "fields/values/ttsValue";
import { SSMLGender, isSSMLGender } from "lib/tts";
import L10n from "localization";
import { TbMessageLanguage } from "react-icons/tb";

export const sampleTTSType = "audio/mp3";
const sampleTTSFilename = "hello-world.mp3";
export const sampleTTSURL = `/${sampleTTSFilename}`;
export const sampleTTSText = "Hello, world.";

export const ttsFieldType: IFieldType<"tts"> = {
  name: () => L10n.localize((s) => s.field.typeTTS),
  description: () => L10n.localize((s) => s.field.typeTTSDescription),
  icon: TbMessageLanguage,
  sampleValue() {
    return this.dumpFML({ text: sampleTTSText });
  },
  fmlTag: "tts",
  type: "tts",
  parseField(attrs, srcs): IFieldTTS {
    const fontSizePx = Number.parseInt(attrs.fontSizePx);

    const lang: BFF69 | undefined = isBFF69(attrs.lang) ? attrs.lang : undefined;
    const gender: SSMLGender | undefined = isSSMLGender(attrs.gender) ? attrs.gender : undefined;

    return {
      name: attrs.name,
      type: "tts",
      sides: parseSides(attrs.sides),
      source: srcs[0],
      attributes: {
        fontSizePx: Number.isNaN(fontSizePx) ? undefined : fontSizePx,
        lang,
        gender,
      },
    };
  },

  loadFML(fml) {
    const res = parseFML({ tagName: this.fmlTag, fml });
    if (!res) {
      return null;
    }
    const { body } = res;
    return { text: body };
  },

  dumpFML({ text }) {
    return serializeFML({ tagName: this.fmlTag, body: text });
  },

  updateValue(field, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  valueComponent: TTSValue,
  editorComponent: FieldEditorTTS,
  valueEditorComponent: FieldValueEditorTTS,

  convert(field) {
    switch (field.type) {
      case "richtext":
      case "text":
      case "tts": {
        return {
          ...field,
          attributes: { lang: undefined, gender: undefined, ...field.attributes },
          type: this.type,
        };
      }
      default:
        return { ...field, type: this.type, attributes: { lang: undefined, gender: undefined } };
    }
  },

  string(value) {
    return value.text;
  },

  valid(field) {
    const hasRef = hasFieldRef(field.source);
    if (!hasRef && !field.attributes?.lang) {
      return false;
    }
    return validateTranslationPipelineLang(field);
  },
};

export interface IFieldTTS extends IBaseTextField {
  type: "tts";
  attributes?: {
    lang?: BFF69;
    fontSizePx?: number;
    gender?: SSMLGender;
  };
}

export interface ITTSFieldValue {
  text: string;
}
