import idb from "@data/idb";
import BlobStore from "@data/idb/blobStore";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNavLink,
  IonNote,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Op, Operation } from "@models/operation";
import { showElectronDevTools } from "electron";
import { isMacAppStoreElectron } from "globals";
import { useAsyncValFromPromiseState } from "hooks/util/useAsyncValState";
import { applePurchasedUnlimitedAtLocalStorageKey } from "hooks/util/useHasUnlimited";
import { addCircleOutline, createOutline, removeCircleOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";
import DebugOperation from "./debugOperation";

function OperationItem({ op }: { op: Op }) {
  const icon =
    op.type === "INSERT"
      ? addCircleOutline
      : op.type === "DELETE"
        ? removeCircleOutline
        : op.type === "UPDATE"
          ? createOutline
          : undefined;
  return (
    <IonNavLink routerDirection="forward" component={() => <DebugOperation op={op} />}>
      <IonItem button detail>
        <IonIcon icon={icon} slot="start" />
        <IonLabel>
          <h2>{op.object_type}</h2>
          <p>{op.timestamp}</p>
        </IonLabel>
      </IonItem>
    </IonNavLink>
  );
}

export default function Debug(): JSX.Element {
  const [operations, fetchOperations] = useAsyncValFromPromiseState<Op[]>([]);
  const [numPendingBlobUploads, fetchNumPendingBlobUploads] = useAsyncValFromPromiseState<number>();
  React.useEffect(() => {
    fetchOperations(Operation.unsynced());
    fetchNumPendingBlobUploads(BlobStore.numPendingBlobUploads());
  }, [fetchNumPendingBlobUploads, fetchOperations]);

  function handleResetDots() {
    idb.dots
      .clear("dots")
      .then(() => {
        alert("Dots reset successful.");
      })
      .catch(() => {
        alert("Dots reset failed.");
      });
  }

  function handleClearIAPCache() {
    const ok = confirm("Clear the Apple purchase cache?");
    if (ok) {
      localStorage.removeItem(applePurchasedUnlimitedAtLocalStorageKey);
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.general.debug)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonList inset>
          <IonListHeader>
            <IonLabel>Sync</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel>Pending blob uploads</IonLabel>
            <IonNote>{numPendingBlobUploads.val}</IonNote>
          </IonItem>
          <IonListHeader>
            <IonLabel>Operations</IonLabel>
          </IonListHeader>
          {operations.val?.map((op) => (
            <OperationItem key={op.id} op={op} />
          ))}
        </IonList>
        <IonList inset>
          {isMacAppStoreElectron() ? (
            <IonItem button onClick={showElectronDevTools}>
              <IonLabel>Show Dev Tools</IonLabel>
            </IonItem>
          ) : undefined}
        </IonList>
        <IonList inset>
          <IonListHeader>
            <IonLabel>Dots</IonLabel>
          </IonListHeader>
          <IonItem onClick={handleResetDots} button>
            Reset Dots
          </IonItem>
        </IonList>
        <IonList inset>
          <IonListHeader>
            <IonLabel>IAP Cache</IonLabel>
          </IonListHeader>
          <IonItem onClick={handleClearIAPCache} button>
            Clear Apple Purchase Cache
          </IonItem>
        </IonList>
      </IonContent>
    </>
  );
}
