import { showPrompt } from "@components/prompt";
import { Deck } from "@models/deck";
import DeckDownloadScreen from "@screens/deckDownload";
import { History } from "history";
import { IDeckListDeckRow } from "hooks/data/deckList";
import useDeckDownloadState from "hooks/util/useDeckDownloadState";
import useErrorAlert from "hooks/util/useErrorAlert";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import DeckItem from "./deckItem";
import { useGradePercent } from "./useGradePercent";

export default function DeckListDeckItem(props: {
  row: IDeckListDeckRow;
  history?: History;
  onClick?: () => Promise<void>;
  inlineDownload?: boolean;
  showFolderPrefix?: boolean;
}): JSX.Element {
  const { row } = props;
  const { deck } = row;
  const href = deck.local ? `/decks/local/${deck.id}` : undefined;
  const name = Deck.displayName(deck, props.showFolderPrefix);
  const decks = useMemo(() => [deck], [row]);
  const gradePct = useGradePercent(decks, false);
  const archived = Deck.isArchived(row.deck);
  const numKnols = row.count;

  const downloadState = useDeckDownloadState(deck.id);
  const downloading = downloadState === "downloading";

  const [showDeleteError] = useErrorAlert({ code: "DELETING_DECK" });
  const handleDelete = useCallback(async () => {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.deck.confirmRemovalDangerous),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.delete).toLocaleUpperCase(),
      callback: async () => {
        try {
          await Deck.PermanentlyDeleteDeck(deck.id);
        } catch (err) {
          showDeleteError(err);
        }
      },
    });
  }, [deck]);

  const [showArchiveDeckError] = useErrorAlert({ code: "ARCHIVING_DECK" });
  const handleArchive = useCallback(async () => {
    Deck.archive(deck.id).catch((err) => showArchiveDeckError(err));
  }, [deck, showArchiveDeckError]);

  const history = useHistory();
  const [presentDownloadDeckModal] = useIonCardModalWithDismiss(DeckDownloadScreen, {
    deckID: deck.id,
    deck: deck,
    history: history,
  });
  const [showDownloadError] = useErrorAlert({ code: "DOWNLOADING_DECK" });
  const handleClick = useCallback(async () => {
    await props.onClick?.();

    // HACK: I don't know why, but when rendered from the Inbox flow, the
    // history object from useHistory in this component was undefined, so it
    // was necessary to pass in a history parameter.
    const hist = props.history ?? history;

    if (!deck.local && props.inlineDownload) {
      try {
        await Deck.download(deck.id);
      } catch (err) {
        showDownloadError(err);
        return;
      }
    } else if (!deck.local) {
      presentDownloadDeckModal();
      return;
    } else if (href) {
      hist.push(href);
      return;
    }
    hist.push(`/decks/local/${deck.id}`);
  }, [
    deck,
    href,
    history,
    presentDownloadDeckModal,
    showDownloadError,
    props.history,
    props.onClick,
    props.inlineDownload,
  ]);

  return (
    <DeckItem
      key={deck.id}
      name={name}
      numKnols={numKnols}
      type={row.type}
      hideStatusIcon={false}
      description={deck.description}
      local={deck.local}
      downloading={downloading}
      timestamp={row.timestamp}
      modifiedAt={deck.modified_at}
      shared={deck.shared}
      gradePct={gradePct}
      archived={archived}
      onDelete={archived ? handleDelete : undefined}
      onArchive={downloading ? undefined : handleArchive}
      onClick={handleClick}
    />
  );
}
