import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
  SegmentChangeEventDetail,
} from "@ionic/react";
import logEvent from "analytics";
import { History } from "history";
import { useViewWillEnter } from "hooks/util/useDataFetch";
import React from "react";
import ScreenComponent from "../components/screen";
import L10n from "../localization";
import ImportAPKG from "./import/apkgImport";
import ImportCSV from "./import/csvImport";
import ImportFML from "./import/fmlImport";

interface IProps {
  history: History;
  dismiss?: () => Promise<void>;
  nav?: boolean;
}

const formats = ["FML", "CSV", "APKG"] as const;
type ImportFormat = (typeof formats)[number];
function isOfTypeImportFormats(i: string): i is ImportFormat {
  return (formats as readonly string[]).includes(i);
}

let initialImportTab: ImportFormat = "FML";
export function setInitialImportTab(tab: ImportFormat) {
  initialImportTab = tab;
}

function ImportScreenBody({ dismiss, history, nav }: IProps): JSX.Element {
  const [activeSection, setActiveSection] = React.useState<ImportFormat>(initialImportTab);
  let activeContent: JSX.Element;
  if (activeSection === "CSV") {
    activeContent = <ImportCSV history={history} dismiss={dismiss} />;
  } else if (activeSection === "APKG") {
    activeContent = <ImportAPKG history={history} dismiss={dismiss} />;
  } else {
    activeContent = <ImportFML dismiss={dismiss} history={history} />;
  }

  useViewWillEnter(() => {
    logEvent("visited_import_screen");
  }, []);

  const handleChange = (e: CustomEvent<SegmentChangeEventDetail>) => {
    const val = e.detail.value;
    if (e.detail.value && typeof val === "string" && isOfTypeImportFormats(val)) {
      setActiveSection(val);
    }
  };

  return (
    <>
      <IonToolbar>
        <IonSegment
          style={{ marginBottom: 12, marginTop: 8, width: "100%" }}
          value={activeSection}
          onIonChange={handleChange}
        >
          <IonSegmentButton value="FML">AnkiApp</IonSegmentButton>
          <IonSegmentButton value="CSV">CSV</IonSegmentButton>
          <IonSegmentButton value="APKG">APKG</IonSegmentButton>
        </IonSegment>
      </IonToolbar>
      {activeContent}
    </>
  );
}

export default function ImportScreen(props: IProps) {
  const isModal = props.dismiss !== null;

  if (props.nav) {
    return (
      <>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonBackButton />
            </IonButtons>
            <IonTitle>{L10n.localize((s) => s.general.import)}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent color="light">
          <ImportScreenBody {...props} />
        </IonContent>
      </>
    );
  }

  return (
    <ScreenComponent
      title={L10n.localize((s) => s.general.import)}
      defaultBackLink="/home"
      leftButton={
        isModal ? (
          <IonButton onClick={props.dismiss}>{L10n.localize((s) => s.actions.close)}</IonButton>
        ) : undefined
      }
      noBigTitle={isModal}
      helpQuery="import"
      content={<ImportScreenBody {...props} />}
      contentColor="light"
    />
  );
}
