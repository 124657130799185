import { IonSpinner } from "@ionic/react";
import React from "react";

interface IProps {
  delayMillis?: number;
}
export default function LoadingBlob({ delayMillis = 25 }: IProps): JSX.Element | null {
  const [delayOver, setDelayOver] = React.useState(false);
  React.useEffect(() => {
    const handle = setTimeout(() => setDelayOver(true), delayMillis);
    return () => {
      clearTimeout(handle);
    };
  }, [delayMillis]);

  if (!delayOver) {
    return null;
  }

  return (
    <span style={{ display: "block", width: "100%", textAlign: "center" }}>
      <IonSpinner name="dots" />
    </span>
  );
}
