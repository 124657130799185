import { IDBAppSchema } from "@data/idb";
import { Knol } from "@models/knol";
import { IBaseOperation } from "@models/operation";
import EventBus from "eventBus";
import { IDBPTransaction, StoreNames } from "idb";

export interface IResponseInsertOperation extends IBaseOperation {
  object_type: "response";
  type: "INSERT";
  object_parameters: {
    device_id?: string;
    created_at: string;
    deck_id: string;
    duration_ms: number;
    knol_id: string;
    layout_id: string;
    score: number;
    score_mean: number | null;
    score_standard_deviation: number | null;
    last_response_at: string | null;
  };
}

export type ResponseOperation = IResponseInsertOperation;

export const INSERT = async (
  op: IResponseInsertOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const store = tx.objectStore("responses");
  const { knol_id, layout_id, deck_id } = op.object_parameters;
  const r = {
    ...op.object_parameters,
    device_id: op.device_id,
    created_at: op.object_parameters.created_at,
  };
  await store.put(r);
  await Knol.UpdateGrade(tx, knol_id, layout_id);
  const deckStore = tx.objectStore("decks");
  const deck = await deckStore.get(deck_id);
  if (deck) {
    const last_reviewed_at = new Date(op.object_parameters.created_at);
    await deckStore.put({ ...deck, last_reviewed_at });
  }
  return () => {
    EventBus.emit("responseInserted", { response: r });
    EventBus.emit("knolUpdated", { knolID: knol_id, deckID: deck_id });
    EventBus.emit("deckUpdated", { ID: deck_id });
  };
};
