import RadioSelectSettingNavItem, {
  IRadioSelectOption,
} from "@components/radioSelectSettingNavItem";
import {
  IonItem,
  IonLabel,
  IonNavLink,
  IonNote,
  IonSelect,
  IonSelectOption,
  SelectCustomEvent,
} from "@ionic/react";
import AnnotationModeNav, { annotationModeName } from "fields/editors/annotationModeNav";
import { LanguageSelectorItem } from "fields/editors/languageSelectorItem";
import { humanLanguages } from "fields/lang";
import { BFF69, validTTSLangCodes } from "fields/langCodes";
import { IFieldJapanese } from "fields/types/japanese";
import { IFieldText } from "fields/types/text";
import L10n from "localization";
import React from "react";
import ConfigSelectorItem from "./configSelectorItem";
import { LanguageConfigTranslationSetting } from "./configSpecificOptions";
import { ICJConfig, jp2fields } from "./configSpecificOptionsCJ";
import { IConfigSelection } from "./main";

interface IProps {
  config: IConfigSelection;
  onChange: (cfg: IConfigSelection) => void;
}
export default function ConfigSpecificOptionsJapanese({ config, onChange }: IProps) {
  const frontField = config.fields[0];
  const backField = config.fields[1];

  const langs = humanLanguages();

  let ttsSetting = "off";
  if (frontField.type === "tts" && backField.type === "tts") {
    ttsSetting = "both";
  } else if (frontField.type === "tts") {
    ttsSetting = "front";
  } else if (backField.type === "tts") {
    ttsSetting = "back";
  }

  const japaneseSide =
    config.fields[0].type === "japanese" && config.fields[0].sides[0] === 1 ? 0 : 1;
  const japaneseSideSetting = japaneseSide === 0 ? "front" : "back";
  const japaneseField = config.fields[0] as IFieldJapanese;
  const localField = config.fields[1] as IFieldText;
  const localLang = localField.attributes?.lang as BFF69 | undefined;

  const hasJpTTS = config.fields.find((f) => f.name === `${japaneseField.name}-tts`);
  const hasLocalTTS = config.fields.find((f) => f.name === `${localField.name}-tts`);
  if (hasJpTTS && hasLocalTTS) {
    ttsSetting = "both";
  } else if (hasJpTTS) {
    ttsSetting = "foreign";
  } else if (hasLocalTTS) {
    ttsSetting = "local";
  } else {
    ttsSetting = "off";
  }

  const foreignLang = "ja-JP";
  const ttsOptions: IRadioSelectOption<"off" | "both" | "foreign" | "local">[] = [
    { name: L10n.localize((s) => s.general.off), val: "off" },
  ];
  const ttsSupportedForeignLang = foreignLang && validTTSLangCodes.includes(foreignLang);
  const ttsSupportedLocalLang = localLang && validTTSLangCodes.includes(localLang);
  if (ttsSupportedForeignLang && ttsSupportedLocalLang) {
    ttsOptions.push({ name: L10n.localize((s) => s.general.both), val: "both" });
  }
  if (ttsSupportedForeignLang) {
    ttsOptions.push({ name: L10n.localize((s) => s.field.typeJapanese), val: "foreign" });
  }
  if (ttsSupportedLocalLang) {
    ttsOptions.push({ name: L10n.localize((s) => s.general.local), val: "local" });
  }

  let translationSetting: LanguageConfigTranslationSetting = "off";
  if (localField.source) {
    translationSetting = "from-foreign";
  } else if (japaneseField.source) {
    translationSetting = "from-local";
  }

  function deriveJpConfig(): ICJConfig {
    return {
      foreignSide: japaneseSide === 0 ? "front" : "back",
      localLangCode: localLang,
      ttsForeign: ttsSetting === "both" || ttsSetting === "foreign",
      ttsLocal: ttsSetting === "both" || ttsSetting === "local",
      translation: translationSetting,
      annotationMode: japaneseField.attributes?.furiganaMode,
    };
  }

  return (
    <>
      <IonItem lines="none" style={{ marginTop: 16 }}>
        <ConfigSelectorItem
          base={config.base}
          name={config.name}
          fields={config.fields}
          values={config.values}
          onSelect={() => null}
        />
      </IonItem>
      <LanguageSelectorItem
        label={L10n.localize((s) => s.general.localLanguage)}
        field={localField}
        langCode={localLang}
        langs={langs}
        langRequired
        onChange={(code) => {
          const newConfig = JSON.parse(JSON.stringify(config));
          newConfig.fields = jp2fields({
            ...deriveJpConfig(),
            localLangCode: code as BFF69 | undefined,
          });
          onChange(newConfig);
        }}
      />
      <IonItem>
        <IonSelect
          value={japaneseSideSetting}
          onIonChange={(e: SelectCustomEvent) => {
            const side = e.detail.value;
            if (!["front", "back"].includes(side)) {
              return;
            }
            const newConfig = JSON.parse(JSON.stringify(config));
            newConfig.fields = jp2fields({
              ...deriveJpConfig(),
              foreignSide: side,
            });
            onChange(newConfig);
          }}
        >
          <IonLabel slot="label">
            <h2>{L10n.localize((s) => s.general.japaneseSide)}</h2>
          </IonLabel>
          <IonSelectOption value="front">{L10n.localize((s) => s.general.front)}</IonSelectOption>
          <IonSelectOption value="back">{L10n.localize((s) => s.general.back)}</IonSelectOption>
        </IonSelect>
      </IonItem>
      <RadioSelectSettingNavItem<"off" | "both" | "foreign" | "local">
        title={L10n.localize((s) => s.general.textToSpeech)}
        description={L10n.localize((s) => s.general.textToSpeechDescription)}
        selectedItemVal={ttsSetting}
        onChange={(ttsChoice) => {
          const newConfig = JSON.parse(JSON.stringify(config));
          newConfig.fields = jp2fields({
            ...deriveJpConfig(),
            ttsForeign: ttsChoice === "both" || ttsChoice === "foreign",
            ttsLocal: ttsChoice === "both" || ttsChoice === "local",
          });
          onChange(newConfig);
        }}
        options={ttsOptions}
      />
      <RadioSelectSettingNavItem<"off" | "from-foreign" | "from-local">
        title={L10n.localize((s) => s.general.translation)}
        description={L10n.localize((s) => s.general.translationDescription)}
        selectedItemVal={translationSetting}
        onChange={(translationChoice) => {
          const newConfig = JSON.parse(JSON.stringify(config));
          newConfig.fields = jp2fields({
            ...deriveJpConfig(),
            translation: translationChoice,
          });
          onChange(newConfig);
        }}
        options={[
          { name: L10n.localize((s) => s.general.off), val: "off" },
          { name: `ja-JP → ${localLang}`, val: "from-foreign" },
          { name: `${localLang} → ja-JP`, val: "from-local" },
        ]}
      />
      <IonNavLink
        routerDirection="forward"
        component={() => (
          <AnnotationModeNav
            title={L10n.localize((s) => s.field.furigana)}
            mode={japaneseField.attributes?.furiganaMode}
            onSelect={(newMode) => {
              const newConfig = JSON.parse(JSON.stringify(config));
              newConfig.fields = jp2fields({
                ...deriveJpConfig(),
                annotationMode: newMode,
              });
              onChange(newConfig);
            }}
          />
        )}
      >
        <IonItem button>
          <IonLabel>{L10n.localize((s) => s.field.furigana)}</IonLabel>
          <IonNote slot="end">{annotationModeName(japaneseField.attributes?.furiganaMode)}</IonNote>
        </IonItem>
      </IonNavLink>
    </>
  );
}
