import MiddleEllipse from "@components/middleEllipse";
import ProgressPie from "@components/progressPie";
import Subtitle from "@components/subtitle";
import { Deck } from "@models/deck";
import { TimerMode } from "@models/deckSettings";
import React from "react";
import TimerHUD from "./reviewTimer";
import TagHUD from "./tagHUD";

interface IProps {
  deckName: string;
  deckTagName: string | undefined;
  activeCardIndex: number;
  numCards: number;
  showTags: boolean;
  cardTags: string[];
  timerMillis?: number;
  isTheaterMode?: boolean;
  timerMode: TimerMode;
}
export default function HUD(props: IProps): JSX.Element {
  const {
    deckName,
    deckTagName,
    activeCardIndex,
    numCards,
    showTags,
    cardTags,
    timerMillis,
    isTheaterMode,
    timerMode,
  } = props;
  const hudStyle: React.CSSProperties = {
    position: "fixed",
    zIndex: 9,
    width: "100%",
    opacity: 0.9,
  };

  const showTimer = timerMode !== "off" && timerMillis !== undefined;
  const displayName = Deck.folderSeparatorFormattedName(deckName ?? "");
  const progress = activeCardIndex / numCards || 0;

  return (
    <>
      <div style={hudStyle}>
        <Subtitle
          text={isTheaterMode ? undefined : <MiddleEllipse text={displayName} />}
          progress={progress}
          isTheaterMode={isTheaterMode}
        />
      </div>
      {showTimer && <TimerHUD isTheaterMode={isTheaterMode} />}
      {showTags && (
        <TagHUD
          folder={deckTagName}
          tags={cardTags}
          noChip
          style={{
            left: showTimer ? 32 : 8,
            marginTop: isTheaterMode ? 4 : 36,
          }}
        />
      )}
      {isTheaterMode ? (
        <ProgressPie
          donut={isTheaterMode}
          progress={progress}
          style={{
            opacity: 0.5,
            position: "fixed",
            right: "5px",
            zIndex: 9,
          }}
        />
      ) : undefined}
    </>
  );
}
