import { useCallback, useRef } from "react";
import { genTraceID } from "analytics/action";
import logEvent from "analytics";

type StepLogger = (step: string, extra: any) => void;
export function useFunnelLogger(name: string): StepLogger {
  const traceIDRef = useRef(genTraceID());
  const stepLogger = useCallback(
    (step: string, extra: any) => {
      logEvent(name, { ...extra, step, traceID: traceIDRef.current });
    },
    [name],
  );
  return stepLogger;
}
