import FeatureFlags, { ReviewGateFlag } from "featureFlags";
import {
  getGlobalTotalNumberReviewsToday,
  getGlobalTotalReviews,
} from "hooks/data/responseHistory";
import { hasUnlimitedBasedOnApplePurchase } from "hooks/util/useHasUnlimited";
import evalReviewGateConditions, { IReviewGateInputs } from "./evalReviewGateConditions";

export type IReviewGateConfig = Partial<ReviewGateFlag>;
const flagDefault: IReviewGateConfig = {
  user_created_at_threshold: "2023-02-28",
  num_daily_reviews_warn_threshold: 50,
  num_daily_reviews_gate_threshold: 100,
  num_total_reviews_warn_threshold: 800,
};

const flagName = "review_gate";

export type ReviewGateLevel = "warn" | "gate";

let reviewGateThreshold = 1000;

export const getReviewGateThreshold = () => {
  return reviewGateThreshold;
};

export const setReviewGateThreshold = (n: number) => {
  reviewGateThreshold = n;
};

function parseFlag(flag: unknown): IReviewGateConfig {
  function isReviewGateConfig(obj: unknown): obj is IReviewGateConfig {
    return (
      typeof obj === "object" &&
      obj !== null &&
      Object.keys(flagDefault).every((key) => {
        const value = (obj as Record<string, unknown>)[key];
        return (
          typeof value === typeof flagDefault[key as keyof IReviewGateConfig] ||
          typeof value === "undefined"
        );
      })
    );
  }

  if (isReviewGateConfig(flag)) {
    return flag;
  }
  return flagDefault;
}

export interface IReviewGateResult {
  level: ReviewGateLevel;
  inputs?: IReviewGateInputs;
  flag?: IReviewGateConfig;
}
// null indicates no gate (a.k.a. user can review freely).
export async function checkReviewGate(): Promise<IReviewGateResult | null> {
  if (hasUnlimitedBasedOnApplePurchase()) {
    return null;
  }

  const flagAndContext = FeatureFlags.getFlagAndContext(flagName);
  if (flagAndContext.fetching) {
    // console.log("Flags not initially available");
    return null;
  }

  if (flagAndContext.error || flagAndContext.fetching) {
    // console.log("Error parsing flag and context");
    return null;
  }

  const { context } = flagAndContext;
  if (!context) {
    // console.log("Not showing ad due to missing context");
    return null;
  }
  if (context.hasUnlimited) {
    // console.log("Not showing ad due to being unlimited");
    return null;
  }

  const flag = parseFlag(flagAndContext.flag);
  if (flag.num_daily_reviews_gate_threshold) {
    setReviewGateThreshold(flag.num_daily_reviews_gate_threshold);
  }

  // HACK: uncomment to test review gate.
  // return {
  //   level: "gate",
  //   flag: flagDefault,
  //   inputs: {
  //     numReviewsToday: 100,
  //     numReviewsTotal: 1000,
  //     userCreatedAt: new Date().toUTCString(),
  //   },
  // };

  const { userCreatedAt } = context;
  try {
    const numReviewsToday = await getGlobalTotalNumberReviewsToday();
    const numReviewsTotal = await getGlobalTotalReviews();
    const inputs = { numReviewsToday, numReviewsTotal, userCreatedAt };
    const level = evalReviewGateConditions(flag, inputs);
    if (!level) {
      return null;
    }

    return {
      level,
      flag,
      inputs,
    };
  } catch {
    return null;
  }
}
