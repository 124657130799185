import { deckListRowMatchesQuery } from "@screens/search/deckResults";
import { GlobalVars } from "globals";
import { History } from "history";
import { useDeckListWithFolders } from "hooks/data/deckList";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import React, { useEffect } from "react";
import LocalAndRemoteDecks from "../decks/localAndRemoteDecks";
import sortRows from "../decks/sortRows";
import { SortOptions } from "../decks/types";
import GetStartedModal from "./getStartedModal";
import OnboardingModal from "./onboarding/onboardingModal";

interface IProps {
  query?: string;
  sortOrder?: SortOptions;
  handleSort: (s: SortOptions) => void;
  maxItems: number;
  onNewDeck: () => void;
  onImportDeck: () => void;
  onAddToInbox: () => void;
  history: History;
}
export default function CombinedFolderAndDeckList(props: IProps): JSX.Element {
  const { query, sortOrder, handleSort, maxItems } = props;

  // Convert to folders and eliminate redundancy.
  const deckList = useDeckListWithFolders(!query ? null : undefined);
  const matchingRows =
    query !== undefined
      ? deckList.rows.filter((r) => deckListRowMatchesQuery(r, query))
      : deckList.rows;
  const sortedRows = sortRows(sortOrder, matchingRows);
  const displayedRows = sortedRows.slice(0, maxItems);

  const [presentOnboardingModal] = useIonCardModalWithDismiss(OnboardingModal, {
    onAddToInbox: props.onAddToInbox,
    history: props.history,
  });
  const [presentGetStartedModal] = useIonCardModalWithDismiss(GetStartedModal, {
    onAddToInbox: props.onAddToInbox,
    history: props.history,
  });
  const empty = deckList.rows.length < 1;
  useEffect(() => {
    if (GlobalVars.userJustRegistered) {
      presentOnboardingModal();
      GlobalVars.userJustRegistered = false;
      return;
    }
    if (empty) {
      presentGetStartedModal();
      return;
    }
  }, [presentOnboardingModal, presentGetStartedModal, empty]);

  return (
    <LocalAndRemoteDecks
      loadingLocal={false}
      loadingRemote={false}
      totalRows={deckList.rows.length}
      sortedRows={displayedRows}
      sortOrder={sortOrder}
      handleSort={handleSort}
      errorLoadingRemoteDecks={false}
      presentGetStartedModal={presentGetStartedModal}
    />
  );
}
