// iosAvailableIAPProductsMetadata returns the intersection of products offered via iOS IAP and products server knows about.

import useErrorAlert from "hooks/util/useErrorAlert";
import IAPv2 from "iap";
import { useEffect, useState } from "react";
import { IIAPProduct, IStoreKitProduct } from "./ios";
import { frequencyFor } from "./lib";
import PDPLogger from "./logger";
import { FreeTrialDuration } from "./types";

function parseFreeTrialDuration(skp: IStoreKitProduct): FreeTrialDuration {
  const count = Number.parseInt(skp.free_trial_unit_count);
  if (skp.free_trial_units === "day" && count === 3) {
    return "3 days";
  }
  if (
    (skp.free_trial_units === "week" && count === 1) ||
    (skp.free_trial_units === "day" && count === 7)
  ) {
    return "1 week";
  }
}

export function iosAvailableIAPProductsMetadata(skProds: IStoreKitProduct[]): IIAPProduct[] {
  const products: IIAPProduct[] = [];
  for (const skp of skProds) {
    const serverProductID = serverProductIDFor(skp.id);
    if (!serverProductID) {
      continue;
    }
    const frequency = frequencyFor(serverProductID);
    const product: IIAPProduct = {
      id: serverProductID,
      iapProductId: skp.id,
      name: skp.name,
      description: skp.description,
      display_price: skp.display_price,
      frequency,
      eligible_for_free_trial: skp.eligible_for_free_trial === "true",
      freeTrialDuration: parseFreeTrialDuration(skp),
    };
    products.push(product);
  }
  return products;
}

function serverProductIDFor(iTunesProductID: string): string | undefined {
  // NOTE: this is a switch statement rather than a simple property lookup
  // in a const object, because JS autoformatters like to strip the quotes
  // off from around constant property names, which could result in a minifier
  // getting clever and minifying those property names. Don't want that.
  switch (iTunesProductID) {
    case "free_trial_test3":
      return "unlimited_year";
    case "test_free_trial2":
      return "unlimited_year";
    case "test_free_trial":
      return "unlimited_year";
    case "unlimited_yearly":
      return "unlimited_year";
    case "unlimited_lifetime":
      return "unlimited_lifetime";
  }
}

export async function fetchIAPProducts(): Promise<IIAPProduct[]> {
  return new Promise((resolve, reject) => {
    function handleIAPPluginProductsFetchFailed() {
      reject();
    }

    function handleIAPPluginProductsFetchSuccess(resp: { products: IStoreKitProduct[] }) {
      const products = iosAvailableIAPProductsMetadata(resp.products);
      resolve(products);
    }

    IAPv2.getProducts()
      .then(handleIAPPluginProductsFetchSuccess)
      .catch(handleIAPPluginProductsFetchFailed);
  });
}

export function useIAPProducts(
  logger: PDPLogger,
  onPurchaseFailure: (err?: string) => void,
): IIAPProduct[] | undefined {
  const [productsState, setProductsState] = useState<IIAPProduct[]>();
  const [showError] = useErrorAlert({ code: "FETCHING_IAPS" });

  useEffect(() => {
    fetchIAPProducts()
      .then((state) => {
        logger.log("apple_iap_products_fetched", { ...state });
        setProductsState(state);
      })
      .catch((err) => {
        logger.log("apple_iap_products_fetch_failed");
        showError(err);
        onPurchaseFailure();
      });
  }, [logger, onPurchaseFailure, showError]);

  return productsState;
}
