import InfoDot from "@components/infoDot";
import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { cardsPerReviewAllValue } from "@models/deckSettings";
import { DotID, clearDot } from "dots";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback } from "react";
import CardsPerSessionSettingModal from "./cardsPerSessionSettingModal";
import { PiCards } from "react-icons/pi";
import SettingsListItemIcon from "@components/settingsListItemIcon";

interface IProps {
  numTotalCards: number;
  cardsPerReview: number;
  onChange: (num: number) => void;
  description?: string;
}
export default function CardsPerSessionSettingItem({
  numTotalCards,
  cardsPerReview,
  onChange,
  description,
}: IProps): JSX.Element {
  const dotID: DotID = "cards_per_session_setting";

  const [showModal] = useIonCardModalWithDismiss(CardsPerSessionSettingModal, {
    numTotalCards,
    cardsPerReview,
    onChange,
  });
  const show = useCallback(async () => {
    clearDot(dotID).catch(() => {});
    showModal();
  }, [showModal]);

  return (
    <IonItem button onClick={show}>
      <InfoDot id={dotID} />
      <SettingsListItemIcon icon={PiCards} />
      <IonLabel>
        <h2>{L10n.localize((s) => s.card.perSession)}</h2>
        {description ? <p>{description}</p> : undefined}
      </IonLabel>
      <IonNote slot="end">
        {cardsPerReview === cardsPerReviewAllValue
          ? L10n.localize((s) => s.general.all)
          : cardsPerReview}
      </IonNote>
    </IonItem>
  );
}
