import { Deck } from "@models/deck";
import { archiveFolder } from "@models/folder";
import { IDeckListFolderRow } from "hooks/data/deckList";
import useErrorAlert from "hooks/util/useErrorAlert";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import DeckItem from "./deckItem";
import { useGradePercent } from "./useGradePercent";

export default function DeckListFolderItem({ row }: { row: IDeckListFolderRow }): JSX.Element {
  const href = `/folders/${encodeURIComponent(row.folderPath ?? row.name)}`;
  const name = Deck.folderSeparatorFormattedName(row.name);
  const gradePct = useGradePercent(row.decks, false);
  const numDecks = row.count;

  const [showArchiveFolderError] = useErrorAlert({ code: "ARCHIVING_FOLDER" });
  const handleArchive = useCallback(async () => {
    archiveFolder(name).catch((err) => showArchiveFolderError(err));
  }, [name]);

  const history = useHistory();
  const handleClick = useCallback(() => {
    history.push(href);
  }, [href, history]);

  let modifiedAt: Date | undefined = undefined;
  for (const deck of row.decks ?? []) {
    if (!modifiedAt || deck.modified_at > modifiedAt) {
      modifiedAt = deck.modified_at;
    }
  }

  return (
    <DeckItem
      key={row.name}
      name={name}
      numDecks={numDecks}
      type={row.type}
      hideStatusIcon={false}
      timestamp={row.timestamp}
      modifiedAt={modifiedAt}
      gradePct={gradePct}
      onArchive={handleArchive}
      onDelete={!row.decks || row.decks.length < 1 ? handleArchive : undefined}
      onClick={handleClick}
    />
  );
}
