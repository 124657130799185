export default {
  onboarding: {
    genSampleDeckLoadingMessage: {
      en: "Creating flashcards based on your responses...",
      "pt-br": "Criando cartões com base em suas respostas...",
      "zh-cn": "根据您的回答创建暗记卡...",
      es: "Creando tarjetas basadas en tus respuestas...",
      fr: "Création de cartes à partir de vos réponses...",
      "zh-tw": "根據您的回答創建暗記卡...",
      ja: "あなたの回答に基づいてフラッシュカードを作成中...",
      de: "Erstelle Lernkarten basierend auf deinen Antworten...",
    },
    langContextNavDescription: {
      en: 'What contexts do you anticipate using the language in? For example "using public transit", "ordering food at restaurants", "checking in to a hotel", etc...',
      "zh-tw":
        "您預計在哪些情境中使用該語言？例如「使用公共交通」、「在餐廳點餐」、「辦理酒店入住」等……",
      fr: 'Quelles contextes prévoyez-vous d\'utiliser la langue ? Par exemple, "utiliser les transports en commun", "commander de la nourriture au restaurant", "s\'enregistrer à l\'hôtel", etc.',
      "pt-br":
        'Quais contextos você prevê usar o idioma? Por exemplo, "usar transporte público", "pedir comida em restaurantes", "fazer check-in em um hotel", etc...',
      es: '¿Qué contextos anticipas usar el idioma? Por ejemplo "usar el transporte público", "pedir comida en restaurantes", "registrarse en un hotel", etc...',
      de: 'In welchem Kontext planen Sie, die Sprache zu verwenden? Zum Beispiel "öffentliche Verkehrsmittel nutzen", "Essen in einem Restaurant bestellen", "in einem Hotel einchecken" usw.',
      "zh-cn":
        "您预计在哪些场合使用这种语言？例如“搭乘公共交通工具”，“在餐馆点餐”，“酒店入住登记”等……",
      ja: "どのような状況でその言語を使用することを想定していますか？たとえば、「公共交通機関の利用」、「レストランでの食事の注文」、「ホテルでのチェックイン」など。",
    },
    learningVectorDescription: {
      en: "How much do you know already and what would you like to learn or accomplish next?",
      "pt-br": "Quanto você já sabe e o que gostaria de aprender ou alcançar a seguir?",
      "zh-tw": "你已經知道多少，接下來想學習或完成什麼？",
      "zh-cn": "你已经知道多少，你接下来想学习或完成什么？",
      fr: "Que savez-vous déjà et qu'aimeriez-vous apprendre ou accomplir ensuite ?",
      ja: "あなたがすでにどれくらい知っているのか、そして次に何を学んだり達成したいのか教えてください。",
      de: "Wie viel weißt du schon und was möchtest du als Nächstes lernen oder erreichen?",
      es: "¿Cuánto sabes ya y qué te gustaría aprender o lograr a continuación?",
    },
    testPrepDescription: {
      en: "How much preparation have you already done? For example, how many days have you studied for it?",
      "pt-br":
        "Quanto de preparação você já fez? Por exemplo, quantos dias você estudou para isso?",
      de: "Wie viel Vorbereitung hast du bereits gemacht? Zum Beispiel, wie viele Tage hast du dafür gelernt?",
      ja: "どれくらい準備をしましたか？例えば、何日間それを勉強しましたか？",
      "zh-cn": "你已经做了多少准备？例如，你已经为此学习了多少天？",
      es: "¿Cuánta preparación ya has hecho? Por ejemplo, ¿cuántos días has estudiado para ello?",
      fr: "Combien de préparation avez-vous déjà faite ? Par exemple, combien de jours avez-vous étudié pour ça ?",
      "zh-tw": "你已經做了多少準備？例如，你已經為此學習了多少天？",
    },
    schoolSubjectMedicine: {
      en: "Medicine",
      "zh-tw": "醫學",
      de: "Medizin",
      fr: "Médecine",
      es: "Medicina",
      "zh-cn": "医学",
      "pt-br": "Medicina",
      ja: "医学",
    },
    schoolSubjectBiology: {
      en: "Biology",
      es: "Biología",
      de: "Biologie",
      ja: "生物学",
      "pt-br": "Biologia",
      "zh-tw": "生物學",
      "zh-cn": "生物学",
      fr: "Biologie",
    },
    schoolSubjectChemistry: {
      en: "Chemistry",
      ja: "化学",
      "zh-cn": "化学",
      "zh-tw": "化學",
      fr: "Chimie",
      de: "Chemie",
      "pt-br": "Química",
      es: "Química",
    },
    schoolSubjectPsychology: {
      en: "Psychology",
      es: "Psicología",
      "zh-tw": "心理學",
      de: "Psychologie",
      "pt-br": "Psicologia",
      fr: "Psychologie",
      "zh-cn": "心理学",
      ja: "心理学",
    },
    schoolSubjectEconomics: {
      en: "Economics",
      es: "Economía",
      fr: "Économie",
      "zh-tw": "經濟學",
      "pt-br": "Economia",
      "zh-cn": "经济学",
      de: "Wirtschaftswissenschaften",
      ja: "経済学",
    },
    schoolSubjectComputerScience: {
      en: "Computer Science",
      "zh-cn": "计算机科学",
      fr: "Informatique",
      es: "Ciencias de la Computación",
      "zh-tw": "計算機科學",
      ja: "コンピュータサイエンス",
      "pt-br": "Ciência da Computação",
      de: "Informatik",
    },
    goalSchool: {
      en: "Study for School",
      de: "Für die Schule lernen",
      "zh-tw": "為學校復習",
      es: "Estudio para la Escuela",
      fr: "Étudier pour l'école",
      "pt-br": "Estudar para a Escola",
      ja: "学校の勉強用",
      "zh-cn": "为学校学习",
    },
    goalTest: {
      en: "Study for a Standardized Test",
      de: "Für einen standardisierten Test lernen",
      "zh-cn": "为标准化考试复习",
      fr: "Étudier pour un test standardisé",
      "zh-tw": "為標準化測試進行復習",
      es: "Estudiar para un examen estandarizado",
      ja: "標準化されたテストの勉強",
      "pt-br": "Estudar para um Teste Padronizado",
    },
    goalLang: {
      en: "Learn a Language",
      "zh-tw": "學習語言",
      "pt-br": "Aprenda um Idioma",
      es: "Aprender un idioma",
      "zh-cn": "学习语言",
      de: "Lerne eine Sprache",
      fr: "Apprendre une langue",
      ja: "言語を学ぶ",
    },
    sampleDeck: {
      en: "Sample Deck",
      fr: "Exemple de Deck",
      ja: "サンプルデッキ",
      de: "Beispielstapel",
      es: "Baraja de muestra",
      "zh-cn": "示例暗记包",
      "zh-tw": "範例暗記包",
      "pt-br": "Exemplo de Deck",
    },
    genFlashcardsNavDescription: {
      en: "AnkiApp helps you learn efficiently by practicing flashcards. We call this Reviewing. We'll make you some flashcards to try.",
      "pt-br":
        "AnkiApp ajuda você a aprender de forma eficiente praticando cartões. Chamamos isso de Revisão. Faremos alguns cartões para você experimentar.",
      fr: "AnkiApp vous aide à apprendre efficacement en pratiquant avec des flashcards. Nous appelons cela Révision. Nous allons vous créer quelques flashcards à essayer.",
      de: "AnkiApp hilft Ihnen, effizient zu lernen, indem Sie mit Lernkarten üben. Wir nennen das Überprüfung. Wir erstellen Ihnen einige Lernkarten zum Ausprobieren.",
      "zh-cn":
        "AnkiApp通过练习暗记卡帮助你高效学习。我们称之为复习。我们会为你制作一些暗记卡来尝试。",
      ja: "AnkiAppはフラッシュカードを使って効率的に学習するのを助けます。これを「レビュー」と呼びます。試しにいくつかのフラッシュカードを作成します。",
      "zh-tw":
        "AnkiApp 通過練習暗記卡來幫助你有效學習。我們稱這為復習。我們會為你製作一些暗記卡來試試。",
      es: "AnkiApp te ayuda a aprender eficientemente practicando tarjetas. A esto lo llamamos Revisar. Te haremos algunas tarjetas para probar.",
    },
    langMotivationTravel: {
      en: "For Travel",
      "zh-tw": "旅行用",
      "zh-cn": "用于旅行",
      es: "Para Viajar",
      ja: "旅行用",
      "pt-br": "Para Viagem",
      de: "Für Reisen",
      fr: "Pour Voyage",
    },
    langMotivationRelocate: {
      en: "To Live Somewhere New",
      "zh-tw": "住在新地方",
      "pt-br": "Viver em Um Lugar Novo",
      de: "Woanders leben",
      es: "Vivir en un lugar nuevo",
      fr: "Vivre Quelque Part de Nouveau",
      ja: "新しい場所に住む",
      "zh-cn": "到一个新地方生活",
    },
    langMotivationWork: {
      en: "For Work",
      "pt-br": "Para Trabalho",
      fr: "Pour le travail",
      de: "Für die Arbeit",
      es: "Para trabajar",
      "zh-cn": "工作用",
      "zh-tw": "工作用",
      ja: "仕事用",
    },
    langMotivationSchool: {
      en: "For School",
      "zh-cn": "为学校",
      fr: "Pour l'école",
      "pt-br": "Para a Escola",
      de: "Für die Schule",
      ja: "学校用",
      es: "Para la escuela",
      "zh-tw": "適用于學校",
    },
    langMotivationFun: {
      en: "For Fun/General Interest",
      "zh-cn": "兴趣/一般爱好",
      de: "Zum Spaß/allgemeines Interesse",
      es: "Por diversión/interés general",
      ja: "娯楽/一般の興味",
      fr: "Pour le Plaisir/Intérêt Général",
      "pt-br": "Para Diversão/Interesse Geral",
      "zh-tw": "娛樂/一般興趣",
    },
    langNavTitle: {
      en: "Language",
      "zh-cn": "语言",
      "zh-tw": "語言",
      ja: "言語",
      "pt-br": "Idioma",
      de: "Sprache",
      fr: "Langue",
      es: "Idioma",
    },
    langProficiencyNone: {
      en: "None",
      de: "Keine",
      ja: "なし",
      fr: "Aucun",
      es: "Ninguno",
      "pt-br": "Nenhum",
      "zh-cn": "无",
      "zh-tw": "無",
    },
    langProficiencyNoneDesc: {
      en: "No knowledge of language.",
      es: "Sin conocimiento de idioma.",
      de: "Keine Sprachkenntnisse.",
      "zh-tw": "不懂語言。",
      "pt-br": "Sem conhecimento de idioma.",
      fr: "Aucune connaissance de la langue.",
      ja: "言語の知識なし。",
      "zh-cn": "不懂语言。",
    },
    langProficiencyA1: {
      en: "Beginner",
      es: "Principiante",
      "zh-cn": "初学者",
      de: "Anfänger",
      ja: "初心者",
      "pt-br": "Iniciante",
      "zh-tw": "初學者",
      fr: "Débutant",
    },
    langProficiencyA1Desc: {
      en: "Basic phrases, simple interactions, everyday topics.",
      es: "Frases básicas, interacciones simples, temas cotidianos.",
      "zh-cn": "基础短语，简单互动，日常话题。",
      "pt-br": "Frases básicas, interações simples, tópicos do cotidiano.",
      "zh-tw": "基本短語、簡單互動、日常話題。",
      de: "Einfache Sätze, einfache Interaktionen, alltägliche Themen.",
      fr: "Phrases de base, interactions simples, sujets quotidiens.",
      ja: "基本的なフレーズ、簡単な対話、日常的なトピック。",
    },
    langProficiencyA2: {
      en: "Elementary",
      es: "Elemental",
      de: "Elementar",
      "zh-cn": "小学",
      "pt-br": "Elementar",
      fr: "Élémentaire",
      ja: "初級",
      "zh-tw": "初級",
    },
    langProficiencyA2Desc: {
      en: "Short sentences, routine tasks, familiar situations.",
      "zh-cn": "短句，常规任务，熟悉情境。",
      es: "Frases cortas, tareas rutinarias, situaciones familiares.",
      "pt-br": "Frases curtas, tarefas rotineiras, situações familiares.",
      fr: "Phrases courtes, tâches routinières, situations familières.",
      "zh-tw": "短句子，例行任務，熟悉的情況。",
      de: "Kurze Sätze, routinemäßige Aufgaben, vertraute Situationen.",
      ja: "短い文、日常的なタスク、慣れ親しんだ状況。",
    },
    langProficiencyB1: {
      en: "Intermediate",
      es: "Intermedio",
      "zh-tw": "中級",
      fr: "Intermédiaire",
      "zh-cn": "中级",
      "pt-br": "Intermediário",
      de: "Mittelstufe",
      ja: "中級",
    },
    langProficiencyB1Desc: {
      en: "Basic fluency, opinions, and common conversations.",
      "zh-cn": "基本流利度、观点和日常对话。",
      "pt-br": "Fluência básica, opiniões e conversas comuns.",
      "zh-tw": "基本流利度、意見和常見對話。",
      ja: "基本的な流暢さ、意見、一般的な会話。",
      fr: "Fluence de base, opinions et conversations courantes.",
      de: "Grundlegende Sprachkenntnisse, Meinungen und alltägliche Gespräche.",
      es: "Fluidez básica, opiniones y conversaciones comunes.",
    },
    langProficiencyB2: {
      en: "Upper Intermediate",
      "zh-cn": "中高级",
      "zh-tw": "中高級",
      es: "Intermedio Alto",
      ja: "中級上",
      de: "Mittelstufe",
      fr: "Intermédiaire Supérieur",
      "pt-br": "Intermediário Avançado",
    },
    langProficiencyB2Desc: {
      en: "Complex discussions, professional topics, fluent interaction.",
      fr: "Discussions complexes, sujets professionnels, interaction fluide.",
      "pt-br": "Discussões complexas, tópicos profissionais, interação fluente.",
      de: "Komplexe Diskussionen, fachliche Themen, fließende Interaktion.",
      "zh-tw": "複雜討論，專業話題，流利互動。",
      ja: "複雑な議論、専門的なトピック、流暢なやり取り。",
      es: "Discusiones complejas, temas profesionales, interacción fluida.",
      "zh-cn": "复杂讨论, 专业主题, 流利互动。",
    },
    langProficiencyC1: {
      en: "Advanced",
      "zh-cn": "高级",
      de: "Fortgeschritten",
      "pt-br": "Avançado",
      fr: "Avancé",
      "zh-tw": "進階",
      es: "Avanzado",
      ja: "上級",
    },
    langProficiencyC1Desc: {
      en: "Sophisticated language, nuanced expression, academic topics.",
      "zh-cn": "复杂语言、细致表达、学术主题。",
      "pt-br": "Linguagem sofisticada, expressão matizada, tópicos acadêmicos.",
      fr: "Langage sophistiqué, expression nuancée, sujets académiques.",
      es: "Lenguaje sofisticado, expresión matizada, temas académicos.",
      "zh-tw": "高級語言、細膩表達、學術主題。",
      ja: "洗練された言語、微妙な表現、学術的なトピック。",
      de: "Anspruchsvolle Sprache, nuancierte Ausdrucksweise, akademische Themen.",
    },
    langProficiencyC2: {
      en: "Proficient",
      ja: "熟練",
      "zh-cn": "熟练",
      "pt-br": "Proficiente",
      "zh-tw": "精通",
      de: "Kompetent",
      fr: "Compétent",
      es: "Proficiente",
    },
    langProficiencyC2Desc: {
      en: "Near-native, complex understanding, effortless communication.",
      fr: "Compréhension complexe, communication fluide, comme un natif.",
      "pt-br": "Compreensão quase nativa, comunicação complexa e sem esforço.",
      es: "Casi nativo, comprensión compleja, comunicación sin esfuerzo.",
      "zh-cn": "接近母语的复杂理解，轻松沟通。",
      de: "Beinahe-natives Verständnis, komplexes Verständnis, mühelose Kommunikation.",
      "zh-tw": "接近母語的複雜理解，流利溝通。",
      ja: "ほぼネイティブ、複雑な理解、楽々なコミュニケーション。",
    },
    schoolNavTitle: {
      en: "School",
      "zh-cn": "学校",
      es: "Escuela",
      fr: "École",
      de: "Schule",
      "pt-br": "Escola",
      ja: "学校 (がっこう)",
      "zh-tw": "學校",
    },
    testNavTitle: {
      en: "Test",
      ja: "テスト",
      de: "Test",
      "zh-tw": "測試",
      "zh-cn": "测试",
      es: "Prueba",
      "pt-br": "Teste",
      fr: "Test",
    },
    langNavDescription: {
      en: "Which language do you want to learn?",
      es: "¿Qué idioma quieres aprender?",
      "pt-br": "Qual idioma você deseja aprender?",
      fr: "Quelle langue voulez-vous apprendre ?",
      "zh-cn": "你想学习哪种语言？",
      de: "Welche Sprache möchtest du lernen?",
      "zh-tw": "你想學哪種語言？",
      ja: "どの言語を学びたいですか？",
    },
    langMotivationNavDescription: {
      en: "What is the main reason you want to learn it?",
      "pt-br": "Qual é a principal razão para você querer aprender isso?",
      fr: "Quelle est la principale raison pour laquelle vous voulez l'apprendre ?",
      "zh-cn": "你学习它的主要原因是什么？",
      es: "¿Cuál es la principal razón por la que quieres aprenderlo?",
      "zh-tw": "你想學習它的主要原因是什麼？",
      de: "Was ist der Hauptgrund, warum Sie es lernen möchten?",
      ja: "それを学びたい主な理由は何ですか？",
    },
    langProficiencyNavDescription: {
      en: "What is your proficiency level?",
      "pt-br": "Qual é o seu nível de proficiência?",
      fr: "Quel est votre niveau de compétence ?",
      "zh-cn": "你的熟练程度是什么?",
      "zh-tw": "你的熟練程度是什麼？",
      de: "Was ist Ihr Sprachniveau?",
      es: "¿Cuál es tu nivel de competencia?",
      ja: "あなたの習熟度は？",
    },
    schoolNavDescription: {
      en: "What is the main subject you want to study?",
      "zh-cn": "你想学习的主要科目是什么？",
      "pt-br": "Qual é o principal assunto que você quer estudar?",
      es: "¿Cuál es el tema principal que deseas estudiar?",
      fr: "Quel est le sujet principal que vous souhaitez étudier ?",
      de: "Was ist das Hauptfach, das Sie studieren möchten?",
      "zh-tw": "您想要學習的主要科目是什麼？",
      ja: "あなたが勉強したい主な科目は何ですか？",
    },
    testNavDescription: {
      en: "What test are you studying for?",
      "zh-cn": "你在准备什么考试？",
      de: "Welchen Test lernst du?",
      es: "¿Para qué examen estás estudiando?",
      "zh-tw": "你正在準備什麼考試？",
      fr: "Pour quel test étudiez-vous ?",
      "pt-br": "Para qual teste você está estudando?",
      ja: "どのテストの勉強をしていますか?",
    },
    goalNavDescription: {
      en: "What is your goal? This helps us tailor AnkiApp to you.",
      "pt-br": "Qual é o seu objetivo? Isso nos ajuda a personalizar o AnkiApp para você.",
      es: "¿Cuál es tu objetivo? Esto nos ayuda a personalizar AnkiApp para ti.",
      de: "Was ist Ihr Ziel? Dies hilft uns, AnkiApp auf Sie abzustimmen.",
      "zh-tw": "您的目標是什麼？這有助於我們為您量身打造AnkiApp。",
      fr: "Quel est votre objectif ? Cela nous aide à adapter AnkiApp pour vous.",
      "zh-cn": "你的目标是什么？这有助于我们根据您的需求定制 AnkiApp。",
      ja: "あなたの目標は何ですか？これにより、AnkiAppをあなたに合わせて調整できます。",
    },
    goalBreadcrumb: {
      en: "Goal",
      "pt-br": "Meta",
      es: "Objetivo",
      ja: "目標",
      "zh-cn": "目标",
      de: "Ziel",
      "zh-tw": "目標",
      fr: "Objectif",
    },
    detailsBreadcrumb: {
      en: "Details",
      ja: "詳細",
      es: "Detalles",
      "zh-tw": "詳情",
      "pt-br": "Detalhes",
      fr: "Détails",
      de: "Einzelheiten",
      "zh-cn": "详情",
    },
    flashcardsBreadcrumb: {
      en: "Flashcards",
      es: "Tarjetas de memoria",
      ja: "フラッシュカード",
      "zh-cn": "暗记卡",
      "pt-br": "Cartões",
      de: "Karteikarten",
      "zh-tw": "暗記卡",
      fr: "Flashcards",
    },
  },
  getStarted: {
    getStarted: {
      en: "Get Started",
      "pt-br": "Começar",
      fr: "Commencer",
      de: "Los Geht's",
      es: "Comenzar",
      ja: "スタート",
      "zh-cn": "开始",
      "zh-tw": "開始使用",
    },
    makeContent: {
      en: "Make Content",
      fr: "Créer du contenu",
      de: "Inhalt erstellen",
      "pt-br": "Criar Conteúdo",
      ja: "コンテンツを作る",
      es: "Contenidos",
      "zh-cn": "制作内容",
      "zh-tw": "製作內容",
    },
    loadContent: {
      en: "Load Content",
      fr: "Contenu du chargement",
      "pt-br": "Carregar Conteúdo",
      ja: "コンテンツを読み込む",
      de: "Inhalt laden",
      es: "Cargar contenido",
      "zh-cn": "加载内容",
      "zh-tw": "載入內容",
    },
    findContent: {
      en: "Find Content",
      de: "Inhalt finden",
      ja: "コンテンツを探す",
      "pt-br": "Encontrar Conteúdo",
      fr: "Trouver un contenu",
      es: "Buscar contenido",
      "zh-cn": "查找内容",
      "zh-tw": "尋找內容",
    },
    contentSource: {
      en: "Content Source",
      de: "Inhalt Quelle",
      ja: "コンテンツ・ソース",
      "pt-br": "Fonte de Conteúdo",
      es: "Fuente del contenido",
      fr: "Source du contenu",
      "zh-tw": "內容來源",
      "zh-cn": "内容来源",
    },
    photo: {
      en: "Photo",
      ja: "写真",
      "pt-br": "Foto",
      fr: "Photo",
      de: "Foto",
      es: "Foto",
      "zh-cn": "照片",
      "zh-tw": "照片",
    },
    photoDescription: {
      en: "Automatically extract content from photos of notes, study materials, signage, menus, etc...",
      ja: "ノート、学習資料、看板、メニューなどの写真からコンテンツを自動的に抽出します。",
      fr: "Extraire automatiquement le contenu des photos des notes, du matériel d'étude, de la signalisation, des menus, etc...",
      "pt-br":
        "Extrair automaticamente conteúdo de fotos de anotações, materiais de estudo, sinalização, menus, etc...",
      es: "Extraiga automáticamente el contenido de fotos de apuntes, material de estudio, señalización, menús, etc.",
      de: "Automatisches Extrahieren von Inhalten aus Fotos von Notizen, Studienmaterialien, Beschilderungen, Speisekarten usw...",
      "zh-cn": "自动提取照片中的笔记、学习资料、标牌、菜单等内容...",
      "zh-tw": "自動從筆記、學習材料、標誌、菜單等照片中提取內容...",
    },
    spreadsheet: {
      en: "Spreadsheet",
      fr: "Feuille de calcul",
      ja: "スプレッドシート",
      "pt-br": "Planilha",
      de: "Tabellenkalkulation",
      es: "Hoja de cálculo",
      "zh-tw": "試算表",
      "zh-cn": "电子表格",
    },
    spreadsheetDescription: {
      en: "Import flashcards from CSV files",
      ja: "CSVファイルからフラッシュカードをインポート",
      fr: "Importer des flashcards à partir de fichiers CSV",
      es: "Importar fichas desde archivos CSV",
      "pt-br": "Importar cartões de arquivos CSV",
      de: "Importieren von Karteikarten aus CSV-Dateien",
      "zh-tw": "從 CSV 文件匯入暗記卡",
      "zh-cn": "从CSV文件导入暗记卡",
    },
    import: {
      en: "Import",
      es: "Importar",
      ja: "輸入",
      fr: "Importation",
      "pt-br": "Importar",
      de: "Importieren",
      "zh-cn": "导入",
      "zh-tw": "匯入",
    },
    importDescription: {
      en: "Import existing flashcards from backups and other apps",
      "pt-br": "Importar cartões existentes de backups e outros apps",
      de: "Importieren Sie bestehende Karteikarten aus Backups und anderen Anwendungen",
      es: "Importar tarjetas existentes desde copias de seguridad y otras aplicaciones",
      ja: "バックアップや他のアプリから既存のフラッシュカードをインポート",
      fr: "Importer des flashcards existantes à partir de sauvegardes et d'autres applications",
      "zh-tw": "從備份及其他應用程式匯入現有的暗記卡",
      "zh-cn": "从备份和其他应用程序导入现有的暗记卡",
    },
    manualDescription: {
      en: "Create your own flashcards with rich formatting, images and audio, automatic translation, text-to-speech (read aloud), and AI-assistance",
      ja: "豊富な書式、画像、音声、自動翻訳、テキスト読み上げ、AIアシストで自分だけのフラッシュカードを作成",
      "pt-br":
        "Crie seus próprios cartões com formatação avançada, imagens e áudio, tradução automática, texto-para-fala (leitura em voz alta) e assistência de IA.",
      es: "Cree sus propias tarjetas con formato enriquecido, imágenes y audio, traducción automática, conversión de texto a voz (lectura en voz alta) y asistencia de IA.",
      fr: "Créez vos propres flashcards avec un formatage riche, des images et de l'audio, une traduction automatique, une synthèse vocale (lecture à voix haute) et une assistance IA.",
      de: "Erstellen Sie Ihre eigenen Lernkarten mit umfangreicher Formatierung, Bildern und Audio, automatischer Übersetzung, Text-to-Speech (Vorlesen) und KI-Unterstützung",
      "zh-cn": "创建您自己的暗记卡，支持丰富的格式、图像和音频、自动翻译、文本朗读和AI助手。",
      "zh-tw":
        "創建您自己的暗記卡，具備豐富的格式、圖像和音頻、自動翻譯、文字轉語音（朗讀）和AI協助。",
    },
    searchDescription: {
      en: "Find pre-made content",
      de: "Vorgefertigte Inhalte finden",
      fr: "Trouver du contenu préétabli",
      es: "Buscar contenidos prefabricados",
      ja: "既成のコンテンツを探す",
      "pt-br": "Encontrar conteúdo pronto",
      "zh-cn": "查找预制内容",
      "zh-tw": "尋找預製內容",
    },
    description: {
      en: "To get started, you'll need content to study.",
      es: "Para empezar, necesitarás contenidos que estudiar.",
      de: "Für den Anfang brauchen Sie Inhalte, die Sie studieren können.",
      ja: "始めるには、勉強するためのコンテンツが必要だ。",
      "pt-br": "Para começar, você precisará de conteúdo para estudar.",
      fr: "Pour commencer, vous aurez besoin d'un contenu à étudier.",
      "zh-cn": "要开始，你需要学习内容。",
      "zh-tw": "要開始，您需要學習內容。",
    },
  },
  nux: {
    afterFirstCardCreate: {
      en: "You made your first card! To start studying, press < to go to the Deck screen. Or stay here and make more cards.",
      de: "Sie haben Ihre erste Karte erstellt! Um das Lernen zu beginnen, drücken Sie < zurück zur Deck-Ansicht. Oder bleiben Sie hier, um weitere Karten zu erstellen.",
      es: "¡Has hecho tu primera carta! Para empezar a estudiar, pulsa < para ir a la pantalla del mazo. O quédate aquí y haz más cartas.",
      fr: "Vous avez créé votre première carte ! Pour commencer à étudier, appuyez sur < pour aller à l'écran des Decks. Ou restez ici et créez d'autres cartes.",
      "pt-br":
        "Você fez seu primeiro cartão! Para começar a estudar, pressione < para ir para a tela do Baralho. Ou fique aqui e faça mais cartões.",
      "zh-cn":
        "你制作了你的第一张卡！要开始学习，请按<进入甲板屏幕。或者留在这里，制作更多的卡片。",
      "zh-tw":
        "你製作了你的第一張卡！要開始學習，請按<進入甲板螢幕。或者留在這裡，製作更多的卡片。",
      ja: "最初のカードが作れましたね勉強を始めるには、<を押してデッキの画面に移動します。また、ここに残ってもっとカードを作ることもできます。",
    },
    deckScreenFirstVisit: {
      en: 'Press "Review" to start studying.',
      de: 'Drücken Sie "Überprüfen", um das Lernen zu beginnen.',
      es: 'Pulsa "Revisar" para empezar a estudiar.',
      fr: 'Appuyez sur "Révision" pour commencer à étudier.',
      "pt-br": 'Pressione "Revisar" para começar a estudar.',
      "zh-cn": '按"复习"开始学习。',
      "zh-tw": "按「複習」開始學習。",
      ja: "「復習」を押して、勉強を開始します。",
    },
  },
  feedback: {
    reviewSatisfiedPrompt: {
      en: "Are you satisfied with AnkiApp?",
      de: "Sind Sie mit AnkiApp zufrieden?",
      es: "¿Estás satisfecho con AnkiApp?",
      fr: "Êtes-vous satisfait d'AnkiApp ?",
      "pt-br": "Você está satisfeito com a AnkiApp?",
      "zh-cn": "你对暗记App满意吗？",
      "zh-tw": "你對暗記App滿意嗎？",
      ja: "AnkiAppに満足していますか？",
    },
    reviewFreeformFeedbackPrompt: {
      en: "Tell us what's wrong:",
      de: "Teilen Sie uns mit, was nicht stimmt:",
      es: "Díganos qué pasa:",
      fr: "Dites-nous ce qui ne va pas :",
      "pt-br": "Diga-nos o que aconteceu:",
      "zh-cn": "告诉我们出了什么问题：",
      "zh-tw": "告訴我們出了什麼問題：",
      ja: "何が問題なのか、教えてください。",
    },
    reviewFreeformFeedbackTitle: {
      en: "We want to improve!",
      de: "Wir möchten uns verbessern!",
      es: "¡Queremos mejorar!",
      fr: "Nous voulons nous améliorer !",
      "pt-br": "Queremos melhorar!",
      "zh-cn": "我们要改进！",
      "zh-tw": "我們要改進！",
      ja: "改善したい!",
    },
    reviewRequestMobile: {
      en: "Please give AnkiApp a review—This helps drive future development.",
      de: "Bitte geben Sie AnkiApp eine Bewertung—Das hilft, die zukünftige Entwicklung voranzutreiben.",
      es: "Por favor, dale a AnkiApp una reseña - Esto ayuda a impulsar el desarrollo futuro.",
      fr: "Veuillez donner votre avis sur AnkiApp - cela permet de guider le développement futur.",
      "pt-br":
        "Por favor, faça uma avaliação a AnkiApp - Isso ajuda a impulsionar o desenvolvimento futuro.",
      "zh-cn": "请给暗记App一个评论——推动未来的发展。",
      "zh-tw": "請給暗記App一個評論——推動未來的發展。",
      ja: "AnkiAppのレビューをお願いします。",
    },
    reviewRequestNonMobile: {
      en: "Please consider recommending AnkiApp to a friend! This helps drive future development.",
      de: "Bitte erwägen Sie, AnkiApp einem Freund zu empfehlen! Das unterstützt die zukünftige Entwicklung.",
      es: "¡Por favor, considera recomendar AnkiApp a un amigo! Esto ayuda a impulsar el desarrollo futuro.",
      fr: "Pensez à recommander AnkiApp à un ami ! Cela contribue au développement futur de l'application.",
      "pt-br":
        "Por favor, considere recomendar a AnkiApp a um amigo! Isto ajuda a impulsionar o desenvolvimento futuro.",
      "zh-cn": "请考虑向朋友推荐暗记App！这有助于推动未来的发展。",
      "zh-tw": "請考慮向朋友推薦暗記App！這有助於推動未來的發展。",
      ja: "AnkiAppを友人に勧めることを検討してください。これは、将来の開発の原動力となります。",
    },
    reviewRequestTitle: {
      en: "Thanks for your feedback!",
      de: "Vielen Dank für Ihr Feedback!",
      es: "Gracias por sus comentarios.",
      fr: "Merci pour vos commentaires !",
      "pt-br": "Obrigado pelo seu feedback!",
      "zh-cn": "感谢您的反馈！",
      "zh-tw": "感謝您的反饋！",
      ja: "ご感想ありがとうございました。",
    },
    feedback: {
      en: "Feedback",
      de: "Rückmeldung",
      fr: "Commentaires",
      "pt-br": "Feedback",
      "zh-cn": "反馈",
      "zh-tw": "反饋",
      es: "Comentarios",
      ja: "フィードバック",
    },
    pdpCancelSurveyPrompt: {
      en: "What is the biggest improvement we could make to AnkiApp for you?",
      de: "Welche Verbesserung wäre für Sie bei AnkiApp am bedeutendsten?",
      es: "¿Cuál es la mayor mejora que podríamos hacer en AnkiApp para ti?",
      fr: "Quelle est la plus grande amélioration que nous pourrions apporter à AnkiApp pour vous ?",
      "pt-br": "Na sua opinião, qual a maior melhoria possivel que poderiamos fazer na AnkiApp?",
      "zh-cn": "我们可以为您对暗记App做出的最大改进是什么？",
      "zh-tw": "我們可以為您對暗記App做出的最大改進是什麼？",
      ja: "AnkiAppの最大の改善点は何ですか？",
    },
  },
  sharing: {
    emailShareInstructions: {
      en: 'Enter emails above and click "Add", then click "Share" to share the deck with them. You can add multiple email addresses separated by commas, to quickly share with many people at once.',
      ja: "上記のEメールアドレスを入力して「追加」をクリックし、「共有」をクリックすると、そのEメールアドレスとデッキを共有することができます。カンマ区切りで複数のメールアドレスを追加すれば、一度に多くの人と素早く共有できます。",
      es: 'Introduce las direcciones de correo electrónico y haz clic en "Añadir". A continuación, haz clic en "Compartir" para compartir el mazo con ellos. Puedes añadir varias direcciones de correo electrónico separadas por comas, para compartir rápidamente con muchas personas a la vez.',
      de: 'Geben Sie oben die E-Mail-Adressen ein und klicken Sie auf "Hinzufügen" und dann auf "Freigeben", um das Deck mit ihnen zu teilen. Sie können mehrere E-Mail-Adressen durch Kommas getrennt hinzufügen, um das Deck schnell mit mehreren Personen gleichzeitig zu teilen.',
      fr: 'Saisissez les adresses électroniques ci-dessus et cliquez sur "Ajouter", puis sur "Partager" pour leur transmettre le jeu de cartes. Vous pouvez ajouter plusieurs adresses électroniques séparées par des virgules, pour partager rapidement avec plusieurs personnes à la fois.',
      "pt-br":
        'Digite os e-mails acima e clique em "Adicionar", depois clique em "Compartilhar" para compartilhar o deck com eles. Você pode adicionar vários endereços de e-mail separados por vírgulas, para compartilhar rapidamente com muitas pessoas ao mesmo tempo.',
      "zh-cn":
        "在上方输入电子邮件并点击“添加”，然后点击“分享”来与他们分享暗记包。您可以添加多个用逗号分隔的电子邮件地址，以便快速与多人分享。",
      "zh-tw":
        "在上面輸入電郵地址並點擊「新增」，然後點擊「分享」以分享暗記包。你可以用逗號分隔多個電郵地址，快速與多人分享。",
    },
    invitePeople: {
      en: "Invite People",
      es: "Invitar a gente",
      de: "Menschen einladen",
      "pt-br": "Convidar Pessoas",
      fr: "Inviter des personnes",
      ja: "人を招く",
      "zh-cn": "邀请他人",
      "zh-tw": "邀請人員",
    },
    shareGroupLinkDescription: {
      en: "Share a link for people to join this study group",
      fr: "Partager un lien pour que les gens rejoignent ce groupe d'étude",
      es: "Comparta un enlace para que la gente se una a este grupo de estudio",
      de: "Geben Sie einen Link weiter, damit andere dieser Studiengruppe beitreten können",
      ja: "このスタディ・グループに参加するためのリンクを共有する。",
      "pt-br": "Compartilhe um link para as pessoas entrarem neste grupo de estudo",
      "zh-cn": "分享链接邀请人加入此学习小组",
      "zh-tw": "分享連結讓人們加入此學習小組",
    },
    copiedStudyGroupShareLink: {
      en: "Copied sharing link.",
      de: "Freigabelink kopiert.",
      es: "Enlace compartido copiado.",
      fr: "Copié le lien de partage.",
      "pt-br": "Link de compartilhamento copiada.",
      ja: "共有リンクをコピーしました。",
      "zh-cn": "分享链接已复制",
      "zh-tw": "分享鏈接已復制",
    },
    sharedDecks: {
      en: "Shared",
      de: "Geteilte Decks",
      es: "Compartido",
      fr: "Partagé",
      "pt-br": "Compartilhado",
      "zh-cn": "分享",
      "zh-tw": "分享",
      ja: "シェアード",
    },
    shareDeck: {
      en: "Share Deck",
      de: "Deck teilen",
      fr: "Partager le Deck",
      "zh-cn": "分享暗记包",
      "zh-tw": "分享暗記包",
      es: "Compartir la cubierta",
      ja: "シェアデッキ",
      "pt-br": "Compartilhar Deck",
    },
    shareDeckDescription: {
      en: "Other people in this Study Group will be able to review decks you share",
      es: "Otras personas de este Grupo de Estudio podrán revisar las cubiertas que compartas",
      de: "Andere Personen in dieser Studiengruppe können die von Ihnen geteilten Decks einsehen",
      ja: "このスタディグループの他の参加者は、あなたが共有したデッキをレビューすることができます。",
      "pt-br":
        "Outras pessoas neste Grupo de Estudo poderão revisar os decks que você compartilhar.",
      fr: "Les autres membres de ce groupe d'étude pourront consulter les fichiers que vous partagez.",
      "zh-cn": "此学习小组中的其他人将能够复习您共享的暗记包。",
      "zh-tw": "此學習小組中的其他人將能夠復習您分享的暗記包。",
    },
    emails: {
      en: "Emails",
      de: "E-Mails",
      fr: "Emails",
      "pt-br": "Emails",
      "zh-cn": "电子邮件",
      "zh-tw": "電子郵件",
      es: "Correos electrónicos",
      ja: "電子メール",
    },
    studyGroupsDescription: {
      en: "Study Groups let you easily share decks with many people at once.",
      de: "Mit Studiengruppen können Sie problemlos Decks mit mehreren Personen gleichzeitig teilen.",
      fr: "Les groupes d'étude vous permettent de partager facilement des jeux avec plusieurs personnes à la fois.",
      "zh-cn": "用学习小组可以方便分享暗记包并跟踪朋友的进度。",
      "zh-tw": "用學習小組可以方便分享暗記包並跟蹤朋友的進度。",
      es: "Los grupos de estudio te permiten compartir fácilmente los mazos con muchas personas a la vez.",
      ja: "勉強会では、一度に多くの人と簡単にデッキを共有できます。",
      "pt-br": "Grupos de Estudo permitem compartilhar decks com muitas pessoas de uma vez.",
    },
    addStudyGroup: {
      en: "Add a Study Group",
      de: "Eine Studiengruppe hinzufügen",
      fr: "Ajouter un groupe d'étude",
      "pt-br": "Adicionar um grupo de estudo",
      "zh-cn": "添加一个学习小组",
      "zh-tw": "添加一個學習小組",
      es: "Añadir un grupo de estudio",
      ja: "研究会の追加",
    },
  },
  groups: {
    groupNameInstruction: {
      en: "Pick a descriptive name for your Study Group.",
      ja: "研究会の名前を決めてください。",
      fr: "Choisissez un nom descriptif pour votre groupe d'étude.",
      de: "Wählen Sie einen aussagekräftigen Namen für Ihre Studiengruppe.",
      es: "Elija un nombre descriptivo para su Grupo de estudio.",
      "pt-br": "Escolha um nome descritivo para o seu Grupo de Estudo.",
      "zh-cn": "给你的学习小组选一个描述性的名字。",
      "zh-tw": "為您的學習小組選擇一個描述性的名稱。",
    },
    namePromptInstruction: {
      en: "Set a name to identify yourself in Study Groups.",
      de: "Wählen Sie einen Namen, um sich in Studiengruppen zu identifizieren.",
      es: "Establezca un nombre para identificarse en los Grupos de Estudio.",
      fr: "Définissez un nom pour vous identifier dans les groupes d'étude.",
      "pt-br": "Defina um nome para se identificar nos Grupos de Estudo.",
      ja: "Study Groupで自分を識別するための名前を設定します。",
      "zh-cn": "设置一个名称以在学习小组中表明您的身份。",
      "zh-tw": "設置一個名稱以在學習小組中表明您的身份。",
    },
    cancelPendingRequestFailed: {
      en: "Failed to cancel pending request.",
      de: "Anhängige Anfrage konnte nicht abgebrochen werden.",
      es: "No se ha podido cancelar la solicitud pendiente.",
      fr: "Échec de l'annulation de la demande en attente.",
      "pt-br": "Falha em cancelar a solicitação pendente.",
      ja: "保留中のリクエストのキャンセルに失敗しました。",
      "zh-cn": "拒绝请求失败",
      "zh-tw": "拒絕請求失敗",
    },
    pendingRequests: {
      en: "Pending Requests",
      de: "Schwebende Anfragen",
      es: "Solicitudes Pendientes",
      fr: "Demandes en Cours",
      "pt-br": "Solicitações pendentes",
      ja: "保留中のリクエスト",
      "zh-cn": "加入学习小组请求",
      "zh-tw": "加入學習小組請求",
    },
    joinRequest: {
      en: "Join Request",
      de: "Beitrittsanfrage",
      es: "Solicitud de adhesión",
      fr: "Demande d'adhésion",
      "pt-br": "Solicitação de participação",
      ja: "参加申し込み",
      "zh-cn": "添加一个学习小组",
      "zh-tw": "添加一個學習小組",
    },
    joinRequestAcceptFailed: {
      en: "Failed to accept join request.",
      de: "Beitrittsanfrage konnte nicht akzeptiert werden.",
      es: "No se ha podido aceptar la solicitud de unión.",
      fr: "Impossible d'accepter la demande d'adhésion.",
      "pt-br": "Falha em aceitar a solicitação de participação.",
      ja: "参加要求の受け入れに失敗しました。",
      "zh-cn": "加入学习小组请求失败",
      "zh-tw": "加入學習小組請求失敗",
    },
    joinRequestRejectFailed: {
      en: "Failed to reject join request.",
      de: "Beitrittsanfrage konnte nicht abgelehnt werden.",
      es: "No se ha podido rechazar la solicitud de unión.",
      fr: "Échec du rejet de la demande d'adhésion.",
      "pt-br": "Falha em rejeitar a solicitação de participação.",
      ja: "参加要求の拒否に失敗しました。",
      "zh-cn": "拒绝学习小组请求失败",
      "zh-tw": "拒絕學習小組請求失敗",
    },
    joinRequestTokenInvalid: {
      en: "Group join token invalid. Try contacting the group admin.",
      de: "Gruppenbeitrittstoken ungültig. Versuchen Sie, den Gruppenadministrator zu kontaktieren.",
      es: "El token de unión al grupo no es válido. Intenta contactar con el administrador del grupo.",
      fr: "Le jeton d'adhésion au groupe n'est pas valide. Essayez de contacter l'administrateur du groupe.",
      "pt-br":
        "Token para ingressar no grupo inválido . Tente entrar em contato com o administrador do grupo.",
      ja: "グループ参加トークンが無効です。グループ管理者に連絡してみてください。",
      "zh-cn": "加入学习小组代码无效。 尝试联系学习小组管理员。",
      "zh-tw": "加入小組代碼無效。 嘗試聯繫學習小組管理員。",
    },
    leaveGroup: {
      en: "Leave Group",
      de: "Gruppe verlassen",
      fr: "Quitter le groupe",
      "pt-br": "Sair do Grupo",
      "zh-cn": "离开小组",
      "zh-tw": "離開小組",
      es: "Dejar el grupo",
      ja: "離脱グループ",
    },
    confirmRemoveDeck: {
      en: "Remove deck from Study Group? Group members will no longer be able to download the deck.",
      de: "Soll das Deck aus der Studiengruppe entfernt werden? Gruppenmitglieder können das Deck dann nicht mehr herunterladen.",
      es: "¿Quitar la baraja del Grupo de Estudio? Los miembros del grupo ya no podrán descargar el mazo.",
      fr: "Supprimer le jeu de cartes du groupe d'étude ? Les membres du groupe ne pourront plus télécharger le jeu de cartes.",
      ja: "スタディグループからデッキを削除しますか？グループのメンバーはデッキをダウンロードすることができなくなります。",
      "zh-cn": "从学习小组移除暗记包？小组组成员将无法再下载该暗记包。",
      "zh-tw": "从學習小組移除暗記包？小組組成員將無法再下載該暗記包。",
      "pt-br":
        "Remover deck do Grupo de Estudo? Os membros do grupo não poderão mais baixar o deck.",
    },
    studyGroup: {
      en: "Study Group",
      de: "Studiengruppe",
      es: "Grupo de estudio",
      fr: "Groupe d'étude",
      "pt-br": "Grupo de Estudo",
      "zh-cn": "学习小组",
      "zh-tw": "學習小組",
      ja: "研究会",
    },
    studyGroups: {
      en: "Study Groups",
      de: "Studiengruppen",
      fr: "Groupes d'étude",
      "pt-br": "Grupos de Estudo",
      "zh-cn": "学习小组",
      "zh-tw": "學習小組",
      es: "Grupos de estudio",
      ja: "研究会",
    },
    leaderboard: {
      en: "Leaderboard",
      de: "Bestenliste",
      es: "Tabla de clasificación",
      fr: "Tableau des leaders",
      "pt-br": "Placar de líderes",
      "zh-cn": "排行榜",
      "zh-tw": "排行榜",
      ja: "リーダーボード",
    },
    new: {
      en: "New Study Group",
      de: "Neue Studiengruppe",
      es: "Nuevo grupo de estudio",
      fr: "Nouveau groupe d'étude",
      ja: "新しい研究会",
      "pt-br": "Novo Grupo de Estudo",
      "zh-cn": "创建新学习小组",
      "zh-tw": "創建新學習小組",
    },
    defaultName: {
      en: "My Study Group",
      de: "Meine Studiengruppe",
      fr: "Mon groupe d'étude",
      "pt-br": "Meu Grupo de Estudo",
      "zh-cn": "我的学习小组",
      "zh-tw": "我的學習小組",
      es: "Mi grupo de estudio",
      ja: "私の勉強会",
    },
    edit: {
      en: "Edit Group",
      de: "Gruppe bearbeiten",
      fr: "Éditer le groupe",
      "pt-br": "Editar Grupo",
      "zh-cn": "编辑学习小组",
      "zh-tw": "編輯學習小組",
      es: "Editar Grupo",
      ja: "グループを変える",
    },
    invite: {
      en: "Invite Members",
      de: "Mitglieder einladen",
      es: "Invitar a los miembros",
      fr: "Inviter les membres",
      "pt-br": "Convidar membros",
      "zh-cn": "邀请成员",
      "zh-tw": "邀請成員",
      ja: "会員を招待する",
    },
    delete: {
      en: "Delete Group",
      de: "Gruppe löschen",
      fr: "Supprimer le groupe",
      "pt-br": "Excluir Grupo",
      "zh-cn": "删除学习小组",
      "zh-tw": "刪除學習小組",
      es: "Borrar Grupo",
      ja: "グループ削除",
    },
    confirmDelete: {
      en: "Delete this group?",
      de: "Diese Gruppe löschen?",
      fr: "Supprimer ce groupe ?",
      "pt-br": "Excluir este grupo?",
      "zh-cn": "删除此学习小组？",
      "zh-tw": "刪除此學習小組？",
      es: "¿Borrar este grupo?",
      ja: "このグループを削除しますか？",
    },
    members: {
      en: "Members",
      de: "Mitglieder",
      fr: "Membres",
      "pt-br": "Membros",
      "zh-cn": "成员",
      "zh-tw": "成員",
      es: "Miembros",
      ja: "メンバー紹介",
    },
    noOtherMembers: {
      en: "Use the share link to invite people to join this Study Group.",
      de: "Nutzen Sie den Freigabelink, um Personen zur Teilnahme an dieser Studiengruppe einzuladen.",
      es: "Utilice el enlace de compartir para invitar a la gente a unirse a este Grupo de Estudio.",
      fr: "Utilisez le lien de partage pour inviter des personnes à rejoindre ce groupe d'étude.",
      ja: "共有リンクを使って、この研究会に参加する人を招待してください。",
      "pt-br":
        "Use o link compartilhado para convidar as pessoas a participarem deste Grupo de Estudo.",
      "zh-cn": "使用分享链接邀请人们加入这个学习小组。",
      "zh-tw": "使用分享鏈接邀請人們加入這個學習小組。",
    },
    invited: {
      en: "Invited",
      de: "Eingeladen",
      fr: "Invité",
      "pt-br": "Convidado",
      "zh-cn": "邀请",
      "zh-tw": "邀請",
      es: "Invitado",
      ja: "招待された",
    },
    requests: {
      en: "Requests",
      de: "Anfragen",
      es: "Solicita",
      fr: "Demandes",
      "pt-br": "Solicitações",
      ja: "要望事項",
      "zh-cn": "加入学习小组请求",
      "zh-tw": "加入學習小組請求",
    },
    last24hr: {
      en: "Last 24 hours",
      de: "Letzte 24 Stunden",
      fr: "Dernières 24 heures",
      "pt-br": "Últimas 24 horas",
      "zh-cn": "近24小时",
      "zh-tw": "近24小時",
      es: "Últimas 24 horas",
      ja: "過去24時間",
    },
    last7d: {
      en: "Last 7 days",
      de: "Letzte 7 Tage",
      fr: "7 derniers jours",
      "pt-br": "Últimos 7 dias",
      "zh-cn": "近7天",
      "zh-tw": "近7天",
      es: "Últimos 7 días",
      ja: "直近7日間",
    },
    me: {
      en: "Me",
      de: "Ich",
      fr: "Moi",
      "pt-br": "Eu",
      "zh-cn": "我",
      "zh-tw": "我",
      es: "Yo",
      ja: "私",
    },
    emptyMessage: {
      en: "Add a Study Group to share decks and study progress with other people.",
      de: "Fügen Sie eine Studiengruppe hinzu, um Decks und Lernfortschritte mit anderen zu teilen.",
      es: "Añade un Grupo de Estudio para compartir los mazos y el progreso del estudio con otras personas.",
      fr: "Ajoutez un groupe d'étude pour partager des jeux et la progression de l'étude avec d'autres personnes.",
      "zh-cn": "添加一个学习小组，与其他人分享暗记包和学习进度。",
      "zh-tw": "添加一個學習小組，與其他人分享暗記包和學習進度。",
      ja: "スタディグループを追加して、デッキや勉強の進捗状況を他の人と共有することができます。",
      "pt-br":
        "Adicione um Grupo de Estudo para compartilhar decks e progresso de estudo com outras pessoas.",
    },
    activity: {
      en: "Activity",
      de: "Aktivität",
      fr: "Activité",
      "pt-br": "Atividade",
      "zh-cn": "活动",
      "zh-tw": "活動",
      es: "Actividad",
      ja: "活動内容",
    },
  },
  profile: {
    licenses: {
      en: "Licenses",
      fr: "Licences",
      es: "Licencias",
      "pt-br": "Licenças",
      ja: "ライセンス",
      de: "Lizenzen",
      "zh-cn": "许可证",
      "zh-tw": "許可證",
    },
    currentProduct: {
      en: "Current Plan",
      de: "Aktuelles Produkt",
      es: "Suscripción actual",
      fr: "Abonnement actuel",
      "pt-br": "Assinatura atual",
      "zh-cn": "当前订阅",
      "zh-tw": "當前訂閱",
      ja: "現行計画",
    },
  },
  errors: {
    photoPermissionDenied: {
      en: "Access denied. Grant AnkiApp permission to access photos and/or camera, and try again.",
      ja: "アクセスが拒否されました。AnkiAppに写真やカメラへのアクセス許可を与えて、再度お試しください。",
      "pt-br":
        "Acesso negado. Conceda permissão ao AnkiApp para acessar fotos e/ou câmera, e tente novamente.",
      es: "Acceso denegado. Concede a AnkiApp permiso para acceder a las fotos y/o a la cámara e inténtalo de nuevo.",
      fr: "Accès refusé. Accordez à AnkiApp la permission d'accéder aux photos et/ou à l'appareil photo, et réessayez.",
      de: "Zugriff verweigert. Erteile AnkiApp die Erlaubnis, auf Fotos und/oder die Kamera zuzugreifen, und versuche es erneut.",
      "zh-cn": "访问被拒绝。请授予AnkiApp访问照片和/或相机的权限，然后重试。",
      "zh-tw": "拒絕訪問。請授予 AnkiApp 訪問照片和/或相機的權限，然後重試。",
    },
    recordingResponse: {
      en: "Error recording response. Developers have been notified.",
      "pt-br": "Erro ao registrar resposta. Os desenvolvedores foram notificados.",
      de: "Antwort der Fehleraufzeichnung. Die Entwickler wurden benachrichtigt.",
      es: "Respuesta del registro de errores. Los desarrolladores han sido notificados.",
      fr: "Erreur d'enregistrement de la réponse. Les développeurs ont été informés.",
      ja: "エラー記録対応。開発者に通知しました。",
      "zh-cn": "记录响应时出错。开发者已收到通知。",
      "zh-tw": "記錄回應時出錯。開發人員已被通知。",
    },
    failedToParseTeX: {
      en: "Failed to parse TeX.",
      de: "TeX konnte nicht geparst werden.",
      fr: "Échec de l'analyse de TeX.",
      es: "Error al analizar TeX.",
      ja: "TeXのパースに失敗しました。",
      "pt-br": "Falha ao processar TeX.",
      "zh-tw": "無法解析 TeX。",
      "zh-cn": "解析 TeX 失败。",
    },
    failedToOpenUrl: {
      en: "Failed to open URL",
      de: "URL konnte nicht geöffnet werden.",
      es: "Fallo al abrir la URL",
      fr: "Impossible d'ouvrir l'URL",
      "pt-br": "Falha em abrir a URL",
      ja: "URLを開くのに失敗しました",
      "zh-cn": "打开网址失败。",
      "zh-tw": "打開網址失敗。",
    },
    failedToLoadImage: {
      en: "Failed to load image. Card may not save properly.",
      de: "Bild konnte nicht geladen werden. Karte könnte möglicherweise nicht korrekt gespeichert werden.",
      es: "No se ha podido cargar la imagen. Es posible que la tarjeta no se guarde correctamente.",
      fr: "Échec du chargement de l'image. La carte peut ne pas être enregistrée correctement.",
      "pt-br": "Falha no carregamento da imagem. O cartão pode não ser salvo corretamente.",
      ja: "画像の読み込みに失敗しました。カードが正しく保存されていない可能性があります。",
      "zh-cn": "加载图片失败。 卡片可能无法正确保存。",
      "zh-tw": "加載圖片失敗。 卡片可能無法正確保存。",
    },
    failedToSaveFile: {
      en: "Failed to save file.",
      de: "Datei konnte nicht gespeichert werden.",
      es: "No se ha podido guardar el archivo.",
      fr: "Échec de l'enregistrement du fichier.",
      ja: "ファイルの保存に失敗しました。",
      "pt-br": "Falha ao salvar o arquivo.",
      "zh-tw": "無法儲存檔案。",
      "zh-cn": "文件保存失败。",
    },
  },
  unlimited: {},
  contact: {
    bug: {
      en: "Encountered a bug",
      de: "Auf einen Fehler gestoßen",
      fr: "J'ai rencontré un bug",
      "pt-br": "Encontrou um bug",
      "zh-cn": "遇到一个bug",
      "zh-tw": "遇到一個bug",
      es: "Se ha encontrado un error",
      ja: "バグの発生",
    },
    suggestion: {
      en: "Feature request or suggestion",
      de: "Feature-Anfrage oder Vorschlag",
      fr: "Demande ou suggestion de fonctionnalité",
      "pt-br": "Pedido de recurso ou sugestão",
      "zh-cn": "功能请求或建议",
      "zh-tw": "功能請求或建議",
      es: "Petición o sugerencia de características",
      ja: "機能に関するご要望・ご提案",
    },
    unlimited: {
      en: "Unlimited",
      de: "Unbegrenzt",
      es: "Unlimited",
      fr: "Unlimited",
      "pt-br": "Ilimitada",
      "zh-cn": "无限版",
      "zh-tw": "無限版",
      ja: "アンリミテッド",
    },
    reason: {
      en: "Reason for contacting",
      de: "Grund für die Kontaktaufnahme",
      fr: "Motif de la prise de contact",
      "pt-br": "Motivo do contato",
      "zh-cn": "联系原因",
      "zh-tw": "聯繫原因",
      es: "Motivo de contacto",
      ja: "お問い合わせの理由",
    },
  },
  import: {
    importFromText: {
      en: "Import from text",
      ja: "テキストからのインポート",
      de: "Aus Text importieren",
      es: "Importar desde texto",
      fr: "Importation d'un texte",
      "pt-br": "Importar de texto",
      "zh-cn": "从文本导入",
      "zh-tw": "從文本導入",
    },
    fmlInstructions: {
      en: "Import from an AnkiApp deck file (.zip or .xml).",
      de: "Import aus einer AnkiApp Deckdatei (.zip oder .xml).",
      ja: "AnkiAppデッキファイル（.zipまたは.xml）からインポートします。",
      es: "Importar desde un archivo de baraja de AnkiApp (.zip o .xml).",
      "pt-br": "Importar de um arquivo de deck do AnkiApp (.zip ou .xml).",
      fr: "Importation à partir d'un fichier AnkiApp (.zip ou .xml).",
      "zh-cn": "从 AnkiApp 暗记包文件 (.zip 或 .xml) 导入。",
      "zh-tw": "從 AnkiApp 暗記包檔案 (.zip 或 .xml) 匯入。",
    },
    apkgHelp1: {
      de: "Auf dem Hauptbildschirm der Deckliste in Anki SRS:",
      en: "From the main deck list screen in Anki SRS:",
      fr: "À partir de l'écran principal de la liste des jeux dans Anki SRS :",
      "zh-cn": "在Anki SRS 的主页面：",
      "zh-tw": "在Anki SRS 的主頁面：",
      es: "Desde la pantalla principal de la lista de mazos en Anki SRS:",
      ja: "Anki SRSのメインデッキリスト画面より。",
      "pt-br": "Da tela principal da lista de decks no Anki SRS:",
    },
    apkgHelp2: {
      de: "Im Exportdialog:",
      en: "From the export dialog:",
      fr: "Dans la boîte de dialogue d'exportation :",
      "pt-br": "A partir do diálogo de exportação:",
      "zh-cn": "导出页面：",
      "zh-tw": "導出頁面：",
      es: "En el diálogo de exportación:",
      ja: "エクスポートダイアログから",
    },
    apkgHelp3: {
      de: "Das exportierte Deckpaket (.apkg) kann dann zur Verwendung in AnkiApp importiert werden.",
      en: "The exported deck package (.apkg) can then be imported for use in AnkiApp.",
      fr: "Le paquet de jeux exporté (.apkg) peut ensuite être importé pour être utilisé dans AnkiApp.",
      "zh-cn": "然后，道出的APKG可以在暗记App导入。",
      "zh-tw": "然後，導出的APKG可以在暗記App導入。",
      es: "El paquete de mazos exportado (.apkg) puede ser importado para su uso en AnkiApp.",
      ja: "エクスポートされたデッキパッケージ（.apkg）は、AnkiAppにインポートして使用することができます。",
      "pt-br": "O pacote do deck exportado (.apkg) pode então ser importado para uso no AnkiApp.",
    },
    apkgInstructions1: {
      de: "Um loszulegen, benötigen Sie eine APKG-Datei (.apkg).",
      en: "To get started, you'll need an APKG file (.apkg).",
      fr: "Pour commencer, vous aurez besoin d'un fichier APKG (.apkg).",
      "pt-br": "Para começar, você precisará de um arquivo APKG (.apkg).",
      "zh-cn": "首先，您需要一个APKG文件（.apkg）。",
      "zh-tw": "首先，您需要一個APKG文件（.apkg）。",
      es: "Para empezar, necesitarás un archivo APKG (.apkg).",
      ja: "はじめに、APKGファイル（.apkg）が必要です。",
    },
    incorrectFileTypeAPKG: {
      de: "Falscher Dateityp. Bitte stellen Sie sicher, dass Sie eine .apkg- oder .anki2-Datei auswählen.",
      en: "Incorrect file type. Please make sure you select an .apkg or .anki2 file.",
      fr: "Le type de fichier est incorrect. Assurez-vous d'avoir sélectionné un fichier .apkg ou .anki2.",
      "pt-br": "Tipo de arquivo incorreto. Certifique-se de selecionar um arquivo .apkg ou .anki2.",
      "zh-cn": "文件不正确。请确认您选择.apkg或.anki2文件。",
      "zh-tw": "檔類不正確。請確認您選擇.apkg或.anki2檔。",
      es: "Tipo de archivo incorrecto. Asegúrese de seleccionar un archivo .apkg o .anki2.",
      ja: "ファイルの種類が正しくありません。.apkgまたは.anki2ファイルを選択していることを確認してください。",
    },
    uploadedProcessingAPKG: {
      en: "Import has been uploaded and is processing. An email will be sent to you in 1-15 minutes with the status of the import process. On success, the deck will show up in your 'All Decks' list.",
      de: 'Der Import wurde hochgeladen und wird verarbeitet. In 1-15 Minuten erhalten Sie eine E-Mail mit dem Status des Importvorgangs. Bei Erfolg wird das Deck in Ihrer Liste "Alle Decks" angezeigt.',
      es: 'La importación se ha cargado y se está procesando. Se le enviará un correo electrónico en 1-15 minutos con el estado del proceso de importación. Si la importación se realiza con éxito, el mazo aparecerá en su lista de "Todos los mazos".',
      fr: "L'importation a été téléchargée et est en cours de traitement. Un courriel vous sera envoyé dans 1 à 15 minutes avec l'état du processus d'importation. En cas de succès, le jeu apparaîtra dans votre liste \"Tous les jeux\".",
      ja: "インポートがアップロードされ、処理中です。1～15分後にインポート処理の状況を示すメールが送信されます。成功すると、デッキは「すべてのデッキ」リストに表示されます。",
      "zh-cn":
        "导入已上传并正在处理中。 将在 1-15 分钟内向您发送一封电子邮件，其中包含导入过程的状态。 成功后，该套暗记包将显示在您的“所有暗记包”列表中。",
      "zh-tw":
        "導入已上傳並正在處理中。 將在 1-15 分鐘內向您發送一封電子郵件，其中包含導入過程的狀態。 成功後，該套暗記包將顯示在您的“所有暗記包”列表中。",
      "pt-br":
        "A importação foi carregada e está em processamento. Um e-mail será enviado para você em 1-15 minutos com o status do processo de importação. Em caso de sucesso, o deck aparecerá na sua lista de 'Todos os Decks'.",
    },
    errorAPKG: {
      de: "Oje! Etwas ist schief gelaufen. Wenden Sie sich an support@ankiapp.com, wenn Sie Hilfe benötigen. Achten Sie darauf, dass Sie die apkg-Datei(en), die Sie importieren möchten, mit angeben.",
      en: "Uh oh! Something went wrong. Contact support@ankiapp.com if you need help. Be sure to include the apkg file(s) you wish to import.",
      fr: "Uh oh ! Quelque chose s'est mal passé. Contactez support@ankiapp.com si vous avez besoin d'aide. Assurez-vous d'inclure le(s) fichier(s) apkg que vous souhaitez importer.",
      "pt-br":
        "Eita! Alguma coisa deu errado. Contate support@ankiapp.com se você precisar de ajuda. Certifique-se de incluir o(s) arquivo(s) apkg que você deseja importar.",
      "zh-cn": "哦哦！上传文件失败。请联系 support@ankiapp.com。请附上您想要导入的 apkg 文件。",
      "zh-tw": "哦哦！上傳文件失敗。請聯繫 support@ankiapp.com。請附上您想要導入的 apkg 檔。",
      es: "¡Uh oh! Algo ha salido mal. Contacta con support@ankiapp.com si necesitas ayuda. Asegúrate de incluir los archivos apkg que deseas importar.",
      ja: "あ、あれ？何か問題が発生しました。何かあったら support@ankiapp.com に連絡してください。インポートしたいapkgファイルを必ず入れてください。",
    },
    needHelp: {
      de: "Brauchen Sie Hilfe?",
      en: "Need Help?",
      fr: "Besoin d'aide ?",
      "pt-br": "Precisa de ajuda?",
      "zh-cn": "需要帮助？",
      "zh-tw": "需要説明？",
      es: "¿Necesita ayuda?",
      ja: "お困りですか？",
    },
    incorrectFileTypeSpreadsheet: {
      de: "Falscher Dateityp. Bitte stellen Sie sicher, dass Sie eine CSV- oder ZIP-Datei auswählen.",
      en: "Incorrect file type. Please make sure you select a CSV or ZIP file.",
      fr: "Le type de fichier est incorrect. Veuillez vous assurer que vous sélectionnez un fichier CSV ou ZIP.",
      "pt-br":
        "Tipo de arquivo incorreto. Por favor, certifique-se de selecionar um arquivo CSV ou ZIP.",
      "zh-cn": "文件类型不正确。请确保选择CSV或ZIP文件。",
      "zh-tw": "檔類型不正確。請確保選擇CSV或ZIP檔。",
      es: "Tipo de archivo incorrecto. Asegúrese de seleccionar un archivo CSV o ZIP.",
      ja: "ファイル形式が正しくありません。CSVファイルまたはZIPファイルを選択してください。",
    },
    invalidEncodingSpreadsheet: {
      de: "Tabellenkalkulationsdatei hat ungültige Kodierung; bitte stellen Sie sicher, dass die Datei UTF-8 ist",
      en: "Spreadsheet file has invalid encoding; please make sure file is UTF-8",
      fr: "Le fichier de la feuille de calcul a un encodage invalide ; veuillez vous assurer que le fichier est UTF-8.",
      "pt-br":
        "O arquivo de planilha tem codificação inválida; por favor, certifique-se de que o arquivo seja UTF-8",
      "zh-cn": "电子表格文件具有无效编码：请确保文件是UTF-8",
      "zh-tw": "試算表格檔有無效編碼：請確保檔是UTF-8",
      es: "El archivo de la hoja de cálculo tiene una codificación no válida; asegúrese de que el archivo es UTF-8",
      ja: "スプレッドシートファイルのエンコーディングが無効です。ファイルがUTF-8であることを確認してください。",
    },
    success: {
      de: "Erfolgreich importieren!",
      en: "Import success!",
      fr: "Succès d'importation !",
      "pt-br": "Importação bem-sucedida!",
      "zh-cn": "导入成功！",
      "zh-tw": "導入成功！",
      es: "Éxito de la importación.",
      ja: "輸入成功!",
    },
    errorAdditionalDetails: {
      de: "Zusätzliche Details",
      en: "Additional details",
      fr: "Détails supplémentaires",
      "pt-br": "Mais detalhes",
      "zh-cn": "其他详细信息",
      "zh-tw": "其他詳細資訊",
      es: "Detalles adicionales",
      ja: "詳細",
    },
    errorSpreadsheet: {
      de: "Oje! Es könnte etwas schief gegangen sein. Geben Sie dem Importeur etwas Zeit zur Bearbeitung und kontaktieren Sie uns unter support@ankiapp.com, wenn die Decks nicht auftauchen. Wir helfen Ihnen gerne weiter.",
      en: "Uh oh! Something may have gone wrong. Give the importer some time to process then contact us at support@ankiapp.com if the decks do not show up. We'll be happy to help.",
      fr: "Uh oh ! Quelque chose a peut-être mal tourné. Laissez à l'importateur le temps de traiter le dossier, puis contactez-nous à l'adresse support@ankiapp.com si les platines n'apparaissent pas. Nous serons heureux de vous aider.",
      "zh-cn": "哦哦！可能出问题了。如卡片组没导入成功，请联系我们。",
      "zh-tw": "哦哦！可能出問題了。如卡片組沒導入成功，請聯繫我們。",
      es: "¡Uh oh! Algo puede haber salido mal. Dale al importador algo de tiempo para procesar y luego ponte en contacto con nosotros en support@ankiapp.com si las cubiertas no aparecen. Estaremos encantados de ayudarte.",
      ja: "あれ？何か問題が発生したようです。輸入業者の処理に時間がかかり、デッキが表示されない場合は、support@ankiapp.com までご連絡ください。喜んでお手伝いさせていただきます。",
      "pt-br":
        "Oh não! Algo pode ter dado errado. Dê algum tempo para o importador processar e, se os decks não aparecerem, entre em contato conosco em support@ankiapp.com. Ficaremos felizes em ajudar.",
    },
    chooseFile: {
      de: "Datei auswählen",
      en: "Choose File",
      fr: "Choisir le fichier",
      "pt-br": "Escolher arquivo",
      "zh-cn": "选择文件",
      "zh-tw": "選擇檔案",
      es: "Elija el archivo",
      ja: "ファイルを選択",
    },
    beginImport: {
      de: "Beginn der Einfuhr",
      en: "Begin Import",
      fr: "Commencer l'importation",
      "pt-br": "Importar",
      "zh-cn": "开始导入",
      "zh-tw": "開始導入",
      es: "Iniciar la importación",
      ja: "インポート開始",
    },
    fileDescriptionSpreadsheet: {
      de: 'Sie können entweder eine Datei mit durch Kommata getrennten Werten (".csv") oder eine Zip-Datei (".zip") hochladen, die das Deck csv und alle einzelnen Bild-/Audiodateien enthält.',
      en: 'You may upload either a comma-separated value file (".csv" extension), or a zip file (".zip" extension) which includes the deck csv and all individual image/audio files.',
      fr: 'Vous pouvez télécharger soit un fichier de valeurs séparées par des virgules (extension ".csv"), soit un fichier zip (extension ".zip") qui comprend le csv du jeu et tous les fichiers image/audio individuels.',
      "zh-cn": "您可选择CSV或ZIP文件。",
      "zh-tw": "您可以選擇CSV或ZIP文件",
      es: 'Puede cargar un archivo de valores separados por comas (extensión ".csv") o un archivo zip (extensión ".zip") que incluya el csv del mazo y todos los archivos de imagen/audio individuales.',
      ja: "アップロードできるファイルは、カンマ区切りのファイル（拡張子「.csv」）か、デッキcsvと個々の画像・音声ファイルを含むzipファイル（拡張子「.zip」）のどちらかです。",
      "pt-br":
        'Você pode enviar tanto um arquivo separado por vírgulas (extensão ".csv") ou um arquivo zip (extensão ".zip") que inclui o csv do deck e todos os arquivos individuais de imagem/áudio.',
    },
    pleaseWait: {
      de: "Bitte warten Sie... die Bearbeitung großer Decks kann eine Weile dauern.",
      en: "Please wait... large decks may take a while to process.",
      fr: "Veuillez patienter... le traitement des gros paquets peut prendre un certain temps.",
      "zh-cn": "请稍候。。。可能需要一段时间来处理。",
      "zh-tw": "請稍候。。。可能需要一段時間來處理。",
      es: "Por favor, espere... los mazos grandes pueden tardar en procesarse.",
      ja: "大型のデッキの場合、処理に時間がかかることがありますので、お待ちください。",
      "pt-br": "Por favor, aguarde... decks grandes podem demorar um pouco para processar.",
    },
    spreadsheetColumnStructure: {
      de: "Säulenstruktur",
      en: "Column Structure",
      fr: "Structure des colonnes",
      "pt-br": "Estrutura da coluna",
      "zh-cn": "柱状结构",
      "zh-tw": "柱狀結構",
      es: "Estructura de la columna",
      ja: "列の構成",
    },
    spreadsheetColumnGeneralInstructions: {
      de: "Die .csv-Datei sollte eine Zeile für jede Karte im Deck und bis zu (aber nicht mehr als) 7 Spalten mit Daten enthalten, die Folgendes darstellen:",
      en: "The .csv file should have a row for each card in the deck, and up to (but not exceeding) 7 columns of data representing the following:",
      fr: "Le fichier .csv doit comporter une ligne pour chaque carte du jeu, et jusqu'à (mais pas plus de) 7 colonnes de données représentant les éléments suivants :",
      "zh-cn": ".csv文件应为暗记包每张卡设置一行，每行包括：",
      "zh-tw": ".csv檔應為暗記包每張卡設置一行，每行包括：",
      es: "El archivo .csv debe tener una fila para cada carta de la baraja, y hasta (pero sin exceder) 7 columnas de datos que representen lo siguiente:",
      ja: ".csvファイルには、デッキの各カードを表す行と、以下を表す最大7列のデータが必要です（ただし、最大7列まで）。",
      "pt-br":
        "O arquivo .csv deve ter uma linha para cada cartão no deck, e até (mas não excedendo) 7 colunas de dados representando o seguinte:",
    },
    csv2ColumnName: {
      en: "Text-only (2 columns)",
      de: "Reiner Text (2 Spalten)",
      fr: "Texte seul (2 colonnes)",
      es: "Sólo texto (2 columnas)",
      ja: "テキストのみ（2列）",
      "pt-br": "Texto (2 colunas)",
      "zh-cn": "仅文本（2列）",
      "zh-tw": "僅文字 (兩欄)",
    },
    csv3ColumnName: {
      en: "Text-only with tags (5 columns)",
      de: "Reiner Text mit Tags (5 Spalten)",
      "pt-br": "Texto somente com tags (5 colunas)",
      fr: "Texte seul avec balises (5 colonnes)",
      ja: "タグ付きテキストのみ（5列）",
      es: "Sólo texto con etiquetas (5 columnas)",
      "zh-tw": "純文字加標籤（5列）",
      "zh-cn": "纯文本带标签（5栏）",
    },
    csv5ColumnName: {
      en: "Text and images (7 columns)",
      es: "Texto e imágenes (7 columnas)",
      de: "Text und Bilder (7 Spalten)",
      ja: "テキストと画像（7カラム）",
      "pt-br": "Texto e imagens (7 colunas)",
      fr: "Texte et images (7 colonnes)",
      "zh-cn": "文本和图片 (7 列)",
      "zh-tw": "文字和圖片 (7列)",
    },
    csv7ColumnName: {
      en: "Text, images, and audio (7 columns)",
      es: "Texto, imágenes y audio (7 columnas)",
      fr: "Texte, images et audio (7 colonnes)",
      ja: "テキスト、画像、音声（7カラム）",
      "pt-br": "Texto, imagens e áudio (7 colunas)",
      de: "Text, Bilder und Audio (7 Spalten)",
      "zh-cn": "文本、图像和音频 (7列)",
      "zh-tw": "文字、圖片和音訊（7 列）",
    },
    spreadsheetColumn1Instruction: {
      de: "Text für die Vorderseite der Karte",
      en: "Text for front of card",
      fr: "Texte pour le recto de la carte",
      "pt-br": "Texto para a frente do cartão",
      "zh-cn": "卡片前面的文本",
      "zh-tw": "卡片前面的文本",
      es: "Texto para el anverso de la tarjeta",
      ja: "カード表面用テキスト",
    },
    spreadsheetColumn2Instruction: {
      de: "Text für die Rückseite der Karte",
      en: "Text for back of card",
      fr: "Texte pour le dos de la carte",
      "pt-br": "Texto para o verso do cartão",
      "zh-cn": "卡片背面的文本",
      "zh-tw": "卡片背面的文本",
      es: "Texto para el reverso de la tarjeta",
      ja: "カード裏面のテキスト",
    },
    spreadsheetColumn3Instruction: {
      de: "(Optional) Liste der Tags, durch Komma getrennt",
      en: "(Optional) List of tags, comma separated",
      fr: "(Facultatif) Liste de balises, séparées par des virgules.",
      "pt-br": "(Opcional) Lista de etiquetas, separadas por vírgula",
      "zh-cn": "（可选）标签列表，逗号分离",
      "zh-tw": "（可選）標籤清單，逗號分離",
      es: "(Opcional) Lista de etiquetas, separadas por comas",
      ja: "(オプション) カンマで区切られたタグのリスト。",
    },
    spreadsheetColumn4Instruction: {
      de: "(Optional) Dateiname für das Bild auf der Vorderseite der Karte",
      en: "(Optional) Filename for image on front of card",
      fr: "(Facultatif) Nom de fichier pour l'image sur le recto de la carte",
      "pt-br": "(Opcional) Nome do arquivo para a imagem na frente do cartão",
      "zh-cn": "（可选）卡片前图像的文件名",
      "zh-tw": "（可選）卡片前圖像的檔名",
      es: "(Opcional) Nombre del archivo para la imagen del anverso de la tarjeta",
      ja: "(オプション) カード表面の画像のファイル名",
    },
    spreadsheetColumn5Instruction: {
      de: "(Optional) Dateiname für das Bild auf der Rückseite der Karte",
      en: "(Optional) Filename for image on back of card",
      fr: "(Facultatif) Nom de fichier pour l'image au dos de la carte",
      "pt-br": "(Opcional) Nome do arquivo para a imagem no verso do cartão",
      "zh-cn": "（可选）卡片背面图像的文件名",
      "zh-tw": "（可選）卡片背面圖像的檔名",
      es: "(Opcional) Nombre del archivo para la imagen en el reverso de la tarjeta",
      ja: "(オプション) カード裏面の画像用ファイル名",
    },
    spreadsheetColumn6Instruction: {
      de: "(Optional) Dateiname der Audiodatei für die Vorderseite der Karte",
      en: "(Optional) File name of audio file for front of card",
      fr: "(Facultatif) Nom de fichier du fichier audio pour le recto de la carte",
      "pt-br": "(Opcional) Nome do arquivo de áudio para a frente do cartão",
      "zh-cn": "（可选）卡片前音频文件的文件名称",
      "zh-tw": "（可選）卡片前音訊檔的檔名稱",
      es: "(Opcional) Nombre del archivo de audio para el frente de la tarjeta",
      ja: "(オプション) カード前面のオーディオファイルのファイル名",
    },
    spreadsheetColumn7Instruction: {
      de: "(Optional) Dateiname der Audiodatei für die Rückseite der Karte",
      en: "(Optional) File name of audio file for back of card",
      fr: "(Facultatif) Nom de fichier du fichier audio pour le dos de la carte",
      "pt-br": "(Opcional) Nome do arquivo de áudio para o verso do cartão",
      "zh-cn": "（可选）卡背面音频文件的文件名称",
      "zh-tw": "（可選）卡背面音訊檔的檔名稱",
      es: "(Opcional) Nombre del archivo de audio para el reverso de la tarjeta",
      ja: "(オプション) カード裏面のオーディオファイルのファイル名",
    },
  },
  auth: {
    authgateForceLoginCTA: {
      en: "Immediately log out other devices, and proceed with login",
      de: "Andere Geräte sofort abmelden und mit der Anmeldung fortfahren",
      es: "Cierre inmediatamente la sesión de otros dispositivos y proceda al inicio de sesión",
      fr: "Déconnectez immédiatement les autres appareils et procédez à la connexion.",
      "pt-br": "Encerrar a sessão imediatamente nos outros dispositivos, e prosseguir com o login",
      "zh-cn": "立即注销其他设备，然后继续登录",
      "zh-tw": "立即註銷其他設備，然後繼續登錄",
      ja: "すぐに他のデバイスをログアウトし、ログインを続行します。",
    },
    confirmAuthgateForceLogin: {
      en: "Are you sure you want to continue? Doing so will force all your other devices to log out.",
      de: "Sind Sie sicher, dass Sie fortfahren möchten? Durch das Fortfahren werden alle anderen Geräte abgemeldet.",
      es: "¿Estás seguro de que quieres continuar? Si lo haces, obligarás a todos tus otros dispositivos a cerrar la sesión.",
      fr: "Êtes-vous sûr de vouloir continuer ? Ce faisant, vous obligerez tous vos autres appareils à se déconnecter.",
      "pt-br":
        "Você tem certeza de que quer continuar? Isso, forçará todos os seus outros dispositivos encerrarem a sessão.",
      "zh-cn": "你确定要继续吗？所有其他设备将会注销。",
      "zh-tw": "你確定要繼續嗎？所有其他設備將會註銷。",
      ja: "本当にこのままでよいのですか？この操作を行うと、他のすべてのデバイスが強制的にログアウトされます。",
    },
    welcome: {
      de: "Willkommen bei AnkiApp!",
      en: "Welcome to AnkiApp!",
      fr: "Bienvenue sur AnkiApp!",
      "pt-br": "Bem-vindo ao AnkiApp!",
      "zh-cn": "欢迎使用暗记App!",
      "zh-tw": "歡迎使用暗記App!",
      es: "¡Bienvenidos a AnkiApp!",
      ja: "AnkiApp へようこそ!",
    },
    sessionExpired: {
      de: "Ihre AnkiApp-Sitzung auf diesem Gerät ist abgelaufen. Bitte melden Sie sich erneut an.",
      en: "Your AnkiApp session on this device has expired. Please login again.",
      fr: "Votre session AnkiApp sur cet appareil a expiré. Veuillez vous connecter à nouveau.",
      "pt-br": "Sua sessão AnkiApp neste dispositivo expirou. Por favor, faça o login novamente.",
      "zh-cn": "您已注销；请重新登陆.",
      "zh-tw": "您已註銷；請重新登陸.",
      es: "Tu sesión de AnkiApp en este dispositivo ha expirado. Por favor, inicie sesión de nuevo.",
      ja: "このデバイスでのAnkiAppセッションの有効期限が切れました。再度ログインしてください。",
    },
    sessionExpiredAuthgate: {
      en: "You have reached the device limit for your account. You will be automatically logged out. To remain logged in, you must upgrade to AnkiApp Unlimited, which includes the following.",
      de: "Sie haben das Gerätelimit für Ihren Account erreicht. Sie werden automatisch ausgeloggt. Um eingeloggt zu bleiben, müssen Sie ein Upgrade auf AnkiApp Unlimited durchführen, das Folgendes beinhaltet.",
      es: "Has alcanzado el límite de dispositivos de tu cuenta. Se cerrará la sesión automáticamente. Para seguir conectado, debes actualizar a AnkiApp Unlimited, que incluye lo siguiente.",
      fr: "Vous avez atteint la limite d'appareils pour votre compte. Vous serez automatiquement déconnecté. Pour rester connecté, vous devez passer à AnkiApp Unlimited, qui comprend les éléments suivants.",
      "pt-br":
        "Você atingiu o limite de dispositivos para sua conta. Você será automaticamente desconectado do sistema. Para permanecer conectado, você deve atualizar para AnkiApp Ilimitada, que inclui o seguinte.",
      ja: "お客様のアカウントで使用できる端末の制限に達しました。自動的にログアウトされます。ログイン状態を維持するには、以下を含む AnkiApp Unlimited にアップグレードする必要があります。",
      "zh-cn":
        "您已达到帐户的设备限制。 您将被自动注销。 要保持登录状态，您必须升级到 AnkiApp Unlimited。",
      "zh-tw":
        "您已達到帳戶的設備限制。 您將被自動註銷。 要保持登錄狀態，您必須升級到 AnkiApp Unlimited。",
    },
    signupError400: {
      en: "Invalid email or password. Please use a valid email and non-empty password, then try again.",
      de: "Ungültige E-Mail oder Passwort. Bitte verwenden Sie eine gültige E-Mail-Adresse und ein nicht leeres Passwort und versuchen Sie es erneut.",
      es: "Correo electrónico o contraseña no válidos. Por favor, utilice un correo electrónico válido y una contraseña no vacía, y vuelva a intentarlo.",
      fr: "Courriel ou mot de passe invalide. Veuillez utiliser une adresse électronique valide et un mot de passe non vide, puis réessayez.",
      "pt-br":
        "E-mail ou senha inválidos. Por favor, use um e-mail válido e uma senha não vazia, depois tente novamente.",
      "zh-cn": "无效的电子邮件或密码。请使用有效的电子邮件和非空密码，然后重试。",
      "zh-tw": "無效的電子郵件或密碼。請使用有效的電子郵件和非空密碼，然後重試。",
      ja: "電子メールまたはパスワードが無効です。有効なメールアドレスと空でないパスワードを使用して、もう一度やり直してください。",
    },
    error400: {
      de: 'Die Authentifizierung ist fehlgeschlagen. Überprüfen Sie, ob Ihre E-Mail gültig ist, und versuchen Sie es dann erneut. Wenn Sie sich bereits für AnkiApp angemeldet haben oder AnkiApp bereits auf anderen Geräten verwenden, achten Sie darauf, "Einloggen" zu wählen und die korrekten Zugangsdaten zu verwenden.',
      en: 'Authentication failed.<br />Make sure your email is valid, then try again.<br /><br />If you have previously signed up for AnkiApp or already use AnkiApp on other devices, make sure you select "Log In" and use the correct account credentials.',
      fr: "L'authentification a échoué.<br />Vérifiez que votre e-mail est valide, puis réessayez.<br /><br />Si vous vous êtes déjà inscrit à AnkiApp ou si vous utilisez déjà AnkiApp sur d'autres appareils, assurez-vous de sélectionner \"Connexion\" et d'utiliser les informations d'identification correctes du compte.",
      "pt-br":
        'A autenticação falhou.<br />Certifique-se de que seu e-mail é válido, depois tente novamente.<br /><br />Se você já tinha uma conta na AnkiApp ou já usa a AnkiApp em outros dispositivos, certifique-se de selecionar "Login" e use as credenciais corretas da conta.',
      "zh-cn":
        "验证失败.<br />请查证您输入的邮箱和密码.<br /><br />如果您已有暗记App的帐号，请选择“登陆”选项.",
      "zh-tw":
        "驗證失敗.<br />請查證您輸入的郵箱和密碼.<br /><br />如果您已有暗記App的帳號，請選擇「登陸」選項.",
      es: 'Autenticación fallida.<br />Asegúrate de que tu correo electrónico es válido y vuelve a intentarlo.<br /><br />Si te has registrado previamente en AnkiApp o ya utilizas AnkiApp en otros dispositivos, asegúrate de seleccionar "Iniciar sesión" y de utilizar las credenciales de la cuenta correcta.',
      ja: "認証に失敗しました。<br />メールが有効であることを確認し、再度お試しください。<br /><br />以前にAnkiAppに登録したことがある、またはすでに他のデバイスでAnkiAppを使用している場合、「ログイン」を選択して正しいアカウントの認証情報を使用していることを確認してください。",
    },
    error401: {
      de: "Die Authentifizierung ist fehlgeschlagen. Überprüfen Sie Ihre E-Mail-Adresse und Ihr Passwort und versuchen Sie es dann erneut.",
      en: "Authentication failed.<br />Check your email and password, then try again.",
      fr: "L'authentification a échoué.<br />Vérifiez votre email et votre mot de passe, puis réessayez.",
      "pt-br":
        "A autenticação falhou.<br />Verifique o seu e-mail e senha, depois tente novamente.",
      "zh-cn": "验证失败. 请查证您输入的邮箱和密码.",
      "zh-tw": "驗證失敗. 請查證您輸入的郵箱和密碼.",
      es: "La autenticación ha fallado.<br />Comprueba tu correo electrónico y tu contraseña, e inténtalo de nuevo.",
      ja: "認証に失敗しました。<br />メールとパスワードを確認し、もう一度やり直してください。",
    },
    error404: {
      en: 'No account found with that email address. If this is your first time using AnkiApp, choose "Sign Up" to create an account.',
      de: 'Kein Konto mit dieser E-Mail-Adresse gefunden. Wenn Sie AnkiApp zum ersten Mal verwenden, wählen Sie bitte "Registrieren", um ein Konto zu erstellen.',
      es: 'No se ha encontrado ninguna cuenta con esa dirección de correo electrónico. Si es la primera vez que usas AnkiApp, elige "Registrarse" para crear una cuenta.',
      fr: "Aucun compte n'a été trouvé avec cette adresse e-mail. Si c'est la première fois que vous utilisez AnkiApp, choisissez \"Sign Up\" pour créer un compte.",
      "pt-br":
        'Não foi encontrada nenhuma conta com esse endereço de e-mail. Se esta é sua primeira vez usando a AnkiApp, escolha "Cadastre-se" para criar uma conta.',
      "zh-cn":
        '找不到具有该电子邮件地址的帐户。如果这是您第一次使用AnkiApp，请选择"注册"以创建帐户。',
      "zh-tw":
        '找不到具有該電子郵件地址的帳戶。如果這是您第一次使用AnkiApp，請選擇"註冊"以創建帳戶。',
      ja: "そのメールアドレスのアカウントは見つかりませんでした。初めてAnkiAppを使用する場合は、「サインアップ」を選択してアカウントを作成してください。",
    },
    error409: {
      de: "Das Konto existiert bereits. Möchten Sie sich anmelden, anstatt ein neues Konto zu erstellen? Wenn Sie Ihr Passwort vergessen haben, verwenden Sie bitte die Funktion zum Zurücksetzen des Passworts, um wieder Zugang zu erhalten.",
      en: "Account already exists. Did you mean to log in instead of create a new account? If you forgot your password, or didn't create this account, use the password reset feature to regain access.",
      fr: "Le compte existe déjà. Vouliez-vous vous connecter au lieu de créer un nouveau compte ? Si vous avez oublié votre mot de passe, ou si vous n'avez pas créé ce compte, utilisez la fonction de réinitialisation du mot de passe pour retrouver l'accès.",
      "pt-br":
        "A conta já existe. Você estava tentando fazer o login ao invés de criar uma nova conta? Se você esqueceu sua senha, ou não criou esta conta, use o recurso de redefinir senha para recuperar seu acesso.",
      "zh-cn":
        "帐户已经存在。您是想登录而不是创建新帐户吗？如果您忘记了密码或未创建此帐户，请使用密码重置功能重新获得访问权限。",
      "zh-tw":
        "帳戶已經存在。您是想登錄而不是創建新帳戶嗎？如果您忘記了密碼或未創建此帳戶，請使用密碼重置功能重新獲得訪問許可權。",
      es: "La cuenta ya existe. ¿Querías entrar en tu cuenta en lugar de crear una nueva? Si ha olvidado su contraseña o no ha creado esta cuenta, utilice la función de restablecimiento de contraseña para recuperar el acceso.",
      ja: "アカウントはすでに存在しています。新しいアカウントを作成する代わりに、ログインすることを意図していますか？パスワードを忘れた場合、またはこのアカウントを作成しなかった場合は、パスワードリセット機能を使用してアクセスを回復してください。",
    },
    error502: {
      de: "Die Authentifizierung ist derzeit nicht möglich. Dies ist wahrscheinlich ein temporäres Problem. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Support.",
      en: "Unable to authenticate. This is likely a temporary issue——if the problem persists, please contact support.",
      fr: "Impossible de s'authentifier. Il s'agit probablement d'un problème temporaire. Si le problème persiste, veuillez contacter l'assistance.",
      "pt-br":
        "Não foi possivel autenticar. Provavelmente isso é um problema temporario - se o problema persistir, favor contatar o suporte.",
      "zh-cn": "验证失败. 可能是由于暂时性的服务中断导致；如果此问题仍然存在，请及时联系客服.",
      "zh-tw": "驗證失敗. 可能是由於暫時性的服務中斷導致；如果此問題仍然存在，請及時聯繫客服.",
      es: "No se puede autentificar. Es probable que se trate de un problema temporal; si el problema persiste, póngase en contacto con el servicio de asistencia técnica.",
      ja: "認証ができません。これは一時的な問題である可能性が高いので、問題が解決しない場合はサポートにお問い合わせください。",
    },
    error503: {
      de: "Die Authentifizierung ist derzeit nicht möglich. Dies ist wahrscheinlich ein temporäres Problem. Wenn das Problem weiterhin besteht, kontaktieren Sie bitte den Support.",
      en: "Unable to authenticate. This is likely a temporary issue——if the problem persists, please contact support.",
      fr: "Impossible de s'authentifier. Il s'agit probablement d'un problème temporaire. Si le problème persiste, veuillez contacter l'assistance.",
      "pt-br":
        "Não foi possivel autenticar. Provavelmente isso é um problema temporario - se o problema persistir, favor contatar o suporte.",
      "zh-cn": "验证失败. 可能是由于暂时性的服务中断导致.；如果此问题仍然存在，请及时联系客服.",
      "zh-tw": "驗證失敗. 可能是由於暫時性的服務中斷導致.；如果此問題仍然存在，請及時聯繫客服.",
      es: "No se puede autentificar. Es probable que se trate de un problema temporal; si el problema persiste, póngase en contacto con el servicio de asistencia técnica.",
      ja: "認証ができません。これは一時的な問題である可能性が高いので、問題が解決しない場合はサポートにご連絡ください。",
    },
    error: {
      de: "Die Authentifizierung ist nicht möglich.<br />Überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.<br /><br />Dieser Fehler ist meist auf eine fehlende Netzwerkverbindung zurückzuführen, z. B. bei einem Gerät, das sich im Flugzeugmodus befindet. Wenn Sie mit anderen Anwendungen oder über einen Webbrowser auf das Internet zugreifen können, wenden Sie sich bitte an den Support.",
      en: "Unable to authenticate.<br />Check your network connection and try again.<br /><br />This error is most commonly due to a lack of network connectivity, such as a device that is in airplane mode. If you are able to access the internet using other applications or via a web browser, please contact support.",
      fr: "Impossible de s'authentifier.<br />Vérifiez votre connexion réseau et réessayez.<br /><br />Cette erreur est le plus souvent due à un manque de connectivité réseau, comme un appareil qui est en mode avion. Si vous êtes en mesure d'accéder à Internet à l'aide d'autres applications ou via un navigateur Web, veuillez contacter l'assistance.",
      "pt-br":
        "Não foi possível autenticar.<br />Verifique sua conexão de rede e tente novamente.<br /><br />O que mais causa esse erro é falta de conexão com a rede, como um dispositivo que está no modo avião. Se você for capaz de acessar a Internet usando outros aplicativos ou através de um navegador web, por favor, entre em contato com o suporte.",
      "zh-cn":
        "验证失败.<br />请确认您的网络处于正常状态.<br /><br />如果在连网状态下仍出现此错误，可能是由于暂时性的服务中断导致. 更多详情欢迎访问暗记App官方博客和微博（@暗记app），如果此问题仍然存在，请及时联系客服.",
      "zh-tw":
        "驗證失敗.<br />請確認您的網路處於正常狀態.<br /><br />如果在連網狀態下仍出現此錯誤，可能是由於暫時性的服務中斷導致. 更多詳情歡迎訪問暗記App官方博客和微博（@暗記app），如果此問題仍然存在，請及時聯繫客服.",
      es: "No se puede autenticar.<br />Comprueba tu conexión de red e inténtalo de nuevo.<br /><br />Este error se debe normalmente a una falta de conectividad de red, como un dispositivo que está en modo avión. Si puedes acceder a Internet utilizando otras aplicaciones o a través de un navegador web, ponte en contacto con el servicio de asistencia técnica.",
      ja: "<br /><br />このエラーは、デバイスが機内モードになっているなど、ネットワーク接続ができないことが原因で発生することがほとんどです。他のアプリケーションやウェブブラウザを使用してインターネットにアクセスできる場合は、サポートにお問い合わせください。",
    },
  },
  pushNotifs: {
    permissionRequestMessage: {
      en: "AnkiApp needs your permission to notify you when people request to join your Study Groups.",
      de: "AnkiApp benötigt Ihre Erlaubnis, um Sie zu benachrichtigen, wenn Nutzer darum bitten, Ihren Lerngruppen beizutreten.",
      es: "AnkiApp necesita tu permiso para notificarte cuando la gente solicita unirse a tus Grupos de Estudio.",
      fr: "AnkiApp a besoin de votre permission pour vous avertir lorsque des personnes demandent à rejoindre vos groupes d'étude.",
      ja: "AnkiAppは、あなたの勉強会に参加希望者があった場合に、あなたに通知する許可を必要とします。",
      "pt-br":
        "A AnkiApp precisa de sua permissão para notificá-lo quando as pessoas pedirem para participar dos seus Grupos de Estudo.",
      "zh-cn": "当人们要求加入您的学习小组时，AnkiApp 需要您的许可才能通知您。",
      "zh-tw": "當人們要求加入您的學習小組時，AnkiApp 需要您的許可才能通知您。",
    },
  },
  deck: {
    emptyDeckMessage: {
      en: "To get started, make some flashcards to study.",
      ja: "手始めに、フラッシュカードを作って勉強してみよう。",
      de: "Erstellen Sie für den Anfang einige Lernkarten zum Lernen.",
      fr: "Pour commencer, préparez des flashcards à étudier.",
      "pt-br": "Para começar, crie alguns cartões para estudar.",
      es: "Para empezar, haz algunas fichas para estudiar.",
      "zh-cn": "要开始，制作一些暗记卡来学习。",
      "zh-tw": "要開始，製作一些暗記卡來學習。",
    },
    choose: {
      en: "Choose Deck",
      es: "Elegir Cubierta",
      "pt-br": "Escolher Deck",
      de: "Deck Wählen",
      ja: "デッキを選ぶ",
      fr: "Choisir la Deck",
      "zh-cn": "选择暗记包",
      "zh-tw": "選擇暗記包",
    },
    flipStabilization: {
      en: "Flip Stabilization",
      fr: "Stabilisation du basculement",
      ja: "フリップスタビライゼーション",
      "pt-br": "Estabilização de Virada",
      de: "Flip-Stabilisierung",
      es: "Estabilización Flip",
      "zh-tw": "翻轉穩定",
      "zh-cn": "翻转稳定",
    },
    flipStabilizationDescription: {
      en: "Determines placement of card content when flipping from front to back.",
      de: "Bestimmt die Platzierung des Karteninhalts beim Umdrehen von vorne nach hinten.",
      ja: "カードの内容を表から裏へめくるときの配置を決める。",
      es: "Determina la ubicación del contenido de la tarjeta al voltearla de anverso a reverso.",
      fr: "Détermine l'emplacement du contenu de la carte lorsqu'elle est retournée du recto au verso.",
      "pt-br": "Determina a posição do conteúdo do cartão ao virar da frente para o verso.",
      "zh-cn": "确定暗记卡内容在正面到背面翻转时的位置。",
      "zh-tw": "確定從正面翻到背面時暗記卡內容的位置。",
    },
    flipStabilizationOnDescription: {
      en: "Content on front will remain in same place after flipping to back.",
      es: "El contenido del anverso permanecerá en el mismo lugar después de pasar al reverso.",
      fr: "Le contenu du recto restera au même endroit après avoir été retourné au verso.",
      de: "Der Inhalt auf der Vorderseite bleibt nach dem Umklappen auf die Rückseite an derselben Stelle.",
      "pt-br": "O conteúdo da frente permanecerá no mesmo lugar após virar para o verso.",
      ja: "表のコンテンツは、裏返した後も同じ場所に残ります。",
      "zh-tw": "翻到背面後，正面的內容將保持在同一位置。",
      "zh-cn": "翻转到背面后，正面的内容将保持在同一位置。",
    },
    flipStabilizationOffDescription: {
      en: "Content on front will be initially centered, but will move after flipping to back.",
      "pt-br":
        "O conteúdo na frente estará inicialmente centralizado, mas se moverá após virar para o verso.",
      ja: "前面のコンテンツは最初は中央に表示されるが、背面に反転すると移動する。",
      de: "Der Inhalt auf der Vorderseite ist zunächst zentriert, wird aber nach dem Umdrehen auf die Rückseite verschoben.",
      es: "El contenido del anverso estará centrado inicialmente, pero se moverá al pasar al reverso.",
      fr: "Le contenu du recto sera initialement centré, mais se déplacera après avoir été retourné au verso.",
      "zh-tw": "正面內容初始將居中，但翻到背面後會移動。",
      "zh-cn": "正面的内容最初会居中，但翻转到背面后会移动。",
    },
    resetGrades: {
      en: "Reset Grades",
      de: "Noten zurücksetzen",
      fr: "Réinitialiser les notes",
      es: "Restablecer calificaciones",
      ja: "成績のリセット",
      "pt-br": "Redefinir notas",
      "zh-cn": "重置成绩",
      "zh-tw": "重設成績",
    },
    unusedFieldsDetectedHeader: {
      en: "Unused Fields Detected",
      de: "Unbenutzte Felder entdeckt",
      fr: "Champs non utilisés détectés",
      ja: "未使用フィールドの検出",
      es: "Campos no utilizados detectados",
      "pt-br": "Campos não utilizados detectados",
      "zh-cn": "检测到未使用的字段",
      "zh-tw": "檢測到未使用的欄位",
    },
    unusedFieldsDetectedBody: {
      en: "Fields may have been deleted by accident. Click the fields below to re-add them to the deck config.",
      de: "Felder könnten versehentlich gelöscht worden sein. Klicken Sie die untenstehenden Felder an, um sie wieder zur Deck-Konfiguration hinzuzufügen.",
      fr: "Des champs peuvent avoir été supprimés par accident. Cliquez sur les champs ci-dessous pour les réinsérer dans la configuration du pont.",
      "pt-br":
        "Os campos podem ter sido excluídos por acidente. Clique nos campos abaixo para adicioná-los novamente à configuração do deck.",
      ja: "フィールドが誤って削除された可能性があります。以下のフィールドをクリックして、デッキ設定に再追加してください。",
      es: "Los campos pueden haber sido borrados por accidente. Haga clic en los campos siguientes para volver a añadirlos a la configuración de la cubierta.",
      "zh-cn": "字段可能已意外删除。点击下面的字段将它们重新添加到卡组配置中。",
      "zh-tw": "欄位可能已意外刪除。點擊下面的欄位將它們重新添加到牌組配置中。",
    },
    fieldsOnlyOnFrontHeader: {
      en: "Front Only Content",
      de: "Inhalt nur vorne",
      fr: "Contenu uniquement à l'avant",
      ja: "表のみの内容",
      es: "Contenido solo en el frente",
      "pt-br": "Conteúdo apenas na frente",
      "zh-cn": "仅正面内容",
      "zh-tw": "僅正面內容",
    },
    fieldsOnlyOnFrontBody: {
      en: "Cards only have content assigned to the front. The back side will be blank.",
      de: "Karten haben nur auf der Vorderseite Inhalt. Die Rückseite bleibt leer.",
      fr: "Les cartes n'ont du contenu que sur le devant. L'arrière sera vide.",
      ja: "カードには表にのみ内容が割り当てられています。裏面は空白になります。",
      es: "Las tarjetas solo tienen contenido en el frente. El reverso estará en blanco.",
      "pt-br": "Os cartões têm conteúdo apenas na frente. O verso ficará em branco.",
      "zh-cn": "卡片仅在正面分配内容。背面将为空白。",
      "zh-tw": "卡片僅在正面分配內容。背面將為空白。",
    },
    fieldsOnlyOnBackHeader: {
      en: "Back Only Content",
      de: "Inhalt nur hinten",
      fr: "Contenu uniquement à l'arrière",
      ja: "裏のみの内容",
      es: "Contenido solo en el reverso",
      "pt-br": "Conteúdo apenas no verso",
      "zh-cn": "仅反面内容",
      "zh-tw": "僅反面內容",
    },
    fieldsOnlyOnBackBody: {
      en: "Cards only have content assigned to the back. The front side will be blank.",
      de: "Karten haben nur auf der Rückseite Inhalt. Die Vorderseite bleibt leer.",
      fr: "Les cartes n'ont du contenu que sur l'arrière. L'avant sera vide.",
      ja: "カードには裏にのみ内容が割り当てられています。表面は空白になります。",
      es: "Las tarjetas solo tienen contenido en el reverso. El frente estará en blanco.",
      "pt-br": "Os cartões têm conteúdo apenas no verso. A frente ficará em branco.",
      "zh-cn": "卡片仅在反面分配内容。正面将为空白。",
      "zh-tw": "卡片僅在反面分配內容。正面將為空白。",
    },
    missingFieldsDetectedHeader: {
      en: "Fields Missing",
      de: "Felder fehlen",
      fr: "Champs manquants",
      ja: "不足しているフィールド",
      es: "Campos faltantes",
      "pt-br": "Campos ausentes",
      "zh-cn": "缺少字段",
      "zh-tw": "缺少欄位",
    },
    missingFieldsDetectedBody: {
      en: "Deck has no fields, so cards will be blank. Add fields to resolve this issue.",
      de: "Deck hat keine Felder, daher werden Karten leer sein. Fügen Sie Felder hinzu, um dieses Problem zu lösen.",
      fr: "Le paquet n'a pas de champs, donc les cartes seront vides. Ajoutez des champs pour résoudre ce problème.",
      ja: "デッキにフィールドがないため、カードは空白になります。この問題を解決するためにフィールドを追加してください。",
      es: "El mazo no tiene campos, por lo que las tarjetas estarán en blanco. Añada campos para resolver este problema.",
      "zh-cn": "卡组没有字段，所以卡片将为空白。添加字段以解决此问题。",
      "zh-tw": "卡組沒有欄位，所以卡片將為空白。添加欄位以解決此問題。",
      "pt-br":
        "Deck não tem campos, então cartões ficarão em branco. Adicione campos para resolver este problema.",
    },
    yours: {
      en: "Your Decks",
      fr: "Vos Ponts",
      "pt-br": "Seus Baralhos",
      ja: "あなたのデッキ",
      de: "Deine Decks",
      es: "Sus Mazos",
      "zh-cn": "你的暗记包",
      "zh-tw": "你的暗記包",
    },
    cardOrientation: {
      en: "Card Orientation",
      fr: "Orientation de la carte",
      de: "Kartenorientierung",
      ja: "カードの向き",
      es: "Orientación de la tarjeta",
      "pt-br": "Orientação do cartão",
      "zh-cn": "卡片方向",
      "zh-tw": "卡片方向",
    },
    cardOrientationDescription: {
      en: "Determines which side of each card is shown first during review.",
      "pt-br": "Determina qual lado de cada cartão é mostrado primeiro durante a revisão.",
      ja: "審査中に各カードのどちらの面を先に見せるかを決める。",
      es: "Determina qué cara de cada carta se muestra primero durante la revisión.",
      de: "Legt fest, welche Seite jeder Karte bei der Überprüfung zuerst angezeigt wird.",
      fr: "Détermine quelle face de chaque carte est affichée en premier lors de l'examen.",
      "zh-cn": "确定复习时每张卡片的哪一面首先显示。",
      "zh-tw": "決定在複習時每張卡片的哪一面先顯示。",
    },
    recent: {
      en: "Recent Decks",
      de: "Aktuelle Decks",
      es: "Mazos Recientes",
      fr: "Ponts Récents",
      ja: "最近のデッキ",
      "pt-br": "Baralhos Recentes",
      "zh-cn": "最近复习的暗记包",
      "zh-tw": "最近復習的暗記包",
    },
    all: {
      en: "All Decks",
      de: "Alle Decks",
      es: "Todas las cubiertas",
      fr: "Tous les ponts",
      ja: "全デッキ",
      "zh-cn": "所有暗记包",
      "zh-tw": "所有暗記包",
      "pt-br": "Todos os Decks",
    },
    singular: {
      de: "Deck",
      en: "Deck",
      fr: "Deck",
      "zh-cn": "暗记包",
      "zh-tw": "暗記包",
      es: "Cubierta",
      ja: "デッキ",
      "pt-br": "Deck",
    },
    plural: {
      de: "Decks",
      en: "Decks",
      fr: "Decks",
      "zh-cn": "暗记包",
      "zh-tw": "暗記包",
      es: "Cubiertas",
      ja: "デッキ",
      "pt-br": "Decks",
    },
    defaultName: {
      en: "My Deck",
      de: "Mein Deck",
      es: "Mi baraja",
      fr: "Mon deck",
      "zh-cn": "我的暗记包",
      "zh-tw": "我的暗記包",
      ja: "マイデッキ",
      "pt-br": "Meu Deck",
    },
    shared: {
      en: "Shared Decks",
      de: "Geteilte Decks",
      es: "Cubiertas compartidas",
      fr: "Decks partagés",
      "pt-br": "Baralhos compartilhados",
      "zh-cn": "共用暗记包",
      "zh-tw": "共用暗記包",
      ja: "シェアードデッキ",
    },
    download: {
      de: "Deck herunterladen",
      en: "Download Deck",
      fr: "Télécharger Deck",
      "pt-br": "Baixar Baralho",
      "zh-cn": "下载暗记包",
      "zh-tw": "下載暗記包",
      es: "Descarga de la cubierta",
      ja: "ダウンロードデッキ",
    },
    downloadComplete: {
      en: "Deck download and update complete!",
      de: "Download und Aktualisierung des Decks komplett!",
      es: "¡Descarga y actualización de la cubierta completa!",
      fr: "Téléchargement et mise à jour de Deck terminés !",
      ja: "デッキのダウンロードとアップデートが完了しました",
      "pt-br": "Dowload e atualização do Baralho completos",
      "zh-cn": "下载并更新暗记包已完成！",
      "zh-tw": "下載並更新暗記包已完成！",
    },
    edit: {
      de: "Deck bearbeiten",
      en: "Edit Deck",
      fr: "Modifier",
      "pt-br": "Editar Baralho",
      "zh-cn": "编辑暗记包",
      "zh-tw": "編輯暗記包",
      es: "Editar la cubierta",
      ja: "デッキを変える",
    },
    get: {
      de: "Decks erhalten",
      en: "Get Decks",
      fr: "Obtenir des terrasses",
      "pt-br": "Obter Baralhos",
      "zh-cn": "获取暗记包",
      "zh-tw": "獲取暗記包",
      es: "Conseguir mazos",
      ja: "デッキを入手",
    },
    mine: {
      de: "Meine Decks",
      en: "My Decks",
      fr: "Mes Decks",
      "pt-br": "Meus Baralhos",
      "zh-cn": "我的暗记包",
      "zh-tw": "我的暗記包",
      es: "Mis mazos",
      ja: "マイデッキ",
    },
    new: {
      de: "Neues Deck",
      en: "New Deck",
      fr: "Nouveau Deck",
      "pt-br": "Novo Baralho",
      "zh-cn": "新暗记包",
      "zh-tw": "新暗記包",
      es: "Cubierta Nueva",
      ja: "新デッキ",
    },
    confirmRemoval: {
      de: "Deck entfernen? Der Fortschritt wird gespeichert.",
      en: "Remove deck? Progress will be saved.",
      fr: "Supprimer le deck ? La progression sera sauvegardée.",
      "zh-cn": "删除暗记包？",
      "zh-tw": "刪除暗記包？",
      es: "¿Quitar la cubierta? El progreso se guardará.",
      ja: "デッキを削除しますか？進行状況は保存されます。",
      "pt-br": "Remover deck? O progresso será salvo.",
    },
    confirmResetGrades: {
      en: "Reset grades? All cards will be marked as NEW. This cannot be undone.",
      de: "Noten zurücksetzen? Alle Karten werden als NEU markiert. Dies kann nicht rückgängig gemacht werden.",
      fr: "Réinitialiser les notes ? Toutes les cartes seront marquées comme NOUVELLES. Ceci est irréversible.",
      es: "¿Restablecer calificaciones? Todas las tarjetas se marcarán como NUEVAS. Esto no se puede deshacer.",
      ja: "成績をリセットしますか？すべてのカードが新規としてマークされます。これは元に戻せません。",
      "pt-br":
        "Redefinir notas? Todos os cartões serão marcados como NOVOS. Isso não pode ser desfeito.",
      "zh-cn": "重置成绩？所有卡片将标记为新的。这不能被撤销。",
      "zh-tw": "重設成績？所有卡片將標記為新的。這不能被撤銷。",
    },
    confirmRemovalDangerous: {
      de: "Möchten Sie dieses Deck dauerhaft löschen?",
      en: "Permanently delete this deck?",
      fr: "Supprimer définitivement ce jeu ?",
      "zh-cn": "彻底删除此暗记包？",
      "zh-tw": "徹底刪除此暗記包？",
      es: "¿Borrar permanentemente esta baraja?",
      ja: "このデッキを永久に削除しますか？",
      "pt-br": "Excluir permanentemente este deck?",
    },
    sharePrompt: {
      de: "E-Mail-Adressen zum Teilen:",
      en: "Emails to share with:",
      fr: "Emails à partager avec :",
      "pt-br": "E-mails para compartilhar:",
      "zh-cn": "分享至邮箱（可多写）：",
      "zh-tw": "分享至郵箱（可多寫）：",
      es: "Correos electrónicos para compartir:",
      ja: "共有するメール",
    },
    confirmUnshare: {
      de: "Geteiltes Deck entfernen?",
      en: "Remove shared deck?",
      fr: "Supprimer le deck partagé ?",
      "zh-cn": "移除此暗记包？",
      "zh-tw": "移除此暗記包？",
      es: "¿Quitar la cubierta compartida?",
      ja: "共有デッキを撤去？",
      "pt-br": "Remover deck compartilhado?",
    },
    info: {
      de: "Deck-Infos",
      en: "Deck Info",
      fr: "Infos sur le deck",
      "zh-cn": "暗记包信息",
      "zh-tw": "暗記包信息",
      es: "Información de la cubierta",
      ja: "デッキ情報",
      "pt-br": "Info do Deck",
    },
    noMatchingCards: {
      en: "No cards match selected tags and/or grades.",
      de: "Keine Karten entsprechen den ausgewählten Tags und/oder Bewertungen.",
      es: "No hay tarjetas que coincidan con las etiquetas y/o grados seleccionados.",
      fr: "Aucune carte ne correspond aux étiquettes et/ou aux grades sélectionnés.",
      "pt-br": "Nenhum cartão corresponde às etiquetas e/ou notas selecionadas.",
      "zh-cn": "没有卡匹配选定的标签和/或成绩。",
      "zh-tw": "沒有卡匹配選定的標籤和/或成績。",
      ja: "選択されたタグやグレードに合致するカードはありません。",
    },
    emptyNewGridCellMessage: {
      en: "Enter text or drag an image here to make a new card",
      de: "Text eingeben oder ein Bild hierher ziehen, um eine neue Karte zu erstellen.",
      es: "Introduzca un texto o arrastre una imagen aquí para crear una nueva tarjeta",
      fr: "Saisissez du texte ou faites glisser une image ici pour créer une nouvelle carte.",
      "pt-br": "Insira o texto ou arraste uma imagem aqui para fazer um novo cartão",
      "zh-cn": "在此处输入文本或拖动图像以制作新卡",
      "zh-tw": "在此處輸入文字或拖動圖像以製作新卡",
      ja: "ここにテキストを入力するか、画像をドラッグして、新しいカードを作成します。",
    },
    resetFilters: {
      en: "Reset Filters",
      de: "Filter zurücksetzen",
      es: "Restablecer filtros",
      fr: "Réinitialisation des filtres",
      "pt-br": "Redefinir filtros",
      "zh-cn": "重置筛选",
      "zh-tw": "重置篩選",
      ja: "リセットフィルター",
    },
    noCardTags: {
      en: "No tags. When you add tags to your cards, those tags will appear here. Then you can select them, to review only cards with those tags.",
      de: "Keine Tags. Wenn Sie Ihren Karten Tags hinzufügen, erscheinen diese hier. Dann können Sie sie auswählen, um nur Karten mit diesen Tags zu überprüfen.",
      es: "No hay etiquetas. Cuando añada etiquetas a sus tarjetas, éstas aparecerán aquí. Entonces puedes seleccionarlas, para revisar sólo las tarjetas con esas etiquetas.",
      fr: "Pas de tags. Lorsque vous ajoutez des étiquettes à vos cartes, celles-ci apparaissent ici. Vous pouvez alors les sélectionner, pour ne consulter que les cartes comportant ces étiquettes.",
      "pt-br":
        "Sem etiquetas. Quando você adiciona etiquetas aos seus cartões, essas etiquetas aparecerão aqui. Então você pode selecioná-las, para revisar somente cartões com essas etiquetas.",
      "zh-cn":
        "没有标签。 当您向卡片添加标签时，这些标签将显示在此处。 然后，您可以选择它们以仅查看带有这些标签的卡片。",
      "zh-tw":
        "沒有標籤。 當您向卡片添加標籤時，這些標籤將顯示在此處。 然後，您可以選擇它們以僅查看帶有這些標籤的卡片。",
      ja: "タグはありません。カードにタグを追加すると、そのタグがここに表示されます。タグを選択すると、そのタグが付いたカードのみを確認することができます。",
    },
    none: {
      de: "Keine Decks.",
      en: "No decks.",
      fr: "Pas de platines.",
      "zh-cn": "没有暗记包。",
      "zh-tw": "沒有暗記包。",
      es: "No hay cubiertas.",
      ja: "デッキがない。",
      "pt-br": "Sem decks.",
    },
    noneLocal: {
      de: "Keine lokalen Decks.<br/>Überprüfen Sie Ihre Cloud-Decks oder erstellen Sie ein neues.",
      en: "No Local decks.<br />Check your Cloud decks or make a new one.",
      fr: "Aucun jeu de cartes local. Vérifiez vos jeux de cartes de nuages ou créez-en un nouveau.",
      "zh-cn": "无<br/>下载或创建新暗记包",
      "zh-tw": "無<br/>下載或創建新暗記包",
      es: "No hay mazos locales.<br />Revisa tus mazos de la Nube o haz uno nuevo.",
      ja: "ローカルデッキはありません。<br />クラウドデッキを確認するか、新しいデッキを作成してください。",
      "pt-br": "Nenhum deck Local.<br />Verifique seus decks na Nuvem ou crie um novo.",
    },
    noneEditableLocal: {
      en: "To get started, use the <b>New Deck</b> button to make a deck. Then, add cards to that deck to study.<br /><br />Or, you can import a deck.<br /><br />If you already have decks, download them from the Home screen.",
      de: "Um zu beginnen, verwenden Sie den <b>Neues Deck</b> Button, um ein Deck zu erstellen. Fügen Sie dann Karten hinzu, um mit dem Lernen zu beginnen.<br/><br/>Oder importieren Sie ein Deck.<br/><br/>Wenn Sie bereits Decks haben, laden Sie diese vom Home-Bildschirm herunter.",
      es: "Para empezar, utiliza el botón <b>Nuevo mazo</b> para crear un mazo. A continuación, añade cartas a ese mazo para estudiarlo.<br /><br />También puedes importar un mazo.<br /><br />Si ya tienes mazos, descárgalos desde la pantalla de inicio.",
      fr: "Pour commencer, utilisez le bouton <b>Nouveau deck</b> pour créer un deck. Ensuite, ajoutez des cartes à ce deck pour les étudier.<br /><br />Ou, vous pouvez importer un deck.<br /><br />Si vous avez déjà des decks, téléchargez-les depuis l'écran d'accueil.",
      ja: "まずは、<b>新しいデッキ</b>ボタンでデッキを作ります。<br /><br />また、デッキをインポートすることもできます。<br /><br />すでにデッキをお持ちの方は、ホーム画面からダウンロードしてください。",
      "zh-cn":
        "要开始使用，请使用 <b>创建暗记包</b> 按钮制作一副暗记包。 然后，将卡片添加到该套暗记包进行复习。<br /><br />或者，您可以导入套暗记包。<br /><br />如果您已有套暗记包，请从主页下载。",
      "zh-tw":
        "要開始使用，請使用 <b>創建暗記包</b> 按鈕製作一副暗記包。 然後，將卡片添加到該套暗記包進行復習。<br /><br />或者，您可以導入套暗記包。<br /><br />如果您已有套暗記包，請從主頁下載。",
      "pt-br":
        "Para começar, use o botão <b>Novo Deck</b> para criar um deck. Em seguida, adicione cartões a esse deck para estudar.<br /><br />Ou, você pode importar um deck.<br /><br />Se você já tem decks, baixe-os na tela inicial.",
    },
    noHistory: {
      de: "Zuletzt verwendete Lokale Decks erscheinen hier.",
      en: "Recently-used Local decks appear here.",
      fr: "Les platines locales récemment utilisées apparaissent ici.",
      "zh-cn": "本机没有最近复习的暗记包",
      "zh-tw": "本機沒有最近復習的暗記包",
      es: "Aquí aparecen las cubiertas locales utilizadas recientemente.",
      ja: "最近使用されたローカルデッキが表示されます。",
      "pt-br": "Decks locais usados recentemente aparecem aqui.",
    },
    noReviews: {
      de: "Tippen Sie auf den Überprüfen-Knopf, um mit dem Lernen zu beginnen.",
      en: "Tap the Review button to begin studying.",
      fr: "Appuyez sur le bouton Révision pour commencer à étudier.",
      "pt-br": "Toque no botão Revisar para começar a estudar.",
      "zh-cn": '点按 "复习" 按钮以开始学习',
      "zh-tw": '點按 "復習" 按鈕以開始學習',
      es: "Pulse el botón de revisión para empezar a estudiar.",
      ja: "復習ボタンをタップして、勉強を開始します。",
    },
    other: {
      de: "Andere",
      en: "Other",
      fr: "Autre",
      "pt-br": "Outros",
      "zh-cn": "其他",
      "zh-tw": "其他",
      es: "Otros",
      ja: "その他",
    },
    remoteOffline: {
      de: "Offline. Cloud-Decks erscheinen hier, wenn wieder eine Verbindung besteht.",
      en: "Offline. Cloud decks will appear here, when back online.",
      fr: "Hors ligne. Les jeux en nuage apparaîtront ici, lorsqu'ils seront de nouveau en ligne.",
      "zh-cn": "离线。重新联机后, 云暗记包将出现在这里。",
      "zh-tw": "離線。重新連線後, 雲暗記包將出現在這裡。",
      es: "Sin conexión. Las cubiertas de la nube aparecerán aquí, cuando vuelvan a estar en línea.",
      ja: "オフラインの状態です。オンラインに戻ると、ここにクラウドデッキが表示されます。",
      "pt-br": "Offline. Os decks em nuvem aparecerão aqui, quando voltar online.",
    },
    namePlaceholder: {
      en: "Deck Name",
      de: "Deckname",
      es: "Nombre de la cubierta",
      fr: "Nom du deck",
      "zh-cn": "甲板名称",
      "zh-tw": "甲板名稱",
      ja: "デッキ名",
      "pt-br": "Nome do Deck",
    },
    descriptionPlaceholder: {
      en: "Description",
      de: "Beschreibung",
      es: "Descripción",
      fr: "Description",
      "pt-br": "Descrição",
      "zh-cn": "描述",
      "zh-tw": "描述",
      ja: "商品説明",
    },
    thirdPartyAttribution: {
      de: "Inhalte Dritter: keine Garantie für die Eignung für einen bestimmten Zweck, keine Garantie für die Nichtverletzung von Rechten Dritter, keine Garantie irgendeiner Art, weder ausdrücklich noch implizit.",
      en: "Third party content: no warranty of fitness for a particular use, no warranty of non-infringement, no warranty of any kind, express or implied.",
      fr: "Contenu tiers : aucune garantie d'adéquation à un usage particulier, aucune garantie de non-contrefaçon, aucune garantie d'aucune sorte, expresse ou implicite.",
      "pt-br":
        "Conteúdo de terceiros: sem garantia de adequação a um uso particular, nenhuma garantia de não-infração, nenhuma garantia de qualquer tipo, expressa ou implícita.",
      "zh-cn":
        "Third party content: no warranty of fitness for a particular use, no warranty of non-infringement, no warranty of any kind, express or implied.",
      "zh-tw":
        "Third party content: no warranty of fitness for a particular use, no warranty of non-infringement, no warranty of any kind, express or implied.",
      es: "Contenido de terceros: no hay garantía de idoneidad para un uso particular, no hay garantía de no infracción, no hay garantía de ningún tipo, expresa o implícita.",
      ja: "第三者コンテンツ：特定用途への適合性の保証、非侵害の保証、明示または黙示を問わずいかなる種類の保証もいたしません。",
    },
    noLayouts: {
      en: "Deck has no layouts",
      de: "Das Deck hat keine Layouts",
      es: "La cubierta no tiene diseños",
      fr: "Le deck n'a pas d'agencement",
      "zh-cn": "没有布局。",
      "zh-tw": "沒有佈局。",
      ja: "デッキのレイアウトはありません",
      "pt-br": "Deck não possui layouts",
    },
    canUpgrade: {
      en: "Deck Format Upgrade Available",
      de: "Deckformat-Upgrade verfügbar",
      es: "Actualización del formato de mazo disponible",
      fr: "Mise à jour du format du paquet disponible",
      ja: "デッキフォーマットのアップグレードが利用可能です",
      "pt-br": "Atualização de formato de deck disponível",
      "zh-cn": "卡组格式升级可用",
      "zh-tw": "牌組格式升級可用",
    },
    canUpgradeBody: {
      en: "Upgrading will let you enable card auto-flip, flip-stabilization, and card generation, which automatically extract content from photos of notes, study materials, signage, menus, etc...",
      de: "Durch das Upgrade können Sie die automatische Kartenumdrehung, Stabilisierung der Umdrehung und Kartenerstellung aktivieren, die automatisch Inhalte aus Fotos von Notizen, Lernmaterialien, Beschilderungen, Menüs usw. extrahieren.",
      es: "La actualización permitirá habilitar el volteo automático de cartas, estabilización de volteo y generación de cartas, que extraen automáticamente contenido de fotos de notas, materiales de estudio, señalizaciones, menús, etc.",
      fr: "La mise à jour vous permettra d'activer le retournement automatique des cartes, la stabilisation du retournement et la génération de cartes, qui extrait automatiquement le contenu des photos de notes, de matériaux d'étude, de panneaux, de menus, etc.",
      ja: "アップグレードすると、カードの自動反転、反転の安定化、およびカード生成を有効にすることができ、これにより、ノート、学習資料、標識、メニューなどの写真から自動的にコンテンツが抽出されます。",
      "pt-br":
        "A atualização permitirá habilitar o auto-virar de cartões, estabilização de virada e geração de cartões, que extrai automaticamente conteúdo de fotos de anotações, materiais de estudo, sinalizações, menus, etc.",
      "zh-cn":
        "升级后，您将能够启用卡片自动翻转、翻转稳定和卡片生成功能，这些功能可以自动从笔记、学习材料、标志、菜单等的照片中提取内容。",
      "zh-tw":
        "升級後，您將能够啟用卡片自動翻轉、翻轉穩定和卡片生成功能，這些功能可以自動從筆記、學習材料、標誌、菜單等的照片中提取內容。",
    },
    confirmUpgrade: {
      en: "Upgrade deck? This change cannot be undone.",
      de: "Deck aktualisieren? Diese Änderung kann nicht rückgängig gemacht werden.",
      es: "¿Actualizar el mazo? Este cambio no se puede deshacer.",
      fr: "Mettre à jour le paquet ? Ce changement est irréversible.",
      ja: "デッキをアップグレードしますか？この変更は元に戻すことができません。",
      "pt-br": "Atualizar o deck? Esta alteração não pode ser desfeita.",
      "zh-cn": "升级牌组？此更改无法撤销。",
      "zh-tw": "升級牌組？此更改無法撤銷。",
    },
  },
  card: {
    singular: {
      de: "Karte",
      en: "Card",
      fr: "Carte",
      "pt-br": "Cartão",
      "zh-cn": "暗记卡",
      "zh-tw": "暗記卡",
      es: "Tarjeta",
      ja: "カード",
    },
    yours: {
      en: "Your Cards",
      es: "Sus Tarjetas",
      de: "Deine Karten",
      ja: "あなたのカード",
      "pt-br": "Seus Cartões",
      fr: "Vos Cartes",
      "zh-cn": "您的暗记卡",
      "zh-tw": "您的暗記卡",
    },
    plural: {
      de: "Karten",
      en: "Cards",
      fr: "Cartes",
      "pt-br": "Cartões",
      "zh-cn": "暗记卡",
      "zh-tw": "暗記卡",
      es: "Tarjetas",
      ja: "カード",
    },
    duplicateDetected: {
      en: "Duplicate detected. One or more fields in this card match an existing card in this deck.",
      "pt-br":
        "Duplicado detectado. Um ou mais campos neste cartão correspondem a um cartão existente neste deck.",
      ja: "重複が検出されました。このカードの1つ以上のフィールドが、このデッキの既存のカードと一致する。",
      fr: "Duplicata détecté. Un ou plusieurs champs de cette carte correspondent à une carte existante dans ce jeu.",
      de: "Duplikat entdeckt. Ein oder mehrere Felder dieser Karte stimmen mit einer bestehenden Karte in diesem Stapel überein.",
      es: "Duplicado detectado. Uno o más campos de esta tarjeta coinciden con una tarjeta existente en este mazo.",
      "zh-cn": "检测到重复。此暗记卡中的一个或多个数据区与此暗记包中的现有暗记卡相符。",
      "zh-tw": "檢測到重複。此暗記卡中的一個或多個數據區與此暗記包中已存在的暗記卡匹配。",
    },
    numDuplicatesDetected: {
      en: "duplicates detected",
      de: "Duplikate entdeckt",
      es: "duplicados detectados",
      "pt-br": "duplicatas detectadas",
      ja: "重複検出",
      fr: "doublons détectés",
      "zh-cn": "检测到重复项",
      "zh-tw": "檢測到重複項目",
    },
    generateFallibilityWarning: {
      en: "Generated cards may contain mistakes.",
      de: "Generierte Karten können Fehler enthalten.",
      "pt-br": "Cartões gerados podem conter erros.",
      es: "Las tarjetas generadas pueden contener errores.",
      ja: "作成されたカードには間違いが含まれている可能性があります。",
      fr: "Les cartes générées peuvent contenir des erreurs.",
      "zh-cn": "生成的暗记卡可能包含错误。",
      "zh-tw": "生成的暗記卡可能包含錯誤。",
    },
    noSuitableDeck: {
      en: "No compatible decks",
      de: "Keine kompatiblen Decks",
      es: "No hay mazos compatibles",
      fr: "Pas de paquets compatibles",
      "zh-cn": "没有兼容的卡组",
      "zh-tw": "沒有兼容的牌組",
      ja: "互換性のあるデッキがありません",
      "pt-br": "Nenhum deck compatível",
    },
    noLayoutsToCreateCardError: {
      en: "No layouts. You must create a layout before creating a card.",
      de: "Keine Layouts vorhanden. Sie müssen ein Layout erstellen, um eine Karte anzulegen.",
      es: "No hay maquetas. Debe crear un diseño antes de crear una tarjeta.",
      fr: "Pas de mise en page. Vous devez créer une mise en page avant de créer une carte.",
      "pt-br": "Sem layouts. Você deve criar um layout antes de criar um cartão.",
      "zh-cn": "没有布局。在创建卡片之前，您必须创建布局。",
      "zh-tw": "沒有佈局。在建立卡片之前，您必須創建佈局。",
      ja: "レイアウトはありません。カードを作成する前に、レイアウトを作成する必要があります。",
    },
    copy: {
      en: "Copy Cards",
      ja: "カードをコピー",
      "pt-br": "Copiar Cartões",
      "zh-cn": "复制暗记卡",
      de: "Karten Kopieren",
      fr: "Copier des Cartes",
      "zh-tw": "複製暗記卡",
      es: "Copiar Cartas",
    },
    copyCompatibilityWarning: {
      en: "NOTE: if the receiving deck does not have a compatible layout, cards may appear to be missing content when copied.",
      de: "HINWEIS: Wenn das Ziel-Deck kein kompatibles Layout hat, kann es vorkommen, dass die Karten scheinbar ohne Inhalt kopiert werden.",
      fr: "REMARQUE: si la platine réceptrice ne dispose pas d'une mise en page compatible, les cartes peuvent sembler manquer de contenu lors de la copie.",
      "zh-cn": "注意：如果接收暗记包没有兼容的布局，则复制时卡可能显示缺少内容。",
      "zh-tw": "注意：如果接收暗記包沒有相容的佈局，則複製時卡可能顯示缺少內容。",
      ja: "注意：受信側のデッキが互換性のあるレイアウトでない場合、コピーした際にカードの内容が欠落しているように見えることがあります。",
      es: "NOTA: si el mazo receptor no tiene un diseño compatible, las tarjetas pueden parecer que les falta contenido al copiarlas.",
      "pt-br":
        "NOTA: se o deck de recebimento não tiver um layout compatível, cartões podem parecer estar faltando conteúdo quando copiados.",
    },
    copyNoDecks: {
      en: "No decks to copy card to. Download or make a new deck.",
      de: "Keine Decks zum Kopieren der Karte vorhanden. Laden Sie ein Deck herunter oder erstellen Sie ein neues.",
      es: "No hay barajas a las que copiar la carta. Descarga o crea un nuevo mazo.",
      fr: "Pas de jeux de cartes à copier. Téléchargez ou créez un nouveau jeu de cartes.",
      "zh-cn": "没有兼容暗记包。下载或制作新暗记包。",
      "zh-tw": "沒有兼容暗記包。下載或製作新暗記包。",
      ja: "カードをコピーするデッキがない。ダウンロードするか、新しいデッキを作ってください。",
      "pt-br": "Nenhum deck para copiar o cartão. Baixe ou crie um novo deck.",
    },
    total: {
      de: "Gesamte Karten",
      en: "Total Cards",
      fr: "Total des cartes",
      "pt-br": "Total de Cartões",
      "zh-cn": "暗记卡",
      "zh-tw": "暗記卡",
      es: "Total de tarjetas",
      ja: "カード合計",
    },
    edit: {
      de: "Karte bearbeiten",
      en: "Edit Card",
      fr: "Modifier la Carte",
      "pt-br": "Editar Cartão",
      "zh-cn": "编辑暗记卡",
      "zh-tw": "編輯暗記卡",
      es: "Editar tarjeta",
      ja: "カードを変える",
    },
    generate: {
      en: "Generate Cards",
      fr: "Générer des Cartes",
      de: "Karten Cenerieren",
      "pt-br": "Gerar Cartões",
      ja: "カードの作成",
      es: "Generar Tarjetas",
      "zh-tw": "生成暗記卡",
      "zh-cn": "生成暗记卡",
    },
    perSession: {
      de: "Karten pro Sitzung",
      en: "Cards per Session",
      fr: "Cartes par session",
      "pt-br": "Cartões por sessão",
      "zh-cn": "复习卡片数量",
      "zh-tw": "復習卡片數量",
      es: "Tarjetas por sesión",
      ja: "セッションごとのカード",
    },
    cardsPerSessionDescription: {
      en: "Choose the maximum number of cards to show in each review session.",
      es: "Elija el número máximo de tarjetas que se mostrarán en cada sesión de revisión.",
      ja: "各レビューセッションで表示するカードの最大数を選択します。",
      fr: "Choisissez le nombre maximum de cartes à montrer lors de chaque session d'examen.",
      "pt-br": "Escolha o número máximo de cartões a serem exibidos em cada sessão de revisão.",
      de: "Wählen Sie die maximale Anzahl von Karten, die in jeder Sitzung gezeigt werden sollen.",
      "zh-cn": "选择每个复习会话中显示的卡片的最大数量。",
      "zh-tw": "選擇每個複習會議中顯示的卡片的最大數量。",
    },
    confirmRemoval: {
      de: "Karte entfernen? Dies kann nicht rückgängig gemacht werden.",
      en: "Remove card? This cannot be undone.",
      fr: "Retirer la carte ? Cette opération ne peut être annulée.",
      "pt-br": "Apagar cartão? Isso não pode ser desfeito.",
      "zh-cn": "确定删除？删除后不可还原",
      "zh-tw": "確定刪除？刪除後不可還原",
      es: "¿Quitar la tarjeta? Esto no se puede deshacer.",
      ja: "カードを削除しますか？これは元に戻せません。",
    },
    confirmRemovalPlural: {
      en: "Remove cards? This cannot be undone.",
      es: "¿Quitar tarjetas? Esto no se puede deshacer.",
      "pt-br": "Apagar cartões? Isso não pode ser desfeito.",
      ja: "カードを削除しますか？これは元に戻せません。",
      de: "Karten entfernen? Dies kann nicht rückgängig gemacht werden.",
      fr: "Supprimer des cartes ? Cette opération ne peut être annulée.",
      "zh-cn": "确定删除？删除后不可还原",
      "zh-tw": "確定刪除？刪除後不可還原",
    },
    create: {
      en: "Create Card",
      ja: "カード作成",
      fr: "Créer une Carte",
      "pt-br": "Criar Cartão",
      de: "Karte Erstellen",
      es: "Crear Tarjeta",
      "zh-cn": "创建暗记卡",
      "zh-tw": "建立暗記卡",
    },
    new: {
      de: "Neue Karte",
      en: "New Card",
      fr: "Nouvelle carte",
      "pt-br": "Novo cartão",
      "zh-cn": "创建暗记卡",
      "zh-tw": "創建暗記卡",
      es: "Nueva tarjeta",
      ja: "新カード",
    },
    createFeatureDescription: {
      en: "Make cards by typing in your own content.",
      "pt-br": "Crie cartões digitando seu próprio conteúdo.",
      ja: "自分のコンテンツを入力してカードを作る。",
      de: "Erstellen Sie Karten, indem Sie Ihren eigenen Inhalt eintippen.",
      fr: "Créez des cartes en tapant votre propre contenu.",
      es: "Crea tarjetas escribiendo tu propio contenido.",
      "zh-cn": "通过输入您自己的内容来制作暗记卡。",
      "zh-tw": "輸入你自己的內容來建立暗記卡。",
    },
    none: {
      de: "Keine Karten",
      en: "No Cards",
      fr: "Pas de cartes",
      "pt-br": "Sem Cartões",
      "zh-cn": "无暗记卡",
      "zh-tw": "無暗記卡",
      es: "Sin tarjetas",
      ja: "カードなし",
    },
    starred: {
      de: "Mit Stern",
      en: "Starred",
      fr: "Favori",
      "pt-br": "Favoritado",
      "zh-cn": "加星卡片",
      "zh-tw": "加星卡片",
      es: "Protagonista:",
      ja: "星付き",
    },
    ignoredCards: {
      de: "Ignorierte Karten",
      en: "Ignored Cards",
      fr: "Cartes ignorées",
      "pt-br": "Cartões Ignorados",
      "zh-cn": "忽略卡",
      "zh-tw": "忽略卡",
      es: "Tarjetas ignoradas",
      ja: "無視されるカード",
    },
    starredCards: {
      de: "Markierte Karten",
      en: "Starred Cards",
      fr: "Cartes favori",
      "pt-br": "Cartões favoritados",
      "zh-cn": "星卡",
      "zh-tw": "星卡",
      es: "Tarjetas con estrella",
      ja: "星付きカード",
    },
    noneStarred: {
      de: "Keine markierten Karten",
      en: "No Starred Cards",
      fr: "Pas de cartes favori",
      "pt-br": "Sem Cartões Favoritados",
      "zh-cn": "无",
      "zh-tw": "無",
      es: "Sin tarjetas con estrella",
      ja: "スター付きカードはありません",
    },
    renderFailed: {
      de: "Die Karte konnte nicht dargestellt werden.",
      en: "Failed to render card.",
      fr: "Échec du rendu de la carte.",
      "pt-br": "Falha ao exibir o cartão.",
      "zh-cn": "无法呈现卡。",
      "zh-tw": "無法呈現卡。",
      es: "Fallo en la presentación de la tarjeta.",
      ja: "カードのレンダリングに失敗しました。",
    },
    clickToEdit: {
      en: "Click to edit",
      de: "Zum Bearbeiten klicken",
      es: "Haga clic para editar",
      fr: "Cliquez pour modifier",
      "pt-br": "Clique para editar",
      "zh-cn": "点击编辑",
      "zh-tw": "點擊編輯",
      ja: "クリックで編集",
    },
    errorAddingFile: {
      en: "Error adding file.",
      de: "Fehler beim Hinzufügen der Datei.",
      es: "Error al añadir el archivo.",
      fr: "Erreur d'ajout de fichier.",
      "pt-br": "Erro ao adicionar arquivo.",
      ja: "ファイルの追加に失敗しました。",
      "zh-cn": "添加文件时出错",
      "zh-tw": "添加文件時出錯",
    },
  },
  layout: {
    defaultName: {
      en: "My Layout",
      es: "Mi diseño",
      de: "Mein Layout",
      "pt-br": "Meu Layout",
      ja: "マイレイアウト",
      fr: "Ma mise en page",
      "zh-cn": "我的布局",
      "zh-tw": "我的佈局",
    },
    singular: {
      de: "Layout",
      en: "Layout",
      fr: "Mise en page",
      "pt-br": "Layout",
      "zh-cn": "排版",
      "zh-tw": "排版",
      es: "Diseño",
      ja: "レイアウト",
    },
    plural: {
      de: "Layouts",
      en: "Layouts",
      fr: "Mises en page",
      "pt-br": "Layouts",
      "zh-cn": "排版",
      "zh-tw": "排版",
      es: "Diseños",
      ja: "レイアウト",
    },
    edit: {
      de: "Layout bearbeiten",
      en: "Edit Layout",
      fr: "Modifier la mise en page",
      "pt-br": "Editar layout",
      "zh-cn": "编辑排版",
      "zh-tw": "編輯排版",
      es: "Editar el diseño",
      ja: "レイアウトを変える",
    },
    create: {
      de: "Neues Layout",
      en: "New Layout",
      fr: "Nouvelle mise en page",
      "pt-br": "Novo Layout",
      "zh-cn": "创建排版",
      "zh-tw": "創建排版",
      es: "Nueva disposición",
      ja: "新レイアウト",
    },
    confirmReset: {
      de: "Layout zurücksetzen? Änderungen gehen verloren.",
      en: "Reset layout? Changes will be lost.",
      fr: "Réinitialiser la mise en page ? Les modifications seront perdues.",
      "pt-br": "Redefinir o layout? As mudanças serão perdidas.",
      "zh-cn": "确定重设排版？",
      "zh-tw": "確定重設排版？",
      es: "¿Restablecer el diseño? Los cambios se perderán.",
      ja: "レイアウトをリセットしますか？変更内容が失われます。",
    },
    confirmRemoval: {
      de: "Layout entfernen? Dies kann nicht rückgängig gemacht werden.",
      en: "Remove layout? This cannot be undone.",
      fr: "Supprimer la mise en page ? Cette opération ne peut être annulée.",
      "pt-br": "Apagar o layout? Isto não pode ser desfeito.",
      "zh-cn": "确定删除排版？删除后不可还原",
      "zh-tw": "確定刪除排版？刪除後不可還原",
      es: "¿Quitar el diseño? Esto no se puede deshacer.",
      ja: "レイアウトを削除しますか？これは元に戻せません。",
    },
    frontStructure: {
      de: "Vorderseite (HTML)",
      en: "Front (HTML)",
      fr: "Front (HTML)",
      "pt-br": "Frente (HTML)",
      "zh-cn": "正面 (HTML)",
      "zh-tw": "正面 (HTML)",
      es: "Portada (HTML)",
      ja: "フロント（HTML）",
    },
    backStructure: {
      de: "Rückseite (HTML)",
      en: "Back (HTML)",
      fr: "Retour (HTML)",
      "pt-br": "Verso (HTML)",
      "zh-cn": "反面 (HTML)",
      "zh-tw": "反面 (HTML)",
      es: "Volver (HTML)",
      ja: "戻る（HTML）",
    },
    reset: {
      de: "Layout zurücksetzen",
      en: "Reset layout",
      fr: "Réinitialisation de la mise en page",
      "pt-br": "Redefinir o layout",
      "zh-cn": "重设排版",
      "zh-tw": "重設排版",
      es: "Restablecer la disposición",
      ja: "レイアウトをリセットする",
    },
    none: {
      de: "Keine Layouts",
      en: "No Layouts",
      fr: "Aucune mise en page",
      "pt-br": "Sem layouts",
      "zh-cn": "无排版",
      "zh-tw": "無排版",
      es: "No hay diseños",
      ja: "レイアウトなし",
    },
    defaultDescription: {
      en: "Plain text front to back and back to front. Just like a textual flash card.",
      ja: "表から裏へ、裏から表へ。まるでフラッシュカードのように。",
      de: "Vorderseite zu Rückseite und umgekehrt im einfachen Textformat, genau wie eine herkömmliche Karteikarte.",
      es: "Del anverso al reverso y del reverso al anverso. Igual que una tarjeta flash.",
      "pt-br": "Texto simples frente e verso. Como um cartão textual.",
      fr: "De l'avant à l'arrière et de l'arrière à l'avant. Comme une carte flash.",
      "zh-tw": "文字化從前至後及從後至前。就像一張文字化的暗記卡。",
      "zh-cn": "简单文本从正面到背面和从背面到正面。就像一个文字暗记卡。",
    },
    newLayoutDescription: {
      en: "Customize content fields and their display",
      de: "Anpassung der Inhaltsfelder und deren Darstellung",
      fr: "Personnaliser les champs de contenu et leur affichage",
      es: "Personalizar los campos de contenido y su visualización",
      ja: "コンテンツフィールドとその表示のカスタマイズ",
      "pt-br": "Personalizar campos de conteúdo e sua exibição",
      "zh-cn": "自定义内容数据区及其显示",
      "zh-tw": "自定內容數據區及其顯示",
    },
  },
  preInstalledLayouts: {
    textUnderImage: {
      en: "Text Under Image",
      de: "Text unter Bild",
      es: "Texto debajo de la imagen",
      fr: "Texte sous l'image",
      ja: "画像の下のテキスト",
      "pt-br": "Texto sob a imagem",
      "zh-tw": "圖片下方的文字",
      "zh-cn": "图片下方的文字",
    },
    standard: {
      en: "Standard",
      de: "Standard",
      es: "Estándar",
      fr: "Standard",
      ja: "標準",
      "pt-br": "Padrão",
      "zh-tw": "標準",
      "zh-cn": "标准",
    },
    simple: {
      en: "Simple",
      de: "Einfach",
      es: "Sencillo",
      fr: "Facile",
      ja: "簡単",
      "pt-br": "Simples",
      "zh-tw": "簡單",
      "zh-cn": "简单",
    },
    enhanced: {
      en: "Enhanced",
      fr: "Amélioré",
      ja: "エンハンスド",
      es: "Mejorado",
      de: "Verbessert",
      "pt-br": "Aprimorado",
      "zh-tw": "特殊",
      "zh-cn": "特殊",
    },
    specialized: {
      en: "Specialized",
      es: "Especializada",
      ja: "スペシャライズド",
      "pt-br": "Especializado",
      de: "Spezialisiert",
      fr: "Spécialisé",
      "zh-tw": "專門",
      "zh-cn": "专业化的",
    },
    language: {
      en: "Language",
      "pt-br": "Idioma",
      es: "Idioma",
      de: "Sprache",
      fr: "Langue",
      ja: "言語",
      "zh-cn": "语言",
      "zh-tw": "語言",
    },
    specialtyLanguage: {
      en: "Specialty Language",
      de: "Spezialsprache",
      es: "Idioma de especialidad",
      ja: "専門言語",
      fr: "Langue de spécialité",
      "pt-br": "Idioma com caracteres especiais",
      "zh-tw": "專業語言",
      "zh-cn": "专业语言",
    },
    programming: {
      en: "Programming",
      ja: "プログラミング",
      de: "Programmierung",
      fr: "Programmation",
      es: "Programación",
      "pt-br": "Programação",
      "zh-tw": "編程",
      "zh-cn": "编程",
    },
    media: {
      en: "Media",
      ja: "メディア",
      es: "Medios de comunicación",
      de: "Medien",
      fr: "Médias",
      "pt-br": "Mídia",
      "zh-tw": "多媒體",
      "zh-cn": "多媒体",
    },
    math: {
      en: "Math",
      fr: "Mathématiques",
      ja: "数学",
      de: "Mathematik",
      es: "Matemáticas",
      "pt-br": "Matemática",
      "zh-tw": "數學",
      "zh-cn": "数学",
    },
    mathAndProgramming: {
      en: "Math and Programming",
      es: "Matemáticas y programación",
      fr: "Maths et programmation",
      de: "Mathematik und Programmierung",
      "pt-br": "Matemática e Programação",
      ja: "数学とプログラミング",
      "zh-tw": "數學和編程",
      "zh-cn": "数学和编程",
    },
    listeningTTS: {
      en: "Natural spoken text (TTS)",
      de: "Natürlicher gesprochener Text (TTS)",
      es: "Comprensión oral (TTS)",
      fr: "Compréhension orale (TTS)",
      ja: "聴解(TTS)",
      "pt-br": "Compreensão auditiva (Texto para fala)",
      "zh-tw": "自然語音文本轉語音 (TTS)",
      "zh-cn": "自然语音文本转语音 (TTS)",
    },
    listeningAudio: {
      en: "Audio",
      "zh-tw": "音頻",
      "zh-cn": "音频",
      es: "Audio",
      fr: "Audio",
      ja: "オーディオ",
      de: "Audio",
      "pt-br": "Áudio",
    },
    imageRecognition: {
      en: "Image",
      de: "Bild",
      fr: "Image",
      es: "Imagen",
      "pt-br": "Imagem",
      ja: "画像",
      "zh-tw": "圖像",
      "zh-cn": "图像",
    },
    richText: {
      en: "Rich Text",
      de: "Reichhaltiger Text",
      es: "Texto enriquecido",
      ja: "リッチテキスト",
      fr: "Texte enrichi",
      "pt-br": "Texto detalhado",
      "zh-tw": "富文本",
      "zh-cn": "富文本",
    },
    equationTeX: {
      en: "Equation (TeX)",
      es: "Ecuación (TeX)",
      de: "Gleichung (TeX)",
      "pt-br": "Equação (TeX)",
      ja: "式(TeX)",
      fr: "Equation (TeX)",
      "zh-tw": "方程 (TeX)",
      "zh-cn": "方程 (TeX)",
    },
    codeProduction: {
      en: "Code (Production)",
      es: "Código (Producción)",
      fr: "Code (Production)",
      de: "Code (Produktion)",
      "pt-br": "Código (Produção)",
      ja: "コード（制作）",
      "zh-tw": "代碼（生產）",
      "zh-cn": "代码（生产）",
    },
    speechField: {
      en: "Speech",
      fr: "Discours",
      de: "Sprachfeld",
      es: "Discurso",
      ja: "スピーチ",
      "pt-br": "Discurso",
      "zh-tw": "言語",
      "zh-cn": "言语",
    },
    meaningField: {
      en: "Meaning",
      de: "Bedeutungsfeld",
      fr: "Signification",
      es: "Significado",
      ja: "意味",
      "pt-br": "Significado",
      "zh-tw": "文義",
      "zh-cn": "文义",
    },
    imageField: {
      en: "Image",
      ja: "画像",
      de: "Bildfeld",
      es: "Imagen",
      fr: "Image",
      "pt-br": "Imagem",
      "zh-tw": "圖像",
      "zh-cn": "图像",
    },
    codeField: {
      en: "Code",
      ja: "コード",
      es: "Código",
      fr: "Code",
      de: "Codefeld",
      "pt-br": "Código",
      "zh-tw": "代碼",
      "zh-cn": "代碼",
    },
    equationField: {
      en: "Equation",
      de: "Gleichungsfeld",
      fr: "Équation",
      ja: "方程式",
      es: "Ecuación",
      "pt-br": "Equação",
      "zh-tw": "方程",
      "zh-cn": "方程",
    },
  },
  review: {
    omni: {
      en: "OmniReview",
      de: "OmniÜberprüfung",
      es: "OmniRevise",
      fr: "OmniRévision",
      "pt-br": "OmniRevisão",
      "zh-cn": "Omni复习",
      "zh-tw": "Omni復習",
      ja: "オムニレビュー",
    },
    omniReviewRecencyCutoffLabel: {
      de: "Aktualitätsgrenze",
      en: "Recency Cutoff",
      fr: "Limite de récence",
      "pt-br": "Limite de Recência",
      "zh-cn": "最近截止",
      "zh-tw": "最近截止",
      es: "Corte de Recencia",
      ja: "最近のカットオフ",
    },
    omniReviewRecencyCutoffDescription: {
      de: "Wählen Sie Decks, die seit",
      en: "Select decks reviewed since",
      fr: "Sélectionnez les decks passés en revue depuis",
      "zh-cn": "选择自该时间以来复习的卡组",
      "zh-tw": "選擇自該時間以來複習的卡組",
      es: "Selecciona barajas revisadas desde",
      ja: "この時点以降にレビューされたデッキを選択",
      "pt-br": "Selecione decks revisados desde",
    },
    omniReviewRecencyCutoffModalDescription: {
      de: "Aktualitätsgrenze legt den Zeitrahmen fest, für die Decks in die Rezensionssitzung einbezogen werden sollen.",
      en: "Recency cutoff sets the timeframe for decks to include in the review session.",
      fr: "La limite de récence définit la période pour laquelle les decks seront inclus dans la session de révision.",
      "zh-cn": "最近截止设置了包括在复习会议中的卡组的时间框架。",
      "zh-tw": "最近截止設置了包括在復習會議中的卡組的時間框架。",
      es: "El corte de recencia establece el marco de tiempo para incluir las barajas en la sesión de revisión.",
      ja: "最近のカットオフは、レビューセッションに含めるデッキの時間枠を設定します。",
      "pt-br":
        "O corte de recência define o período de tempo para incluir decks na sessão de revisão.",
    },
    omniReviewRecencyCutoff1wkLabel: {
      de: "Letzte Woche",
      en: "Past week",
      fr: "La semaine passée",
      "pt-br": "Semana passada",
      "zh-cn": "过去一周",
      "zh-tw": "過去一週",
      es: "Semana pasada",
      ja: "過去1週間",
    },
    omniReviewRecencyCutoff1wkDescription: {
      de: "Wählen Sie Decks, die in der letzten Woche überprüft wurden",
      en: "Select decks reviewed in past week",
      fr: "Sélectionnez les decks révisés la semaine passée",
      "zh-cn": "选择在过去一周内复习的卡组",
      "zh-tw": "選擇在過去一週內複習的卡組",
      es: "Selecciona barajas revisadas la semana pasada",
      ja: "過去1週間にレビューされたデッキを選択",
      "pt-br": "Selecione decks revisados na semana passada",
    },
    omniReviewRecencyCutoff1moLabel: {
      de: "Letzter Monat",
      en: "Past month",
      fr: "Le mois passé",
      "pt-br": "Mês passado",
      "zh-cn": "过去一个月",
      "zh-tw": "過去一個月",
      es: "Mes pasado",
      ja: "過去1ヶ月",
    },
    omniReviewRecencyCutoff1moDescription: {
      de: "Wählen Sie Decks, die im letzten Monat überprüft wurden",
      en: "Select decks reviewed in past month",
      fr: "Sélectionnez les decks révisés le mois passé",
      "zh-cn": "选择在过去一个月内复习的卡组",
      "zh-tw": "選擇在過去一個月內複習的卡組",
      es: "Selecciona barajas revisadas el mes pasado",
      ja: "過去1ヶ月にレビューされたデッキを選択",
      "pt-br": "Selecione decks revisados no mês passado",
    },
    omniReviewRecencyCutoff3moLabel: {
      de: "Letzten 3 Monate",
      en: "Past 3 months",
      fr: "Les trois derniers mois",
      "pt-br": "Últimos 3 meses",
      "zh-cn": "过去三个月",
      "zh-tw": "過去三個月",
      es: "Últimos 3 meses",
      ja: "過去3ヶ月",
    },
    omniReviewRecencyCutoff3moDescription: {
      de: "Wählen Sie Decks, die in den letzten 3 Monaten überprüft wurden",
      en: "Select decks reviewed in past 3 months",
      fr: "Sélectionnez les decks révisés ces trois derniers mois",
      "zh-cn": "选择在过去三个月内复习的卡组",
      "zh-tw": "選擇在過去三個月內複習的卡組",
      es: "Selecciona barajas revisadas en los últimos 3 meses",
      ja: "過去3ヶ月にレビューされたデッキを選択",
      "pt-br": "Selecione decks revisados nos últimos 3 meses",
    },
    omniReviewRecencyCutoffAllDescription: {
      de: "Alle Decks auswählen",
      en: "Select all decks",
      fr: "Sélectionner tous les decks",
      "zh-cn": "选择所有卡组",
      "zh-tw": "選擇所有卡組",
      es: "Seleccionar todas las barajas",
      ja: "すべてのデッキを選択",
      "pt-br": "Selecionar todos os decks",
    },
    failedToLoadSession: {
      en: "Failed to load review session.",
      "pt-br": "Falha ao carregar a sessão de revisão.",
      es: "Error al cargar la sesión de revisión.",
      ja: "レビューセッションのロードに失敗しました。",
      de: "Die Überprüfungssitzung konnte nicht geladen werden.",
      fr: "Échec du chargement de la session d'examen.",
      "zh-cn": "无法加载复习卡片。",
      "zh-tw": "無法載入複習卡片。",
    },
    isTheaterMode: {
      en: "Theater Mode",
      fr: "Mode théâtre",
      ja: "シアターモード",
      de: "Theater-Modus",
      es: "Modo Teatro",
      "pt-br": "Modo Teatro",
      "zh-cn": "剧院模式",
      "zh-tw": "劇院模式",
    },
    autoFlip: {
      en: "Auto-flip",
      fr: "Retournement automatique",
      de: "Automatisches Umklappen",
      es: "Auto-flip",
      ja: "オートフリップ",
      "pt-br": "Virar automaticamente",
      "zh-cn": "自动翻转",
      "zh-tw": "自動翻轉",
    },
    autoAdvance: {
      en: "Auto-advance",
      de: "Automatischer Vorlauf",
      es: "Avance automático",
      "pt-br": "Avanço automático",
      fr: "Avance automatique",
      ja: "自動前進",
      "zh-tw": "自動前進",
      "zh-cn": "自动前进",
    },
    tagsSettingDescription: {
      en: "Select tags here to review only cards with those tags.",
      es: "Seleccione aquí las etiquetas para revisar sólo las tarjetas con esas etiquetas.",
      fr: "Sélectionnez les étiquettes ici pour ne consulter que les cartes portant ces étiquettes.",
      ja: "ここでタグを選択すると、そのタグの付いたカードだけを確認することができます。",
      de: "Wählen Sie hier Tags aus, um nur Karten mit diesen Tags anzuzeigen.",
      "pt-br": "Selecione as tags aqui para revisar apenas os cartões com essas tags.",
      "zh-cn": "在此选择标签以只复习带有这些标签的卡片。",
      "zh-tw": "在此選擇標籤以只複習帶有這些標籤的卡片。",
    },
    timerDescription: {
      en: "Auto-flip will automatically flip from the front to the back of each card. Auto-advance will automatically flip and advance from one card to the next, for a fully-passive review session. Auto-advance mode will count reviews for each card you see, but won't affect your grades.",
      fr: "Le retournement automatique permet de passer automatiquement du recto au verso de chaque carte. L'avance automatique permet de passer automatiquement d'une carte à l'autre, pour une session de révision entièrement passive. Le mode d'avance automatique comptabilise les révisions pour chaque carte que vous voyez, mais n'affecte pas vos notes.",
      es: "Auto-flip pasará automáticamente del anverso al reverso de cada tarjeta. Auto-avance pasará automáticamente de una tarjeta a la siguiente, para una sesión de repaso totalmente pasiva. El modo de avance automático contará los repasos de cada tarjeta que veas, pero no afectará a tus notas.",
      "pt-br":
        "O Auto-flip vai virar automaticamente da frente para o verso de cada cartão. O avanço automático irá virar e avançar de um cartão para o outro, para uma sessão de revisão totalmente passiva. O modo Auto-advance contará as revisões de cada cartão que você ver, mas não influenciará suas notas.",
      de: "Auto-Flip wird automatisch von der Vorder- zur Rückseite jeder Karte blättern. Auto-Advance blättert und springt automatisch von einer Karte zur nächsten, für eine vollständig passive Lernsession. Der Auto-Advance-Modus zählt Ihre Wiederholungen für jede gesehene Karte, beeinflusst jedoch nicht Ihre Noten.",
      ja: "オートフリップは、各カードの表から裏へ自動的にめくります。オートアドバンスは、1枚のカードから次のカードへ自動的にめくりながら進みます。オートアドバンス・モードでは、各カードを見るたびにレビューがカウントされますが、成績には影響しません。",
      "zh-cn":
        "自动翻页会自动从每张卡片的正面翻到背面。自动前进会自动翻页并从一张卡片进入下一张，提供一个完全被动的复习环节。自动前进模式会计算你看到的每张卡片的复习次数，但不会影响你的成绩。",
      "zh-tw":
        "自動翻頁將會自動從每張卡片的正面翻到背面。自動前進將會自動翻頁並從一張卡片進入下一張，提供一個全自動的復習環節。自動前進模式會計算你所看過的每張卡片的復習次數，但不會影響你的成績。",
    },
    timer: {
      en: "Auto-flip Timer",
      de: "Automatischer Timer zum Karten umdrehen",
      es: "Temporizador de autodesconexión",
      fr: "Minuterie Auto-flip",
      "pt-br": "Temporizador para virar automaticamente",
      "zh-cn": "自动翻卡",
      "zh-tw": "自動翻卡",
      ja: "オートフリップタイマー",
    },
    mode: {
      en: "Review Mode",
      de: "Überprüfungsmodus",
      es: "Modo de revisión",
      fr: "Mode révision",
      "pt-br": "Modo de revisão",
      "zh-cn": "复习模式",
      "zh-tw": "復習模式",
      ja: "レビューモード",
    },
    modeSettingDescription: {
      en: "Review Mode controls how cards are selected for each review session.",
      es: "El modo de revisión controla cómo se seleccionan las tarjetas para cada sesión de revisión.",
      fr: "Le mode de révision détermine la manière dont les cartes sont sélectionnées pour chaque session de révision.",
      "pt-br":
        "O Modo de revisão controla como os cartões são selecionados para cada sessão de revisão.",
      de: "Der Überprüfungsmodus steuert, wie die Karten für jede Überprüfungssitzung ausgewählt werden.",
      ja: "レビュー・モードは、各レビュー・セッションでのカードの選択方法を制御します。",
      "zh-cn": "复习模式控制如何为每个复习会话选择卡片。",
      "zh-tw": "複習模式控制如何為每個複習會議選擇卡片。",
    },
    modeShuffled: {
      en: "Random Shuffle",
      de: "Zufälliges Mischen",
      es: "barajar al azar",
      fr: "mélange aléatoire",
      "zh-cn": "随机顺序",
      "zh-tw": "隨機順序",
      ja: "ランダムシャッフル",
      "pt-br": "Embaralhar Aleatoriamente",
    },
    modeShuffledDescription: {
      en: "Selects cards randomly.",
      "pt-br": "Seleciona cartões aleatoriamente.",
      ja: "ランダムにカードを選ぶ。",
      fr: "Sélectionne les cartes de manière aléatoire.",
      es: "Selecciona las cartas aleatoriamente.",
      de: "Wählt die Karten zufällig aus.",
      "zh-cn": "随机选择卡片。",
      "zh-tw": "隨機選擇卡片。",
    },
    modeCreatedOrder: {
      en: "In Created Order",
      de: "In erstellter Reihenfolge",
      es: "En orden de creación",
      fr: "Dans l'ordre de création",
      "pt-br": "Por ordem de criação",
      "zh-cn": "按创建时间顺序",
      "zh-tw": "按創建時間順序",
      ja: "作成順",
    },
    modeCreatedOrderDescription: {
      en: "Selects and shows cards in created order.",
      "pt-br": "Seleciona e mostra os cartões na ordem criada.",
      es: "Selecciona y muestra las tarjetas en el orden creado.",
      fr: "Sélectionne et montre les cartes dans l'ordre créé.",
      de: "Wählt Karten aus und zeigt sie in der erstellten Reihenfolge an.",
      ja: "カードを選択し、作成された順番に表示する。",
      "zh-cn": "按创建顺序选择并显示卡片。",
      "zh-tw": "按創建順序選擇並顯示卡片。",
    },
    modeAnkiAppSRSDescription: {
      en: "Selects cards intelligently using grades.",
      de: "Wählt Karten auf intelligente Weise anhand von Noten aus.",
      "pt-br": "Seleciona cartões de forma inteligente usando notas.",
      fr: "Sélectionne les cartes de manière intelligente en utilisant les notes.",
      ja: "成績によってカードを賢く選択。",
      es: "Selecciona las tarjetas de forma inteligente utilizando calificaciones.",
      "zh-cn": "使用成绩智能选择卡片。",
      "zh-tw": "使用成績智能選擇卡片。",
    },
    createdOrderNumCardsToSkip: {
      en: "Number of cards to skip",
      de: "Anzahl zu überspringender Karten",
      es: "Número de tarjetas a saltar",
      fr: "Nombre de cartes à sauter",
      "pt-br": "Número de cartões para pular",
      "zh-cn": "按创建时间顺序",
      "zh-tw": "按創建時間順序",
      ja: "スキップするカードの枚数",
    },
    createdOrderNumCardsToSkipPrompt: {
      en: "(Enter 0 to not skip cards)",
      de: "(Geben Sie 0 ein, um keine Karten zu überspringen)",
      es: "Introduzca 0 para no omitir las tarjetas",
      fr: "Entrez 0 pour ne pas sauter de cartes",
      "pt-br": "Insira 0 para não pular cartões",
      "zh-cn": "按创建时间顺序",
      "zh-tw": "按創建時間順序",
      ja: "(カードをスキップしない場合は0を入力してください）",
    },
    session: {
      en: "Session",
      de: "Sitzung",
      es: "Sesión",
      fr: "Session",
      "pt-br": "Sessão",
      "zh-cn": "会期",
      "zh-tw": "會期",
      ja: "セッション",
    },
    singular: {
      en: "Review",
      de: "Überprüfung",
      es: "Revise",
      fr: "Révision",
      "pt-br": "Revisão",
      "zh-cn": "复习",
      "zh-tw": "復習",
      ja: "レビュー",
    },
    downloaded: {
      en: "Review (Downloaded Decks)",
      ja: "レビュー（ダウンロード済みデッキ）",
      "pt-br": "Revisão (Baralhos Baixados)",
      es: "Revisión (Mazos Descargados)",
      de: "Überprüfung (Heruntergeladene Decks)",
      fr: "Revue (Ponts Téléchargés)",
      "zh-cn": "复习（已下载卡组）",
      "zh-tw": "複習（已下載牌組）",
    },
    group: {
      en: "Review Group",
      de: "Gruppe überprüfen",
      es: "Grupo de Revisión",
      fr: "Groupe de révision",
      "pt-br": "Grupo de Revisão",
      "zh-cn": "评审组",
      "zh-tw": "評審組",
      ja: "レビューグループ",
    },
    streak: {
      en: "Streak (Days)",
      de: "Serie (Tage)",
      es: "Racha (Días)",
      fr: "Série (Jours)",
      "pt-br": "Sequência (Dias)",
      "zh-cn": "连续学习天数",
      "zh-tw": "連續學習天數",
      ja: "連続日数",
    },
    plural: {
      de: "Überprüfungen",
      en: "Reviews",
      fr: "Révisions",
      "pt-br": "Revisões",
      "zh-cn": "复习记录",
      "zh-tw": "復習記錄",
      es: "Revisiones",
      ja: "レビュー",
    },
    numToday: {
      de: "Heutige Überprüfungen",
      en: "Reviews Today",
      fr: "Révisions aujourd'hui",
      "pt-br": "Revisões Hoje",
      "zh-cn": "今日复习次数",
      "zh-tw": "今日復習次數",
      es: "Reseñas de hoy",
      ja: "今日のレビュー",
    },
    numPerDay: {
      de: "Überprüfungen pro Tag (Durchschnitt)",
      en: "Reviews per Day (Average)",
      fr: "Révisions par jour (moyenne)",
      "pt-br": "Revisões por dia (Média)",
      "zh-cn": "每日平均复习次数",
      "zh-tw": "每日平均復習次數",
      es: "Reseñas por día (promedio)",
      ja: "1日あたりのレビュー数（平均）",
    },
    total: {
      de: "Gesamtüberprüfungen",
      en: "Total Number of Reviews",
      fr: "Nombre total de révisions",
      "pt-br": "Número total de revisões",
      "zh-cn": "复习总次数",
      "zh-tw": "復習總次數",
      es: "Número total de revisiones",
      ja: "レビュー総数",
    },
    totalTime: {
      de: "Gesamte Überprüfungszeit",
      en: "Total Review Time",
      fr: "Durée totale de l'révision",
      "pt-br": "Tempo total de revisão",
      "zh-cn": "复习总时长",
      "zh-tw": "復習總時長",
      es: "Tiempo total de revisión",
      ja: "総レビュー時間",
    },
    averageTime: {
      de: "Durchschnittliche Überprüfungszeit",
      en: "Average Review Time",
      fr: "Durée moyenne de révision",
      "pt-br": "Tempo médio de revisão",
      "zh-cn": "平均复习时长",
      "zh-tw": "平均復習時長",
      es: "Tiempo medio de revisión",
      ja: "平均レビュー時間",
    },
    reportPrompt: {
      de: "Bitte beschreiben Sie das Problem mit dieser Karte.",
      en: "Please describe the issue(s) with this card.",
      fr: "Veuillez décrire le ou les problèmes que pose cette carte.",
      "pt-br": "Favor descrever o(s) problema(s) com este cartão.",
      "zh-cn": "请详细描述举报原因：",
      "zh-tw": "請詳細描述舉報原因：",
      es: "Por favor, describa los problemas de esta tarjeta.",
      ja: "このカードの問題点を教えてください。",
    },
  },
  response: {
    fail: {
      de: "Fehl",
      en: "Fail",
      fr: "Échec",
      "pt-br": "Errei",
      "zh-cn": "重来",
      "zh-tw": "重來",
      es: "Falla",
      ja: "☓",
    },
    hard: {
      de: "Schwer",
      en: "Hard",
      fr: "Difficile",
      "pt-br": "Difícil",
      "zh-cn": "困难",
      "zh-tw": "困難",
      es: "Difícil",
      ja: "△",
    },
    good: {
      de: "Gut",
      en: "Good",
      fr: "Bon",
      "pt-br": "Bom",
      "zh-cn": "好",
      "zh-tw": "好",
      es: "Buena",
      ja: "〇",
    },
    easy: {
      de: "Leicht",
      en: "Easy",
      fr: "Facile",
      "pt-br": "Fácil",
      "zh-cn": "简单",
      "zh-tw": "簡單",
      es: "Fácil",
      ja: "完璧",
    },
    auto: {
      en: "Auto",
      de: "Automatisch",
      "pt-br": "Auto",
      fr: "Automatique",
      ja: "自動",
      es: "Automático",
      "zh-tw": "自動",
      "zh-cn": "自动",
    },
  },
  folder: {
    singular: {
      en: "Folder",
      de: "Ordner",
      es: "Carpeta",
      fr: "Dossier",
      "pt-br": "Pasta",
      "zh-cn": "文件夹",
      "zh-tw": "資料夾",
      ja: "フォルダ",
    },
    plural: {
      en: "Folders",
      de: "Ordner",
      es: "Carpetas",
      fr: "Dossiers",
      "pt-br": "Pastas",
      "zh-cn": "文件夹",
      "zh-tw": "資料夾",
      ja: "フォルダー",
    },
    subfolders: {
      en: "Subfolders",
      de: "Unterordner",
      es: "Subcarpetas",
      fr: "Sous-dossiers",
      "pt-br": "Subpastas",
      "zh-cn": "子文件夹",
      "zh-tw": "子資料夾",
      ja: "サブフォルダー",
    },
    new: {
      en: "New Folder",
      de: "Neuen Ordner",
      es: "Nueva Carpeta",
      fr: "Nouveau Dossier",
      "pt-br": "Nova Pasta",
      "zh-cn": "新建文件夹",
      "zh-tw": "新建資料夾",
      ja: "新しいフォルダ",
    },
    newSubfolder: {
      en: "New Subfolder",
      de: "Neuen Unterordner",
      es: "Nueva Subcarpeta",
      fr: "Nouveau Sous-dossier",
      "pt-br": "Nova Subpasta",
      "zh-cn": "新建子文件夹",
      "zh-tw": "新建子資料夾",
      ja: "新しいサブフォルダー",
    },
    delete: {
      en: "Delete Folder",
      de: "Ordner löschen",
      es: "Eliminar Carpeta",
      fr: "Supprimer le Dossier",
      "pt-br": "Excluir Pasta",
      "zh-cn": "删除文件夹",
      "zh-tw": "刪除資料夾",
      ja: "フォルダの削除",
    },
    rename: {
      en: "Rename Folder",
      de: "Ordner umbenennen",
      es: "Cambiar nombre de carpeta",
      fr: "Renommer un dossier",
      "pt-br": "Renomear pasta",
      "zh-cn": "重命名文件夹",
      "zh-tw": "重命名資料夾",
      ja: "フォルダ名の変更",
    },
    renamePrompt: {
      en: "New name",
      de: "Neuer Name",
      es: "Nuevo nombre",
      fr: "Nouveau nom",
      "pt-br": "Novo nome",
      "zh-cn": "新名称",
      "zh-tw": "新名稱",
      ja: "新名称",
    },
    confirmArchive: {
      de: "Ordner und alle Unterordner archivieren?",
      en: "Archive folder and all subfolders?",
      fr: "Archiver le dossier et tous ses sous-dossiers ?",
      "pt-br": "Arquivar pasta e todas as subpastas?",
      "zh-cn": "归档文件夹及其所有子文件夹？",
      "zh-tw": "歸檔資料夾及所有子資料夾？",
      es: "¿Archivar carpeta y todas las subcarpetas?",
      ja: "フォルダーとすべてのサブフォルダーをアーカイブしますか？",
    },
    confirmRemoval: {
      en: "Remove from folder?",
      de: "Aus Ordner entfernen?",
      es: "¿Quitar de la carpeta?",
      fr: "Supprimer du dossier ?",
      "pt-br": "Remover da pasta?",
      "zh-cn": "从文件夹中删除？",
      "zh-tw": "從資料夾中刪除？",
      ja: "フォルダから削除しますか？",
    },
    added: {
      en: "Added",
      de: "Hinzugefügt",
      es: "Añadido",
      fr: "Ajouté",
      "pt-br": "Adicionado",
      "zh-cn": "添加成功",
      "zh-tw": "添加完成",
      ja: "追加",
    },
    moveDeckHere: {
      en: "Move Deck Here",
      fr: "Déplacer le Pont Ici",
      es: "Mueva la Cubierta Aquí",
      ja: "デッキをここに移動",
      de: "Deck Hierher Verschieben",
      "pt-br": "Mover Deck Aqui",
      "zh-tw": "將暗記包移至此處",
      "zh-cn": "将暗记包移到这里",
    },
    newDeckDescription: {
      en: "Create a new deck in this folder.",
      es: "Crea una nueva cubierta en esta carpeta.",
      ja: "このフォルダに新しいデッキを作成する。",
      fr: "Créez un nouveau jeu dans ce dossier.",
      "pt-br": "Criar um novo deck nesta pasta.",
      de: "Erstellen Sie ein neues Deck in diesem Ordner.",
      "zh-tw": "在此資料夾中創建一個新的暗記包。",
      "zh-cn": "在这个文件夹中新建暗记包。",
    },
    moveDeckHereDescription: {
      en: "Add an existing deck to this folder.",
      "pt-br": "Adicione um deck existente a esta pasta.",
      ja: "既存のデッキをこのフォルダに追加する。",
      fr: "Ajouter un jeu de cartes existant à ce dossier.",
      es: "Añade una cubierta existente a esta carpeta.",
      de: "Fügen Sie ein bestehendes Deck zu diesem Ordner hinzu.",
      "zh-tw": "將已存在的暗記包加入到此資料夾。",
      "zh-cn": "将现有暗记包添加到此文件夹。",
    },
    removeDeckFrom: {
      en: "Remove Deck",
      de: "Deck Entfernen",
      es: "Quitar Mazo",
      fr: "Retirer le Deck",
      "pt-br": "Excluir Baralho",
      "zh-cn": "从文件夹中移除暗记包",
      "zh-tw": "從資料夾中移除暗記包",
      ja: "デッキの取り外し",
    },
    none: {
      en: "Organize your decks with folders.",
      de: "Organisieren Sie Ihre Decks mit Ordnern.",
      es: "Organiza tus mazos con carpetas.",
      fr: "Organisez vos decks avec des dossiers.",
      "zh-cn": "使用文件夹整理您的卡片组。",
      "zh-tw": "使用文件夾整理您的卡片組。",
      ja: "フォルダでデッキを整理",
      "pt-br": "Organize seus decks com pastas.",
    },
  },
  tag: {
    singular: {
      de: "Tag",
      en: "Tag",
      fr: "Étiquette",
      "pt-br": "Etiqueta",
      "zh-cn": "标签",
      "zh-tw": "標籤",
      es: "Etiqueta",
      ja: "タグ",
    },
    plural: {
      de: "Tags",
      en: "Tags",
      fr: "Tags",
      "pt-br": "Etiquetas",
      "zh-cn": "标签",
      "zh-tw": "標籤",
      es: "Etiquetas:",
      ja: "タグ",
    },
    untagged: {
      de: "Unmarkiert",
      en: "Untagged",
      fr: "Non balisé",
      "pt-br": "Etiqueta removida",
      "zh-cn": "无标签",
      "zh-tw": "無標籤",
      es: "Sin etiqueta",
      ja: "タグなし",
    },
    none: {
      de: "Keine Tags.",
      en: "No tags.",
      fr: "Pas d'étiquettes.",
      "pt-br": "Sem etiquetas.",
      "zh-cn": "无标签",
      "zh-tw": "無標籤",
      es: "No hay etiquetas.",
      ja: "タグはありません。",
    },
    show: {
      de: "Tags Anzeigen",
      en: "Show Tags",
      fr: "Afficher les Étiquettes",
      "pt-br": "Mostrar Etiquetas",
      "zh-cn": "显示标签",
      "zh-tw": "顯示標籤",
      es: "Mostrar Etiquetas",
      ja: "タグを表示する",
    },
    hide: {
      de: "Tags verbergen",
      en: "Hide Tags",
      fr: "Cacher les Étiquettes",
      "pt-br": "Esconder etiquetas",
      "zh-cn": "不显示标签",
      "zh-tw": "不顯示標籤",
      es: "Ocultar Etiquetas",
      ja: "タグを隠す",
    },
    add: {
      de: "Tag(s) hinzufügen",
      en: "Add Tag(s)",
      fr: "Ajouter une ou plusieurs balises",
      "pt-br": "Adicionar etiqueta(s)",
      "zh-cn": "添加标签",
      "zh-tw": "添加標籤",
      es: "Añadir etiqueta(s)",
      ja: "タグを追加する",
    },
    inputList: {
      de: "Geben Sie eine Liste von Tags ein, getrennt durch Komma:",
      en: "Input list of tags, separated by comma:",
      fr: "Liste d'entrée des balises, séparées par une virgule :",
      "pt-br": "Insira a lista de etiquetas, separadas por vírgula:",
      "zh-cn": "输入标签（可用逗号分隔）",
      "zh-tw": "輸入標籤（可用逗號分隔）",
      es: "Lista de entrada de etiquetas, separadas por comas:",
      ja: "カンマで区切られたタグのリストを入力します。",
    },
    confirmRemoval: {
      en: "Remove tag?",
      de: "Tag entfernen?",
      es: "¿Quitar la etiqueta?",
      fr: "Supprimer l'étiquette ?",
      "pt-br": "Excluir a etiqueta?",
      "zh-cn": "删除标签？",
      "zh-tw": "刪除標籤？",
      ja: "タグを外す？",
    },
  },
  grade: {
    singular: {
      de: "Note",
      en: "Grade",
      fr: "Grade",
      "pt-br": "Nota",
      "zh-cn": "成绩",
      "zh-tw": "成績",
      es: "Grado",
      ja: "グレード",
    },
    changeToday: {
      de: "Notenänderung heute",
      en: "Grade Change Today",
      fr: "Changement de classe aujourd'hui",
      "pt-br": "Mudança de nota hoje",
      "zh-cn": "今日成绩提升",
      "zh-tw": "今日成績提升",
      es: "Cambio de grado hoy",
      ja: "本日のグレード変更",
    },
    newGrade: {
      de: "Neue Note",
      en: "New Grade",
      fr: "Nouvelle note",
      "pt-br": "Nova nota",
      "zh-cn": "新成绩",
      "zh-tw": "新成績",
      es: "Nuevo grado",
      ja: "新グレード",
    },
    NEW: {
      de: "Neu",
      en: "New",
      fr: "Nouveau",
      "pt-br": "Novo",
      "zh-cn": "新",
      "zh-tw": "新",
      es: "Nuevo",
      ja: "新",
    },
    F: {
      de: "6",
      en: "F",
      fr: "F",
      "pt-br": "F",
      "zh-cn": "较差",
      "zh-tw": "較差",
      es: "F",
      ja: "F",
    },
    E: {
      de: "5",
      en: "E",
      fr: "E",
      "pt-br": "E",
      "zh-cn": "差",
      "zh-tw": "差",
      es: "E",
      ja: "E",
    },
    D: {
      de: "4",
      en: "D",
      fr: "D",
      "pt-br": "D",
      "zh-cn": "弱",
      "zh-tw": "弱",
      es: "D",
      ja: "D",
    },
    C: {
      de: "3",
      en: "C",
      fr: "C",
      "pt-br": "C",
      "zh-cn": "一般",
      "zh-tw": "一般",
      es: "C",
      ja: "C",
    },
    B: {
      de: "2",
      en: "B",
      fr: "B",
      "pt-br": "B",
      "zh-cn": "良",
      "zh-tw": "良",
      es: "B",
      ja: "B",
    },
    A: {
      de: "1",
      en: "A",
      fr: "A",
      "pt-br": "A",
      "zh-cn": "好",
      "zh-tw": "好",
      es: "A",
      ja: "A",
    },
    AA: {
      de: "AA",
      en: "AA",
      fr: "AA",
      "pt-br": "AA",
      "zh-cn": "优",
      "zh-tw": "優",
      es: "AA",
      ja: "たんじゅん",
    },
    AAA: {
      de: "AAA",
      en: "AAA",
      fr: "AAA",
      "pt-br": "AAA",
      "zh-cn": "厉害",
      "zh-tw": "厲害",
      es: "AAA",
      ja: "AAA",
    },
  },
  account: {
    email: {
      de: "E-Mail",
      en: "Email",
      fr: "Courriel :",
      "pt-br": "Email",
      "zh-cn": "邮箱",
      "zh-tw": "郵箱",
      es: "Envíe un correo electrónico a",
      ja: "電子メール",
    },
    password: {
      de: "Passwort",
      en: "Password",
      fr: "Mot de passe",
      "pt-br": "Senha",
      "zh-cn": "密码",
      "zh-tw": "密碼",
      es: "Contraseña",
      ja: "パスワード",
    },
    confirmPassword: {
      de: "Passwort bestätigen",
      en: "Confirm Password",
      fr: "Confirmer le mot de passe",
      "pt-br": "Confirmar senha",
      "zh-cn": "再次输入密码",
      "zh-tw": "再次輸入密碼",
      es: "Confirmar contraseña",
      ja: "パスワードの確認",
    },
    changeEmail: {
      de: "E-Mail-Adresse ändern",
      en: "Change Email",
      fr: "Modifier l'e-mail",
      "pt-br": "Alterar e-mail",
      "zh-cn": "更改电子邮箱",
      "zh-tw": "更改電子郵箱",
      es: "Cambiar el correo electrónico",
      ja: "電子メールの変更",
    },
    changeEmailError: {
      de: "Fehler beim Ändern der E-Mail. Stellen Sie sicher, dass Ihr aktuelles Passwort korrekt ist.",
      en: "Error changing email. Ensure your current password is correct.",
      fr: "Erreur de changement d'adresse électronique. Assurez-vous que votre mot de passe actuel est correct.",
      "pt-br": "Erro ao alterar o e-mail. Certifique-se de que sua senha atual esteja correta.",
      "zh-cn": "更改电子邮箱错误。请确认密码正确。",
      "zh-tw": "更改電子郵箱錯誤。請確認密碼正確。",
      es: "Error al cambiar el correo electrónico. Asegúrese de que su contraseña actual es correcta.",
      ja: "メールの変更に失敗しました。現在のパスワードが正しいか確認してください。",
    },
    newEmail: {
      de: "Neue E-Mail-Adresse",
      en: "New Email",
      fr: "Nouvel Email",
      "pt-br": "Novo e-mail",
      "zh-cn": "新电子邮箱",
      "zh-tw": "新電子郵箱",
      es: "Nuevo correo electrónico",
      ja: "新規メール",
    },
    changePassword: {
      de: "Passwort ändern",
      en: "Change Password",
      fr: "Changer le mot de passe",
      "pt-br": "Alterar senha",
      "zh-cn": "更改密码",
      "zh-tw": "更改密碼",
      es: "Cambiar la contraseña",
      ja: "パスワードの変更",
    },
    changePasswordError: {
      de: "Fehler beim Ändern des Passworts. Stellen Sie sicher, dass Ihr aktuelles Passwort korrekt ist.",
      en: "Error changing password. Ensure your current password is correct.",
      fr: "Erreur lors de la modification du mot de passe. Assurez-vous que votre mot de passe actuel est correct.",
      "pt-br": "Erro ao alterar a senha. Certifique-se de que sua senha atual esteja correta.",
      "zh-cn": "更改密码失败；确认输入正确的已有密码.",
      "zh-tw": "更改密碼失敗；確認輸入正確的已有密碼.",
      es: "Error al cambiar la contraseña. Asegúrese de que su contraseña actual es correcta.",
      ja: "パスワードの変更に失敗しました。現在のパスワードが正しいか確認してください。",
    },
    changePasswordSuccess: {
      de: "Passwort erfolgreich geändert.",
      en: "Password changed.",
      fr: "Le mot de passe a été modifié.",
      "pt-br": "Senha alterada.",
      "zh-cn": "密码更改成功.",
      "zh-tw": "密碼更改成功.",
      es: "Se ha cambiado la contraseña.",
      ja: "パスワードを変更しました。",
    },
    currentPassword: {
      de: "Aktuelles Passwort",
      en: "Current password",
      fr: "Mot de passe actuel",
      "pt-br": "Senha atual",
      "zh-cn": "请输入已有密码:",
      "zh-tw": "請輸入已有密碼:",
      es: "Contraseña actual",
      ja: "現在のパスワード",
    },
    newPassword: {
      de: "Neues Passwort",
      en: "New Password",
      fr: "Nouveau mot de passe",
      "pt-br": "Nova senha",
      "zh-cn": "请输入新密码:",
      "zh-tw": "請輸入新密碼:",
      es: "Nueva contraseña",
      ja: "新しいパスワード",
    },
    passwordMismatch: {
      de: "Die Passwörter stimmen nicht überein.",
      en: "Passwords don't match.",
      fr: "Les mots de passe ne correspondent pas.",
      "pt-br": "As senhas não correspondem.",
      "zh-cn": "密码不匹配.",
      "zh-tw": "密碼不匹配.",
      es: "Las contraseñas no coinciden.",
      ja: "パスワードが一致しない。",
    },
    forgotPassword: {
      de: "Passwort vergessen?",
      en: "Forgot Password?",
      fr: "Mot de passe oublié ?",
      "pt-br": "Esqueceu a senha?",
      "zh-cn": "忘记密码？",
      "zh-tw": "忘記密碼？",
      es: "¿Olvidaste la contraseña?",
      ja: "パスワードを忘れた方はこちら",
    },
    forgotPasswordPrompt: {
      de: "Geben Sie die E-Mail-Adresse ein, die Sie für die Anmeldung bei AnkiApp verwendet haben. Eine E-Mail mit Details zum Zurücksetzen Ihres Passworts wird an diese Adresse geschickt.",
      en: "Enter the email address you used when signing up with AnkiApp. An email will be sent to this address with details on how to reset your password.",
      fr: "Saisissez l'adresse électronique que vous avez utilisée lors de votre inscription à AnkiApp. Un e-mail sera envoyé à cette adresse avec les détails sur la façon de réinitialiser votre mot de passe.",
      "pt-br":
        "Insira o endereço de e-mail que utilizou quando se inscreveu na AnkiApp. Será enviado um e-mail para este endereço com detalhes sobre como redefinir a sua senha.",
      "zh-cn":
        "输入您在AnkiApp注册时使用的电子邮件地址。我们将向该地址发送一封电子邮件，说明如何重置您的密码。",
      "zh-tw":
        "輸入您在AnkiApp註冊時使用的電子郵件地址。我們將向該地址發送一封電子郵件，說明如何重置您的密碼。",
      es: "Introduce la dirección de correo electrónico que utilizaste al registrarte en AnkiApp. Se enviará un correo electrónico a esta dirección con los detalles sobre cómo restablecer tu contraseña.",
      ja: "AnkiAppにサインアップした際に使用したメールアドレスを入力してください。このアドレスに、パスワードのリセット方法の詳細が記載されたメールが送信されます。",
    },
    forgotPasswordSuccess: {
      de: "Anfrage zum Zurücksetzen verschickt. Bitte überprüfen Sie Ihre E-Mails.",
      en: "Reset request sent. Please check your email.",
      fr: "La demande de réinitialisation a été envoyée. Veuillez vérifier votre e-mail.",
      "pt-br": "Pedido de redefinição de senha enviado. Por favor, verifique seu e-mail.",
      "zh-cn": "请求发出成功；请查收邮件.",
      "zh-tw": "請求發出成功；請查收郵件.",
      es: "Solicitud de restablecimiento enviada. Por favor, compruebe su correo electrónico.",
      ja: "リセット要求が送信されました。メールをご確認ください。",
    },
    forgotPasswordFailure: {
      de: "Anfrage zum Zurücksetzen gescheitert. Sie müssen die E-Mail eingeben, mit der Sie sich registriert haben.",
      en: "Reset request failed. You must enter the email you registered with.",
      fr: "La demande de réinitialisation a échoué. Vous devez saisir l'adresse électronique avec laquelle vous vous êtes inscrit.",
      "pt-br":
        "O pedido de redefinir a senha falhou. Você deve digitar o e-mail com o qual se registrou.",
      "zh-cn": "请求失败；请输入有效的邮箱.",
      "zh-tw": "請求失敗；請輸入有效的郵箱.",
      es: "La solicitud de restablecimiento ha fallado. Debe introducir el correo electrónico con el que se registró.",
      ja: "リセットのリクエストに失敗しました。登録したEメールを入力してください。",
    },
    logIn: {
      de: "Einloggen",
      en: "Log In",
      fr: "Connectez-vous",
      "pt-br": "Login",
      "zh-cn": "登录",
      "zh-tw": "登錄",
      es: "Entrar",
      ja: "ログイン",
    },
    signUp: {
      de: "Registrieren",
      en: "Sign Up",
      fr: "S'inscrire",
      "pt-br": "Cadastre-se",
      "zh-cn": "注册",
      "zh-tw": "註冊",
      es: "Inscríbete",
      ja: "サインアップ",
    },
    emailSuggest: {
      en: "Did you mean",
      de: "Meinten Sie",
      fr: "Vouliez-vous écrire",
      "pt-br": "Você quis dizer",
      "zh-cn": "替换",
      "zh-tw": "替換",
      es: "¿Quiso decir",
      ja: "置換:",
    },
    termsOfService: {
      de: "Nutzungsbedingungen",
      en: "Terms of Service",
      fr: "Conditions d'utilisation",
      "pt-br": "Termos de serviço",
      "zh-cn": "使用条款",
      "zh-tw": "使用條款",
      es: "Condiciones de servicio",
      ja: "利用規約",
    },
    privacyPolicy: {
      de: "Datenschutzrichtlinien",
      en: "Privacy Policy",
      fr: "Politique de confidentialité",
      "pt-br": "Política de Privacidade",
      "zh-cn": "隐私权",
      "zh-tw": "隱私權",
      es: "Política de privacidad",
      ja: "プライバシーポリシー",
    },
    forceLogout: {
      de: "WARNUNG: Eine Abmeldung führt jetzt zum Verlust von Daten.",
      en: "WARNING: logout now will cause data loss.",
      fr: "AVERTISSEMENT : se déconnecter maintenant entraînera une perte de données.",
      "pt-br": "AVISO: desconectar-se agora vai causar perda de dados.",
      "zh-cn": "注意: 如果现在登出，部分数据无法同步.",
      "zh-tw": "注意: 如果現在登出，部分數據無法同步.",
      es: "ADVERTENCIA: cerrar la sesión ahora causará la pérdida de datos.",
      ja: "警告：今ログアウトすると、データが失われます。",
    },
    forceLogoutAborted: {
      de: "Abmeldung abgebrochen.",
      en: "Logout aborted.",
      fr: "Déconnexion interrompue.",
      "pt-br": "Desconexão cancelada.",
      "zh-cn": "登出取消.",
      "zh-tw": "登出取消.",
      es: "Cierre de sesión abortado.",
      ja: "ログアウトが中止されました。",
    },
    confirmLogout: {
      de: "Sind Sie sicher, dass Sie sich abmelden möchten?",
      en: "Are you sure you want to logout?",
      fr: "Vous êtes sûr de vouloir vous déconnecter ?",
      "pt-br": "Você tem certeza de que quer sair da sessão?",
      "zh-cn": "确定登出？",
      "zh-tw": "確定登出？",
      es: "¿Seguro que quieres cerrar la sesión?",
      ja: "本当にログアウトしますか？",
    },
    iOSUpgrade: {
      de: "Upgrade auf AnkiApp Unlimited",
      en: "Upgrade to AnkiApp Unlimited",
      fr: "Passez à AnkiApp Unlimited",
      "pt-br": "Atualizar para AnkiApp Ilimitada",
      "zh-cn": "升级到暗记App无限版",
      "zh-tw": "升級到暗記App無限版",
      es: "Actualizar a AnkiApp Unlimited",
      ja: "AnkiApp 無制限版へのアップグレード",
    },
    upgrade: {
      de: "Upgrade AnkiApp",
      en: "Upgrade AnkiApp",
      fr: "Mettre à jour AnkiApp",
      "pt-br": "Atualizar a AnkiApp",
      "zh-cn": "升级暗记App",
      "zh-tw": "升級暗記App",
      es: "Actualizar AnkiApp",
      ja: "AnkiAppのアップグレード",
    },
    reviewGateGateReason: {
      en: "You've reached the review limit for today. Upgrade now to continue studying, or come back tomorrow.",
      de: "Sie haben das Überprüfungslimit für heute erreicht. Aktualisieren Sie jetzt, um weiterzulernen, oder kommen Sie morgen wieder.",
      es: "Has alcanzado el límite de repasos por hoy. Actualiza ahora para seguir estudiando o vuelve mañana.",
      ja: "本日のレビュー制限に達しました。今すぐアップグレードして勉強を続けるか、明日また来てください。",
      "pt-br":
        "Você atingiu o limite de revisão para hoje. Atualize agora para continuar estudando, ou volte amanhã.",
      fr: "Vous avez atteint la limite de révision pour aujourd'hui. Mettez à niveau maintenant pour continuer à étudier, ou revenez demain.",
      "zh-cn": "您已达到今天的复习限制。现在升级以继续学习，或者明天再来。",
      "zh-tw": "您已達到今天的複習限制。現在升級以繼續學習，或者明天再來。",
    },
    upgradePitch: {
      de: "Besuchen Sie ankiapp.com zum Upgrade.",
      en: "Visit web.ankiapp.com to upgrade.",
      fr: "Visitez web.ankiapp.com pour effectuer la mise à niveau.",
      "pt-br": "Visite web.ankiapp.com para atualizar.",
      "zh-cn": "访问web.ankiapp.com升级",
      "zh-tw": "訪問web.ankiapp.com升級",
      es: "Visita web.ankiapp.com para actualizarte.",
      ja: "アップグレードはweb.ankiapp.comで行ってください。",
    },
    upgradeNow: {
      de: "Jetzt upgraden",
      en: "Upgrade Now",
      fr: "Mettre à niveau",
      "pt-br": "Atualizar agora",
      "zh-cn": "立刻升级",
      "zh-tw": "立刻升級",
      es: "Actualizar ahora",
      ja: "今すぐアップグレード",
    },
    basic: {
      de: "Basic",
      en: "Basic",
      fr: "Basic",
      "pt-br": "Básica",
      "zh-cn": "免费版",
      "zh-tw": "免費版",
      es: "Basic",
      ja: "ベーシック",
    },
    restoreIAP: {
      de: "Käufe wiederherstellen",
      en: "Restore Purchases",
      fr: "Restaurer les achats",
      "pt-br": "Restaurar Compras",
      "zh-cn": "恢复购买",
      "zh-tw": "恢復購買",
      es: "Restablecer las compras",
      ja: "購入履歴の復元",
    },
    iapsRestored: {
      en: "Purchases restored.",
      es: "Compras restauradas.",
      fr: "Achats restaurés.",
      de: "Käufe wiederhergestellt.",
      "pt-br": "Compras restauradas.",
      ja: "購入が復活した。",
      "zh-cn": "购买已恢复。",
      "zh-tw": "購買已恢復。",
    },
    noIAPsToRestore: {
      en: "No purchases found.",
      de: "Keine Käufe gefunden.",
      ja: "購入は見つかりませんでした。",
      fr: "Aucun achat n'a été trouvé.",
      es: "No se han encontrado compras.",
      "pt-br": "Nenhuma compra encontrada.",
      "zh-cn": "未找到购买记录。",
      "zh-tw": "找不到購買記錄。",
    },
    delete: {
      de: "Konto löschen",
      en: "Delete Account",
      fr: "Supprimer le compte",
      "pt-br": "Excluir conta",
      "zh-cn": "删除账号",
      "zh-tw": "刪除賬號",
      es: "Borrar la cuenta",
      ja: "アカウント削除",
    },
    confirmDeleteAccount: {
      de: "Das Löschen Ihres Kontos ist endgültig und entfernt alle mit Ihrem Benutzer verknüpften Daten, einschließlich Decks, Karten und Antworten. Um zu bestätigen, dass Sie fortfahren und Ihr Konto LÖSCHEN möchten, geben Sie Ihr Passwort unten ein:",
      en: "Deleting your account is PERMANENT and will erase all data associated with your user, including decks, cards, and responses. To confirm you wish to continue and DELETE your account, type your password below:",
      fr: "La suppression de votre compte est PERMANENTE et effacera toutes les données associées à votre utilisateur, y compris les jeux, les cartes et les réponses. Pour confirmer que vous souhaitez continuer et SUPPRIMER votre compte, tapez votre mot de passe ci-dessous :",
      "zh-cn":
        "删除您的帐户是永久性的，并将删除与您的用户相关的所有数据，包括暗记包、卡片和响应。要确认您希望继续删除您的帐户，请在下面键入您的密码：",
      "zh-tw":
        "刪除您的帳戶是永久性的，並將刪除與您的用戶相關的所有數據，包括暗記包、卡片和回應。要確認您希望繼續刪除您的帳戶，請在下面鍵入您的密碼：",
      es: "La eliminación de su cuenta es PERMANENTE y borrará todos los datos asociados a su usuario, incluyendo barajas, cartas y respuestas. Para confirmar que desea continuar y ELIMINAR su cuenta, escriba su contraseña a continuación:",
      ja: "アカウントの削除は永久的なものであり、デッキ、カード、回答など、あなたのユーザーに関連するすべてのデータが消去されます。アカウントの削除と継続を確認するには、以下にパスワードを入力してください。",
      "pt-br":
        "Excluir sua conta é PERMANENTE e apagará todos os dados associados ao seu usuário, incluindo decks, cartões e respostas. Para confirmar que deseja continuar e EXCLUIR sua conta, digite sua senha abaixo:",
    },
    errorDeletingAccount: {
      de: "Fehler beim Löschen des Kontos. Überprüfen Sie, ob das eingegebene Passwort korrekt ist und versuchen Sie es erneut.",
      en: "Error deleting account. Verify the password entered is correct and try again.",
      fr: "Erreur lors de la suppression du compte. Vérifiez que le mot de passe saisi est correct et réessayez.",
      "pt-br":
        "Erro ao excluir a conta. Verifique se a senha digitada está correta e tente novamente.",
      "zh-cn": "错误删除帐户。验证输入的密码是否正确，然后重试。",
      "zh-tw": "錯誤刪除帳戶。驗證輸入的密碼是否正確，然後重試。",
      es: "Error al eliminar la cuenta. Compruebe que la contraseña introducida es correcta y vuelva a intentarlo.",
      ja: "アカウントの削除に失敗しました。入力されたパスワードが正しいかどうか確認し、もう一度やり直してください。",
    },
    userSince: {
      de: "Nutzer seit",
      en: "User Since",
      fr: "Utilisateur depuis",
      "pt-br": "Usuário Desde",
      "zh-cn": "注册日期",
      "zh-tw": "註冊日期",
      es: "Usuario desde",
      ja: "ユーザー以来",
    },
    manageSubscription: {
      de: "Abonnement verwalten",
      en: "Manage Subscription",
      fr: "Gérer l'abonnement",
      "pt-br": "Gerenciar assinatura",
      "zh-cn": "订阅管理",
      "zh-tw": "訂閱管理",
      es: "Administrar suscripción",
      ja: "サブスクリプションを管理する",
    },
    subscriptionRenewal: {
      de: "Abonnementserneuerung",
      en: "Subscription Renewal",
      fr: "Renouvellement de l'abonnement",
      "pt-br": "Renovação de Assinatura",
      "zh-cn": "订阅续费",
      "zh-tw": "訂閱續費",
      es: "Renovación de Suscripción",
      ja: "サブスクリプションの更新",
    },
    subscriptionExpiring: {
      de: "Abonnement läuft ab",
      en: "Subscription Expiring",
      fr: "Abonnement expirant",
      "pt-br": "Assinatura expirando",
      "zh-cn": "订阅即将到期",
      "zh-tw": "訂閱即將到期",
      es: "Suscripción a punto de expirar",
      ja: "サブスクリプションの期限が切れます",
    },
    subscriptionExpired: {
      de: "Abonnement abgelaufen",
      en: "Subscription Expired",
      fr: "Abonnement expiré",
      "pt-br": "Assinatura expirada",
      "zh-cn": "订阅已到期",
      "zh-tw": "訂閱已到期",
      es: "Suscripción expirada",
      ja: "サブスクリプションの期限が切れました",
    },
    deleteDeviceConfirm: {
      en: "Are you sure you want to delete this device? All unsynced data on the selected device will be deleted.",
      de: "Sind Sie sicher, dass Sie dieses Gerät löschen möchten? Alle nicht synchronisierten Daten auf dem ausgewählten Gerät werden gelöscht.",
      es: "¿Estás seguro de que quieres eliminar este dispositivo? Todos los datos no sincronizados en el dispositivo seleccionado se borrarán.",
      fr: "Êtes-vous sûr de vouloir supprimer cet appareil ? Toutes les données non synchronisées sur l'appareil sélectionné seront supprimées.",
      "pt-br":
        "Você tem certeza de que deseja excluir este dispositivo? Todos os dados não sincronizados no dispositivo selecionado serão excluídos.",
      "zh-cn": "您确定要删除这个设备吗？所选设备上的所有未同步数据将被删除。",
      "zh-tw": "您確定要刪除這個設備嗎？所選設備上的所有未同步數據將被刪除。",
      ja: "このデバイスを削除してもよろしいですか？選択されたデバイス上の未同期データはすべて削除されます。",
    },
    autoRenewNotice: {
      en: "It will auto-renew unless canceled or changed.",
      de: "Es wird automatisch verlängert, sofern nicht gekündigt oder geändert.",
      es: "Se renovará automáticamente a menos que se cancele o cambie.",
      fr: "Il sera automatiquement renouvelé sauf annulation ou modification.",
      "pt-br": "Ele será renovado automaticamente, a menos que seja cancelado ou alterado.",
      "zh-cn": "除非取消或更改，否则将自动续订。",
      "zh-tw": "除非取消或更改，否則將自動續訂。",
      ja: "キャンセルまたは変更されない限り、自動更新されます。",
    },
    nonAutoRenewNotice: {
      en: "It will not auto-renew. Consider renewing manually to continue receiving benefits.",
      de: "Es wird nicht automatisch verlängert. Erwägen Sie eine manuelle Verlängerung, um weiterhin Vorteile zu erhalten.",
      es: "No se renovará automáticamente. Considere renovarlo manualmente para seguir recibiendo beneficios.",
      fr: "Il ne sera pas renouvelé automatiquement. Pensez à le renouveler manuellement pour continuer à bénéficier des avantages.",
      "pt-br":
        "Não será renovado automaticamente. Considere renovar manualmente para continuar recebendo benefícios.",
      "zh-cn": "它不会自动续订。考虑手动续订以继续获得好处。",
      "zh-tw": "它不會自動續訂。考慮手動續訂以繼續獲得好處。",
      ja: "それは自動的に更新されません。引き続き特典を受けるには、手動で更新することを検討してください。",
    },
  },
  sync: {
    inProgress: {
      en: "Syncing...",
      de: "Wird synchronisiert...",
      es: "Sincronización...",
      fr: "Synchronisation...",
      "pt-br": "Sincronizando...",
      "zh-cn": "同步中。。。",
      "zh-tw": "同步中。。。",
      ja: "同期中...",
    },
    error: {
      en: "Error",
      de: "Fehler",
      es: "Error",
      fr: "Erreur",
      "pt-br": "Erro",
      "zh-cn": "错误",
      "zh-tw": "錯誤",
      ja: "エラー",
    },
    offline: {
      en: "Offline",
      de: "Offline",
      es: "Fuera de línea",
      fr: "Hors ligne",
      "pt-br": "Offline",
      "zh-cn": "离线",
      "zh-tw": "離線",
      ja: "オフライン",
    },
    lastSync: {
      de: "Letzte Synchronisation",
      en: "Last Sync",
      fr: "Dernière synchro",
      "pt-br": "Última Sincrinização",
      "zh-cn": "上次同步",
      "zh-tw": "上次同步",
      es: "Última sincronización",
      ja: "最後の同期",
    },
    status: {
      en: "Sync Status",
      de: "Synchronisierungsstatus",
      es: "Estado de la sincronización",
      fr: "Statut de la synchronisation",
      "pt-br": "Status de sincronização",
      "zh-cn": "同步状态",
      "zh-tw": "同步狀態",
      ja: "シンクの状態",
    },
    now: {
      de: "Jetzt synchronisieren",
      en: "Sync Now",
      fr: "Sync Now",
      "pt-br": "Sincronizar Now",
      "zh-cn": "现在同步",
      "zh-tw": "現在同步",
      es: "Sincronizar ahora",
      ja: "シンク・ナウ",
    },
    otherDevices: {
      de: "Weitere Geräte",
      en: "Other Devices",
      fr: "Autres dispositifs",
      "pt-br": "Outros Dispositivos",
      "zh-cn": "其他设备",
      "zh-tw": "其他設備",
      es: "Otros dispositivos",
      ja: "その他のデバイス",
    },
    clientError400: {
      de: "Ein Fehler während der Synchronisierung ist aufgetreten. Aus- und erneutes Einloggen kann helfen. Das AnkiApp Support-Team wurde über den Fehler informiert und wird ihn untersuchen.",
      en: "An error occurred while syncing. Logging out then back in may help. The AnkiApp support team has been notified of the error, and will investigate.",
      fr: "Une erreur s'est produite lors de la synchronisation. Se déconnecter puis se reconnecter peut aider. L'équipe d'assistance d'AnkiApp a été informée de l'erreur, et va enquêter.",
      "pt-br":
        "Ocorreu um erro durante a sincronização. Encerrar a sessão e refazer o login pode ajudar. A equipe de suporte da AnkiApp foi notificada do erro e irá investigar.",
      "zh-cn":
        "同步时发生错误。注销然后返回可能会有所帮助。AnkiApp 支持团队已收到错误通知，并将进行调查。",
      "zh-tw":
        "同步時發生錯誤。註銷然後返回可能會有所説明。AnkiApp 支援團隊已收到錯誤通知，並將進行調查。",
      es: "Se ha producido un error al sincronizar. Cerrar la sesión y volver a entrar puede ayudar. El equipo de soporte de AnkiApp ha sido notificado del error y lo investigará.",
      ja: "同期中にエラーが発生しました。ログアウトして再度ログインすると改善される場合があります。AnkiApp サポートチームにこのエラーを報告し、調査することになりました。",
    },
    clientError400Logout: {
      de: "Ein Fehler während der Synchronisierung ist aufgetreten. Sie werden abgemeldet. Bitte loggen Sie sich erneut ein und laden Sie Ihre Decks erneut herunter. Das AnkiApp Support-Team wurde über den Fehler informiert und wird ihn untersuchen.",
      en: "An error occurred while syncing. You will be logged out. Please log in again and re-download your decks. The AnkiApp support team has been notified of the error, and will investigate.",
      fr: "Une erreur s'est produite lors de la synchronisation. Vous allez être déconnecté. Veuillez vous reconnecter et retélécharger vos jeux. L'équipe de support d'AnkiApp a été informée de l'erreur, et va enquêter.",
      "zh-cn":
        "同步时发生错误。您将被注销。请再次登录并重新下载您的暗记包。AnkiApp 支持团队已收到错误通知，并将进行调查。",
      "zh-tw":
        "同步時發生錯誤。您將被登出。請再次登錄並重新下載您的暗記包。AnkiApp 支援團隊已收到錯誤通知，並將進行調查。",
      es: "Se ha producido un error al sincronizar. Se ha cerrado la sesión. Por favor, inicia la sesión de nuevo y vuelve a descargar tus mazos. El equipo de soporte de AnkiApp ha sido notificado del error y lo investigará.",
      ja: "同期中にエラーが発生しました。ログアウトされます。再度ログインして、デッキを再ダウンロードしてください。AnkiAppのサポートチームにこのエラーを報告し、調査することになりました。",
      "pt-br":
        "Ocorreu um erro durante a sincronização. Você será desconectado. Por favor, faça login novamente e rebaixe seus decks. A equipe de suporte do AnkiApp foi notificada do erro e irá investigar.",
    },
    serverError500: {
      de: "Ein vorübergehender Fehler ist während des Synchronisierens aufgetreten. Das AnkiApp Support-Team wurde informiert und wird den Fehler untersuchen. Wenn dieser Fehler weiterhin besteht, loggen Sie sich bitte aus und wieder ein.",
      en: "A temporary error occurred while syncing. The AnkiApp support team has been notified and will investigate. If this error persists, please log out and log back in again.",
      fr: "Une erreur temporaire s'est produite lors de la synchronisation. L'équipe de support d'AnkiApp a été informée et va enquêter. Si cette erreur persiste, veuillez vous déconnecter et vous reconnecter.",
      "pt-br":
        "Ocorreu um erro temporário durante a sincronização. A equipe de suporte da AnkiApp foi notificada e irá investigar. Se este erro persistir, favor encerrar a sessão e fazer o login novamente.",
      "zh-cn":
        "同步时会出现临时错误。暗记App团队已收到通知，并将进行调查。如果此错误仍然存在，请注销并重新登录。",
      "zh-tw":
        "同步時會出現臨時錯誤。AnkiApp團隊已收到通知，並將進行調查。如果此錯誤仍然存在，請註銷並重新登錄。",
      es: "Se ha producido un error temporal durante la sincronización. El equipo de soporte de AnkiApp ha sido notificado y lo investigará. Si este error persiste, cierra la sesión y vuelve a iniciarla.",
      ja: "同期中に一時的なエラーが発生しました。AnkiApp サポートチームに通知され、調査されることになりました。このエラーが続く場合は、一度ログアウトして、再度ログインしてください。",
    },
  },
  sort: {
    createdAt: {
      de: "Erstelldatum",
      en: "Created At",
      fr: "Créé à",
      "pt-br": "Criado em",
      "zh-cn": "创建时间",
      "zh-tw": "創建時間",
      es: "Creado en",
      ja: "クリエイテッドアット",
    },
    modifiedAt: {
      en: "Modified At",
      es: "Modificado en",
      "pt-br": "Modificado em",
      de: "Änderungsdatum",
      fr: "Modifié à",
      "zh-cn": "修改时间",
      "zh-tw": "修改時間",
      ja: "変更日時",
    },
    lastSeen: {
      de: "Zuletzt überprüft",
      en: "Last Reviewed",
      fr: "Dernière révision",
      "pt-br": "Última revisão",
      "zh-cn": "上次复习时间",
      "zh-tw": "上次復習時間",
      es: "Última revisión",
      ja: "最終レビュー",
    },
    name: {
      de: "Name (Alle)",
      en: "Name (All)",
      fr: "Nom (Tous)",
      "pt-br": "Nome (Todos)",
      "zh-cn": "名称（全部）",
      "zh-tw": "名稱（全部）",
      es: "Nombre (Todos)",
      ja: "名称（すべて）",
    },
    nameFoldersFirst: {
      de: "Name (Ordner zuerst)",
      en: "Name (Folders First)",
      fr: "Nom (Dossiers d'abord)",
      "pt-br": "Nome (Pastas primeiro)",
      "zh-cn": "名称（文件夹优先）",
      "zh-tw": "名稱（文件夾優先）",
      es: "Nombre (Carpetas primero)",
      ja: "名称（フォルダー優先）",
    },
    nameDecksFirst: {
      de: "Name (Decks zuerst)",
      en: "Name (Decks First)",
      fr: "Nom (Paquets d'abord)",
      "pt-br": "Nome (Baralhos primeiro)",
      "zh-cn": "名称（套牌优先）",
      "zh-tw": "名稱（套牌優先）",
      es: "Nombre (Mazos primero)",
      ja: "名称（デッキ優先）",
    },
    score: {
      de: "Punktestand",
      en: "Score",
      fr: "Score",
      "pt-br": "Pontuação",
      "zh-cn": "分数",
      "zh-tw": "分數",
      es: "Puntuación",
      ja: "スコア",
    },
  },
  create: {
    instructions: {
      en: "Choose deck to add a new card to.",
      de: "Wählen Sie ein Deck, zu dem Sie eine neue Karte hinzufügen möchten.",
      es: "Elige el mazo al que añadir una nueva carta.",
      fr: "Choisissez le jeu auquel vous souhaitez ajouter une nouvelle carte.",
      "zh-cn": "选择暗记包添加新卡。",
      "zh-tw": "選擇暗記包添加新卡。",
      ja: "新しいカードを追加するデッキを選択します。",
      "pt-br": "Escolha o deck para adicionar um novo cartão.",
    },
  },
  inbox: {
    bodyTip: {
      en: "After you've saved an image to your Inbox, click on it to generate cards to study.",
      es: "Cuando hayas guardado una imagen en tu bandeja de entrada, haz clic en ella para generar tarjetas para estudiar.",
      fr: "Après avoir enregistré une image dans votre boîte de réception, cliquez dessus pour générer des cartes à étudier.",
      ja: "受信トレイに画像を保存した後、その画像をクリックすると、学習用のカードが作成されます。",
      de: "Nachdem Sie ein Bild in Ihrem Posteingang gespeichert haben, klicken Sie darauf, um Karten zum Lernen zu erstellen.",
      "pt-br":
        "Depois de salvar uma imagem na sua Caixa de Entrada, clique nela para gerar cartões para estudar.",
      "zh-cn": "将图像保存到您的收件箱后，点击它生成暗记卡进行学习。",
      "zh-tw": "將圖像儲存到你的收件箱後，點擊它生成要學習的暗記卡。",
    },
    deckSelectModalTip: {
      en: "Choose a deck to place generated cards into.",
      "pt-br": "Escolha um deck para colocar os cartões gerados.",
      es: "Elige un mazo en el que colocar las cartas generadas.",
      ja: "生成されたカードを入れるデッキを選ぶ。",
      de: "Wähle einen Stapel, in den du die erzeugten Karten legst.",
      fr: "Choisissez une pioche dans laquelle vous placerez les cartes générées.",
      "zh-cn": "选择一个暗记包放入生成的暗记卡。",
      "zh-tw": "選擇一個暗記包來放置生成的暗記卡。",
    },
    empty: {
      en: "Use the Inbox to quickly capture materials you want to make flashcards from. You can generate cards from those pictures at any time.<br /><br />Use it for lecture notes, course materials, signs and menus while traveling, etc...",
      "pt-br":
        "Use a Caixa de entrada para capturar rapidamente materiais dos quais você deseja criar flashcards. Depois, você pode gerar cartões a partir dessas imagens a qualquer momento.<br /><br />Use-o para anotações de palestras, materiais de cursos, placas e cardápios em viagens, etc...",
      ja: "受信トレイを使って、フラッシュカードを作りたい素材を素早く取り込みましょう。その写真からいつでもカードを作成できます。講義のノート、授業資料、旅行中の標識やメニューなど...。",
      fr: "Utilisez la boîte de réception pour capturer rapidement les documents à partir desquels vous souhaitez créer des flashcards.<br /><br />Vous pouvez ensuite générer des cartes à partir de ces images à tout moment. Utilisez-le pour des notes de cours, du matériel pédagogique, des panneaux et des menus lorsque vous voyagez, etc...",
      de: "Verwenden Sie den Posteingang, um schnell Materialien zu erfassen, aus denen Sie Karteikarten erstellen möchten. Dann können Sie jederzeit Karten aus diesen Bildern erstellen. <br /><br />Verwenden Sie es für Vorlesungsnotizen, Kursmaterialien, Schilder und Speisekarten auf Reisen usw...",
      es: "Utiliza la Bandeja de entrada para capturar rápidamente materiales con los que quieras hacer tarjetas.<br /><br />Luego puedes generar tarjetas a partir de esas imágenes en cualquier momento. Úsalo para apuntes de clase, materiales de cursos, señales y menús mientras viajas, etc...",
      "zh-cn": "将图片添加到您的收件箱中，随时可以从中生成卡片。",
      "zh-tw": "將圖片添加到您的收件箱中，隨時可以從中生成卡片。",
    },
  },
  actions: {
    study: {
      en: "Study",
      "zh-cn": "学习",
      fr: "Réviser",
      es: "Estudiar",
      de: "Lernen",
      "pt-br": "Estudar",
      ja: "学習",
      "zh-tw": "學習",
    },
    preview: {
      en: "Preview",
      "pt-br": "Visualizar",
      ja: "プレビュー",
      fr: "Avant-première",
      de: "Vorschau",
      es: "Vista previa",
      "zh-cn": "预览",
      "zh-tw": "預覽",
    },
    select: {
      en: "Select",
      "pt-br": "Selecionar",
      fr: "Sélectionner",
      ja: "選択",
      de: "Wählen Sie",
      es: "Seleccione",
      "zh-cn": "选择",
      "zh-tw": "選擇",
    },
    confirmDelete: {
      en: "Are you sure? This cannot be undone.",
      fr: "En êtes-vous sûr ? Cela ne peut pas être annulé.",
      de: "Sind Sie sicher? Das kann nicht rückgängig gemacht werden.",
      es: "¿Está seguro? Esto no se puede deshacer.",
      ja: "本当ですか？これは元に戻せない。",
      "pt-br": "Tem certeza? Isso não pode ser desfeito.",
      "zh-cn": "您确定吗？此操作无法撤销。",
      "zh-tw": "您確定嗎？此操作無法撤銷。",
    },
    addToInbox: {
      en: "Add to Inbox",
      de: "Zum Posteingang hinzufügen",
      fr: "Ajouter à la boîte de réception",
      "pt-br": "Adicionar à caixa de entrada",
      es: "Añadir a la bandeja de entrada",
      ja: "受信トレイに追加",
      "zh-cn": "添加到收件箱",
      "zh-tw": "添加到收件箱",
    },
    generate: {
      en: "Generate",
      es: "Genere",
      ja: "生成する",
      de: "Erzeugen Sie",
      "pt-br": "Gerar",
      fr: "Générer",
      "zh-cn": "生成",
      "zh-tw": "生成",
    },
    retry: {
      en: "Retry",
      "pt-br": "Repetir",
      fr: "Réessayer",
      es: "Reintentar",
      de: "Wiederholung",
      ja: "リトライ",
      "zh-cn": "重试",
      "zh-tw": "重試",
    },
    export: {
      en: "Export",
      de: "Exportieren",
      ja: "輸出",
      es: "Exportar",
      fr: "Exportation",
      "pt-br": "Exportação",
      "zh-cn": "导出",
      "zh-tw": "導出",
    },
    choose: {
      en: "Choose",
      fr: "Choisissez",
      "pt-br": "Escolher",
      de: "Wählen",
      ja: "選ぶ",
      es: "Elija",
      "zh-cn": "选择",
      "zh-tw": "選擇",
    },
    upgrade: {
      en: "Upgrade",
      de: "Aktualisieren",
      es: "Actualizar",
      fr: "Mise à niveau",
      ja: "アップグレード",
      "pt-br": "Atualizar",
      "zh-cn": "升级",
      "zh-tw": "升級",
    },
    accept: {
      en: "Accept",
      de: "Akzeptieren",
      es: "Aceptar",
      fr: "Accepter",
      "pt-br": "Aceitar",
      ja: "受入",
      "zh-cn": "接受",
      "zh-tw": "接受",
    },
    reject: {
      en: "Reject",
      de: "Ablehnen",
      es: "Rechazar",
      fr: "Rejeter",
      "pt-br": "Rejeitar",
      ja: "拒否する",
      "zh-cn": "拒绝",
      "zh-tw": "拒絕",
    },
    close: {
      en: "Close",
      de: "Schließen",
      es: "Cerrar",
      fr: "Fermer",
      "pt-br": "Fechar",
      "zh-cn": "关闭",
      "zh-tw": "關閉",
      ja: "閉じる",
    },
    skip: {
      en: "Skip",
      de: "Überspringen",
      es: "Saltar",
      fr: "Skip",
      "pt-br": "Pular",
      "zh-cn": "跳",
      "zh-tw": "跳",
      ja: "スキップ",
    },
    rename: {
      en: "Rename",
      de: "Umbenennen",
      es: "Cambiar el nombre de",
      fr: "Renommer",
      "pt-br": "Renomear",
      "zh-cn": "重命名",
      "zh-tw": "重新命名",
      ja: "リネーム",
    },
    change: {
      en: "change",
      de: "Ändern",
      es: "cambiar",
      fr: "changement",
      "pt-br": "Mudar",
      "zh-cn": "更改",
      "zh-tw": "更改",
      ja: "かわりめ",
    },
    add: {
      de: "Hinzufügen",
      en: "Add",
      fr: "Ajouter",
      "pt-br": "Adicionar",
      "zh-cn": "添加",
      "zh-tw": "添加",
      es: "Añadir",
      ja: "追加",
    },
    copy: {
      en: "Copy",
      de: "Kopieren",
      es: "Copiar",
      fr: "Copie",
      "pt-br": "Copiar",
      "zh-cn": "复制",
      "zh-tw": "複製",
      ja: "コピー",
    },
    edit: {
      de: "Bearbeiten",
      en: "Edit",
      fr: "Modifier",
      "pt-br": "Editar",
      "zh-cn": "编辑",
      "zh-tw": "編輯",
      es: "Editar",
      ja: "変える",
    },
    unstar: {
      de: "Entfavorisieren",
      en: "Un-star",
      fr: "Désactiver favori",
      "pt-br": "Desfavoritar",
      "zh-cn": "去星号",
      "zh-tw": "去星號",
      es: "Desaparece la estrella",
      ja: "アンスター",
    },
    star: {
      de: "Favorisieren",
      en: "Star",
      fr: "Favori",
      "pt-br": "Favoritar",
      "zh-cn": "加星号",
      "zh-tw": "加星號",
      es: "Estrella",
      ja: "星",
    },
    unignore: {
      de: "Nicht ignorieren",
      en: "Unignore",
      fr: "Désamorcer",
      "pt-br": "Não ignorar",
      "zh-cn": "不忽略",
      "zh-tw": "不忽略",
      es: "Unignore",
      ja: "アンノア",
    },
    ignore: {
      de: "Ignorieren",
      en: "Ignore",
      fr: "Ignorer",
      "pt-br": "Ignorar",
      "zh-cn": "忽略",
      "zh-tw": "忽略",
      es: "Ignora",
      ja: "無視",
    },
    reportError: {
      de: "Fehler melden",
      en: "Report Error",
      fr: "Rapport d'erreur",
      "pt-br": "Relatar erro",
      "zh-cn": "举报错误",
      "zh-tw": "舉報錯誤",
      es: "Informar de un error",
      ja: "エラー報告",
    },
    selectPhoto: {
      en: "Select Photo",
      "pt-br": "Selecionar Foto",
      de: "Foto auswählen",
      ja: "写真を選ぶ",
      es: "Seleccionar foto",
      fr: "Sélectionner une photo",
      "zh-cn": "选择照片",
      "zh-tw": "選擇相片",
    },
    takePicture: {
      en: "Take Picture",
      "pt-br": "Tirar Foto",
      ja: "写真を撮る",
      fr: "Prendre une photo",
      de: "Bild aufnehmen",
      es: "Tomar foto",
      "zh-tw": "拍照",
      "zh-cn": "拍照",
    },
    new: {
      de: "Neu",
      en: "New",
      fr: "Nouveau",
      "pt-br": "Novo",
      "zh-cn": "创建",
      "zh-tw": "創建",
      es: "Nuevo",
      ja: "新",
    },
    create: {
      en: "Create",
      de: "Erstellen",
      es: "Crear",
      fr: "Créer",
      "pt-br": "Criar",
      "zh-cn": "创造",
      "zh-tw": "創造",
      ja: "作成",
    },
    delete: {
      de: "Löschen",
      en: "Delete",
      fr: "Supprimer",
      "pt-br": "Excluir",
      "zh-cn": "删除",
      "zh-tw": "刪除",
      es: "Borrar",
      ja: "削除",
    },
    remove: {
      de: "Entfernen",
      en: "Remove",
      fr: "Retirer",
      "pt-br": "Remover",
      "zh-cn": "删除",
      "zh-tw": "刪除",
      es: "Eliminar",
      ja: "削除",
    },
    move: {
      en: "Move",
      es: "Mover",
      de: "Verschieben",
      "pt-br": "Mover",
      fr: "Déplacer",
      ja: "移動",
      "zh-cn": "移动",
      "zh-tw": "移動",
    },
    search: {
      de: "Suchen",
      en: "Search",
      fr: "Recherche",
      "pt-br": "Pesquisar",
      "zh-cn": "搜索",
      "zh-tw": "搜索",
      es: "Busque en",
      ja: "検索",
    },
    share: {
      de: "Teilen",
      en: "Share",
      fr: "Partager",
      "pt-br": "Compartilhar",
      "zh-cn": "分享",
      "zh-tw": "分享",
      es: "Compartir",
      ja: "シェア",
    },
    continue: {
      de: "Fortfahren",
      en: "Continue",
      fr: "Continuer",
      "pt-br": "Continuar",
      "zh-cn": "继续",
      "zh-tw": "繼續",
      es: "Continuar",
      ja: "継続",
    },
    browse: {
      de: "Durchsuchen",
      en: "Browse",
      fr: "Parcourir",
      "pt-br": "Navegar",
      "zh-cn": "浏览",
      "zh-tw": "瀏覽",
      es: "Visite",
      ja: "ブラウズ",
    },
    download: {
      de: "Herunterladen",
      en: "Download",
      fr: "Télécharger",
      "pt-br": "Baixar",
      "zh-cn": "下载",
      "zh-tw": "下載",
      es: "Descargar",
      ja: "ダウンロード",
    },
    record: {
      en: "Record",
      de: "Aufzeichnen",
      es: "Registrar",
      fr: "Enregistrer",
      ja: "記録",
      "pt-br": "Gravar",
      "zh-cn": "记录",
      "zh-tw": "記錄",
    },
    logout: {
      de: "Abmelden",
      en: "Logout",
      fr: "Déconnexion",
      "pt-br": "Encerrar sessão",
      "zh-cn": "登出",
      "zh-tw": "登出",
      es: "Cierre de sesión",
      ja: "ログアウト",
    },
    showMore: {
      en: "Show more...",
      de: "Mehr anzeigen...",
      es: "Mostrar más...",
      fr: "Afficher plus...",
      ja: "もっと見る...",
      "pt-br": "Mostrar mais...",
      "zh-cn": "显示更多...",
      "zh-tw": "顯示更多...",
    },
    cancel: {
      de: "Abbrechen",
      en: "Cancel",
      fr: "Annuler",
      "pt-br": "Cancelar",
      "zh-cn": "取消",
      "zh-tw": "取消",
      es: "Cancelar",
      ja: "キャンセル",
    },
    confirm: {
      de: "Bestätigen",
      en: "Confirm",
      fr: "Confirmer",
      "pt-br": "Confirmar",
      "zh-cn": "确认",
      "zh-tw": "確認",
      es: "Confirmar",
      ja: "確認",
    },
    ok: {
      de: "OK",
      en: "OK",
      fr: "OK",
      "pt-br": "OK",
      "zh-cn": "确定",
      "zh-tw": "確定",
      es: "OK",
      ja: "よっしゃー",
    },
    save: {
      de: "Speichern",
      en: "Save",
      fr: "Sauvez",
      "pt-br": "Salvar",
      "zh-cn": "保存",
      "zh-tw": "保存",
      es: "Guardar",
      ja: "保存",
    },
    flip: {
      de: "Umdrehen",
      en: "Flip",
      fr: "Retourner",
      "pt-br": "Virar",
      "zh-cn": "显示答案",
      "zh-tw": "顯示答案",
      es: "Voltea",
      ja: "裏返す",
    },
    pause: {
      en: "Pause",
      de: "Pause",
      fr: "Pause",
      es: "Pausa",
      ja: "一時停止",
      "pt-br": "Pausar",
      "zh-cn": "暂停",
      "zh-tw": "暫停",
    },
    resume: {
      en: "Resume",
      de: "Fortsetzen",
      fr: "Reprendre",
      es: "Reanudar",
      ja: "再開",
      "pt-br": "Retomar",
      "zh-cn": "继续",
      "zh-tw": "繼續",
    },
    open: {
      de: "Öffnen",
      en: "Open",
      fr: "Ouvrir",
      "pt-br": "Abrir",
      "zh-cn": "打开",
      "zh-tw": "打開",
      es: "Abrir",
      ja: "オープン",
    },
    contact: {
      de: "Kontaktieren",
      en: "Contact",
      fr: "Contact",
      "pt-br": "Contato",
      "zh-cn": "联系客服",
      "zh-tw": "聯繫客服",
      es: "Contact",
      ja: "お問い合わせ先",
    },
    reset: {
      de: "Zurücksetzen",
      en: "Reset",
      fr: "Réinitialisation",
      "pt-br": "Resetar",
      "zh-cn": "重设",
      "zh-tw": "重設",
      es: "Reiniciar",
      ja: "リセット",
    },
    send: {
      de: "Senden",
      en: "Send",
      fr: "Envoyer",
      "pt-br": "Enviar",
      "zh-cn": "发送",
      "zh-tw": "發送",
      es: "Enviar",
      ja: "送信",
    },
    saved: {
      de: "Gespeichert",
      en: "Saved",
      fr: "Sauvegardé",
      "pt-br": "Salvo",
      "zh-cn": "已保存",
      "zh-tw": "已保存",
      es: "Guardado",
      ja: "セーブ",
    },
    refresh: {
      de: "Aktualisieren",
      en: "Refresh",
      fr: "Rafraîchir",
      "pt-br": "Atualizar",
      "zh-cn": "刷新",
      "zh-tw": "刷新",
      es: "Actualizar",
      ja: "リフレッシュ",
    },
  },
  error: {
    temporary: {
      en: "A temporary error occurred. Please try again.",
      ja: "一時的なエラーが発生しました。もう一度お試しください。",
      de: "Es ist ein temporärer Fehler aufgetreten. Bitte versuchen Sie es erneut.",
      fr: "Une erreur temporaire s'est produite. Veuillez réessayer.",
      es: "Se ha producido un error temporal. Por favor, inténtelo de nuevo.",
      "pt-br": "Ocorreu um erro temporário. Por favor, tente novamente.",
      "zh-cn": "发生临时错误。请重试。",
      "zh-tw": "發生暫時性錯誤。請再試一次。",
    },
    translation: {
      en: "Automatic translation failed.",
      de: "Automatische Übersetzung fehlgeschlagen.",
      es: "Ha fallado la traducción automática.",
      ja: "自動翻訳に失敗しました。",
      "pt-br": "Tradução automática falhou.",
      fr: "La traduction automatique a échoué.",
      "zh-cn": "自动翻译失败。",
      "zh-tw": "自動翻譯失敗。",
    },
    communication: {
      de: "Fehler bei der Kommunikation mit dem Server. Überprüfen Sie Ihre Netzwerkverbindung und versuchen Sie es erneut.",
      en: "Error communicating with server. Check your network connection and try again.",
      fr: "Erreur de communication avec le serveur. Vérifiez votre connexion réseau et réessayez.",
      "pt-br":
        "Erro de comunicação com o servidor. Verifique sua conexão de rede e tente novamente.",
      "zh-cn": "发送请求失败",
      "zh-tw": "發送請求失敗",
      es: "Error de comunicación con el servidor. Compruebe su conexión de red y vuelva a intentarlo.",
      ja: "サーバーとの通信にエラーが発生しました。ネットワーク接続を確認し、再度お試しください。",
    },
    internal: {
      de: "Interner Fehler. Kontaktieren Sie den Support für Hilfe.",
      en: "Internal error. Contact support for help.",
      fr: "Erreur interne. Contactez le support pour obtenir de l'aide.",
      "pt-br": "Erro interno. Contate o suporte para obter ajuda.",
      "zh-cn": "发生错误: 如需帮助请联系客服.",
      "zh-tw": "發生錯誤: 如需幫助請聯繫客服.",
      es: "Error interno. Póngase en contacto con el servicio de asistencia para obtener ayuda.",
      ja: "内部エラーです。サポートにお問い合わせください。",
    },
    fileTooLarge: {
      de: "Datei zu groß.",
      en: "File too large.",
      fr: "Le fichier est trop volumineux.",
      "pt-br": "Arquivo muito grande.",
      "zh-cn": "错误：文件过大",
      "zh-tw": "錯誤：文件過大",
      es: "Archivo demasiado grande.",
      ja: "ファイルサイズが大きすぎます。",
    },
    fileNotSupported: {
      de: "Dateityp nicht unterstützt.",
      en: "File type not supported.",
      fr: "Le type de fichier n'est pas pris en charge.",
      "pt-br": "Tipo de arquivo não suportado.",
      "zh-cn": "错误：文件格式未被支持",
      "zh-tw": "錯誤：文件格式未被支持",
      es: "Tipo de archivo no admitido.",
      ja: "ファイルの種類がサポートされていません。",
    },
    databaseUnOpenable: {
      en: "Failed to open database. Please reload app and try again. If that doesn't work, contact support@ankiapp.com for help.",
      de: "Datenbank kann nicht geöffnet werden. Bitte laden Sie die App neu und versuchen Sie es erneut. Wenn das nicht funktioniert, kontaktieren Sie bitte support@ankiapp.com für Hilfe.",
      es: "No se ha podido abrir la base de datos. Por favor, recargue la aplicación e inténtelo de nuevo. Si esto no funciona, contacta con support@ankiapp.com para obtener ayuda.",
      fr: "Impossible d'ouvrir la base de données. Veuillez recharger l'application et réessayer. Si cela ne fonctionne pas, contactez support@ankiapp.com pour obtenir de l'aide.",
      "pt-br":
        "Falha em abrir o banco de dados. Por favor, feche e abra o aplicativo e tente novamente. Se isso não funcionar, entre em contato com support@ankiapp.com para obter ajuda.",
      ja: "データベースを開くのに失敗しました。アプリを再読み込みして、もう一度試してください。それでもうまくいかない場合は、support@ankiapp.com までお問い合わせください。",
      "zh-cn":
        "无法打开数据库。 请重新加载应用程序并重试。 如果这不起作用，请联系 support@ankiapp.com 寻求帮助。",
      "zh-tw":
        "無法打開數據庫。 請重新加載應用程序並重試。 如果這不起作用，請聯繫 support@ankiapp.com 尋求幫助。",
    },
    databaseCorrupt: {
      de: "Datenbank ist fehlerhaft. Sie werden abgemeldet. Bitte melden Sie sich erneut an und laden Sie Ihre Decks erneut herunter. Das AnkiApp-Support-Team wurde über den Fehler informiert und wird ihn untersuchen.",
      en: "Database is in an invalid state. You will be logged out. Please log in again and re-download your decks. The AnkiApp support team has been notified of the error, and will investigate.",
      fr: "La base de données est dans un état invalide. Vous allez être déconnecté. Veuillez vous reconnecter et retélécharger vos jeux. L'équipe de support d'AnkiApp a été informée de l'erreur et va enquêter.",
      "zh-cn":
        "数据库处于无效状态。您将被注销。请再次登录并重新下载您的暗记包。AnkiApp 支持团队已收到错误通知，并将进行调查。",
      "zh-tw":
        "資料庫處於無效狀態。您將被登出。請再次登錄並重新下載您的暗記包。AnkiApp 支援團隊已收到錯誤通知，並將進行調查。",
      es: "La base de datos está en un estado no válido. Se cerrará la sesión. Por favor, inicia la sesión de nuevo y vuelve a descargar tus mazos. El equipo de soporte de AnkiApp ha sido notificado del error y lo investigará.",
      ja: "データベースが無効な状態です。ログアウトされます。再度ログインして、デッキを再ダウンロードしてください。AnkiAppのサポートチームにこのエラーを報告し、調査することになりました。",
      "pt-br":
        "O banco de dados está em um estado inválido. Você será desconectado. Por favor, faça login novamente e rebaixe seus decks. A equipe de suporte do AnkiApp foi notificada do erro e investigará.",
    },
    downloadingDeck: {
      de: "Fehler beim Herunterladen des Decks. Das Deck könnte unvollständig sein. Bitte entfernen Sie das Deck und versuchen Sie es erneut. Wenn das nicht funktioniert, kontaktieren Sie den Support.",
      en: "Error downloading deck. Deck may be incomplete. Please remove deck and try again. If that doesn't work, contact support.",
      fr: "Erreur de téléchargement du jeu. Le jeu est peut-être incomplet. Veuillez retirer le jeu et réessayer. Si cela ne fonctionne pas, contactez l'assistance.",
      "zh-cn": "错误：暗记包下载没完成；请您删除并重新下载",
      "zh-tw": "錯誤：暗記包下載沒完成；請您刪除並重新下載。",
      es: "Error al descargar el mazo. El mazo puede estar incompleto. Por favor, elimine el mazo e inténtelo de nuevo. Si no funciona, contacta con el servicio de asistencia.",
      ja: "デッキのダウンロードに失敗しました。デッキが不完全な可能性があります。デッキを削除して、再度お試しください。それでもダメな場合は、サポートまでご連絡ください。",
      "pt-br":
        "Erro ao baixar o deck. O deck pode estar incompleto. Por favor, remova o deck e tente novamente. Se isso não funcionar, contate o suporte.",
    },
  },
  field: {
    sourceManual: {
      en: "Manual",
      de: "Manuell",
      ja: "マニュアル",
      es: "Manual",
      fr: "Manuel",
      "pt-br": "Manual",
      "zh-cn": "手动",
      "zh-tw": "手動",
    },
    sourceManualDescription: {
      en: "Enter source material manually",
      fr: "Saisir manuellement le matériel source",
      de: "Quellmaterial manuell eingeben",
      ja: "ソースを手動で入力する",
      "pt-br": "Insira material de origem manualmente",
      es: "Introducir manualmente el material de partida",
      "zh-cn": "手动输入源材料",
      "zh-tw": "輸入源材料手動",
    },
    sourceTranslationDescription: {
      en: "Translate text from another field",
      es: "Traducir texto de otro campo",
      ja: "他のフィールドのテキストを翻訳する",
      de: "Text aus einem anderen Feld übersetzen",
      fr: "Traduire le texte d'un autre champ",
      "pt-br": "Traduzir texto de outro campo",
      "zh-tw": "從另一個數據區翻譯文本",
      "zh-cn": "从另一个数据区翻译文本",
    },
    sourceReference: {
      en: "Field Reference",
      "pt-br": "Referência de Campo",
      de: "Feldreferenz",
      es: "Referencia de campo",
      fr: "Référence du champ",
      ja: "フィールドリファレンス",
      "zh-cn": "数据区参考",
      "zh-tw": "數據區參考",
    },
    sourceReferenceDescription: {
      en: "Load content from another field",
      de: "Inhalt aus einem anderen Feld laden",
      es: "Cargar contenido desde otro campo",
      ja: "他のフィールドからコンテンツを読み込む",
      fr: "Charger le contenu d'un autre champ",
      "pt-br": "Carregar conteúdo de outro campo",
      "zh-tw": "載入來自其他數據區的內容",
      "zh-cn": "从其他数据区加载内容",
    },
    sourceDescription: {
      en: "Where field content comes from",
      de: "Herkunft der Feldinhalte",
      ja: "フィールドコンテンツはどこから来るのか",
      es: "De dónde proceden los contenidos de campo",
      fr: "D'où vient le contenu des champs",
      "pt-br": "De onde vem o conteúdo do campo",
      "zh-cn": "数据区内容的来源是哪儿",
      "zh-tw": "數據區內容來源位置",
    },
    this: {
      en: "This Field",
      de: "Dieses Feld",
      ja: "この分野",
      es: "Este campo",
      fr: "Ce champ",
      "pt-br": "Este Campo",
      "zh-cn": "此数据区",
      "zh-tw": "這個數據區",
    },
    singular: {
      de: "Feld",
      en: "Field",
      fr: "Champ",
      "pt-br": "Campo",
      "zh-cn": "数据区",
      "zh-tw": "數據區",
      es: "Campo",
      ja: "フィールド",
    },
    plural: {
      de: "Felder",
      en: "Fields",
      fr: "Champs",
      "pt-br": "Campos",
      "zh-cn": "数据区",
      "zh-tw": "數據區",
      es: "Campos",
      ja: "フィールド",
    },
    source: {
      en: "Source",
      ja: "出典",
      de: "Quelle",
      es: "Fuente",
      fr: "Source :",
      "pt-br": "Fonte",
      "zh-cn": "来源",
      "zh-tw": "來源",
    },
    translationSource: {
      en: "Source",
      ja: "出典",
      de: "Quelle",
      es: "Fuente",
      fr: "Source :",
      "pt-br": "Fonte",
      "zh-cn": "来源",
      "zh-tw": "來源",
    },
    translationSourceDescription: {
      en: "Field to translate",
      "pt-br": "Campo para traduzir",
      fr: "Champ à traduire",
      de: "Zu übersetzendes Feld",
      ja: "翻訳するフィールド",
      es: "Campo a traducir",
      "zh-cn": "数据区",
      "zh-tw": "數據區",
    },
    typeLanguageDescription: {
      en: "Language-learning deck. Supports automatic translation and text-to-speech.",
      fr: "Jeu d'apprentissage des langues. Prise en charge de la traduction automatique et de la synthèse vocale.",
      es: "Cubierta de aprendizaje de idiomas. Admite traducción automática y conversión de texto a voz.",
      de: "Deck zum Erlernen von Sprachen. Unterstützt automatische Übersetzung und Text-to-Speech.",
      ja: "言語学習デッキ。自動翻訳と音声合成に対応。",
      "pt-br": "Deck de aprendizado de idiomas. Suporta tradução automática e texto para fala.",
      "zh-cn": "语言学习暗记包。支持自动翻译和文字转语音。",
      "zh-tw": "語言學習暗記包。支援自動翻譯和文字轉語音。",
    },
    visibility: {
      en: "Visibility",
      ja: "視認性",
      "pt-br": "Visibilidade",
      de: "Sichtbarkeit",
      es: "Visibilidad",
      fr: "Visibilité",
      "zh-cn": "可见性",
      "zh-tw": "可見度",
    },
    hint: {
      en: "Hint",
      de: "Hinweis",
      es: "Sugerencia",
      ja: "ヒント",
      fr: "Indice",
      "pt-br": "Dica",
      "zh-cn": "提示",
      "zh-tw": "提示",
    },
    pinyin: {
      en: "Pinyin",
      de: "Pinyin",
      ja: "Pinyin",
      es: "Pinyin",
      "pt-br": "Pinyin",
      fr: "Pinyin",
      "zh-cn": "拼音",
      "zh-tw": "拼音",
    },
    furigana: {
      en: "Furigana",
      de: "Furigana",
      ja: "フリガナ",
      es: "Furigana",
      "pt-br": "Furigana",
      fr: "Furigana",
      "zh-cn": "振假名",
      "zh-tw": "振假名",
    },
    furiganaOverrideTip: {
      en: "To edit the furigana, enter your desired furigana within 「「」」 at the end of the field. For example ”日「「ひ」」”.",
      ja: "ふりがなを編集するには、フィールドの最後にある「「」」の中に希望のふりがなを入力してください。例えば、「日「「ひ」」」のように。",
      es: 'Para editar el furigana, introduzca el furigana que desee dentro de 「「」」 al final del campo. Por ejemplo, "日「「ひ」」".',
      fr: "Pour modifier le furigana, saisissez le furigana désiré dans les 「「」」 à la fin du champ. Par exemple, '日「「ひ」」'.",
      "pt-br":
        'Para editar o furigana, insira o furigana desejado dentro de 「「」」 ao final do campo. Por exemplo "日「「ひ」」".',
      de: 'Um das Furigana zu bearbeiten, geben Sie das gewünschte Furigana innerhalb von 「「」」 am Ende des Feldes ein. Zum Beispiel "日「「ひ」」".',
      "zh-tw": '要編輯注音，請在數據區末尾的「「」」中輸入你想要的注音。例如 "日「「ひ」」"。',
      "zh-cn": "要编辑假名，在数据区末尾输入你想要的假名并用「「」」括起来。例如”日「「ひ」」”。",
    },
    hintDescription: {
      en: "Long-press Flip button to show field",
      ja: "フリップボタン長押しでフィールド表示",
      es: "Pulsación larga del botón Flip para mostrar el campo",
      fr: "Appuyez longuement sur le bouton Flip pour afficher le champ",
      "pt-br": "Pressione e segure o botão de virar para mostrar o campo",
      de: "Zum Anzeigen des Feldes lange auf die Umdrehen-Taste drücken",
      "zh-cn": "长按翻转按钮显示数据区",
      "zh-tw": "長按翻轉按鈕顯示數據區",
    },
    new: {
      de: "Neues Feld",
      en: "New Field",
      fr: "Nouveau champ",
      "pt-br": "Novo campo",
      "zh-cn": "添加数据区",
      "zh-tw": "添加數據區",
      es: "Campo nuevo",
      ja: "新分野",
    },
    type: {
      en: "Type",
      de: "Typ",
      es: "Tipo",
      fr: "Type",
      ja: "タイプ",
      "pt-br": "Tipo",
      "zh-tw": "類型",
      "zh-cn": "类型",
    },
    typeText: {
      en: "Text",
      de: "Text",
      es: "Texto",
      fr: "Texte",
      ja: "テキスト",
      "pt-br": "Texto",
      "zh-cn": "纯文本",
      "zh-tw": "純文本",
    },
    typeTextDescription: {
      en: "Plain text, as a single block.",
      de: "Einfacher Text in einem Block",
      es: "Texto simple",
      fr: "Texte en clair",
      ja: "プレーンテキスト",
      "pt-br": "Texto simples, bloco único",
      "zh-cn": "纯文本",
      "zh-tw": "純文本",
    },
    typeRichText: {
      en: "Rich Text",
      de: "Formatierter Text",
      es: "Texto enriquecido",
      fr: "Texte enrichi",
      ja: "リッチテキスト",
      "pt-br": "Texto detalhado",
      "zh-cn": "富文本",
      "zh-tw": "富文本",
    },
    typeRichTextDescription: {
      en: "For cards with rich formatting, such as inline images, lists, and text styling.",
      fr: "Pour les cartes avec un formatage riche, comme les listes et le style du texte.",
      de: "Für Karten mit umfangreicher Formatierung, wie z. B. Einbettung von Bildern, Listen und Textstilen.",
      es: "Para tarjetas con formato enriquecido, como listas y estilo de texto.",
      ja: "リストやテキストスタイルなど、リッチなフォーマットを持つカード向け。",
      "pt-br":
        "Para cartões com formatação detalha, tais como imagens, listas e estilização de texto.",
      "zh-cn": "适用于格式丰富的卡片，例如列表和文本样式。",
      "zh-tw": "適用於格式豐富的卡片，例如列表和文本樣式。",
    },
    typeChinese: {
      en: "Chinese",
      fr: "Chinois",
      es: "Chino",
      ja: "中国語",
      de: "Chinesisch",
      "pt-br": "Chinês",
      "zh-cn": "中文",
      "zh-tw": "中文",
    },
    typeChineseDescription: {
      en: "Auto word detection and pinyin generation. Supports traditional and simplified characters.",
      es: "Detección automática de palabras y generación de pinyin. Compatible con caracteres tradicionales y simplificados.",
      de: "Automatische Worterkennung und Pinyin-Erzeugung. Unterstützt traditionelle und vereinfachte Schriftzeichen.",
      ja: "単語の自動検出とピンイン生成。繁体字・簡体字に対応。",
      fr: "Détection automatique des mots et génération de pinyin. Prise en charge des caractères traditionnels et simplifiés.",
      "pt-br":
        "Detecção automática de palavras e geração de pinyin. Suporta caracteres tradicionais e simplificados.",
      "zh-cn": "自动字检测和拼音生成。 支持繁体字和简体字。",
      "zh-tw": "自動字檢測和拼音生成。 支持繁體字和簡體字。",
    },
    typeJapanese: {
      en: "Japanese",
      de: "Japanisch",
      es: "Japonés",
      fr: "Japonais",
      ja: "日本語",
      "pt-br": "Japonês",
      "zh-cn": "日文",
      "zh-tw": "日文",
    },
    typeJapaneseDescription: {
      en: "Automatic word detection and optional furigana as a hint, or always visible.",
      ja: "単語を自動検出し、オプションでふりがなをヒントとして表示、または常に表示することができます。",
      es: "Detección automática de palabras y furigana opcional como pista, o siempre visible.",
      de: "Automatische Worterkennung und optionales Furigana als Hinweis oder immer sichtbar.",
      fr: "Détection automatique des mots et furigana optionnel comme indice, ou toujours visible.",
      "pt-br":
        "Detecção automática de palavras e escolha entre furigana como uma dica, ou sempre visível.",
      "zh-cn": "自动单词检测和可选的注音假名作为提示，或始终可见。",
      "zh-tw": "自動單詞檢測和可選的注音假名作為提示，或始終可見。",
    },
    japaneseFuriganaHint: {
      en: "Furigana Hint",
      fr: "Indice Furigana",
      "pt-br": "Dica de Furigana",
      es: "Sugerencia Furigana",
      de: "Furigana-Hinweis",
      ja: "フリガナヒント",
      "zh-cn": "Furigana Hint",
      "zh-tw": "Furigana Hint",
    },
    typeTTS: {
      en: "TTS",
      de: "TTS",
      es: "TTS",
      fr: "TTS",
      ja: "TTS",
      "pt-br": "TTS",
      "zh-cn": "语音合成",
      "zh-tw": "語音合成",
    },
    typeTTSDescription: {
      en: "High fidelity spoken speech from text. Perfect for language listening comprehension.",
      es: "Voz hablada de alta fidelidad a partir de un texto. Perfecto para la comprensión oral de idiomas.",
      de: "Hochwertige Sprachausgabe aus Text. Ideal für das Hörverstehen von Sprachen.",
      fr: "Un discours parlé haute fidélité à partir d'un texte. Parfait pour la compréhension orale des langues.",
      ja: "テキストから高忠実度の音声を再生。語学の聴解に最適です。",
      "pt-br":
        "Fala de alta fidelidade a partir de texto. Perfeito para a compreensão auditiva do idioma.",
      "zh-cn": "来自文本的高保真语音。 非常适合语言听力理解。",
      "zh-tw": "來自文本的高保真語音。 非常適合語言聽力理解。",
    },
    ttsFailed: {
      en: "Text-to-Speech failed. Please ensure there is internet connectivity.",
      fr: "La synthèse vocale a échoué. Veuillez vous assurer que vous disposez d'une connexion Internet.",
      "pt-br": "Texto para Fala falhou. Por favor, certifique-se de que há conexão com a Internet.",
      es: "Error en la conversión de texto a voz. Asegúrese de que dispone de conexión a Internet.",
      ja: "音声合成に失敗しました。インターネットに接続できる状態であることを確認してください。",
      de: "Text-zu-Sprache fehlgeschlagen. Bitte stellen Sie sicher, dass eine Internetverbindung besteht.",
      "zh-cn": "文字转语音失败。 请确保有互联网连接。",
      "zh-tw": "文字轉語音失敗。 請確保有互聯網連接。",
    },
    typeTranslationDescription: {
      en: "AI (neural net) powered translation. Works for all languages.",
      de: "KI-gestützte Übersetzung mit neuronalen Netzen. Funktioniert für alle Sprachen.",
      ja: "AI（ニューラルネット）搭載の翻訳。すべての言語で動作します。",
      fr: "Traduction assistée par l'IA (réseau neuronal). Fonctionne pour toutes les langues.",
      es: "Traducción asistida por IA (red neuronal). Funciona en todos los idiomas.",
      "pt-br": "Tradução alimentada por AI (rede neural). Funciona para todos os idiomas.",
      "zh-cn": "AI（神经网络）驱动的翻译。 适用于所有语言。",
      "zh-tw": "AI（神經網絡）驅動的翻譯。 適用於所有語言。",
    },
    typeCode: {
      en: "Code",
      ja: "コード",
      es: "Código",
      de: "Code",
      "pt-br": "Código",
      fr: "Code",
      "zh-cn": "代码",
      "zh-tw": "代碼",
    },
    typeCodeDescription: {
      en: "Fixed width formatting and automatic syntax highlighting. Works with all languages.",
      ja: "固定幅のフォーマットと自動シンタックスハイライト。すべての言語で動作します。",
      fr: "Formatage à largeur fixe et coloration syntaxique automatique. Fonctionne avec toutes les langues.",
      es: "Formato de ancho fijo y resaltado automático de sintaxis. Funciona con todos los idiomas.",
      "pt-br":
        "Formatação de largura fixa e realce automático da sintaxe. Funciona com todos os idiomas.",
      de: "Formatierung mit fester Breite und automatischer Syntaxhervorhebung. Funktioniert für alle Programmiersprachen.",
      "zh-cn": "固定宽度格式和自动语法突出显示。 适用于所有语言。",
      "zh-tw": "固定寬度格式和自動語法突出顯示。 適用於所有語言。",
    },
    typeTeX: {
      en: "TeX",
      de: "TeX",
      es: "TeX",
      "pt-br": "TeX",
      ja: "TeX",
      fr: "TeX",
      "zh-cn": "TeX",
      "zh-tw": "TeX",
    },
    typeTeXDescription: {
      en: "Advanced mathmatical typesetting with TeX.",
      es: "Composición matemática avanzada con TeX.",
      de: "Fortgeschrittener mathematischer Satz mit TeX.",
      "pt-br": "Composição tipográfica avançada de fórmulas matemáticas com TeX.",
      ja: "TeXによる高度な数学的組版。",
      fr: "Composition mathématique avancée avec TeX.",
      "zh-cn": "使用 TeX 进行高级数学排版。",
      "zh-tw": "使用 TeX 進行高級數學排版。",
    },
    typeImage: {
      en: "Image",
      es: "Imagen",
      ja: "画像",
      de: "Bild",
      fr: "Image",
      "pt-br": "Imagem",
      "zh-cn": "图像",
      "zh-tw": "圖像",
    },
    typeImageDescription: {
      en: "Add an image from file or camera.",
      fr: "Ajoutez une image à partir d'un fichier ou d'un appareil photo.",
      es: "Añade una imagen desde un archivo o una cámara.",
      ja: "ファイルやカメラから画像を追加する。",
      de: "Ein Bild aus einer Datei oder Kamera hinzufügen.",
      "pt-br": "Adicionar uma imagem de arquivo ou câmera.",
      "zh-cn": "添加来自文件或相机的图像。",
      "zh-tw": "添加來自文件或相機的圖像。",
    },
    typeAudio: {
      en: "Audio",
      fr: "Audio",
      de: "Audio",
      "pt-br": "Áudio",
      es: "Audio",
      ja: "オーディオ",
      "zh-cn": "音频",
      "zh-tw": "音頻",
    },
    typeAudioDescription: {
      en: "Sound file or microphone recording.",
      ja: "サウンドファイルまたはマイク録音",
      es: "Archivo de sonido o grabación con micrófono",
      de: "Tondatei oder Mikrofonaufnahme.",
      fr: "Fichier son ou enregistrement microphonique",
      "pt-br": "Arquivo de audio, ou grvação de microfone",
      "zh-cn": "声音文件或麦克风录音。",
      "zh-tw": "聲音文件或麥克風錄音。",
    },
    confirmRemove: {
      en: "Remove field?",
      de: "Feld entfernen?",
      es: "¿Quitar el campo?",
      fr: "Supprimer le champ ?",
      ja: "フィールドを削除しますか？",
      "pt-br": "Remover campo?",
      "zh-cn": "删除字段？",
      "zh-tw": "刪除字段？",
    },
    annotationModeHintDescription: {
      en: "Long-press Flip button to show",
      es: "Pulsación larga del botón Flip para mostrar",
      fr: "Appuyez longuement sur le bouton Flip pour afficher",
      ja: "フリップボタン長押しで表示",
      de: "Langes Drücken der Umdrehen-Taste zeigt Annotationen",
      "pt-br": "Mantenha pressionado o botão Virar para revelar",
      "zh-cn": "长按翻转按钮显示",
      "zh-tw": "長按翻轉按鈕顯示",
    },
    annotationModeAlwaysDescription: {
      en: "Always show",
      ja: "常に表示する",
      fr: "Toujours montrer",
      de: "Immer Anzeigen",
      es: "Mostrar siempre",
      "pt-br": "Mostrar sempre",
      "zh-cn": "总是显示",
      "zh-tw": "總是顯示",
    },
    annotationModeBackDescription: {
      en: "Show on back of card",
      ja: "カード裏面に表示",
      fr: "Afficher au dos de la carte",
      es: "Mostrar en el reverso de la tarjeta",
      de: "Auf Rückseite der Karte anzeigen",
      "pt-br": "Mostrar no verso do cartão",
      "zh-cn": "显示在卡片背面",
      "zh-tw": "顯示在卡片背面",
    },
    annotationModeOffDescription: {
      en: "Never show",
      fr: "Ne jamais montrer",
      es: "No mostrar nunca",
      "pt-br": "Nunca mostrar",
      ja: "決して見せない",
      de: "Nicht anzeigen",
      "zh-cn": "从不显示",
      "zh-tw": "從不顯示",
    },
    ttsPreferredVoice: {
      en: "Preferred Voice",
      "pt-br": "Voz Preferida",
      es: "Voz preferida",
      fr: "Voix privilégiée",
      de: "Bevorzugte Stimme",
      ja: "プリファード・ボイス",
      "zh-tw": "首選聲音",
      "zh-cn": "首选语音",
    },
    genderMale: {
      en: "Male",
      ja: "男性",
      fr: "Homme",
      es: "Hombre",
      de: "Männlich",
      "pt-br": "Masculino",
      "zh-cn": "男",
      "zh-tw": "男",
    },
    genderFemale: {
      en: "Female",
      fr: "Femme",
      ja: "女性",
      es: "Mujer",
      "pt-br": "Feminino",
      de: "Weiblich",
      "zh-cn": "女性",
      "zh-tw": "女性",
    },
  },
  help: {
    contactInstructions: {
      de: "Bitte geben Sie alle relevanten Details an. Wenn Sie einen Fehler melden, beschleunigen die folgenden Informationen die Problembearbeitung: 1. Die Schritte, um den Fehler zu erzeugen. 2. Was Sie erwarten, dass passiert. 3. Was tatsächlich passiert.",
      en: "Please include all relevant details. <br/><br/>If you believe you have found a bug, including the following information will help us to resolve the issue faster:<br/><br/> 1. What steps you take to produce the bug.<br/> 2. What you expect to happen when you take those steps.<br/> 3. What actually happens when you take those steps.",
      fr: "Veuillez inclure tous les détails pertinents. <br/><br/>Si vous pensez avoir trouvé un bug, inclure les informations suivantes nous aidera à résoudre le problème plus rapidement :<br/><br/> 1. Les mesures que vous prenez pour produire le bug.<br/> 2. Ce que vous attendez de voir se produire lorsque vous prenez ces mesures.<br/> 3. Ce qui se produit réellement lorsque vous prenez ces mesures.",
      "pt-br":
        "Favor incluir todos os detalhes relevantes. <br/>>br/> Se você acredita ter encontrado um bug, incluir as seguintes informações nos ajudará a resolver o problema mais rapidamente:<br/>>br/>br/> 1. Que passos você tomou para causar o bug.<br/> 2. O que você espera que aconteça quando você faz esses passos.<br/> 3. O que realmente acontece quando você faz esses passos.",
      "zh-cn":
        "如果发现问题，请尽量提供以下信息以便我们尽快修复：<br/><br/> 1. 您在进行哪一步时发现此问题<br/> 2. 您在进行此步时希望达成的目的<br/> 3. 实际出现的结果",
      "zh-tw":
        "如果發現問題，請盡量提供以下信息以便我們盡快修復：<br/><br/> 1. 您在進行哪一步時發現此問題<br/> 2. 您在進行此步時希望達成的目的<br/> 3. 實際出現的結果",
      es: "Por favor, incluya todos los detalles relevantes. <br/><br/>Si cree que ha encontrado un fallo, incluir la siguiente información nos ayudará a resolver el problema más rápidamente:<br/><br/> 1. Qué pasos sigue para producir el fallo. Los pasos que ha dado para producir el fallo.<br/> 2. Lo que espera que ocurra al dar esos pasos.<br/> 3. Lo que realmente ocurre al dar esos pasos.",
      ja: "関連するすべての詳細を含めてください。<br/><br/>バグを発見したと思われる場合、以下の情報を含めると、より早く問題を解決することができます：<br/><br/>1.バグを生成するためにどのような手順を踏んだか。<br/> 2.<br/> 3. それらのステップを実行したときに実際に起こったこと。",
    },
    submitted: {
      de: "Ticket eingereicht.",
      en: "Ticket submitted.",
      fr: "Billet soumis.",
      "pt-br": "Tiquete enviado",
      "zh-cn": "发送成功",
      "zh-tw": "發送成功",
      es: "Billete presentado.",
      ja: "チケットを提出しました。",
    },
  },
  search: {
    noResults: {
      en: "No results",
      de: "Keine Ergebnisse",
      ja: "結果なし",
      "pt-br": "Nenhum resultado",
      es: "Sin resultados",
      fr: "Pas de résultats",
      "zh-cn": "没有结果",
      "zh-tw": "沒有結果",
    },
    browsePublicDecks: {
      en: "Browse Public Decks",
      de: "Öffentliche Decks durchstöbern",
      es: "Examinar las cubiertas públicas",
      fr: "Parcourir les jeux publics",
      "pt-br": "Navegar pelos Baralhos Públicos",
      "zh-cn": "浏览公开卡片组",
      "zh-tw": "流覽公開卡片組",
      ja: "公開デッキを見る",
    },
    publicDecks: {
      en: "Public Decks",
      de: "Öffentliche Decks",
      es: "Cubiertas públicas",
      fr: "Decks publics",
      "pt-br": "Baralhos Públicos",
      ja: "パブリックデッキ",
      "zh-cn": "公开卡片组",
      "zh-tw": "公開卡片組",
    },
    searchPublicDecks: {
      en: "Search Public Decks",
      de: "Öffentliche Decks suchen",
      es: "Buscar en las cubiertas públicas",
      fr: "Recherchez les platines publiques",
      "zh-cn": "搜索公开卡片组",
      "zh-tw": "搜索公開卡片組",
      ja: "公開デッキを検索する",
      "pt-br": "Buscar Decks Públicos",
    },
  },
  searchResult: {
    singular: {
      de: "Ergebnis",
      en: "Result",
      fr: "Résultat",
      "pt-br": "Resultado",
      "zh-cn": "结果",
      "zh-tw": "結果",
      es: "Resultado",
      ja: "結果",
    },
    plural: {
      de: "Ergebnisse",
      en: "Results",
      fr: "Résultats",
      "pt-br": "Resultados",
      "zh-cn": "结果",
      "zh-tw": "結果",
      es: "Resultados",
      ja: "結果",
    },
  },
  settings: {
    background: {
      en: "Background",
      es: "Fondo",
      "zh-cn": "背景",
      de: "Hintergrund",
      "pt-br": "Plano de Fundo",
      ja: "背景",
      fr: "Arrière-plan",
      "zh-tw": "背景",
    },
    backgroundGenerationSource: {
      en: "Generation Source Image",
      "pt-br": "Imagem de Origem da Geração",
      de: "Bildquelle Erstellen",
      "zh-cn": "生成源图像",
      ja: "画像生成元",
      es: "Generar Imagen de Origen",
      "zh-tw": "生成來源圖像",
      fr: "Générer l'image Source",
    },
    backgroundGenerationSourceDescription: {
      en: "For cards generated from an image, display that source image in the background",
      ja: "画像から生成されたカードの場合、その画像を背景に表示する",
      es: "Para tarjetas generadas a partir de una imagen, muestra esa imagen de origen en el fondo",
      "zh-tw": "對於從圖片生成的暗記卡，在背景中顯示該來源圖片",
      fr: "Pour les cartes générées à partir d'une image, affichez cette image source en arrière-plan",
      "zh-cn": "对于从图像生成的暗记卡，在背景中显示该来源图像",
      de: "Bei Karten, die aus einem Bild erstellt wurden, dieses Quellbild im Hintergrund anzeigen",
      "pt-br": "Para cartões gerados a partir de uma imagem, exibir essa imagem de origem no fundo",
    },
    editor: {
      en: "Editor",
      fr: "Éditeur",
      es: "Editor",
      de: "Editor",
      "pt-br": "Editor",
      ja: "エディター",
      "zh-tw": "編輯器",
      "zh-cn": "编辑器",
    },
    day: {
      en: "Day",
      de: "Tag",
      es: "Día",
      fr: "Jour",
      "pt-br": "Dia",
      ja: "日",
      "zh-cn": "灯光模式",
      "zh-tw": "燈光模式",
    },
    night: {
      en: "Night",
      de: "Nacht",
      es: "Noche",
      fr: "Nuit",
      "pt-br": "Noite",
      ja: "夜",
      "zh-cn": "黑暗模式",
      "zh-tw": "黑暗模式",
    },
    auto: {
      en: "Automatic",
      de: "Automatisch",
      es: "Automático",
      fr: "Automatique",
      "pt-br": "Automático",
      ja: "自動",
      "zh-cn": "自动",
      "zh-tw": "自動",
    },
    fontSizeExampleText: {
      en: "Example Text",
      de: "Beispieltext",
      es: "Ejemplo de Texto",
      fr: "Exemple de Texte",
      "pt-br": "Exemplo de Texto",
      ja: "テキスト例",
      "zh-cn": "字体",
      "zh-tw": "字體",
    },
    fontSizeDescription: {
      en: "Adjusts how large text is during review.",
      de: "Passt an, wie groß der Text während der Überprüfung ist.",
      es: "Ajusta el tamaño del texto durante la revisión.",
      "pt-br": "Ajusta o tamanho do texto durante a revisão.",
      fr: "Permet de régler la taille du texte lors de la révision.",
      ja: "レビュー中のテキストの大きさを調整します。",
      "zh-cn": "调整复习时文字的大小。",
      "zh-tw": "調整複習時文字的大小。",
    },
    haptics: {
      en: "Haptics",
      de: "Haptik",
      es: "Hápticos",
      fr: "Haptique",
      "pt-br": "Tato",
      "zh-cn": "触觉",
      "zh-tw": "觸覺",
      ja: "ハプティクス",
    },
    reviewHapticsEnabled: {
      en: "Review button haptic feedback",
      de: "Haptisches Feedback bei Überprüfungstaste aktiviert",
      es: "Háptica del botón habilitada",
      fr: "Bouton retour haptique activé",
      "pt-br": "Feedback tátil do botão de revisão",
      "zh-cn": "按钮触觉反馈是否启用",
      "zh-tw": "按鈕觸覺反饋是否啟用",
      ja: "レビューボタンの振動",
    },
    reset: {
      de: "Einstellungen zurücksetzen",
      en: "Reset Settings",
      fr: "Réinitialiser les paramètres",
      "pt-br": "Redefinir configurações",
      "zh-cn": "还原设置",
      "zh-tw": "還原設置",
      es: "Restablecer la configuración",
      ja: "設定のリセット",
    },
    confirmReset: {
      de: "Sind Sie sicher, dass Sie die Einstellungen auf die Standardwerte zurücksetzen möchten?",
      en: "Are you sure you wish to reset settings to default values?",
      fr: "Êtes-vous sûr de vouloir réinitialiser les paramètres aux valeurs par défaut ?",
      "pt-br": "Você tem certeza de que deseja redefinir as configurações para os valores padrão?",
      "zh-cn": "确定还原所有设置？",
      "zh-tw": "確定還原所有設置？",
      es: "¿Está seguro de que desea restablecer los valores por defecto?",
      ja: "本当に初期値に戻すのですか？",
    },
    alwaysHideCardPreview: {
      en: "Always hide card preview when creating or editing a card",
      de: "Kartenvorschau beim Erstellen oder Bearbeiten einer Karte immer ausblenden",
      es: "Siempre ocultar la vista previa de la tarjeta al crear o editar una tarjeta",
      fr: "Toujours masquer l'aperçu de la carte lors de la création ou de l'édition d'une carte",
      "pt-br": "Sempre ocultar a pré-visualização do cartão ao criar ou editar um cartão",
      "zh-cn": "创建或编辑卡片时始终隐藏卡片预览",
      "zh-tw": "建立或編輯卡片時，總是隱藏卡片預覽",
      ja: "カードの作成や編集時に常にカードプレビューを非表示にする",
    },
    cardSearch: {
      en: "Card Search",
      "zh-cn": "卡片搜索",
      "zh-tw": "卡片搜索",
      fr: "Recherche de carte",
      de: "Kartensuche",
      es: "Búsqueda de tarjetas",
      ja: "カード検索",
      "pt-br": "Pesquisar cartão",
    },
    useGlobCardSearch: {
      en: "Use <a href='https://en.wikipedia.org/wiki/Glob_(programming)'>glob search</a> when searching content in cards",
      "pt-br": "Use a pesquisa glob ao pesquisar conteúdo em cartões",
      ja: "カード内のコンテンツを検索するときに<a href='https://ja.wikipedia.org/wiki/%E3%82%B0%E3%83%AD%E3%83%96'>グロブ</a>検索を使用する",
      "zh-cn": "在卡片中搜索内容时使用全局搜索",
      "zh-tw": "在卡片中搜索內容時使用全局搜索",
      de: "Globale Suche beim Suchen im Karteninhalt verwenden",
      fr: "Utiliser la recherche globale lors de la recherche de contenu dans les cartes",
      es: "Use la búsqueda global cuando busque contenido en tarjetas",
    },
    expandRichEditorToolbar: {
      en: "Always show expanded toolbar for the rich text editor",
      "pt-br": "Sempre mostrar a barra de ferramentas expandida para o editor de rich text",
      ja: "リッチテキストエディタの拡張ツールバーを常に表示させる",
      de: "Erweiterte Symbolleiste im Rich-Text-Editor immer anzeigen",
      fr: "Toujours afficher la barre d'outils étendue de l'éditeur de texte enrichi",
      es: "Mostrar siempre la barra de herramientas expandida para el editor de texto enriquecido",
      "zh-cn": "始终显示富文本编辑器的扩展工具栏",
      "zh-tw": "始終顯示豐富文字編輯器的擴展工具列",
    },
    enableAdvancedFontControls: {
      en: "Enable advanced rich text editor font controls",
      "pt-br": "Habilitar controles avançados de fonte do editor de rich text",
      ja: "リッチテキストエディタの高度なフォント制御を可能にする",
      fr: "Activer les contrôles avancés des polices de l'éditeur de texte riche",
      es: "Activar los controles avanzados de fuentes del editor de texto enriquecido",
      de: "Erweiterte Schriftartensteuerung im Rich-Text-Editor aktivieren",
      "zh-cn": "启用富文本编辑器的高级字体控制",
      "zh-tw": "啟用豐富文字編輯器的高級字體控制",
    },
  },
  purchasing: {
    scanOrClickToPay: {
      en: "Scan with your phone to pay, or click to pay in your browser.",
      de: "Zum Bezahlen mit dem Handy scannen oder klicken Sie, um im Browser zu bezahlen.",
      es: "Escanee con su teléfono para pagar, o haga clic para pagar en su navegador.",
      fr: "Scannez avec votre téléphone pour payer, ou cliquez pour payer dans votre navigateur.",
      "pt-br": "Escaneie com seu telefone para pagar, ou clique para pagar com seu navegador.",
      "zh-cn": "用手机扫描或点击二维码付款",
      "zh-tw": "用手機掃描或點擊二維碼付款",
      ja: "携帯電話でスキャンして支払うか、ブラウザでクリックして支払います。",
    },
    clickWhenDone: {
      en: "Click here when done",
      de: "Klicken Sie hier, wenn Sie fertig sind",
      es: "Haga clic aquí cuando termine",
      fr: "Cliquez ici lorsque vous avez terminé",
      "pt-br": "Clique aqui quando finalizado",
      "zh-cn": "完成后点击",
      "zh-tw": "完成後點擊",
      ja: "完了したらここをクリック",
    },
    validationError: {
      de: "Fehler bei der Kaufvalidierung. Bitte versuchen Sie es erneut oder verwenden Sie eine gültige Zahlungsmethode.",
      en: "There was an error validating your purchase. Please try again with a valid payment method.",
      fr: "Une erreur s'est produite lors de la validation de votre achat. Veuillez réessayer avec un mode de paiement valide.",
      "pt-br":
        "Houve um erro na validação de sua compra. Por favor, tente novamente com um método de pagamento válido.",
      "zh-cn": "支付验证错误",
      "zh-tw": "支付驗證錯誤",
      es: "Hubo un error al validar su compra. Por favor, inténtalo de nuevo con un método de pago válido.",
      ja: "購入の認証にエラーが発生しました。有効な支払い方法でもう一度やり直してください。",
    },
    iOSIAPError: {
      de: "Fehler beim Abonnieren. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support für Hilfe.",
      en: "Error subscribing. Please try again or contact support for help.",
      fr: "Erreur d'inscription. Veuillez réessayer ou contacter le service d'assistance pour obtenir de l'aide.",
      "pt-br":
        "Erro na assinatura. Por favor, tente novamente ou entre em contato com o suporte para obter ajuda.",
      "zh-cn": "订阅错误。请重试或联系支持人员寻求帮助。",
      "zh-tw": "訂閱錯誤。請重試或聯繫支援人員尋求説明。",
      es: "Error de suscripción. Por favor, inténtelo de nuevo o póngase en contacto con el servicio de asistencia para obtener ayuda.",
      ja: "登録に失敗しました。もう一度お試しいただくか、サポートまでお問い合わせください。",
    },
    iOSIAPRestoreError: {
      de: "Fehler bei der Wiederherstellung der Käufe. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support für Hilfe.",
      en: "Error restoring purchases. Please try again or contact support for help.",
      fr: "Erreur de restauration des achats. Veuillez réessayer ou contacter le service d'assistance pour obtenir de l'aide.",
      "pt-br":
        "Erro ao restaurar compras. Por favor, tente novamente ou entre em contato com o suporte para obter ajuda.",
      "zh-cn": "还原购买时出错。请重试或联系支持人员寻求帮助。",
      "zh-tw": "還原購買時出錯。請重試或聯繫支援人員尋求説明。",
      es: "Error al restaurar las compras. Por favor, inténtelo de nuevo o póngase en contacto con el servicio de asistencia para obtener ayuda.",
      ja: "購入した商品の復元に失敗しました。再度お試しいただくか、サポートまでお問い合わせください。",
    },
    yearFrequency: {
      de: "Jährlich",
      en: "Year",
      fr: "Année",
      "pt-br": "Ano",
      "zh-cn": "年",
      "zh-tw": "年",
      es: "Año",
      ja: "年",
    },
    oneTimeFrequency: {
      de: "Einmalig",
      en: "One-time",
      fr: "Une seule fois",
      "pt-br": "Uma vez",
      "zh-cn": "永久",
      "zh-tw": "永久",
      es: "Una sola vez",
      ja: "1回",
    },
  },
  time: {
    year: {
      de: "J",
      en: "y",
      fr: "y",
      "pt-br": "a",
      "zh-cn": "年",
      "zh-tw": "年",
      es: "y",
      ja: "y",
    },
    day: {
      de: "T",
      en: "d",
      fr: "d",
      "pt-br": "d",
      "zh-cn": "天",
      "zh-tw": "天",
      es: "d",
      ja: "d",
    },
    hour: {
      de: "Std",
      en: "h",
      fr: "h",
      "pt-br": "h",
      "zh-cn": "小时",
      "zh-tw": "小時",
      es: "h",
      ja: "h",
    },
    minute: {
      de: "Min",
      en: "m",
      fr: "m",
      "pt-br": "m",
      "zh-cn": "分钟",
      "zh-tw": "分鐘",
      es: "m",
      ja: "m",
    },
    second: {
      de: "Sek",
      en: "s",
      fr: "s",
      "pt-br": "s",
      "zh-cn": "秒",
      "zh-tw": "秒",
      es: "s",
      ja: "s",
    },
  },
  platform: {
    mac: {
      de: "macOS",
      en: "macOS",
      fr: "macOS",
      "pt-br": "macOS",
      "zh-cn": "macOS",
      "zh-tw": "macOS",
      es: "macOS",
      ja: "マクロス",
    },
    ios: {
      de: "iOS",
      en: "iOS",
      fr: "iOS",
      "pt-br": "iOS",
      "zh-cn": "iOS",
      "zh-tw": "iOS",
      es: "iOS",
      ja: "iOS",
    },
    win: {
      de: "Windows",
      en: "Windows",
      fr: "Windows",
      "pt-br": "Windows",
      "zh-cn": "Win",
      "zh-tw": "Win",
      es: "Windows",
      ja: "ウィンドウズ",
    },
    android: {
      de: "Android",
      en: "Android",
      fr: "Android",
      "pt-br": "Android",
      "zh-cn": "安卓",
      "zh-tw": "Android",
      es: "Android",
      ja: "アンドロイド",
    },
    other: {
      de: "Andere",
      en: "Other",
      fr: "Autre",
      "pt-br": "Outros",
      "zh-cn": "其它",
      "zh-tw": "其他",
      es: "Otros",
      ja: "その他",
    },
  },
  archive: {
    empty: {
      en: "Archive old decks to keep your Home screen organized.",
      fr: "Archivez vos anciennes platines pour organiser votre écran d'accueil.",
      ja: "古いデッキをアーカイブして、ホーム画面を整理整頓。",
      de: "Archivieren Sie alte Decks, um Ihren Startbildschirm übersichtlich zu halten.",
      "pt-br": "Arquive decks antigos para manter sua tela inicial organizada.",
      es: "Archiva los mazos antiguos para mantener organizada tu pantalla de inicio.",
      "zh-cn": "归档旧的暗记包，以保持主屏幕整洁。",
      "zh-tw": "將舊暗記包存檔以保持主畫面整齊。",
    },
    noun: {
      en: "Archive",
      de: "Archiv",
      fr: "Archive",
      "pt-br": "Arquivo",
      "zh-cn": "档案",
      "zh-tw": "檔案",
      es: "Archivo",
      ja: "アーカイブ",
    },
    verb: {
      en: "Archive",
      "pt-br": "Arquivar",
      fr: "Archiver",
      es: "Archivar",
      de: "Archivieren",
      ja: "アーカイブする",
      "zh-cn": "归档",
      "zh-tw": "歸檔",
    },
    confirmRestore: {
      en: "Restore this deck?",
      "pt-br": "Restaurar este deck?",
      de: "Dieses Deck wiederherstellen?",
      fr: "Restaurer ce pont ?",
      ja: "このデッキを復活させる？",
      es: "¿Restaurar este mazo?",
      "zh-cn": "恢复此暗记包？",
      "zh-tw": "要恢復呢個暗記包？",
    },
  },
  beta: {
    enable: {
      en: "Enable beta access",
      de: "Beta-Zugang aktivieren",
      es: "Activar el acceso beta",
      "pt-br": "Ativar acesso beta",
      fr: "Activer l'accès à la version bêta",
      ja: "ベータ版へのアクセスを有効にする",
      "zh-cn": "启用测试版访问",
      "zh-tw": "啟用測試版訪問",
    },
    description: {
      en: "Enabling beta grants you access to cutting-edge features like Generate Cards (on the Deck screen menu) which lets you create flashcards from image and text prompts using advanced AI.",
      ja: "ベータ版を有効にすると、デッキ画面のメニューから、AIを使用して画像やテキストプロンプトからフラッシュカードを作成できる「カードを生成する」などの最先端機能にアクセスできるようになります。",
      es: "Activar la versión beta te da acceso a funciones de vanguardia como Generar tarjetas, en el menú de la pantalla Deck, que te permite crear tarjetas a partir de imágenes y texto utilizando IA.",
      "pt-br":
        "Habilitar o beta concede acesso a recursos de ponta, como Gerar Cartões (no menu da tela Deck), que permite criar cartões a partir de imagens e perguntas de texto usando IA avançada.",
      de: 'Durch die Aktivierung der Beta-Version erhalten Sie Zugriff auf innovative Funktionen wie "Karten generieren" im Menü "Deck", mit dem Sie Lernkarten aus Bild- und Textvorgaben mithilfe von KI erstellen können.',
      fr: "L'activation de la version bêta vous permet d'accéder à des fonctionnalités de pointe telles que Générer des cartes, dans le menu de l'écran Deck, qui vous permet de créer des flashcards à partir d'images et de textes grâce à l'intelligence artificielle.",
      "zh-cn":
        "启用测试版可让您访问尖端功能，例如在暗记包屏幕菜单中的生成暗记卡功能，它允许您使用先进的AI根据图像和文本提示创建暗记卡。",
      "zh-tw":
        "啟用測試版本可讓你訪問最新功能，如生成暗記卡（在暗記包畫面菜單中），使用先進的AI從圖像和文本提示創建暗記卡。",
    },
  },
  generation: {
    promptHistoryEmptyDescription: {
      en: "Prompts you use will appear here, so you can reuse them later.",
      es: "Los indicios que uses aparecerán aquí, para que puedas reutilizarlos más tarde.",
      "zh-cn": "您使用的提示将出现在这里，因此您可以稍后重复使用它们。",
      ja: "ここに使用したプロンプトが表示されます。後で再利用できます。",
      "pt-br":
        "Os prompts que você usar aparecerão aqui, para que você possa reutilizá-los mais tarde.",
      "zh-tw": "您使用過的提示將顯示在此處，以便您稍後重複使用。",
      de: "Hier erscheinen die von Ihnen verwendeten Eingabeaufforderungen, damit Sie sie später wiederverwenden können.",
      fr: "Les invites que vous utilisez apparaîtront ici, afin que vous puissiez les réutiliser plus tard.",
    },
    confirmRemovePrompt: {
      en: "Remove prompt?",
      "zh-cn": "移除提示？",
      de: "Prompt entfernen?",
      es: "¿Eliminar prompt?",
      ja: "プロンプトを削除しますか？",
      "pt-br": "Remover o prompt?",
      fr: "Supprimer la question ?",
      "zh-tw": "刪除提示？",
    },
    generateMore: {
      en: "Generate More",
      ja: "さらに増やす",
      "pt-br": "Gerar Mais",
      de: "Mehr generieren",
      fr: "Générer plus",
      es: "Generar más",
      "zh-tw": "生成更多",
      "zh-cn": "生成更多",
    },
    fieldsDescription: {
      en: "Cards will be generated for these fields.",
      es: "Se generarán tarjetas para estos campos.",
      de: "Für diese Felder werden Karten erstellt.",
      "pt-br": "Cartões serão gerados para estes campos.",
      ja: "カードはこれらのフィールドに生成される。",
      fr: "Des cartes seront générées pour ces champs.",
      "zh-cn": "将为这些数据区生成暗记卡。",
      "zh-tw": "將為這些數據區生成暗記卡。",
    },
    incompatibleConfig: {
      en: "Incompatible deck config.",
      de: "Inkompatible Deckkonfiguration.",
      "pt-br": "Configuração de deck incompatível.",
      es: "Configuración de cubierta incompatible.",
      fr: "Configuration de pont incompatible.",
      ja: "デッキ構成に互換性がない。",
      "zh-tw": "不相容的暗記包配置。",
      "zh-cn": "不兼容的暗记包配置。",
    },
    featureDescription: {
      en: "Automatically generate cards from text or photos of class notes, lecture slides, and more...",
      de: "Erstellen Sie automatisch Karten aus Text oder Fotos von Unterrichtsmitschriften, Vorlesungsfolien und mehr...",
      ja: "授業ノートや講義スライドなどのテキストや写真からカードを自動生成...",
      fr: "Génère automatiquement des cartes à partir de textes ou de photos de notes de cours, de diapositives de conférences, et plus encore...",
      es: "Genere tarjetas automáticamente a partir de texto o fotos de apuntes de clase, diapositivas...",
      "pt-br":
        "Gerar cartões automaticamente a partir de texto ou fotos de anotações de aula, slides de palestra e mais...",
      "zh-cn": "自动从课堂笔记、讲义幻灯片等文本或照片生成暗记卡...",
      "zh-tw": "自動由課堂筆記、講義幻燈片等文本或照片生成暗記卡...",
    },
    suggestedUses: {
      en: 'Here are some things you can try:\n- Attach photos of handwritten notes, lecture slides, and more, ...\n- Copy-paste typed notes into the prompt, to generate flashcards from them.\n- Type in a list of verbs, and ask to conjugate them into past tense, in another language.\n- Write a prompt like "make flashcards from this Wikipedia page: https://en.wikipedia.org/wiki/Citric_acid_cycle".',
      es: 'Aquí tienes algunas cosas que puedes probar:\n- Adjuntar fotos de notas escritas a mano, diapositivas de conferencias, ...\n- Copiar y pegar apuntes mecanografiados para generar fichas a partir de ellos.\n- Escribe una lista de verbos y pídeles que los conjuguen en pasado en otro idioma.\n- Escribe algo como "haz fichas a partir de esta página de Wikipedia: https://en.wikipedia.org/wiki/Citric_acid_cycle".',
      de: 'Hier sind einige Möglichkeiten, die Sie ausprobieren können:\n- Fügen Sie Fotos von handschriftlichen Notizen, Vorlesungsfolien und mehr ein, ...\n- Kopieren Sie getippte Notizen in die Eingabeaufforderung, um daraus Karteikarten zu erstellen.\n- Geben Sie eine Liste von Verben ein und bitten Sie darum, sie in einer anderen Sprache in die Vergangenheitsform zu konjugieren.\n- Schreiben Sie eine Aufforderung wie "Erstellen Sie Karteikarten aus dieser Wikipedia-Seite: https://en.wikipedia.org/wiki/Citric_acid_cycle".',
      ja: "ここで、いくつか試してみてください：\n- 手書きのノートや講義のスライドなどの写真を添付する。\n- タイプしたノートをコピーペーストしてプロンプトに入力し、そこからフラッシュカードを作成する。\n- 動詞のリストを入力し、他の言語で過去形に活用させる。\n- このウィキペディアのページからフラッシュカードを作る：https://en.wikipedia.org/wiki/Citric_acid_cycle」のようなプロンプトを書く。",
      "pt-br":
        'Aqui estão algumas coisas que você pode tentar:\n- Anexe fotos de notas manuscritas, slides de palestras e mais...\n- Copie e cole notas digitadas no prompt, para gerar cartões a partir delas.\n- Digite uma lista de verbos e peça para conjugá-los no passado, em outra língua.\n- Escreva um prompt como "criar cartões a partir desta página da Wikipédia: https://en.wikipedia.org/wiki/Citric_acid_cycle".',
      fr: 'Voici ce que vous pouvez faire :\n- Joignez des photos de notes manuscrites, de diapositives de cours, etc.\n- Copier-coller des notes dactylographiées dans l\'invite, pour générer des flashcards à partir de celles-ci.\n- Tapez une liste de verbes et demandez-leur de se conjuguer au passé dans une autre langue.\n- Rédigez une invite du type "créez des flashcards à partir de cette page Wikipedia: https://en.wikipedia.org/wiki/Citric_acid_cycle".',
      "zh-cn":
        "这里有些操作你可以尝试：\n- 附上手写笔记、讲义幻灯片等照片，...\n- 复制粘贴输入的笔记到提示中，从中生成暗记卡。\n- 输入一个动词列表，并要求将它们在另一种语言中变成过去式。\n- 编写类似“从此Wikipedia页面生成暗记卡：https://en.wikipedia.org/wiki/Citric_acid_cycle”的提示。",
      "zh-tw":
        '這裡有些你可以嘗試的事情：\n- 附加手寫筆記、課堂幻燈片等的照片，...\n- 複製-貼上打字的筆記到提示中，來從中生成暗記卡。\n- 輸入動詞清單，然後要求將它們變成過去式，用另一種語言。\n- 寫一個提示如 "從這個維基百科頁面製作暗記卡：https://en.wikipedia.org/wiki/Citric_acid_cycle"。',
    },
  },
  general: {
    other: {
      en: "Other",
      de: "Andere",
      fr: "Autre",
      "pt-br": "Outros",
      "zh-cn": "其他",
      "zh-tw": "其他",
      es: "Otros",
      ja: "その他",
    },
    defaultUnlessOverridden: {
      en: "Default unless overridden",
      "pt-br": "Padrão, a menos que substituído",
      es: "Por defecto a menos que se anule",
      ja: "上書きされない限りデフォルト",
      de: "Standard, sofern nicht überschrieben",
      fr: "Valeur par défaut sauf si elle est remplacée",
      "zh-cn": "默认，除非被覆盖",
      "zh-tw": "預設除非覆蓋",
    },
    inbox: {
      en: "Inbox",
      fr: "Boîte de réception",
      ja: "受信トレイ",
      es: "Bandeja de entrada",
      "pt-br": "Caixa de entrada",
      de: "Posteingang",
      "zh-cn": "收件箱",
      "zh-tw": "收件箱",
    },
    generateCardInstructions: {
      en: "Provide an image and/or text prompt to generate cards from.",
      de: "Geben Sie eine Bild- und/oder Textaufforderung vor, anhand derer Sie Karten erstellen können.",
      es: "Proporcione una imagen y/o un texto para generar tarjetas.",
      ja: "カードを作成するための画像やテキストを提供する。",
      fr: "Fournir une image et/ou un texte pour générer des cartes.",
      "pt-br": "Forneça uma imagem e/ou texto para gerar cartões.",
      "zh-cn": "提供图像和/或文本提示以生成暗记卡。",
      "zh-tw": "請提供圖像或文字提示以生成暗記卡。",
    },
    promptPlaceholderText: {
      en: "Prompt...",
      fr: "Prompt...",
      de: "Prompt...",
      "pt-br": "Pergunta...",
      es: "Prompt...",
      ja: "プロンプト...",
      "zh-cn": "提示...",
      "zh-tw": "提示...",
    },
    beta: {
      en: "Beta",
      fr: "Bêta",
      ja: "ベータ",
      de: "Beta",
      es: "Beta",
      "pt-br": "Beta",
      "zh-tw": "Beta",
      "zh-cn": "测试版",
    },
    tip: {
      en: "Tip",
      ja: "ヒント",
      de: "Tipp",
      "pt-br": "Dica",
      es: "Consejo",
      fr: "Conseil",
      "zh-cn": "提示",
      "zh-tw": "提示",
    },
    custom: {
      en: "Custom",
      ja: "カスタム",
      de: "Benutzerdefiniert",
      es: "A medida",
      "pt-br": "Personalizado",
      fr: "Sur mesure",
      "zh-cn": "自定",
      "zh-tw": "自訂",
    },
    hintDelay: {
      en: "Hint Reveal Delay (ms)",
      fr: "Délai de révélation de l'indice (ms)",
      ja: "ヒント表示遅延時間 (ms)",
      es: "Retardo de revelación de pista (ms)",
      de: "Verzögerung der Hinweisanzeige (ms)",
      "pt-br": "Atraso na Revelação da Dica (ms)",
      "zh-cn": "提示显示延迟 (毫秒)",
      "zh-tw": "提示顯示延遲 (毫秒)",
    },
    normal: {
      en: "Normal",
      fr: "Normal",
      es: "Normal",
      "pt-br": "Normal",
      de: "Normal",
      ja: "通常",
      "zh-cn": "正常",
      "zh-tw": "正常",
    },
    normalOrientationDescription: {
      en: "Front-to-back",
      ja: "前後",
      de: "Von vorne nach hinten",
      "pt-br": "Da frente para trás",
      es: "De adelante hacia atrás",
      fr: "De l'avant à l'arrière",
      "zh-cn": "从前到后",
      "zh-tw": "從前到後",
    },
    reversed: {
      en: "Reversed",
      de: "Umgekehrt",
      fr: "Inversé",
      es: "Invertido",
      ja: "反転",
      "pt-br": "Invertido",
      "zh-cn": "反向",
      "zh-tw": "反向",
    },
    reversedOrientationDescription: {
      en: "Back-to-front",
      fr: "De l'arrière à l'avant",
      ja: "バック・トゥ・フロント",
      "pt-br": "De trás para frente",
      de: "Von hinten nach vorne",
      es: "De atrás hacia delante",
      "zh-cn": "从后到前",
      "zh-tw": "從後到前",
    },
    mixed: {
      en: "Mixed",
      fr: "Mixte",
      es: "Mixto",
      ja: "ミックス",
      "pt-br": "Misto",
      de: "Gemischt",
      "zh-cn": "混合",
      "zh-tw": "混合",
    },
    mixedOrientationDescription: {
      en: "Random combination of normal and reversed",
      "pt-br": "Combinação aleatória de normal e invertido",
      es: "Combinación aleatoria de normal e invertida",
      ja: "ノーマルとリバースのランダムな組み合わせ",
      fr: "Combinaison aléatoire de normal et inversé",
      de: "Zufällige Kombination von Normal und Umgekehrt",
      "zh-cn": "正常和反向的随机组合",
      "zh-tw": "正常和反向的隨機組合",
    },
    config: {
      en: "Config",
      es: "Configurar",
      de: "Konfiguration",
      fr: "Config",
      ja: "コンフィグ",
      "pt-br": "Config",
      "zh-cn": "配置",
      "zh-tw": "配置",
    },
    side: {
      en: "Side",
      ja: "側面",
      es: "Lado",
      fr: "Côté",
      "pt-br": "Lado",
      de: "Seite",
      "zh-cn": "侧",
      "zh-tw": "側",
    },
    manual: {
      en: "Manual",
      es: "Manual",
      fr: "Manuel",
      de: "Manuell",
      ja: "マニュアル",
      "pt-br": "Manual",
      "zh-cn": "手动",
      "zh-tw": "手動",
    },
    selected: {
      en: "Selected",
      fr: "Sélectionné",
      ja: "選択された",
      es: "Seleccionado",
      de: "Ausgewählte",
      "pt-br": "Selecionado",
      "zh-cn": "已选",
      "zh-tw": "已選",
    },
    translation: {
      en: "Translation",
      de: "Übersetzung",
      fr: "Traduction",
      "pt-br": "Tradução",
      ja: "翻訳",
      es: "Traducción",
      "zh-cn": "翻译",
      "zh-tw": "翻譯",
    },
    always: {
      en: "Always",
      ja: "常に",
      "pt-br": "Sempre",
      de: "Immer",
      es: "Siempre",
      fr: "Toujours",
      "zh-cn": "总是",
      "zh-tw": "總是",
    },
    reading: {
      en: "Reading",
      es: "Lectura",
      fr: "Lecture",
      "pt-br": "Leitura",
      ja: "読み",
      de: "Lesen",
      "zh-cn": "阅读",
      "zh-tw": "閱讀",
    },
    advanced: {
      en: "Advanced",
      de: "Fortgeschritten",
      fr: "Avancé",
      "pt-br": "Avançado",
      es: "Avanzado",
      ja: "アドバンスト",
      "zh-cn": "高级",
      "zh-tw": "高級",
    },
    basic: {
      en: "Basic",
      es: "Básico",
      de: "Basis",
      fr: "Base",
      ja: "ベーシック",
      "pt-br": "Básico",
      "zh-cn": "基础",
      "zh-tw": "基礎",
    },
    required: {
      en: "Required",
      de: "Erforderlich",
      es: "Obligatorio",
      fr: "Obligatoire",
      ja: "必須",
      "pt-br": "Obrigatório",
      "zh-cn": "必需",
      "zh-tw": "必需",
    },
    about: {
      en: "About",
      de: "Über",
      es: "Acerca de",
      fr: "À propos de",
      ja: "について",
      "pt-br": "Sobre",
      "zh-cn": "关于",
      "zh-tw": "關於",
    },
    debug: {
      en: "Debug",
      de: "Debuggen",
      es: "Depurar",
      fr: "Déboguer",
      ja: "デバッグ",
      "pt-br": "Depuração",
      "zh-cn": "调试",
      "zh-tw": "調試",
    },
    devices: {
      en: "Devices",
      de: "Geräte",
      es: "Dispositivos",
      fr: "Dispositifs",
      ja: "デバイス",
      "pt-br": "Dispositivos",
      "zh-cn": "设备",
      "zh-tw": "裝置",
    },
    home: {
      en: "Home",
      de: "Startseite",
      es: "Inicio",
      fr: "Accueil",
      ja: "ホーム",
      "pt-br": "Início",
      "zh-cn": "主页",
      "zh-tw": "主頁",
    },
    optional: {
      en: "Optional",
      de: "Optional",
      es: "Opcional:",
      fr: "En option",
      "pt-br": "Opcional",
      ja: "オプション",
      "zh-cn": "可选",
      "zh-tw": "可選",
    },
    firstName: {
      en: "First Name",
      de: "Vorname",
      es: "Nombre",
      fr: "Prénom",
      "pt-br": "Primeiro nome",
      ja: "名",
      "zh-cn": "名",
      "zh-tw": "名",
    },
    lastName: {
      en: "Last Name",
      de: "Nachname",
      es: "Apellido",
      fr: "Dernier nom",
      "pt-br": "Sobrenome",
      ja: "姓",
      "zh-cn": "姓",
      "zh-tw": "姓",
    },
    or: {
      en: "or",
      de: "oder",
      es: "o",
      fr: "ou",
      "pt-br": "ou",
      "zh-cn": "或",
      "zh-tw": "或",
      ja: "または",
    },
    dotChar: {
      en: ".",
      de: ".",
      es: ".",
      fr: ".",
      "pt-br": ".",
      "zh-cn": "。",
      "zh-tw": "。",
      ja: "。",
    },
    processing: {
      en: "Processing",
      de: "Verarbeitung",
      es: "Procesando",
      fr: "Traitement",
      "pt-br": "Processando",
      "zh-cn": "处理中",
      "zh-tw": "處理中",
      ja: "処理",
    },
    confirmDiscardChange: {
      en: "Discard changes?",
      de: "Sind Sie sicher, dass Sie die Änderungen verwerfen möchten?",
      es: "¿Descartar los cambios?",
      fr: "Jeter les changements ?",
      "pt-br": "Descartar mudanças?",
      "zh-cn": "丢弃更改？",
      "zh-tw": "丟棄更改？",
      ja: "変更点を破棄する？",
    },
    confirmLeaveWithoutSaving: {
      en: "Leave without saving?",
      de: "Sind Sie sicher, dass Sie ohne zu speichern verlassen möchten?",
      es: "¿Irse sin ahorrar?",
      fr: "Partir sans sauver ?",
      "pt-br": "Sair sem salvar?",
      "zh-cn": "取消创建卡片？",
      "zh-tw": "取消創建卡片？",
      ja: "保存しないで帰る？",
    },
    dataLoadingMessage: {
      en: "Loading data...",
      de: "Daten werden geladen...",
      es: "Cargando datos...",
      fr: "Chargement des données...",
      ja: "データ読み込み中...",
      "pt-br": "Carregando dados...",
      "zh-cn": "下载中...",
      "zh-tw": "下載中...",
    },
    attribution: {
      de: "Zuschreibung",
      en: "Attribution",
      fr: "Attribution",
      "pt-br": "Atribuição",
      "zh-cn": "Attribution",
      "zh-tw": "Attribution",
      es: "Atribución",
      ja: "アトリビュート",
    },
    calendar: {
      de: "Kalender",
      en: "Calendar",
      fr: "Calendrier",
      "pt-br": "Calendário",
      "zh-cn": "日历",
      "zh-tw": "日曆",
      es: "Calendario",
      ja: "カレンダー",
    },
    importDeck: {
      en: "Import Deck",
      de: "Deck importieren",
      fr: "Deck d'importation",
      "zh-cn": "导入暗记包",
      "zh-tw": "導入暗記包",
      es: "Cubierta de importación",
      ja: "インポートデッキ",
      "pt-br": "Importar Deck",
    },
    import: {
      de: "Importieren",
      en: "Import Decks",
      fr: "Decks d'importation",
      "zh-cn": "导入文件",
      "zh-tw": "導入文件",
      es: "Mazos de importación",
      ja: "インポートデッキ",
      "pt-br": "Importar Decks",
    },
    public: {
      de: "Öffentlich",
      en: "Public",
      fr: "Public",
      "pt-br": "Público",
      "zh-cn": "公开",
      "zh-tw": "公開",
      es: "Público",
      ja: "公共",
    },
    profile: {
      de: "Profil",
      en: "Profile",
      fr: "Profil",
      "pt-br": "Perfil",
      "zh-cn": "个人信息",
      "zh-tw": "個人信息",
      es: "Perfil",
      ja: "プロフィール",
    },
    settings: {
      de: "Einstellungen",
      en: "Settings",
      fr: "Paramètres",
      "pt-br": "Configurações",
      "zh-cn": "设置",
      "zh-tw": "設置",
      es: "Ajustes",
      ja: "設定",
    },
    help: {
      de: "Hilfe",
      en: "Help",
      fr: "Aide",
      "pt-br": "Ajuda",
      "zh-cn": "帮助",
      "zh-tw": "幫助",
      es: "Ayuda",
      ja: "ヘルプ",
    },
    news: {
      de: "Neuigkeiten",
      en: "News",
      fr: "Nouvelles",
      "pt-br": "Notícias",
      "zh-cn": "消息",
      "zh-tw": "消息",
      es: "Noticias",
      ja: "ニュース",
    },
    name: {
      de: "Name",
      en: "Name",
      fr: "Nom",
      "pt-br": "Nome",
      "zh-cn": "名称",
      "zh-tw": "名稱",
      es: "Nombre",
      ja: "名称",
    },
    create: {
      de: "Erstellen",
      en: "Create",
      fr: "Créer",
      "pt-br": "Criar",
      "zh-cn": "创建",
      "zh-tw": "創建",
      es: "Crear",
      ja: "作成",
    },
    subject: {
      de: "Thema",
      en: "Subject",
      fr: "Sujet",
      "pt-br": "Assunto",
      "zh-cn": "标题",
      "zh-tw": "標題",
      es: "Asunto",
      ja: "課題",
    },
    description: {
      de: "Beschreibung",
      en: "Description",
      fr: "Description",
      "pt-br": "Descrição",
      "zh-cn": "描述",
      "zh-tw": "描述",
      es: "Descripción",
      ja: "商品説明",
    },
    noDescription: {
      de: "Keine Beschreibung.",
      en: "No description.",
      fr: "Pas de description.",
      "pt-br": "Sem descrição.",
      "zh-cn": "无描述",
      "zh-tw": "無描述",
      es: "No hay descripción.",
      ja: "記載なし。",
    },
    preview: {
      de: "Vorschau",
      en: "Preview",
      fr: "Prévisualisation",
      "pt-br": "Pré-visualizar",
      "zh-cn": "预览",
      "zh-tw": "預覽",
      es: "Vista previa",
      ja: "プレビュー",
    },
    language: {
      de: "Sprache",
      en: "Language",
      fr: "Langue",
      "pt-br": "Idioma",
      "zh-cn": "语言",
      "zh-tw": "語言",
      es: "Idioma",
      ja: "言語",
    },
    recap: {
      de: "Zusammenfassung",
      en: "Recap",
      fr: "Récapitulatif",
      "pt-br": "Recapitular",
      "zh-cn": "复习总结",
      "zh-tw": "復習總結",
      es: "Recapitulación",
      ja: "まとめ",
    },
    remote: {
      de: "Fern",
      en: "Cloud",
      fr: "Nuage",
      "pt-br": "Nuvem",
      "zh-cn": "云端",
      "zh-tw": "雲端",
      es: "Nube",
      ja: "クラウド",
    },
    local: {
      de: "Lokal",
      en: "Local",
      fr: "Local",
      "pt-br": "Local",
      "zh-cn": "本机储存",
      "zh-tw": "本機儲存",
      es: "Local",
      ja: "ローカル",
    },
    stats: {
      de: "Statistiken",
      en: "Stats",
      fr: "Stats",
      "pt-br": "Estatísticas",
      "zh-cn": "统计",
      "zh-tw": "統計",
      es: "Estadísticas",
      ja: "統計情報",
    },
    info: {
      de: "Information",
      en: "Info",
      fr: "Info",
      "pt-br": "Informações",
      "zh-cn": "信息",
      "zh-tw": "信息",
      es: "Información",
      ja: "情報",
    },
    style: {
      de: "Stil (CSS)",
      en: "Style (CSS)",
      fr: "Style (CSS)",
      "pt-br": "Estilo (CSS)",
      "zh-cn": "样式",
      "zh-tw": "樣式",
      es: "Estilo (CSS)",
      ja: "スタイル（CSS）",
    },
    colorScheme: {
      de: "Farbschema",
      en: "Color Scheme",
      fr: "Schéma de couleurs",
      "pt-br": "Paleta de cores",
      "zh-cn": "页面主题",
      "zh-tw": "頁面主題",
      es: "Esquema de colores",
      ja: "カラースキーム",
    },
    version: {
      de: "Version",
      en: "Version",
      fr: "Version",
      "pt-br": "Versão",
      "zh-cn": "版本",
      "zh-tw": "版本",
      es: "Versión",
      ja: "バージョン",
    },
    front: {
      de: "Vorderseite",
      en: "Front",
      fr: "Avant",
      "pt-br": "Frente",
      "zh-cn": "正面",
      "zh-tw": "正面",
      es: "Frontal",
      ja: "表",
    },
    back: {
      de: "Rückseite",
      en: "Back",
      fr: "Arrière",
      "pt-br": "Trás",
      "zh-cn": "反面",
      "zh-tw": "反面",
      es: "Posterior",
      ja: "裏",
    },
    frontSide: {
      de: "Vorderseite",
      en: "Front Side",
      fr: "Côté Avant",
      "pt-br": "Lado da Frente",
      "zh-cn": "正面",
      "zh-tw": "正面",
      es: "Lado Frontal",
      ja: "表",
    },
    backSide: {
      de: "Rückseite",
      en: "Back Side",
      fr: "Côté Arrière",
      "pt-br": "Lado de Trás",
      "zh-cn": "反面",
      "zh-tw": "反面",
      es: "Lado Posterior",
      ja: "裏",
    },
    both: {
      en: "Both",
      de: "Beide",
      ja: "両方",
      fr: "Les deux",
      "pt-br": "Ambos",
      es: "Ambos",
      "zh-tw": "兩者",
      "zh-cn": "两者",
    },
    localLanguage: {
      en: "Local Language",
      ja: "現地語",
      de: "Lokale Sprache",
      es: "Lengua local",
      "pt-br": "Idioma Local",
      fr: "Langue locale",
      "zh-cn": "本地语言",
      "zh-tw": "本地語言",
    },
    languageFront: {
      en: "Language (Front)",
      de: "Sprache (Vorderseite)",
      fr: "Langue (Recto)",
      ja: "言語（フロント）",
      "pt-br": "Idioma (Frente)",
      es: "Lengua (Anverso)",
      "zh-cn": "语言 (正面)",
      "zh-tw": "語言（正面）",
    },
    languageBack: {
      en: "Language (Back)",
      es: "Lengua (Volver)",
      fr: "Langue (Retour)",
      de: "Sprache (Rückseite)",
      ja: "言語（背面）",
      "pt-br": "Idioma (Verso)",
      "zh-tw": "語言 (背面)",
      "zh-cn": "语言（背面）",
    },
    chineseSide: {
      en: "Chinese Side",
      ja: "中国側",
      es: "Lado Chino",
      fr: "Côté Chinois",
      "pt-br": "Lado Chinês",
      de: "Chinesische Seite",
      "zh-cn": "中文面",
      "zh-tw": "中文面",
    },
    japaneseSide: {
      en: "Japanese Side",
      ja: "日本側",
      es: "Lado Japonés",
      de: "Japanische Seite",
      "pt-br": "Lado Japonês",
      fr: "Côté Japonais",
      "zh-cn": "日语面",
      "zh-tw": "日文翻譯",
    },
    textToSpeech: {
      en: "Text-to-Speech",
      es: "Texto a voz",
      fr: "Synthèse vocale",
      ja: "音声合成",
      de: "Text-to-Speech",
      "pt-br": "Texto-para-Fala",
      "zh-tw": "文字轉語音",
      "zh-cn": "文本转语音",
    },
    textToSpeechDescription: {
      en: "Automatically read text aloud",
      fr: "Lecture automatique du texte à haute voix",
      de: "Automatisches Vorlesen von Text",
      es: "Leer automáticamente el texto en voz alta",
      ja: "テキストの自動読み上げ",
      "pt-br": "Leia o texto automaticamente em voz alta",
      "zh-cn": "自动朗读文本",
      "zh-tw": "自動朗讀文字",
    },
    translationDescription: {
      en: "Automatically translate text",
      es: "Traducir texto automáticamente",
      "pt-br": "Texto traduzido automaticamente",
      de: "Automatisches Übersetzen von Text",
      ja: "テキストを自動的に翻訳する",
      fr: "Traduire automatiquement le texte",
      "zh-tw": "自動翻譯文字",
      "zh-cn": "自动翻译文本",
    },
    bothSides: {
      en: "Both Sides",
      fr: "Deux côtés",
      ja: "両面",
      de: "Beide Seiten",
      es: "Ambos Lados",
      "pt-br": "Ambos os Lados",
      "zh-cn": "双面",
      "zh-tw": "雙面",
    },
    goBack: {
      de: "Zurückgehen",
      en: "Back",
      fr: "Retourner",
      "pt-br": "Voltar",
      "zh-cn": "返回",
      "zh-tw": "返回",
      es: "Volver",
      ja: "戻る",
    },
    default: {
      de: "Standard",
      en: "Default",
      fr: "Défaut",
      "pt-br": "Padrão",
      "zh-cn": "默认",
      "zh-tw": "默認",
      es: "Por defecto",
      ja: "デフォルト",
    },
    fontSize: {
      de: "Schriftgröße",
      en: "Font Size",
      fr: "Taille de la police",
      "pt-br": "Tamanho da fonte",
      "zh-cn": "字体大小",
      "zh-tw": "字體大小",
      es: "Tamaño de la fuente",
      ja: "文字サイズ",
    },
    fontColor: {
      de: "Schriftfarbe",
      en: "Font Color",
      fr: "Couleur de la police",
      "pt-br": "Cor da fonte",
      "zh-cn": "字体颜色",
      "zh-tw": "字體顏色",
      es: "Color de la fuente",
      ja: "文字色",
    },
    never: {
      de: "Nie",
      en: "Never",
      fr: "Jamais",
      "pt-br": "Nunca",
      "zh-cn": "从未",
      "zh-tw": "從未",
      es: "Nunca",
      ja: "決して",
    },
    on: {
      en: "On",
      "pt-br": "Ligado",
      de: "Auf",
      ja: "オン",
      fr: "Sur",
      es: "En",
      "zh-cn": "上",
      "zh-tw": "上",
    },
    off: {
      en: "Off",
      de: "Aus",
      es: "Fuera de",
      fr: "Off",
      "pt-br": "Desligado",
      ja: "オフ",
      "zh-cn": "关",
      "zh-tw": "關",
    },
    enabled: {
      en: "Enabled",
      de: "Aktiviert",
      es: "Activado",
      fr: "Activé",
      "pt-br": "Habilitado",
      ja: "有効",
      "zh-cn": "开",
      "zh-tw": "開",
    },
    none: {
      de: "Keine",
      en: "None",
      fr: "Aucun",
      "pt-br": "Nenhum",
      "zh-cn": "无",
      "zh-tw": "無",
      es: "Ninguno",
      ja: "なし",
    },
    studyGoal: {
      en: "Daily Study Goal",
      de: "Tägliches Lernziel",
      es: "Objetivo del estudio",
      fr: "Objectif de l'étude",
      "pt-br": "Objetivo do estudo diario",
      "zh-cn": "学习目标",
      "zh-tw": "學習目標",
      ja: "日々の学習目標",
    },
    studyGoalHit: {
      en: "🎉 You hit your study goal! 🎉",
      de: "🎉 Sie haben Ihr Lernziel erreicht! 🎉",
      es: "🎉 ¡Has llegado a tu objetivo de estudio! 🎉",
      fr: "🎉 Vous avez atteint votre objectif d'étude ! 🎉",
      "pt-br": "🎉 Você atingiu seu objetivo de estudo! 🎉",
      "zh-cn": "🎉你达到了你的学习目标！🎉",
      "zh-tw": "🎉你達到了你的學習目標！🎉",
      ja: "🎉 勉強の目標が達成されましたね🎉",
    },
    reminders: {
      en: "Reminders",
      de: "Erinnerungen",
      es: "Recordatorios",
      fr: "Rappels",
      "pt-br": "Lembretes",
      "zh-cn": "提醒",
      "zh-tw": "提醒",
      ja: "リマインダ",
    },
    openSettings: {
      en: "Open Settings",
      de: "Einstellungen öffnen",
      es: "Abrir la configuración",
      fr: "Ouvrir les paramètres",
      "pt-br": "Abrir Configurações",
      "zh-cn": "打开设置",
      "zh-tw": "打開設置",
      ja: "オープン設定",
    },
    enterSearchQuery: {
      de: "Suchanfrage eingeben.",
      en: "Enter a search query.",
      fr: "Saisissez une requête de recherche.",
      "pt-br": "Digite um termo para pesquisar",
      "zh-cn": "请输入搜索内容",
      "zh-tw": "請輸入搜索內容",
      es: "Introduzca una consulta de búsqueda.",
      ja: "検索クエリーを入力します。",
    },
    done: {
      de: "Fertig",
      en: "Done",
      fr: "Terminé",
      "pt-br": "Feito",
      "zh-cn": "完成",
      "zh-tw": "完成",
      es: "Hecho",
      ja: "完了",
    },
    today: {
      de: "Heute",
      en: "Today",
      fr: "Aujourd'hui",
      "pt-br": "Hoje",
      "zh-cn": "今天",
      "zh-tw": "今天",
      es: "Hoy",
      ja: "今日",
    },
    lastSeen: {
      de: "Zuletzt gesehen",
      en: "Last Seen",
      fr: "Dernières vues",
      "pt-br": "Visto por último",
      "zh-cn": "上次复习时间",
      "zh-tw": "上次復習時間",
      es: "Última vez que se vio",
      ja: "最後に見た",
    },
    views: {
      de: "Aufrufe",
      en: "Views",
      fr: "Vues",
      "pt-br": "Vizualizações",
      "zh-cn": "浏览次数",
      "zh-tw": "瀏覽次數",
      es: "Vistas",
      ja: "ビュー",
    },
    updated: {
      de: "Aktualisiert",
      en: "Updated",
      fr: "Mise à jour de",
      "pt-br": "Atualizado",
      "zh-cn": "更新时间",
      "zh-tw": "更新時間",
      es: "Actualizado",
      ja: "更新情報",
    },
    more: {
      de: "Mehr",
      en: "More",
      fr: "Plus de",
      "pt-br": "Mais",
      "zh-cn": "更多",
      "zh-tw": "更多",
      es: "Más",
      ja: "もっと見る",
    },
    recording: {
      en: "Recording...",
      de: "Aufnahme...",
      es: "Grabación...",
      fr: "Enregistrement...",
      "pt-br": "Gravando...",
      "zh-cn": "录音。。。",
      "zh-tw": "錄音。。。",
      ja: "レコーディング...",
    },
    stopRecording: {
      en: "Push OK to finish recording.",
      de: "Drücken Sie OK, um die Aufnahme zu beenden.",
      es: "Presione OK para finalizar la grabación.",
      fr: "Appuyez sur OK pour terminer l’enregistrement.",
      "pt-br": "Aperte OK pra parar de gravar.",
      "zh-cn": "按确定完成录制。",
      "zh-tw": "按確定完成錄製。",
      ja: "OKを押して、録音を終了します。",
    },
    audioRecordingNotSupported: {
      en: "Audio recording not supported.",
      de: "Audioaufnahme wird nicht unterstützt.",
      es: "No se admite la grabación de audio.",
      fr: "Enregistrement audio non pris en charge.",
      "pt-br": "Gravação de áudio não suportada.",
      "zh-cn": "不支持的录音。",
      "zh-tw": "不支持的錄音。",
      ja: "音声録音には対応していません。",
    },
    fetching: {
      de: "Laden...",
      en: "Fetching...",
      fr: "La recherche...",
      "pt-br": "Buscando...",
      "zh-cn": "加载中...",
      "zh-tw": "加載中...",
      es: "Buscando...",
      ja: "フェッチ...",
    },
    clickToEdit: {
      en: "Click to edit",
      de: "Zum Bearbeiten klicken",
      es: "Haga clic para editar",
      fr: "Cliquez pour modifier",
      "pt-br": "Clique para editar",
      "zh-cn": "点击编辑",
      "zh-tw": "點擊編輯",
      ja: "クリックで編集",
    },
    clientOutdated: {
      de: "Die App ist veraltet. Bitte aktualisieren Sie, um fortzufahren. Besuchen Sie für weitere Informationen das AnkiApp Hilfe- und Supportportal.",
      en: "App out of date. You must update to continue. Check AnkiApp help and support portal for more information.",
      fr: "L'application n'est plus à jour. Vous devez mettre à jour pour continuer. Consultez le portail d'aide et de support d'AnkiApp pour plus d'informations.",
      "pt-br":
        "Aplicativo desatualizado. Você precisa atualizar para continuar. Verifique o portal de ajuda e suporte da AnkiApp para mais informações.",
      "zh-cn": "您的客户端已过期. 请及时更新以便继续使用暗记App. 如需帮助请联系客服",
      "zh-tw": "您的客戶端已過期. 請及時更新以便繼續使用暗記App. 如需幫助請聯繫客服",
      es: "La aplicación no está actualizada. Debes actualizarla para continuar. Consulta el portal de ayuda y soporte de AnkiApp para más información.",
      ja: "アプリが古くなりました。続行するにはアップデートする必要があります。詳細はAnkiAppヘルプとサポートポータルを確認してください。",
    },
    appName: {
      de: "AnkiApp",
      en: "AnkiApp",
      fr: "AnkiApp",
      "pt-br": "AnkiApp",
      "zh-cn": "暗记App",
      "zh-tw": "暗記App",
      es: "AnkiApp",
      ja: "AnkiApp",
    },
    localeName: {
      de: "Deutsch",
      en: "English",
      fr: "Français",
      "pt-br": "Português",
      "zh-cn": "简体中文",
      "zh-tw": "繁體中文",
      es: "Español",
      ja: "日本語",
    },
    interface: {
      de: "Oberfläche",
      en: "Interface",
      fr: "Interface",
      "pt-br": "Interface",
      "zh-cn": "界面",
      "zh-tw": "界面",
      es: "Interfaz",
      ja: "インターフェース",
    },
    account: {
      de: "Konto",
      en: "Account",
      fr: "Compte",
      "pt-br": "Conta",
      "zh-cn": "用户信息",
      "zh-tw": "用戶信息",
      es: "Cuenta",
      ja: "アカウント",
    },
    device: {
      de: "Gerät",
      en: "Device",
      fr: "Dispositif",
      "pt-br": "Dispositivo",
      "zh-cn": "其他信息",
      "zh-tw": "其他信息",
      es: "Dispositivo",
      ja: "デバイス",
    },
    recent: {
      en: "Recent",
      de: "Kürzlich",
      es: "Recientes",
      fr: "Récent",
      "pt-br": "Recentes",
      "zh-cn": "最近",
      "zh-tw": "最近",
      ja: "最近の",
    },
    history: {
      de: "Verlauf",
      en: "History",
      fr: "Histoire",
      "pt-br": "Histórico",
      "zh-cn": "记录",
      "zh-tw": "記錄",
      es: "Historia",
      ja: "沿革",
    },
    audio: {
      de: "Audio",
      en: "Audio",
      fr: "Audio",
      "pt-br": "Áudio",
      "zh-cn": "音频",
      "zh-tw": "音頻",
      es: "Audio",
      ja: "オーディオ",
    },
    autoplayAudio: {
      de: "Audio automatisch abspielen",
      en: "Auto-play Audio",
      fr: "Lecture automatique audio",
      "pt-br": "Reproduzir áudio automaticamente",
      "zh-cn": "自动播放音频",
      "zh-tw": "自動播放音頻",
      es: "Reproducción automática de audio",
      ja: "オーディオの自動再生",
    },
    no: {
      de: "Nein",
      en: "No",
      fr: "Non",
      "pt-br": "Não",
      "zh-cn": "否",
      "zh-tw": "否",
      es: "No",
      ja: "いいえ",
    },
    yes: {
      de: "Ja",
      en: "Yes",
      fr: "Oui",
      "pt-br": "Sim",
      "zh-cn": "是",
      "zh-tw": "是",
      es: "Sí",
      ja: "はい",
    },
    fontSizePrompt: {
      de: "Geben Sie die Schriftgröße als Prozentwert ein.",
      en: "Enter size as a percentage.",
      fr: "Entrez la taille en pourcentage.",
      "pt-br": "Insira o tamanho em porcentagem.",
      "zh-cn": "请输入字体大小比例（百分比）",
      "zh-tw": "請輸入字體大小比例（百分比）",
      es: "Introduzca el tamaño en forma de porcentaje.",
      ja: "サイズをパーセントで入力します。",
    },
    next: {
      de: "Nächstes",
      en: "Next",
      fr: "Suivant",
      "pt-br": "Próximo",
      "zh-cn": "下一项",
      "zh-tw": "下一項",
      es: "Siguiente",
      ja: "次のページ",
    },
    sort: {
      de: "Sortieren",
      en: "Sort",
      fr: "Trier",
      "pt-br": "Ordenar",
      "zh-cn": "排序",
      "zh-tw": "排序",
      es: "Ordenar",
      ja: "ソート",
    },
    all: {
      de: "Alle",
      en: "All",
      fr: "Tous",
      "pt-br": "Todos",
      "zh-cn": "全部",
      "zh-tw": "全部",
      es: "Todo",
      ja: "すべて",
    },
    noUndo: {
      de: "Dies kann nicht rückgängig gemacht werden.",
      en: "This cannot be undone.",
      fr: "Cela ne peut être défait.",
      "pt-br": "Isso não pode ser desfeito.",
      "zh-cn": "操作后不可还原。",
      "zh-tw": "操作後不可還原。",
      es: "Esto no se puede deshacer.",
      ja: "これは元に戻せません。",
    },
    justNow: {
      de: "Gerade eben",
      en: "Just now",
      fr: "A l'instant",
      "pt-br": "Agora mesmo",
      "zh-cn": "刚刚",
      "zh-tw": "剛剛",
      es: "Ahora mismo",
      ja: "たった今",
    },
    milestones: {
      de: "Meilensteine",
      en: "Milestones",
      fr: "Jalons",
      "pt-br": "Marcos",
      "zh-cn": "里程碑",
      "zh-tw": "里程碑",
      es: "Hitos",
      ja: "マイルストーン",
    },
    milestonesNone: {
      de: "Wie wäre es mit einem Meilenstein? Mach weiter und beobachte deinen Fortschritt! 🚀",
      en: "How about setting a milestone? Keep going and watch your progress soar! 🚀",
      fr: "Et si vous fixiez un jalon? Continuez et voyez vos progrès s'envoler! 🚀",
      "pt-br": "Que tal definir um marco? Continue e veja seu progresso decolar! 🚀",
      "zh-cn": "设定一个里程碑如何？继续前进，看到你的进步飙升！🚀",
      "zh-tw": "設定一個里程碑怎麼樣？繼續前進，看到你的進步飛躍！🚀",
      es: "¿Qué tal establecer un hito? ¡Sigue adelante y observa cómo crece tu progreso! 🚀",
      ja: "マイルストーンを設定してみませんか？続けて、あなたの進捗を見てください！🚀",
    },
    features: {
      de: "Funktionen",
      en: "Features",
      fr: "Caractéristiques",
      "pt-br": "Características",
      "zh-cn": "特殊功能",
      "zh-tw": "特殊功能",
      es: "Características",
      ja: "特徴",
    },
    expires: {
      de: "Läuft ab",
      en: "Expires",
      fr: "Expiration :",
      "pt-br": "Expira",
      "zh-cn": "过期时间",
      "zh-tw": "過期時間",
      es: "Expira",
      ja: "期限切れ",
    },
    attention: {
      de: "Achtung",
      en: "Attention",
      fr: "Attention",
      "pt-br": "Atenção",
      "zh-cn": "注意",
      "zh-tw": "注意",
      es: "Atención",
      ja: "注意事項",
    },
    multiPlatformPitch: {
      en: "AnkiApp works on all your devices.",
      de: "AnkiApp funktioniert auf all Ihren Geräten.",
      es: "AnkiApp funciona en todos tus dispositivos.",
      fr: "AnkiApp fonctionne sur tous vos appareils.",
      "pt-br": "A AnkiApp funciona em todos os seus dispositivos.",
      "zh-cn": "AnkiApp 可在您的所有设备上运行。",
      "zh-tw": "AnkiApp 可在您的所有設備上運行。",
      ja: "AnkiAppは、あなたのすべてのデバイスで動作します。",
    },
    emailMeDownloadLinks: {
      en: "Email me download links",
      de: "E-Mail mit Download-Links schicken",
      es: "Envíeme por correo electrónico los enlaces de descarga",
      fr: "Envoyez-moi les liens de téléchargement par courriel",
      "pt-br": "Me envie um e-mail com links para download",
      "zh-cn": "通过电子邮件向我发送下载链接",
      "zh-tw": "通過電子郵件向我發送下載鏈接",
      ja: "ダウンロードリンクをメールする",
    },
    emailSent: {
      en: "Sent! Check your email.",
      de: "Gesendet! Überprüfen Sie Ihre E-Mail.",
      es: "¡Enviado! Comprueba tu correo electrónico.",
      fr: "Envoyé ! Vérifiez votre courriel.",
      "pt-br": "Enviado! Verifique seu e-mail.",
      "zh-cn": "已发送!",
      "zh-tw": "已發送!",
      ja: "送信されましたメールを確認してください。",
    },
    emailSendFailed: {
      en: "Failed to send email. Please try again or contact support@ankiapp.com for help.",
      de: "Fehler beim Senden der E-Mail. Bitte versuchen Sie es erneut oder kontaktieren Sie support@ankiapp.com für Hilfe.",
      es: "No se ha podido enviar el correo electrónico. Por favor, inténtelo de nuevo o póngase en contacto con support@ankiapp.com para obtener ayuda.",
      fr: "L'envoi du courriel a échoué. Veuillez réessayer ou contacter support@ankiapp.com pour obtenir de l'aide.",
      "pt-br":
        "Falha ao enviar o e-mail. Por favor, tente novamente ou contate support@ankiapp.com para obter ajuda.",
      "zh-cn": "发送电子邮件失败。请再试一次或联系 support@ankiapp.com 寻求帮助。",
      "zh-tw": "發送電子郵件失敗。請再試一次或聯繫 support@ankiapp.com 尋求幫助。",
      ja: "メールの送信に失敗しました。再度お試しいただくか、support@ankiapp.com までお問い合わせください。",
    },
    screenshots: {
      en: "Screenshots",
      de: "Bildschirmfotos",
      es: "Capturas de pantalla",
      fr: "Captures d'écran",
      ja: "スクリーンショット",
      "pt-br": "Capturas de tela",
      "zh-tw": "截圖",
      "zh-cn": "截图",
    },
    id: {
      de: "ID",
      en: "ID",
      fr: "ID",
      "pt-br": "ID",
      "zh-cn": "ID",
      "zh-tw": "ID",
      es: "ID",
      ja: "ID",
    },
  },
};
