import ScreenComponent from "@components/screen";
import { IonRefresher, IonRefresherContent, RefresherEventDetail } from "@ionic/react";
import EventBus from "eventBus";
import { History } from "history";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import L10n from "localization";
import { usePushNotifPermissionRequest } from "pushnotifs";
import React from "react";
import { useShareDeckModal } from "./studyGroups/shareDeckModal";
import { useStudyGroup } from "./studyGroups/useStudyGroups";
import Menu from "./studyGroup/menu";
import Body from "./studyGroup/body";

export function StudyGroupScreen(props: {
  id: string;
  history: History;
}): JSX.Element {
  const { id, history } = props;
  const [group, fetchGroup] = useStudyGroup(id);

  React.useEffect(() => {
    function handlePushNotif({ groupID }: { groupID: string }) {
      if (groupID === id) {
        fetchGroup();
      }
    }

    EventBus.on("studyGroupPushNotifReceived", handlePushNotif);
    return () => {
      EventBus.off("studyGroupPushNotifReceived", handlePushNotif);
    };
  }, [id, fetchGroup]);

  usePushNotifPermissionRequest();

  function handleDismiss() {
    history.replace("/groups");
  }

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    fetchGroup().finally(() => {
      event.detail.complete();
    });
  }

  function handleShareSuccess() {
    fetchGroup();
    showToast({
      color: "success",
      duration: 200,
      message: L10n.localize((s) => s.general.done),
    });
  }
  const [showShareModal] = useShareDeckModal(group.val?.id ?? "", handleShareSuccess);
  const [showToast] = useDismissibleToast();

  return (
    <ScreenComponent
      title={group.val?.name}
      helpQuery="groups"
      content={
        group.error ? null : (
          <>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <Body
              group={group.val}
              loading={group.loading}
              refetchGroup={fetchGroup}
              openShareModal={showShareModal}
            />
          </>
        )
      }
      defaultBackLink="/groups"
      rightButton={
        !group.loading ? (
          <Menu
            onDismiss={handleDismiss}
            group={group.val}
            fetchGroup={fetchGroup}
            openShareModal={showShareModal}
          />
        ) : undefined
      }
    />
  );
}
