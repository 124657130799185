import EditableValueWrapper from "@cardRendering/editableValueWrapper";
import Template from "@cardRendering/template";
import Value from "@cardRendering/value";
import { ITypedBlobURL } from "@data/idb";
import { DeckFields, fieldMap } from "@models/deck";
import { Orientation } from "@models/deckSettings";
import { Layout } from "@models/layout";
import {
  FlipStabilizationSetting,
  defaultFlipStabilizationSetting,
} from "@screens/deck/flipStabilizationSelectorItem";
import { fieldNameWithLang } from "fields/components/fieldValueEditorItem";
import React from "react";
import { ID } from "types/ID";
import CardSide, { ICardSideSharedProps } from "./cardSide";

type ICardSidesProps = {
  persistDownloadedBlobs?: boolean;
  disableAudioControlsRendering?: boolean;
  values: Record<string, string>;
  disableTouchEvents: boolean;
  editable: boolean;
  layoutName: string;
  templates: string[];
  onFieldClick?: (fieldKey: string) => void;
  onFieldBlur?: (fieldKey: string) => void;
  visibleSide: 0 | 1;
  refreshCounter?: number;
  fields?: DeckFields;
  orientation?: Orientation;
  blobIDToURL?: Record<ID, ITypedBlobURL>;
  flipStabilization?: FlipStabilizationSetting;
  query?: string;
  deckID?: ID;
} & ICardSideSharedProps;

export default function CardSides(props: ICardSidesProps): JSX.Element | null {
  const { visibleSide, rubberbandScroll, autoplayAudio, overflow } = props;
  const {
    persistDownloadedBlobs = true,
    disableAudioControlsRendering,
    templates,
    values,
    disableTouchEvents,
    editable,
    layoutName,
    onFieldClick,
    onFieldBlur,
    refreshCounter,
    fields,
    orientation = "normal",
    blobIDToURL,
    flipStabilization = defaultFlipStabilizationSetting,
    query,
  } = props;

  const sideRenderers = React.useMemo(() => {
    const templateName = `WrappedTemplate${layoutName}`;

    const [front, back] = Layout.imageStabilizedTemplates(
      [templates[0], templates[1]],
      flipStabilization,
    );

    return [
      Template(front || "", templateName, disableTouchEvents),
      Template(back || "", templateName, disableTouchEvents),
    ];
  }, [templates, layoutName, disableTouchEvents, flipStabilization]);

  const compiledRendererProps: Record<string, any> = {};

  const fmap = fieldMap(fields);
  const vmap = values;

  // In legacy decks, values will exist without corresponding fields.
  // For fields with source refs, a field will exist without a corresponding value.
  // So, here we take the union of field names from both those places.
  const fieldNames = new Set<string>(Object.keys(values));
  fields?.forEach((field) => fieldNames.add(field.name));

  for (const fieldName of fieldNames) {
    // NOTE: fields with source refs (e.g. TTS) may have no value.
    const v = values[fieldName];
    const field = fmap[fieldName];
    const value = Value({
      value: v ?? "",
      values: vmap,
      blobIdToObjectUrl: blobIDToURL,
      options: {
        disableTouchEvents,
        disableAudioPlayback: false,
        persistDownloadedBlobs,
        disableAudioControlsRendering,
        orientation,
        query: query,
      },
      field,
      fieldMap: fmap,
    });

    if (!editable) {
      compiledRendererProps[fieldName] = value;
    } else {
      compiledRendererProps[fieldName] = (valueProps: any) => {
        // Check if the value of this field (v) is missing, in which case pass undefined.
        // That will result in display of some placeholder instructions to click to edit.
        const body = v ? typeof value === "function" && value(valueProps) : undefined;
        return (
          <EditableValueWrapper
            label={fieldNameWithLang(field, fieldName)}
            onClick={() => onFieldClick?.(fieldName)}
            onBlur={() => onFieldBlur?.(fieldName)}
          >
            {body}
          </EditableValueWrapper>
        );
      };
    }
  }

  return (
    <CardSide
      refreshCounter={refreshCounter}
      sideNum={visibleSide}
      rubberbandScroll={rubberbandScroll}
      autoplayAudio={autoplayAudio}
      overflow={overflow}
      sideRenderers={sideRenderers}
      compiledRendererProps={compiledRendererProps}
    />
  );
}
