import { showPrompt } from "@components/prompt";
import { IDB_TRUE } from "@data/idb";
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { IDeck } from "@models/deck";
import { useYesNoPrompt } from "hooks/util/useConfirmCancellationDialog";
import { addOutline } from "ionicons/icons";
import L10n from "localization";
import Network from "network";
import React, { useCallback, useEffect, useState } from "react";
import { ID } from "types/ID";
import StudyGroupDeckItem from "../decks/studyGroupDeckItem";
import { userIsOwner } from "./lib";
import { IGroup } from "./types";
import { superCache } from "hooks/data/superCache";

function useIsLocalDeck(id: string): boolean {
  const [local, setLocal] = useState(superCache.decks?.get(id)?.local === IDB_TRUE);

  useEffect(() => {
    function handleStatusChange({ ID }: { ID: string }) {
      if (ID === id) {
        setLocal(superCache.decks?.get(id)?.local === IDB_TRUE);
      }
    }

    superCache.events.on("deckUpdated", handleStatusChange);
    return () => {
      superCache.events.off("deckUpdated", handleStatusChange);
    };
  }, [id]);

  return local;
}

export function SharedDeck(props: {
  deck: IDeck;
  onRemoveDeck?: (deckId: ID) => void;
}) {
  const { deck, onRemoveDeck } = props;
  const { id } = deck;

  const isLocal = useIsLocalDeck(id);

  return (
    <StudyGroupDeckItem
      key={id}
      deck={deck}
      name={deck.name}
      type={isLocal ? "shared-local" : "shared-remote"}
      href={`/decks/local/${id}`}
      onRemoveDeck={onRemoveDeck}
    />
  );
}

export default function SharedDecks(props: {
  group: IGroup;
  fetchGroup: () => void;
  onShare: () => void;
}) {
  const { group, fetchGroup, onShare } = props;
  const { decks } = group;

  const isOwner = userIsOwner(group);

  const presentRemoveDeckConfirmation = useYesNoPrompt(
    L10n.localize((s) => s.actions.confirm),
    L10n.localize((s) => s.groups.confirmRemoveDeck),
  );
  const handleRemoveDeck = useCallback(
    (deckID: ID) =>
      presentRemoveDeckConfirmation(async () => {
        try {
          await Network.fetch("DELETE", `/users/groups/${group.id}/decks/${deckID}`);
          fetchGroup();
        } catch {
          showPrompt({
            prompt: L10n.localize((s) => s.error.internal),
            promptType: "alert",
            title: L10n.localize((s) => s.general.attention),
          });
        }
      }),
    [presentRemoveDeckConfirmation, fetchGroup, group.id],
  );

  if (!decks) {
    return (
      <IonList inset>
        <IonItem lines="none" onClick={onShare} detail button color="light">
          <IonLabel>
            <h1>{L10n.localize((s) => s.sharing.shareDeck)}</h1>
            <p>{L10n.localize((s) => s.sharing.shareDeckDescription)}</p>
          </IonLabel>
        </IonItem>
      </IonList>
    );
  }

  return (
    <IonList inset>
      <IonListHeader>
        <IonLabel>{L10n.localize((s) => s.deck.shared)}</IonLabel>
        <IonButton onClick={onShare}>
          <IonIcon icon={addOutline} />
        </IonButton>
      </IonListHeader>
      {(decks ?? []).map((deck) => (
        <SharedDeck
          key={deck.id}
          deck={deck}
          onRemoveDeck={isOwner ? handleRemoveDeck : undefined}
        />
      ))}
    </IonList>
  );
}
