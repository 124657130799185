import idb, { IDB_TRUE } from "@data/idb";
import { remoteDeckToIDBDeck } from "@data/remoteDecks";
import { Deck, IDeckData } from "@models/deck";
import { IResponse } from "@models/response";
import { setName } from "data/stores/name";
import { IDevice } from "device";
import EventBus from "eventBus";
import FeatureFlags, { IFeatureFlags } from "featureFlags";
import { superCache } from "hooks/data/superCache";
import L10n from "localization";
import { Setting, setSettingLocally } from "settings";
import Device from "../device";
import Globals from "../globals";
import INativeWindow from "../interfaces/INativeWindow";
import Log from "../log";
import Network from "../network";
import { Operation } from "./operation";

declare global {
  interface Window {
    __immediateLogout: () => Promise<void>;
  }
}

interface NavigatorWithUserLanguage extends Navigator {
  userLanguage?: string;
}

// Define the possible types for setting value
type SettingValue = string | number | boolean;

export interface IUserProduct {
  id: string;
  customer_id: string;
  expires_at: string;
  will_auto_renew: boolean;
}

export interface IMilestone {
  icon: string;
  headline: string | null;
  achieved_at: string;
  description: string | null;
  achievedAtDate?: Date;
}

export interface IAccountInfo {
  created_at: string;
  email: string;
  has_unlimited: boolean;
  products?: IUserProduct[];
  flags?: IFeatureFlags;
  name_first?: string;
  name_last?: string;
  milestones?: { [key: string]: IMilestone };
}

interface ILoginResponseDeck {
  id: string;
  status: Deck.DeckStatus;
  name: string;
  description: string;
  layout_id: string;
  created_at: string;
  modified_at: string;
  tags?: string[];
}

export interface ILoginResponse {
  user: IUser;
  device: IDevice;
  subscription_decks?: ILoginResponseDeck[];
  user_decks?: ILoginResponseDeck[];
  share_decks?: ILoginResponseDeck[];
  responses: IResponse[];
  settings: string;
}

export interface ICreateUserResponse {
  user: IUser;
  device: IDevice;
  decks: IDeckData[];
}

export interface IUser {
  id: string;
  email: string;
  password: string;
  created_at: string;
  locale: string;
  name_first?: string;
  name_last?: string;
}

export namespace User {
  export function email(): string {
    return localStorage["AnkiApp.user.email"];
  }

  export function updateHasUnlimited() {
    FeatureFlags.invalidateFlagCache();
  }

  export function getNumberOfResponsesForUser() {
    return idb.db.count("responses");
  }

  export async function deleteAccount(passwordVerify: string) {
    const userId = localStorage["AnkiApp.user.id"];
    if (!userId) {
      return;
    }

    await Network.fetch("DELETE", `/users/${userId}/`, {
      verify: btoa(passwordVerify),
    });
    await User.logout();
  }

  export function id(): string {
    return localStorage["AnkiApp.user.id"];
  }

  export function isLoggedIn(): boolean {
    return Device.hasToken();
  }

  export async function doLogin(loginResponse: ILoginResponse) {
    localStorage.clear();
    localStorage["AnkiApp.version"] = Globals.version;
    localStorage["AnkiApp.database.schema"] = Globals.dbSchemaVersion;
    if (loginResponse.settings) {
      const settings = JSON.parse(loginResponse.settings);
      for (const [k, v] of Object.entries(settings)) {
        setSettingLocally(k as Setting, v as SettingValue);
      }

      const locale = settings?.selectedLocale;
      if (locale) {
        L10n.switchLocale(locale);
      }
    }
    Device.update(loginResponse.device);
    localStorage["AnkiApp.user.email"] = loginResponse.user.email;

    localStorage["AnkiApp.user.id"] = loginResponse.user.id;
    Log.setUserId(loginResponse.user.id);

    localStorage["AnkiApp.user.created_at"] = loginResponse.user.created_at;

    // NOTE: it's necessary to block on this call so that the user's name gets set before the group join hook fires.
    const { name_first, name_last } = loginResponse.user;
    await setName({ first: name_first, last: name_last });

    await FeatureFlags.updateFlagCache();

    await idb.init();
    await superCache.init();

    const responses = loginResponse.responses || [];

    // Load decks and folders from login response.
    for (const deck of loginResponse.user_decks ?? []) {
      const row = remoteDeckToIDBDeck({ deck });
      await Deck.migrateDeckFromNet(row);
    }
    for (const deck of loginResponse.share_decks ?? []) {
      const row = remoteDeckToIDBDeck({ deck, shared: IDB_TRUE });
      await Deck.migrateDeckFromNet(row);
    }
    for (const deck of loginResponse.subscription_decks ?? []) {
      const row = remoteDeckToIDBDeck({ deck });
      await Deck.migrateDeckFromNet(row);
    }
    const tx = idb.db.transaction("responses", "readwrite");
    const store = tx.objectStore("responses");

    for (let i = 0; i < responses.length; i++) {
      await store.add({
        device_id: responses[i].device_id,
        knol_id: responses[i].knol_id,
        deck_id: responses[i].deck_id,
        layout_id: responses[i].layout_id,
        duration_ms: responses[i].duration_ms,
        created_at: responses[i].created_at,
        score: responses[i].score,
        score_mean: responses[i].score_mean,
        score_standard_deviation: responses[i].score_standard_deviation,
        last_response_at: responses[i].last_response_at,
      });

      if ((i + 1) % 10 === 0 || i === responses.length - 1) {
        EventBus.emit("loginResponsesInsertedBatch", {
          numerator: i + 1,
          denominator: responses.length,
        });
      }
    }
    await tx.done;
  }

  export async function logout(): Promise<void> {
    Network.abortAllFetches();
    Operation.cancelOutstandingSyncRequests();

    try {
      Device.logout();
      localStorage.clear();
      idb.db.clear("decks");
      idb.db.clear("knols");
      idb.db.clear("operations");
      idb.db.clear("responses");
      idb.blobs.clear("blobs");
      idb.blobs.clear("blobUploadQueue");
      idb.blobs.clear("queuedBlobs");
      idb.deleteDB(idb.db);
      idb.deleteDB(idb.blobs);
      superCache.clear();
    } catch {
      // nbd
    } finally {
      window.location = "/auth";
    }
  }

  export async function hasUnlimited(): Promise<boolean> {
    const resp = await Network.fetchWithMetadata<IAccountInfo>({
      action: "GET",
      path: "/users/account",
      handle401: false,
    });
    const info = resp?.[0];
    return info?.has_unlimited;
  }

  export async function authorize(
    action: "create" | "login",
    email: string,
    password: string,
  ): Promise<ILoginResponse> {
    const baseData = {
      device_identifier: Device.identifier,
      device_name: Device.name,
      device_platform: Device.platform,
      device_version: Device.version,
      email: email.replace(/\s+/g, "").toLowerCase(),
      locale: navigator.language || (navigator as NavigatorWithUserLanguage).userLanguage,
      password: password,
    };

    return Network.fetch<ILoginResponse>("POST", `/users/${action}`, baseData);
  }

  export async function resetPassword(email: string) {
    return Network.fetch("POST", "/users/password/reset", { email });
  }
}

// Expose immediate logout function (for Cypress tests).
declare let window: INativeWindow;

if (typeof window !== "undefined") {
  window.__immediateLogout = User.logout;
}
