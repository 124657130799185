import InfoDot from "@components/infoDot";
import { showPrompt } from "@components/prompt";
import RadioSelectSettingModal from "@components/radioSelectSettingModal";
import SettingsListItemIcon from "@components/settingsListItemIcon";
import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { ReviewMode, ReviewModeSetting } from "@models/deckSettings";
import { DotID, clearDot } from "dots";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback } from "react";
import { PiShuffle } from "react-icons/pi";

interface IProps {
  reviewMode: ReviewModeSetting;
  cardsPerReview: number;
  setReviewMode: (mode: ReviewModeSetting) => void;
}
export default function ReviewModeSettingItem({
  reviewMode,
  cardsPerReview,
  setReviewMode,
}: IProps): JSX.Element {
  const modeIdToDisplayName: Record<ReviewMode, string> = {
    srs: "AnkiApp Advanced SRS",
    shuffled: L10n.localize((s) => s.review.modeShuffled),
    in_created_order: L10n.localize((s) => s.review.modeCreatedOrder),
  };
  const modes: Array<{ mode: ReviewMode; desc: string }> = [
    { mode: "srs", desc: L10n.localize((s) => s.review.modeAnkiAppSRSDescription) },
    { mode: "shuffled", desc: L10n.localize((s) => s.review.modeShuffledDescription) },
    { mode: "in_created_order", desc: L10n.localize((s) => s.review.modeCreatedOrderDescription) },
  ];

  function handleModeSelected(newMode: ReviewMode) {
    if (newMode === reviewMode.name) {
      return;
    }

    if (newMode === "in_created_order") {
      // pop up prompt to ask for number of cards to skip
      // TODO: need to localize this
      showPrompt({
        title: L10n.localize((s) => s.review.createdOrderNumCardsToSkip),
        prompt: L10n.localize((s) => s.review.createdOrderNumCardsToSkipPrompt),
        promptType: "input",
        validationRegex: /^\d+$/,
        default: "0",
        suggestions: ["0", "10", "20", "50"],
        cancelCallback: () => {
          setReviewMode({ name: "in_created_order", skip: 0 });
        },
        callback: (response) => {
          let skip = Number.parseInt(response ?? "0");
          if (Number.isNaN(skip)) {
            skip = 0;
          }
          const newSkip = Math.min(cardsPerReview, skip);
          setReviewMode({ name: "in_created_order", skip: newSkip });
        },
      });
    } else {
      setReviewMode({ name: newMode });
    }
  }

  const options = modes.map(({ mode, desc }) => {
    let name = modeIdToDisplayName[mode];
    if (
      mode === "in_created_order" &&
      reviewMode.name === "in_created_order" &&
      reviewMode.skip > 0
    ) {
      name = `${name} (${L10n.localize((s) => s.funcs.review.numToSkip)(reviewMode.skip)})`;
    }
    return { name, val: mode, desc };
  });

  const dotID: DotID = "review_mode_setting";
  const [showModal] = useIonCardModalWithDismiss(RadioSelectSettingModal, {
    options,
    title: L10n.localize((s) => s.review.mode),
    onChange: handleModeSelected as any, // TODO make types more robust.
    selectedItemVal: reviewMode.name,
    description: L10n.localize((s) => s.review.modeSettingDescription),
  });

  const handleClick = useCallback(() => {
    clearDot(dotID).catch(() => {});
    showModal();
  }, [showModal]);

  const displayName = modeIdToDisplayName[reviewMode.name];

  return (
    <IonItem button onClick={handleClick}>
      <InfoDot id={dotID} />
      <SettingsListItemIcon icon={PiShuffle} />
      <IonLabel>{L10n.localize((s) => s.review.mode)}</IonLabel>
      <IonNote slot="end">
        <div>{displayName}</div>
        <div style={{ fontSize: 14 }}>
          {reviewMode.name === "in_created_order" && reviewMode.skip > 0
            ? L10n.localize((s) => s.funcs.review.numToSkip)(reviewMode.skip)
            : undefined}
        </div>
      </IonNote>
    </IonItem>
  );
}
