import { IonItem, IonLabel, IonList, IonNote } from "@ionic/react";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { useCardModal } from "@screens/card";
import { useFlagAndContext } from "featureFlags";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback, useEffect } from "react";
import { LuFilePlus, LuWand2 } from "react-icons/lu";
import { ID } from "types/ID";
import GenCardsModal from "./genCardsModal";
import { useFunnelLogger } from "analytics/funnel";

interface IProps {
  deck: IDeck;
  knols: MapView<ID, IKnol>;
}
export default function EmptyDeck(props: IProps) {
  const logFunnelEvent = useFunnelLogger("new_deck_event");
  const genFlag = useFlagAndContext("features");
  const hasGenFlag = genFlag?.flag?.ai_card_create !== undefined;
  const [presentGenCardsModal] = useIonCardModalWithDismiss(GenCardsModal, {
    deck: props.deck,
    knols: props.knols,
    conditionalDismiss: true,
  });
  const handleGenerateCardsClicked = useCallback(() => {
    logFunnelEvent("clicked_empty_deck_option", { deck: props.deck, option: "generate_cards" });
    presentGenCardsModal();
  }, [presentGenCardsModal, logFunnelEvent, props.deck]);

  const [presentCardCreateModal] = useCardModal({
    deck: props.deck,
  });
  const handleCreateCardClicked = useCallback(() => {
    logFunnelEvent("clicked_empty_deck_option", { deck: props.deck, option: "create_card" });
    presentCardCreateModal();
  }, [presentCardCreateModal, logFunnelEvent, props.deck]);

  useEffect(() => {
    logFunnelEvent("saw_empty_deck_screen", { deck: props.deck });
  }, [logFunnelEvent, props.deck]);

  return (
    <>
      <IonNote>
        <p className="ion-margin-horizontal ion-text-wrap">
          {L10n.localize((s) => s.deck.emptyDeckMessage)}
        </p>
      </IonNote>
      <IonList inset>
        <IonItem button onClick={handleCreateCardClicked}>
          <div slot="start">
            <LuFilePlus size={24} style={{ color: "var(--ion-color-primary)" }} />
          </div>
          <IonLabel>
            <h1>{L10n.localize((s) => s.card.create)}</h1>
            <p>{L10n.localize((s) => s.card.createFeatureDescription)}</p>
          </IonLabel>
        </IonItem>
      </IonList>

      {hasGenFlag ? (
        <IonList inset>
          <IonItem button onClick={handleGenerateCardsClicked}>
            <div slot="start">
              <LuWand2 size={24} style={{ color: "var(--ion-color-primary)" }} />
            </div>
            <IonLabel>
              <h1>{L10n.localize((s) => s.card.generate)}</h1>
              <p>{L10n.localize((s) => s.generation.featureDescription)}</p>
            </IonLabel>
          </IonItem>
        </IonList>
      ) : undefined}
    </>
  );
}
