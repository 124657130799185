import timeAdjustedScore, { responseDataFromTimeAdjustedScore } from "@core/timeAdjustedScore";
import idb, { IDeckKnolJoinRow } from "@data/idb";
import { dateToYYYYMMDD } from "@lib/dates";
import { Base64UUID, HexUUID, convertToBase64IfNecessary } from "@lib/ids";
import { UTCMillis } from "@lib/timestamps";
import { Operation } from "@models/operation";
import {
  IResponseData,
  IResponseDataFHGE,
  IResponseInsertOperation,
  IResponseObjectParameters,
} from "@operations/response";
import { MAGIC_LAYOUT_ID, MAGIC_LAYOUT_ID_BASE64 } from "fields/magicLayout";
import Globals, { baseScores, TResponseValue } from "globals";
import { IResponseHistory } from "hooks/data/responseHistory";
import { superCache } from "hooks/data/superCache";
import { updateStudyGoalBadgeCount } from "studyGoals";
import { ID } from "types/ID";
import moment from "../../../node_modules/moment/moment";
import Device from "../device";
import { IKnol, Knol } from "./knol";

// Responses as serialized to IndexedDB.
export interface IIDBResponse {
  knol_id: Base64UUID;
  layout_id?: Base64UUID;
  duration_ms: number;
  created_at: UTCMillis;
  score: number;
  data: IResponseData;
}

// Responses stored locally.
export interface ILocalResponse {
  knol_id: string;
  deck_id: string;
  layout_id?: string;
  created_at: UTCMillis;
  duration_ms: number;
  score: number;
  data?: IResponseData;
}

// Legacy responses definition for old IDB responses table.
export interface IResponse {
  knol_id: string;
  deck_id: string;
  device_id: string;
  layout_id: string;
  created_at: string;
  duration_ms: number;
  score: number;
  score_mean: number | null;
  score_standard_deviation: number | null;
  last_response_at: string | null;
}

export namespace Response {
  export function localResponseToIDB(
    resp: ILocalResponse,
  ): { resp: IIDBResponse; kd: IDeckKnolJoinRow } | undefined {
    if (!resp.knol_id || !resp.deck_id) {
      return;
    }
    const knolID = convertToBase64IfNecessary(resp.knol_id as Base64UUID | HexUUID);
    const deckID = convertToBase64IfNecessary(resp.deck_id as Base64UUID | HexUUID);
    const copy: IIDBResponse = {
      knol_id: knolID,
      duration_ms: resp.duration_ms,
      created_at: resp.created_at,
      score: resp.score,
      data: resp.data ?? responseDataFromTimeAdjustedScore(resp.score),
    };
    const kd: IDeckKnolJoinRow = {
      deckID,
      knolID,
    };
    if (
      resp.layout_id &&
      resp.layout_id !== MAGIC_LAYOUT_ID &&
      resp.layout_id !== MAGIC_LAYOUT_ID_BASE64
    ) {
      copy.layout_id = convertToBase64IfNecessary(resp.layout_id as Base64UUID | HexUUID);
    }
    return { resp: copy, kd };
  }

  export async function record(
    knol: IKnol,
    layoutID: ID,
    resp: TResponseValue,
    durationMs: number,
  ): Promise<void> {
    let prevTime: string | null;
    let prevMean: number | null;
    let prevStandardDeviation: number | null;
    const kwg = Knol.GetGrade(knol);

    if (kwg.last_response_at) {
      prevTime = moment.utc(kwg.last_response_at).format();
    } else {
      prevTime = null;
    }

    if (kwg.score_mean) {
      prevMean = kwg.score_mean;
    } else {
      prevMean = null;
    }

    if (kwg.score_standard_deviation) {
      prevStandardDeviation = kwg.score_standard_deviation;
    } else {
      prevStandardDeviation = null;
    }

    const now = new Date().toISOString();

    const baseScore = baseScores[resp];
    const score =
      resp === Globals.RESPONSES.AUTO ? baseScore : timeAdjustedScore(baseScore, durationMs);
    const data: IResponseDataFHGE = { input: resp };

    const r: IResponseObjectParameters = {
      device_id: Device.getID(),
      knol_id: knol.id,
      deck_id: knol.deck_id,
      layout_id: layoutID,
      duration_ms: durationMs,
      created_at: now,
      data,
      score,
      score_mean: prevMean,
      score_standard_deviation: prevStandardDeviation,
      last_response_at: prevTime,
    };

    const op: IResponseInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "response",
      object_parameters: r,
    };

    await Operation.operateAndSave(op);
    updateStudyGoalBadgeCount();
  }

  export function AllHistory(): IResponseHistory {
    const history: IResponseHistory = new Map();
    for (const [knolID, layoutMap] of superCache.responses ?? []) {
      const deckID = superCache.knolIDToDeckID?.get(knolID);
      if (!deckID) {
        continue;
      }
      for (const [, responses] of layoutMap) {
        for (const resp of responses) {
          const date = new Date(resp.createdAt);
          const dateStr = dateToYYYYMMDD(date);
          let dateMap = history.get(dateStr);
          if (!dateMap) {
            dateMap = new Map();
            history.set(dateStr, dateMap);
          }

          const deckCount = dateMap.get(deckID) ?? 0;
          dateMap.set(deckID, deckCount + 1);
        }
      }
    }
    return history;
  }

  export async function totalCount() {
    return idb.db.count("responsesV2");
  }
}
