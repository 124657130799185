import { AsyncVal } from "hooks/util/useAsyncValState";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import Network from "network";
import { useEffect, useState } from "react";
import PDPLogger from "./logger";
import { IServerProduct } from "./types";

export default function useProducts(email: string, logger: PDPLogger): AsyncVal<IServerProduct[]> {
  const [showProductsFetchError] = useErrorAlert({ code: "FETCHING_PRODUCTS" });
  const [productsState, setProductsState] = useState<AsyncVal<IServerProduct[]>>({
    val: undefined,
    loading: false,
    error: undefined,
  });
  useEffect(() => {
    setProductsState({ loading: true });
    Network.fetch<IServerProduct[]>("GET", "/products", {
      locale: L10n.currentLocale,
      email,
    })
      .then((products) => {
        logger.log("products_fetched", { products });
        setProductsState({ val: products, loading: false });
      })
      .catch((error) => {
        logger.log("products_fetch_failed", { err: error });
        setProductsState({ loading: false, error });
        showProductsFetchError(error);
      });
  }, [email, logger, showProductsFetchError]);
  return productsState;
}
