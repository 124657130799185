import { showPrompt } from "@components/prompt";
import logEvent from "analytics";
import { handleIndexedDBRefreshRequiredErrorIfNecessary } from "errors/indexedDBRefreshRequired";
import L10n from "localization";
import { NetworkError } from "network";
import { useCallback } from "react";
import useAlert from "./useAlert";

export const errorCodes = {
  LOADING_DECKS: 1,
  LOADING_CARDS_DATA: 2,
  CREATING_DECK: 3,
  INSERTING_RESPONSE: 4,
  DELETING_DECK: 5,
  EXPORTING_DECK: 6,
  COMPLETING_PURCHASE: 7,
  RESTORING_IAPS: 8,
  PURCHASING_IAP: 9,
  FETCHING_IAPS: 10,
  FETCHING_PRODUCTS: 11,
  DELETING_FOLDER: 12,
  MOVING_DECK: 13,
  ARCHIVING_DECK: 14,
  RESTORING_DECK: 15,
  ARCHIVING_FOLDER: 16,
  NETWORK_ERROR: 17,
  RESETTING_GRADES: 18,
  DOWNLOADING_DECK: 19,
  SAVING_DECK: 20,
  TOGGLING_REVIEW_GRADE: 21,
  REFETCHING_DECKS: 22,
  CHANGING_SETTINGS: 23,
  GENERATING_CARDS: 24,
  PICKING_PHOTO: 25,
  TOGGLING_BETA: 26,
  TAGGING_KNOL: 27,
  MAKING_STUDY_GROUP: 28,
  SHARING_DECK: 29,
  SHARING_TO_STUDY_GROUP: 30,
  COPYING_CARDS: 31,
  COMPLETING_ONBOARDING: 32,
  LOGGING_IN: 33,
} as const;

type ErrorCode = keyof typeof errorCodes;

interface IErrorAlertProps {
  message?: string;
  code: ErrorCode;
}

function errorMessage({
  message = L10n.localize((s) => s.error.internal),
  code,
}: IErrorAlertProps): string {
  return `${message} [CODE ${errorCodes[code]}]`;
}

export default function useErrorAlert({ message, code }: IErrorAlertProps) {
  const [show] = useAlert();
  const showError = useCallback(
    async (error: unknown, extraLogData?: Record<string, unknown>) => {
      if (await handleIndexedDBRefreshRequiredErrorIfNecessary(error)) {
        show(L10n.localize((s) => s.error.temporary));
        return;
      }
      if (error instanceof NetworkError && error.statusCode === 0) {
        show(L10n.localize((s) => s.error.communication));
        return;
      }
      const msg = errorMessage({ message, code });
      show(msg);
      const errorObj: any =
        error instanceof Error
          ? {
              name: error.name,
              message: error.message,
              stack: error.stack,
            }
          : error;
      if (error instanceof NetworkError) {
        errorObj.statusCode = error.statusCode;
      }
      logEvent("showed_error_message", { error: errorObj, ...extraLogData, code });
    },
    [code, message, show],
  );

  return [showError];
}

export function showErrorAlert({ message, code }: IErrorAlertProps) {
  const msg = errorMessage({ message, code });
  showPrompt({
    prompt: msg,
    promptType: "alert",
    title: L10n.localize((s) => s.general.attention),
  });
}
