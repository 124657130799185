import MD5 from "crypto-js/md5";
import { BFF69 } from "fields/langCodes";
import { ID } from "types/ID";
import { SSMLGender } from "./tts";

interface TTSVoice {
  gender?: SSMLGender;
}

export interface ITTSBlobParams {
  text: string;
  lang?: BFF69;
  preferredGender?: SSMLGender;
}

export interface ITTSBlobParamsWithAsyncText {
  text: string | undefined;
  lang?: BFF69;
  preferredGender?: SSMLGender;
}

export interface ITTSBlobParamsWithDeckID extends ITTSBlobParams {
  deckID: ID;
}

// When rendering, values load asynchonously, so text may not immediately be available.
export type TTSRenderParams = ITTSBlobParamsWithAsyncText & { deckID?: ID };

interface ITTSParamsHash {
  lang?: BFF69;
  rate: number;
  text: string;
  voice?: TTSVoice;
}

export function ttsParamsHash({
  text,
  lang,
  preferredGender,
}: {
  text: string;
  lang?: BFF69;
  preferredGender?: SSMLGender;
}): string {
  const ttsParams: ITTSParamsHash = {
    lang,
    rate: 1,
    text,
  };
  if (preferredGender) {
    ttsParams.voice = { gender: preferredGender };
  }
  const ttsParametersJSON = JSON.stringify(ttsParams);
  return MD5(ttsParametersJSON).toString();
}
