export const bff69 = [
  "ar-EG",
  "be-BY",
  "bg-BG",
  "ca-ES",
  "cs-CZ",
  "cy-GB",
  "da-DK",
  "de-DE",
  "el-GR",
  "en-AU",
  "en-GB",
  "en-US",
  "es-ES",
  "es-US",
  "et-EE",
  "eu-ES",
  "fa-IR",
  "fi-FI",
  "fr-CA",
  "fr-FR",
  "ga-IE",
  "gd-GB",
  "gl-ES",
  "he-IL",
  "hi-IN",
  "hr-HR",
  "hu-HU",
  "id-ID",
  "is-IS",
  "it-IT",
  "ja-JP",
  "ka-GE",
  "kk-KZ",
  "km-KH",
  "ko-KR",
  "lo-LA",
  "lt-LT",
  "lv-LV",
  "mi-NZ",
  "mk-MK",
  "mn-MN",
  "ms-MY",
  "ne-NP",
  "nl-NL",
  "no-NO",
  "pl-PL",
  "ps-AF",
  "pt-BR",
  "pt-PT",
  "ro-RO",
  "ru-RU",
  "sa-IN",
  "sk-SK",
  "sl-SI",
  "sm-WS",
  "so-SO",
  "sr-RS",
  "sv-SE",
  "sw-TZ",
  "th-TH",
  "tr-TR",
  "ug-CN",
  "uk-UA",
  "ur-PK",
  "uz-UZ",
  "vi-VN",
  "zh-CN",
  "zh-HK",
  "zh-TW",
] as const;

// Our format for language codes. Like BCP 47.
// e.g. en-US, zh-HK, fr-FR, ...
export type BFF69 = (typeof bff69)[number];

export const validTranslationLangCodes: string[] = [
  "af-ZA",
  "ak-GH",
  "sq-AL",
  "am-ET",
  "ar-EG",
  "hy-AM",
  "as-IN",
  "ay-BO",
  "az-AZ",
  "bm-ML",
  "eu-ES",
  "be-BY",
  "bn-BD",
  "bs-BA",
  "bg-BG",
  "ca-ES",
  "ny-MW",
  "zh-CN",
  "zh-TW",
  "co-FR",
  "hr-HR",
  "cs-CZ",
  "da-DK",
  "dv-MV",
  "nl-NL",
  "et-EE",
  "ee-GH",
  "en-US",
  "tl-PH",
  "fi-FI",
  "fr-FR",
  "fy-NL",
  "gl-ES",
  "lg-UG",
  "ka-GE",
  "de-DE",
  "el-GR",
  "gn-PY",
  "gu-IN",
  "ht-HT",
  "ha-NG",
  "iw-IL",
  "hi-IN",
  "hu-HU",
  "is-IS",
  "ig-NG",
  "id-ID",
  "ga-IE",
  "it-IT",
  "ja-JP",
  "jw-ID",
  "kn-IN",
  "kk-KZ",
  "km-KH",
  "rw-RW",
  "ko-KR",
  "ku-TR",
  "ky-KG",
  "lo-LA",
  "la-VA",
  "lv-LV",
  "ln-CD",
  "lt-LT",
  "lb-LU",
  "mk-MK",
  "mg-MG",
  "ms-MY",
  "ml-IN",
  "mt-MT",
  "mi-NZ",
  "mr-IN",
  "mn-MN",
  "my-MM",
  "ne-NP",
  "no-NO",
  "or-IN",
  "om-ET",
  "ps-AF",
  "fa-IR",
  "pl-PL",
  "pt-BR",
  "pa-IN",
  "qu-PE",
  "ro-RO",
  "ru-RU",
  "sm-WS",
  "sa-IN",
  "gd-GB",
  "sr-RS",
  "st-ZA",
  "sn-ZW",
  "sd-PK",
  "si-LK",
  "sk-SK",
  "sl-SI",
  "so-SO",
  "es-ES",
  "su-ID",
  "sw-TZ",
  "sv-SE",
  "tg-TJ",
  "ta-IN",
  "tt-RU",
  "te-IN",
  "th-TH",
  "ti-ET",
  "ts-ZA",
  "tr-TR",
  "tk-TM",
  "uk-UA",
  "ur-PK",
  "ug-CN",
  "uz-UZ",
  "vi-VN",
  "cy-GB",
  "xh-ZA",
  "yo-NG",
  "zu-ZA",
  "he-IL",
  "jv-ID",
];

export const validTTSLangCodes: BFF69[] = [
  "bg-BG",
  "ca-ES",
  "cs-CZ",
  "da-DK",
  "de-DE",
  "el-GR",
  "en-AU",
  "en-GB",
  "en-US",
  "es-ES",
  "es-US",
  "fi-FI",
  "fr-CA",
  "fr-FR",
  "hi-IN",
  "hu-HU",
  "id-ID",
  "is-IS",
  "it-IT",
  "ja-JP",
  "ko-KR",
  "lv-LV",
  "ms-MY",
  "nl-NL",
  "pl-PL",
  "pt-BR",
  "pt-PT",
  "ro-RO",
  "ru-RU",
  "sk-SK",
  "sr-RS",
  "sv-SE",
  "th-TH",
  "tr-TR",
  "uk-UA",
  "vi-VN",
  "zh-CN",
  "zh-HK",
  "zh-TW",
];
