import { IonCheckbox, IonItem, IonLabel, IonList, IonNote } from "@ionic/react";
import React from "react";
import Price from "./price";
import { IProduct } from "./types";

interface IProps<T extends IProduct> {
  product: T;
  selected?: boolean;
  onClick?: () => void;
}
export default function ProductItem<T extends IProduct>({ product, selected, onClick }: IProps<T>) {
  return (
    <IonList inset style={{ width: "100%" }}>
      <IonItem onClick={onClick}>
        <IonLabel>
          <h2>{product.name}</h2>
          <Price product={product} style={{ color: "var(--ion-color-medium)" }} />
        </IonLabel>
        <IonNote>
          <IonCheckbox checked={selected} />
        </IonNote>
      </IonItem>
    </IonList>
  );
}
