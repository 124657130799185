import UpgradeBanner from "@components/banner/upgradeBanner";
import Empty from "@components/empty";
import { showPrompt } from "@components/prompt";
import { IonButton, IonIcon, IonList } from "@ionic/react";
import { Deck } from "@models/deck";
import ScreenComponent from "components/screen";
import EventBus from "eventBus";
import { IDeckListDeckRow, useDeckList } from "hooks/data/deckList";
import useErrorAlert from "hooks/util/useErrorAlert";
import { trashBinOutline } from "ionicons/icons";
import L10n from "localization";
import React, { useCallback } from "react";
import DeckItem from "./decks/deckItem";
import { deckListRowMatchesQuery } from "./search/deckResults";

function ArchiveItem(props: {
  row: IDeckListDeckRow;
}): JSX.Element | null {
  const { row } = props;
  const { deck } = row;

  const name = Deck.displayName(deck) ?? L10n.localize((s) => s.general.none);

  const [showRestoreError] = useErrorAlert({ code: "RESTORING_DECK" });
  const [showDeleteError] = useErrorAlert({ code: "DELETING_DECK" });

  const handleClick = useCallback(() => {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.archive.confirmRestore),
      promptType: "confirm",
      callback: async () => {
        try {
          await Deck.unarchive(deck);
          EventBus.emit("deckUpdated", { ID: deck.id });
        } catch (err) {
          showRestoreError(err);
        }
      },
    });
    return;
  }, [deck]);

  async function handleDelete() {
    if (!deck) {
      return;
    }

    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.deck.confirmRemovalDangerous),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.delete).toLocaleUpperCase(),
      callback: async () => {
        try {
          await Deck.PermanentlyDeleteDeck(deck.id);
        } catch (err) {
          showDeleteError(err);
        }
      },
    });
  }

  return (
    <DeckItem
      key={deck.id}
      type={row.type}
      name={name}
      onClick={handleClick}
      numKnols={row.count}
      hideMetadata
      hideStatusIcon
      local={deck.local}
      shared={deck.shared}
      onDelete={handleDelete}
      archived
    />
  );
}

export function ArchiveBody({ query }: { query?: string | undefined }) {
  const deckList = useDeckList({ archiveOnly: true });
  const matchingRows =
    query !== undefined
      ? deckList.rows.filter((r) => deckListRowMatchesQuery(r, query))
      : deckList.rows;
  const decks: IDeckListDeckRow[] = [];
  for (const row of matchingRows) {
    if (row.type === "deck") {
      decks.push(row);
    }
  }

  if (deckList.rows.length < 1) {
    return <Empty text={L10n.localize((s) => s.archive.empty)} />;
  }

  return (
    <IonList>
      {decks.map((r) => (
        <ArchiveItem key={r.type + r.deck.id} row={r} />
      ))}
    </IonList>
  );
}

export function DeleteAllFromArchiveButton(): JSX.Element | null {
  const deckList = useDeckList({ archiveOnly: true });
  const [showDeleteError] = useErrorAlert({ code: "DELETING_DECK" });

  // Get all archived deck IDs
  const deckIDs = deckList.rows.filter((row) => row.type === "deck").map((row) => row.deck.id);

  // Disable button if no decks to delete
  const noDecksToDelete = deckIDs.length === 0;

  async function handleDeleteAll() {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.deck.confirmRemovalAllDecksDangerous),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.delete).toLocaleUpperCase(),
      callback: async () => {
        try {
          await Deck.deleteBatch(deckIDs);
        } catch (err) {
          showDeleteError(err);
        }
      },
    });
  }

  if (!noDecksToDelete) {
    return (
      <IonButton onClick={handleDeleteAll} disabled={noDecksToDelete}>
        <IonIcon color="danger" icon={trashBinOutline} />
      </IonButton>
    );
  }
  return null;
}

export default function ArchiveScreen(): JSX.Element {
  const [query, setQuery] = React.useState<string>();

  return (
    <ScreenComponent
      id="main"
      slug="Archive"
      defaultBackLink="/"
      headerBanner={<UpgradeBanner />}
      title={L10n.localize((s) => s.archive.noun)}
      onLiveSearch={setQuery}
      searchDebounceMs={50}
      rightButton={<DeleteAllFromArchiveButton />}
      content={<ArchiveBody query={query} />}
    />
  );
}
