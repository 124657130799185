import TTSWrapper from "@cardRendering/ttsWrapper";
import { IonIcon } from "@ionic/react";
import { ITTSBlobParamsWithAsyncText, TTSRenderParams, ttsParamsHash } from "@lib/ttsParamsHash";
import AudioBlob from "cardRendering/audioBlob";
import { IFieldValueProps } from "fields/fieldType";
import { DeckFieldMap, IField, fieldTypeMap } from "fields/fields";
import { reading } from "fields/japaneseLib";
import { appLang } from "fields/lang";
import { validTTSLangCodes } from "fields/langCodes";
import { FieldValues, fieldLangFollowingRef } from "fields/lib";
import { hasFieldRef, ttsRefFieldString } from "fields/sources/lib";
import { IFieldTTS, ITTSFieldValue, sampleTTSText, sampleTTSURL } from "fields/types/tts";
import useAsyncBlobVal from "hooks/util/useAsyncBlobVal";
import useNetworkStatus from "hooks/util/useNetworkStatus";
import { radioOutline } from "ionicons/icons";
import { visibleOn } from "lib/orientation";
import React, { useEffect, useMemo, useState } from "react";
import TextValue from "./textValue";

interface IFieldRef {
  name: string;
  field: IField;
  val: string;
}
export function getRef(
  field: IField,
  fieldMap: DeckFieldMap,
  values: FieldValues,
): IFieldRef | undefined {
  if (field.source?.type !== "ref") {
    return;
  }
  const name = field.source.name;
  return { name, field: fieldMap[name], val: values[name] };
}

// useTTSParams gets the text and lang to use for this field,
// asynchronously computing text, if necessary (for 日本語 refs).
function useTTSParams(
  parsedVal: ITTSFieldValue | null | undefined,
  field: IFieldTTS,
  fieldMap: DeckFieldMap,
  values: FieldValues,
): ITTSBlobParamsWithAsyncText {
  const ref = getRef(field, fieldMap, values);
  const lang = fieldLangFollowingRef(field, fieldMap) ?? appLang();

  // When this TTS field sources from a japanese field, we need to
  // asynchonously process that source field's text before rendering
  // the TTS value, so initialize as undefined.
  const needsAsyncTextProcessing = ref?.field.type === "japanese";
  const parsedText = parsedVal?.text;
  const defaultText = needsAsyncTextProcessing ? undefined : parsedText;
  const [text, setText] = useState<string | undefined>(defaultText);

  // Asynchronously compute text.
  useEffect(() => {
    // If the ref field is not available, update using the default text,
    // which may be undefined, if needsAsyncTextProcessing is true.
    const useDefaultText = !ref?.field || !ref.val;
    if (useDefaultText) {
      setText(defaultText);
      return;
    }

    const refText = ttsRefFieldString(ref.field, ref.val);
    if (!refText) {
      return;
    }

    // Japanese refs must be tokenized in the same way as the furigana,
    // to avoid discrepancy between TTS reading and furigana reading.
    if (ref.field.type === "japanese") {
      reading(refText).then((r) => setText(r));
      return;
    }

    setText(refText);
  }, [ref?.field, ref?.val, defaultText]);

  return { text, lang, preferredGender: field.attributes?.gender };
}

function TTSValue({
  value,
  values,
  field,
  fieldMap,
  ctx,
  options,
  blobIdToObjectUrl,
}: IFieldValueProps<IFieldTTS>): JSX.Element | null {
  const parsedVal = value !== undefined ? fieldTypeMap.tts.loadFML(value) : undefined;
  const { text, lang, preferredGender } = useTTSParams(parsedVal, field, fieldMap, values);

  const ttsParams = useMemo((): TTSRenderParams => {
    return {
      text,
      lang,
      preferredGender,
      deckID: options.deckID,
    };
  }, [lang, text, preferredGender]);
  const ttsImpossible = !text || (lang && !validTTSLangCodes.includes(lang));
  const id = ttsImpossible ? undefined : ttsParamsHash({ text, lang, preferredGender });
  const audioVal = useAsyncBlobVal(
    id,
    text === sampleTTSText && lang === undefined,
    sampleTTSURL,
    blobIdToObjectUrl,
    options.persistDownloadedBlobs,
    ttsParams,
  );

  const networkStatus = useNetworkStatus();
  const offline = networkStatus?.connected === false;

  const hasNonTranslationSourceRef =
    hasFieldRef(field.source) && field.source?.type !== "translation";

  if (ttsImpossible) {
    return (
      <TextValue
        value={text ?? ""}
        values={values}
        field={field}
        ctx={ctx}
        options={options}
        fieldMap={fieldMap}
      />
    );
  }

  if (offline && !audioVal) {
    return (
      <TTSWrapper
        autoplayAudio={ctx.autoplayAudio ?? false}
        lang={lang}
        rate={1.0}
        disableClick={options.disableTouchEvents ?? false}
      >
        <TextValue
          value={text ?? ""}
          values={values}
          field={field}
          ctx={ctx}
          options={options}
          fieldMap={fieldMap}
        />
      </TTSWrapper>
    );
  }

  const { sideNum } = ctx;
  const visible =
    sideNum === undefined ? true : visibleOn(sideNum, field.sides, options.orientation);

  let preventOrchestration = !visible;
  if (sideNum === 1 && visibleOn(0, field.sides, options.orientation)) {
    preventOrchestration = true;
  }

  return (
    <div>
      {hasNonTranslationSourceRef ? undefined : <div>{text}</div>}
      <AudioBlob
        id={audioVal?.id}
        url={audioVal?.url}
        preventOrchestration={preventOrchestration}
      />
    </div>
  );
}

// NOTE: this wrapper is just to handle the case of TTS values in the card editor,
// where we don't want to be live-updating (and making new TTS requests) as the user
// updates the TTS source text.
export default function PotentiallyDisabledTTSValue(
  props: IFieldValueProps<IFieldTTS>,
): JSX.Element | null {
  if (props.options.disableAudioControlsRendering) {
    const { field, value } = props;
    const hasNonTranslationSourceRef =
      hasFieldRef(field.source) && field.source?.type !== "translation";
    const parsedVal = value !== undefined ? fieldTypeMap.tts.loadFML(value) : undefined;
    return (
      <div>
        {hasNonTranslationSourceRef ? undefined : <div>{parsedVal?.text}</div>}
        <IonIcon icon={radioOutline} />
      </div>
    );
  }
  return <TTSValue {...props} />;
}
