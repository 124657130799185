import { folderDisplayString } from "@components/folderWithSubfoldersString";
import cardPriority from "@core/cardPriority";
import { Grade } from "@core/grade";
import idb, { IDBAppSchema, IDB_FALSE, IndexedDBBoolean } from "@data/idb";
import { subFolderMagicSeparator } from "@data/lib/folders";
import { filterIterable } from "@lib/iterable";
import * as Folder from "@models/folder";
import {
  IDeckDeleteBatchOperation,
  IDeckDeleteOperation,
  IDeckInsertOperation,
  IDeckUpdateOperation,
} from "@operations/deck";
import { IDeckTagDeleteOperation, IDeckTagInsertOperation } from "@operations/deckTag";
import { ISubscriptionInsertOperation } from "@operations/subscription";
import { genSourceImageFieldName } from "@screens/deck/genCardsModal";
import deckDownloadManager from "deckDownloadManager";
import EventBus from "eventBus";
import { IField, fieldTypeMap } from "fields/fields";
import { ALL_LAYOUT_ID, isModernDeck } from "fields/magicLayout";
import { getResponseHistoryForDeck } from "hooks/data/responseHistory";
import { superCache } from "hooks/data/superCache";
import { IDBPTransaction, StoreNames } from "idb";
import JSZip from "jszip";
import moment from "moment";
import { ID } from "types/ID";
import { DeckSetting, IDeckSettings } from "./deckSettings";
import { IGrade, IKnol, Knol } from "./knol";
import { ILayout, Layout } from "./layout";
import { Operation } from "./operation";

export interface IDeck {
  id: string;
  created_at?: Date;
  modified_at: Date;
  last_reviewed_at?: Date;
  config?: IDeckConfig;
  status?: Deck.DeckStatus;
  name: string;
  description?: string;
  layout_id: ID;
  layouts?: ILayout[];
  tags?: string[];
  local?: IndexedDBBoolean;
  shared?: IndexedDBBoolean;
  migrated?: IndexedDBBoolean;
  user_config?: IDeckUserConfig;
}

export const dummyDeck: IDeck = {
  id: "BADBADBAD",
  modified_at: new Date(0),
  name: "",
  layout_id: "",
};

export interface ICardsDataOptions {
  withTags?: string[];
  limit?: number;
  offset?: number;
  order?: string;
  query?: string;
}

export interface IDeckUserConfig {
  settings?: Partial<IDeckSettings>;
  lastResetAt?: Date;
}

export interface IDeckData {
  status: Deck.DeckStatus;
  reviewTag?: string;
  id: string;
  source?: string;
  name: string;
  num_knols?: number;
  saved_knols?: number;
  layout_id: string;
  description: string;
  created_at: string;
  modified_at: string;
  tags?: string[];
  layouts?: ILayout[];
  knols?: IKnol[];
  download_uri?: string;
  config?: IDeckConfig;
  user_config?: IDeckUserConfig;
}

export interface IGradeHistogram {
  IGNORED: number;
  MARKED: number;
  NEW: number;
  F: number;
  E: number;
  D: number;
  C: number;
  B: number;
  A: number;
}

export interface IDeckConfig {
  base?: string;
  fields: DeckFields;
}
export type DeckFields = IField[];

export function setsEqual<T>(s1: Set<T>, s2: Set<T>) {
  // Check if both sets are of the same size
  if (s1.size !== s2.size) return false;

  // Check if every element in set1 is present in s2
  for (const item of s1) {
    if (!s2.has(item)) {
      return false;
    }
  }

  return true;
}

export function fieldMap(fields?: DeckFields): Record<string, IField> {
  const map: Record<string, IField> = {};
  if (fields) {
    for (const field of fields) {
      map[field.name] = field;
    }
  }
  return map;
}

export const archiveTag = "archive";

export function exportDeckToFML(deck: IDeck, knolsWithGrades: Iterable<IKnol>): string {
  const name = Deck.nameForExport(deck);
  const deckName = name.replaceAll('"', "'");
  const deckTags = deck.tags?.join(",");
  let data = `<deck name="${deckName}"${deckTags ? ` tags='${deckTags}'` : ""}><fields>`;
  const fields = deck.config?.fields;
  if (fields) {
    for (const f of fields) {
      const field = fieldTypeMap[f.type].fmlTag;
      data = data.concat(
        `<${field} name='${f.name}' sides='${f.sides.join("")}'${
          !f.attributes
            ? ""
            : Object.entries(f.attributes)
                .map((a) => {
                  if (a[1]) {
                    return ` ${a[0]}='${a[1]}'`;
                  }
                  return "";
                })
                .join(" ")
        }>`,
      );

      if (f.source) {
        data = data.concat("<sources>");
        switch (f.source.type) {
          case "ref":
            data = data.concat(`<${f.source.type} name="${f.source.name}" />`);
            break;
          case "translation":
            data = data.concat("<translation>");
            if (f.source.source && f.source.source.type === "ref") {
              data = data.concat(`<${f.source.source.type} name="${f.source.source.name}" />`);
            }
            data = data.concat("</translation>");
            break;
        }
        data = data.concat("</sources>");
      }
      data = data.concat(`</${field}>`);
    }
  }
  data = data.concat("</fields><cards>");

  const fmap = fieldMap(fields);
  for (const k of knolsWithGrades) {
    data = data.concat("<card>");
    if (k.values) {
      for (const [key, value] of Object.entries(k.values)) {
        const field = fmap[key];
        const fieldType = fieldTypeMap[field?.type] ?? fieldTypeMap.richtext;
        const tagName = fieldType.fmlTag;

        let fieldValue = value;

        // Replace <br> with <br/> and &nbsp; with <br/> to prevent
        // error when importing due to invalid strict XML.
        fieldValue = fieldValue.replace(/<br>/g, "<br/>").replace(/&nbsp;/g, "<br/>");

        data = data.concat(`<${tagName} name='${key}'>${fieldValue}</${tagName}>`);
      }
    }
    data = data.concat("</card>");
  }

  data = data.concat("</cards></deck>");
  return data;
}

export async function exportDeckToFMLZip(deck: IDeck, knolsWithGrades: Iterable<IKnol>) {
  const data = exportDeckToFML(deck, knolsWithGrades);
  const dataBinary = new TextEncoder().encode(data);
  const zip = new JSZip();
  const name = Deck.nameForExport(deck);
  zip.file(`${name}.xml`, dataBinary);
  let blobFolder: JSZip | null = null;
  const tx = idb.blobs.transaction("blobs", "readonly");
  const store = tx.objectStore("blobs");

  // Retrieve all records from the store
  const allRows = await store.getAll();
  for (const row of allRows) {
    if (row.deckID === deck.id) {
      if (!blobFolder) {
        blobFolder = zip.folder("blobs");
      }
      blobFolder?.file(row.id, row.blob);
    }
  }
  await tx.done;
  const zipFile = await zip.generateAsync({ type: "blob" });
  return zipFile;
}

export namespace Deck {
  export const KNOLS_PER_FETCH = 20;
  export const STATUS_UNKNOWN = 0;
  export const STATUS_PRIVATE = 1;
  export const STATUS_SHARED = 2;
  export const STATUS_PUBLIC = 3;
  export const STATUS_THIRD_PARTY = 4;
  export const STATUS_ANKI_SHARED = 5;

  export type DeckStatus =
    | typeof STATUS_UNKNOWN
    | typeof STATUS_PRIVATE
    | typeof STATUS_SHARED
    | typeof STATUS_PUBLIC
    | typeof STATUS_THIRD_PARTY
    | typeof STATUS_ANKI_SHARED;

  export async function find(id: ID): Promise<IDeck | undefined> {
    return idb.db.get("decks", id);
  }

  export async function GetAll(): Promise<IDeck[]> {
    return idb.db.getAll("decks");
  }

  export function lastReviewedAt(deck: IDeck): Date | undefined {
    if (deck.last_reviewed_at) {
      return deck.last_reviewed_at;
    }
    const hist = getResponseHistoryForDeck(deck.id);
    let maxDateStr: string | undefined;
    for (const dateStr of hist?.keys() ?? []) {
      if (maxDateStr === undefined || dateStr > maxDateStr) {
        maxDateStr = dateStr;
      }
    }
    if (maxDateStr) {
      return new Date(maxDateStr);
    }
  }

  export function needsSubscription(status: DeckStatus): boolean {
    return [STATUS_PUBLIC, STATUS_THIRD_PARTY, STATUS_ANKI_SHARED].includes(status);
  }

  function folder(deck: IDeck): string | undefined {
    return deck.tags?.[0];
  }

  export function displayName(deck?: IDeck, addFolderPrefix = true): string {
    if (!deck) {
      return "";
    }

    const formattedName = folderDisplayString(deck.name);
    const parts = [formattedName];

    // Add folder to front if there is one.
    const f = folder(deck);
    if (f && addFolderPrefix && f !== archiveTag) {
      parts.unshift(f);
    }

    return folderSeparatorFormattedName(Folder.join(parts));
  }

  export function folderSeparatorFormattedName(name: string): string {
    return folderDisplayString(name);
  }

  export function nameForExport(deck: IDeck): string {
    return deck.name?.replace(/[<>:"\/\\|?*\x00-\x1F]/g, "_") ?? "deck";
  }

  export async function execResetGradesOperation(deckID: ID) {
    const deck: IDeck | undefined = await idb.db.get("decks", deckID);
    if (!deck) {
      return;
    }
    const userConfig = deck.user_config ?? {};
    userConfig.lastResetAt = new Date();
    const op: IDeckUpdateOperation = {
      ...Operation.operationDefaults(),
      object_parameters: {
        id: deckID,
        user_config: {
          ...deck.user_config,
          lastResetAt: new Date(),
        },
      },
      object_type: "deck",
      type: "UPDATE",
    };
    return Operation.operate(true, [op]);
  }

  export async function insert(
    id: ID,
    name: string,
    description: string | undefined,
    config: IDeckConfig,
    folder?: string,
  ) {
    const now = new Date().toISOString();

    const deckOp: IDeckInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "deck",
      object_parameters: {
        id,
        status: 1, // private
        name,
        tags: folder ? [{ name: folder }] : undefined,
        description: description,
        created_at: now,
        modified_at: now,
        config,
      },
    };

    const subOp: ISubscriptionInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "subscription",
      object_parameters: {
        user_id: localStorage["AnkiApp.user.id"],
        deck_id: id,
        modified_at: now,
        deck_name: name,
        deck_description: description,
      },
    };
    await Operation.operate(true, [deckOp, subOp]);
  }

  export async function resetGrades(
    deckID: ID,
    tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
  ) {
    const knolsStore = tx.objectStore("knols");
    const idx = knolsStore.index("deck_id");
    let cursor = await idx.openCursor(IDBKeyRange.only(deckID));
    let i = 0;
    while (cursor) {
      cursor.update({ ...cursor.value, grades: undefined });
      cursor = await cursor.continue();
      EventBus.emit("knolGradeReset", { numerator: i });
      i++;
    }
  }

  export async function setSettings(deck: IDeck, settings: Partial<IDeckSettings>) {
    if (!deck) {
      return;
    }
    const userConfig = deck.user_config ?? {};
    const userSettings: Partial<IDeckSettings> = {
      ...userConfig.settings,
      ...settings,
    };
    userConfig.settings = userSettings;

    const op: IDeckUpdateOperation = {
      ...Operation.operationDefaults(),
      object_parameters: {
        id: deck.id,
        user_config: userConfig,
      },
      object_type: "deck",
      type: "UPDATE",
    };
    return Operation.operateAndSave(op);
  }

  export async function setSetting<T extends DeckSetting>(
    deck: IDeck | undefined,
    setting: T,
    value: IDeckSettings[T],
  ) {
    if (!deck) {
      return;
    }
    return Deck.setSettings(deck, { [setting]: value });
  }

  export function cardsWithGrade(cards: Iterable<IKnol>, targetGrades: Grade[]): Iterable<IKnol> {
    return filterIterable(cards, (card) => {
      const grade = Knol.GetGrade(card);
      return Knol.isGradeInSet(grade, targetGrades);
    });
  }

  export function gradeHistogram(knols: Iterable<IKnol>): IGradeHistogram {
    const histogram = {
      IGNORED: 0,
      MARKED: 0,
      NEW: 0,
      F: 0,
      E: 0,
      D: 0,
      C: 0,
      B: 0,
      A: 0,
    };
    for (const k of knols) {
      const g = Grade(cardPriority(Knol.GetGrade(k)));
      if (g === "AA" || g === "AAA") {
        // Combine A grade variations.
        histogram.A += 1;
      } else if (g) {
        histogram[g] += 1;
      }
    }
    return histogram;
  }

  export async function numKnols(deckID: string): Promise<number> {
    const tx = idb.db.transaction("knols", "readonly");
    const store = tx.objectStore("knols");
    const index = store.index("deck_id");

    let count = 0;
    let cursor = await index.openCursor(IDBKeyRange.only(deckID));

    while (cursor) {
      count++;
      cursor = await cursor.continue();
    }

    await tx.done;
    return count;
  }

  export async function numCards(deckID: string, layoutID?: string): Promise<number> {
    if (layoutID) {
      // TODO
    }
    return numKnols(deckID);
  }

  export async function addTag(deckID: string, tagName: string): Promise<void> {
    const op: IDeckTagInsertOperation = {
      ...Operation.operationDefaults(),
      object_parameters: {
        deck_id: deckID,
        tag_name: tagName,
      },
      object_type: "deck_tag",
      type: "INSERT",
    };
    return Operation.operateAndSave(op);
  }

  export async function deleteTag(deckID: string, tagName: string): Promise<void> {
    const op: IDeckTagDeleteOperation = {
      ...Operation.operationDefaults(),
      type: "DELETE",
      object_type: "deck_tag",
      object_parameters: {
        deck_id: deckID,
        tag_name: tagName,
      },
    };
    return Operation.operateAndSave(op);
  }

  export async function PermanentlyDeleteDeck(deckID: ID): Promise<void> {
    const op: IDeckDeleteOperation = {
      ...Operation.operationDefaults(),
      type: "DELETE",
      object_type: "deck",
      object_parameters: {
        id: deckID,
      },
    };
    return Operation.operateAndSave(op);
  }

  export async function deleteBatch(deckIDs: ID[]): Promise<void> {
    if (deckIDs.length < 1) {
      return;
    }
    const op: IDeckDeleteBatchOperation = {
      ...Operation.operationDefaults(),
      type: "DELETE",
      object_type: "deck",
      object_parameters: {
        ids: deckIDs,
      },
    };
    return Operation.operateAndSave(op);
  }

  export function knolTags(
    knols?: Iterable<IKnol>,
    exclude: string[] = [Knol.MARKED_TAG, Knol.IGNORE_TAG],
  ): string[] {
    if (!knols) {
      return [];
    }
    const tagCounts: Record<string, number> = {};
    for (const knol of knols) {
      for (const tag of knol?.tags ?? []) {
        if (!exclude.includes(tag)) {
          tagCounts[tag] = (tagCounts[tag] || 0) + 1;
        }
      }
    }
    const sortedTags = Object.entries(tagCounts)
      .sort((a, b) => b[1] - a[1])
      .map((entry) => entry[0]);
    return sortedTags;
  }

  export function sortKnols(order: string): (a: IKnol, b: IKnol) => number {
    return (a: IKnol, b: IKnol) => {
      let result = 0;
      let aGrade: IGrade;
      let bGrade: IGrade;
      switch (order) {
        case "cards.created_at ASC":
          result =
            (a.created_at ? a.created_at.getTime() : 0) -
            (b.created_at ? b.created_at.getTime() : 0);
          break;
        case "cards.created_at DESC":
          result =
            (b.created_at ? b.created_at.getTime() : 0) -
            (a.created_at ? a.created_at.getTime() : 0);
          break;
        case "cards.score_mean ASC":
          aGrade = Knol.GetGrade(a);
          bGrade = Knol.GetGrade(b);
          result = (aGrade.score_mean ?? 0) - (bGrade.score_mean ?? 0);
          break;
        case "cards.score_mean DESC":
          aGrade = Knol.GetGrade(a);
          bGrade = Knol.GetGrade(b);
          result = (bGrade.score_mean ?? 0) - (aGrade.score_mean ?? 0);
          break;
        case "cards.last_response_at ASC":
          aGrade = Knol.GetGrade(a);
          bGrade = Knol.GetGrade(b);
          result =
            (aGrade.last_response_at ? new Date(aGrade.last_response_at).getTime() : 0) -
            (bGrade.last_response_at ? new Date(bGrade.last_response_at).getTime() : 0);
          break;
        case "cards.last_response_at DESC":
          aGrade = Knol.GetGrade(a);
          bGrade = Knol.GetGrade(b);
          result =
            (bGrade.last_response_at ? new Date(bGrade.last_response_at).getTime() : 0) -
            (aGrade.last_response_at ? new Date(aGrade.last_response_at).getTime() : 0);
          break;
      }
      return result;
    };
  }

  export async function cardsData(
    decks: IDeck[],
    options: ICardsDataOptions = {},
  ): Promise<IKnol[]> {
    if (decks.length === 0) {
      return [];
    }

    const { withTags = [] } = options;

    const knolsWithGrades: IKnol[] = [];

    async function processKnol(deck: IDeck, knol: IKnol) {
      if (knol.tags?.includes(Knol.IGNORE_TAG)) {
        return;
      }

      if (withTags.length > 0 && !withTags.every((tag) => knol.tags?.includes(tag))) {
        return;
      }

      if (!knol.grades || !knol.grades[deck.layout_id]) {
        knol.grades ??= {};
        knol.grades[deck.layout_id] = Knol.getDefaultGradeValues();
      }
      knolsWithGrades.push(knol);
    }

    async function processDeck(deck: IDeck) {
      for (const knolID of superCache.deckIDToKnolIDs?.get(deck.id) ?? []) {
        const knol = superCache.knols?.get(knolID);
        if (knol) {
          await processKnol(deck, knol);
        }
      }
    }

    for (const deck of decks) {
      await processDeck(deck);
    }
    if (options.order) {
      knolsWithGrades.sort(Deck.sortKnols(options.order));
    }
    return knolsWithGrades;
  }

  export function cardsAveragePriority(cards?: Iterable<IKnol>) {
    if (!cards) {
      return Number.NaN;
    }
    // TODO: use running mean algorithm.
    let [sum, n] = [0, 0];
    for (const card of cards) {
      const pri = cardPriority(Knol.GetGrade(card));
      if (!Number.isNaN(pri)) {
        sum += pri;
        n += 1;
      }
    }
    return sum / n;
  }

  export async function averagePriority(deck: IDeck, options = {}) {
    const cardsData = await Deck.cardsData([deck], options);
    return Deck.cardsAveragePriority(cardsData);
  }

  export function hasNoReviews(cards?: Iterable<IKnol>): boolean {
    const pri = Deck.cardsAveragePriority(cards);
    const noReviews = Number.isNaN(pri);
    return noReviews;
  }

  export async function gradeAndChangeToday(
    deck: IDeck,
    callback: (grade: number | null, change: number) => void,
  ) {
    const avgPriority = await Deck.averagePriority(deck, {});
    // avgPriority is NaN if the deck is "NEW"
    let currentGrade: number | null = Math.max(0, 1 - avgPriority);
    currentGrade = Math.round(currentGrade * 1000000) / 1000000; // precision(6)
    const currDate = moment();
    const today = currDate.format("YYYYMMDD");
    const yesterday = currDate.subtract(1, "days").format("YYYYMMDD");
    let changeToday = 0;
    const cache = JSON.parse(localStorage[`AnkiApp.cache.averageGradePerDay.${deck.id}`] || "{}");
    const gradeYesterday = cache[yesterday];
    if (gradeYesterday) {
      // change today is delta from yesterday
      changeToday = currentGrade - gradeYesterday || 0;
    } else {
      cache[yesterday] = currentGrade || 0;
    }
    cache[today] = currentGrade;
    localStorage[`AnkiApp.cache.averageGradePerDay.${deck.id}`] = JSON.stringify(cache);
    if (Number.isNaN(currentGrade)) {
      currentGrade = null;
    }
    callback(currentGrade, changeToday);
  }

  export async function getNakedKnols(deckID: string, limit?: number): Promise<string[]> {
    const tx = idb.db.transaction("knols", "readonly");
    const store = tx.objectStore("knols");
    const index = store.index("deck_id");

    const nakedKnolIDs: string[] = [];
    let cursor = await index.openCursor(IDBKeyRange.only(deckID));

    while (cursor && (limit === undefined || nakedKnolIDs.length < limit)) {
      if (!cursor.value.values) {
        nakedKnolIDs.push(cursor.value.id);
      }
      cursor = await cursor.continue();
    }

    await tx.done;
    return nakedKnolIDs;
  }

  export function namePath(name: string, folder?: string): string {
    const parts = [name];
    if (folder) {
      parts.unshift(folder);
    }
    return Folder.join(parts);
  }

  // moveToFolder removes deck from all the folders it's currently in, and moves it to folder.
  export async function moveToFolder(deck: IDeck, folder: string): Promise<void> {
    // Remove from existing folders.
    const ops: Array<IDeckTagDeleteOperation | IDeckTagInsertOperation> = [];
    for (const tag of deck.tags ?? []) {
      const op = await genRemoveFromFolderOperation(deck.id, tag);
      ops.push(op);
    }

    // Add to new folder.
    const op: IDeckTagInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "deck_tag",
      object_parameters: {
        deck_id: deck.id,
        tag_name: folder,
      },
    };
    ops.push(op);

    return Operation.operate(true, ops);
  }

  export async function archive(deckID: ID): Promise<void> {
    const op: IDeckTagInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "deck_tag",
      object_parameters: {
        deck_id: deckID,
        tag_name: archiveTag,
      },
    };
    await Operation.operate(true, [op]);
    return removeDecksFromLocal(deckID);
  }

  export async function unarchive(deck: IDeck): Promise<void> {
    const op: IDeckTagDeleteOperation = {
      ...Operation.operationDefaults(),
      type: "DELETE",
      object_type: "deck_tag",
      object_parameters: {
        deck_id: deck.id,
        tag_name: archiveTag,
      },
    };
    await Operation.operate(true, [op]);
    for (const tag of deck.tags ?? []) {
      await Folder.createFolder(tag);
    }
  }

  export async function removeFromFolder(deckID: ID, folder: string): Promise<void> {
    const op = await genRemoveFromFolderOperation(deckID, folder);
    return Operation.operateAndSave(op);
  }

  async function genRemoveFromFolderOperation(
    deckID: ID,
    folder: string,
  ): Promise<IDeckTagDeleteOperation> {
    const op: IDeckTagDeleteOperation = {
      ...Operation.operationDefaults(),
      type: "DELETE",
      object_type: "deck_tag",
      object_parameters: {
        deck_id: deckID,
        tag_name: folder,
      },
    };
    return op;
  }

  export function isArchived(deck: IDeck): boolean {
    return (deck.tags ?? []).includes(archiveTag);
  }

  export function getAllDecksInFolders(
    folder?: string | null,
    opts: { archiveOnly?: boolean; includeArchived?: boolean; includeSubFolders?: boolean } = {
      includeSubFolders: false,
      includeArchived: false,
      archiveOnly: false,
    },
  ): IDeck[] {
    const decks: IDeck[] = [];

    const isInFolder = (deck: IDeck): boolean => {
      if (!deck.tags) return false;
      if (opts.includeSubFolders) {
        return deck.tags.some(
          (tag) => tag === folder || tag.startsWith(`${folder}${subFolderMagicSeparator}`),
        );
      }
      return deck.tags.includes(folder as string);
    };

    for (const deck of superCache.decks?.values() ?? []) {
      if (folder === null || folder === undefined) {
        // Root-level decks (not in any folder)
        decks.push(deck);
      } else {
        // Decks in a specific folder
        if (isInFolder(deck)) {
          decks.push(deck);
        }
      }
    }

    if (opts.archiveOnly) {
      return decks.filter((deck) => Deck.isArchived(deck));
    }
    if (opts.includeArchived) {
      return decks;
    }
    return decks.filter((deck) => !Deck.isArchived(deck));
  }

  export async function migrateDeckFromNet(netDeck: IDeck): Promise<boolean> {
    let changed = false; // Tracks whether any change was made.
    const netTags = netDeck.tags ?? [];
    const tx = idb.db.transaction(["decks", "folders"], "readwrite");
    let deckEvent: { e: "deckAdded" | "deckMoved"; ID: ID } | null = null;
    // Load folders.
    let folderAdded = false;
    const expandedTags = Folder.expandIntermediateFolders(netTags);
    for (const tag of expandedTags) {
      const row = await tx.objectStore("folders").get(tag);
      if (!row) {
        await tx.objectStore("folders").add({ name: tag });
        changed = true;
        folderAdded = true;
      }
    }
    // Load deck.
    const idbDeck = await tx.objectStore("decks").get(netDeck.id);
    if (idbDeck) {
      // Ensure it has all the tags it should.
      // NOTE: it is critical that operations sync happens prior to this logic, otherwise tags deleted locally could be resurrected from an out-of-date server response.
      const netTagsSet = new Set(netTags);
      const rowTagsSet = new Set(idbDeck.tags ?? []);
      if (!setsEqual(netTagsSet, rowTagsSet)) {
        const combinedSet = new Set([...netTagsSet, ...rowTagsSet]);
        const tags = Array.from(combinedSet);
        await tx.objectStore("decks").put({ ...idbDeck, tags: tags });
        changed = true;
        deckEvent = { e: "deckMoved", ID: idbDeck.id };
      }
    } else {
      // remote deck but no local deck, add it
      await tx.objectStore("decks").put(netDeck);
      changed = true;
      deckEvent = { e: "deckAdded", ID: netDeck.id };
    }
    await tx.done;

    if (folderAdded) {
      EventBus.emit("folderCreated");
    }
    if (deckEvent) {
      EventBus.emit(deckEvent.e, { ID: deckEvent.ID });
    }

    return changed;
  }

  export async function removeDecksFromLocal(deckID: ID) {
    await idb.update(idb.db, "decks", deckID, {
      local: IDB_FALSE,
    });
    EventBus.emit("deckRemoved", { deckIDs: [deckID] });
  }

  export async function addTagToDeck(deckID: ID, tag: string) {
    const d = await idb.db.get("decks", deckID);
    if (d) {
      const allTags = new Set(d.tags);
      allTags.add(tag);
      const newTags = Array.from(allTags);
      return idb.update(idb.db, "decks", deckID, {
        tags: newTags,
      });
    }
  }

  export async function removeTagFromDeck(deckID: ID, tag: string) {
    const d = await idb.db.get("decks", deckID);
    if (d) {
      const newTags = d.tags?.filter((t) => t !== tag);
      return idb.update(idb.db, "decks", deckID, {
        tags: newTags,
      });
    }
  }

  export async function setDeckConfig(deckID: ID, config: IDeckConfig) {
    // NOTE: this will NOT create a new row if one doesn't exist.
    // That should never happen because we're migrating decks into IDB on boot.
    const now = new Date();
    return idb.update(idb.db, "decks", deckID, {
      config,
      updatedAt: now,
    });
  }

  export async function recentDecks(N: number): Promise<IDeck[]> {
    const tx = idb.db.transaction("decks", "readonly");
    const allDecks = await tx.store.getAll();
    allDecks.sort((a, b) => b.modified_at.getTime() - a.modified_at.getTime());
    return allDecks.slice(0, N);
  }

  export function fieldOrderFromFields(fields: DeckFields): string[] {
    return fields.map((field) => field.name);
  }

  export function fieldOrderFromLayouts(layouts: ILayout[]): string[] {
    const fieldSet = new Set<string>();
    for (const layout of layouts) {
      for (const template of layout.templates) {
        const fields = Layout.extractFields(template);
        for (const field of fields) {
          fieldSet.add(field);
        }
      }
    }
    return Array.from(fieldSet);
  }

  export function fieldOrderFromDeckOrKnolValues(
    deck: IDeck | undefined,
    fields: DeckFields | undefined,
    values: Record<string, string>,
  ): string[] {
    // Extract keys from selected layout, if present, otherwise infer from knol values.
    let layouts: ILayout[] = [];
    if (deck?.layout_id === ALL_LAYOUT_ID) {
      if (deck?.layouts) {
        layouts = deck?.layouts;
      }
    } else {
      const layout = deck?.layouts?.find((l) => l.id === deck.layout_id);
      if (layout) {
        layouts = [layout];
      }
    }
    const knolKeys = layouts.length > 0 ? fieldOrderFromLayouts(layouts) : Object.keys(values);

    // Legacy support.
    if (!deck || !isModernDeck(deck)) {
      return knolKeys;
    }

    if (!fields || fields.length < 1) {
      return knolKeys;
    }

    const order = fields.map((f) => f.name);
    return order;
  }

  export async function download(deckID: ID): Promise<void> {
    const uri = `/decks/${deckID}`;
    return deckDownloadManager.addDownload(uri);
  }

  export function generationSourceImages(deckID: ID): Set<string> {
    const imgs = new Set<string>();
    for (const id of superCache.deckIDToKnolIDs?.get(deckID) ?? []) {
      const knol = superCache.knols?.get(id);
      const img = knol?.values?.[genSourceImageFieldName];
      if (img) {
        imgs.add(img);
      }
    }
    return imgs;
  }
}
