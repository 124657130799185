import { StepLogger } from "analytics/funnel";
import { History } from "history";
import L10n from "localization";
import React from "react";
import SingleSelectNav, { IPromptOption } from "./singleSelectNav";
import GoalSchoolVectorNav from "./goalSchoolVectorNav";

interface IProps {
  dismiss: () => Promise<void>;
  onAddToInbox: () => void;
  history: History;
  logStep: StepLogger;
}
export default function GoalSchoolNav(props: IProps) {
  const opts: IPromptOption[] = [
    { name: L10n.localize((s) => s.onboarding.schoolSubjectMedicine), val: "medicine" },
    { name: L10n.localize((s) => s.onboarding.schoolSubjectBiology), val: "biology" },
    { name: L10n.localize((s) => s.onboarding.schoolSubjectChemistry), val: "chemistry" },
    { name: L10n.localize((s) => s.onboarding.schoolSubjectPsychology), val: "psychology" },
    { name: L10n.localize((s) => s.onboarding.schoolSubjectEconomics), val: "economics" },
    {
      name: L10n.localize((s) => s.onboarding.schoolSubjectComputerScience),
      val: "computer science",
    },
  ] as const;
  return (
    <SingleSelectNav
      title={L10n.localize((s) => s.onboarding.schoolNavTitle)}
      description={L10n.localize((s) => s.onboarding.schoolNavDescription)}
      options={opts}
      other
      dismiss={props.dismiss}
      history={props.history}
      onNav={(opt) => {
        return (
          <GoalSchoolVectorNav
            dismiss={props.dismiss}
            history={props.history}
            onAddToInbox={props.onAddToInbox}
            logStep={props.logStep}
            subject={opt.val}
            subjectName={opt.name}
          />
        );
      }}
      searchable
    />
  );
}
