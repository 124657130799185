import { ITypedBlobURL } from "@data/idb";
import { TTSRenderParams } from "@lib/ttsParamsHash";
import BlobStore from "data/idb/blobStore";
import { useEffect, useState } from "react";
import { ID } from "types/ID";

export interface IBlobVal {
  id: ID;
  url: string;
}

// useAsyncBlobVal loads a blob from the blob store, and broadcasts events so the audio orchestrator knows when its time to play (if applicable).
export default function useAsyncBlobVal(
  id: ID | undefined,
  useSample: boolean,
  sampleURL: string,
  blobIDToURL: Record<ID, ITypedBlobURL> = {},
  persistDownloadedBlob = true,
  ttsParams?: TTSRenderParams,
  deckID?: ID,
): IBlobVal | undefined {
  const preloadedURL = id && blobIDToURL ? blobIDToURL[id] : undefined;
  const preloadedBlobVal = id && preloadedURL ? { id, url: preloadedURL.url } : undefined;
  const [blobVal, setBlobVal] = useState<IBlobVal>();

  useEffect(() => {
    let url: string | undefined;

    if (preloadedURL) {
      return;
    }

    async function loadBlob() {
      if (!id) {
        setBlobVal(undefined);
        return;
      }

      // HACK: detect and handle field example value.
      if (useSample) {
        setBlobVal({ id, url: sampleURL });
        return;
      }

      const typedURL =
        ttsParams !== undefined
          ? await BlobStore.getTypedBlobURLUncached({
              id,
              persistDownloadedBlob,
              deckID: ttsParams.deckID,
              ttsParams,
            })
          : await BlobStore.getTypedBlobURLUncached({ id, persistDownloadedBlob, deckID });
      url = typedURL?.url;
      if (url) {
        setBlobVal({ id, url });
      } else {
        setBlobVal(undefined);
      }
    }

    loadBlob();

    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [id, useSample, sampleURL, setBlobVal, ttsParams, persistDownloadedBlob]);

  return preloadedBlobVal ?? blobVal;
}
