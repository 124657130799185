import { IDBAppSchema, IDB_TRUE, IQueuedBlobSansDeckID, default as idb } from "@data/idb";
import BlobStore from "@data/idb/blobStore";
import { IDeck, IDeckConfig, IDeckUserConfig } from "@models/deck";
import { Deck } from "@models/deck";
import { IDeckSettings } from "@models/deckSettings";
import { ILayout } from "@models/layout";
import { loadBlobIDsForCard } from "@screens/review/reviewSessionBlobs";
import * as Sentry from "@sentry/browser";
import EventBus from "eventBus";
import { MAGIC_LAYOUT_ID, magicLayoutFor } from "fields/magicLayout";
import { IDBPTransaction, StoreNames } from "idb";
import { ID } from "types/ID";
import { IKnol, Knol } from "../models/knol";
import { IBaseOperation } from "../models/operation";
import { handleIndexedDBRefreshRequiredErrorIfNecessary } from "errors/indexedDBRefreshRequired";

interface IDeckTag {
  name: string;
}

export interface IDeckUpdateOperation extends IBaseOperation {
  object_type: "deck";
  type: "UPDATE";
  object_parameters: {
    id: string;
    created_at?: string;
    description?: string;
    layout_id?: string;
    modified_at?: string;
    name?: string;
    status?: Deck.DeckStatus;
    layouts?: ILayout[];
    tags?: Array<{ name: string }>;
    knols?: IKnol[];
    config?: IDeckConfig;
    user_config?: IDeckUserConfig;
  };
}

export interface IDeckInsertOperation extends IBaseOperation {
  object_type: "deck";
  type: "INSERT";
  object_parameters: IDeckInsertObjectParameters;
}

export interface IDeckDeleteOperation extends IBaseOperation {
  object_type: "deck";
  type: "DELETE";
  object_parameters: {
    id: ID;
  };
}

export interface IDeckDeleteBatchOperation extends IBaseOperation {
  object_type: "deck";
  type: "DELETE";
  object_parameters: {
    ids: ID[];
  };
}

interface IDeckInsertObjectParameters {
  id: string;
  created_at: string;
  description?: string;
  modified_at: string;
  name: string;
  status: Deck.DeckStatus;
  tags?: Array<{ name: string }>;
  knols?: IKnol[];
  layout_id?: string;
  layouts?: ILayout[];
  config?: IDeckConfig;
  user_config?: {
    settings?: Partial<IDeckSettings>;
  };
}

export type DeckOperation =
  | IDeckUpdateOperation
  | IDeckInsertOperation
  | IDeckDeleteOperation
  | IDeckDeleteBatchOperation;

const DELETE = async (
  operation: IDeckDeleteOperation | IDeckDeleteBatchOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const params = operation.object_parameters;
  const deckIDs = "id" in params ? [params.id] : params.ids;

  await Promise.all(deckIDs.map((deckID) => tx.objectStore("decks").delete(deckID)));

  // Delete blobs with the specific deck IDs
  const blobsTxn = idb.blobs.transaction(["blobs", "queuedBlobs"], "readwrite");
  const blobsStore = blobsTxn.objectStore("blobs");
  const queuedBlobsStore = blobsTxn.objectStore("queuedBlobs");
  for (const deckID of deckIDs) {
    // Delete from 'blobs'
    const blobsIndex = blobsStore.index("deckID");
    let blobsCursor = await blobsIndex.openCursor(IDBKeyRange.only(deckID));
    while (blobsCursor) {
      blobsCursor.delete();
      blobsCursor = await blobsCursor.continue();
    }

    // Delete from 'queuedBlobs'
    const queuedBlobsIndex = queuedBlobsStore.index("deckID");
    let queuedBlobsCursor = await queuedBlobsIndex.openCursor(IDBKeyRange.only(deckID));
    while (queuedBlobsCursor) {
      queuedBlobsCursor.delete();
      queuedBlobsCursor = await queuedBlobsCursor.continue();
    }
  }
  await blobsTxn.done;

  return () => {
    EventBus.emit("deckDeleted", { deckIDs });
  };
};

const UPDATE = async (
  operation: IDeckUpdateOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const params = operation.object_parameters;

  const store = tx.objectStore("decks");
  const prevIDBRow: IDeck | undefined = await store.get(params.id);
  const prevResetAt = prevIDBRow?.user_config?.lastResetAt;
  const newResetAt = params.user_config?.lastResetAt;
  const needsReset = newResetAt ? newResetAt > (prevResetAt ?? new Date(0)) : false;

  const newIDBRow: Partial<IDeck> = {};
  if (params.created_at !== undefined) {
    newIDBRow.created_at = new Date(params.created_at);
  }
  if (params.modified_at !== undefined) {
    newIDBRow.modified_at = new Date(params.modified_at);
  }
  if (params.status !== undefined) {
    newIDBRow.status = params.status;
  }
  if (params.name !== undefined) {
    newIDBRow.name = params.name;
  }
  if (params.description !== undefined) {
    newIDBRow.description = params.description;
  }
  if (params.layout_id !== undefined) {
    newIDBRow.layout_id = params.layout_id;
  }
  if (params.layouts !== undefined) {
    newIDBRow.layouts = params.layouts;
  }
  if (params.tags !== undefined) {
    newIDBRow.tags = params.tags.map(({ name }) => name);
  }
  if (params.config !== undefined) {
    newIDBRow.config = params.config;
  }
  if (params.user_config !== undefined) {
    newIDBRow.user_config = params.user_config;
  }
  await idb.update(idb.db, "decks", params.id, newIDBRow, tx);

  if (needsReset) {
    await Deck.resetGrades(params.id, tx);
  }

  return () => {
    EventBus.emit("deckUpdated", { ID: params.id });
    if (needsReset) {
      EventBus.emit("deckGradesReset", { ID: params.id });
    }
  };
};

const INSERT = async (
  operation: IDeckInsertOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const params = operation.object_parameters;

  const deckID = params.id;
  const { config, user_config } = params;
  const layouts = config ? [magicLayoutFor(config)] : params.layouts ?? [];
  const fields = config?.fields;

  if (!config) {
    for (const layout of layouts) {
      layout.knol_keys = Deck.fieldOrderFromLayouts([layout]);
    }
  }
  const knolsStore = tx.objectStore("knols");
  let counter = 0;

  // Need to collect all templates to extract blobs in legacy decks.
  const allTemplates: string[] = [];
  for (const layout of layouts) {
    allTemplates.push(...layout.templates);
  }

  const queuedBlobs: IQueuedBlobSansDeckID[] = [];

  for (const knol of params.knols ?? []) {
    const baseCreatedAt = new Date(knol.created_at ?? params.created_at);
    await knolsStore.put({
      ...knol,
      modified_at: new Date(knol.modified_at ?? params.modified_at),
      created_at: new Date(baseCreatedAt.getTime() + counter++),
      deck_id: params.id,
    });
    if (layouts) {
      for (const l of layouts) {
        await Knol.UpdateGrade(tx, knol.id, l.id);
      }
    } else {
      // modern deck
      await Knol.UpdateGrade(tx, knol.id, MAGIC_LAYOUT_ID);
    }
    const knolBlobContent = await loadBlobIDsForCard(
      deckID,
      knol.values ?? {},
      fields,
      allTemplates,
    );
    for (const qb of knolBlobContent.queuedBlobs) {
      queuedBlobs.push(qb);
    }
  }
  for (const tag of (params.tags || []) as IDeckTag[]) {
    await tx.objectStore("folders").put({ name: tag.name });
  }

  const idbDeck: IDeck = {
    id: params.id,
    created_at: new Date(params.created_at),
    modified_at: new Date(params.modified_at),
    layouts: layouts,
    status: params.status,
    name: params.name,
    description: params.description || "",
    layout_id: params.layout_id || MAGIC_LAYOUT_ID,
    tags: (params.tags ?? []).map(({ name }) => name),
    local: IDB_TRUE,
    migrated: IDB_TRUE,
    config,
    user_config,
  };
  // NOTE: .put (update) instead of .add, because the deck may already be in IDB, just not local.
  await tx.objectStore("decks").put(idbDeck);
  return async () => {
    // execute this callback after the deck operation has been applied
    try {
      await BlobStore.enqueueBlobsForDownload(deckID, queuedBlobs);
    } catch (err) {
      if (!(await handleIndexedDBRefreshRequiredErrorIfNecessary(err))) {
        Sentry.captureException(err, { tags: { operationName: "deck", operationType: "INSERT" } });
      }
    }

    BlobStore.downloadQueuedBlobs(deckID).catch(async (err) => {
      if (!(await handleIndexedDBRefreshRequiredErrorIfNecessary(err))) {
        Sentry.captureException(err, { tags: { operationName: "deck", operationType: "INSERT" } });
      }
    });
    EventBus.emit("deckAdded", { ID: deckID });
    for (const tag of (params.tags || []) as IDeckTag[]) {
      EventBus.emit("deckMoved", {
        ID: deckID,
        folder: tag.name,
      });
      EventBus.emit("folderCreated");
    }
  };
};

export { DELETE, INSERT, UPDATE };
