import EventBus from "eventBus";
import flags from "featureFlags";
import React, { useEffect } from "react";

const applePurchaseGracePeriodDays = 2;
const applePurchaseGracePeriodMilliseconds = applePurchaseGracePeriodDays * 1000 * 60 * 60 * 24;

// Cache StoreKit status to localstorage. Store the timestamp and give a grace period.
export const applePurchasedUnlimitedAtLocalStorageKey = "apple_purchased_at";
export function setUnlimitedBasedOnApplePurchase() {
  localStorage.setItem(applePurchasedUnlimitedAtLocalStorageKey, new Date().toISOString());
}

export function hasUnlimitedBasedOnApplePurchase(): boolean {
  const applePurchasedAtStr = localStorage.getItem(applePurchasedUnlimitedAtLocalStorageKey);
  if (!applePurchasedAtStr) {
    return false;
  }
  const applePurchasedAt = new Date(applePurchasedAtStr);
  const now = new Date();
  const timeDifference = now.getTime() - applePurchasedAt.getTime();
  if (timeDifference <= applePurchaseGracePeriodMilliseconds) {
    return true;
  }
  return false;
}

// When hasUnlimited is undefined it's either loading or there was an error.
export default function useHasUnlimited(forceFetch = true): {
  error: boolean;
  loading: boolean;
  hasUnlimited?: boolean;
} {
  const defaultVal = {
    error: false,
    loading: true,
  };

  const [state, setState] = React.useState<{
    error: boolean;
    loading: boolean;
    hasUnlimited?: boolean;
  }>(defaultVal);

  useEffect(() => {
    function update() {
      if (hasUnlimitedBasedOnApplePurchase()) {
        setState({ error: false, loading: false, hasUnlimited: true });
        return;
      }
      if (forceFetch || flags.flagCache?.hasUnlimited === undefined) {
        setState(defaultVal);
        flags
          .updateFlagCache()
          .then(() => {
            const hasUnlimited = flags.flagCache?.hasUnlimited;
            const error = hasUnlimited === undefined;
            setState({ error, loading: false, hasUnlimited });
          })
          .catch(() => {
            setState({ error: true, loading: false });
          });
      } else {
        setState({ error: false, loading: false, hasUnlimited: flags.flagCache?.hasUnlimited });
      }
    }

    update();

    EventBus.on("flagCacheInvalidated", update);
    return () => {
      EventBus.off("flagCacheInvalidated", update);
    };
  }, [forceFetch]);

  return state;
}
