import { responseDataFromTimeAdjustedScore } from "@core/timeAdjustedScore";
import { Base64UUID, HexUUID, convertToBase64IfNecessary } from "@lib/ids";
import { dateToUTCMillis } from "@lib/timestamps";
import { IIDBResponse } from "@models/response";
import EventBus from "eventBus";
import L10n from "localization";
import idb from "./idb";

async function migrateResponseBatch(
  numTotalRows: number,
  numRowsAlreadyMigrated: number,
  batchSize = 1000,
): Promise<number> {
  if (!idb.db.objectStoreNames.contains("responses")) {
    // console.log("No responses v1 table to migrate");
    return -1;
  }

  const tx = idb.db.transaction(["responses", "responsesV2", "knols_decks"], "readwrite");
  const v1Store = tx.objectStore("responses");
  const v2Store = tx.objectStore("responsesV2");
  const kdStore = tx.objectStore("knols_decks");

  let numMigratedRows = 0;

  let cursor = await v1Store.openCursor();
  while (cursor && numMigratedRows < batchSize) {
    const v1Resp = cursor.value;

    if (v1Resp.knol_id && v1Resp.deck_id) {
      const knolID = convertToBase64IfNecessary(v1Resp.knol_id as Base64UUID | HexUUID);
      const deckID = convertToBase64IfNecessary(v1Resp.deck_id as Base64UUID | HexUUID);
      const data = responseDataFromTimeAdjustedScore(v1Resp.score);
      const v2Resp: IIDBResponse = {
        knol_id: knolID,
        duration_ms: v1Resp.duration_ms,
        created_at: dateToUTCMillis(new Date(v1Resp.created_at)),
        score: v1Resp.score,
        data,
      };
      await v2Store.add(v2Resp);
      await kdStore.put({ knolID, deckID });
    }
    numMigratedRows += 1;

    if (numMigratedRows % 250 === 0) {
      EventBus.emit("responsesBatchLoaded", {
        numerator: numRowsAlreadyMigrated + numMigratedRows,
        denominator: numTotalRows,
        message: L10n.localize((s) => s.general.updatingDatabase),
      });
    }
    await cursor.delete();
    cursor = await cursor.continue();
  }
  await tx.done;

  return numMigratedRows;
}

export default async function migrateResponsesToV2IfNecessary() {
  if (!idb.db.objectStoreNames.contains("responses")) {
    // console.log("No responses v1 table to migrate");
    return;
  }

  const numRowsToMigrate = await idb.db.count("responses");
  let numTotalMigratedRows = 0;

  let numMigratedRowsLastBatch = await migrateResponseBatch(numRowsToMigrate, numTotalMigratedRows);
  while (numMigratedRowsLastBatch > 0) {
    numMigratedRowsLastBatch = await migrateResponseBatch(numRowsToMigrate, numTotalMigratedRows);
    numTotalMigratedRows += numMigratedRowsLastBatch;
  }

  EventBus.emit("responsesBatchLoaded", {
    numerator: numTotalMigratedRows,
    denominator: numRowsToMigrate,
    message: L10n.localize((s) => s.general.updatingDatabase),
  });
}
