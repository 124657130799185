import { showPrompt } from "@components/prompt";
import ScreenMenu, { MenuButton } from "@components/screenMenu";
import { User } from "@models/user";
import EventBus from "eventBus";
import { createOutline, exitOutline, shareOutline, trashOutline } from "ionicons/icons";
import L10n from "localization";
import Network from "network";
import React from "react";
import { userIsOwner } from "../studyGroups/lib";
import { IGroup } from "../studyGroups/types";

interface IProps {
  group?: IGroup;
  fetchGroup: () => void;
  onDismiss: () => void;
  openShareModal: () => void;
}
export default function Menu(props: IProps) {
  const { group, fetchGroup, onDismiss, openShareModal } = props;

  if (!group) {
    return null;
  }
  const { id, name } = group;

  function handleLeaveGroup() {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.funcs.groups.leaveGroupQuestion)(name),
      promptType: "confirm",
      callback: async () => {
        try {
          await Network.fetch("DELETE", `/users/groups/${id}/users/${User.id()}`);
          EventBus.emit("leftStudyGroup", { groupID: id });
          onDismiss();
        } catch {
          showPrompt({
            prompt: L10n.localize((s) => s.error.internal),
            promptType: "alert",
            title: L10n.localize((s) => s.general.attention),
          });
        }
      },
    });
  }

  function handleDeleteGroup() {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.groups.confirmDelete),
      promptType: "confirm",
      callback: async () => {
        Network.fetch("DELETE", `/users/groups/${id}`)
          .then(() => {
            EventBus.emit("deletedStudyGroup", { groupID: id });
            onDismiss();
          })
          .catch(() => {
            showPrompt({
              prompt: L10n.localize((s) => s.error.internal),
              promptType: "alert",
              title: L10n.localize((s) => s.general.attention),
            });
          });
      },
    });
  }

  async function handleSave(newName: string | null, emails: string[]) {
    const invites = emails.map((e) => {
      return { email: e };
    });
    try {
      await Network.fetch("PATCH", `/users/groups/${id}`, {
        name: newName ?? name,
        invites,
      });
      fetchGroup();
    } catch {
      showPrompt({
        prompt: L10n.localize((s) => s.error.internal),
        promptType: "alert",
        title: L10n.localize((s) => s.general.attention),
      });
    }
  }

  function showRenamePrompt() {
    showPrompt({
      promptType: "input",
      title: L10n.localize((s) => s.groups.studyGroup),
      prompt: L10n.localize((s) => s.general.name),
      validationRegex: /.+/,
      default: name,
      callback: (response) => {
        const newName = response?.trim() ?? "";
        if (newName === "" || newName === name) {
          return;
        }

        handleSave(newName, []);
      },
    });
  }

  const isOwner = userIsOwner(group);

  return (
    <ScreenMenu>
      {isOwner && (
        <MenuButton
          label={L10n.localize((s) => s.actions.rename)}
          icon={createOutline}
          onClick={showRenamePrompt}
        />
      )}
      <MenuButton
        label={L10n.localize((s) => s.sharing.shareDeck)}
        icon={shareOutline}
        detail={false}
        onClick={openShareModal}
      />
      <MenuButton
        label={L10n.localize((s) => s.groups.leaveGroup)}
        icon={exitOutline}
        detail={false}
        onClick={handleLeaveGroup}
      />
      {isOwner && (
        <MenuButton
          lines="none"
          detail={false}
          label={L10n.localize((s) => s.groups.delete)}
          color="danger"
          icon={trashOutline}
          onClick={handleDeleteGroup}
        />
      )}
    </ScreenMenu>
  );
}
