import { StepLogger } from "analytics/funnel";
import { History } from "history";
import L10n from "localization";
import React from "react";
import GenFlashcardsNav from "./genFlashcardsNav";
import FreeformTextNav from "./freeformTextNav";
import { OnboardingGoalInfo } from "./onboardingModal";

interface IProps {
  dismiss: () => Promise<void>;
  onAddToInbox: () => void;
  history: History;
  logStep: StepLogger;
  test: string;
}
export default function GoalTestVectorNav(props: IProps) {
  return (
    <FreeformTextNav
      title={props.test}
      description={L10n.localize((s) => s.onboarding.testPrepDescription)}
      dismiss={props.dismiss}
      history={props.history}
      onNav={(vector) => {
        const goal: OnboardingGoalInfo = {
          goal: "test",
          test: props.test,
          vector,
        };
        const prompt = `I'm studying for the ${goal.test}. I want flash cards of test questions that I can practice. My experience or goals are: "${goal.vector}"`;
        return (
          <GenFlashcardsNav
            prompt={prompt}
            dismiss={props.dismiss}
            history={props.history}
            onAddToInbox={props.onAddToInbox}
            logStep={props.logStep}
            goal={goal}
          />
        );
      }}
    />
  );
}
