import NestedNav from "@components/nestedNav";
import { IonButton, IonIcon, IonInput, IonItem, IonList, IonNavLink, IonNote } from "@ionic/react";
import { History } from "history";
import L10n from "localization";
import React, { useRef, useState } from "react";
import Breadcrumbs from "./breadcrumbs";
import { chevronForward } from "ionicons/icons";

interface IProps {
  description: string;
  title: string;
  dismiss: () => Promise<void>;
  history: History;
  onNav: (val: string) => React.ReactElement;
}

export default function FreeformTextNav(props: IProps): JSX.Element {
  const { title, description } = props;
  const ref = useRef<HTMLIonNavLinkElement>(null);
  const [val, setVal] = useState("");

  return (
    <NestedNav
      title={L10n.localize((s) => s.getStarted.getStarted)}
      color="light"
      headerChildren={<Breadcrumbs crumb="details" />}
    >
      <>
        <h2 className="ion-margin-horizontal">{title}</h2>
        <IonNote>
          <p className="ion-margin-horizontal ion-text-wrap">{description}</p>
        </IonNote>
        <IonList inset>
          <IonItem>
            <form
              style={{ width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
                if (ref.current && val !== "") {
                  ref.current.click();
                }
              }}
            >
              <IonInput
                placeholder={L10n.localize((s) => s.general.required)}
                onIonInput={(e) => {
                  const value = e.detail?.value;
                  setVal(value ?? "");
                }}
              />
            </form>
            <IonButton
              type="submit"
              slot="end"
              fill="clear"
              disabled={val === ""}
              style={{ paddingRight: 0 }}
            >
              <IonNavLink ref={ref} routerDirection="forward" component={() => props.onNav(val)}>
                <IonIcon icon={chevronForward} />
              </IonNavLink>
            </IonButton>
          </IonItem>
        </IonList>
      </>
    </NestedNav>
  );
}
