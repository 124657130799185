import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { showPrompt } from "@components/prompt";
import { IonItem, IonLabel, IonList, IonNote, IonProgressBar } from "@ionic/react";
import { User } from "@models/user";
import logEvent from "analytics";
import { groupLinkJoinAnalyticsKey } from "hooks/util/useGroupJoinRequest";
import L10n from "localization";
import Network from "network";
import React from "react";
import JoinRequestList from "../studyGroups/joinRequestList";
import Members from "../studyGroups/members";
import SharedDecks from "../studyGroups/sharedDecks";
import { IGroup, IMember } from "../studyGroups/types";
import Invites from "./invites";

export default function Body(props: {
  group?: IGroup;
  loading: boolean;
  refetchGroup: () => void;
  openShareModal: () => void;
}) {
  const { group, loading, refetchGroup, openShareModal } = props;

  const shareUrl = group?.share_url;
  const shareUrlForDisplay = shareUrl?.replace(/^https:\/\//, "");
  const hasShareUrl = !!shareUrl;
  async function handleShareLink() {
    if (!shareUrlForDisplay) {
      return;
    }

    if (Capacitor.isPluginAvailable("Share") && (await Share.canShare()).value) {
      try {
        await Share.share({ url: shareUrl });

        logEvent(groupLinkJoinAnalyticsKey, {
          step: "link_shared",
          group_id: group?.id,
          group_name: group?.name,
          shareUrl,
        });
      } catch {
        // Share canceled.
      }
    } else {
      if (shareUrl) {
        // NOTE: should work in Chrome without extra permissions.
        // May need to revisit if we expand browser support.
        try {
          await navigator.clipboard.writeText(shareUrl);

          alert(L10n.localize((s) => s.sharing.copiedStudyGroupShareLink));

          logEvent(groupLinkJoinAnalyticsKey, {
            step: "link_copied",
            group_id: group?.id,
            group_name: group?.name,
            shareUrl,
          });
        } catch {
          logEvent(groupLinkJoinAnalyticsKey, {
            step: "link_copy_failed",
            group_id: group?.id,
            group_name: group?.name,
            shareUrl,
          });
        }
      }
    }
  }

  const handleKickMember = (member: IMember) => {
    if (!group) {
      return;
    }
    if (member.role === "creator") {
      return;
    }
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.funcs.groups.kickMemberQuestion)(member.email, group.name),
      promptType: "confirm",
      callback: async () => {
        try {
          await Network.fetch("DELETE", `/users/groups/${group.id}/users/${member.id}`);
          refetchGroup();
        } catch {
          showPrompt({
            prompt: L10n.localize((s) => s.error.internal),
            promptType: "alert",
            title: L10n.localize((s) => s.general.attention),
          });
        }
      },
    });
  };

  const justMe = group?.members.length === 1 && group.members?.[0].email === User.email();

  return loading || !group ? (
    <IonProgressBar type="indeterminate" />
  ) : (
    <>
      {justMe ? (
        <IonNote>
          <p className="ion-margin-horizontal ion-text-wrap">
            {L10n.localize((s) => s.sharing.studyGroupsDescription)}
          </p>
        </IonNote>
      ) : undefined}
      <IonList inset>
        <IonItem
          button
          lines="none"
          detail
          onClick={handleShareLink}
          style={{ cursor: hasShareUrl ? "pointer" : undefined }}
          color="light"
        >
          <IonLabel style={{ flexBasis: "content", flexShrink: 0 }}>
            <h1>{L10n.localize((s) => s.sharing.invitePeople)}</h1>
            <p>{L10n.localize((s) => s.sharing.shareGroupLinkDescription)}</p>
            {hasShareUrl ? (
              <p style={{ fontFamily: "monospace", marginTop: 12 }}>{shareUrlForDisplay}</p>
            ) : undefined}
          </IonLabel>
        </IonItem>
      </IonList>
      <JoinRequestList group={group} onChange={refetchGroup} />
      <Members group={group} onKickMember={handleKickMember} />
      <Invites group={group} />
      <SharedDecks group={group} fetchGroup={refetchGroup} onShare={openShareModal} />
    </>
  );
}
