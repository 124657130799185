import { IonItem, IonLabel, IonList, IonNote } from "@ionic/react";
import { IDeck } from "@models/deck";
import L10n from "localization";
import React from "react";
import { LuFilePlus, LuFolderInput } from "react-icons/lu";
import { useAddToFolderDeckSelector } from "./menu";

interface IProps {
  createDeck: () => void;
  addToFolder: (decks: IDeck[]) => Promise<void>;
}
export default function EmptyFolder(props: IProps) {
  const showDeckSelect = useAddToFolderDeckSelector({
    onSelect: props.addToFolder,
  });

  return (
    <>
      <IonNote>
        <p className="ion-margin-horizontal ion-text-wrap">{L10n.localize((s) => s.folder.none)}</p>
      </IonNote>
      <IonList inset>
        <IonItem button onClick={props.createDeck}>
          <div slot="start">
            <LuFilePlus size={24} style={{ color: "var(--ion-color-primary)" }} />
          </div>
          <IonLabel>
            <h1>{L10n.localize((s) => s.deck.new)}</h1>
            <p>{L10n.localize((s) => s.folder.newDeckDescription)}</p>
          </IonLabel>
        </IonItem>
      </IonList>

      <IonList inset>
        <IonItem button onClick={showDeckSelect}>
          <div slot="start">
            <LuFolderInput size={24} style={{ color: "var(--ion-color-primary)" }} />
          </div>
          <IonLabel>
            <h1>{L10n.localize((s) => s.folder.moveDeckHere)}</h1>
            <p>{L10n.localize((s) => s.folder.moveDeckHereDescription)}</p>
          </IonLabel>
        </IonItem>
      </IonList>
    </>
  );
}
