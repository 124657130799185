import RadioSelectSettingModal from "@components/radioSelectSettingModal";
import SettingsListItemIcon from "@components/settingsListItemIcon";
import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { IDeck } from "@models/deck";
import { Layout } from "@models/layout";
import { Operation } from "@models/operation";
import { IDeckUpdateOperation } from "@operations/deck";
import { ALL_LAYOUT_ID } from "fields/magicLayout";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React from "react";
import { PiLayout } from "react-icons/pi";

export default function LayoutSelectItem(props: {
  deck: IDeck;
  showAll?: boolean;
}): JSX.Element {
  const { deck, showAll = true } = props;
  if (!deck || !deck.layouts) {
    return <></>;
  }
  const layoutID = deck.layout_id;
  const layouts = deck.layouts;
  const options = layouts.map((layout) => ({ name: layout.name, val: layout.id }));

  // Only add "All" option if there are multiple layouts.
  if (layouts.length > 1 && showAll) {
    options.unshift({
      name: L10n.localize((s) => s.general.all),
      val: ALL_LAYOUT_ID,
    });
  }

  const selectedItemVal = layoutID ?? Layout.ALL_LAYOUT_VALUE;

  const handleLayoutSelected = async (val: string) => {
    if (!deck) return;
    const op: IDeckUpdateOperation = {
      ...Operation.operationDefaults(),
      object_type: "deck",
      type: "UPDATE",
      object_parameters: {
        id: deck.id,
        modified_at: new Date().toISOString(),
        layout_id: val,
      },
    };
    await Operation.operate(true, [op]);
  };

  const [showModal] = useIonCardModalWithDismiss(RadioSelectSettingModal, {
    options,
    title: L10n.localize((s) => s.layout.singular),
    onChange: handleLayoutSelected,
    selectedItemVal,
  });

  const selectedItemName = options.find(({ val }) => val === selectedItemVal)?.name ?? "";

  return (
    <IonItem lines="none" button onClick={showModal}>
      <SettingsListItemIcon icon={PiLayout} />
      <IonLabel style={{ flexBasis: "content", flexShrink: 0 }}>
        {L10n.localize((s) => s.layout.singular)}
      </IonLabel>
      <IonNote
        slot="end"
        style={{
          overflowX: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {selectedItemName}
      </IonNote>
    </IonItem>
  );
}
