import { DeckFields } from "@models/deck";
import { fieldTypeMap } from "fields/fields";
import { appLang } from "fields/lang";
import { SIDES } from "fields/sides";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export default function japaneseRecognition(): IMarketItem {
  const japaneseRecognitionFields: DeckFields = [
    {
      name: fieldNames.Japanese(),
      type: "japanese",
      sides: SIDES.BOTH,
      attributes: {
        furiganaMode: "hint",
      },
    },
    {
      name: fieldNames.Meaning(),
      type: "text",
      sides: SIDES.BACK,
      attributes: { lang: appLang() },
      source: {
        type: "translation",
        source: {
          type: "ref",
          name: fieldNames.Japanese(),
        },
      },
    },
  ];

  return {
    base: "japanese",
    name: L10n.localize((s) => s.field.typeJapanese),
    description: L10n.localize((s) => s.field.typeJapaneseDescription),
    fields: japaneseRecognitionFields,
    values: {
      [fieldNames.Japanese()]: fieldTypeMap.japanese.dumpFML({
        japanese: "花より団子",
      }),
      [fieldNames.Meaning()]: "Substance over form",
    },
    previewValuesHTML: {
      [fieldNames.Japanese()]:
        '<div><ruby>花<rt class="hint">はな</rt></ruby><ruby>より<rt class="hint">&nbsp;</rt></ruby><ruby>団子<rt class="hint">だんご</rt></ruby></div>',
      [fieldNames.Meaning()]: "Substance over form",
    },
  };
}
