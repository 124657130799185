import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { Knol } from "@models/knol";
import { closeCircle, folderOpenOutline, pricetagOutline } from "ionicons/icons";
import React from "react";
import Style, { IgnoreIcon, StarIcon, StarOutlineIcon } from "style";
import FolderWithSubfoldersString from "./folderWithSubfoldersString";

export default function Tag(props: {
  name: string;
  style?: React.CSSProperties;
  onDelete?: () => void;
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
  folder?: boolean;
  noChip?: boolean;
  noIcon?: boolean;
}): JSX.Element {
  const { name, onDelete, disabled, onClick, folder, style = {}, noChip, noIcon } = props;

  const deletable = !!onDelete;
  const clickable = !!onDelete || !!onClick;

  const color = props.color ?? (deletable ? "primary" : undefined);

  let label: string | JSX.Element = name;
  if (name === "marked") {
    const filled = !clickable || (clickable && color === "primary");

    if (filled) {
      label = <StarIcon />;
    } else {
      label = <StarOutlineIcon />;
    }
  } else if (name === "ignored") {
    label = <IgnoreIcon />;
  }

  let showTagIcon = !noIcon;
  if ([Knol.IGNORE_TAG, Knol.MARKED_TAG].includes(name)) {
    showTagIcon = false;
  }

  if (noChip) {
    return (
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          ...style,
        }}
      >
        {showTagIcon && (
          <IonIcon
            icon={folder ? folderOpenOutline : pricetagOutline}
            color={Style.colors.secondaryFg}
          />
        )}
        <span style={{ marginLeft: 4 }}>{label}</span>
      </div>
    );
  }

  return (
    <IonChip
      style={Object.assign(style, {
        pointerEvents: disabled ? "none" : undefined,
        cursor: onDelete ? "pointer" : undefined,
        flex: "0 0 auto",
      })}
      onClick={onDelete ?? onClick}
      color={color}
      outline={clickable}
    >
      {showTagIcon && (
        <IonIcon
          icon={folder ? folderOpenOutline : pricetagOutline}
          color={Style.colors.secondaryFg}
        />
      )}
      <IonLabel
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {typeof label === "string" && folder ? (
          <FolderWithSubfoldersString folder={label} />
        ) : (
          label
        )}
      </IonLabel>
      {onDelete && <IonIcon icon={closeCircle} />}
    </IonChip>
  );
}
