import Empty from "@components/empty";
import NestedNav from "@components/nestedNav";
import { IonButton, IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import Members from "@screens/studyGroups/members";
import { useStudyGroup } from "@screens/studyGroups/useStudyGroups";
import { History } from "history";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import React, { useCallback } from "react";
import { ID } from "types/ID";

interface IProps {
  id: ID;
  name: string;
  onShare: (groupIDs: ID[]) => Promise<void>;
  history: History;
}
export default function StudyGroupShareNav(props: IProps) {
  const [group] = useStudyGroup(props.id);
  const [showError] = useErrorAlert({ code: "SHARING_TO_STUDY_GROUP" });

  const handleShare = useCallback(async () => {
    try {
      await props.onShare([props.id]);
      props.history.push(`/groups/${props.id}`);
    } catch (err) {
      showError(err);
    }
  }, [props.id, props.onShare, showError, props.history]);

  const noDecksShared = !group.loading && !group.val?.decks?.length;

  return (
    <NestedNav
      title={L10n.localize((s) => s.sharing.shareDeck)}
      rightButton={
        <IonButton onClick={handleShare}>{L10n.localize((s) => s.actions.share)}</IonButton>
      }
    >
      <IonList>
        <IonListHeader>
          <h1>{props.name}</h1>
        </IonListHeader>
      </IonList>
      {group.val ? (
        <>
          <Members group={group.val} />
          <IonList>
            <IonListHeader>
              <IonLabel>{L10n.localize((s) => s.deck.shared)}</IonLabel>
            </IonListHeader>
            {(group.val.decks ?? []).map((deck) => (
              <IonItem key={deck.id}>
                <IonLabel>{deck.name}</IonLabel>
              </IonItem>
            ))}
            {noDecksShared ? <Empty marginTop="none" /> : undefined}
          </IonList>
        </>
      ) : undefined}
    </NestedNav>
  );
}
