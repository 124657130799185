import { convertNonBlobImagesToBlobs } from "@screens/cardEdit/lib";
import { blobHash } from "blobs/lib";
import { IFieldValueEditorProps } from "fields/fieldType";
import { IFieldRichText } from "fields/types/richText";
import React, { useCallback, useRef } from "react";

import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";

import { useIonAlert } from "@ionic/react";
import { ITinyEvents } from "@tinymce/tinymce-react/lib/cjs/main/ts/Events";
import EventBus from "eventBus";
import { MAX_FILE_BYTES } from "globals";
import { useIsPhoneImmediately } from "hooks/util/useMediaQuery";
import { recordAudio, supportsRecording } from "lib/audio";
import L10n from "localization";
import { getEnableAdvancedFontControls, getExpandRichEditorToolbar } from "settings";
import Style from "style";
import { pickImage } from "./fieldValueEditorImage";

const audioFileButtonName = "audiofile";
const audioFileIconSVG = `<svg stroke-width="0" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M16 8v2h-3v4.5a2.5 2.5 0 1 1-2-2.45V8h4V4H5v16h14V8h-3zM3 2.992C3 2.444 3.447 2 3.999 2H16l5 5v13.993A1 1 0 0 1 20.007 22H3.993A1 1 0 0 1 3 21.008V2.992z"></path></g></svg>`;

const recordAudioButtonName = "recordaudio";
const recordAudioIconSVG = `<svg fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M12 3a3 3 0 0 0-3 3v4a3 3 0 0 0 6 0V6a3 3 0 0 0-3-3zm0-2a5 5 0 0 1 5 5v4a5 5 0 0 1-10 0V6a5 5 0 0 1 5-5zM3.055 11H5.07a7.002 7.002 0 0 0 13.858 0h2.016A9.004 9.004 0 0 1 13 18.945V23h-2v-4.055A9.004 9.004 0 0 1 3.055 11z"></path></g></svg>`;

const imageButtonName = "imagefile";
const imageIconSVG = `<svg fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"><g><path fill="none" d="M0 0h24v24H0z"></path><path d="M21 15v3h3v2h-3v3h-2v-3h-3v-2h3v-3h2zm.008-12c.548 0 .992.445.992.993V13h-2V5H4v13.999L14 9l3 3v2.829l-3-3L6.827 19H14v2H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016zM8 7a2 2 0 1 1 0 4 2 2 0 0 1 0-4z"></path></g></svg>`;

interface IRichTextEditorProps extends Omit<IFieldValueEditorProps<IFieldRichText>, "field"> {
  onChange: (value: string) => void;
}
function RichTextEditor(props: IRichTextEditorProps): JSX.Element {
  const { onChange, registerBlob, onFocus, name } = props;
  const [presentAlert] = useIonAlert();

  const editorRef = useRef<TinyMCEEditor>();

  async function handleAudioFile() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", ".mp3,.m4a,.wav,.ogg");

    input.addEventListener("change", (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.addEventListener("load", async () => {
          const id = await blobHash(file);
          const url = await registerBlob(id, file);
          const html = `<audio src="${url}" data-blob-id="${id}" controls />`;
          if (editorRef.current) {
            editorRef.current.insertContent(html);
          }
        });
        reader.readAsDataURL(file);
      }
    });

    input.click();
  }

  async function handleRecordAudio() {
    await recordAudio(async (blob: Blob) => {
      if (blob.size > MAX_FILE_BYTES) {
        presentAlert(L10n.localize((s) => s.error.fileTooLarge));
        return;
      }

      const id = await blobHash(blob);
      const url = await registerBlob(id, blob);
      const html = `<audio src="${url}" data-blob-id="${id}" controls />`;
      if (editorRef.current) {
        editorRef.current.insertContent(html);
      }
    });
  }

  async function handleImage() {
    const res = await pickImage();
    if (res) {
      const { id, blob } = res;
      const url = await registerBlob(id, blob);
      const html = `<img src="${url}" data-blob-id="${id}" style="max-width: 100%" />`;
      if (editorRef.current) {
        editorRef.current.insertContent(html);
      }
    }
  }

  // The first time TinyMCE loads and processes the HTML we provide as input,
  // it may produce HTML output that differs (e.g. reorders <img> tag attributes).
  // We need to notice this and not trigger an update for that initial difference.
  const tinyMCEInitiallyProcessedHTML = useRef<string>();
  const changedAfterInit = useRef(false);

  const handleInit: ITinyEvents["onInit"] = useCallback(
    (evt, editor: TinyMCEEditor) => {
      tinyMCEInitiallyProcessedHTML.current = editor.getContent();
      editorRef.current = editor;
      editor.on("focus", () => onFocus(name));
    },
    [name, onFocus],
  );

  const handleChange = useCallback(async () => {
    if (editorRef.current) {
      const imgs = Array.from(editorRef?.current.getBody().querySelectorAll("img") ?? []);
      await convertNonBlobImagesToBlobs(imgs, registerBlob);
      const newHTML = editorRef.current.getContent();
      if (changedAfterInit.current || newHTML !== tinyMCEInitiallyProcessedHTML.current) {
        onChange(newHTML);
      } else {
        changedAfterInit.current = true;
      }
    }
  }, [onChange, registerBlob]);

  const isPhone = useIsPhoneImmediately();

  return (
    <Editor
      tinymceScriptSrc="/tinymce/tinymce.min.js"
      onInit={handleInit}
      value={props.value}
      // initialValue={props.value}
      onEditorChange={handleChange}
      disabled={!props.editable}
      init={{
        content_css: Style.currentTheme === "night" ? "dark" : undefined,
        skin: Style.currentTheme === "night" ? "oxide-dark" : undefined,
        branding: false,
        element_format: "xhtml",
        encoding: "xml",
        elementpath: false,
        height: 320,
        width: "100%",
        menubar: false,
        placeholder: L10n.localize((s) => s.card.clickToEdit),
        plugins: "lists image table",
        ui_mode: "split",
        toolbar_mode: getExpandRichEditorToolbar() ? "wrap" : "sliding",
        image_title: false,
        automatic_uploads: false,
        font_size_formats: "8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt",
        font_size_input_default_unit: "pt",
        statusbar: props.editable && !isPhone,
        toolbar: !props.editable
          ? false
          : `styles ${getEnableAdvancedFontControls() ? "fontfamily fontsizeinput | " : " "}` +
            "bold italic forecolor backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            `removeformat | undo redo | ${imageButtonName} ${recordAudioButtonName} ${audioFileButtonName} | table`,
        mobile: {
          // Sliding mode shows a "..." button to expand the toolbar.
          // NOTE: if the first group of toolbar items (delimited by | chars) is too long, ONLY the "..." will be shown initially.
          toolbar_mode: getExpandRichEditorToolbar() ? "wrap" : "sliding",
          toolbar: !props.editable
            ? false
            : `bold italic forecolor ${imageButtonName} ${recordAudioButtonName} ${audioFileButtonName} | ` +
              "styles fontfamily backcolor | fontsizeinput " +
              "alignleft aligncenter alignright alignjustify bullist numlist | outdent indent | " +
              `removeformat | undo redo | table`,
        },
        setup: (editor) => {
          editor.addShortcut("meta+s", "Save card", () => {
            EventBus.emit("cardSaved");
          });
          const audioFileIconName = "audio";
          editor.ui.registry.addIcon(audioFileIconName, audioFileIconSVG);
          editor.ui.registry.addButton(audioFileButtonName, {
            icon: audioFileIconName,
            onAction: handleAudioFile,
          });

          const microphoneIconName = "microphone";
          editor.ui.registry.addIcon(microphoneIconName, recordAudioIconSVG);
          editor.ui.registry.addButton(recordAudioButtonName, {
            icon: microphoneIconName,
            onAction: handleRecordAudio,
            enabled: supportsRecording,
          });

          const imageIconName = "imagefile";
          editor.ui.registry.addIcon(imageIconName, imageIconSVG);
          editor.ui.registry.addButton(imageButtonName, {
            icon: imageIconName,
            onAction: handleImage,
          });
        },
        content_style: `
            body { font-family:Helvetica,Arial,sans-serif; font-size:14px }
            p { margin: 0; padding: 0; }
          `,
      }}
    />
  );
}

export default React.memo(RichTextEditor);
