import { useCallback, useState } from "react";
import useDisableKeyboardScroll from "./util/useDisableKeyboardScroll";

export default function useKeyboardHeight(
  onShow?: (heightPx: number) => void,
  onHide?: () => void,
) {
  const [kbHeightPx, setKbHeightPx] = useState(0);
  const handleKeyboardShow = useCallback(
    (heightPx: number) => {
      setKbHeightPx(heightPx);
      onShow?.(heightPx);
    },
    [onShow],
  );
  const handleKeyboardHide = useCallback(() => {
    setKbHeightPx(0);
    onHide?.();
  }, [onHide]);
  useDisableKeyboardScroll(handleKeyboardShow, handleKeyboardHide);
  return kbHeightPx;
}
