import L10n from "localization";
import React from "react";

interface IPitchProps {
  reason?: string;
  style?: React.CSSProperties;
}
export default function Pitch(props: IPitchProps) {
  const { reason, style } = props;
  return (
    <div style={{ marginTop: 8, fontSize: 18, ...style }}>
      {reason ?? L10n.localize((s) => s.account.defaultReason)}
    </div>
  );
}
