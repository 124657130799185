import { SlugContext } from "@components/screen";
import { IonButton, IonIcon } from "@ionic/react";
import { genTraceID, logAction } from "analytics/action";
import React from "react";
import { useHistory } from "react-router";
import { useNewGroupPrompt } from "./newModal";
import { addOutline } from "ionicons/icons";

export default function NewStudyGroupButton(): JSX.Element {
  const slugContext = React.useContext(SlugContext);

  const presentNewStudyGroupPrompt = useNewGroupPrompt();
  const history = useHistory();
  async function handleNewStudyGroup() {
    const action = {
      subj: "user",
      verb: "create",
      obj: "study_group",
      loc: slugContext,
      trace_id: genTraceID(),
    };
    logAction({ ...action, state: "init" });

    const resp = await presentNewStudyGroupPrompt();
    if (resp) {
      logAction({ ...action, state: "done" });
      history.push(`/groups/${resp.id}`);
    } else {
      logAction({ ...action, state: "quit" });
    }
  }

  return (
    <IonButton onClick={handleNewStudyGroup}>
      <IonIcon icon={addOutline} />
    </IonButton>
  );
}
