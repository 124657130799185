import { IFieldValueProps } from "fields/fieldType";
import { IBaseTextField } from "fields/lib";
import React, { Fragment } from "react";

const generateKey = (part: string, index: number) => `${part}-${index}`;

function escapeRegExp(string: string): string {
  return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
}

const highlightWord = (text: string, word?: string): JSX.Element => {
  if (!word || !text) return <>{text}</>;
  const escapedWord = escapeRegExp(word);
  const parts = text.split(new RegExp(`(${escapedWord})`, "gi"));
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === word.toLowerCase() ? (
          <span key={generateKey(part, index)} className="__match__">
            {part}
          </span>
        ) : (
          <Fragment key={generateKey(part, index)}>{part}</Fragment>
        ),
      )}
    </>
  );
};

export default function TextValue({
  value,
  field,
  options,
}: IFieldValueProps<IBaseTextField>): JSX.Element {
  return !options.query ? (
    <div style={{ fontSize: field.attributes?.fontSizePx, whiteSpace: "pre-line" }}>{value}</div>
  ) : (
    <div style={{ fontSize: field.attributes?.fontSizePx, whiteSpace: "pre-line" }}>
      {highlightWord(value, options.query)}
    </div>
  );
}
