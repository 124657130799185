import React from "react";
import { IconType } from "react-icons";

interface IProps {
  icon: IconType;
}
export default function SettingsListItemIcon({ icon }: IProps) {
  return (
    <div slot="start" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      {React.createElement(icon, { color: "var(--ion-color-primary)", size: 20 })}
    </div>
  );
}
