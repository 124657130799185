import Empty from "@components/empty";
import { showPrompt } from "@components/prompt";
import {
  InputCustomEvent,
  IonButton,
  IonButtons,
  IonChip,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonMenuButton,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  SelectCustomEvent,
  TextareaCustomEvent,
} from "@ionic/react";
import Device from "device";
import FeatureFlags from "featureFlags";
import Globals from "globals";
import useHasUnlimited from "hooks/util/useHasUnlimited";
import { closeCircle, folderOutline } from "ionicons/icons";
import L10n, { userLocale } from "localization";
import React, { useState } from "react";
import Style from "style";
import { FileAttachment } from "../contact/types";

export default function ContactScreen(): JSX.Element {
  const [formData, setFormData] = useState({
    email: localStorage.getItem("AnkiApp.user.email") || "",
    subject: "",
    name: localStorage.getItem("AnkiApp.user.nameFirst") || "",
    reason: "",
    description: "",
  });
  const [files, setFiles] = useState<Map<string, FileAttachment>>(
    new Map<string, FileAttachment>(),
  );
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [sending, setSending] = useState<boolean>(false);

  const fileChooserRef: React.RefObject<HTMLInputElement> = React.createRef();
  const { hasUnlimited } = useHasUnlimited();

  const isSubmittable = (): boolean => {
    return (
      formData.subject.length > 0 &&
      formData.description.length > 0 &&
      formData.reason !== "" &&
      sending !== true &&
      !submitted
    );
  };

  const handleAddFile = (file: FileAttachment) => {
    setFiles((prevFiles) => {
      const updatedFiles = new Map(prevFiles);
      updatedFiles.set(file.name, file);
      return updatedFiles;
    });
  };

  const handleRemoveFile = (fileName: string) => {
    setFiles((prevFiles) => {
      const updatedFiles = new Map(prevFiles);
      updatedFiles.delete(fileName);
      return updatedFiles;
    });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      reader.onload = () => {
        const f: FileAttachment = {
          name: file.name,
          size: file.size,
          type: file.type,
          data: btoa(reader.result as string),
        };

        handleAddFile(f);

        reader.onerror = () => {
          alert("Error adding attachment");
        };
      };
    }
  };

  const submitTicket = async () => {
    setSending(true);
    const noteData: { [key: string]: any } = {};
    noteData["version"] = Globals.version;
    noteData["userAgent"] = navigator.userAgent;
    noteData["hasUnlimited"] = hasUnlimited;
    const o = {
      device: ["created_at", "id", "last_synced_at"],
      database: ["schema"],
      settings: ["selectedLocale"],
      user: ["created_at", "id"],
    };
    for (const k of Object.keys(o)) {
      const vals = o[k as keyof typeof o];
      for (const v of vals) {
        const data = localStorage.getItem(`AnkiApp.${k}.${v}`);
        if (data) {
          noteData[k] = noteData[k] || {};
          noteData[k][v] = data;
        }
      }
    }
    const headers: { [key: string]: string } = {};
    const locale = userLocale();
    if (locale) {
      headers["Accept-Language"] = locale;
    }
    if (Globals.version) {
      headers["AnkiApp-Client-Version"] = Globals.version;
    }
    if (Device.hasID()) {
      headers["AnkiApp-Client-Id"] = Device.id ?? "";
    }
    if (Device.hasToken()) {
      headers["AnkiApp-Client-Token"] = Device.getToken();
    }

    const note = JSON.stringify(noteData);

    const payload = {
      email: formData.email,
      name: formData.name,
      subject: formData.subject,
      description: formData.description,
      reason: formData.reason,
      note,
      attachments: Array.from(files.values()),
    };
    try {
      const response = await fetch(Globals.contactEndpoint, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      showPrompt({
        title: L10n.localize((s) => s.general.attention),
        prompt: L10n.localize((s) => s.help.submitted),
        promptType: "alert",
        callback: () => {
          setTimeout(() => setSubmitted(true), 0);
        },
      });
    } catch (e) {
      showPrompt({
        title: L10n.localize((s) => s.general.attention),
        prompt: L10n.localize((s) => s.error.communication),
        promptType: "alert",
      });
    } finally {
      setSending(false);
    }
  };

  let accept: string | undefined = ".jpg,.jpeg,.gif,.png,.heif";
  if (Globals.platform === "android") {
    // On Android, anything in the accept attribute was blocking the file dialog from opening.
    accept = undefined;
  }

  const textStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const options = {
    cssClass: "support-popup",
  };

  const flagAndContext = FeatureFlags.getFlagAndContext("beta_channel");

  let isBetaEligble = false;
  let betaOptedIn = false;

  if (flagAndContext?.flag && "IsEligible" in flagAndContext.flag) {
    isBetaEligble = flagAndContext.flag.is_eligible;
    betaOptedIn = flagAndContext.flag.has_opted_in;
  }

  const content = submitted ? (
    <Empty text={L10n.localize((s) => s.help.submitted)} />
  ) : (
    <>
      <IonList inset lines="none" className="pb-8">
        <IonItem>
          <div
            style={{ margin: Style.edgePadding }}
            dangerouslySetInnerHTML={{
              __html: L10n.localize((s) => s.help.contactInstructions) || "",
            }}
          />
        </IonItem>
      </IonList>
      <IonList inset>
        {!localStorage["AnkiApp.user.email"] ? (
          <IonItem>
            <IonInput
              label={L10n.localize((s) => s.account.email)}
              labelPlacement="stacked"
              value={formData.email}
              type="email"
              onIonInput={(e: InputCustomEvent) => {
                setFormData((prevState) => ({
                  ...prevState,
                  email: e.detail.value ?? "",
                }));
              }}
            />
          </IonItem>
        ) : undefined}
        <IonItem>
          <IonSelect
            style={{ width: "99%" }}
            label={L10n.localize((s) => s.contact.reason)}
            labelPlacement="stacked"
            value={formData.reason}
            interface="popover"
            interfaceOptions={options}
            onIonChange={(e: SelectCustomEvent) => {
              setFormData((prevState) => ({
                ...prevState,
                reason: e.detail.value ?? "",
              }));
            }}
          >
            <IonSelectOption value={"bug"}>{L10n.localize((s) => s.contact.bug)}</IonSelectOption>
            <IonSelectOption value={"suggestion"}>
              {L10n.localize((s) => s.contact.suggestion)}
            </IonSelectOption>
            <IonSelectOption value={"unlimited"}>
              {L10n.localize((s) => s.contact.unlimited)}
            </IonSelectOption>
            {isBetaEligble && !betaOptedIn && (
              <IonSelectOption value={"beta"}>Beta opt-in</IonSelectOption>
            )}
            <IonSelectOption value={"other"}>
              {L10n.localize((s) => s.general.other)}
            </IonSelectOption>
          </IonSelect>
        </IonItem>
        <IonItem>
          <IonInput
            label={L10n.localize((s) => s.general.subject)}
            labelPlacement="stacked"
            type="text"
            value={formData.subject}
            onIonInput={(e: InputCustomEvent) => {
              setFormData((prevState) => ({
                ...prevState,
                subject: e.detail.value ?? "",
              }));
            }}
          />
        </IonItem>
        <IonItem>
          <IonItemGroup style={{ width: "100%" }}>
            <IonLabel position="fixed">{L10n.localize((s) => s.general.screenshots)}</IonLabel>
            <IonChip outline color="primary">
              <IonIcon
                icon={folderOutline}
                onClick={() => {
                  fileChooserRef.current?.click();
                }}
              />
              <IonLabel
                onClick={() => {
                  fileChooserRef.current?.click();
                }}
              >
                {L10n.localize((s) => s.import.chooseFile)}
              </IonLabel>
              <input
                ref={fileChooserRef}
                style={{ display: "none" }}
                type="file"
                accept={accept}
                onChange={handleFileChange}
              />
            </IonChip>
            {Array.from(files.keys()).map((fileName) => {
              return (
                <IonItem key={fileName} className="ion-text-nowrap" lines="none">
                  <IonLabel style={textStyle}>{fileName}</IonLabel>
                  <IonIcon
                    icon={closeCircle}
                    slot="end"
                    onClick={() => {
                      handleRemoveFile(fileName);
                    }}
                  />
                </IonItem>
              );
            })}
          </IonItemGroup>
        </IonItem>
        <IonItem>
          <IonTextarea
            label={L10n.localize((s) => s.general.description)}
            labelPlacement="stacked"
            value={formData.description}
            rows={8}
            onIonInput={(e: TextareaCustomEvent) => {
              setFormData((prevState) => ({
                ...prevState,
                description: e.detail.value ?? "",
              }));
            }}
          />
        </IonItem>
      </IonList>
    </>
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.actions.contact)}</IonTitle>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton onClick={submitTicket} disabled={!isSubmittable()}>
              {L10n.localize((s) => s.actions.send)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        {content}
      </IonContent>
    </>
  );
}
