import { WebPlugin } from "@capacitor/core";
import { User } from "@models/user";
import logEvent from "analytics";
import EventBus from "eventBus";
import FeatureFlags from "featureFlags";
import { isMacAppStoreElectron } from "globals";
import { IAPv2Plugin, IBuyProductResponse, IRestoreProductsResponse } from "iap";
import { IStoreKitProduct } from "unlimited/ios";

interface IWindow extends Window {
  electronIAP: IAPv2Plugin;
}

declare let window: IWindow;

class IAPv2ElectronPlugin extends WebPlugin implements IAPv2Plugin {
  async getProducts(): Promise<{ products: IStoreKitProduct[] }> {
    return window.electronIAP.getProducts();
  }
  async restoreProducts(args: {
    userIdUUID: string;
  }): Promise<IRestoreProductsResponse> {
    const resp = await window.electronIAP.restoreProducts(args);
    return resp;
  }
  async buyProduct(args: {
    productId: string;
    userIdUUID: string;
  }): Promise<IBuyProductResponse> {
    const resp = await window.electronIAP.buyProduct(args);
    return resp;
  }
  async getAllTransactions(): Promise<{ transactions: string[] }> {
    // TODO: implement (requires StoreKit v2)
    return { transactions: [] };
  }
}

const IAPv2Electron = new IAPv2ElectronPlugin();

export function useMacAppStoreElectronBackgroundPurchaseHandler(
  onSuccess: () => void,
  onFailure: () => void,
) {
  if (!isMacAppStoreElectron()) {
    return;
  }

  EventBus.on("backgroundIAPSuccess", onSuccess);
  EventBus.on("backgroundIAPFailure", onFailure);
  return () => {
    EventBus.off("backgroundIAPSuccess", onSuccess);
    EventBus.off("backgroundIAPFailure", onFailure);
  };
}

function notUnlimited() {
  return FeatureFlags.flagCache?.hasUnlimited !== true;
}

if (isMacAppStoreElectron()) {
  window.electronIAP.addListener("console", (msg: string, ...rest) => {
    logEvent("electron_iap_log", { msg, data: rest });
    // Uncomment to get log messages from Electron IAP code.
    console.info(`[IAP]: ${msg}`, ...rest);
  });
  window.electronIAP.addListener("backgroundPurchaseUpdate", async (info: any) => {
    if (typeof info === "object") {
      const state = info["state"];
      logEvent("background_iap_update", { ...info });
      switch (state) {
        case "success": {
          if (notUnlimited()) {
            User.updateHasUnlimited();
          }
          EventBus.emit("backgroundIAPSuccess");
          break;
        }
        case "failed": {
          EventBus.emit("backgroundIAPFailure");
          break;
        }
        default: {
          break;
        }
      }
    }
  });
}

export default IAPv2Electron;
