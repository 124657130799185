import NestedNav from "@components/nestedNav";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonNavLink,
  IonNote,
  useIonLoading,
} from "@ionic/react";
import MapView from "@lib/mapView";
import { Deck, IDeck, IDeckConfig } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import { parseGeneratedResponse } from "@screens/deck/genCards/lib";
import { genCards } from "@screens/deck/genCardsModal";
import { StepLogger } from "analytics/funnel";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import { SIDES } from "fields/sides";
import { History } from "history";
import Lib from "lib";
import L10n from "localization";
import React from "react";
import { GetStartedNav } from "../getStartedModal";
import Breadcrumbs from "./breadcrumbs";
import { OnboardingGoalInfo } from "./onboardingModal";
import { BFF69, validTTSLangCodes } from "fields/langCodes";
import { fieldNames } from "fields/layoutMarket/fieldNames";
import useErrorAlert from "hooks/util/useErrorAlert";

function capitalize(str: string) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

// Capitalize and remove trailing " (Country)" suffix.
export function formatLangName(name: string): string {
  const language = capitalize(name);
  const trimmed = language.replace(/\s*\(.*\)$/, "");
  return trimmed;
}

function sampleDeckName(goal: OnboardingGoalInfo): string {
  const suffix = L10n.localize((s) => s.onboarding.sampleDeck);
  switch (goal.goal) {
    case "test":
      return `${goal.test} ${suffix}`;
    case "lang": {
      return `${formatLangName(goal.langName)} ${suffix}`;
    }
    case "school":
      return `${goal.subject} ${suffix}`;
  }
}

interface IProps {
  prompt: string;
  dismiss: () => Promise<void>;
  history: History;
  onAddToInbox: () => void;
  logStep: StepLogger;
  goal: OnboardingGoalInfo;
}
export default function GenFlashcardsNav(props: IProps) {
  const [presentLoading, dismissLoading] = useIonLoading();
  const [showError] = useErrorAlert({ code: "COMPLETING_ONBOARDING" });
  async function handleStart() {
    try {
      await presentLoading(L10n.localize((s) => s.onboarding.genSampleDeckLoadingMessage));
      props.logStep("saving", props.goal);
      const config: IDeckConfig = {
        fields: [
          { type: "text", name: fieldNames.Front(), sides: SIDES.BOTH },
          { type: "text", name: fieldNames.Back(), sides: SIDES.BACK },
        ],
      };
      if (props.goal.goal === "lang" && validTTSLangCodes.includes(props.goal.langCode as BFF69)) {
        if (props.goal.langCode === "ja-JP") {
          config.fields = [
            {
              type: "japanese",
              name: fieldNames.Japanese(),
              sides: SIDES.BOTH,
              attributes: {
                furiganaMode: "hint",
              },
            },
            {
              name: `${fieldNames.Japanese()}-tts`,
              type: "tts",
              sides: SIDES.BOTH,
              source: {
                type: "ref",
                name: fieldNames.Japanese(),
              },
            },
            { type: "text", name: "Back", sides: SIDES.BACK },
          ];
        } else {
          config.fields = [
            {
              type: "tts",
              name: fieldNames.Front(),
              sides: SIDES.BOTH,
              attributes: {
                lang: props.goal.langCode as BFF69,
              },
            },
            { type: "text", name: fieldNames.Back(), sides: SIDES.BACK },
          ];
        }
      }
      const deck: IDeck = {
        id: Lib.uuid16(),
        modified_at: new Date(),
        name: sampleDeckName(props.goal),
        layout_id: MAGIC_LAYOUT_ID,
        config,
      };
      const { resp } = await genCards({
        prompt: props.prompt,
        deck,
        numKnols: 0,
        pending: [],
        sessionID: Lib.uuid16(),
      });
      const columns = deck.config?.fields.map((f) => f.name) ?? [];
      const parsed = parseGeneratedResponse(resp, columns, new MapView());

      await Deck.insert(deck.id, deck.name, undefined, config);
      for (const row of parsed) {
        const knolID = Lib.uuid16();
        const knol: IKnol = {
          id: knolID,
          deck_id: deck.id,
          values: row.values,
        };
        await Knol.Create(deck.id, knol);
      }
      await dismissLoading();
      await props.dismiss();
      props.history.push(`/decks/local/${deck.id}`);
      props.logStep("saved", { ...props.goal, numCards: parsed.length, deck });
    } catch (err) {
      dismissLoading();
      props.dismiss();
      showError(err);
    }
  }

  async function handleSkip() {
    props.logStep("skipped", props.goal);
  }

  return (
    <NestedNav
      title={L10n.localize((s) => s.getStarted.getStarted)}
      color="light"
      headerChildren={<Breadcrumbs crumb="flashcards" />}
      rightButton={
        <IonNavLink
          routerDirection="forward"
          component={() => (
            <GetStartedNav
              dismiss={props.dismiss}
              history={props.history}
              onAddToInbox={props.onAddToInbox}
            />
          )}
          onClick={handleSkip}
        >
          <IonButton>{L10n.localize((s) => s.actions.skip)}</IonButton>
        </IonNavLink>
      }
    >
      <IonNote>
        <p className="ion-margin-horizontal ion-text-wrap">
          {L10n.localize((s) => s.onboarding.genFlashcardsNavDescription)}
        </p>
      </IonNote>
      <IonList inset>
        <IonItem button onClick={handleStart}>
          <IonLabel color="primary">
            <h1>{L10n.localize((s) => s.actions.study)}</h1>
          </IonLabel>
        </IonItem>
      </IonList>
    </NestedNav>
  );
}
