import { useCallback, useState } from "react";
import React from "react";
import {
  IonPage,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonSearchbar,
  SearchbarCustomEvent,
  IonIcon,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from "@ionic/react";
import L10n from "localization";
import { fuzzyMatch } from "@lib/search";
import { returnDownBack, trashOutline } from "ionicons/icons";
import { useYesNoPrompt } from "hooks/util/useConfirmCancellationDialog";

export interface IPromptHistoryEntry {
  prompt: string;
  timestamp: number;
}

interface IProps {
  onSelect: (prompt: string) => void;
  description?: string;
  dismiss: () => Promise<void>;
  selectLabel?: string;
  cancelLabel?: string;
}

const PROMPT_HISTORY_KEY = "promptHistory";

function loadPromptHistory(): IPromptHistoryEntry[] {
  const json = localStorage.getItem(PROMPT_HISTORY_KEY);
  if (!json) {
    return [];
  }
  try {
    const history = JSON.parse(json);
    return history as IPromptHistoryEntry[];
  } catch {
    return [];
  }
}

export function updatePromptHistory(prompt: string) {
  const promptHistory = loadPromptHistory();
  const existingIndex = promptHistory.findIndex((entry) => entry.prompt === prompt);

  if (existingIndex !== -1) {
    const updatedHistory = [...promptHistory];
    updatedHistory[existingIndex] = { prompt, timestamp: Date.now() };
    localStorage.setItem(PROMPT_HISTORY_KEY, JSON.stringify(updatedHistory));
  } else {
    const newPromptHistory = [{ prompt, timestamp: Date.now() }, ...promptHistory.slice(0, 49)];
    localStorage.setItem(PROMPT_HISTORY_KEY, JSON.stringify(newPromptHistory));
  }
}

function deletePrompt(prompt: string) {
  const promptHistory = loadPromptHistory();
  const newPromptHistory = promptHistory.filter((entry) => entry.prompt !== prompt);
  localStorage.setItem(PROMPT_HISTORY_KEY, JSON.stringify(newPromptHistory));
}

export default function PromptHistoryModal(props: IProps) {
  const [options, setOptions] = useState(loadPromptHistory());
  const [query, setQuery] = useState<string>("");
  const optionsMatchingQuery = options.filter((opt) => {
    return fuzzyMatch(query, opt.prompt);
  });

  const handleSelect = useCallback(
    (prompt: string) => {
      props.onSelect(prompt);
      props.dismiss();
    },
    [props.dismiss, props.onSelect],
  );

  const presentRemovePromptConfirmation = useYesNoPrompt(
    L10n.localize((s) => s.actions.confirm),
    L10n.localize((s) => s.generation.confirmRemovePrompt),
  );
  const handleDelete = useCallback(
    (prompt: string) => {
      presentRemovePromptConfirmation(() => {
        deletePrompt(prompt);
        setOptions(loadPromptHistory());
      });
    },
    [presentRemovePromptConfirmation],
  );

  return (
    <IonPage>
      <IonToolbar>
        <IonTitle>{L10n.localize((s) => s.general.history)}</IonTitle>
        <IonButtons slot="secondary">
          <IonButton onClick={props.dismiss}>
            {props.cancelLabel ?? L10n.localize((s) => s.actions.cancel)}
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonToolbar>
        <IonSearchbar
          value={query}
          onIonInput={(e: SearchbarCustomEvent) => {
            const newVal = e.detail.value;
            if (newVal !== undefined && newVal !== null) {
              setQuery(newVal);
            }
          }}
          placeholder={L10n.localize((s) => s.actions.search)}
        />
      </IonToolbar>
      <IonContent color="light" style={{ "--padding-bottom": "env(safe-area-inset-bottom)" }}>
        <IonList inset>
          {optionsMatchingQuery.map(({ prompt, timestamp }) => {
            return (
              <IonItemSliding key={prompt + timestamp}>
                <IonItem onClick={() => handleSelect(prompt)} button detailIcon={returnDownBack}>
                  <IonLabel className="ion-text-wrap">
                    <div>{prompt}</div>
                    <p>{new Date(timestamp).toLocaleString()}</p>
                  </IonLabel>
                </IonItem>
                <IonItemOptions side="end">
                  <IonItemOption color="danger" onClick={() => handleDelete(prompt)}>
                    <IonIcon slot="icon-only" icon={trashOutline} />
                  </IonItemOption>
                </IonItemOptions>
              </IonItemSliding>
            );
          })}
        </IonList>
        {options.length < 1 ? (
          <IonNote>
            <p className="ion-margin-horizontal ion-text-wrap">
              {L10n.localize((s) => s.generation.promptHistoryEmptyDescription)}
            </p>
          </IonNote>
        ) : undefined}
      </IonContent>
    </IonPage>
  );
}
