import { suppressPDPCancelSurvey } from "@components/pdpCancelSurvey";
import React from "react";
import { frequencyFor } from "./lib";
import { IPDPContentProps } from "./pdpContent";
import { Products } from "./products";
import { IProduct } from "./types";
import useProducts from "./useProducts";

interface IProps extends IPDPContentProps {
  onPurchaseComplete: () => void;
}
export default function WebPDPContent({
  userID,
  email,
  logger,
  reason,
  onPurchaseComplete,
  dismiss,
}: IProps) {
  const productsState = useProducts(email, logger);
  const { val: serverProducts } = productsState;

  function handlePurchase(product: IProduct) {
    logger.log("user_indicated_purchase_complete", { product });
    suppressPDPCancelSurvey();
    dismiss();
    onPurchaseComplete();
  }

  const prods: IProduct[] = (serverProducts ?? []).map((prod) => {
    return {
      ...prod,
      frequency: frequencyFor(prod.id),
    };
  });

  return (
    <Products
      reason={reason}
      onPurchase={handlePurchase}
      products={prods}
      productsLoading={serverProducts === undefined}
      gateway="stripe"
      userID={userID}
      email={email}
      logger={logger}
    />
  );
}
