import React from "react";
import { IGeneratedRow } from "./lib";
import { IDeck } from "@models/deck";
import CardPreview from "@cardRendering/cardPreview";

interface IProps {
  deck: IDeck;
  genIndex: number;
  rows: IGeneratedRow[];
  columns: string[];
  updateRow: (genIndex: number, rowIndex: number, row: IGeneratedRow) => void;
}
export default function GenCardPreviews(props: IProps) {
  if (!props.deck.config) {
    return null;
  }
  const style = {
    textAlign: "center",
    display: "flex",
    gap: 12,
    flexWrap: "nowrap",
    flexDirection: "row",
    overflowX: "auto",
    maxWidth: "100%",
    padding: 12,
    margin: 16,
  } as React.CSSProperties;

  return (
    <div style={style}>
      {props.rows.map((row, i) => {
        return (
          <div key={i} style={{ position: "relative" }}>
            <CardPreview
              deck={props.deck}
              editable={false}
              visibleSide={1}
              disableTouchEvents={false}
              autoplayAudio={false}
              knol={{
                id: "id",
                deck_id: props.deck.id,
                values: row.values,
              }}
              isClickable
              onClick={() => props.updateRow(props.genIndex, i, { ...row, enabled: !row.enabled })}
              style={row.enabled ? { border: "2px solid var(--ion-color-primary)" } : undefined}
            />
          </div>
        );
      })}
    </div>
  );
}
