import { showPrompt } from "@components/prompt";
import { useIonAlert } from "@ionic/react";
import { Deck } from "@models/deck";
import { useDecks } from "hooks/data/deckList";
import L10n from "localization";
import Network from "network";

export function useShareDeckModal(groupId: string, onSuccess: () => void): [() => void] {
  const [showAlert, hideAlert] = useIonAlert();
  const decks = useDecks();

  function sendShare(deckId: string, groupId: string) {
    Network.fetch("POST", "/decks/share", {
      deck_id: deckId,
      groups: [{ id: groupId }],
    })
      .then(onSuccess)
      .catch(() => {
        return showPrompt({
          title: L10n.localize((s) => s.general.attention),
          prompt: L10n.localize((s) => s.error.communication),
          promptType: "alert",
        });
      });
  }

  async function showShareDeckModal() {
    try {
      const sortedDecks = decks.sort((a, b) => a.name.localeCompare(b.name));
      showAlert({
        translucent: true,
        header: L10n.localize((s) => s.sharing.shareDeck),
        inputs: sortedDecks.map((deck) => ({
          name: deck.id,
          type: "checkbox" as const,
          label: Deck.displayName(deck),
          value: deck.id,
        })),
        buttons: [
          {
            text: L10n.localize((s) => s.actions.cancel),
            role: "cancel",
            cssClass: "secondary",
            handler: hideAlert,
          },
          {
            text: L10n.localize((s) => s.actions.share),
            handler: (deckIds: string[] | undefined) => {
              deckIds?.forEach((deckId) => {
                sendShare(deckId, groupId);
              });
              hideAlert();
            },
          },
        ],
      });
    } catch {
      showPrompt({
        title: L10n.localize((s) => s.general.attention),
        prompt: L10n.localize((s) => s.error.communication),
        promptType: "alert",
      });
    }
  }

  return [showShareDeckModal];
}
