import Globals from "globals";
import L10n from "localization";
import React from "react";
import { IonButton, IonIcon, IonInput, IonItem, IonList, IonNote } from "@ionic/react";
import { mailOutline } from "ionicons/icons";
import cyAttr from "lib/cyAttr";
import ShareListItem from "./shareListItem";

interface IProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
  title?: string;
}
export default function EditableEmailList(props: IProps): JSX.Element {
  const { emails, setEmails } = props;

  const [val, setVal] = React.useState<string | null | undefined>("");

  function handleAddEmail(evt: { preventDefault: () => void; stopPropagation: () => void }) {
    if (!isValid) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();

    const valEmails = val?.split(",").map((email) => email.trim());
    const newEmails = valEmails?.filter((email) => !emails.includes(email)) || [];

    if (newEmails.length === 0) {
      return;
    }

    // NOTE: email validation is handled by the input component.

    setEmails([...newEmails, ...emails]);
    setVal("");
  }

  function handleRemoveEmail(email: string) {
    setEmails(emails.filter((e) => e !== email));
  }

  const isValid = val ? Globals.emailValidationRegex.test(val) : false;

  return (
    <div
      style={{
        flex: "1 1 auto",
        marginTop: "1rem",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IonList inset>
        <IonItem color="light">
          <IonInput
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                handleAddEmail(e);
              }
            }}
            {...cyAttr("share-modal-email-input")}
            style={{ "--color": !isValid && val !== "" ? "var(--ion-color-danger)" : undefined }}
            value={val}
            onIonInput={(evt) => setVal(evt.detail.value)}
            type="email"
            placeholder={L10n.localize((s) => s.account.email)}
          >
            <IonIcon icon={mailOutline} slot="start" />
          </IonInput>

          <IonButton
            fill="clear"
            size="large"
            onClick={handleAddEmail}
            type="submit"
            disabled={!isValid}
          >
            {L10n.localize((s) => s.actions.add)}
          </IonButton>
        </IonItem>
      </IonList>
      <IonList style={{ height: "100%", overflow: "scroll" }}>
        {emails.map((email) => (
          <ShareListItem key={email} email={email} onRemove={handleRemoveEmail} />
        ))}
      </IonList>
      {emails.length < 1 ? (
        <IonNote>
          <p className="ion-margin-horizontal ion-text-wrap">
            {L10n.localize((s) => s.sharing.emailShareInstructions)}
          </p>
        </IonNote>
      ) : undefined}
    </div>
  );
}
