import { useNewFolderHandler } from "@components/newFolderButton";
import ScreenMenu, { MenuButton } from "@components/screenMenu";
import { Deck, IDeck } from "@models/deck";
import { useDecks } from "hooks/data/deckList";
import L10n from "localization";
import React from "react";
import {
  LuFilePlus,
  LuFolderEdit,
  LuFolderInput,
  LuFolderOutput,
  LuFolderSymlink,
  LuFolderTree,
  LuFolderX,
} from "react-icons/lu";
import { ID } from "types/ID";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import MultiSelectModal from "@components/multiSelectModal";

export function useAddToFolderDeckSelector({
  onSelect,
}: {
  onSelect: (decks: IDeck[]) => void;
}): () => void {
  const decks = useDecks();
  const sortedDecks = decks.sort((d1, d2) => ((d1.name ?? "") < (d2.name ?? "") ? -1 : 1));

  const [showModal] = useIonCardModalWithDismiss(MultiSelectModal<IDeck>, {
    title: L10n.localize((s) => s.folder.moveDeckHere),
    selectLabel: L10n.localize((s) => s.actions.move),
    options: sortedDecks.map((deck) => {
      const name = Deck.displayName(deck);
      return {
        name: name,
        val: deck,
      };
    }),
    onSelect: (decks: IDeck[]) => {
      if (!decks) {
        return;
      }
      onSelect(decks);
    },
  });

  return showModal;
}

export function Menu({
  folder,
  createDeck,
  addToFolder,
  removeFromFolder,
  decks,
  archiveFolder,
  renameFolder,
  moveFolder,
}: {
  folder: string;
  createDeck: () => void;
  removeFromFolder: (deckIDs: ID[]) => Promise<void>;
  addToFolder: (decks: IDeck[]) => Promise<void>;
  archiveFolder: () => Promise<void>;
  renameFolder: () => Promise<void>;
  moveFolder: (folder: string) => Promise<void>;
  decks: IDeck[];
}) {
  const showDeckSelect = useAddToFolderDeckSelector({
    onSelect: addToFolder,
  });

  const handleNewFolder = useNewFolderHandler(folder);

  const [showRemoveDeckModal] = useIonCardModalWithDismiss(MultiSelectModal<ID>, {
    title: L10n.localize((s) => s.folder.removeDeckFrom),
    selectLabel: L10n.localize((s) => s.actions.remove),
    options: decks.map((deck) => {
      return {
        name: deck.name,
        val: deck.id,
      };
    }),
    onSelect: async (deckIDs: string[]) => {
      if (!deckIDs) {
        return;
      }
      await removeFromFolder(deckIDs);
    },
  });

  return (
    <ScreenMenu>
      <MenuButton
        label={L10n.localize((s) => s.deck.new)}
        icon={<LuFilePlus size={24} />}
        onClick={createDeck}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.folder.moveDeckHere)}
        icon={<LuFolderSymlink size={24} />}
        onClick={showDeckSelect}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.folder.removeDeckFrom)}
        icon={<LuFolderOutput size={24} />}
        onClick={showRemoveDeckModal}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.folder.newSubfolder)}
        icon={<LuFolderTree size={22} />}
        onClick={handleNewFolder}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.folder.rename)}
        icon={<LuFolderEdit size={24} />}
        onClick={renameFolder}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.actions.move)}
        icon={<LuFolderInput size={24} />}
        onClick={moveFolder}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.archive.verb)}
        icon={<LuFolderX size={24} />}
        onClick={archiveFolder}
        color="danger"
        detail={false}
        lines="none"
      />
    </ScreenMenu>
  );
}
