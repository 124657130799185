import { useLoadingText } from "@components/loadingText";
import { IonTextarea, TextareaCustomEvent } from "@ionic/react";
import FieldValueEditorItem from "fields/components/fieldValueEditorItem";
import { IFieldValueEditorProps } from "fields/fieldType";
import { fieldTypeMap } from "fields/fields";
import useAutoFocusIonTextarea from "fields/hooks/useAutoFocusIonTextarea";
import useSetLangIonTextarea from "fields/hooks/useSetLangIonTextarea";
import { hasFieldRef } from "fields/sources/lib";
import useValueEditorSourcePipeline from "fields/sources/useSourcePipeline";
import { IFieldTTS } from "fields/types/tts";
import L10n from "localization";
import React, { useRef } from "react";

export default function FieldValueEditorTTS({
  field,
  name,
  value,
  onChange,
  editorRef,
  autoFocus,
  editable,
  onFocus,
}: IFieldValueEditorProps<IFieldTTS>): JSX.Element {
  const ttsType = fieldTypeMap.tts;
  const parsedVal = value !== undefined ? ttsType.loadFML(value) : undefined;
  const textVal = parsedVal?.text;

  const lang = field.attributes?.lang;
  const ref = useRef<HTMLIonTextareaElement>(null);
  useSetLangIonTextarea(lang, ref);
  useAutoFocusIonTextarea(autoFocus, ref.current);

  async function handleChange(newVal: string) {
    const fml = ttsType.dumpFML({ text: newVal });
    onChange(name, fml);
  }

  const pipelineUpdatePending = useValueEditorSourcePipeline(field, onChange);

  const hasNonTranslationSourceRef =
    hasFieldRef(field.source) && field.source?.type !== "translation";
  const disabled = editable === false || hasNonTranslationSourceRef || pipelineUpdatePending;
  const loadingText = useLoadingText(
    pipelineUpdatePending,
    L10n.localize((s) => s.general.processing),
  );

  return (
    <FieldValueEditorItem
      name={name}
      field={field}
      editorRef={editorRef}
      onTextPaste={disabled ? undefined : handleChange}
    >
      <IonTextarea
        ref={ref}
        autoGrow
        disabled={disabled}
        value={pipelineUpdatePending ? loadingText : textVal}
        spellCheck
        rows={1}
        placeholder={disabled ? undefined : L10n.localize((s) => s.general.clickToEdit)}
        onIonFocus={() => onFocus(name)}
        onIonInput={(evt: TextareaCustomEvent) => {
          if (!editable) {
            return;
          }
          const val = evt.target.value;
          handleChange(val ?? "");
        }}
      />
    </FieldValueEditorItem>
  );
}
