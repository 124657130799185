import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  SearchbarCustomEvent,
} from "@ionic/react";
import { ionicMode } from "ionicApp";
import L10n from "localization";
import React from "react";

interface IProps {
  title: string;
  children: React.ReactNode;
  onLiveSearch?: (query: string) => void;
  query?: string;
  footer?: React.ReactNode;
  rightButton?: React.ReactNode;
}
export default function NestedNav({
  title,
  children,
  query,
  onLiveSearch,
  footer,
  rightButton,
}: IProps) {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          {rightButton ? <IonButtons slot="end">{rightButton}</IonButtons> : undefined}
        </IonToolbar>
        {onLiveSearch ? (
          <IonToolbar>
            <IonSearchbar
              value={query}
              onIonInput={(e: SearchbarCustomEvent) => {
                const newVal = e.detail.value;
                if (newVal !== undefined && newVal !== null) {
                  onLiveSearch(newVal);
                }
              }}
              placeholder={L10n.localize((s) => s.actions.search)}
              style={
                {
                  "--box-shadow": ionicMode === "md" ? "none" : undefined,
                } as React.CSSProperties & { "--box-shadow": string }
              }
            />
          </IonToolbar>
        ) : undefined}
      </IonHeader>
      {footer ? <IonFooter>{footer}</IonFooter> : undefined}
      <IonContent>{children}</IonContent>
    </>
  );
}
