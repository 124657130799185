import NestedNav from "@components/nestedNav";
import * as NameStore from "@data/stores/name";
import { IonButton, IonInput, IonItem, IonLabel, IonList, IonNote } from "@ionic/react";
import cyAttr from "@lib/cyAttr";
import { createStudyGroup, setUserName } from "@models/studyGroup";
import { History } from "history";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import React, { useCallback, useEffect, useState } from "react";
import { ID } from "types/ID";

interface IProps {
  onShare: (groupIDs: ID[]) => Promise<void>;
  history: History;
}
export default function NewStudyGroupNav(props: IProps) {
  const [showError] = useErrorAlert({ code: "MAKING_STUDY_GROUP" });
  const [name, setName] = useState<string>(L10n.localize((s) => s.groups.defaultName));

  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  useEffect(() => {
    NameStore.getName().then((name) => {
      setFirstName(name.first);
      setLastName(name.last);
    });
  }, []);

  const handleShare = useCallback(async () => {
    if (!firstName || !name) {
      return;
    }
    try {
      const resp = await createStudyGroup(name);
      await props.onShare([resp.id]);
      await setUserName(firstName, lastName);
      props.history.push(`/groups/${resp.id}`);
    } catch (err) {
      showError(err);
    }
  }, [name, props.onShare, showError, props.history, firstName, lastName]);

  const disabled = !name || !firstName;

  return (
    <NestedNav
      title={L10n.localize((s) => s.groups.new)}
      rightButton={
        <IonButton disabled={disabled} onClick={handleShare} {...cyAttr("share-button")}>
          {L10n.localize((s) => s.actions.share)}
        </IonButton>
      }
    >
      <IonNote>
        <p className="ion-margin-horizontal ion-text-wrap">
          {L10n.localize((s) => s.groups.groupNameInstruction)}
        </p>
      </IonNote>
      <IonList inset>
        <IonItem color="light">
          <IonLabel>{L10n.localize((s) => s.general.name)}</IonLabel>
          <IonInput
            value={name}
            clearInput
            onIonInput={(e) => setName(e.detail.value ?? "")}
            autoFocus
          />
        </IonItem>
      </IonList>
      <IonNote>
        <p className="ion-margin-horizontal ion-text-wrap">
          {L10n.localize((s) => s.groups.namePromptInstruction)}
        </p>
      </IonNote>
      <IonList inset>
        <IonItem color="light">
          <IonLabel>{L10n.localize((s) => s.general.firstName)}</IonLabel>
          <IonInput
            {...cyAttr("first-name")}
            placeholder={L10n.localize((s) => s.general.required)}
            value={firstName}
            autocomplete="given-name"
            autoCapitalize="words"
            onIonInput={(e) => setFirstName(e.detail.value ?? "")}
          />
        </IonItem>
        <IonItem color="light">
          <IonLabel>{L10n.localize((s) => s.general.lastName)}</IonLabel>
          <IonInput
            value={lastName}
            placeholder={L10n.localize((s) => s.general.optional)}
            autocomplete="family-name"
            autoCapitalize="words"
            onIonInput={(e) => setLastName(e.detail.value ?? "")}
          />
        </IonItem>
      </IonList>
    </NestedNav>
  );
}
