import LoadingSpinner from "@components/loadingSpinner";
import { IonButton, IonSpinner } from "@ionic/react";
import EventBus from "eventBus";
import L10n from "localization";
import React, { useState, useEffect } from "react";
import Style from "style";
import PDPLogger from "unlimited/logger";
import { IProduct } from "unlimited/types";
import CTA from "./cta";
import FinePrint from "./finePrint";
import Pitch from "./pitch";
import Price from "./price";
import ProductItem from "./productItem";
import StripeQRCode from "./stripeQRCode";
import UnlimitedBadge from "./unlimitedBadge";

interface IProps<T extends IProduct> {
  reason?: string;
  onPurchase: (product: T) => void;
  showAppleTerms?: boolean;
  products: T[];
  productsLoading?: boolean;
  onForcePastAuthgate?: () => void;
  gateway?: "stripe" | "apple";
  userID: string;
  email: string;
  logger?: PDPLogger;
}

export function Products<T extends IProduct>(props: IProps<T>): JSX.Element {
  const {
    showAppleTerms,
    products,
    productsLoading,
    onPurchase,
    reason,
    gateway,
    email,
    userID,
    logger,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const recurringProduct = products.filter(
    (prod) => prod.frequency !== null && prod.frequency !== undefined,
  )[0];
  const [showAll, setShowAll] = useState(gateway === "stripe");
  const [selectedProduct, setSelectedProduct] = useState(recurringProduct);
  const activeProduct = selectedProduct ?? recurringProduct;

  useEffect(() => {
    function handleIAPEvent(status: string) {
      if (status === "cancelled") {
        setLoading(false);
      }
    }

    EventBus.on("iap", handleIAPEvent);

    return () => {
      EventBus.off("iap", handleIAPEvent);
    };
  }, []);

  function handlePurchase(product: T) {
    setLoading(true);
    onPurchase(product);
  }

  const style: React.CSSProperties = {
    color: Style.colors.primaryFg,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  if (!gateway) {
    // No way to purchase.
    return (
      <div style={style}>
        <CTA />
        <Pitch reason={reason} />
        <h4 style={{ userSelect: "text" }}>{L10n.localize((s) => s.account.upgradePitch)}</h4>
        {loading && <LoadingSpinner />}
      </div>
    );
  }

  let sortedProducts = products;
  if (recurringProduct) {
    if (showAll) {
      sortedProducts = [recurringProduct].concat(
        products.filter((p) => p.id !== recurringProduct.id),
      );
    } else {
      sortedProducts = [recurringProduct];
    }
  }

  return (
    <div style={style}>
      <UnlimitedBadge style={{ marginTop: 18 }} />
      <Pitch reason={reason} style={{ marginBottom: 18 }} />
      {productsLoading ? (
        <div style={{ textAlign: "center" }}>
          <IonSpinner />
        </div>
      ) : (
        <>
          {sortedProducts.map((product: T) => (
            <ProductItem
              key={product.id}
              product={product}
              selected={activeProduct?.id === product.id}
              onClick={() => setSelectedProduct(product)}
            />
          ))}
        </>
      )}

      {!showAll ? (
        <IonButton
          fill="clear"
          size="small"
          style={{ color: "var(--ion-color-medium)" }}
          onClick={() => setShowAll(true)}
        >
          {L10n.localize((s) => s.account.seeAllPurchaseOptions)}
        </IonButton>
      ) : undefined}

      {gateway === "stripe" ? (
        <StripeQRCode
          email={email}
          userID={userID}
          product={activeProduct}
          onComplete={() => onPurchase(activeProduct)}
          logger={logger}
        />
      ) : (
        <div style={{ marginTop: 36, width: "100%", textAlign: "center" }}>
          <div
            style={{
              fontSize: 14,
              color: "var(--ion-color-medium)",
            }}
          >
            <Price product={activeProduct} />
          </div>
          <IonButton
            disabled={loading}
            expand="block"
            onClick={() => handlePurchase(activeProduct)}
            size="large"
            style={{ minHeight: 72, marginTop: 8, marginBottom: 48, "--color": "white" }}
          >
            {activeProduct?.eligible_for_free_trial
              ? L10n.localize((s) => s.account.tryItFree)
              : L10n.localize((s) => s.account.upgradeNow)}
          </IonButton>

          <FinePrint showAppleTerms={showAppleTerms} product={activeProduct} />
        </div>
      )}

      {loading && <LoadingSpinner />}
    </div>
  );
}
