import L10n from "localization";
import React from "react";
import { IonItem, IonLabel, IonList, IonNavLink } from "@ionic/react";
import NewStudyGroupNav from "./newStudyGroupNav";
import { ID } from "types/ID";
import { History } from "history";
import cyAttr from "@lib/cyAttr";

interface IProps {
  onShare: (groupIDs: ID[]) => Promise<void>;
  history: History;
}
export default function StudyGroupsEmpty(props: IProps) {
  return (
    <IonNavLink
      routerDirection="forward"
      component={() => <NewStudyGroupNav onShare={props.onShare} history={props.history} />}
    >
      <IonList inset>
        <IonItem button detail color="light" {...cyAttr("new-study-group-button")}>
          <IonLabel>
            <h1>{L10n.localize((s) => s.sharing.addStudyGroup)}</h1>
            <p>{L10n.localize((s) => s.sharing.studyGroupsDescription)}</p>
          </IonLabel>
        </IonItem>
      </IonList>
    </IonNavLink>
  );
}
