import CardPreview from "@cardRendering/cardPreview";
import ClickableLabeledSection from "@components/clickableLabeledSection";
import Empty from "@components/empty";
import idb from "@data/idb";
import { IonList, IonProgressBar } from "@ionic/react";
import { Deck, IDeck } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import { MoreButton } from "@screens/deck/cardsSection";
import EventBus from "eventBus";
import React from "react";
import { ID } from "types/ID";

const NUM_MAX_CARD_PREVIEWS_PER_DECK = 10;

export interface ILayoutResult {
  id: string;
  name: string;
  style: string;
  templatesJson: string;
  templates: string[];
}

function DeckOfCards(props: {
  deck: IDeck;
  cards: IKnol[];
  query: string;
}): JSX.Element {
  const { deck, cards, query } = props;

  const cardHeight = 280;
  const cardWidth = 240;
  const displayName = Deck.displayName(deck);

  return (
    <ClickableLabeledSection label={displayName} href={`/decks/local/${deck.id}`}>
      <div
        style={{
          overflowX: "auto",
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          padding: 8,
          gap: 8,
        }}
      >
        {cards.slice(0, NUM_MAX_CARD_PREVIEWS_PER_DECK).map((card) => {
          return (
            <CardPreview
              key={card.id}
              isClickable
              showHeader
              query={query}
              visibleSide={1}
              deck={deck}
              knol={card}
              cardWidth={cardWidth}
              cardHeight={cardHeight}
              disableAudioControlsRendering
            />
          );
        })}

        {cards.length > NUM_MAX_CARD_PREVIEWS_PER_DECK && (
          <MoreButton deckID={deck.id} query={query} />
        )}
      </div>
    </ClickableLabeledSection>
  );
}

export default function CardResults(props: {
  query: string;
}) {
  const { query } = props;

  const [state, setState] = React.useState({
    loading: query !== "",
    results: new Map() as Map<ID, { deck: IDeck; knolsWithGrades: IKnol[] }>,
  });

  React.useEffect(() => {
    const fetchCards = async (
      query: string,
    ): Promise<Map<ID, { deck: IDeck; knolsWithGrades: IKnol[] }>> => {
      const tx = idb.db.transaction(["knols", "decks"], "readonly");
      const knolStore = tx.objectStore("knols");
      const knolIndex = knolStore.index("deck_id");
      const decksStore = tx.objectStore("decks");

      let cursor = await knolIndex.openCursor();
      const knols = [] as IKnol[];
      const deckIds = new Set<ID>();

      while (cursor) {
        if (Knol.matchesQuery(cursor.value, query)) {
          knols.push(cursor.value);
          deckIds.add(cursor.value.deck_id);
        }
        cursor = await cursor.continue();
      }

      const knolsWithGrades = knols.map((knol) => ({
        ...knol,
        grades: knol.grades || {}, // Ensure grades is an object, even if empty
      }));

      const resultMap = new Map<ID, { deck: IDeck; knolsWithGrades: IKnol[] }>();

      for (const deckId of deckIds) {
        const deck = await decksStore.get(deckId);
        if (!deck) continue; // Skip if deck is not found

        const filteredKnolsWithGrades = knolsWithGrades.filter((knol) => knol.deck_id === deckId);
        resultMap.set(deckId, { deck, knolsWithGrades: filteredKnolsWithGrades });
      }

      return resultMap;
    };

    const handleUpdate = () => {
      if (query) {
        fetchCards(query)
          .then((r) => {
            setState({ loading: false, results: r });
          })
          .catch((err) => {
            setState((prevState) => ({ ...prevState, loading: false }));
          });
      } else {
        setState({
          results: new Map() as Map<ID, { deck: IDeck; knolsWithGrades: IKnol[] }>,
          loading: false,
        });
      }
    };

    handleUpdate();
    EventBus.on("knolUpdated", handleUpdate);
    return () => {
      EventBus.off("knolUpdated", handleUpdate);
    };
  }, [query]);

  const items = state.results
    ? [...state.results.entries()].map(([deckId, { deck, knolsWithGrades }]) => {
        return <DeckOfCards key={deckId} deck={deck} cards={knolsWithGrades} query={query} />;
      })
    : [];

  return (
    <IonList>
      {state.loading && <IonProgressBar type="indeterminate" />}
      {items.length < 1 && !state.loading && <Empty />}
      {items}
    </IonList>
  );
}
