import { IonSkeletonText } from "@ionic/react";
import React, { useEffect, useRef } from "react";

type IProps = {
  numRows: number;
  numCols: number;
  style?: React.CSSProperties;
};

export default function LoadingTable({ numRows, numCols, style }: IProps) {
  // Scroll to the loading table when it mounts.
  const ref = useRef<HTMLTableElement>(null);
  useEffect(() => {
    ref.current?.parentElement?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <table style={{ width: "100%", ...style }} ref={ref}>
      <tbody>
        {Array.from({ length: numRows }, (_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: numCols }, (_, colIndex) => (
              <td key={colIndex} style={{ height: 24, padding: 6 }}>
                <IonSkeletonText
                  animated={true}
                  style={
                    { "--border-radius": 0 } as React.CSSProperties & { "--border-radius": number }
                  }
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
