import FileDropZone from "@components/fileDropZone";
import { showPrompt } from "@components/prompt";
import TipDot from "@components/tipDot";
import { IInboxItem } from "@data/idb";
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonFooter,
  IonHeader,
  IonNote,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { Inbox } from "@models/inbox";
import { IKnol, Knol } from "@models/knol";
import logEvent from "analytics";
import { clearDot } from "dots";
import { useFlagAndContext } from "featureFlags";
import useKeyboardHeight from "hooks/useKeyboardHeight";
import useConfirmCancellationDialog from "hooks/util/useConfirmCancellationDialog";
import useErrorAlert from "hooks/util/useErrorAlert";
import { file2Base64 } from "lib";
import Lib from "lib";
import L10n from "localization";
import Network from "network";
import React, { useCallback, useState, useRef, useEffect } from "react";
import { LuAlertTriangle } from "react-icons/lu";
import { ID } from "types/ID";
import FieldsCard from "./genCards/fieldsCard";
import GenTable from "./genCards/genTable";
import {
  IGenerateResponse,
  IGeneratedRow,
  isLikelyDuplicate,
  objDiff,
  parseGeneratedResponse,
} from "./genCards/lib";
import LoadingTable from "./genCards/loadingTable";
import PromptCard from "./genCards/promptCard";
import PromptInput from "./genCards/promptInput";
import { getPhoto } from "@lib/camera";

interface IGeneration {
  prompt: string | null | undefined;
  fileBase64: string | undefined;
  rows: IGeneratedRow[];
}

interface IProps {
  deck: IDeck;
  knols: MapView<ID, IKnol>;
  dismiss: () => void;
  setCanDismiss: (v: boolean) => void;
}
export default function GenCardsModal(props: IProps): JSX.Element {
  const { deck, dismiss, knols, setCanDismiss } = props;

  useEffect(() => {
    clearDot("generate_cards_menu_item").catch(() => {});
  }, []);

  const [inboxItem, setInboxItem] = useState<IInboxItem>();
  useEffect(() => {
    async function loadQueuedBlob() {
      try {
        const item = await Inbox.getItemQueuedForGeneration();
        if (!item) {
          return;
        }
        const base64 = await file2Base64(item.blob);
        setInboxItem(item);
        setFileBase64(base64);
        Inbox.clearItemQueuedForGeneration();
      } catch (err) {
        console.log(err);
      }
    }
    loadQueuedBlob();
  }, []);

  const [prompt, setPrompt] = useState<string | null | undefined>();
  const [fileBase64, setFileBase64] = useState<string>();
  const [generating, setGenerating] = useState(false);
  const [generations, setGenerations] = useState<IGeneration[]>([]);
  const [saving, setSaving] = useState(false);
  const [generated, setGenerated] = useState(false);

  const numCharsPerToken = 4;
  const features = useFlagAndContext("features");
  const aiFlag = features?.flag?.ai_card_create;
  const maxTokens = aiFlag?.max_tokens ?? 1024;
  const maxChars = maxTokens * numCharsPerToken;

  const columns = deck.config?.fields.map((f) => f.name) ?? [];
  const disabled =
    aiFlag === undefined || saving || generating || deck.config === undefined || generated;

  const sessionID = useRef<ID | null>();

  const dirty = generations.length > 0;
  useEffect(() => {
    setCanDismiss(!dirty);
  }, [dirty, setCanDismiss]);
  const presentConfirmCancel = useConfirmCancellationDialog(dismiss);
  const handleClose = useCallback(() => {
    if (!dirty) {
      dismiss();
      return;
    }
    presentConfirmCancel();
  }, [dirty, dismiss, presentConfirmCancel]);

  const [showError] = useErrorAlert({ code: "GENERATING_CARDS" });
  const generate = useCallback(async () => {
    try {
      setGenerating(true);

      // List of knols generated previously in this session.
      const pending = generations.flatMap((gen) =>
        gen.rows.filter((row) => row.enabled).map((row) => row.values),
      );

      const [resp, { headers }] = await Network.fetchWithMetadata<IGenerateResponse>({
        action: "POST",
        path: "/decks/ai",
        data: {
          file: fileBase64,
          prompt,
          deck: {
            ...deck,
            num_knols: knols.size,
          },
          pending,
          session_id: sessionID.current,
        },
      });
      sessionID.current = headers.get("x-ankiapp-ai-session-id");
      const respRows = parseGeneratedResponse(resp, columns, knols);

      // Sort duplicates to end.
      const nonDupeRows = respRows.filter((row) => !row.duplicate);
      const dupeRows = respRows.filter((row) => row.duplicate);
      const sortedRows = nonDupeRows.concat(dupeRows);

      setGenerations(generations.concat({ prompt, rows: sortedRows, fileBase64 }));
      setFileBase64(undefined);
      setPrompt(undefined);
      setGenerated(true);

      logEvent("knol_generate_event", {
        step: "generate",
        sessionID: sessionID.current,
        hasFile: fileBase64 !== undefined,
        prompt,
        numTotal: sortedRows.length,
      });
    } catch (err) {
      showError(err);
    } finally {
      setGenerating(false);
    }
  }, [prompt, generations, deck, columns, knols, showError, fileBase64]);

  // Patches override the value of a row, identified by row number.
  const [patches, setPatches] = useState<Map<number, Map<number, IGeneratedRow>>>(new Map());
  const updateRow = useCallback(
    (genIndex: number, rowIndex: number, newRow: IGeneratedRow) => {
      const duplicate = isLikelyDuplicate(knols, newRow.values);
      const newPatches = new Map(patches);
      const genMap = new Map<number, IGeneratedRow>(newPatches.get(genIndex));
      genMap.set(rowIndex, { ...newRow, duplicate });
      newPatches.set(genIndex, genMap);
      setPatches(newPatches);
    },
    [knols, patches],
  );

  // Apply patches.
  const gens = generations.map((g) => JSON.parse(JSON.stringify(g))); // Ugh. Have to do this to clone rows.
  for (const [i, genMap] of patches) {
    for (const [j, patch] of genMap) {
      if (gens?.[i]?.rows?.[j]) {
        gens[i].rows[j] = patch;
      }
    }
  }

  let numDupes = 0;
  let numEnabled = 0;
  let numTotal = 0;
  for (const gen of gens ?? []) {
    for (const row of gen.rows) {
      if (row.duplicate) {
        numDupes += 1;
      }
      if (row.enabled) {
        numEnabled += 1;
      }
      numTotal += 1;
    }
  }

  const save = useCallback(async () => {
    if (!gens) {
      return;
    }
    try {
      setSaving(true);
      const knolIDs: ID[] = [];
      const prompts: string[] = [];
      for (const gen of gens) {
        let anyEnabled = false;
        for (const row of gen.rows) {
          if (!row.enabled) {
            continue;
          }
          anyEnabled = true;
          const knol: IKnol = {
            id: Lib.uuid16(),
            deck_id: deck.id,
            values: row.values,
          };
          await Knol.Create(deck.id, knol);
          knolIDs.push(knol.id);
        }
        if (anyEnabled && gen.prompt) {
          prompts.push(gen.prompt);
        }
      }
      dismiss();

      if (inboxItem) {
        await Inbox.markItemSaved(inboxItem, deck.id);
        logEvent("inbox_event", { step: "item_saved_to_deck" });
      }

      let numEdited = 0;
      const edits: Record<string, string> = {};
      for (const [i, genMap] of patches) {
        for (const [j, patch] of genMap) {
          const origRow = generations[i]?.rows?.[j];
          if (patch.enabled && origRow && objDiff(patch.values, origRow.values)) {
            numEdited += 1;
            for (const key in patch.values) {
              const orig = origRow.values[key];
              const edit = patch.values[key];
              if (edit !== orig) {
                edits[orig] = edit;
              }
            }
          }
        }
      }
      logEvent("knol_generate_event", {
        step: "save",
        sessionID: sessionID.current,
        prompts,
        numTotal,
        numDupes,
        numEnabled,
        numEdited,
        edits,
        knolIDs,
      });
    } catch (err) {
      // TODO: handle intermediate error (error in middle of inserting all knols).
    } finally {
      setSaving(false);
    }
  }, [gens, patches, generations, deck.id, dismiss, numDupes, numEnabled, numTotal, inboxItem]);

  const [showPhotoPickerError] = useErrorAlert({ code: "PICKING_PHOTO" });
  const pickPhoto = useCallback(async () => {
    try {
      const image = await getPhoto();
      const base64 = image.base64String;
      if (!base64) {
        return null;
      }

      const mimeType = `image/${image.format}`;
      setFileBase64(`data:${mimeType};base64,${base64}`);
    } catch (err) {
      if (err instanceof Error && err.message === "User cancelled photos app") {
        return;
      }
      if (err instanceof Error && err.message.startsWith("User denied access")) {
        showPrompt({
          prompt: L10n.localize((s) => s.errors.photoPermissionDenied),
          promptType: "alert",
          title: L10n.localize((s) => s.general.attention),
        });
        return;
      }
      showPhotoPickerError(err);
    }
  }, [showPhotoPickerError]);

  const handleFileSelected = useCallback(
    async (file: File) => {
      if (disabled) {
        return;
      }
      try {
        const fileB64 = await file2Base64(file);
        setFileBase64(fileB64);
      } catch (err) {
        showPhotoPickerError(err);
      }
    },
    [disabled, showPhotoPickerError],
  );

  const [keyboardOpen, setKeyboardShowing] = useState(false);
  const onKeyboardShow = useCallback(() => setKeyboardShowing(true), [setKeyboardShowing]);
  const onKeyboardHide = useCallback(() => setKeyboardShowing(false), [setKeyboardShowing]);
  const kbHeightPx = useKeyboardHeight(onKeyboardShow, onKeyboardHide);

  return (
    <IonPage style={{ marginBottom: keyboardOpen ? undefined : "env(safe-area-inset-bottom)" }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.card.generate)}</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={handleClose} color={dirty ? "danger" : undefined}>
              {dirty
                ? L10n.localize((s) => s.actions.cancel)
                : L10n.localize((s) => s.actions.close)}
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton onClick={save} disabled={saving || numEnabled < 1 || generating}>
              {saving ? <IonSpinner /> : L10n.localize((s) => s.actions.save)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonFooter
        style={{
          display: generating ? "none" : undefined,
          textAlign: "center",
          paddingBottom: kbHeightPx,
        }}
      >
        {generated ? (
          <IonButton fill="clear" onClick={() => setGenerated(false)}>
            {L10n.localize((s) => s.generation.generateMore)}
          </IonButton>
        ) : (
          <PromptInput
            disabled={disabled}
            prompt={prompt}
            setPrompt={setPrompt}
            pickPhoto={pickPhoto}
            generate={generate}
            maxChars={maxChars}
            enableText={aiFlag?.with_prompt}
            enablePhoto={aiFlag?.with_image}
          />
        )}
      </IonFooter>
      <IonContent color="light">
        <FileDropZone
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
          }}
          onFileSelected={handleFileSelected}
          disabled={disabled || !aiFlag.with_image}
        >
          {gens.length < 1 ? (
            <>
              <FieldsCard deck={deck} />
              <TipDot
                dotID="generate_cards_modal_suggestions"
                message={L10n.localize((s) => s.generation.suggestedUses)}
              />
            </>
          ) : undefined}

          {gens.map((gen, gi) => {
            return (
              <React.Fragment key={gi}>
                {gen.fileBase64 || gen.prompt ? (
                  <PromptCard fileBase64={gen.fileBase64} prompt={gen.prompt} />
                ) : undefined}
                <GenTable genIndex={gi} rows={gen.rows} columns={columns} updateRow={updateRow} />
              </React.Fragment>
            );
          })}

          {fileBase64 || (generating && prompt) ? (
            <PromptCard
              fileBase64={fileBase64}
              prompt={prompt}
              clearAttachment={() => setFileBase64(undefined)}
            />
          ) : undefined}

          {generating ? (
            <IonCard style={{ width: "calc(100% - 24px)", padding: 8 }}>
              <LoadingTable numRows={5} numCols={2} />
            </IonCard>
          ) : undefined}

          {gens.length > 0 && !generating ? (
            <IonNote>
              <div
                className="ion-margin-horizontal ion-text-wrap"
                style={{ textAlign: "center", marginTop: 0 }}
              >
                {numDupes && numDupes > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 4,
                      marginBottom: 4,
                    }}
                  >
                    <LuAlertTriangle />
                    {`${numDupes} ${L10n.localize((s) => s.card.numDuplicatesDetected)}`}
                  </div>
                ) : undefined}
                <div>{`${numEnabled}/${numTotal} ${L10n.localize(
                  (s) => s.general.selected,
                ).toLowerCase()}`}</div>
              </div>
            </IonNote>
          ) : undefined}

          <IonNote style={{ marginTop: "auto" }}>
            {!generated && !generating ? (
              <p className="ion-margin-horizontal ion-text-wrap" style={{ textAlign: "center" }}>
                {L10n.localize((s) => s.general.generateCardInstructions)}
              </p>
            ) : (
              <p className="ion-margin-horizontal ion-text-wrap" style={{ textAlign: "center" }}>
                {L10n.localize((s) => s.card.generateFallibilityWarning)}
              </p>
            )}
          </IonNote>
        </FileDropZone>
      </IonContent>
    </IonPage>
  );
}
