import NestedNav from "@components/nestedNav";
import {
  IonItem,
  IonLabel,
  IonList,
  IonNavLink,
  IonNote,
  IonSearchbar,
  SearchbarCustomEvent,
} from "@ionic/react";
import { fuzzyMatch } from "@lib/search";
import { History } from "history";
import L10n from "localization";
import React, { useState } from "react";
import Breadcrumbs from "./breadcrumbs";
import OtherListNavItem from "./otherListNavItem";

export interface IPromptOption {
  name: string;
  val: string;
  desc?: string;
}

interface IProps<T> {
  description: string;
  title: string;
  options: IPromptOption[];
  dismiss: () => Promise<void>;
  history: History;
  searchable?: boolean;
  other?: boolean;
  onNav: (opt: IPromptOption) => React.ReactElement;
}

export default function SingleSelectNav<T extends string>(props: IProps<T>): JSX.Element {
  const { title, description, options } = props;
  const [query, setQuery] = useState<string>();
  const matches = !query ? options : options.filter((opt) => fuzzyMatch(query, opt.name));

  return (
    <NestedNav
      title={L10n.localize((s) => s.getStarted.getStarted)}
      color="light"
      headerChildren={<Breadcrumbs crumb="details" />}
    >
      <>
        <h2 className="ion-margin-horizontal">{title}</h2>
        <IonNote>
          <p className="ion-margin-horizontal ion-text-wrap">{description}</p>
        </IonNote>
        <IonList inset>
          {props.searchable ? (
            <IonItem>
              <IonSearchbar
                style={{ padding: 0 }}
                value={query}
                onIonInput={(e: SearchbarCustomEvent) => {
                  const newVal = e.detail.value;
                  if (newVal !== undefined && newVal !== null) {
                    setQuery(newVal);
                  }
                }}
              />
            </IonItem>
          ) : undefined}
          {matches.map((opt) => {
            return (
              <IonNavLink
                key={opt.val}
                routerDirection="forward"
                component={() => props.onNav(opt)}
              >
                <IonItem detail>
                  <IonLabel className="ion-text-wrap">
                    <h2>{opt.name}</h2>
                    {opt.desc ? <p>{opt.desc}</p> : undefined}
                  </IonLabel>
                </IonItem>
              </IonNavLink>
            );
          })}
          {props.other ? <OtherListNavItem onNav={props.onNav} /> : undefined}
        </IonList>
      </>
    </NestedNav>
  );
}
