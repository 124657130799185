import { showPrompt } from "@components/prompt";
import {
  InputCustomEvent,
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonNote,
} from "@ionic/react";
import logEvent from "analytics";
import DeckDownloadManager from "deckDownloadManager";
import Globals from "globals";
import { History } from "history";
import { closeCircle, documentOutline, folderOutline } from "ionicons/icons";
import Lib from "lib";
import L10n from "localization";
import Network, { NetworkError } from "network";
import React, { useRef, useState } from "react";

interface IImportResponse {
  decks: { id: string; name: string; created_at: string }[];
}

function instructions() {
  return [
    L10n.localize((s) => s.import.spreadsheetColumn1Instruction),
    L10n.localize((s) => s.import.spreadsheetColumn2Instruction),
    L10n.localize((s) => s.import.spreadsheetColumn3Instruction),
    L10n.localize((s) => s.import.spreadsheetColumn4Instruction),
    L10n.localize((s) => s.import.spreadsheetColumn5Instruction),
    L10n.localize((s) => s.import.spreadsheetColumn6Instruction),
    L10n.localize((s) => s.import.spreadsheetColumn7Instruction),
  ] as const;
}

function ColumnInstruction({ index }: { index: number }) {
  const text = instructions()[index];
  return (
    <IonItem>
      <IonLabel style={{ whiteSpace: "normal" }}>{text}</IonLabel>
      <IonNote slot="start" color="primary">
        {index + 1}
      </IonNote>
    </IonItem>
  );
}

interface IBeginImportButtonProps {
  disabled: boolean;
  setLoading: () => void;
  file?: any;
  blob?: Blob;
  deckTitle?: string;
  resetForm: () => void;
  history: History;
  dismiss?: () => Promise<void>;
}
function BeginImportButton({
  disabled,
  setLoading,
  file,
  blob,
  deckTitle,
  resetForm,
  history,
  dismiss,
}: IBeginImportButtonProps) {
  function handleImport() {
    setLoading();

    logEvent("import_spreadsheet_submitted_file");

    Network.fetchWithMetadata<IImportResponse>({
      action: "POST",
      path: `/imports/csv?title=${deckTitle}`,
      data: blob ?? file,
    })
      .then(([response, _]) => {
        showPrompt({
          title: L10n.localize((s) => s.general.attention),
          prompt: L10n.localize((s) => s.import.success),
          promptType: "alert",
          callback: async () => {
            const deckId = response?.decks?.[0].id;
            if (deckId) {
              const uri = `/decks/${deckId}/data`;
              await DeckDownloadManager.addDownload(uri);
              history.push(`/decks/local/${deckId}`);
              await dismiss?.();
            }
          },
        });
      })
      .catch((e: NetworkError) => {
        if (e.statusCode >= 400 && e.statusCode < 500) {
          showPrompt({
            title: L10n.localize((s) => s.general.attention),
            prompt: `${L10n.localize((s) => s.import.errorSpreadsheet)}\n\n${L10n.localize(
              (s) => s.import.errorAdditionalDetails,
            )}: <p><b>${e.message}</b></p>`,
            promptType: "alert",
          });
        } else {
          showPrompt({
            title: L10n.localize((s) => s.general.attention),
            prompt: L10n.localize((s) => s.error.communication),
            promptType: "alert",
          });
        }
      })
      .finally(() => {
        resetForm();
      });
  }

  return (
    <IonButton fill="clear" expand="full" disabled={disabled} onClick={handleImport}>
      {L10n.localize((s) => s.import.beginImport)}
    </IonButton>
  );
}

export default function ImportCSV(props: { dismiss?: () => Promise<void>; history: History }) {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [deckTitle, setDeckTitle] = useState<string>("");
  const [file, setFile] = useState<File>();
  const [blob, setBlob] = useState<Blob>();

  const fileChooserRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    logEvent("import_csv_selected_file", { numFiles: e.target.files?.length });
    const file = e.target.files?.[0];
    if (file) {
      if (file?.name.match(/(.*?)\.(csv|zip)$/) === null) {
        alert(L10n.localize((s) => s.import.incorrectFileTypeSpreadsheet));
        setDisabled(true);
        return;
      }

      let blob: Blob | undefined = undefined;
      if (Globals.platform === "android") {
        blob = await Lib.file2blob(file);
      }

      if (file.name.match(/(.*?)\.(csv)$/) !== null) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(blob ?? file);
        reader.onload = () => {
          if (reader.result && !Lib.isValidUTF8(reader.result as ArrayBuffer)) {
            logEvent("import_csv_failed_due_to_invalid_encoding");
            alert(L10n.localize((s) => s.import.invalidEncodingSpreadsheet));
            setDisabled(true);
            return;
          }
          setFile(file);
          setBlob(blob);
          setDisabled(false);
        };
        reader.onerror = () => {
          setDisabled(true);
        };
      } else {
        setFile(file);
        setBlob(blob);
        setDisabled(false);
      }
    }
  };

  const resetForm = () => {
    if (fileChooserRef.current) {
      fileChooserRef.current.value = "";
    }
    setFile(undefined);
    setDisabled(true);
    setLoading(false);
  };

  let accept = ".csv,.zip" as string | undefined;
  if (Globals.platform === "android") {
    accept = undefined;
  }

  return (
    <>
      <IonList inset>
        <IonItem lines="none">
          <IonLabel>{L10n.localize((s) => s.import.spreadsheetColumnGeneralInstructions)}</IonLabel>
        </IonItem>

        <IonListHeader>{L10n.localize((s) => s.import.spreadsheetColumnStructure)}</IonListHeader>
        <IonAccordionGroup value="2-col">
          <IonAccordion value="2-col">
            <IonItem slot="header">
              <IonLabel>{L10n.localize((s) => s.import.csv2ColumnName)}</IonLabel>
            </IonItem>
            <IonList slot="content" lines="none">
              {[0, 1].map((i) => (
                <ColumnInstruction index={i} key={i} />
              ))}
            </IonList>
          </IonAccordion>
          <IonAccordion value="3-col">
            <IonItem slot="header">
              <IonLabel>{L10n.localize((s) => s.import.csv3ColumnName)}</IonLabel>
            </IonItem>
            <IonList slot="content" lines="none">
              {[0, 1, 2].map((i) => (
                <ColumnInstruction index={i} key={i} />
              ))}
            </IonList>
          </IonAccordion>
          <IonAccordion value="5-col">
            <IonItem slot="header">
              <IonLabel>{L10n.localize((s) => s.import.csv5ColumnName)}</IonLabel>
            </IonItem>
            <IonList slot="content" lines="none">
              {[0, 1, 2, 3, 4].map((i) => (
                <ColumnInstruction index={i} key={i} />
              ))}
            </IonList>
          </IonAccordion>
          <IonAccordion value="7-col">
            <IonItem slot="header">
              <IonLabel>{L10n.localize((s) => s.import.csv7ColumnName)}</IonLabel>
            </IonItem>
            <IonList slot="content" lines="none">
              {[0, 1, 2, 3, 4, 5, 6].map((i) => (
                <ColumnInstruction index={i} key={i} />
              ))}
            </IonList>
          </IonAccordion>
        </IonAccordionGroup>
      </IonList>
      <IonList inset>
        <IonItem
          lines="none"
          button
          detail={!file}
          onClick={() => {
            if (file) {
              if (fileChooserRef.current) {
                fileChooserRef.current.value = "";
              }
              setDisabled(true);
              setFile(undefined);
            } else {
              logEvent("import_spreadsheet_clicked_file_chooser");
              fileChooserRef.current?.click();
            }
          }}
        >
          <IonIcon slot="start" icon={file ? documentOutline : folderOutline} />
          <IonLabel color="primary">
            {file ? file.name : L10n.localize((s) => s.import.chooseFile)}
          </IonLabel>
          <input
            ref={fileChooserRef}
            style={{ display: "none" }}
            type="file"
            accept={accept}
            onChange={handleFileChange}
          />
          {file ? (
            <IonNote slot="end">
              <IonIcon icon={closeCircle} />
            </IonNote>
          ) : undefined}
        </IonItem>

        {!disabled && (
          <>
            <IonItem lines="inset">
              <IonInput
                label={L10n.localize((s) => s.general.name)}
                disabled={disabled}
                placeholder={L10n.localize((s) => s.general.required)}
                autoFocus
                value={deckTitle}
                onIonInput={(e: InputCustomEvent) => setDeckTitle(e.detail.value ?? "")}
                clearInput
              />
            </IonItem>
            <BeginImportButton
              dismiss={props.dismiss}
              history={props.history}
              disabled={disabled || !deckTitle}
              deckTitle={deckTitle}
              setLoading={() => setLoading(true)}
              file={file}
              blob={blob}
              resetForm={resetForm}
            />
          </>
        )}
        <IonLoading isOpen={loading} message={L10n.localize((s) => s.import.pleaseWait)} />
      </IonList>
      <IonNote className="">
        <p className="ion-margin-horizontal ion-text-wrap">
          {L10n.localize((s) => s.import.fileDescriptionSpreadsheet)}
        </p>
      </IonNote>
    </>
  );
}
