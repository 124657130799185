import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonNav,
  IonNavLink,
  IonNote,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import DeckCreateMainNav from "@screens/deckCreate/main";
import ImportScreen, { setInitialImportTab } from "@screens/import";
import logEvent from "analytics";
import { History } from "history";
import L10n from "localization";
import React, { useCallback, useEffect } from "react";
import { LuCamera, LuFileSpreadsheet, LuImport, LuPenSquare, LuSearch } from "react-icons/lu";

interface IProps {
  dismiss: () => Promise<void>;
  onAddToInbox: () => void;
  history: History;
}
function GetStartedNav(props: IProps): JSX.Element {
  const { dismiss } = props;

  const handlePhoto = useCallback(async () => {
    await dismiss();
    props.onAddToInbox();
    logEvent("get_started_event", { step: "picked_option", option: "photo" });
  }, [dismiss, props.onAddToInbox]);

  const handleSearch = useCallback(async () => {
    await dismiss();
    props.history.push("/search");
    logEvent("get_started_event", { step: "picked_option", option: "search" });
  }, [dismiss, props.history]);

  useEffect(() => {
    logEvent("get_started_event", { step: "showed_modal" });
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.getStarted.getStarted)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent color="light" style={{ paddingBottom: "env(safe-area-inset-bottom)" }}>
        <IonNote>
          <p className="ion-margin-horizontal ion-text-wrap">
            {L10n.localize((s) => s.getStarted.description)}
          </p>
        </IonNote>

        <IonText color="medium">
          <h3 className="ion-margin-horizontal" style={{ fontSize: "1.2rem", marginBottom: 8 }}>
            {L10n.localize((s) => s.getStarted.makeContent)}
          </h3>
        </IonText>
        <IonList inset style={{ marginBottom: 32, marginTop: 0 }}>
          <IonNavLink
            routerDirection="forward"
            component={() => {
              logEvent("get_started_event", { step: "picked_option", option: "manual" });
              return <DeckCreateMainNav history={props.history} dismiss={dismiss} nav />;
            }}
          >
            <IonItem button>
              <div slot="start">
                <LuPenSquare size={24} style={{ color: "var(--ion-color-primary)" }} />
              </div>
              <IonLabel>
                <h1>{L10n.localize((s) => s.deck.new)}</h1>
                <p>{L10n.localize((s) => s.getStarted.manualDescription)}</p>
              </IonLabel>
            </IonItem>
          </IonNavLink>
        </IonList>

        <IonText color="medium">
          <h3 className="ion-margin-horizontal" style={{ fontSize: "1.2rem" }}>
            {L10n.localize((s) => s.getStarted.loadContent)}
          </h3>
        </IonText>
        <IonList inset style={{ marginBottom: 32, marginTop: 0 }}>
          <IonItem button>
            <div slot="start">
              <LuCamera size={24} style={{ color: "var(--ion-color-primary)" }} />
            </div>
            <IonLabel onClick={handlePhoto}>
              <h2>{L10n.localize((s) => s.getStarted.photo)}</h2>
              <p>{L10n.localize((s) => s.getStarted.photoDescription)}</p>
            </IonLabel>
          </IonItem>
          <IonNavLink
            routerDirection="forward"
            component={() => {
              setInitialImportTab("CSV");
              logEvent("get_started_event", { step: "picked_option", option: "spreadsheet" });
              return <ImportScreen dismiss={props.dismiss} history={props.history} nav />;
            }}
          >
            <IonItem button>
              <div slot="start">
                <LuFileSpreadsheet size={24} style={{ color: "var(--ion-color-primary)" }} />
              </div>
              <IonLabel>
                <h2>{L10n.localize((s) => s.getStarted.spreadsheet)}</h2>
                <p>{L10n.localize((s) => s.getStarted.spreadsheetDescription)}</p>
              </IonLabel>
            </IonItem>
          </IonNavLink>
          <IonNavLink
            routerDirection="forward"
            component={() => {
              setInitialImportTab("FML");
              logEvent("get_started_event", { step: "picked_option", option: "import" });
              return <ImportScreen dismiss={props.dismiss} history={props.history} nav />;
            }}
          >
            <IonItem button>
              <div slot="start">
                <LuImport size={24} style={{ color: "var(--ion-color-primary)" }} />
              </div>
              <IonLabel>
                <h2>{L10n.localize((s) => s.getStarted.import)}</h2>
                <p>{L10n.localize((s) => s.getStarted.importDescription)}</p>
              </IonLabel>
            </IonItem>
          </IonNavLink>
        </IonList>

        <IonText color="medium">
          <h3 className="ion-margin-horizontal" style={{ fontSize: "1.2rem" }}>
            {L10n.localize((s) => s.getStarted.findContent)}
          </h3>
        </IonText>
        <IonList inset style={{ marginTop: 0 }}>
          <IonItem button>
            <div slot="start">
              <LuSearch size={24} style={{ color: "var(--ion-color-primary)" }} />
            </div>
            <IonLabel onClick={handleSearch}>
              <h2>{L10n.localize((s) => s.actions.search)}</h2>
              <p>{L10n.localize((s) => s.getStarted.searchDescription)}</p>
            </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export default function GetStartedModal(props: IProps): JSX.Element {
  const navRoot = useCallback(() => {
    return (
      <GetStartedNav
        dismiss={props.dismiss}
        onAddToInbox={props.onAddToInbox}
        history={props.history}
      />
    );
  }, [props.dismiss, props.onAddToInbox, props.history]);
  return <IonNav root={navRoot} />;
}
