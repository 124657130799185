import { IonList } from "@ionic/react";
import listeningAudio from "fields/layoutMarket/audio";
import imageRecognition from "fields/layoutMarket/image";
import {
  languageLayoutsWithFields,
  mathLayoutsWithFields,
  programmingLayoutsWithFields,
} from "fields/layoutMarket/preInstalledLayouts";
import standard from "fields/layoutMarket/standard";
import { IMarketItem } from "fields/layoutMarket/types";
import { useDecks } from "hooks/data/deckList";
import L10n from "localization";
import React from "react";
import { IConfigSelection } from "./main";
import MarketSection from "./marketSection";

function useRecentMarketItems(): IMarketItem[] {
  const allDecks = useDecks();
  const configDecks = allDecks
    .filter((deck) => deck.config !== undefined)
    .sort((d1, d2) => d2.modified_at.getTime() - d1.modified_at.getTime())
    .slice(0, 20);

  const recents: IMarketItem[] = [];
  for (const deck of configDecks) {
    if (!deck.config) {
      continue;
    }
    const values: Record<string, string> = {};
    for (const field of deck.config.fields) {
      if (!(field.type === "text" || field.type === "tts")) {
        // If not one of those field types, just use the default field value for the preview.
        // The default will look better in general, but for text and TTS, want to show the lang.
        continue;
      }
      const langCode = (field.attributes as { lang?: string })?.lang;
      values[field.name] = langCode ? `${field.name} (${langCode})` : field.name;
    }
    recents.push({
      base: deck.config.base ?? deck.id,
      name: deck.name,
      description: new Intl.DateTimeFormat(L10n.currentLocale, {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(deck.modified_at),
      fields: deck.config.fields,
      values,
    });
  }

  return recents;
}

export interface ILayoutSelectorListProps {
  onSelect: (cfg: IConfigSelection) => void;
  defaultItem: IMarketItem;
}
function LayoutSelectorList({ onSelect, defaultItem }: ILayoutSelectorListProps) {
  const recents = useRecentMarketItems();

  interface IMarketSection {
    name: string;
    list: IMarketItem[];
  }
  const layoutSections: IMarketSection[] = [
    {
      name: L10n.localize((s) => s.preInstalledLayouts.standard),
      list: [defaultItem, standard(), imageRecognition()],
    },
    {
      name: L10n.localize((s) => s.preInstalledLayouts.language),
      list: languageLayoutsWithFields(),
    },
    {
      name: L10n.localize((s) => s.preInstalledLayouts.specialized),
      list: [listeningAudio(), ...mathLayoutsWithFields(), ...programmingLayoutsWithFields()],
    },
    { name: L10n.localize((s) => s.general.recent), list: recents },
  ];

  return (
    <IonList style={{ paddingBottom: 24 }}>
      {layoutSections.map(({ name, list }) => {
        if (list.length < 1) {
          return;
        }
        return (
          <MarketSection
            selectedID={defaultItem.base}
            key={name}
            name={name}
            list={list}
            onSelect={onSelect}
          />
        );
      })}
    </IonList>
  );
}

export default React.memo(LayoutSelectorList);
