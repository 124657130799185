import { Brand } from "./timestamps";

const numCharsIn128BitBase64String = 24;
const numCharsIn128BitHexString = 32;

export type HexUUID = Brand<string, "HexUUID">;
export type Base64UUID = Brand<string, "Base64UUID">;

export function isBase64UUID(id: HexUUID | Base64UUID): id is Base64UUID {
  // If unpadded, less than 24.
  return id.length <= numCharsIn128BitBase64String;
}

export function isHexUUID(id: HexUUID | Base64UUID): id is HexUUID {
  return id.length === numCharsIn128BitHexString;
}

export function base64ToHex(base64: string): string {
  // Decode Base64 string
  const binaryString = atob(base64);
  let hexString = "";

  // Convert each character in the binary string to a hex value
  for (let i = 0; i < binaryString.length; i++) {
    const hex = binaryString.charCodeAt(i).toString(16);
    hexString += hex.padStart(2, "0"); // Ensure each hex byte is two characters
  }

  return hexString;
}

export function base64UUIDToHexUUID(b64: Base64UUID): HexUUID {
  return base64ToHex(b64) as HexUUID;
}

export function hexUUIDToBase64UUID(hex: HexUUID): Base64UUID {
  if (hex.length % 2 !== 0) {
    throw new Error("Hex string must have an even length.");
  }

  const byteLength = hex.length / 2;
  const bytes = new Uint8Array(byteLength);

  for (let i = 0; i < byteLength; i++) {
    const hexByte = hex.substring(i * 2, i * 2 + 2);
    const byteValue = Number.parseInt(hexByte, 16);
    if (Number.isNaN(byteValue)) {
      throw new Error("Invalid hex string.");
    }
    bytes[i] = byteValue;
  }

  // Convert byte array to binary string using Array.map and Array.join
  const binaryString = Array.from(bytes)
    .map((byte) => String.fromCharCode(byte))
    .join("");

  // Convert binary string to Base64
  const base64String = btoa(binaryString);

  // Remove the padding (==), since we know it's always 128 bits
  const base64WithoutPadding = base64String.slice(0, -2);

  return base64WithoutPadding as Base64UUID;
}

export function convertToBase64IfNecessary(id: Base64UUID | HexUUID): Base64UUID {
  return isBase64UUID(id) ? id : hexUUIDToBase64UUID(id);
}

export function convertToHexIfNecessary(id: Base64UUID | HexUUID): HexUUID {
  return isHexUUID(id) ? id : base64UUIDToHexUUID(id);
}
