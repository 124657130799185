import idb, { IDBAppSchema } from "@data/idb";
import { subFolderMagicSeparator } from "@data/lib/folders";
import { Deck } from "@models/deck";
import { IBaseOperation } from "@models/operation";
import EventBus from "eventBus";
import { IDBPTransaction, StoreNames } from "idb";

export interface IDeckTagDeleteOperation extends IBaseOperation {
  object_type: "deck_tag";
  type: "DELETE";
  object_parameters: {
    deck_id?: string;
    tag_name: string;
  };
}

export interface IDeckTagInsertOperation extends IBaseOperation {
  object_type: "deck_tag";
  type: "INSERT";
  object_parameters: {
    deck_id: string;
    tag_name: string;
  };
}

export interface IDeckTagUpdateOperation extends IBaseOperation {
  object_type: "deck_tag";
  type: "UPDATE";
  object_parameters: {
    tag_name_prev: string;
    tag_name: string;
  };
}

export type DeckTagOperation =
  | IDeckTagDeleteOperation
  | IDeckTagInsertOperation
  | IDeckTagUpdateOperation;

const DELETE = async (
  op: IDeckTagDeleteOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const { deck_id, tag_name } = op.object_parameters;
  if (deck_id !== undefined) {
    const deckStore = tx.objectStore("decks");
    const d = await deckStore.get(deck_id);
    if (d) {
      const newTags = d.tags?.filter((t) => t !== tag_name);
      d.tags = newTags;
      await deckStore.put(d);
    }
    return () => {
      EventBus.emit("deckMoved", { ID: deck_id });
    };
  }
  // Delete folder.
  const folderStore = tx.objectStore("folders");
  await folderStore.delete(tag_name);

  // Delete subfolders.
  const subFolderPrefix = tag_name + subFolderMagicSeparator;
  let cursor = await folderStore.openCursor();
  while (cursor) {
    if (cursor.value.name.startsWith(subFolderPrefix)) {
      await cursor.delete();
    }
    cursor = await cursor.continue();
  }
  return () => {
    EventBus.emit("folderDeleted", { name: tag_name });
  };
};

const INSERT = async (
  op: IDeckTagInsertOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const { deck_id, tag_name } = op.object_parameters;
  await tx.objectStore("folders").put({ name: tag_name });
  const deckStore = tx.objectStore("decks");
  const d = await deckStore.get(deck_id);
  if (d) {
    const allTags = new Set(d.tags);
    allTags.add(tag_name);
    const newTags = Array.from(allTags);
    await deckStore.put({ ...d, tags: newTags });
  }
  return () => {
    EventBus.emit("deckMoved", {
      ID: deck_id,
      folder: tag_name,
    });
    EventBus.emit("folderCreated");
  };
};

const UPDATE = async (
  op: IDeckTagUpdateOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const { tag_name, tag_name_prev } = op.object_parameters;
  const from = tag_name_prev;
  const to = tag_name;
  const subFolderPrefix = from + subFolderMagicSeparator;
  const newName = (folder: string): string => {
    if (folder === from) {
      return to;
    }
    if (folder.startsWith(subFolderPrefix)) {
      // It's a subfolder.
      const sansPrefix = folder.substring(subFolderPrefix.length);
      const newPrefix = to + subFolderMagicSeparator;
      return newPrefix + sansPrefix;
    }
    return folder;
  };
  let cursor = await tx.objectStore("folders").openCursor();
  const renames: Record<string, string> = {};
  while (cursor) {
    if (cursor.value.name === from || cursor.value.name.startsWith(subFolderPrefix)) {
      // Create a new object with the updated name
      const name = newName(cursor.value.name);
      const updatedRow = {
        ...cursor.value,
        name,
      };
      renames[cursor.value.name] = name;

      if (updatedRow.name !== cursor.value.name) {
        // If the name is changed, delete the old record and add a new one
        await cursor.delete();
        await tx.objectStore("folders").add(updatedRow);
      } else {
        // If the name is not changed, you can update the record as is
        await cursor.update(updatedRow);
      }
    }
    cursor = await cursor.continue();
  }

  // Move decks
  const decks = Deck.getAllDecksInFolders(from, { includeSubFolders: true });
  for (const deck of decks) {
    const tags = deck.tags?.map((tag) => newName(tag));
    await idb.update(idb.db, "decks", deck.id, { tags }, tx);
  }
  return () => {
    for (const [tag_name_prev, tag_name] of Object.entries(renames)) {
      EventBus.emit("folderRenamed", { tag_name_prev, tag_name });
    }
    for (const deck of decks) {
      EventBus.emit("deckUpdated", { ID: deck.id });
    }
  };
};

export { DELETE, INSERT, UPDATE };
