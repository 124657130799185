import Network from "network";
import * as NameStore from "@data/stores/name";

export interface INewStudyGroupRequest {
  name: string;
}

export interface INewStudyGroupResponse {
  id: string;
  name: string;
  created_at: string;
  share_url: string;
}

export async function createStudyGroup(name: string): Promise<INewStudyGroupResponse> {
  const payload: INewStudyGroupRequest = {
    name,
  };
  return Network.fetch<INewStudyGroupResponse>("POST", "/users/groups", payload);
}

export async function setUserName(
  first: string | undefined,
  last: string | undefined,
): Promise<void> {
  await Network.fetch("POST", "/users/account/name", {
    name_first: first,
    name_last: last,
  });
  NameStore.setName({ first, last });
}
