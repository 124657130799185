import JapaneseWithFurigana, {
  IJapaneseToken,
  nullFuriganaToken,
} from "fields/components/japaneseWithFurigana";
import { defaultAnnotationMode } from "fields/editors/annotationModeNav";
import { fieldTypeMap } from "fields/fields";
import { IFieldValueProps } from "fields/fieldType";
import { tokenize } from "fields/japaneseLib";
import { IFieldJapanese } from "fields/types/japanese";
import React from "react";

export default function JapaneseValue({
  value,
  field,
}: IFieldValueProps<IFieldJapanese>): JSX.Element | null {
  const parsedVal = value !== undefined ? fieldTypeMap.japanese.loadFML(value) : undefined;
  const japanese = parsedVal?.japanese ?? "";
  const furiganaMode = field.attributes?.furiganaMode ?? defaultAnnotationMode;

  const [output, setOutput] = React.useState<IJapaneseToken[]>();

  React.useEffect(() => {
    if (japanese === "") {
      setOutput([]);
      return;
    }

    if (furiganaMode !== "off") {
      tokenize(japanese).then((tokens) => setOutput(tokens));
    }
  }, [japanese, furiganaMode]);

  const showFuriganaPlaceholder = furiganaMode === "off" || !output;
  const tokens = showFuriganaPlaceholder ? nullFuriganaToken(japanese) : output;

  return (
    <div style={{ fontSize: field.attributes?.fontSizePx }}>
      {tokens.map((token, i) => {
        return <JapaneseWithFurigana key={i} token={token} furiganaMode={furiganaMode} />;
      })}
    </div>
  );
}
