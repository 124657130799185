import { History } from "history";
import { IDeckOrFolderRow } from "hooks/data/deckList";
import React from "react";
import DeckListDeckItem from "./deckListDeckItem";
import DeckListFolderItem from "./deckListFolderItem";

export default function DeckListItem(props: {
  row: IDeckOrFolderRow;
  history?: History;
  onClick?: () => Promise<void>;
  inlineDownload?: boolean;
  showFolderPrefix?: boolean;
}): JSX.Element {
  const { row } = props;
  switch (row.type) {
    case "deck":
      return <DeckListDeckItem {...props} row={row} />;
    case "folder":
      return <DeckListFolderItem {...props} row={row} />;
  }
}
