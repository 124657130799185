import { Locales } from "locales";
import L10n from "localization";
import { IField } from "./fields";
import { BFF69, bff69, validTTSLangCodes, validTranslationLangCodes } from "./langCodes";

export function fieldLang(field?: IField): BFF69 | undefined {
  if (!field) {
    return;
  }

  switch (field.type) {
    case "japanese":
      return "ja-JP";
    case "chinese":
    case "text":
    case "tts":
      return field.attributes?.lang;
  }
}

export function isBFF69(lang: string): lang is BFF69 {
  return bff69.includes(lang as BFF69);
}

const appLangToBCP47: Record<Locales, BFF69> = {
  de: "de-DE",
  en: "en-US",
  es: "es-ES",
  fr: "fr-FR",
  ja: "ja-JP",
  "pt-br": "pt-BR",
  "zh-cn": "zh-CN",
  "zh-tw": "zh-TW",
};

// Returns BFF69 lang code for current app UI language.
export function appLang(): BFF69 {
  const appLangCode = L10n.getCurrentLocale();
  return appLangToBCP47[appLangCode];
}

export function languageName(lang: BFF69): string | undefined {
  const currCode = appLang();
  const intl = new Intl.DisplayNames([currCode], { type: "language" });
  return intl.of(lang);
}

export interface ILanguage {
  code: string;
  name: string;
}

// TODO: Translate.
export function humanLanguages(): ILanguage[] {
  return bff69.map((code) => ({
    code,
    name: languageName(code) ?? code,
  }));
}

export function ttsLanguages(): ILanguage[] {
  return validTTSLangCodes
    .filter((code) => bff69.includes(code))
    .map((code) => ({
      code,
      name: languageName(code) ?? code,
    }));
}

export function translationLanguages(): ILanguage[] {
  return validTranslationLangCodes
    .filter((code) => isBFF69(code) && bff69.includes(code))
    .map((code) => {
      return {
        code,
        name: isBFF69(code) ? languageName(code) ?? code : code,
      };
    });
}

export const defaultChineseLang = "zh-CN";
export const chineseLangs: string[] = ["zh-CN", "zh-TW"];
export type ChineseLang = "zh-CN" | "zh-TW";

// humanTimeAgo returns a string like "5 minutes ago".
export function humanTimeAgo(time: Date): string | null {
  const justNowThresholdMillis = 60_000;

  const now = new Date();
  const diffMs = now.getTime() - time.getTime();

  if (isNaN(diffMs)) {
    return null;
  }

  const oneMinMillis = 1000 * 60;
  const oneHourMillis = oneMinMillis * 60;
  const oneDayMillis = oneHourMillis * 24;

  let since: string;

  // Say "just now" if the time was really recent.
  if (diffMs < justNowThresholdMillis) {
    since = L10n.localize((s) => s.general.justNow);
  } else if (diffMs < oneHourMillis) {
    const diffMins = diffMs / oneMinMillis;
    const formatter = new Intl.RelativeTimeFormat(appLang(), { style: "narrow" });
    since = formatter.format(-Math.floor(diffMins), "minutes");
  } else if (diffMs < oneDayMillis) {
    const diffHours = diffMs / oneHourMillis;
    const formatter = new Intl.RelativeTimeFormat(appLang(), { style: "narrow" });
    since = formatter.format(-Math.floor(diffHours), "hours");
  } else {
    const diffDays = diffMs / oneDayMillis;
    const formatter = new Intl.RelativeTimeFormat(appLang(), { style: "narrow" });
    since = formatter.format(-Math.floor(diffDays), "days");
  }

  return since;
}
