import InfoDot from "@components/infoDot";
import RadioSelectSettingModal from "@components/radioSelectSettingModal";
import SettingsListItemIcon from "@components/settingsListItemIcon";
import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { DotID, clearDot } from "dots";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback } from "react";
import { PiArrowsInLineVertical } from "react-icons/pi";

export type FlipStabilizationSetting = "none" | "stabilize";
export const defaultFlipStabilizationSetting: FlipStabilizationSetting = "stabilize";

interface IProps {
  setting?: FlipStabilizationSetting;
  onChange: (setting: FlipStabilizationSetting) => void;
}
export default function FlipStabilizationSelectorItem(props: IProps): JSX.Element {
  const { setting = defaultFlipStabilizationSetting, onChange } = props;

  const options: Array<{ name: string; val: FlipStabilizationSetting; desc: string }> = [
    {
      name: L10n.localize((s) => s.general.on),
      val: "stabilize",
      desc: L10n.localize((s) => s.deck.flipStabilizationOnDescription),
    },
    {
      name: L10n.localize((s) => s.general.off),
      val: "none",
      desc: L10n.localize((s) => s.deck.flipStabilizationOffDescription),
    },
  ];

  const dotID: DotID = "flip_stabilization_setting";
  const [showModal] = useIonCardModalWithDismiss(
    RadioSelectSettingModal<FlipStabilizationSetting>,
    {
      options,
      title: L10n.localize((s) => s.deck.flipStabilization),
      onChange,
      selectedItemVal: setting,
      description: L10n.localize((s) => s.deck.flipStabilizationDescription),
    },
  );

  const handleClick = useCallback(() => {
    clearDot(dotID).catch(() => {});
    showModal();
  }, [showModal]);

  const selectedItemName = options.find(({ val }) => val === setting)?.name ?? "";

  return (
    <IonItem button onClick={handleClick}>
      <InfoDot id={dotID} />
      <SettingsListItemIcon icon={PiArrowsInLineVertical} />
      <IonLabel style={{ flexBasis: "content", flexShrink: 0 }}>
        {L10n.localize((s) => s.deck.flipStabilization)}
      </IonLabel>
      <IonNote
        slot="end"
        style={{
          overflowX: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {selectedItemName}
      </IonNote>
    </IonItem>
  );
}
