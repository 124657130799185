import { IPromptOptions, promptHideEventName, promptShowEventName } from "@components/prompt";
import { ISyncStatus, Op } from "@models/operation";
import { IIDBResponse } from "@models/response";
import { SortOptions } from "@screens/decks/types";
import * as Sentry from "@sentry/browser";
import { DotID } from "dots";
import { IField } from "fields/fields";
import { ID } from "types/ID";
import { IShowParams } from "unlimited/pdp";

export interface IProgressEventParams {
  numerator: number;
  denominator?: number;
  message?: string;
}

// This is the set of event names, paired with the type of arguments that must be emitted by each one.
export interface IEvents {
  [promptHideEventName]: [];
  [promptShowEventName]: [opts: IPromptOptions];
  backgroundIAPFailure: [];
  backgroundIAPSuccess: [];
  cardBlobsPreloaded: [opts: IProgressEventParams];
  cardDeleted: [opts: { knolID?: ID; deckID: ID }];
  cardSaved: [];
  knolUpdated: [{ knolID: ID; deckID: ID }];
  deckAdded: [{ ID: ID }];
  deckDeleted: [{ deckIDs: ID[] }]; // permadelete
  deckDownloadComplete: [evt: { deckID: ID }];
  deckDownloadStatusChange: [{ deckID: ID }];
  deckGradesReset: [{ ID: ID }];
  deckImported: [];
  deckMoved: [{ ID: ID; folder?: string }];
  deckRemoved: [{ deckIDs: ID[] }]; // used for removing but not permadelete
  deckSortUpdated: [value: SortOptions];
  deckUpdated: [{ ID: ID }];
  deletedStudyGroup: [{ groupID: ID }];
  dotCleared: [{ dotID: DotID }];
  fieldValueInput: [{ src: IField; value: string }];
  flagCacheInvalidated: [];
  flagCacheUpdated: [];
  folderCreated: [];
  folderDeleted: [{ name: string }];
  folderRenamed: [{ tag_name_prev: string; tag_name: string }];
  folderUpdated: [{ name: string }];
  groupJoinTokenSet: [];
  hideHint: [];
  hidePDP: [];
  hscroll: [];
  iap: [status: string];
  inboxUpdated: [{ sha256: string }];
  inboxUsedForGeneration: [];
  knolGradeReset: [IProgressEventParams];
  layoutDeleted: [];
  layoutUpdated: [];
  leftStudyGroup: [{ groupID: ID }];
  localeUpdated: [];
  loginResponsesInsertComplete: [];
  loginResponsesInsertedBatch: [IProgressEventParams];
  mouseUp: [];
  omniReviewDeckDownloaded: [opts: IProgressEventParams];
  omniReviewSettingsChanged: [];
  omniReviewRecencyChanged: [];
  pauseTimer: [];
  resumeTimer: [];
  remoteDecksMigrated: [];
  replayAudio: [];
  resetTimer: [];
  responseInserted: [{ response: IIDBResponse }];
  responsesBatchLoaded: [IProgressEventParams];
  responseHistoryUpdated: [];
  resume: [];
  scroll: [];
  showHint: [];
  showPDP: [IShowParams];
  startTimer: [millis: number];
  studyGroupAdded: [];
  studyGroupPushNotifReceived: [{ groupID: ID }];
  swipeToGoBackStart: [];
  sync_begin: [];
  sync_complete: [result: ISyncStatus | undefined];
  sync: [response: Op[]];
  themeChange: [];
}

export type EventName = keyof IEvents;
type EventHandlerArgs<E extends EventName> = IEvents[E];
type EventHandler<E extends EventName> = (...args: EventHandlerArgs<E>) => void | Promise<void>;

type IsProgressEvent<T> = T extends [IProgressEventParams] ? true : false;
export type ProgressEvent = {
  [K in EventName]: IsProgressEvent<IEvents[K]> extends true ? K : never;
}[EventName];

class EventEmitter<T> {
  private events: Map<EventName, EventHandler<EventName>[]> = new Map();

  public async emit<E extends EventName>(name: E, ...args: IEvents[E]) {
    for (const handler of this.events.get(name) ?? []) {
      try {
        await handler(...args);
      } catch (err) {
        // In case an async function is used directly as an event handler, and doesn't catch its exceptions.
        Sentry.captureException(err, { tags: { eventName: name } });
      }
    }
  }

  public on<E extends EventName>(name: E, fn: EventHandler<E>) {
    const handlers: EventHandler<E>[] = this.events.get(name) ?? [];
    handlers.push(fn);
    this.events.set(name, handlers as EventHandler<EventName>[]);
  }

  public off<E extends EventName>(name: E, fn: EventHandler<E>) {
    const handlers = this.events.get(name);
    if (!handlers) {
      return;
    }
    this.events.set(
      name,
      handlers.filter((h) => h !== fn),
    );
  }
}

const EventBus = new EventEmitter();

export function emitEventNextRunLoopCycle<E extends EventName>(name: E, ...args: IEvents[E]) {
  // Use this function to avoid the following issue.
  // When triggering a global event from an IDB transaction callback, which resulted in another IDB
  // operation on a different table, we were seeing errors like:
  // Failed to execute 'objectStore' on 'IDBTransaction': The specified object store was not found.
  // NotFoundError: Failed to execute 'objectStore' on 'IDBTransaction': The specified object store was not found.
  setTimeout(() => EventBus.emit(name, ...args), 0);
}

export default EventBus;
