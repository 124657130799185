import { loadingTextWithDots } from "@components/loadingText";
import { showPrompt } from "@components/prompt";
import { ITypedBlobURL } from "@data/idb";
import BlobStore from "@data/idb/blobStore";
import { useIonLoading } from "@ionic/react";
import { Deck, IDeck, fieldMap } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import { Operation } from "@models/operation";
import { IContentReportInsertOperation } from "@operations/contentReport";
import { IDeckUpdateOperation } from "@operations/deck";
import {
  CardBlobs,
  isSame,
  replaceBlobPlaceholdersWithElementsInPlace,
} from "@screens/cardEdit/lib";
import saveCard from "@screens/cardEdit/saveCard";
import useEditingValuesReducer from "@screens/cardEdit/useEditingValuesReducer";
import useSaveKeyboardShortcut from "@screens/cardEdit/useSaveKeyboardShortcut";
import { FieldsContext } from "@screens/deckCreate";
import { logAction } from "analytics/action";
import EventBus from "eventBus";
import { useDeckFieldsReducer } from "fields/hooks/useFieldsReducer";
import { useLens } from "hooks/data/useLens";
import useConfirmCancellationDialog from "hooks/util/useConfirmCancellationDialog";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import { alertCircleOutline, checkmarkCircleOutline } from "ionicons/icons";
import { deepClone } from "lib";
import L10n from "localization";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ID } from "types/ID";
import CardScreenView from "./cardScreenView";
import useCopyKnols from "./useCopyKnols";
import useDeleteKnols from "./useDeleteKnols";
import { handleIndexedDBRefreshRequiredErrorIfNecessary } from "errors/indexedDBRefreshRequired";

type KeyName = string;
export type EditorRefMap = Record<KeyName, React.RefObject<HTMLIonItemElement>>;

function initializedValuesForKeys(keys: string[]): Record<string, string> {
  const values = {} as Record<string, string>;
  for (const key of keys) {
    values[key] = "";
  }
  return values;
}

export default function CardScreenViewController(props: {
  dismiss: () => void;
  setCanDismiss: (v: boolean) => void;
  deck: IDeck | undefined;
  knol?: IKnol;
}): JSX.Element {
  const { dismiss, deck, knol, setCanDismiss } = props;
  const cacheSpec = useMemo(() => (deck?.id ? { deckID: deck.id } : undefined), [deck?.id]);
  const { loading, knols } = useLens(cacheSpec, {
    excludeIgnored: false,
    sortKnols: false,
    listenWhenOffscreen: true,
  });

  const handleCopyCard = useCopyKnols(deck);

  const allDeckCardTags = loading ? undefined : Deck.knolTags(knols.values(), []);

  const editable = deck?.status === Deck.STATUS_PRIVATE;
  const canRemoveCard = editable && !!knol;
  const deleteKnols = useDeleteKnols(deck);
  const handleRemoveCard = useCallback(() => {
    deleteKnols(knol ? [knol] : [], dismiss);
  }, [knol, dismiss, deleteKnols]);

  const allowCardReport = deck?.status === Deck.STATUS_PUBLIC;
  function handleReportCard() {
    if (!knol || !deck) {
      return;
    }

    // TODO: add select+options elements
    showPrompt({
      promptType: "input",
      title: L10n.localize((s) => s.actions.reportError),
      prompt: L10n.localize((s) => s.review.reportPrompt),
      validationRegex: /.+/,
      inputType: "textarea",
      callback: (response) => {
        const comment = response?.trim();

        const now = new Date().toISOString();
        const op: IContentReportInsertOperation = {
          ...Operation.operationDefaults(),
          type: "INSERT",
          object_type: "content_report",
          object_parameters: {
            knol_id: knol.id,
            layout_id: deck.layout_id,
            report_type: undefined,
            comment,
            timestamp: now,
          },
        };
        Operation.operate(true, [op]);
      },
    });
  }

  const handleAddTag = () => {
    showPrompt({
      promptType: "input",
      title: L10n.localize((s) => s.tag.singular),
      prompt: L10n.localize((s) => s.general.name),
      validationRegex: /.+/,
      suggestions: allDeckCardTags,
      callback: (response) => {
        const tagName = response?.trim().toLowerCase() ?? "";
        if (tagName === "") {
          return;
        }
        addTag(tagName);
      },
    });
  };

  const [editingValues, dispatchEditingValues] = useEditingValuesReducer();
  const initialValues = useRef<Record<string, string>>();
  const [fields, dispatchFields, fieldsLoaded, fieldsEdited] = useDeckFieldsReducer(deck, true);
  const handleChange = useCallback(
    (key: string, value: string) => {
      const fmap = fieldMap(fields);
      const src = fmap[key];
      EventBus.emit("fieldValueInput", { src, value });
      dispatchEditingValues({ type: "update", key, value: value });
    },
    [fields, dispatchEditingValues],
  );

  const blobIdToUrl = React.useRef<Record<ID, ITypedBlobURL>>({});

  const cleanup = React.useCallback(() => {
    // Clean up blob object URLs.
    for (const id in blobIdToUrl.current) {
      const { url } = blobIdToUrl.current[id];
      BlobStore.releaseBlobURL(url);
      delete blobIdToUrl.current[id];
    }
  }, []);

  const handleRegisterBlob = useCallback(
    async (id: string, blob: Blob): Promise<string> => {
      if (!deck) {
        return "";
      }
      try {
        await BlobStore.insertBlob({
          id,
          deckID: deck.id,
          blob,
          pendingSave: true,
        });
        const objectURL = await BlobStore.getBlobURL({ id });
        if (!objectURL) {
          return "";
        }
        blobIdToUrl.current[id] = objectURL;

        return objectURL.url;
      } catch (err) {
        alert(L10n.localize((s) => s.errors.failedToSaveFile));
        logAction({
          subj: "IndexedDB",
          obj: "blob",
          obj_id: id,
          verb: "insert",
          state: "fail",
        });
        return "";
      }
    },
    [deck],
  );

  // const [blobIdToUrl, handleRegisterBlob, cleanup] = useBlobIDToURLMap(deck.id);
  const editorRefs = useRef<EditorRefMap>({});
  const [cardKeys, setCardKeys] = useState<string[]>([]);
  const [cardBlobs, setCardBlobs] = useState<CardBlobs>({});
  const [cardTags, setCardTags] = useState<string[]>([]);

  const addTag = async (tag: string) => {
    if (cardTags.includes(tag)) {
      return;
    }
    if (!editable && knol?.id) {
      // Public deck. Just insta-add the tag.
      await Knol.AddTag(knol.id, tag);
    }
    setCardTags(cardTags.concat(tag));
  };

  const removeTag = async (tag: string) => {
    if (!cardTags.includes(tag)) {
      return;
    }
    if (!editable && knol?.id) {
      // Public deck. Just insta-add the tag.
      await Knol.DeleteTag(knol.id, tag);
    }
    setCardTags(cardTags.filter((t) => t !== tag));
  };

  const init = useCallback(
    (tags: string[], initializedValues: Record<string, string>, blobs: CardBlobs) => {
      initialValues.current = deepClone(initializedValues);
      dispatchEditingValues({ type: "load", newState: initializedValues });
      setCardTags(tags);
      setCardBlobs(blobs);
    },
    [dispatchEditingValues],
  );

  useEffect(() => {
    const fetchCardData = async () => {
      if (!fieldsLoaded) {
        return;
      }

      let initializedValues = {};
      let tags: string[] = [];
      const loadedBlobs = {};

      if (knol) {
        const cardValues = knol.values ?? {};

        initializedValues = deepClone(cardValues);
        await replaceBlobPlaceholdersWithElementsInPlace(
          initializedValues,
          loadedBlobs,
          blobIdToUrl.current,
          fields,
        );

        tags = knol.tags || [];
      }

      const deckKeys = Deck.fieldOrderFromDeckOrKnolValues(deck, fields, initializedValues);
      for (const k of deckKeys) {
        editorRefs.current[k] = React.createRef<HTMLIonItemElement>();
      }

      if (!knol) {
        initializedValues = initializedValuesForKeys(deckKeys);
      }

      init(tags, initializedValues, loadedBlobs);
      setCardKeys(deckKeys);
    };

    fetchCardData();
    return cleanup;
  }, [deck, knol, fields, fieldsLoaded, cleanup, init]);

  const [presentSaveCardToast] = useDismissibleToast();
  const [presentLoadingSpinner, dismissLoadingSpinner] = useIonLoading();

  const saveFields = useCallback(async () => {
    if (!fieldsEdited || !deck) {
      return;
    }

    const op: IDeckUpdateOperation = {
      ...Operation.operationDefaults(),
      type: "UPDATE",
      object_type: "deck",
      object_parameters: {
        id: deck.id,
        name: deck.name,
        description: deck.description,
        config: {
          fields,
        },
      },
    };

    await Operation.operateAndSave(op);
  }, [deck, fields, fieldsEdited]);

  // Prevent/allow modal dismissal based on dirty state.
  const initTags = new Set(knol?.tags ?? []);
  let tagsDirty = initTags.size !== cardTags.length;
  if (!tagsDirty) {
    for (const tag of cardTags) {
      if (!initTags.has(tag)) {
        tagsDirty = true;
        break;
      }
    }
  }
  const edited = !isSame(initialValues?.current ?? {}, editingValues ?? {}) || tagsDirty;
  useEffect(() => {
    setCanDismiss(!edited);
  }, [edited, setCanDismiss]);

  const saveCardAndFields = useCallback(async () => {
    if (!deck) {
      return;
    }
    await saveFields();

    if (!editingValues) {
      return;
    }

    if (!edited) {
      cleanup();
      dismiss();
      return;
    }

    await presentLoadingSpinner({
      message: loadingTextWithDots(
        L10n.localize((s) => s.general.processing),
        L10n.localize((s) => s.general.dotChar),
      ),
    });

    try {
      await saveCard(deck.id, knol, fields, editingValues, cardBlobs, cardTags);
    } catch (err) {
      await handleIndexedDBRefreshRequiredErrorIfNecessary(err);
      await dismissLoadingSpinner();
      if (err instanceof Error) {
        presentSaveCardToast({
          message: err.message,
          icon: alertCircleOutline,
          position: "middle",
          color: "warning",
          duration: 1000,
        });
      }
      return;
    }

    if (knol) {
      cleanup();
      await dismissLoadingSpinner();
      dismiss();
    } else {
      cleanup();
      await dismissLoadingSpinner();

      init(cardTags, initializedValuesForKeys(cardKeys), {});
      setCanDismiss(true);

      // Focus on first input.
      const activeKey = cardKeys[0];
      const activeEd = editorRefs.current?.[activeKey]?.current;
      activeEd?.focus();
    }
    presentSaveCardToast({
      message: L10n.localize((s) => s.actions.saved),
      icon: checkmarkCircleOutline,
      position: "bottom",
      color: "success",
      duration: 1000,
      buttons: [
        {
          text: L10n.localize((s) => s.actions.ok),
          role: "cancel",
        },
      ],
    });
  }, [
    cardBlobs,
    edited,
    knol,
    cardKeys,
    cardTags,
    cleanup,
    deck,
    dismiss,
    dismissLoadingSpinner,
    editingValues,
    fields,
    init,
    presentLoadingSpinner,
    presentSaveCardToast,
    saveFields,
    setCanDismiss,
  ]);

  const isNewCard = !knol;

  // use TinyMCE keyboard shortcuts instead
  useSaveKeyboardShortcut(saveCardAndFields);

  useEffect(() => {
    EventBus.on("cardSaved", saveCardAndFields);
    return () => {
      EventBus.off("cardSaved", saveCardAndFields);
    };
  }, [saveCardAndFields]);

  const presentConfirmCancel = useConfirmCancellationDialog(dismiss);
  const handleClose = useCallback(() => {
    const edited = !isSame(initialValues?.current ?? {}, editingValues ?? {});
    if (!edited) {
      cleanup();
      dismiss();
      return;
    }
    presentConfirmCancel();
  }, [cleanup, dismiss, editingValues, presentConfirmCancel]);

  return (
    <FieldsContext.Provider value={{ fields, dispatch: dispatchFields }}>
      <CardScreenView
        deck={deck}
        knol={knol}
        editingValues={editingValues}
        onClose={handleClose}
        onReportCard={allowCardReport ? handleReportCard : undefined}
        onAddTag={handleAddTag}
        addTag={addTag}
        removeTag={removeTag}
        onRemoveCard={canRemoveCard ? handleRemoveCard : undefined}
        onCopyCard={isNewCard ? undefined : handleCopyCard}
        fields={fields}
        fieldOrder={cardKeys}
        handleChange={handleChange}
        handleRegisterBlob={handleRegisterBlob}
        editorRefs={editorRefs}
        tags={cardTags}
        onSave={saveCardAndFields}
        edited={edited}
        knols={knols}
      />
    </FieldsContext.Provider>
  );
}
