import L10n from "localization";
import React, { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import StudyGroupsList from "./studyGroupList";
import EditableEmailList from "./editableEmailList";
import { History } from "history";

interface IProps {
  onDismiss: () => Promise<void>;
  onShare: (emails: string[], groupIDs: string[]) => Promise<void>;
  defaultEmails?: string[];
  history: History;
}
export default function ShareModal(props: IProps) {
  const { onDismiss, defaultEmails, onShare } = props;

  const [emails, setEmails] = React.useState<string[]>(defaultEmails ?? []);
  const [segmentValue, setSegmentValue] = useState<"groups" | "emails">("groups");

  function handleShare() {
    if (segmentValue === "groups") {
      // Handled elsewhere.
    } else {
      onShare(emails, []);
    }
  }

  return (
    <IonPage style={{ marginBottom: "env(safe-area-inset-bottom)" }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.sharing.shareDeck)}</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={onDismiss}>{L10n.localize((s) => s.actions.cancel)}</IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton onClick={handleShare} disabled={emails.length < 1}>
              {L10n.localize((s) => s.actions.share)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSegment
            value={segmentValue}
            onIonChange={(e) => setSegmentValue(e.detail.value as "groups" | "emails")}
          >
            <IonSegmentButton value="groups">
              {L10n.localize((s) => s.groups.studyGroups)}
            </IonSegmentButton>
            <IonSegmentButton value="emails">
              {L10n.localize((s) => s.sharing.emails)}
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {segmentValue === "groups" ? (
          <StudyGroupsList onShare={onShare} onDismiss={onDismiss} history={props.history} />
        ) : (
          <EditableEmailList emails={emails} setEmails={setEmails} />
        )}
      </IonContent>
    </IonPage>
  );
}
