import cardPriority from "@core/cardPriority";
import computeStats from "@core/computeStats";
import { Grade } from "@core/grade";
import idb, { IDBAppSchema } from "@data/idb";
import BlobStore from "@data/idb/blobStore";
import { Base64UUID, HexUUID, convertToBase64IfNecessary, convertToHexIfNecessary } from "@lib/ids";
import { UTCMillis, dateToUTCMillis } from "@lib/timestamps";
import { IKnolDeleteOperation, IKnolInsertOperation } from "@operations/knol";
import { IKnolTagDeleteOperation, IKnolTagInsertOperation } from "@operations/knolTag";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import { INestedResponse, superCache } from "hooks/data/superCache";
import { IDBPTransaction, StoreNames } from "idb";
import Lib from "lib";
import { normalizeSearchString } from "lib/search";
import { ID } from "types/ID";
import Globals from "../globals";
import { Operation } from "./operation";

export type KnolGrades = Record<ID, IGrade>;

export interface IKnol {
  id: string;
  deck_id: ID;
  created_at?: Date;
  modified_at?: Date;
  tags?: string[];
  values?: Record<string, string>;
  grades?: KnolGrades;
}

export interface IGrade {
  last_response_at?: UTCMillis;
  score_mean?: number;
  score_standard_deviation?: number;
  num_responses?: number;
}

export interface IKnolValue {
  key: string;
  value: string;
}

export namespace Knol {
  export const MARKED_TAG = "marked";
  export const IGNORE_TAG = "ignored";

  export function getDefaultGradeValues(): Partial<IGrade> {
    return {
      last_response_at: undefined,
      score_mean: undefined,
      score_standard_deviation: undefined,
      num_responses: undefined,
    };
  }

  export async function DeleteTag(knolID: string, tagName: string) {
    const op: IKnolTagDeleteOperation = {
      ...Operation.operationDefaults(),
      type: "DELETE",
      object_type: "knol_tag",
      object_parameters: {
        knol_id: knolID,
        tag_name: tagName,
      },
    };

    await Operation.operateAndSave(op);
  }

  export async function AddTag(knolID: string, tagName: string) {
    const op: IKnolTagInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "knol_tag",
      object_parameters: {
        knol_id: knolID,
        tag_name: tagName,
      },
    };

    await Operation.operateAndSave(op);
  }

  export async function Copy(knol: IKnol, destinationDeckID: ID) {
    const { values } = knol;
    if (!values) {
      return;
    }

    const toKnolId = Lib.uuid16();
    const oldToNewBlobIds: Record<string, string> = {};

    for (let [, value] of Object.entries(values)) {
      for (const blobId of Knol.blobIdsPresentInString(value)) {
        const newBlobId = oldToNewBlobIds[blobId] ?? Lib.uuid16();
        oldToNewBlobIds[blobId] = newBlobId;

        await BlobStore.copyBlob(blobId, newBlobId, destinationDeckID, toKnolId);

        // Substitute ID in value string.
        value = value.replaceAll(`{{blob ${blobId}}}`, `{{blob ${newBlobId}}}`);
      }

      const now = new Date();
      const newKnol = {
        id: toKnolId,
        deck_id: destinationDeckID,
        created_at: now,
        modified_at: now,
        values: values,
        tags: knol.tags,
      };

      return Create(destinationDeckID, newKnol);
    }
  }

  export async function Get(knolID: ID) {
    return idb.db.get("knols", knolID);
  }

  export async function GetKnolKeys(deckID: ID): Promise<Record<string, number>> {
    const tx = idb.db.transaction("knols", "readonly");
    const store = tx.objectStore("knols");
    const index = store.index("deck_id");

    // Initialize a map to keep track of keys and their counts
    const keyCounts: Record<string, number> = {};

    // Use the index to find all knols for the specified deckID
    let cursor = await index.openCursor(IDBKeyRange.only(deckID));

    while (cursor) {
      const values = cursor.value.values;

      if (values) {
        // For each knol, iterate through the keys in the 'values' property
        for (const key of Object.keys(values)) {
          // If the key is already in keyCounts, increment its count, otherwise set to 1
          if (key in keyCounts) {
            keyCounts[key] += 1;
          } else {
            keyCounts[key] = 1;
          }
        }
      }

      // Move to the next knol in the deck
      cursor = await cursor.continue();
    }

    // The transaction will auto-close when the promise chain is complete
    return keyCounts;
  }

  export async function Delete(knolID: ID, deckID: ID) {
    delete localStorage[`AnkiApp.averagePriorityAtStartOfDay.${deckID}`];

    const op: IKnolDeleteOperation = {
      ...Operation.operationDefaults(),
      object_parameters: {
        deck_id: deckID,
        knol_id: knolID,
      },
      object_type: "knol",
      type: "DELETE",
    };

    return Operation.operateAndSave(op);
  }

  export function blobIdsPresentInString(str: string): string[] {
    const blobIdPattern = /{{blob (.*?)}}/g;

    const ids: string[] = [];
    let match: RegExpExecArray | null = null;
    do {
      match = blobIdPattern.exec(str);
      if (match) {
        ids.push(match[1]);
      }
    } while (match);
    return ids;
  }

  export async function Create(deckID: string, knol: IKnol): Promise<void> {
    if (!knol.values) {
      return;
    }
    const now = new Date().toISOString();
    const op: IKnolInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "knol",
      object_parameters: {
        deck_id: deckID,
        knol_id: knol.id,
        created_at: knol.created_at?.toISOString() ?? now,
        modified_at: knol.modified_at?.toISOString() ?? now,
        knol_tags: knol.tags,
        values: knol.values,
        response_type_id: Globals.basicResponseType.id,
      },
    };

    return Operation.operateAndSave(op);
  }

  export function GetGrade(knol: IKnol, layoutID?: ID): IGrade {
    // If no layoutID is provided and there are no grades, return default grade values
    if (!layoutID && (!knol.grades || Object.keys(knol.grades).length === 0)) {
      return getDefaultGradeValues();
    }

    let maxLastResponseAt: UTCMillis | undefined;
    let totalScoreMean = 0;
    let totalStdDeviation = 0;
    let totalNumResponses = 0;
    let gradesCount = 0;

    const grades = Object.entries(knol.grades || {});

    // If a specific layoutID is provided, filter grades for that layout
    const filteredGrades = layoutID ? grades.filter(([key, _]) => key === layoutID) : grades;

    let weightedTotalMean = 0;
    let weightedTotalVariance = 0;

    for (const [_, grade] of filteredGrades) {
      if (grade.last_response_at) {
        if (!maxLastResponseAt || maxLastResponseAt < grade.last_response_at) {
          maxLastResponseAt = grade.last_response_at;
        }
      }
      if (grade.score_mean !== undefined) {
        totalScoreMean += grade.score_mean;
        gradesCount++;
      }
      if (grade.score_standard_deviation !== undefined) {
        totalStdDeviation += grade.score_standard_deviation;
      }
      if (grade.num_responses !== undefined) {
        totalNumResponses += grade.num_responses;
      }
      if (
        grade.score_mean !== undefined &&
        grade.score_standard_deviation !== undefined &&
        grade.num_responses !== undefined
      ) {
        weightedTotalMean += grade.score_mean * grade.num_responses;
        weightedTotalVariance += grade.score_standard_deviation ** 2 * grade.num_responses;
      }
    }

    // Calculating means
    const meanScoreMean = gradesCount > 0 ? weightedTotalMean / totalNumResponses : undefined;
    const meanStdDeviation =
      gradesCount > 0 ? Math.sqrt(weightedTotalVariance / totalNumResponses) : undefined;

    // If layoutID is provided and no grades found for it, return default values
    if (layoutID && gradesCount === 0) {
      return getDefaultGradeValues();
    }

    const combinedStats: IGrade = {
      last_response_at: maxLastResponseAt,
      score_mean: meanScoreMean,
      score_standard_deviation: meanStdDeviation,
      num_responses: totalNumResponses,
    };

    return combinedStats;
  }

  export function isGradeInSet(grade: IGrade, targetGrades: Grade[]): boolean {
    if (targetGrades.length < 1) {
      return true;
    }

    // Treat "A", "AA", and "AAA" all as A's.
    const expandedTargetGrades = [...targetGrades];
    if (expandedTargetGrades.includes("A")) {
      expandedTargetGrades.push("AA");
      expandedTargetGrades.push("AAA");
    }

    const g = Grade(cardPriority(grade));
    if (!g) {
      return false;
    }
    return expandedTargetGrades.includes(g);
  }

  export async function WithGrade(knolID: ID, layoutID: ID): Promise<Partial<IKnol> | undefined> {
    const tx = idb.db.transaction("knols", "readonly");
    const knolsStore = tx.objectStore("knols");

    const knol: IKnol | undefined = await knolsStore.get(knolID);
    await tx.done; // Ensure transaction is completed before potentially exiting

    if (!knol) {
      return undefined; // Return undefined if knol not found
    }

    // Ensure `grades` is initialized to an empty object if it doesn't exist
    if (knol.grades === undefined) {
      knol.grades = {};
    }

    // Get or calculate the grade for the specified layoutID
    const grade = GetGrade(knol, layoutID);

    // Create a new object for `knolWithGrade` that includes the grade information
    const knolWithGrade: Partial<IKnol> = {
      ...knol,
      grades: {
        // Spread existing grades and add/update the grade for the current layoutID
        ...knol.grades,
        [layoutID]: grade,
      },
    };

    // Note: `knol_id` and `layout_id` were added to `knolWithGrade` in the initial code snippet,
    // but they are not part of the original `IKnol` interface. If these properties are necessary,
    // the `IKnol` interface should be updated accordingly, or a new interface/type should be created
    // to represent the extended knol object with these additional fields.

    return knolWithGrade;
  }

  export async function UpdateGradesForAllLayouts(
    tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
    knol_id: ID,
    deck_id: ID,
  ) {
    const deckStore = tx.objectStore("decks");
    // get all layout ids, call update grade for each
    const deck = await deckStore.get(deck_id);
    if (deck?.layouts) {
      for (const l of deck.layouts) {
        await UpdateGrade(tx, knol_id, l.id);
      }
    } else {
      // modern deck
      await UpdateGrade(tx, knol_id, MAGIC_LAYOUT_ID);
    }
  }

  export function matchesQuery(knol: IKnol, query: string): boolean {
    const normalizedQuery = normalizeSearchString(query);
    if (!knol.values) {
      return false;
    }
    return Object.values(knol.values).some((value) => {
      // Normalize value to be case-insensitive
      const normalizedValue = normalizeSearchString(value);
      return normalizedValue.includes(normalizedQuery);
    });
  }

  function ComputeGradeForResponses(responses: INestedResponse[]): IGrade {
    const nonAutoResponses = responses.filter((resp) => resp.score !== -1);

    // TODO: just compute stats simultaneously rather than incrementally.
    // const lastResp = nonAutoResponses.pop(); // Now nonAutoResponses has the prior resps.

    let newStats = getDefaultGradeValues();
    for (const response of nonAutoResponses) {
      newStats = computeStats(
        response.score,
        newStats.num_responses ?? 0,
        response.createdAt,
        newStats.last_response_at ?? null,
        newStats.score_mean ?? null,
        newStats.score_standard_deviation ?? null,
      );
    }

    return newStats;
  }

  export function ComputeGradesForAllLayouts(
    knolID: HexUUID | Base64UUID,
    deckLastResetAt?: Date,
  ): KnolGrades | undefined {
    const knolIDHex = convertToHexIfNecessary(knolID);
    const knol = superCache.knols?.get(knolIDHex);
    if (!knol) {
      return;
    }
    const knolIDBase64 = convertToBase64IfNecessary(knol.id as HexUUID | Base64UUID);
    const base64LayoutIDs = superCache.responses?.get(knolIDBase64)?.keys();
    const grades: KnolGrades = {};
    for (const layoutIDBase64 of base64LayoutIDs ?? []) {
      const responses = superCache.responses?.get(knolIDBase64)?.get(layoutIDBase64) ?? [];
      const postResetResponses = responses.filter((resp) => {
        if (deckLastResetAt) {
          return resp.createdAt > dateToUTCMillis(deckLastResetAt);
        }
        return true;
      });
      postResetResponses.sort((r1, r2) => r1.createdAt - r2.createdAt);
      const layoutIDHex = convertToHexIfNecessary(layoutIDBase64);
      grades[layoutIDHex] = ComputeGradeForResponses(postResetResponses);
    }
    return grades;
  }

  export async function UpdateGrade(
    tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
    knol_id: ID,
    layout_id: ID,
    deckLastResetAt?: Date,
  ) {
    const knolsStore = tx.objectStore("knols");
    const knol: IKnol | undefined = await knolsStore.get(knol_id);
    if (!knol) return;

    const knolIDBase64 = convertToBase64IfNecessary(knol_id as HexUUID | Base64UUID);
    const layoutIDBase64 = convertToBase64IfNecessary(layout_id as HexUUID | Base64UUID);
    const responses = superCache.responses?.get(knolIDBase64)?.get(layoutIDBase64) ?? [];
    const postResetResponses = responses.filter((resp) => {
      if (deckLastResetAt) {
        return resp.createdAt > dateToUTCMillis(deckLastResetAt);
      }
      return true;
    });
    postResetResponses.sort((r1, r2) => r1.createdAt - r2.createdAt);

    const newStats = ComputeGradeForResponses(postResetResponses);

    // Update knol grades
    knol.grades = {
      ...knol.grades,
      [layout_id]: newStats,
    };

    // Save updated knol back to store
    await knolsStore.put(knol);
  }
}
