import { IonButton, IonIcon, IonInput, IonItem, IonNavLink } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import L10n from "localization";
import React, { useRef, useState } from "react";
import { IPromptOption } from "./singleSelectNav";

interface IProps {
  onNav: (opt: IPromptOption) => React.ReactElement;
  icon?: React.ReactElement;
}
export default function OtherListNavItem(props: IProps) {
  const otherRef = useRef<HTMLIonNavLinkElement>(null);
  const [otherVal, setOtherVal] = useState("");
  return (
    <IonItem>
      {props.icon ? <div slot="start">{props.icon}</div> : undefined}
      <form
        style={{ width: "100%" }}
        onSubmit={(e) => {
          e.preventDefault();
          if (otherRef.current && otherVal !== "") {
            otherRef.current.click();
          }
        }}
      >
        <IonInput
          placeholder={L10n.localize((s) => s.general.other)}
          onIonInput={(e) => {
            const value = e.detail?.value;
            setOtherVal(value ?? "");
          }}
        />
      </form>
      <IonButton
        type="submit"
        slot="end"
        fill="clear"
        disabled={otherVal === ""}
        style={{ paddingRight: 0 }}
      >
        <IonNavLink
          ref={otherRef}
          routerDirection="forward"
          component={() => props.onNav({ name: "other", val: otherVal })}
        >
          <IonIcon icon={chevronForward} />
        </IonNavLink>
      </IonButton>
    </IonItem>
  );
}
