import React, { useCallback, useState } from "react";
import { IPDPContentProps } from "./pdpContent";
import { Products } from "./products";
import { IServerProduct } from "./types";
import { useIAPProducts } from "./useIAPProducts";
import useIAPPurchaseHandler from "./useIAPPurchaseHandler";
import { suppressPDPCancelSurvey } from "@components/pdpCancelSurvey";

interface IProps extends IPDPContentProps {
  onPurchaseComplete: () => void;
  serverProducts: IServerProduct[] | undefined;
}
export default function ApplePDPContent({
  userID,
  email,
  logger,
  reason,
  onPurchaseComplete,
  serverProducts,
  dismiss,
}: IProps) {
  function handlePurchaseSuccess() {
    suppressPDPCancelSurvey();
    setPurchasePending(false);
    dismiss();
    onPurchaseComplete();
  }

  const [purchasePending, setPurchasePending] = useState(false);
  const handlePurchasePending = useCallback(() => {
    setPurchasePending(true);
  }, []);

  const handlePurchaseFailure = useCallback(
    (err?: string) => {
      setPurchasePending(false);
      dismiss();
    },
    [dismiss],
  );

  const productsState = useIAPProducts(logger, serverProducts, handlePurchaseFailure);

  const onPurchase = useIAPPurchaseHandler({
    logger,
    onPurchaseFailure: handlePurchaseFailure,
    onPurchaseSuccess: handlePurchaseSuccess,
    onPurchasePending: handlePurchasePending,
    iapProducts: productsState?.iapProducts,
    userID,
  });

  const loading = serverProducts === undefined || !productsState || purchasePending;

  return (
    <Products
      reason={reason}
      description={productsState?.description ?? ""}
      showAppleTerms
      onPurchase={onPurchase}
      products={productsState?.products ?? []}
      productsLoading={loading}
      userID={userID}
      email={email}
      gateway="apple"
    />
  );
}
