import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonNav,
  IonNavLink,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useFunnelLogger } from "analytics/funnel";
import { History } from "history";
import L10n from "localization";
import React, { useCallback, useEffect } from "react";
import { PiExam, PiQuestion, PiStudent, PiTranslate } from "react-icons/pi";
import { GetStartedNav } from "../getStartedModal";
import Breadcrumbs from "./breadcrumbs";
import GoalLanguageNav from "./goalLanguageNav";
import GoalSchoolNav from "./goalSchoolNav";
import GoalTestNav from "./goalTestNav";
import OtherListNavItem from "./otherListNavItem";

export type OnboardingGoalInfo =
  | { goal: "school"; subject: string; vector: string }
  | { goal: "test"; test: string; vector: string }
  | {
      goal: "lang";
      langName: string;
      langCode: string;
      motivation: string;
      proficiency: string;
      context: string;
    };

interface IProps {
  dismiss: () => Promise<void>;
  onAddToInbox: () => void;
  history: History;
}
function OnboardingNav(props: IProps): JSX.Element {
  const logStep = useFunnelLogger("onboarding_event");
  useEffect(() => {
    logStep("showed_modal");
  }, [logStep]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.getStarted.getStarted)}</IonTitle>
        </IonToolbar>
        <Breadcrumbs crumb="goal" />
      </IonHeader>
      <IonContent color="light" style={{ paddingBottom: "env(safe-area-inset-bottom)" }}>
        <IonNote>
          <p className="ion-margin-horizontal ion-text-wrap">
            {L10n.localize((s) => s.onboarding.goalNavDescription)}
          </p>
        </IonNote>

        <IonList inset style={{ marginBottom: 32, marginTop: 0 }}>
          <IonNavLink
            routerDirection="forward"
            component={() => {
              logStep("picked_goal", { goal: "language" });
              return (
                <GoalLanguageNav
                  dismiss={props.dismiss}
                  history={props.history}
                  onAddToInbox={props.onAddToInbox}
                  logStep={logStep}
                />
              );
            }}
          >
            <IonItem button>
              <div slot="start">
                <PiTranslate size={24} style={{ color: "var(--ion-color-primary)" }} />
              </div>
              <IonLabel>
                <h2>{L10n.localize((s) => s.onboarding.goalLang)}</h2>
              </IonLabel>
            </IonItem>
          </IonNavLink>

          <IonNavLink
            routerDirection="forward"
            component={() => {
              logStep("picked_goal", { goal: "school" });
              return (
                <GoalSchoolNav
                  dismiss={props.dismiss}
                  history={props.history}
                  onAddToInbox={props.onAddToInbox}
                  logStep={logStep}
                />
              );
            }}
          >
            <IonItem button>
              <div slot="start">
                <PiStudent size={24} style={{ color: "var(--ion-color-primary)" }} />
              </div>
              <IonLabel>
                <h2>{L10n.localize((s) => s.onboarding.goalSchool)}</h2>
              </IonLabel>
            </IonItem>
          </IonNavLink>
          <IonNavLink
            routerDirection="forward"
            component={() => {
              logStep("picked_goal", { goal: "test" });
              return (
                <GoalTestNav
                  dismiss={props.dismiss}
                  history={props.history}
                  onAddToInbox={props.onAddToInbox}
                  logStep={logStep}
                />
              );
            }}
          >
            <IonItem button>
              <div slot="start">
                <PiExam size={24} style={{ color: "var(--ion-color-primary)" }} />
              </div>
              <IonLabel>
                <h2>{L10n.localize((s) => s.onboarding.goalTest)}</h2>
              </IonLabel>
            </IonItem>
          </IonNavLink>
          <OtherListNavItem
            icon={<PiQuestion size={24} style={{ color: "var(--ion-color-primary)" }} />}
            onNav={(opt) => {
              logStep("picked_goal", { goal: "other", other: opt.val });
              return (
                <GetStartedNav
                  dismiss={props.dismiss}
                  history={props.history}
                  onAddToInbox={props.onAddToInbox}
                />
              );
            }}
          />
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export default function OnboardingModal(props: IProps): JSX.Element {
  const navRoot = useCallback(() => {
    return (
      <OnboardingNav
        dismiss={props.dismiss}
        history={props.history}
        onAddToInbox={props.onAddToInbox}
      />
    );
  }, [props.dismiss, props.history, props.onAddToInbox]);
  return <IonNav root={navRoot} />;
}
