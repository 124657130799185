import { IonCard, IonCardContent, IonItem, IonLabel, IonList } from "@ionic/react";
import { DeckFields } from "@models/deck";
import { fieldTypeWithLanguage } from "fields/components/fieldListItem";
import FieldTypeIcon from "fields/components/fieldTypeIcon";
import L10n from "localization";
import React from "react";

interface IProps {
  fields: DeckFields;
}
export default function FieldsCard({ fields }: IProps) {
  return (
    <IonCard style={{ width: "calc(100% - 24px)", padding: 8, marginBottom: 6 }}>
      {fields ? (
        <>
          <IonList>
            {fields.map((field, i) => {
              const isLast = i === fields.length - 1;
              return (
                <IonItem key={field.name} lines={isLast ? "none" : undefined}>
                  <FieldTypeIcon type={field.type} />
                  <IonLabel>
                    <h2>{field.name}</h2>
                    <p>{fieldTypeWithLanguage({ field })}</p>
                  </IonLabel>
                </IonItem>
              );
            })}
          </IonList>
          <IonCardContent style={{ whiteSpace: "preserve", userSelect: "text" }}>
            {L10n.localize((s) => s.generation.fieldsDescription)}
          </IonCardContent>
        </>
      ) : (
        <IonCardContent style={{ whiteSpace: "preserve", userSelect: "text" }}>
          {L10n.localize((s) => s.generation.incompatibleConfig)}
        </IonCardContent>
      )}
    </IonCard>
  );
}
