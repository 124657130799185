import { DeckFields } from "@models/deck";
import L10n from "localization";
import { SourcePipelineNode } from "./sources/types";
import { FieldType, fieldTypeMap, IField } from "./fields";
import { includesPipelineNodeType } from "./sources/lib";
import { fieldLang } from "./lang";
import { BFF69, validTranslationLangCodes } from "./langCodes";
import { Bit, SIDES } from "./sides";

export function defaultFields(): DeckFields {
  const front = L10n.localize((s) => s.general.front);
  const back = L10n.localize((s) => s.general.back);
  return [
    {
      name: front,
      type: defaultFieldType,
      sides: SIDES.BOTH,
    },
    {
      name: back,
      type: defaultFieldType,
      sides: SIDES.BACK,
    },
  ];
}

export function defaultFieldsFor(fieldNames: string[]): DeckFields {
  return fieldNames.map((name, i) => {
    return {
      name,
      type: defaultFieldType,
      sides: i === 0 ? SIDES.BOTH : SIDES.BACK,
    };
  });
}

export interface IBaseField {
  name: string;
  type: FieldType;
  sides: Bit[];
  source?: SourcePipelineNode;
  hint?: boolean;
  attributes?: Record<string, unknown>;
}

export interface IBaseTextField extends IBaseField {
  type: "richtext" | "text" | "tts";
  attributes?: {
    hint?: boolean;
    fontSizePx?: number;
    lang?: BFF69;
  };
}

export interface IFieldWithLang extends IBaseField {
  type: "richtext" | "text" | "tts" | "chinese";
  attributes?: {
    lang?: BFF69;
  };
}

export type DeckFieldMap = Record<string, IField>;
export type FieldValues = Record<string, string>;

export const defaultFieldType: FieldType = "richtext";

export function hasDefaultFieldNameForType(name: string, type: FieldType): boolean {
  const fieldType = fieldTypeMap[type];
  const defaultName = fieldType.name();
  const regex = new RegExp(`^${defaultName}\\d*$`);
  return regex.test(name);
}

export function defaultFieldName(type: FieldType, fields: DeckFields): string {
  // Add a numerical suffix if new default name would cause a collision.
  const fieldType = fieldTypeMap[type];
  const prefix = fieldType.name();

  const fieldWithoutSuffix = fields.find((f) => f.name === prefix);
  if (!fieldWithoutSuffix) {
    return prefix;
  }

  const fieldsWithSamePrefix = fields.filter((f) => f.name.startsWith(prefix));
  if (fieldsWithSamePrefix.length < 1) {
    return prefix;
  }

  const suffixes = fieldsWithSamePrefix.map((f) => f.name.slice(prefix.length));
  const suffixNumbers = suffixes.map((s) => Number.parseInt(s)).filter((i) => !Number.isNaN(i));

  const suffixNumSet = new Set(suffixNumbers);

  // Find the lowest number that is not already used as a suffix.
  const minSuffixNum = 2;
  let suffix = minSuffixNum;
  while (suffixNumSet.has(suffix)) {
    suffix += 1;
  }

  return `${prefix}${suffix}`;
}

export function sourceFieldFollowingRef(
  field: IField | undefined,
  fmap: DeckFieldMap,
): IField | undefined {
  if (!field) {
    return;
  }
  const src = field.source;
  if (src?.type === "ref") {
    return fmap?.[src.name];
  }
  return field;
}

export function fieldLangFollowingRef(
  field: IField | undefined,
  fmap: DeckFieldMap,
): BFF69 | undefined {
  const src = sourceFieldFollowingRef(field, fmap);
  return fieldLang(src) ?? fieldLang(field);
}

export function fieldTypeFollowingRef(field: IField, fmap: DeckFieldMap): FieldType {
  const src = sourceFieldFollowingRef(field, fmap);
  return src?.type ?? field?.type;
}

export function validateTranslationPipelineLang(field: IField): boolean {
  // Require that lang be set if source pipeline includes a translation.
  if (includesPipelineNodeType(field.source, "translation")) {
    switch (field.type) {
      case "chinese":
      case "richtext":
      case "text":
      case "tts": {
        const lang = field.attributes?.lang;
        if (!lang || !validTranslationLangCodes.includes(lang)) {
          return false;
        }
      }
    }
  }
  return true;
}

export function isValidField(field: IField | undefined): boolean {
  if (!field) {
    return false;
  }

  switch (field.type) {
    case "audio": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
    case "chinese": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
    case "code": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
    case "image": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
    case "japanese": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
    case "richtext": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
    case "tex": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
    case "text": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
    case "tts": {
      const fieldType = fieldTypeMap[field.type];
      return fieldType.valid?.(field) !== false;
    }
  }
}

export function fieldValueString(type: FieldType, value: string): string | undefined {
  switch (type) {
    case "audio": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
    case "chinese": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
    case "code": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
    case "image": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
    case "japanese": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
    case "richtext": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
    case "tex": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
    case "text": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
    case "tts": {
      const srcType = fieldTypeMap[type];
      const srcVal = srcType.loadFML(value);
      return srcVal ? srcType.string?.(srcVal) : undefined;
    }
  }
}
