import SettingsModal from "@components/settingsModal";
import { IonCheckbox, IonItem, IonLabel, IonList } from "@ionic/react";
import React from "react";

export interface IMultiSelectOption<T> {
  name: string;
  val: T;
  desc?: string;
  selected?: boolean;
}

interface IProps<T> {
  dismiss: () => void;
  title: string;
  options: IMultiSelectOption<T>[];
  onChange: (val: T, selected: boolean) => void;
  description?: string;
}

export default function MultiSelectSettingModal<T>(props: IProps<T>): JSX.Element {
  const { dismiss, options, title, onChange, description } = props;

  return (
    <SettingsModal title={title} dismiss={dismiss} description={description}>
      <IonList inset>
        {options.map(({ name, val, desc, selected }) => {
          return (
            <IonItem key={JSON.stringify(val)} onClick={() => onChange(val, !selected)}>
              <IonCheckbox checked={selected}>
                <IonLabel className="ion-text-wrap">
                  {name}
                  {desc ? <p>{desc}</p> : undefined}
                </IonLabel>
              </IonCheckbox>
            </IonItem>
          );
        })}
      </IonList>
    </SettingsModal>
  );
}
