import { DeckFields } from "@models/deck";
import { AnnotationMode } from "fields/editors/annotationModeNav";
import { IField } from "fields/fields";
import { BFF69 } from "fields/langCodes";
import { fieldNames } from "fields/layoutMarket/fieldNames";
import { SIDES } from "fields/sides";
import { ChineseLang } from "fields/types/chinese";
import { IFieldTTS } from "fields/types/tts";
import { LanguageConfigTranslationSetting } from "./configSpecificOptions";

export interface ICJConfig {
  localLangCode: BFF69 | undefined;
  foreignSide: "front" | "back";
  ttsForeign: boolean;
  ttsLocal: boolean;
  translation: LanguageConfigTranslationSetting;
  annotationMode: AnnotationMode | undefined;
}

export interface IChineseConfig extends ICJConfig {
  chineseLangCode: ChineseLang | undefined;
}

export function zh2fields(conf: IChineseConfig): DeckFields {
  const foreignField: IField = {
    name: fieldNames.Chinese(),
    type: "chinese",
    sides: conf.foreignSide === "front" ? SIDES.BOTH : SIDES.BACK,
    attributes: {
      pinyinMode: conf.annotationMode,
      lang: conf.chineseLangCode,
    },
  };
  return cj2fields(conf, foreignField);
}

export function jp2fields(conf: ICJConfig): DeckFields {
  const foreignField: IField = {
    name: fieldNames.Japanese(),
    type: "japanese",
    sides: conf.foreignSide === "front" ? SIDES.BOTH : SIDES.BACK,
    attributes: {
      furiganaMode: conf.annotationMode,
    },
  };
  return cj2fields(conf, foreignField);
}

function cj2fields(conf: ICJConfig, foreignField: IField): DeckFields {
  const fields: DeckFields = [];

  const localField: IField = {
    name: fieldNames.Meaning(),
    type: "text",
    sides: conf.foreignSide === "front" ? SIDES.BACK : SIDES.BOTH,
    attributes: {
      lang: conf.localLangCode,
    },
  };

  if (conf.translation === "from-local") {
    foreignField.source = {
      type: "translation",
      source: {
        type: "ref",
        name: localField.name,
      },
    };
  } else if (conf.translation === "from-foreign") {
    localField.source = {
      type: "translation",
      source: {
        type: "ref",
        name: foreignField.name,
      },
    };
  }

  fields.push(foreignField);
  fields.push(localField);

  if (conf.ttsForeign) {
    const foreignTTSField: IFieldTTS = {
      name: `${foreignField.name}-tts`,
      type: "tts",
      sides: foreignField.sides,
      source: {
        type: "ref",
        name: foreignField.name,
      },
    };
    fields.push(foreignTTSField);
  }

  if (conf.ttsLocal) {
    const localTTSField: IFieldTTS = {
      name: `${localField.name}-tts`,
      type: "tts",
      sides: localField.sides,
      source: {
        type: "ref",
        name: localField.name,
      },
    };
    fields.push(localTTSField);
  }

  return fields;
}
