import FeatureFlags from "featureFlags";
import { Platform, isMacAppStoreElectron } from "globals";
import React from "react";
import AndroidPDPContent from "./androidPDPContent";
import ApplePDPContent from "./applePDPContent";
import PDPLogger from "./logger";
import WebPDPContent from "./webPDPContent";

export interface IPDPContentProps {
  userID: string;
  email: string;
  reason?: string;
  dismiss: () => void;
  logger: PDPLogger;
}

interface IProps extends IPDPContentProps {
  platform: Platform;
  onPurchaseComplete?: () => void;
}
export default function PDPContent({
  logger,
  reason,
  platform,
  userID,
  email,
  onPurchaseComplete,
  dismiss,
}: IProps) {
  function handlePurchaseComplete() {
    onPurchaseComplete?.();
    FeatureFlags.invalidateFlagCache();
  }

  const pdpProps = {
    onPurchaseComplete: handlePurchaseComplete,
    userID,
    email,
    logger,
    dismiss,
    reason,
  };

  if (isMacAppStoreElectron()) {
    return <ApplePDPContent {...pdpProps} />;
  }

  switch (platform) {
    case "web":
      return <WebPDPContent {...pdpProps} />;
    case "ios":
      return <ApplePDPContent {...pdpProps} />;
    case "android":
      return <AndroidPDPContent {...pdpProps} />;
  }
}
